import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import CustomButton from 'components/common/CustomButton';
import CBTWrapper from 'components/utils/CBT/CBTWrapper';

const CBTIntro = ({ title, description, numberOfQuestions, setCanStartQuiz }) => {
  const { Title, Paragraph } = Typography;

  return (
    <CBTWrapper>
      <Container>
        <Title level={1} style={{ marginBottom: 10 }}>
          {title}
        </Title>
        <Paragraph style={{ fontSize: 24, marginBottom: 16, textAlign: 'center' }}>
          {description}
        </Paragraph>
        <Paragraph style={{ color: '#0966b8' }}>
          {numberOfQuestions} Question{numberOfQuestions > 1 ? 's' : ''}
        </Paragraph>
        <CustomButton
          radius="40px"
          width="120px"
          bgColor="#0966b8"
          color="#fff"
          size="large"
          onClick={() => setCanStartQuiz(true)}>
          Start Quiz
        </CustomButton>
      </Container>
    </CBTWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1.5rem;
`;

export default CBTIntro;
