import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import SurveyIntro from 'components/utils/CourseSurvey/SurveyIntro';
import SurveyMain from 'components/utils/CourseSurvey/SurveyMain';
import SurveyOutro from 'components/utils/CourseSurvey/SurveyOutro';
import { userCourseSurvey } from 'api/queries/Course';
import PageLoader from 'components/common/PageLoader';
import { useParams } from 'react-router-dom';

const CourseSurvey = () => {
  const { id } = useParams();
  const [surveyStep, setSurveyStep] = useState(0);
  const [surveySkipped, setSurveySkipped] = useState(false);
  const { data, isLoading } = useQuery(['userCourseSurvey', id], userCourseSurvey);

  useEffect(() => {
    if (data && !data.success) {
      setSurveyStep(2);
    }
  }, [data]);

  if (isLoading) return <PageLoader isLoading={isLoading} fullWidth fullHeight />;

  if (!data) return null;

  return (
    <>
      {
        {
          0: (
            <SurveyIntro
              description={data?.data?.description}
              numberOfQuestions={data?.data?.questions.length}
              setSurveyStep={setSurveyStep}
              setSurveySkipped={setSurveySkipped}
            />
          ),
          1: <SurveyMain questions={data?.data?.questions} setSurveyStep={setSurveyStep} />,
          2: <SurveyOutro surveySkipped={surveySkipped} />
        }[surveyStep]
      }
    </>
  );
};

export default CourseSurvey;
