import { Box } from 'grommet';
import React from 'react';
import { useSelector } from 'react-redux';

const ResourceDownloader = ({ title, url, moduleIndex, sectionIndex, uploadViewingMutate }) => {
  const { userId } = useSelector(state => state.auth);
  const { courseId } = useSelector(state => state.course);

  const handleMarkAsComplete = async () => {
    try {
      await uploadViewingMutate({
        UserId: userId,
        courseId: courseId,
        currentModule: moduleIndex,
        currentSection: sectionIndex,
        secondsWatched: 0,
        isCompleted: true
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box width="full" height="full" background="#fff" pad="50px">
      <Box direction="row" pad="30px" background="#F2F3F5" justify="between" align="center">
        <Box>{title}</Box>
        <Box
          as="a"
          download={title}
          target="_blank"
          rel="noreferrer"
          href={url}
          background="#37384A"
          pad="6px 12px"
          round="3px"
          height="40px"
          justify="center">
          Download
        </Box>
      </Box>
      <Box direction="row" justify="center" pad="40px">
        <Box
          as="button"
          background="#37384A"
          pad="6px 12px"
          round="3px"
          height="40px"
          justify="center"
          onClick={handleMarkAsComplete}>
          Mark as Completed
        </Box>
      </Box>
    </Box>
  );
};

export default ResourceDownloader;
