import { getAllPermissions, searchRoleDetails } from 'api/queries/Authentication';
import PageHeader from 'components/common/PageHeader';
import PageLoader from 'components/common/PageLoader';
import ManageRoleModal from 'components/modals/ManageRoleModal';
import { Box, Button, Heading, Text, TextInput } from 'grommet';
import Settings from 'pages/admin/Settings';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { FormEdit, FormSubtract, FormCheckmark } from 'grommet-icons';
import { useDispatch } from 'react-redux';
import { showToast } from 'store/actions/toast';
import { activateDeactivateRole } from 'api/mutations/Authentication';

const permissionMap = new Map();

const RoleManagement = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [selectedRoleType, setSelectedRoleType] = useState(null);
  const [selectedRoleName, setSelectedRoleName] = useState(null);
  const [selectedRolePermissionIds, setSelectedRolePermissionIds] = useState(null);
  const { data: allRoles, isLoading: loadingRoles, refetch } = useQuery(
    ['searchRoleDetails', search, 1, 1000],
    searchRoleDetails
  );
  const { data: allPermissions, isLoading: loadingPermissions } = useQuery(
    'getAllPermissions',
    getAllPermissions
  );

  const reset = () => {
    setSelectedRoleId(null);
    setSelectedRoleType(null);
    setSelectedRoleName(null);
    setSelectedRolePermissionIds(null);
  };

  const refetchAllRoles = () => {
    reset();
    refetch();
  };

  const addRoleHandler = () => {
    reset();
    setShowModal(true);
  };

  const editRoleHandler = (roleId, roleType, roleName, permissionIds) => {
    if (permissionIds.endsWith(','))
      permissionIds = permissionIds.slice(0, permissionIds.length - 1);

    const ids = permissionIds.split(',').map(id => Number(id));
    setSelectedRoleId(roleId);
    setSelectedRoleType(roleType);
    setSelectedRoleName(roleName);
    setSelectedRolePermissionIds(ids);
    setShowModal(true);
  };

  const activateDeactivateRoleHandler = async (roleId, isActive) => {
    const isActiveState = isActive.toLowerCase() === 'true';
    try {
      setLoading(true);
      const data = await activateDeactivateRole({ roleId, isActive: !isActiveState });
      if (data.success) {
        dispatch(showToast('success', data.description));
        refetchAllRoles();
      }
    } catch (error) {
      console.log(error);
      dispatch(showToast('error', `Failed to ${isActiveState ? 'deactivate' : 'activate'} role`));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (allPermissions) {
      permissionMap.clear();
      allPermissions.data.forEach(perm => {
        if (permissionMap.has(perm.permissionGroupingName)) {
          const value = permissionMap.get(perm.permissionGroupingName).concat([perm]);
          permissionMap.set(perm.permissionGroupingName, value);
        } else {
          permissionMap.set(perm.permissionGroupingName, [perm]);
        }
      });
    }
  }, [allPermissions]);

  return (
    <Settings selectedKey="Role Management">
      <PageHeader
        pageActionElement={
          <>
            <Button primary label="Add Role" onClick={addRoleHandler} />
          </>
        }
        pageDescription="Create and manage admin roles"
        marginBottom="medium"
        pageTitle="Role Management"
      />
      <Box>
        <Box>
          <Box margin={{ bottom: 'medium' }}>
            <TextInput
              type="search"
              placeholder="Search Role"
              onChange={e => setSearch(e.target.value)}
            />
          </Box>
          <Box background="light-2" pad="small" direction="row" justify="between">
            <Heading margin="none" level="5" style={{ fontSize: '1.25rem', width: 200 }}>
              Name
            </Heading>
            <Heading margin="none" level="5" style={{ fontSize: '1.25rem', width: 100 }}>
              Type
            </Heading>
            <Heading margin="none" level="5" style={{ fontSize: '1.25rem' }}>
              Action
            </Heading>
          </Box>
          {allRoles?.map(role => (
            <Box
              key={role.id}
              pad="small"
              direction="row"
              justify="between"
              align="center"
              onClick={() => {}}
              hoverIndicator={{ color: 'light-1' }}>
              <Text
                color={role.isActive.toLowerCase() === 'true' ? 'dark-2' : 'light-6'}
                style={{ width: 200 }}>
                {role.roleName.toUpperCase()}
              </Text>
              <Text
                color={role.isActive.toLowerCase() === 'true' ? 'dark-2' : 'light-6'}
                style={{ width: 100 }}>
                {role.roleType === 0 ? 'Rivet' : 'Institution'}
              </Text>
              <Box direction="row" gap="small">
                <Button
                  plain
                  icon={
                    <FormEdit
                      color="#FF8C00"
                      size="medium"
                      style={{ border: '2px solid #FF8C00', borderRadius: '50%' }}
                    />
                  }
                  onClick={() =>
                    editRoleHandler(
                      role.id,
                      role.roleType === 0 ? 'Rivet' : 'Institution',
                      role.roleName.toUpperCase(),
                      role.permission
                    )
                  }
                />
                <Button
                  plain
                  icon={
                    role.isActive.toLowerCase() === 'true' ? (
                      <FormSubtract
                        color="#F00"
                        size="medium"
                        style={{ border: '2px solid #F00', borderRadius: '50%' }}
                      />
                    ) : (
                      <FormCheckmark
                        color="#008000"
                        size="medium"
                        style={{ border: '2px solid #008000', borderRadius: '50%' }}
                      />
                    )
                  }
                  onClick={() => activateDeactivateRoleHandler(role.id, role.isActive)}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      {showModal && (
        <ManageRoleModal
          onClose={() => setShowModal(false)}
          setLoading={setLoading}
          refetchAllRoles={refetchAllRoles}
          permissionMap={permissionMap}
          selectedRoleId={selectedRoleId}
          selectedRoleType={selectedRoleType}
          selectedRoleName={selectedRoleName}
          selectedRolePermissionIds={selectedRolePermissionIds}
        />
      )}
      <PageLoader
        fullWidth={loading}
        fullHeight={loading}
        isLoading={(loadingRoles && !allPermissions) || loadingPermissions || loading}
      />
    </Settings>
  );
};

export default RoleManagement;
