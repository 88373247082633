import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Row,
  Col,
  Typography,
  Form,
  Input,
  Select,
  Avatar,
  Button,
  Upload,
  Image,
  message
} from 'antd';
import { UserOutlined, UploadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation, useQuery } from 'react-query';

import { updateStudentProfile, updateAdminUser } from 'api/mutations/Authentication';
import device from 'configs/responsive/mediaQueries';
import { changeProfilePhoto } from 'store/actions/auth';
import { getCountries } from 'api/queries/Institution';
import { dummyRequest } from 'utils';

const { mobile } = device;

const General = ({ setLoading, setSuccessMessage, checkUser, profileData, refetch, isMobile }) => {
  const dispatch = useDispatch();
  const [profilePicUrl, setProfilePicUrl] = useState('');
  const [profilePicImage, setProfilePicImage] = useState();
  const { userId, StudentProfile, StaffProfileId } = useSelector(state => state.auth);
  const { data: countries } = useQuery(['countries'], getCountries);

  const updateUserProfile = checkUser ? updateStudentProfile : updateAdminUser;

  const [updateMutate] = useMutation(updateUserProfile, {
    onMutate: variables => setLoading(true),
    onSuccess: data => {
      setLoading(false);
      if (profilePicImage) dispatch(changeProfilePhoto(profilePicImage));
      setSuccessMessage(data);
      refetch();
    },
    onError: error => {
      setSuccessMessage(error);
    }
  });

  const onFinish = async ({ firstname, lastname, phone, organization, jobtitle, country, age }) => {
    let formData = new FormData();
    const formDetails = {
      Id: userId,
      ProfileId: checkUser ? StudentProfile : StaffProfileId,
      FirstName: firstname || '',
      LastName: lastname || '',
      ProfilePix: (profilePicUrl && profilePicUrl) || '',
      PhoneNumber: phone || '',
      Organization: organization || '',
      JobTitle: jobtitle || '',
      Country: country || '',
      Age: age || ''
    };
    let formDetailsArray = Object.entries(formDetails).filter(details => details[1] !== '');

    let newFormDetails = {};

    for (const [key, value] of formDetailsArray) {
      newFormDetails[`${key}`] = `${value}`;
    }

    for (let key in newFormDetails) {
      formData.append(
        key === 'Age' || key === 'Country' ? key : `UpdateProfiles.${key}`,
        formDetails[key]
      );
    }

    try {
      await updateMutate(formData);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = info => {
    if (info.file.status) {
      setProfilePicUrl(info.file.originFileObj);
      getBase64(info.file.originFileObj, imageUrl => {
        setProfilePicImage(imageUrl);
      });
    }
  };

  return (
    <Container>
      <Heading>General Information</Heading>
      <Div gutter={16}>
        {profileData && (
          <FormDiv
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 14 }}
            lg={{ span: 14 }}
            xl={{ span: 14 }}>
            <Form
              labelCol={{
                span: 4
              }}
              wrapperCol={{
                span: 14
              }}
              layout="vertical"
              id="General"
              onFinish={onFinish}>
              <Form.Item
                label="First name"
                name="firstname"
                rules={[
                  {
                    pattern: new RegExp(/^[a-zA-Z]+$/),
                    message: 'Only alphabets please'
                  }
                ]}>
                <Input style={{ float: 'right' }} defaultValue={profileData.firstName} />
              </Form.Item>
              <Form.Item
                label="Last name"
                name="lastname"
                // rules={[
                //   {
                //     pattern: new RegExp(/^[a-zA-Z]+$/),
                //     message: 'Only alphabets please'
                //   }
                // ]}
              >
                <Input defaultValue={profileData.lastName} />
              </Form.Item>
              {checkUser && (
                <Form.Item label="Age" name="age">
                  <Select defaultValue={profileData.age}>
                    <Select.Option value="10-14">10-14</Select.Option>
                    <Select.Option value="15-19">15-19</Select.Option>
                    <Select.Option value="20-24">20-24</Select.Option>
                    <Select.Option value="25-29">25-29</Select.Option>
                    <Select.Option value="35-39">35-39</Select.Option>
                    <Select.Option value="45-49">45-49</Select.Option>
                  </Select>
                </Form.Item>
              )}
              <Form.Item label="Phone" name="phone">
                <Input defaultValue={profileData.phoneNumber} type="number" />
              </Form.Item>
              {checkUser && (
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[
                    {
                      pattern: new RegExp(/^[a-zA-Z]+$/),
                      message: 'Only alphabets please'
                    }
                  ]}>
                  <Select defaultValue={profileData.country}>
                    {countries &&
                      countries.map(country => (
                        <Select.Option key={country.id} value={country.countryName}>
                          {country.countryName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
              {/* <Form.Item label="Address">
              <Input />
            </Form.Item> */}
              {checkUser && (
                <>
                  <Form.Item
                    label="Company"
                    name="organization"
                    // rules={[
                    //   {
                    //     pattern: new RegExp(/^[a-zA-Z]+$/),
                    //     message: 'Only alphabets please'
                    //   }
                    //]}
                  >
                    <Input defaultValue={profileData.organization} />
                  </Form.Item>
                  <Form.Item
                    label="Role/Title"
                    name="jobtitle"
                    rules={[
                      {
                        pattern: new RegExp(/^[a-zA-Z\s]*$/),
                        message: 'Only alphabets please'
                      }
                    ]}>
                    <Input defaultValue={profileData.jobTitle} />
                  </Form.Item>
                </>
              )}
            </Form>
          </FormDiv>
        )}
        <ProfilePicture
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 10 }}
          lg={{ span: 10 }}
          xl={{ span: 10 }}>
          <UpdateText>Update profile picture</UpdateText>
          <ProfileAvatar
            icon={
              profilePicImage ? (
                <img src={profilePicImage} alt="profilepic" />
              ) : profileData?.profilePixUrl ? (
                <img src={profileData?.profilePixUrl} />
              ) : (
                <UserOutlined />
              )
            }
            size={180}
          />

          <UploadProfile
            action=""
            onChange={handleChange}
            customRequest={dummyRequest}
            showUploadList={false}>
            <UpdateBtn icon={<UploadOutlined />}>Upload photo</UpdateBtn>
          </UploadProfile>
        </ProfilePicture>
      </Div>
    </Container>
  );
};

export default General;

const Container = styled.div`
  width: 100%;
  height: 100%;
  /* background: green; */
`;

const Heading = styled(Typography.Title)`
  font-size: 1.6em !important;
  font-weight: bold !important;
  color: #afafaf !important;
  margin-bottom: 1em !important;
  @media ${mobile} {
    font-size: 1.2em !important;
  }
`;
const Div = styled(Row)``;
const FormDiv = styled(Col)``;
const ProfilePicture = styled(Col)`
  @media ${mobile} {
    margin-top: 3rem;
  }
`;

const UpdateText = styled(Typography.Paragraph)`
  margin-top: -1em;
  text-align: center;
  color: #afafaf;
  font-weight: bold;
  font-size: 1.2em;
`;

const ProfileAvatar = styled(Avatar)`
  display: block;
  margin: auto;
`;

const UploadProfile = styled(Upload)`
  width: 90%;
  height: 4em;
  margin: auto;
  margin-top: 1em;
  text-align: center;
  display: block !important;
`;

const UpdateBtn = styled(Button)`
  display: block;
  color: #fff;
  background: #0966b8;
  width: 100%;
  height: 100%;
  border: 1px solid #0966b8;
  border-radius: 1.5em;
  font-size: 1em;
`;
