import React, { useEffect, useState } from 'react';
import 'styles/utils.css';
import {
  Box,
  ResponsiveContext,
  Heading,
  TextInput,
  Accordion,
  AccordionPanel,
  ThemeContext,
  CheckBoxGroup
} from 'grommet';
import { isMobile, isTablet } from 'utils';
import { Search } from 'grommet-icons';
import DiscoveryImg from 'assets/images/discover.png';
import styled from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import { getSummaryByCatgory } from 'api/queries/Course';
import { courseSearch, courseLanguageSearch } from 'api/mutations/Course';
import { search } from 'api/mutations/Institution';
import MainLayout from 'components/MainLayout';
import { useSelector, useDispatch } from 'react-redux';
import { signIn } from 'store/actions/auth';
import { useParams, useHistory } from 'react-router-dom';
import { discovery } from 'userRoutes';
import useUrlQueryParams from 'components/hooks/useUrlQueryParams';
import CourseCategory from 'components/common/CourseCategory';
// import TopCategories from 'components/common/TopCategories';
import { Empty, Typography } from 'antd';
import Loader from 'components/common/Loader';
import { useMediaQuery } from 'react-responsive';

const CourseDiscovery = () => {
  const size = React.useContext(ResponsiveContext);
  const [courseSearchResult, setCourseSearchResult] = React.useState([]);
  const [activeIndex, setActiveIndex] = React.useState([0]);
  const dispatch = useDispatch();
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });
  const [value, setValue] = React.useState('');
  const [institutionSearchResult, setInstitutionSearchResult] = React.useState(null);
  const [languageSearchResult, setLanguageSearchResult] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [createError, setCreateError] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const history = useHistory();
  const parameterSearch = useUrlQueryParams();
  const { data } = useParams();
  const searchParams = new URLSearchParams(history.location.search);

  const accessToken3 = searchParams.get('data');

  ///console.log('data333', accessToken3);

  if (accessToken3) {
    dispatch(signIn(JSON.parse(accessToken3)));
    history.push(discovery);
  }

  const { data: summaryByCatgory } = useQuery(['summaryByCatgory'], getSummaryByCatgory);
  // const { data: courseCategory } = useQuery(['courseCategory'], courseCategoryList);

  const [createMutate, info] = useMutation(courseSearch, {
    onSuccess: dataResult => {
      const { description, data, success } = dataResult;
      if (success) {
        setCourseSearchResult(data?.items);

        setSuccessMessage(description);
      } else {
        setCreateError(description);
        setIsSubmitting(false);
        console.log(description);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setCreateError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        // setCreateError(error.message);
        console.log(error);
      }
    }
  });

  const [createInstitutionMutate] = useMutation(search, {
    onSuccess: dataResult => {
      const { description, success } = dataResult;
      setInstitutionSearchResult(dataResult);
      if (success) {
        setInstitutionSearchResult(dataResult);
        setSuccessMessage(description);
      } else {
        setCreateError(description);
        setIsSubmitting(false);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setCreateError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        // setCreateError(error.message);
      }
    }
  });

  const [createLanguageMutate, vals] = useMutation(courseLanguageSearch, {
    onSuccess: dataResult => {
      const { description, success } = dataResult;
      if (success) {
        setLanguageSearchResult(dataResult.data);
        setSuccessMessage(description);
      } else {
        setCreateError(description);
        setIsSubmitting(false);
        console.log(description);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setCreateError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        // setCreateError(error.message);
        console.log(error);
      }
    }
  });

  const searchResult = async e => {
    setValue(e.target.value);
    const param = {
      page: 1,
      pageSize: 50,
      courseCategoryIds: [],
      parameter: value,
      institutionIds: [],
      courseLevels: [],
      languageIds: []
    };
    createMutate(param);
  };
  const exploreAllcourses = async () => {
    const param = {
      page: 1,
      pageSize: 50,
      courseCategoryIds: [],
      parameter: '',
      institutionIds: [],
      courseLevels: [],
      languageIds: []
    };
    createMutate(param);
  };

  React.useEffect(() => {
    try {
      const search = () => {
        const param = {
          page: 1,
          pageSize: 50,
          courseCategoryIds: [],
          parameter: '',
          institutionIds: [],
          courseLevels: [],
          languageIds: []
        };
        createMutate(param);
      };
      search();

      const instituionSearch = () => {
        const param = {
          searchVal: ''
        };
        createInstitutionMutate(param);
      };
      instituionSearch();

      const languageSearch = () => {
        const param = {
          isActive: false,
          usePaging: false
        };
        createLanguageMutate(param);
      };
      languageSearch();
    } catch (error) {}
  }, [createInstitutionMutate, createMutate, createLanguageMutate]);

  const languageHandleChange = (event, val) => {
    const param = {
      page: 1,
      pageSize: 50,
      courseCategoryIds: [],
      parameter: value,
      institutionIds: [],
      courseLevels: [],
      languageIds: val
    };
    createMutate(param);
    //setCheckedLanguage(val);
  };

  const institutionHandleChange = (event, val) => {
    //console.log('checkedLanguage', event, val);
    const param = {
      page: 1,
      pageSize: 50,
      courseCategoryIds: [],
      parameter: value,
      institutionIds: val,
      courseLevels: [],
      languageIds: []
    };
    createMutate(param);
    //setCheckedLanguage(val);
  };

  const categoryHandleChange = (event, val) => {
    //console.log('checkedLanguage', event, val);
    const param = {
      page: 1,
      pageSize: 50,
      courseCategoryIds: val,
      parameter: value,
      institutionIds: [],
      courseLevels: [],
      languageIds: []
    };
    createMutate(param);
    //setCheckedLanguage(val);
  };

  const levelHandleChange = (event, val) => {
    //console.log('checkedLanguage', event, val);
    const param = {
      page: 1,
      pageSize: 50,
      courseCategoryIds: [],
      parameter: value,
      institutionIds: [],
      courseLevels: val,
      languageIds: []
    };
    createMutate(param);
    //setCheckedLanguage(val);
  };
  const [categoryType, setCategoryType] = useState('all');
  useEffect(() => {
    const searchString = parameterSearch.get('category');
    if (['top', 'new', 'topcategory', 'program'].includes(searchString))
      setCategoryType(searchString);
    else setCategoryType('all');
  }, [parameterSearch]);

  return (
    <MainLayout selectedKey={'course'} padded={0} noMargin={0} sidebar={false}>
      <Box
        justify="center"
        height="240px"
        pad={{ top: 'large', bottom: 'large', right: 'medium', left: 'medium' }}
        background={{
          dark: true,
          position: '50% 0%',
          repeat: 'no-repeat',
          size: 'cover',
          image: `linear-gradient(to right, rgba(0,0,0,1),rgba(0,0,0,1), rgba(0,0,0,.1) ), url(${DiscoveryImg})`
        }}>
        <Box>
          <Heading
            color="#fff"
            style={{ wordSpacing: isMobileDevice ? 0 : 5 }}
            level={3}
            margin={{ vertical: 'small' }}>
            Popular topic to learn now
          </Heading>

          <Box direction="row" align="center" margin={{ vertical: 'xsmall' }}>
            <Box
              // width="small"
              direction="row"
              align="center"
              pad={{ horizontal: 'small', vertical: 'xxsmall' }}
              round="small"
              style={{ width: isMobile(size) ? '100%' : 550, background: '#fff', color: '#000' }}>
              <TextInput
                plain
                value={value}
                placeholder="What would you like to learn ?"
                onChange={event => {
                  searchResult(event);
                  if (event.target.value !== '')
                    history.push(`${history.location.pathname}?search=${event.target.value}`);
                  else history.push(history.location.pathname);
                }}
              />
              <Search color="#0849ff" />
            </Box>
          </Box>
          <LinkWrap
            size="12px"
            color="#fff"
            onClick={() => {
              setValue('');
              exploreAllcourses();
              history.push(history.push(history.location.pathname));
            }}>
            Explore all Courses
          </LinkWrap>
        </Box>
      </Box>
      <Box pad="medium" background="#fff">
        <Box gap={isMobile(size) ? 'large' : 'small'} style={{ marginLeft: 0, marginRight: 0 }}>
          <ThemeContext.Extend
            value={{
              accordion: {
                heading: {
                  level: '5',
                  margin: {
                    top: 'small',
                    bottom: 'small',
                    left: 'medium',
                    right: 'medium'
                  }
                }
              }
            }}>
            <Box width="100%" direction="row" gap="xsmall" justify="between">
              {!isMobile(size) && !isTablet(size) && (
                <Box
                  border={[
                    {
                      color: '#cccccc',
                      size: '1px',
                      style: 'solid',
                      side: 'top'
                    },
                    {
                      color: '#cccccc',
                      size: '1px',
                      style: 'solid',
                      side: 'right'
                    },
                    {
                      color: '#cccccc',
                      size: '1px',
                      style: 'solid',
                      side: 'left'
                    }
                  ]}
                  style={{
                    position: 'sticky',
                    height: '100%',
                    width: '285px',
                    top: 70,
                    left: 0
                  }}>
                  <Accordion
                    alignSelf="start"
                    className="accordion_header"
                    style={{ width: '100%' }}
                    // activeIndex={activeIndex}
                    onActive={value => setActiveIndex(value)}>
                    <AccordionPanel label="Category">
                      <Box pad={{ top: 'none', bottom: 'medium', left: 'small', right: 'small' }}>
                        {summaryByCatgory && (
                          <CheckBoxGroup
                            options={summaryByCatgory.data.map(item => ({
                              label: item.courseCategory + '  ' + '(' + item.count + ')',
                              value: item.courseCategoryId
                            }))}
                            onChange={({ value, option }) => categoryHandleChange(option, value)}
                          />
                        )}
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel style={{ color: '#000 !important' }} label="Level">
                      <Box pad={{ top: 'none', bottom: 'medium', left: 'small', right: 'small' }}>
                        <CheckBoxGroup
                          options={[
                            { label: 'Beginner', value: '1' },
                            { label: 'Intermediate', value: '2' },
                            { label: 'Advanced', value: '3' }
                          ]}
                          onChange={({ option, value }) => levelHandleChange(option, value)}
                        />
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel style={{ color: '#000 !important' }} label="Partner">
                      <Box pad={{ top: 'none', bottom: 'medium', left: 'small', right: 'small' }}>
                        {institutionSearchResult && (
                          <CheckBoxGroup
                            options={institutionSearchResult
                              .filter(e => e.isActive)
                              .map(item => ({
                                label: item.name,
                                value: item.id
                              }))}
                            onChange={({ value, option }) => institutionHandleChange(option, value)}
                          />
                        )}
                      </Box>
                    </AccordionPanel>

                    <AccordionPanel style={{ color: '#000 !important' }} label="Language">
                      <Box pad={{ top: 'none', bottom: 'medium', left: 'small', right: 'small' }}>
                        {languageSearchResult && (
                          <CheckBoxGroup
                            options={languageSearchResult.map(item => ({
                              label: item.name,
                              value: item.id
                            }))}
                            onChange={({ value, option }) => languageHandleChange(option, value)}
                          />
                        )}
                      </Box>
                    </AccordionPanel>
                  </Accordion>
                </Box>
              )}
              <Box style={{ flexGrow: 1, width: '70%' }}>
                <Box width="100%">
                  {courseSearchResult.length > 0 &&
                    (categoryType === 'all' || categoryType === 'top') && (
                      <CourseCategory
                        categoryType={categoryType}
                        searchType="top"
                        title="Top Popular courses"
                        courseSearchResult={courseSearchResult}
                      />
                    )}
                  {/* {courseSearchResult.length > 0 &&
                    (categoryType === 'all' || categoryType === 'new') && (
                      <CourseCategory
                        categoryType={categoryType}
                        searchType="new"
                        title="Discover New courses"
                        courseSearchResult={courseSearchResult}
                      />
                    )} */}
                  {courseSearchResult.length < 1 && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 250
                      }}>
                      {info.isLoading ? (
                        <LoaderWrapper>
                          <Loader color="#0966b8" /> Loading ...
                        </LoaderWrapper>
                      ) : (
                        <Empty
                          description={
                            <EmptyDescription>
                              No Result Found {value !== '' ? `For "${value}"` : '...'}
                            </EmptyDescription>
                          }
                          imageStyle={{
                            height: 200
                          }}
                        />
                      )}
                    </div>
                  )}
                </Box>
              </Box>
            </Box>
          </ThemeContext.Extend>
        </Box>
      </Box>
    </MainLayout>
  );
};

const LinkWrap = styled(Typography.Text)`
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
  cursor: pointer;
`;
const EmptyDescription = styled.p`
  font-size: 20px;
  font-weight: 700;
`;
const LoaderWrapper = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

export default CourseDiscovery;
