import React from 'react';
import { Box, Button, Stack, TextInput } from 'grommet';

const FileUpload = ({ getMedia, disabled, setFileList, color = 'brand', mediaType = 'image' }) => {
  const fileInput = React.useRef();
  const [fileName, setFileName] = React.useState(null);

  React.useEffect(() => {
    if (disabled) return;
    const currentFileInput = fileInput.current;
    currentFileInput.addEventListener('change', function () {
      // Check that the file extension is supported.
      // If not, clear the input.
      let hasInvalidFiles = false;
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];

        if (
          !{ image: '.png, .jpeg, .jpg, .gif', video: '.mp4' }[mediaType]
            .split(', ')
            .some(ext => file.name.endsWith(ext))
        ) {
          hasInvalidFiles = true;
        }
      }

      if (hasInvalidFiles) {
        currentFileInput.value = '';
        alert('Unsupported file selected.');
      }
    });
  }, [disabled, mediaType]);

  return (
    <>
      {!disabled && (
        <Box direction="row">
          <Stack anchor="right">
            <TextInput
              disabled
              value={fileName ? fileName : 'No file selected'}
              style={{ width: '350px' }}
            />
            <TextInput
              type="file"
              ref={fileInput}
              accept={{ image: '.png, .jpeg, .jpg, .gif', video: '.mp4' }[mediaType]}
              onChange={e => {
                const media = e.target.files[0];
                setFileName(media?.name);
                getMedia(media);
                if (setFileList) setFileList(media);
              }}
              style={{ width: 0, opacity: 0 }}
            />
            <Button
              primary
              disabled={disabled}
              color={color}
              label="Upload"
              onClick={() => {
                fileInput.current.click();
              }}
            />
          </Stack>
        </Box>
      )}
    </>
  );
};

export default FileUpload;
