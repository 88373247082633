import React, { useContext, useState } from 'react';
import 'styles/utils.css';
import {
  Box,
  Text,
  Heading,
  Button,
  ResponsiveContext,
  Image,
  Card,
  CardFooter,
  Avatar
} from 'grommet';
import moment from 'moment';
import CourseImage from 'assets/images/coursesdis.png';
import { isMobile } from 'utils';
import styled from 'styled-components';
import { Tooltip, Typography, Avatar as Ava, Progress } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { coursetitle, delivery, facilitators } from 'userRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { courseDetails } from 'store/actions/course';
import { MainContext } from 'index';
import AppData from 'AppData';
import { getCourseBuilder } from 'api/queries/Course';
import { createcourse, explorecourse, approvecourse } from 'adminRoutes';
import PageLoader from 'components/common/PageLoader';
import { courseStepNo } from 'store/actions/course';
import useCurrencyFormater from 'components/hooks/useCurrencyFormater';
import testimg from 'assets/images/test.jpg';

const CourseCard = ({
  courseId,
  status = 'draft',
  isPrice = false,
  isReview = false,
  price,
  title,
  description,
  careerRelated = false,
  modules,
  duration,
  background = 'none',
  level,
  userId,
  course,
  courseThumbNailUrl,
  institutionLogo,
  institutionName,
  width,
  fromSearchNav = false,
  margin,
  renderState,
  data,
  navigation = true,
  setShowCourseSetup,
  percentage,
  dashboard,
  setCourseDetails
}) => {
  const size = useContext(ResponsiveContext);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const mainContext = useContext(MainContext);
  const [isLoading, setIsLoading] = useState(false);
  const { userId: logonUserId } = useSelector(state => state.auth);
  const currencyFormatter = useCurrencyFormater();

  const handleResume = () => {
    history.push(delivery, { logonUserId, courseId });
  };

  const courseBuilderDetails = async courseId => {
    try {
      setIsLoading(true);
      const { data } = await getCourseBuilder(courseId);
      if (data) {
        setIsLoading(false);
        dispatch(courseDetails(data));
        if (mainContext.build === AppData.userBuildType) {
          const checkProfile = location.pathname === '/profile';
          if (checkProfile) {
            const { courseId } = renderState;
            history.push(delivery, { userId, courseId });
            return;
          } else {
            setCourseDetails?.(data);
            history.replace(
              `${coursetitle
                .replace(':id', courseId)
                .replace(':coursetitle', title.replaceAll(' ', '-'))}`
            );
            return;
          }
        }
        if (setShowCourseSetup) {
          dispatch(courseStepNo({ stepNo: 1 }));
          history.push(`${approvecourse}?courseReview=true`);
        }
        if (navigation) {
          history.push(createcourse);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const checkExplore = location.pathname === '/manage-course';
  const isUser = mainContext.build === AppData.userBuildType;
  const isManageCourse = location.pathname === '/manage-course';

  return (
    <Box
      pad={{ vertical: 'medium' }}
      margin={margin}
      height="383px"
      width="270px"
      align="start"
      style={{
        zIndex: 3,
        maxWidth: '270px!important',
        minHeight: '383px'
      }}>
      <Card className="course-card" elevation="large" width={width ? width : '100%'} height="383px">
        <ImageTopWrapper>
          <LogoPosition>
            <Avatar size="medium" src={institutionLogo} round="full" />
          </LogoPosition>
          <Image
            style={{ width: '100%', height: '100%' }}
            fit="contain"
            src={courseThumbNailUrl ? encodeURI(courseThumbNailUrl) : CourseImage}
          />
        </ImageTopWrapper>
        <Box background={background}>
          <Box pad={{ horizontal: 'small' }} height="100px" responsive={false}>
            <Box margin={{ vertical: 'small' }}>
              <Box direction="row" align="center" justify="between">
                <Tooltip placement="topLeft" title={title}>
                  <Heading
                    style={{ fontSize: 20, lineHeight: '22px' }}
                    color="#000000"
                    level="4"
                    margin={{ bottom: '5px' }}>
                    {title?.length > 60 ? title?.substring(0, 78) + '...' : title}
                  </Heading>
                </Tooltip>
              </Box>
              <Box>
                <Text color="#4e896d" size="12px" style={{ fontWeight: '700', marginTop: '6px' }}>
                  Partner: {institutionName}
                </Text>
                {isManageCourse && (
                  <Text color="#7f7f7f" size="12px" style={{ fontWeight: '700', marginTop: '6px' }}>
                    status:{' '}
                    <Text color="#0966b8" size="12px">
                      {renderState?.status}
                    </Text>
                  </Text>
                )}
                {!isUser && (
                  <Text color="#7f7f7f" size="12px" style={{ fontWeight: '700', marginTop: '6px' }}>
                    Start date:{' '}
                    <Text color="#0966b8" size="12px">
                      {moment(renderState?.startDate).format('LL')}
                    </Text>
                  </Text>
                )}
              </Box>
            </Box>

            {/* <DescriptionWrapper>{description}</DescriptionWrapper> */}
            <DivProgress>{dashboard && <Progress percent={percentage} size="small" />}</DivProgress>
          </Box>
          <CardFooter background="none">
            <Box
              width="full"
              wrap={true}
              gap={'small'}
              pad={{ horizontal: 'small', bottom: 'small' }}
              direction={isMobile(size) ? 'row' : 'row'}
              align={isMobile(size) ? 'start' : 'center'}
              justify="between">
              <Text color="#4e896d" size="medium" style={{ fontWeight: '700' }}>
                {price ? currencyFormatter('NGN', 'en-NG', price) : 'Free'}
              </Text>
              {/* <Box
                margin={{ bottom: isMobile(size) ? 'small' : 'none' }}
                direction={isMobile(size) ? 'column' : 'row'}
                align="center"
                gap="xsmall">
                <Text style={{ fontSize: 9 }} color="#7e7e7e">
                  {!careerRelated
                    ? `${modules} ${modules > 1 ? 'Modules' : 'Module'}`
                    : `${course} Courses Available`}
                </Text>
                {!careerRelated && !isMobile(size) && (
                  <Box width="5px" height="5px" background="#7e7e7e" round="full"></Box>
                )}
                {!careerRelated && (
                  <Text style={{ fontSize: 9 }} color="#7e7e7e">
                    {duration}
                  </Text>
                )}
                {!careerRelated && !isMobile(size) && (
                  <Box width="5px" height="5px" background="#7e7e7e" round="full"></Box>
                )}
                {!careerRelated && (
                  <Text style={{ fontSize: 9 }} color="#7e7e7e">
                    {level} Level
                  </Text>
                )}
              </Box> */}

              <Button
                size="small"
                alignSelf="start"
                style={{
                  borderRadius: '20px',
                  color: isReview || fromSearchNav ? '#fff' : '#000000'
                }}
                label={
                  dashboard
                    ? 'Resume'
                    : mainContext.build === AppData.userBuildType || checkExplore
                    ? 'Explore'
                    : isReview
                    ? 'Review Course'
                    : isPrice
                    ? 'View Course'
                    : status === 'draft'
                    ? 'Continue'
                    : 'Published'
                }
                primary={isPrice || status === 'released'}
                onClick={() => {
                  dashboard
                    ? handleResume()
                    : checkExplore
                    ? history.push(explorecourse, [renderState])
                    : courseBuilderDetails(courseId);
                }}
              />
            </Box>
          </CardFooter>
        </Box>
      </Card>
      <PageLoader isLoading={isLoading} fullHeight fullWidth />
    </Box>
  );
};
export default CourseCard;

const LogoPosition = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;
const ImageTopWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 192px;
`;

const DescriptionWrapper = styled(Typography.Paragraph)`
  line-height: 20px;
  font-size: 14px;
  text-align: justify;
  width: 100%;
  height: 60px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const DivProgress = styled.div`
  width: 100%;
  margin-bottom: 0.5em;
  height: 22px;
`;
