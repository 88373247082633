import React from 'react';
import { Box, Paragraph } from 'grommet';
import { Typography, Row, Col } from 'antd';
import styled from 'styled-components';
import { RiFacebookFill, RiLinkedinFill, RiChat4Fill } from 'react-icons/ri';

import SkilldormLogo from 'assets/images/landing page Assets/Home landing page/skilldormLogo.png';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const Footer = props => {
  return (
    <Container>
      <BottomRow gutter={40} style={{}}>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <img
            src={SkilldormLogo}
            alt="skilldorm logo"
            style={{ height: '3.2rem', marginTop: '.9rem' }}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <BottomText style={{ margin: '1.9rem 0 .5em 0', display: 'block', textAlign: 'center' }}>
            &#169;{` ${new Date().getFullYear()} SkillDorm. All rights reserved`}
          </BottomText>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Box direction="row" style={{ float: 'right' }} margin={{ top: '1.8rem' }}>
            <Paragraph size="xlarge" margin={{ right: 'medium' }}>
              <a
                style={{ color: '#fff' }}
                href="https://web.facebook.com/skilldorm"
                rel="noreferrer"
                target="_blank">
                {' '}
                <RiFacebookFill style={{ fontSize: '.8em' }} />
              </a>
            </Paragraph>

            <Paragraph size="xlarge" margin={{ right: 'medium' }}>
              <a
                style={{ color: '#fff' }}
                href="https://www.linkedin.com/company/skilldorm"
                rel="noreferrer"
                target="_blank">
                <RiLinkedinFill style={{ fontSize: '.8em' }} />
              </a>
            </Paragraph>
            <Paragraph size="xlarge" margin={{ right: 'medium' }}>
              <a style={{ color: '#fff' }} href="mailto:support@skilldorm.com">
                <RiChat4Fill style={{ fontSize: '.8em' }} />
              </a>
            </Paragraph>
          </Box>
        </Col>
      </BottomRow>
    </Container>
  );
};

Footer.propTypes = {};

export default Footer;

const Container = styled(Row)`
  position: relative;
  margin-top: auto;
  background: #000;
`;

const BottomRow = styled(Row)`
  background: #000;
  width: 100%;
  padding: 0.5em 0.5em !important;
  color: #fff !important;
  @media ${mobile} {
    padding: 1.5em 3em !important;
    width: 100vw;
  }
`;

const BottomText = styled(Typography.Text)`
  color: #fff;
  @media ${mobile} {
    font-size: 0.9em;
  }
`;
