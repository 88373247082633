import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Form, Input } from 'antd';

import { ButtonCTA } from 'components/common/ButtonCTA';
import AppText from 'components/common/AppText';
import { SurveyContext } from 'context/SurveyContext';

const { TextArea } = Input;

const CreateSurveyTitle = ({ setCreateSurvey, setShowForm, surveyData }) => {
  const [loading, setLoading] = useState();
  const { setSurvey, setIsEdit } = useContext(SurveyContext);

  const handleCancel = () => {
    setSurvey(null);
    setCreateSurvey(false);
    setIsEdit(false);
  };

  const onFinish = data => {
    setLoading(true);
    setSurvey(prev => ({ ...prev, ...data }));
    setTimeout(() => {
      setLoading(false);
      setShowForm(true);
    }, 1500);
  };

  return (
    <Container>
      <Form layout="vertical" onFinish={onFinish} id="survey-title">
        <Form.Item
          label={<AppText value="Survey Title" weight={600} color="#000" bottom="0" />}
          name="title"
          initialValue={surveyData?.title}
          rules={[
            {
              required: true,
              message: 'Please add a survey title'
            }
          ]}>
          <Input placeholder="Title of the survey" />
        </Form.Item>
        <Form.Item
          name="description"
          initialValue={surveyData?.description}
          label={<AppText value="Description" weight={600} color="#000" bottom="0" />}>
          <TextArea rows={3} placeholder="Description about the survey" />
        </Form.Item>
      </Form>
      <BtnDiv>
        <ButtonCTA
          color="#5D5D5D"
          border="1px solid #5D5D5D"
          bgColor="#fff"
          borderRadius="3px"
          width="100px"
          fontSize="16px"
          onClick={handleCancel}
          height="35px">
          Back
        </ButtonCTA>

        <InnerBtnDiv>
          <ButtonCTA
            color="#fff"
            loading={loading}
            bgColor="#0966B8"
            borderRadius="3px"
            width="107px"
            fontSize="16px"
            form="survey-title"
            htmlType="submit"
            height="35px">
            Proceed
          </ButtonCTA>
        </InnerBtnDiv>
      </BtnDiv>
    </Container>
  );
};

export default CreateSurveyTitle;

const Container = styled.div``;
const BtnDiv = styled.div`
  padding-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const InnerBtnDiv = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
