import { createUpdateRole } from 'api/mutations/Authentication';
import Modal from 'components/common/Modal';
import {
  Accordion,
  AccordionPanel,
  Box,
  CheckBoxGroup,
  RadioButtonGroup,
  Text,
  TextInput,
  ThemeContext
} from 'grommet';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showToast } from 'store/actions/toast';

const AccordionHeader = ({ text }) => {
  return (
    <Box background="light-1" pad="small">
      {text}
    </Box>
  );
};

const ManageRoleModal = ({
  onClose,
  setLoading,
  permissionMap,
  refetchAllRoles,
  selectedRoleId,
  selectedRoleType,
  selectedRoleName,
  selectedRolePermissionIds
}) => {
  const [roleType, setRoleType] = React.useState(selectedRoleType);
  const [activeIndex, setActiveIndex] = useState([0]);
  const [roleName, setRoleName] = useState(selectedRoleName || '');
  const [permissionIds, setPermissionIds] = useState(selectedRolePermissionIds || []);
  const dispatch = useDispatch();

  const createUpdateRoleHandler = async () => {
    try {
      setLoading(true);
      let payload = {
        name: roleName,
        roleType: { Rivet: 0, Institution: 1 }[roleType],
        permissionId: permissionIds
      };
      if (selectedRoleId) payload.roleId = selectedRoleId;
      const data = await createUpdateRole(payload);
      if (data.success) {
        dispatch(showToast('success', data.description));
        refetchAllRoles();
        onClose();
      } else {
        dispatch(showToast('error', data.description));
      }
    } catch (error) {
      console.log(error);
      dispatch(showToast('error', 'Failed to create role'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      onClose={onClose}
      title="Manage Roles"
      footerCustomButtonDisabled={!roleName || !permissionIds.length}
      footerCustomButtonLabel="Save"
      footerCustomButtonHandler={createUpdateRoleHandler}>
      <ThemeContext.Extend value={{ accordion: { heading: { level: '5' } } }}>
        <Box gap="medium">
          {selectedRoleId ? (
            <Text margin="none">Role Type: {selectedRoleType}</Text>
          ) : (
            <Box direction="row" gap="medium" align="center">
              <Text margin="none">Choose Role Type: </Text>
              <RadioButtonGroup
                name="roleType"
                options={['Institution', 'Rivet']}
                value={roleType}
                onChange={event => setRoleType(event.target.value)}
                style={{ flexDirection: 'row', gap: 10 }}
              />
            </Box>
          )}
          <TextInput
            placeholder="Enter Role Name"
            value={roleName}
            onChange={e => setRoleName(e.target.value)}
          />
          {roleType && (
            <Accordion multiple activeIndex={activeIndex} onActive={e => setActiveIndex(e)}>
              {[...permissionMap]
                .map(([key, value]) => ({ key, value }))
                .map(item => {
                  const options = item.value
                    .filter(x => x.userTypeName.includes(roleType))
                    .map(x => ({ label: x.name, value: x.id }));
                  if (options.length < 1) return null;
                  return (
                    <AccordionPanel key={item.key} header={<AccordionHeader text={item.key} />}>
                      <Box pad="medium">
                        <CheckBoxGroup
                          options={options}
                          value={permissionIds}
                          onChange={({ value }) => {
                            let checkedOptions = value;
                            let uncheckedOptions = options
                              .filter(option => !checkedOptions.includes(option.value))
                              .map(item => item.value);
                            setPermissionIds(state =>
                              [...new Set(state.concat(value))].filter(
                                item => !uncheckedOptions.includes(item)
                              )
                            );
                          }}
                        />
                      </Box>
                    </AccordionPanel>
                  );
                })}
            </Accordion>
          )}
        </Box>
      </ThemeContext.Extend>
    </Modal>
  );
};

export default ManageRoleModal;
