import PageHeader from 'components/common/PageHeader';
import PageLoader from 'components/common/PageLoader';
import CreateCertificateModal from 'components/modals/CreateCertificateModal';
import { Box, Button, Heading, Text } from 'grommet';
import Settings from 'pages/admin/Settings';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { View, FormAdd } from 'grommet-icons';
import { useDispatch } from 'react-redux';
import { showToast } from 'store/actions/toast';
import { searchInstitutionById, viewSignature } from 'api/queries/Institution';
import CertificateTable from 'components/tables/CertificateTable';
import { toggleSignature } from 'api/mutations/Institution';

const CertificateSettings = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedInstitutionId, setSelectedInstitutionId] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedJobTitle, setSelectedJobTitle] = useState(null);
  const [selectedSignatureUrl, setSelectedSignatureUrl] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const { isLoading, data: institutionResult, refetch: refetchInstitution } = useQuery(
    'searchInstitution',
    searchInstitutionById
  );
  const { isLoading: load, data: signatureResult, refetch: refetchSignature } = useQuery(
    ['viewSignature', selectedInstitutionId],
    viewSignature
  );

  const reset = () => {
    setSelectedInstitutionId(null);
    setSelectedName(null);
    setSelectedJobTitle(null);
    setSelectedSignatureUrl(null);
    setSelectedId(null);
  };

  const refetchInstitutionHandler = () => {
    reset();
    refetchInstitution();
  };

  const addCertificateHandler = institutionId => {
    reset();
    setShowModal(true);
    setSelectedInstitutionId(institutionId);
  };

  const editCertificateHandler = (name, jobTitle, signatureUrl, id) => {
    setSelectedName(name);
    setSelectedJobTitle(jobTitle);
    setSelectedSignatureUrl(signatureUrl);
    setSelectedId(id);
    setShowModal(true);
  };

  const [ToggleViewSignature, setViewSignature] = useState(false);
  const toggleTable = () => {
    setViewSignature(state => !state);
  };

  const toggleSignatureHandler = async (id, isActive) => {
    try {
      setLoading(true);
      const data = await toggleSignature({ id, isActive: !isActive });
      if (data.success) {
        dispatch(showToast('success', `${isActive ? 'Deactivate' : 'Activate'} Record`));
        refetchSignature();
      } else {
        dispatch(showToast('error', data.description));
      }
    } catch (error) {
      console.log(error);
      dispatch(showToast('error', `Failed to ${isActive ? 'deactivate' : 'activate'}`));
    } finally {
      setLoading(false);
    }
  };

  // console.log(dataResult);
  return (
    <Settings selectedKey="Certificate Settings">
      <PageHeader
        pageActionElement={
          <>{ToggleViewSignature && <Button primary label="Back" onClick={toggleTable} />}</>
        }
        pageDescription="Create and manage Certificate Settings"
        marginBottom="medium"
        pageTitle="Certificate Settings"
      />
      <Box>
        <Box>
          {ToggleViewSignature ? (
            <CertificateTable
              editCertificateHandler={editCertificateHandler}
              dataResult={signatureResult?.data}
              toggleSignatureHandler={toggleSignatureHandler}
            />
          ) : (
            <>
              <Box background="light-2" pad="small" direction="row" justify="between">
                <Heading margin="none" level="5">
                  Partners
                </Heading>
                <Heading margin="none" level="5">
                  Action
                </Heading>
              </Box>
              {institutionResult?.map(item => (
                <Box
                  className="certificate-table"
                  key={item.id}
                  pad="small"
                  direction="row"
                  justify="between"
                  align="center"
                  onClick={() => {}}
                  hoverIndicator={{ color: 'light-1' }}>
                  <Text style={{ fontSize: 14 }} color={item.isActive ? 'dark-2' : 'light-6'}>
                    {item.name.toUpperCase()}
                  </Text>
                  <Box direction="row" gap="small">
                    <Button
                      plain
                      icon={
                        <FormAdd
                          color={'#0966b8'}
                          size="medium"
                          style={{ border: '2px solid #0966b8', borderRadius: '50%' }}
                        />
                      }
                      onClick={() => {
                        addCertificateHandler(item.id);
                      }}
                    />
                    <Button
                      plain
                      icon={<View color="brand" size="medium" />}
                      onClick={() => {
                        if (!ToggleViewSignature) {
                          toggleTable();
                          setSelectedInstitutionId(item.id);
                        }
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </>
          )}
        </Box>
      </Box>
      {showModal && (
        <CreateCertificateModal
          onClose={() => setShowModal(false)}
          setLoading={setLoading}
          refetchInstitutionHandler={refetchInstitutionHandler}
          refetchSignature={refetchSignature}
          selectedInstitutionId={selectedInstitutionId}
          selectedName={selectedName}
          selectedJobTitle={selectedJobTitle}
          selectedSignatureUrl={selectedSignatureUrl}
          selectedId={selectedId}
        />
      )}
      <PageLoader
        fullWidth={loading}
        fullHeight={loading}
        isLoading={loading || isLoading || load}
      />
    </Settings>
  );
};

export default CertificateSettings;
