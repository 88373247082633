import React from 'react';
import { Box, Button, DropButton, Text, ThemeContext } from 'grommet';
import AddIcon from 'assets/svgs/ic_add.svg';
import VideoIcon from 'assets/svgs/ic_videocam.svg';
import ImageIcon from 'assets/svgs/ic_image.svg';
import FileIcon from 'assets/svgs/ic_file.svg';
import QuizIcon from 'assets/svgs/ic_question_answer.svg';
import IconWrapper from 'components/common/IconWrapper';
import AppData from 'AppData';

const courseItemType = {
  module: { color: '#f3f3f3', label: 'Add Course Module' },
  section: { color: '#fafafa', label: 'Add Course Section' },
  content: { color: '#f3f3f3', label: 'Add Content' }
};

const AddCourseItem = ({ itemType, onClick, addContentType, contentCountData }) => {
  const [showDrop, setShowDrop] = React.useState(false);

  return (
    <>
      {itemType === 'content' ? (
        <>
          {(contentCountData.videoCount < contentCountData.maxCount ||
            contentCountData.audioCount < contentCountData.maxCount ||
            contentCountData.imageCount < contentCountData.maxCount ||
            contentCountData.articleCount < contentCountData.maxCount ||
            contentCountData.quizCount < contentCountData.maxCount) && (
            <ThemeContext.Extend value={{ global: { drop: { zIndex: '9' } } }}>
              <DropButton
                fill="horizontal"
                size="large"
                primary
                color={courseItemType[itemType]?.color}
                style={{ color: '#acacac', zIndex: 9 }}
                label={courseItemType[itemType].label}
                icon={<IconWrapper icon={AddIcon} width="30px" height="30px" />}
                open={showDrop}
                onClick={() => {
                  setShowDrop(state => !state);
                }}
                dropAlign={{ top: 'bottom', right: 'right' }}
                dropContent={
                  <Box pad="xsmall">
                    {contentCountData.videoCount < contentCountData.maxCount &&
                      contentCountData.compressVideoCount < contentCountData.maxCount &&
                      contentCountData.quizCount < contentCountData.maxCount && (
                        <Button
                          plain
                          hoverIndicator
                          label={
                            <Box direction="row" align="center" gap="small" pad="small">
                              <IconWrapper icon={VideoIcon} width="30px" height="30px" />
                              <Text>Video</Text>
                            </Box>
                          }
                          onClick={() => {
                            addContentType(AppData.contentType.video);
                            setShowDrop(false);
                          }}
                        />
                      )}
                    {contentCountData.videoCount < contentCountData.maxCount &&
                      contentCountData.compressVideoCount < contentCountData.maxCount &&
                      contentCountData.quizCount < contentCountData.maxCount && (
                        <Button
                          plain
                          hoverIndicator
                          label={
                            <Box direction="row" align="center" gap="small" pad="small">
                              <IconWrapper icon={VideoIcon} width="30px" height="30px" />
                              <Text>Compressed Video</Text>
                            </Box>
                          }
                          onClick={() => {
                            addContentType(AppData.contentType.compressVideo);
                            setShowDrop(false);
                          }}
                        />
                      )}
                    {contentCountData.imageCount < contentCountData.maxCount && (
                      <Button
                        plain
                        hoverIndicator
                        label={
                          <Box direction="row" align="center" gap="small" pad="small">
                            <IconWrapper icon={ImageIcon} width="30px" height="30px" />
                            <Text>Image</Text>
                          </Box>
                        }
                        onClick={() => {
                          addContentType(AppData.contentType.image);
                          setShowDrop(false);
                        }}
                      />
                    )}
                    {contentCountData.articleCount < contentCountData.maxCount && (
                      <Button
                        plain
                        hoverIndicator
                        label={
                          <Box direction="row" align="center" gap="small" pad="small">
                            <IconWrapper icon={FileIcon} width="30px" height="30px" />
                            <Text>Article</Text>
                          </Box>
                        }
                        onClick={() => {
                          addContentType(AppData.contentType.article);
                          setShowDrop(false);
                        }}
                      />
                    )}
                    {contentCountData.quizCount < contentCountData.maxCount &&
                      contentCountData.videoCount < contentCountData.maxCount && (
                        <Button
                          plain
                          hoverIndicator
                          label={
                            <Box direction="row" align="center" gap="small" pad="small">
                              <IconWrapper icon={QuizIcon} width="30px" height="30px" />
                              <Text>Quiz</Text>
                            </Box>
                          }
                          onClick={() => {
                            addContentType(AppData.contentType.quiz);
                            setShowDrop(false);
                          }}
                        />
                      )}
                  </Box>
                }
              />
            </ThemeContext.Extend>
          )}
        </>
      ) : (
        <Button
          fill="horizontal"
          size="large"
          primary
          color={courseItemType[itemType]?.color}
          label={courseItemType[itemType]?.label}
          icon={<IconWrapper icon={AddIcon} width="30px" height="30px" />}
          style={{ color: '#acacac' }}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default AddCourseItem;
