import React from 'react';
import { Tabs } from 'antd';

import AppText from 'components/common/AppText';
import SurveyTable from './SurveyTable';
import NoSurvey from './NoSurvey';

const { TabPane } = Tabs;

const SurveyTabs = ({ setCreateSurvey, setAssignSurvey }) => {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab={<AppText value="Active Survey" color="#000" size="16px" bottom="0" />} key="1">
        <>
          <SurveyTable setCreateSurvey={setCreateSurvey} setAssignSurvey={setAssignSurvey} />
        </>
      </TabPane>
      <TabPane tab={<AppText value="Draft" color="#000" size="16px" bottom="0" />} key="2">
        <>
          <SurveyTable isDraft setCreateSurvey={setCreateSurvey} />
        </>
      </TabPane>
    </Tabs>
  );
};

export default SurveyTabs;
