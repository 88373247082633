import React, { useState } from 'react';
import { Box, Button, Form, Heading, ResponsiveContext } from 'grommet';
import Layout from 'components/layouts';
import PageHeader from 'components/common/PageHeader';
import Steps from 'components/extras/VerticalSteps';
import { isMobile } from 'utils';
import PasswordSettings from 'components/forms/PasswordSettings';
import { useForm } from 'react-hook-form';
import Message from 'components/common/Message';
import { changePassword, updateAdminUser } from 'api/mutations/Authentication';
import { useMutation, useQuery } from 'react-query';
import { getAdminByUserId } from 'api/queries/Authentication';
import { useSelector } from 'react-redux';
import NotificationModal from 'components/modals/NotificationModal';
import { useEffect } from 'react';
import PageLoader from 'components/common/PageLoader';
import ButtonLoader from 'components/common/ButtonLoader';
import AdminBasicForm from 'components/forms/AdminBasicForm';
import AdminPublicForm from 'components/forms/AdminPublicForm';
import MainLayout from 'components/MainLayout';

const UpdateProfile = () => {
  const [viewProfile, setViewProfile] = useState(true);
  const steps = [
    {
      title: 'Basic Info',
      description: 'Account Information',
      action: (
        <Button
          color="brand"
          onClick={() => {
            setViewProfile(!viewProfile);
          }}
          primary
          label={viewProfile ? 'Update' : 'View'}
        />
      )
    },
    { title: 'Public Profile' },
    {
      title: 'Password and Settings',
      description: 'Update your password and feel more secured anytime'
    }
  ];
  const size = React.useContext(ResponsiveContext);
  const { userId, userName } = useSelector(state => state.auth);
  const [fileList, setFileList] = React.useState(null);
  const [current, setCurrent] = React.useState(1);
  const { register, errors, handleSubmit } = useForm();
  const [basicInfoFormState, setBasicInfoFormState] = useState([]);
  const [profileFormState, setProfileFormState] = useState([]);
  const [passwordFormState, setPasswordFormState] = useState([]);
  const [changePasswordError, setChangePasswordError] = React.useState(null);
  const [profileError, setProfileError] = useState(null);
  const [imageState, setImageState] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [profileSuccessMessage, setProfileSuccessMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(null);
  const [adminUserDetails, setAdminUserDetails] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [headlineValue, setHeadlineValue] = useState('');
  const [biographyValue, setBiographyValue] = useState('');
  const [websiteValue, setWebsiteValue] = useState('');
  const [twitterValue, setTwitterValue] = useState('');
  const [youTubeValue, setYouTubeValue] = useState('');
  const [faceBookValue, setFaceBookValue] = useState('');
  const [linkedInValue, setLinkedInValue] = useState('');

  const next = () => {
    const currentState = current + 1;
    setCurrent(currentState);
  };

  const prev = () => {
    const currentState = current - 1;
    setCurrent(currentState);
  };

  // change password API Mutate

  const [changePasswordMutate] = useMutation(changePassword, {
    onSuccess: data => {
      const { description, success } = data;
      if (success) {
        setSuccessMessage(description);
      } else {
        setChangePasswordError(description);
        setIsSubmitting(false);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setChangePasswordError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        setChangePasswordError(error.message);
      }
    }
  });

  //  update Api Mutate
  const [updateMutate] = useMutation(updateAdminUser, {
    onSuccess: data => {
      const { description, success } = data;
      if (success) {
        setProfileSuccessMessage('Basic Info and Public Profile Updated Successfully');
        setIsSubmitting(false);
      } else {
        setProfileError(description);
        setIsSubmitting(false);
        console.log(description);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setProfileError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        setProfileError(error.message);
        console.log(error.message);
      }
    }
  });

  // basic info submit
  const onInfoSubmit = data => {
    if (fileList === null && adminUserDetails.profilePixUrl === null)
      setProfileError('Upload a profile image');
    setBasicInfoFormState([
      { name: ['firstName'], value: data.firstName },
      { name: ['lastName'], value: data.lastName },
      { name: ['email'], value: data.email },
      { name: ['phone'], value: data.phone },
      { name: ['address1'], value: data.address1 },
      { name: ['address2'], value: data.address2 },
      { name: ['ProfilePix'], value: fileList }
    ]);
    // console.log(data, fileList);
    // console.log(basicInfoFormState);
    setProfileError('');
    next();
  };

  // public & basic onFinish submit
  const onProfileSubmit = async data => {
    let formData = new FormData();
    let formdetails = {
      Id: adminUserDetails.id,
      CurrentUserId: 0,
      ProfileId: userId,
      UserId: userId,
      FirstName: basicInfoFormState[0]?.value,
      LastName: basicInfoFormState[1]?.value,
      Email: basicInfoFormState[2]?.value,
      PhoneNumber: basicInfoFormState[3]?.value,
      Address1: basicInfoFormState[4]?.value,
      Address2: basicInfoFormState[5]?.value,
      ProfilePix: basicInfoFormState[6]?.value,
      Headline: data.headline,
      Biography: data.biography,
      Website: data.website,
      Twitter: data.twitter,
      YouTube: data.youTube,
      FaceBook: data.faceBook,
      LinkedIn: data.linkedIn
    };
    for (let key in formdetails) {
      formData.append(key, formdetails[key]);
    }
    console.log(34, formdetails);
    console.log(33, formData);
    try {
      setProfileError(null);
      setIsSubmitting(true);
      await updateMutate(formData);
    } catch (err) {
      console.log('err', err);
    }
    console.log(data);
  };

  // handle password form change
  const handlePasswordChange = data => {
    setPasswordFormState([
      { name: ['currentPassword'], value: data.currentPassword },
      { name: ['newPassword'], value: data.newPassword },
      { name: ['userName'], value: userName }
    ]);
  };

  // get getAdminByUserId Api
  const { data: allUsers, isLoading } = useQuery(['users', userId], getAdminByUserId);
  useEffect(() => {
    if (allUsers) {
      setAdminUserDetails(allUsers.data);
    }
  }, [allUsers, userId]);

  useEffect(() => {
    if (adminUserDetails) {
      const results = {
        address1: adminUserDetails?.address1,
        address2: adminUserDetails?.address2,
        email: adminUserDetails?.user?.email,
        firstName: adminUserDetails?.user?.firstName,
        lastName: adminUserDetails?.user?.lastName,
        phone: adminUserDetails?.user?.phoneNumber
      };
      setUserDetail(results);
    }
  }, [adminUserDetails]);
  // console.log(adminUserDetails);

  // update user final submission
  const onPasswordSubmit = async data => {
    if (data.currentPassword !== '') {
      if (data.currentPassword === data.newPassword) {
        setChangePasswordError('Current password and New password can not be the same');
        return;
      } else if (data.newPassword !== data.confirmPassword) {
        setChangePasswordError('New password and Confirm password must be the same');
        return;
      }
      try {
        setChangePasswordError(null);
        setIsSubmitting(true);
        await changePasswordMutate({
          UserName: userName,
          OldPassword: passwordFormState[0].value,
          Password: passwordFormState[1].value
        });
      } catch (err) {
        console.log('err', err);
      }
      // if (isSubmitting) return;
    }
    console.log(data);
    console.log(passwordFormState);
  };

  return (
    <MainLayout isAdmin sidebar padded selectedKey="institution">
      <Box
        pad="medium"
        background="#fff"
        direction={isMobile(size) ? 'column' : 'row'}
        gap="large"
        border={isMobile(size) ? false : 'between'}
        wrap={true}>
        <Box gap="medium" margin={{ bottom: 'medium' }}>
          <Heading
            margin={{ bottom: 'xxsmall', top: 'none' }}
            level={4}
            size={isMobile(size) ? 'small' : 'medium'}
            color="#777">
            Update Profile
          </Heading>
          <Steps
            current={current}
            setCurrent={setCurrent}
            steps={steps.map(step => step.title)}
            canGoForwardInitial
          />
        </Box>
        <Box flex="grow">
          <Box margin={{ bottom: 'medium' }}>
            <PageHeader
              pageTitle={steps[current - 1].title}
              pageDescription={steps[current - 1].description}
              pageActionElement={steps[current - 1].action}
            />
            {profileError && <Message status="error" message={profileError} />}
            {changePasswordError && <Message status="error" message={changePasswordError} />}
          </Box>
          {
            {
              1: (
                <>
                  {userDetail && (
                    <AdminBasicForm
                      viewProfile={viewProfile}
                      userDetail={userDetail}
                      basicInfoFormState={basicInfoFormState}
                      onInfoSubmit={onInfoSubmit}
                      setFileList={setFileList}
                      setImageState={setImageState}
                      profilePixUrl={adminUserDetails?.profilePixUrl || imageState}
                    />
                  )}
                </>
              ),
              2: (
                <>
                  {adminUserDetails && (
                    <AdminPublicForm
                      viewProfile={viewProfile}
                      setHeadlineValue={setHeadlineValue}
                      setBiographyValue={setBiographyValue}
                      setWebsiteValue={setWebsiteValue}
                      setTwitterValue={setTwitterValue}
                      setYouTubeValue={setYouTubeValue}
                      isSubmitting={isSubmitting}
                      setFaceBookValue={setFaceBookValue}
                      setLinkedInValue={setLinkedInValue}
                      headlineValue={headlineValue}
                      biographyValue={biographyValue}
                      websiteValue={websiteValue}
                      twitterValue={twitterValue}
                      youTubeValue={youTubeValue}
                      faceBookValue={faceBookValue}
                      linkedInValue={linkedInValue}
                      onProfileSubmit={onProfileSubmit}
                      setProfileFormState={setProfileFormState}
                      profileFormState={profileFormState}
                      prev={prev}
                      adminUserDetails={adminUserDetails}
                    />
                  )}
                </>
              ),
              3: (
                <>
                  <Form
                    onChange={handlePasswordChange}
                    onSubmit={handleSubmit(onPasswordSubmit)}
                    style={{ maxWidth: 475 }}>
                    <PasswordSettings register={register} errors={errors} />
                    <Box direction="row" gap="medium" justify="end">
                      <Button
                        size="small"
                        margin={{ top: 'xsmall' }}
                        onClick={() => {
                          window.location.reload();
                        }}
                        label="Cancel"
                      />
                      <Button
                        type="submit"
                        primary
                        size="small"
                        margin={{ top: 'xsmall' }}
                        label={!isSubmitting && 'Save'}
                        icon={isSubmitting ? <ButtonLoader isSubmitting={isSubmitting} /> : null}
                      />
                    </Box>
                  </Form>
                </>
              )
            }[current]
          }
        </Box>
        {successMessage || profileSuccessMessage ? (
          <NotificationModal
            message={
              <>
                {profileSuccessMessage && profileSuccessMessage}
                <br />
                {successMessage && successMessage}
              </>
            }
            status="success"
            iconHeight="80px"
            iconWidth="80px"
            actionText={successMessage ? 'Complete' : 'Go to Password Settings'}
            actionFunc={() => {
              if (successMessage) {
                window.location.reload();
              } else {
                next();
                setProfileSuccessMessage(null);
              }
            }}
          />
        ) : null}
      </Box>
      <PageLoader isLoading={isLoading} />
    </MainLayout>
  );
};

export default UpdateProfile;
