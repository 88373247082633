import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import CustomLogoWrapper from 'components/common/CustomLogoWrapper';
import { useState } from 'react';

const InstructorsNote = ({ details, institutionEmail, institutionCode, logo }) => {
  const [readMore, setReadMore] = useState(false);

  const handleReadMore = () => {
    //  limitText
    setReadMore(state => !state);
  };
  return (
    <Box className="about-partner">
      <HeaderSubTitle level={3}>About Partner</HeaderSubTitle>
      <RateDiv>
        <CustomLogoWrapper src={logo} title={institutionEmail} subtitle={institutionCode} />
      </RateDiv>
      <Wrapper margin="20px 0px">
        <Paragraph flow={readMore ? 'unset' : 'hidden'} height={readMore ? 'unset' : '100px'}>
          {details}
        </Paragraph>

        <div>
          <ReadMoreIcon onClick={handleReadMore}>
            {readMore ? 'Read Less <<' : 'Read More >>'}
          </ReadMoreIcon>
        </div>
      </Wrapper>
    </Box>
  );
};

const ReadMoreIcon = styled.span`
  font-size: 14px;
  color: #0966b8;
  cursor: pointer;
`;

export default InstructorsNote;

const Paragraph = styled(Typography.Paragraph)`
  font-size: 16px;
  height: ${({ height }) => height};
  overflow: ${({ flow }) => flow};
  color: rgb(108, 108, 108);
  transition: all 0.3s ease-in-out;
`;
const Wrapper = styled.div`
  margin: ${({ margin }) => margin};
`;
const Box = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  ${'' /* height: 100%; */}
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 40px;
`;

const HeaderSubTitle = styled(Typography.Title)`
  margin-bottom: 0px !important;
`;

const RateDiv = styled.div`
  margin: ${({ margin }) => margin};
  display: flex;
  ${'' /* align-items: flex-end; */}
`;
