import React from 'react';
import { Box, Heading, Paragraph } from 'grommet';

const SectionHeader = ({
  title,
  color,
  subtitle,
  fontSize,
  marginTop = 'large',
  marginBottom = 'none'
}) => {
  return (
    <Box margin={{ top: marginTop, bottom: marginBottom }}>
      <Heading color={color} level={4} margin="none" style={{ fontSize: fontSize }}>
        {title}
      </Heading>
      <Paragraph margin="none" fill>
        {subtitle}
      </Paragraph>
    </Box>
  );
};

export default SectionHeader;
