// import React from 'react';
import { useSelector } from 'react-redux';

const usePermission = () => {
  const { permission } = useSelector(state => state.auth);

  /**
   * check if user has the permission key or any of the permission keys
   * @param {string | string[]} permissionKeys
   * @returns {boolean}
   */
  const checkUserPermission = permissionKeys =>
    Array.isArray(permissionKeys)
      ? permissionKeys.some(key => permission.includes(key))
      : permission.includes(permissionKeys);

  return { checkUserPermission };
};

export default usePermission;
