import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Button as AntButton, Checkbox, message } from 'antd';
import { useMutation } from 'react-query';
import { usePaystackPayment } from 'react-paystack';
import { useDispatch } from 'react-redux';

import AppText from 'components/common/AppText';
import AppData from 'AppData';
import { BeneficiaryContext } from 'context/BeneficiaryContext';
import { coursePayment, sendCourseQuantity } from 'api/mutations/Course';
import { showToast } from 'store/actions/toast';
import { useHistory } from 'react-router-dom';
import { discovery } from 'userRoutes';

const Payment = ({ user, partnerId }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const history = useHistory();
  const { beneficiaryData, quantityData } = useContext(BeneficiaryContext);
  const { userName: email, userId } = user;
  const amount = beneficiaryData.reduce((a, b) => a + b.courseAmount, 0);
  const referenceNo = new Date().getTime();

  const handleChange = e => {
    setChecked(e.target.checked);
  };

  const config = {
    reference: referenceNo,
    email,
    amount: +(amount * 100).toFixed(0),
    publicKey: 'pk_test_80b0a743ba262a93c7a0a5134e4602ac058e63e4'
    // publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
  };
  const initializePayment = usePaystackPayment(config);

  const [paymentMutate] = useMutation(coursePayment, {
    onMutate: () => setLoading(true),
    onSuccess: () => {
      setLoading(false);
      initializePayment(onSuccess);
    },
    onError: error => {
      setLoading(false);
      dispatch(showToast('error', error.message));
    }
  });

  const coursePaymentAction = async action => {
    const beneficiary = beneficiaryData.map(el => {
      delete el.id;
      return { ...el };
    });
    const data = await coursePayment({
      referenceNo,
      partnerId: partnerId.length !== 0 ? partnerId[0]?.partnerId : 1,
      courseId: 0,
      userId,
      amount,
      email,
      beneficiary,
      action,
      paymentChannel: AppData.paymentChannel
      // discountCode,
    });
    return data;
  };

  const onSuccessCallback = async () => {
    try {
      const data = await coursePaymentAction('Process');
      if (data.success) {
        message.success('Payment Succcessful');
        history.replace(discovery);
      } else {
        message.error(data.description);
      }
    } catch (error) {
      console.log(error);
      message.error('Payment Failed');
    }
  };

  const onSuccess = data => {
    // console.log(data, reference);
    onSuccessCallback();
  };

  const handlePayment = () => {
    // return console.log('key', process.env.REACT_APP_PAYSTACK_PUBLIC_KEY);
    if (
      beneficiaryData.some(beneficiary => {
        for (const key in beneficiary) {
          if (!beneficiary[key]) {
            return true;
          }
        }
        return false;
      })
    )
      return dispatch(showToast('error', 'All details of beneficiaries must be filled'));
    const beneficiary = beneficiaryData.map(el => {
      delete el.id;
      return { ...el };
    });
    const paymentData = {
      userId,
      courseId: 0,
      email,
      amount,
      partnerId: partnerId.length !== 0 ? partnerId[0]?.partnerId : 1,
      referenceNo,
      paymentChannel: AppData.paymentChannel,
      beneficiary,
      action: 'Log'
    };
    console.log('p-data', paymentData);
    paymentMutate(paymentData);
  };

  const handleSyncQuantty = async () => {
    if (quantityData.length === 0) {
      handlePayment();
    } else {
      try {
        const data = await sendCourseQuantity(quantityData);
        if (data.success) {
          handlePayment();
        }
      } catch (err) {
        dispatch(showToast('error', err.message));
      }
    }
  };

  return (
    <Container>
      <Details>
        <OrderDiv>
          <TitleDiv>
            <AppText value="ORDER SUMMARY" size={'16px'} weight={700} bottom="0" />
          </TitleDiv>
          <ContentDiv>
            <ItemDiv>
              <AppText bottom={'0'} size={'15px'} value={'Subtotal '} weight={700} />
              <AppText bottom={'0'} size={'15px'} value={`N${amount}.00`} weight={700} />
            </ItemDiv>
            <ItemDiv>
              <AppText bottom={'0'} size={'15px'} value={'Discount '} weight={700} />
              <AppText bottom={'0'} size={'15px'} value={'N0.00 '} weight={700} />
            </ItemDiv>
          </ContentDiv>
          <TotalDiv>
            <AppText bottom={'0'} size={'20px'} value={'Total '} weight={700} />
            <AppText bottom={'0'} size={'20px'} value={`N${amount}.00`} weight={700} />
          </TotalDiv>
        </OrderDiv>
        <DiscountDiv>
          <AppText value={'Got a voucher / discount code ?'} />
          <Coupon>
            <CouponInput placeholder="Enter coupon" />
            <CouponBtn>Apply</CouponBtn>
          </Coupon>
        </DiscountDiv>
      </Details>
      <CheckboxDiv>
        <Checkbox checked={checked} onChange={handleChange}>
          By Completing your purchase , you agree to this
          <a href="" target="_blank" style={{ textDecoration: 'none', color: '#0966bb' }}>
            Terms & Privacy policy
          </a>
        </Checkbox>
      </CheckboxDiv>
      <Button onClick={handleSyncQuantty} disabled={loading || !checked} loading={loading}>
        Pay Now
      </Button>
    </Container>
  );
};

export default Payment;

const Container = styled.div`
  position: sticky;
  top: 0;
`;

const Details = styled.div`
  border-radius: 5px;
  border: 1px solid #0966b8;
`;

const OrderDiv = styled.div`
  background: #fff;
  padding: 1.5rem;
`;

const TitleDiv = styled.div`
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(128, 129, 131, 0.29);
`;

const ContentDiv = styled.div`
  padding: 1.5rem 0;
  border-bottom: 1px solid rgba(128, 129, 131, 0.29);
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const ItemDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TotalDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
`;

const DiscountDiv = styled.div`
  background: #ebf5fe;
  padding: 27px 54px;
  text-align: center;
  box-sizing: border-box;
`;

const Coupon = styled.div`
  height: 52px;
  background: #fff;
`;
const CouponInput = styled.input`
  background: #fff;
  border: none;
  outline: none;
  width: 180px;
  height: 100%;
  background: none;
  &::placeholder {
    color: #00000059;
    font-size: 14px;
  }
`;
const CouponBtn = styled(AntButton)`
  border: none;
  background: #92c5f1;
  color: #fff;
  height: 100%;
  width: 104px;
  float: right;
`;

const CheckboxDiv = styled.div`
  padding: 1.5rem 0;
`;

const Button = styled(AntButton)`
  width: 100%;
  background: #0966b8;
  border-radius: 25px;
  height: 50px;
  font-size: 20px;
  color: #fff;
`;
