export const VIEW_DISCOUNT = 'VIEW_DISCOUNT';
export const CLEAR_DISCOUNT = 'CLEAR_DISCOUNT';
export const DISCOUNTID = 'DISCOUNTID';
export const SHOWDISCOUNTUPDATE = 'SHOWDISCOUNTUPDATE';

export const viewDiscount = data => {
  console.log('data', data);
  return {
    type: VIEW_DISCOUNT,
    data
  };
};
export const clearDiscount = data => ({ type: CLEAR_DISCOUNT, data });
export const discountId = data => ({ type: DISCOUNTID, data });
export const showDiscountUpdate = data => ({ type: SHOWDISCOUNTUPDATE, data });
