import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import useCurrencyFormater from 'components/hooks/useCurrencyFormater';
const RevenuePerPartnerTable = ({
  result,
  currentPage,
  pageSize,
  setCurrentPage,
  setPageSize,
  toggleExport
}) => {
  const [pagination, setPagination] = useState();
  const currencyFormatter = useCurrencyFormater();
  useEffect(() => {
    if (!toggleExport) {
      setPagination({
        current: currentPage,
        pageSize: pageSize,
        total: result?.data[0]?.totalRows
      });
    }
  }, [result, currentPage, pageSize, toggleExport]);

  const columns = [
    {
      title: 'S/NO',
      dataIndex: 'sno',
      key: 'sno'
    },
    {
      title: 'PLATFORM NAME',
      dataIndex: 'partner',
      key: 'partner'
    },
    {
      title: 'NO OF COURSE',
      dataIndex: 'noOfCourses',
      key: 'noOfCourses',
      render: text => <p style={{ color: '#1890ff' }}>{text}</p>
    },
    {
      title: 'ENROLLED USERS',
      dataIndex: 'noOfEnrolledUser',
      key: 'noOfEnrolledUser'
    },

    {
      title: 'PLATFORM REV.',
      dataIndex: 'partnerRevenue',
      key: 'partnerRevenue'
    }
  ];

  const handleTableChange = pagination => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };
  const tableData = result?.data?.map((item, i) => ({
    ...item,
    key: i,
    partnerRevenue: currencyFormatter('NGN', 'en-NG', item.partnerRevenue)
  }));

  return (
    <Table
      style={{ width: '100%', overflow: 'auto' }}
      columns={columns}
      dataSource={tableData}
      pagination={pagination}
      onChange={handleTableChange}
    />
  );
};

export default RevenuePerPartnerTable;
