import React from 'react';
import { Layout, Menu } from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { UserDashboardIcon, CourseIcon } from 'components/customIcon/CustomIcon';
import { dashboard, discovery } from 'userRoutes';

const Sidebar = props => {
  const history = useHistory();
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });

  return (
    <Wrapper
      className="aside_menu"
      collapsible={!isMobileDevice}
      collapsed={isMobileDevice ? true : props.show}
      onCollapse={() => props.setShow(!props.show)}>
      <div style={{ marginTop: 32 }}></div>
      <Menu
        className="custom_menu"
        theme="dark"
        defaultSelectedKeys={[props.selectedKey]}
        mode="inline">
        <Menu.Item
          key="dashboard"
          onClick={() => {
            history.push(dashboard);
          }}>
          <UserDashboardIcon />
          <span>Dashboard</span>
        </Menu.Item>

        <Menu.Item
          key="course"
          onClick={() => {
            if (!history.location.pathname.includes('/course')) history.push(discovery);
          }}>
          <CourseIcon />
          <span>Courses</span>
        </Menu.Item>
      </Menu>
    </Wrapper>
  );
};

Sidebar.propTypes = {
  selectedKey: PropTypes.string.isRequired
};

export default Sidebar;

const Wrapper = styled(Layout.Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed;
  background-color: #000;
  left: 0;
  margin-top: 64px;
`;
