import { AccordionPanel, Box } from 'grommet';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSectionList } from 'store/actions/video';
import styled from 'styled-components';
import { Typography } from 'antd';
import PreviewPlayListCard from './PreviewPlayListCard';

const PreviewModulePlayList = ({
  videoPlay,
  currentPlayingModule,
  videoTime,
  moduleName,
  currentModule,
  currentSection,
  item,
  itemsCompleted,
  setCurrentModule,
  setCurrentSection,
  mIndex,
  disabled,
  playing,
  mylearning,
  setVideoSrc,
  setResource
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (item) {
      const sections = item.map((section, index) => {
        return {
          section,
          index
        };
      });
      dispatch(setSectionList(sections));
    }
  }, [dispatch, item]);

  return (
    <Box
      background={disabled ? 'light-5' : 'light-3'}
      style={{ width: '100%', pointerEvents: disabled ? 'none' : 'unset' }}
      onClick={() => {
        // setCurrentModule(mIndex);
        // setCurrentSection(0);
      }}>
      <AccordionPanel
        style={{ padding: 0 }}
        label={
          <LabelTitle>
            <ModuleTitle level={5}>{moduleName}</ModuleTitle>
            {!mylearning && (
              <ModuleText>
                {itemsCompleted}/{item.length}
              </ModuleText>
            )}
          </LabelTitle>
        }>
        {item?.map((list, index) => (
          <PreviewPlayListCard
            setCurrentSection={setCurrentSection}
            key={index}
            indexNumber={index}
            currentPlayingModule={currentPlayingModule}
            currentPlayingSection={currentSection === index}
            videoPlay={videoPlay}
            videoTime={videoTime}
            playing={playing}
            playList={list}
            mylearning={mylearning}
            setVideoSrc={setVideoSrc}
            setResource={setResource}
            currentModule={currentModule}
            currentSection={currentSection}
          />
        ))}
      </AccordionPanel>
    </Box>
  );
};

const LabelTitle = styled.div`
  display: flex;
  width: 100%;
  min-width: 0;
  min-height: 0;
  padding-left: 10px;
  padding-right: 6px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const ModuleTitle = styled(Typography.Title)`
  margin: 12px 12px 12px !important;
  font-size: 16px !important;
  line-height: 22px !important;
  max-width: 384px !important;
  font-weight: 600 !important;
`;
const ModuleText = styled(Typography.Text)`
  margin: 12px 12px 12px !important;
  font-size: 16px;
`;

export default PreviewModulePlayList;
