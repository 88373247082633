import React, { useState } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';
import htmr from 'htmr';

import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const FAQ = () => {
  const [currentIndex, setCurrentIndex] = useState();
  const [activeLink, setActiveLink] = useState(0);
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const showAnswer = index => {
    currentIndex === index ? setCurrentIndex('') : setCurrentIndex(index);
  };
  const showActiveLink = index => {
    setActiveLink(index);
  };

  const faq = [
    {
      question: 'What does it take to become a SkillDorm instructor?',
      answer: `Our instructors are thought-leaders based on their expertise and dynamic 
      perspective in varying industries. They can communicate complex ideas and thoughts 
      by distilling them into compelling stories that aid learning. 
      These qualities make our instructors some of the best in the industry.`
    },
    {
      question: 'Who will view my content?',
      answer: `<p>Our platform aims to reach millions of users across Africa 
      through partnerships with various organizations such as ICSAN, RivetSoftware, 
      Inlaks, and many more. We also aim to make our content accessible in more regions across 
      multiple languages and make the Skilldorm one of the best e-learning 
      marketplace available to learners across the globe.</p>`
    },
    {
      question: 'How do new authors get started?',
      answer: ` <ul>
                <li>Click <a data-tf-popup="t1R5fOuZ"
                data-tf-hide-headers
                data-tf-iframe-props="title=My typeform"
                data-tf-medium="snippet">Teach on SkillDorm</a>.</li>
                <li>Fill the form provided with as many details as you can provide.</li>
                <li>Download the course curriculum template provided at the end of the form.</li>
                <li>Our acquisition editors will reach out to you to provide further 
                guidance on the instructor audition process.</li>
                </ul>`
    },

    {
      question: 'What kind of support does SkillDorm provide?',
      answer: ` <p>We have a team of people ready to help you through each step of the course digitization process.</p>
                <ul>
                <li>Our acquisition editors guide you through the instructor audition.</li>
               <li>Our curriculum managers and scriptwriters are your go-to resource as you create your content. </li>
                <li>Production video editors help during the video production and editing stage.</li>
                <li>Peer instructors review your content for technical accuracy.</li>
                </ul>
      `
    }
  ];

  return (
    <Container>
      <Inner>
        <Heading>{isMobile ? 'Frequently Asked Questions' : 'FAQs'}</Heading>
        <Content>
          {faq.map((faq, index) => (
            <FaqDiv>
              <QuestionDiv onClick={() => showAnswer(index)}>
                <Question>{faq.question}</Question>
                <div>
                  <IconDiv rotate={currentIndex === index}>
                    <InnerIcon fade={currentIndex === index}></InnerIcon>
                  </IconDiv>
                </div>
              </QuestionDiv>
              <Answer isCurrent={currentIndex === index}>{htmr(faq.answer)}</Answer>
            </FaqDiv>
          ))}
        </Content>
      </Inner>
    </Container>
  );
};

export default FAQ;

const Container = styled.div`
  display: flex;
  margin: auto;
  padding: 9rem 0;
  @media ${mobile} {
    padding: 5rem 1.5rem;
  }
`;

const Heading = styled(Typography.Paragraph)`
  position: relative;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  margin-bottom: 3rem !important;
  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 76px;
    width: 125px;
    height: 6px;
    background: #0966b8;
  }
  @media ${mobile} {
    text-align: left;
    font-size: 36px;
    line-height: 43.57px;
    margin-bottom: 3rem !important;
    ::before {
      top: 96px;
    }
  }
`;
const Inner = styled.div`
  display: flex;
  width: 70rem;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Content = styled.div`
  margin-top: 2rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @media ${mobile} {
    width: 100%;
    gap: 1.5rem;
  }
`;
const FaqDiv = styled.div`
  display: flex;
  padding: 1.5rem;
  border: 2px solid #6c6969;
  flex-direction: column;
  justify-content: center;

  @media ${mobile} {
    width: 100%;
    padding: 0.7rem;
  }
`;
const QuestionDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Question = styled(Typography.Paragraph)`
  margin: 0 !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  padding: 0 !important;
  @media ${mobile} {
    font-size: 14px;
    line-height: 16.94px;
  }
`;
const Answer = styled(Typography.Paragraph)`
  margin: 0 !important;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  margin-top: 17px !important;
  letter-spacing: 0em;
  max-height: ${props => (props.isCurrent ? '200px' : '0px')};
  transition: max-height 0.3s ease-in;
  overflow: hidden;
  @media ${mobile} {
    font-size: 12px;
    line-height: 14.94px;
  }
`;

const IconDiv = styled.div`
  position: relative;
  transform: ${({ rotate }) => rotate && 'rotate(90deg)'};
  height: 28px;
  width: 2px;
  margin-right: 0.7rem;
  background: #000;
  transition: transform 0.2s ease-in;
  @media ${mobile} {
    height: 14px;
    width: 1px;
  }
`;
const InnerIcon = styled.div`
  height: 1.7px;
  width: 28px;
  top: 14px;
  left: -14px;
  background: #000;
  position: absolute;
  opacity: ${({ fade }) => fade && '0'};
  transition: opacity 0.2s ease-in;
  @media ${mobile} {
    height: 1.3px;
    width: 14px;
    top: 7px;
    left: -7px;
  }
`;
