export const isMobile = size => size === 'small';

export const isTablet = size => size === 'medium';

export const isMediumDesktop = size => size === 'large';

export const isLargeDesktop = size => size === 'xlarge';

export const animate = ({ type, delay = 0, duration = 500, size = 'medium' }) => ({
  type,
  delay,
  duration,
  size
});

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    sessionStorage.removeItem('state');
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('state', serializedState);
  } catch (err) {
    console.log(err);
  }
};

export const durationInWeeks = (date2, date1) => {
  let diff = (new Date(date2).getTime() - new Date(date1).getTime()) / 1000;
  diff /= 60 * 60 * 24 * 7;
  return Math.abs(Math.round(diff));
};

export const timeFormat = timestamp => {
  const hours = Math.floor(timestamp / 60 / 60);
  const minutes = Math.floor(timestamp / 60) - hours * 60;
  const seconds = Math.floor(timestamp % 60);
  const result = minutes + '.' + seconds;
  const formatted = Math.ceil(Number(result));
  const newFormatted = Math.floor(Number(result));
  if (seconds >= 30) return formatted;
  else return newFormatted;
};

export const countdownTimer = (duration, setDisplayTimer) => {
  let timer = duration * 60;
  let minutes;
  let seconds;

  const timerInterval = setInterval(() => {
    minutes = parseInt(timer / 60, 10);
    seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    if (--timer < 0) {
      clearInterval(timerInterval);
    }

    setDisplayTimer(`${minutes}:${seconds}`);
  }, 1000);
  window.clearQuizTImer = function () {
    clearInterval(timerInterval);
  };
};

export const shuffleArray = array => {
  const arr = [...array];
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};

export const getCourseLevel = courseLevel => {
  const courseLevelObject = {
    0: 'Beginner',
    1: 'Intermediate',
    2: 'Advanced'
  };

  if (!courseLevel) return;

  return courseLevelObject[courseLevel];
};

export const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

export const checkIfCourseHasAsset = courseData => {
  if (courseData.courseModules.length === 0) return false;
  // every module must have an item to return true
  return courseData.courseModules.every(module => {
    if (module.items.length === 0) return false;
    // every module item must have an asset to return true
    return module.items.every(section => {
      if (section.courseModuleItemAssets.length === 0) return false;
      // every asset or quiz asset must have a data to return true
      return section.courseModuleItemAssets.every(asset => {
        if (asset.quiz === null) return true; // if the asset type is not quiz
        if (asset.quiz && asset.quiz.data.length === 0) return false; // if the quiz asset has no data
        return true; // fallback
      });
    });
  });
};

export const saveAs = (blob, fileName) => {
  const elem = window.document.createElement('a');
  elem.href = blob;
  elem.download = fileName;
  elem.style = 'display:none;';
  (document.body || document.documentElement).appendChild(elem);
  if (typeof elem.click === 'function') {
    elem.click();
  } else {
    elem.target = '_blank';
    elem.dispatchEvent(
      new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      })
    );
  }
  URL.revokeObjectURL(elem.href);
  elem.remove();
};

export const base64toBlob = data => {
  const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  const out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: 'application/pdf' });
};

export const timeStampFormat = val => {
  return `${val?.split('T')[0]} - ${val?.split('T')[1]?.split('.')[0]}`;
};

export const limitText = text => {
  const descriptionSubstring = text?.substring(0, 15);

  return text?.length > 15
    ? `${descriptionSubstring?.slice(0, descriptionSubstring?.lastIndexOf(' '))}...`
    : text;
};
export const limitLongText = (text, limit) => {
  const descriptionSubstring = text?.substring(0, limit);

  return text?.length > limit
    ? `${descriptionSubstring?.slice(0, descriptionSubstring?.lastIndexOf(' '))}...`
    : text;
};

export const range = (min, max, step) => {
  const newStep = step === 0 ? 1 : step;
  return Array.from({ length: (max - min) / newStep + 1 }, (_, i) => min + i * newStep);
};

export const randomInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
