import React from 'react';
import { Box, Button, Text } from 'grommet';

const SidebarButton = ({ icon, active, label, ...rest }) => (
  <Button plain {...rest}>
    {({ hover }) => (
      <Box
        direction="row"
        gap="small"
        align="center"
        style={{
          fontWeight: '400'
        }}
        background={hover ? 'status-unknown' : active ? 'status-unknown' : undefined}
        pad={{ horizontal: 'medium', vertical: 'xsmall' }}>
        {icon}
        <Text size="medium">{label}</Text>
      </Box>
    )}
  </Button>
);

export default SidebarButton;
