import { AccordionPanel } from 'grommet';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSectionList } from 'store/actions/video';
import PlayListCard from './PlayListCard';
import styled from 'styled-components';
import { Typography } from 'antd';

const ModulePlayList = ({
  videoPlay,
  currentPlayingModule,
  videoTime,
  moduleName,
  currentModule,
  currentSection,
  item,
  itemsCompleted,
  setCurrentModule,
  setCurrentSection,
  mIndex,
  disabled,
  playing,
  mylearning,
  setVideoSrc,
  setResource,
  previewMode
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (item) {
      const sections = item.map((section, index) => {
        return {
          section,
          index
        };
      });
      dispatch(setSectionList(sections));
    }
  }, [dispatch, item]);

  return (
    <AccordionPanel
      style={{ padding: '0px 12px 0px 5px' }}
      onClick={() => {
        setCurrentModule(mIndex);
        setCurrentSection(0);
      }}
      label={
        <LabelTitle>
          <TitleWrapper>
            <ModuleTitle level={5}>{moduleName}</ModuleTitle>
          </TitleWrapper>
          {!mylearning && !previewMode && (
            <ModuleText>
              {itemsCompleted}/{item.length}
            </ModuleText>
          )}
          {mylearning && (
            <ModuleText>
              {item.length} {item.length > 1 ? 'Sessions' : 'Session'}
            </ModuleText>
          )}
        </LabelTitle>
      }>
      {item?.map((list, index) => {
        return (
          <PlayListCard
            setCurrentSection={setCurrentSection}
            key={index}
            indexNumber={index}
            currentPlayingModule={currentPlayingModule}
            currentPlayingSection={currentSection === index}
            videoPlay={videoPlay}
            videoTime={videoTime}
            playing={playing}
            playList={list}
            mylearning={mylearning}
            setVideoSrc={setVideoSrc}
            setResource={setResource}
            currentModule={currentModule}
            currentSection={currentSection}
            previewMode={previewMode}
          />
        );
      })}
    </AccordionPanel>
  );
};

const LabelTitle = styled.div`
  display: flex;
  width: 100%;
  min-width: 0;
  min-height: 0;
  padding-left: 10px;
  padding-right: 6px;
  align-items: center;
  justify-content: space-between;
`;

const TitleWrapper = styled.div`
  display: flex;
  word-wrap: break-word;
`;

const ModuleTitle = styled(Typography.Title)`
  margin: 12px 12px 12px !important;
  font-size: 16px !important;
  line-height: 22px !important;
  max-width: 384px !important;
  font-weight: 600 !important;
`;
const ModuleText = styled(Typography.Text)`
  margin: 12px 12px 12px !important;
  font-size: 16px;
`;

export default ModulePlayList;
