import React from 'react';
import styled from 'styled-components';

import AppText from 'components/common/AppText';

const StatisticsCard = ({ title, value, iconBg, iconImg, borderBg }) => {
  return (
    <Container bg={borderBg}>
      <TextDiv>
        <AppText value={title} color="#000" size={'18px'} />
        <AppText value={value} color="#000" size={'24px'} weight="600" />
      </TextDiv>
      <IconDiv bg={iconBg}>
        <img src={iconImg} />
      </IconDiv>
    </Container>
  );
};

export default StatisticsCard;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0px 5px 10px 0px #f1f2fa;
  padding: 0 2rem;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 85%;
    width: 3px;
    background: ${props => props.bg};
  }
`;

const TextDiv = styled.div``;

const IconDiv = styled.div`
  height: 59px;
  width: 59px;
  border-radius: 50%;
  background: ${props => props.bg};
  display: flex;
  align-items: center;
  justify-content: center;
`;
