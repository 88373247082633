import React from 'react';
import { Box, FormField, ResponsiveContext, Text, TextArea, TextInput } from 'grommet';
import { isMobile } from 'utils';
import InputValidationLoader from './InputValidationLoader';

let formFieldStyle = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
};

let formFieldStyleMobile = {
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'flex-start'
};
const InputController = ({
  Controller,
  control,
  name,
  htmlfor,
  errors,
  controllerName,
  label,
  width = '248px',
  required,
  loader = false,
  disabled,
  marginBottom = 'small',
  otherRules,
  mobileStyle = formFieldStyleMobile,
  desktopStyle = formFieldStyle,
  placeholder,
  onChangeCallback,
  labelWidth,
  errorMessage = '',
  defaultValue = '',
  value = null,
  type = 'text',
  helpMessage,
  onBlurCallback,
  resize
}) => {
  const size = React.useContext(ResponsiveContext);

  const inputProps = ({ type, disabled, props, placeholder, onChangeCallback, onBlurCallback }) => {
    const inputPropsObj = {
      disabled,
      value: value || props.value,
      placeholder,
      onChange: type
        ? e => {
            const inputTypes = {
              text: e.target.value,
              number: e.target.value,
              textarea: e.target.value,
              url: e.target.value,
              email: e.target.value
            };
            props.onChange(inputTypes[type]);
            if (onChangeCallback) onChangeCallback(inputTypes[type]);
          }
        : null,
      onBlur: type
        ? e => {
            const inputTypes = {
              text: e.target.value,
              number: e.target.value,
              textarea: e.target.value,
              url: e.target.value,
              email: e.target.value
            };
            props.onBlur(inputTypes[type]);
            if (onBlurCallback) onBlurCallback(inputTypes[type]);
          }
        : null
    };

    return inputPropsObj;
  };

  const ControllerError = () => (
    <>
      {errors[controllerName] && (
        <Text
          color="status-critical"
          size="small"
          alignSelf={isMobile(size) ? 'start' : 'end'}
          margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
          {errors[controllerName]?.message}
        </Text>
      )}
    </>
  );

  const ControllerHelpMessage = () => (
    <>
      {helpMessage && (
        <Text
          size="small"
          alignSelf={isMobile(size) ? 'start' : 'end'}
          margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
          {helpMessage}
        </Text>
      )}
    </>
  );

  return (
    <Box margin={{ bottom: marginBottom }} style={{ position: 'relative' }}>
      {loader && <InputValidationLoader message={errors[controllerName]} />}
      <FormField
        label={label}
        name={name}
        htmlfor={htmlfor}
        margin={{ bottom: 'none' }}
        style={isMobile(size) ? mobileStyle : desktopStyle}>
        <Controller
          name={controllerName}
          control={control}
          rules={{ required: errorMessage, ...otherRules }}
          defaultValue={
            type === 'number'
              ? defaultValue === ''
                ? 0
                : defaultValue
              : type === 'textarea'
              ? defaultValue === null
                ? ''
                : defaultValue
              : type === 'text'
              ? defaultValue === null
                ? ''
                : defaultValue
              : type === 'url'
              ? defaultValue === null
                ? ''
                : defaultValue
              : type === 'email'
              ? defaultValue === null
                ? ''
                : defaultValue
              : defaultValue
          }
          render={props =>
            type !== 'textarea' ? (
              <TextInput
                type={type}
                {...inputProps({
                  type,
                  disabled,
                  props,
                  placeholder,
                  onChangeCallback,
                  onBlurCallback
                })}
                style={{ width: isMobile(size) ? '100%' : width, border: 'none' }}
              />
            ) : (
              <TextArea
                resize={resize}
                {...inputProps({
                  type,
                  disabled,
                  props,
                  placeholder,
                  onChangeCallback,
                  onBlurCallback
                })}
                style={{ width: isMobile(size) ? '100%' : width, border: 'none' }}
              />
            )
          }
        />
      </FormField>
      <ControllerHelpMessage />
      <ControllerError />
    </Box>
  );
};
export default InputController;
