import React, { useState } from 'react';
import MainLayout from 'components/MainLayout';
import { Card, Col, Row, Typography, Dropdown, Button, Menu } from 'antd';
import styled from 'styled-components';
import RevenueTable from 'components/tables/RevenueTable';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import RevenueForm from 'components/forms/RevenueForm';
import { getRevenues } from 'api/mutations/Course';
import { useMutation, useQuery } from 'react-query';
import RevenueFormularTable from 'components/tables/RevenueFormularTable';

const Revenue = () => {
  const [createRevenue, setCreateRevenue] = useState(false);
  const [revenueId, setRevenueId] = useState('');
  const [revenueName, setRevenueName] = useState('');
  const [showRevenueFormular, setShowRevenueFormular] = useState(false);
  const { isLoading, data: revenues, refetch } = useQuery('getRevenues', getRevenues);

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        3rd menu item
      </Menu.Item>
    </Menu>
  );

  const handleClick = () => {
    setCreateRevenue(prev => !prev);
  };

  return (
    <MainLayout isAdmin sidebar padded selectedKey="courseInsight">
      {!createRevenue ? (
        <Card>
          <HeaderRow gutter={16} show={!createRevenue}>
            <Col span={8}>
              <Title level={2}>Revenue Sharing</Title>
              {/* <Text>Discover your overall course analytics</Text> */}
            </Col>
            <ExportCol span={8} offset={8}>
              <DiscountBtn
                onClick={() => {
                  handleClick();
                  setShowRevenueFormular(false);
                }}>
                Create Revenue
              </DiscountBtn>
            </ExportCol>
          </HeaderRow>
          <RevenueTable
            revenueList={revenues}
            showFormular={setShowRevenueFormular}
            handleClick={handleClick}
            revenueId={setRevenueId}
            revenueName={setRevenueName}
            refetch={refetch}
          />
        </Card>
      ) : showRevenueFormular ? (
        <Card2>
          <HeaderRow>
            <Title level={3}>Revenue Sharing Formular</Title>
          </HeaderRow>
          <RevenueFormularTable
            handleClick={handleClick}
            showFormular={setShowRevenueFormular}
            revenueId={revenueId}
            revenueName={revenueName}
          />
        </Card2>
      ) : (
        <Card2>
          <HeaderRow>
            <Title level={3}>Revenue Sharing Setup</Title>
          </HeaderRow>
          <RevenueForm
            handleClick={handleClick}
            showFormular={setShowRevenueFormular}
            refetch={refetch}
          />
        </Card2>
      )}
    </MainLayout>
  );
};

const Title = styled(Typography.Title)`
  margin-bottom: 0px !important;
`;

const ExportCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const DiscountBtn = styled(Button)`
  background: #173253;
  border: 1px solid #173253;
  color: #fff;
  border-radius: 4px;
  width: 12em;
`;
const MoreBtn = styled(Button)`
  background: #4d4f5c;
  border: 1px solid #4d4f5c;
  color: #fff;
  border-radius: 4px;
  margin-left: 0.8em;
  width: 8em;
`;

const HeaderRow = styled(Row)`
  margin-bottom: 20px;
  border-bottom: ${props => (props.show ? 'none' : '1px solid #dfdfdf')};
  padding-bottom: 0.5em;
`;

const Card2 = styled(Card)`
  width: 80%;
  margin: auto;
`;

export default Revenue;
