import React, { useEffect, useRef } from 'react';
import { Box, Collapsible } from 'grommet';
import { useMutation, useQuery } from 'react-query';
import { courseEnrolledDetails } from 'api/queries/Course';
import { useState } from 'react';
import 'styles/global.css';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';
import { coursecompleted, discovery } from 'userRoutes';
import PageLoader from 'components/common/PageLoader';
import { courseUploadViewing } from 'api/mutations/Course';
import { showToast } from 'store/actions/toast';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from 'components/MainLayout';
import CBTController from 'components/utils/CBT/CBTController';
import { clearCBT, setCBT } from 'store/actions/cbt';
import { setModuleList } from 'store/actions/video';
import DeliveryTabs from 'components/utils/CourseDelivery/DeliveryTabs';
import { useMediaQuery } from 'react-responsive';
import { adminPreviewCourse } from 'api/queries/Course';
import { Button } from 'antd';
import AppData from 'AppData';
import ResourceDownloader from 'components/common/ResourceDownloader';
import DefaultPoster from 'assets/images/default-img.png';

const PreviewCourse = () => {
  const videoRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });
  const course = useSelector(state => state.course);
  const { quiz } = useSelector(state => state.cbt);
  const { moduleList } = useSelector(state => state.video);
  const [courseId, setCourseId] = React.useState();
  const [isLastSectionVideo, setIsLastSectionVideo] = React.useState(false);
  const [userId, setUserId] = React.useState();
  const { data, isLoading, refetch } = useQuery(
    ['courseEnrolledDetails', course.courseId],
    adminPreviewCourse
  );
  console.log('video', data);
  console.log('historyData', history.location.state);
  const [openNotification] = React.useState(true);
  const [videoModuleIndex, setVideoModuleIndex] = useState(0);
  const [videoSectionIndex, setVideoSectionIndex] = useState(0);
  const [videoTime, setVideoTime] = React.useState(0);
  const [activeIndex, setActiveIndex] = useState([0]);
  const [videoPlay] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [firstVideo, setFirstVideo] = React.useState(null);
  const [poster, setPoster] = useState(DefaultPoster);
  const [resource, setResource] = useState(null);

  // useEffect(() => {
  //   if (moduleList) {
  //     const playItem =
  //       moduleList?.videos[videoModuleIndex]?.items[videoSectionIndex]?.courseModuleItemAssets;
  //     if (playItem?.length === 0) {
  //       setTimeout(() => {
  //         refetch();
  //       }, 2000);
  //     }
  //   }
  // }, [moduleList]);

  useEffect(() => {
    if (course.courseThumbNailUrl !== null) setPoster(course.courseThumbNailUrl);
  }, [course]);
  const [videoSrc, setVideoSrc] = useState(null);
  const [state, setState] = useState({
    url: null,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: true
  });

  const { playing, loop } = state;

  // redirect if user has not login && setting of course and userId
  // React.useEffect(() => {
  //   if (history?.location?.state) {
  //     setCourseId(history.location.state?.courseId);
  //     setUserId(history.location.state?.userId);
  //   } else {
  //     history.push(discovery);
  //   }
  // }, [history, history.location.state]);

  // set first video on landing to this page
  React.useEffect(() => {
    if (data?.data?.videos[0]?.items[0]?.courseModuleItemAssets[0]?.url?.includes('http')) {
      setFirstVideo(data?.data?.videos[0]?.items[0]?.courseModuleItemAssets[0]?.url);
    } else {
      if (data?.data?.videos[0]?.items[0]?.courseModuleItemAssets[0]?.url) {
        let url = `http://${data?.data?.videos[0]?.items[0]?.courseModuleItemAssets[0]?.url}`;
        setFirstVideo(url);
      }
    }
  }, [data]);

  // handle duration
  const handleDuration = duration => {
    console.log('onDuration', duration);
    setVideoTime(duration);
  };
  // handle time watched
  var secondsWatched = videoRef?.current?.getCurrentTime();
  // handle play
  const handlePlay = () => {
    console.log('onPlay');
    setState({ playing: true });
  };

  // handle videoseeking
  const handleProgress = state => {
    if (!state.seeking) {
      // this.setState(state)
    }
  };

  // handle pause
  const handlePause = () => {
    console.log('onPause');
    setState({ playing: false });
  };

  // on video end
  const handleEnded = () => {
    // last item in the section
    if (data?.data?.videos[videoModuleIndex]?.items.length - 1 === videoSectionIndex) {
      setIsLastSectionVideo(true);
      let newModule = videoModuleIndex;
      // last item in the module
      if (data?.data?.videos.length - 1 === videoModuleIndex) {
        console.log('a');
      } else {
        const [playItem] = moduleList?.videos[videoModuleIndex]?.items[
          videoSectionIndex
        ]?.courseModuleItemAssets;
        if (AppData.assetType[playItem.assetType] === 'quiz') {
          handleContinue();
        } else {
          setShowModal(true);
        }
        let newSection = 0;
        console.log('b');
      }
    } else {
      console.log('b');
    }
    setTimeout(() => {
      handlePlayNext();
    }, 2000);
  };

  // sending watched data to api

  // upload watched progress function

  const checkIfMoreThanOneVideoOrQuiz = assetData =>
    assetData.courseModuleItemAssets.filter(
      asset =>
        AppData.assetType[asset.assetType] === 'video' ||
        AppData.assetType[asset.assetType] === 'quiz'
    ).length > 1;

  // clear CBT on component mount
  useEffect(() => {
    dispatch(clearCBT());
  }, [dispatch]);

  // dispatch video to store on component mount
  useEffect(() => {
    console.log('history', history.location.state);
    dispatch(
      setModuleList({
        videos: history.location.state.courseModules.map(data => ({
          ...data,
          items: data.items
            .map(assetData => {
              if (checkIfMoreThanOneVideoOrQuiz(assetData)) {
                return assetData.courseModuleItemAssets.map(item => ({
                  ...assetData,
                  courseModuleItemAssets: [item]
                }));
              }

              return [assetData];
            })
            .reduce((acc, cur) => acc.concat(...cur), [])
        }))
      })
    );
  }, [dispatch, history.location.state.courseModules]);

  // handle next section video
  const handlePlayNext = () => {
    const currentPlaying = moduleList?.videos[videoModuleIndex]?.items?.map(e => {
      const [dataVideo] = e?.courseModuleItemAssets;
      return dataVideo?.durationDisplay === videoSrc?.durationDisplay;
    });
    if (
      currentPlaying &&
      videoSectionIndex < moduleList?.videos[videoModuleIndex]?.items?.length - 1
    ) {
      setVideoSectionIndex(videoSectionIndex + 1);
    }
  };

  // continue to next module function
  const handleContinue = () => {
    setActiveIndex(state => {
      const vla = state[0];
      const sum = vla + 1;
      return [sum];
    });
    setShowModal(false);
    if (videoModuleIndex < moduleList?.videos.length - 1) {
      setVideoModuleIndex(videoModuleIndex + 1);
      setVideoSectionIndex(0);
    }
  };

  // check for playlist either quiz or video
  useEffect(() => {
    if (moduleList?.videos) {
      let [playItem] = moduleList?.videos[videoModuleIndex]?.items[
        videoSectionIndex
      ]?.courseModuleItemAssets;
      dispatch(clearCBT());

      if (AppData.assetType[playItem?.assetType] === 'quiz') {
        setVideoSrc(null);
        dispatch(setCBT(playItem));
        return;
      }
      if (
        AppData.assetType[playItem?.assetType] === 'image' ||
        AppData.assetType[playItem?.assetType] === 'article'
      ) {
        setResource({
          title: moduleList?.videos[videoModuleIndex]?.items[videoSectionIndex].title,
          url: playItem.url
        });
        return;
      }

      if (playItem?.url?.includes('http')) setVideoSrc(playItem);
      else {
        if (playItem) playItem = { ...playItem, url: 'http://' + playItem?.url };
        setVideoSrc(playItem);
      }
    }
  }, [dispatch, moduleList, videoModuleIndex, videoSectionIndex]);

  return (
    <MainLayout isVideoPage selectedKey="dashboard" sidebar={false} padded={0}>
      <Box
        style={{
          overflow: isMobileDevice ? 'auto' : 'hidden',
          height: isMobileDevice ? null : '100%'
        }}>
        <Box direction={isMobileDevice ? 'column-reverse' : 'row'} style={{ height: '100%' }}>
          <Collapsible direction="horizontal" open={openNotification}>
            <Box
              className="videoscroll"
              flex
              width="full"
              background="light-2"
              elevation="small"
              style={{
                maxWidth: isMobileDevice ? '100%' : '320px',
                minWidth: isMobileDevice ? '100%' : '320px',
                height: '100%'
              }}>
              <DeliveryTabs
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                setCurrentModule={setVideoModuleIndex}
                setCurrentSection={setVideoSectionIndex}
                currentModule={videoModuleIndex}
                videoTime={videoTime}
                playing={playing}
                videoPlay={videoPlay}
                setVideoSrc={setVideoSrc}
                setResource={setResource}
                currentSection={videoSectionIndex}
                handlePlayNext={handlePlayNext}
                previewMode
              />
            </Box>
          </Collapsible>
          <Box flex align="center" className="topwrapper">
            <Box
              className={!showModal ? 'none_d' : 'controlwrapper'}
              direction="row"
              justify="center"
              width={'100%'}
              height={'100%'}
              style={{ position: 'absolute', zIndex: 2, top: 0, right: 0 }}>
              <Box justify="center" direction="row" gap="medium" align="center">
                <Button
                  type="primary"
                  danger
                  onClick={() => setShowModal(false)}
                  style={{ width: 200 }}
                  size="large">
                  Cancel
                </Button>
                <Button size="large" onClick={handleContinue} type="primary">
                  Complete and Continue
                </Button>
              </Box>
            </Box>
            {quiz ? (
              <CBTController
                handleEnded={handleEnded}
                moduleIndex={videoModuleIndex}
                sectionIndex={videoSectionIndex}
                refetch={refetch}
              />
            ) : resource ? (
              <ResourceDownloader
                title={resource.title}
                url={resource.url}
                moduleIndex={videoModuleIndex}
                sectionIndex={videoSectionIndex}
                refetch={refetch}
              />
            ) : (
              <Box width="full" className="player-wrapper">
                <ReactPlayer
                  ref={videoRef}
                  onDuration={handleDuration}
                  className="react-player"
                  url={videoSrc?.url || firstVideo}
                  width="100%"
                  height="100%"
                  config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  onContextMenu={e => e.preventDefault()}
                  light={`'${encodeURI(poster)}'`}
                  controls
                  playing={playing}
                  loop={loop}
                  onReady={() => setState({ playing: true })}
                  onStart={() => {}}
                  onPlay={handlePlay}
                  onSeek={e => console.log('onSeek', e)}
                  onPause={handlePause}
                  onEnded={handleEnded}
                  onProgress={handleProgress}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <PageLoader isLoading={isLoading} />
    </MainLayout>
  );
};

export default PreviewCourse;
