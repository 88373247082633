import React from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'antd';

import AppText from 'components/common/AppText';
import ButtonLoader from 'components/common/ButtonLoader';

const UpdateCoursePopUp = ({
  isModalVisible,
  handleOk,
  handleCancel,
  modalTitle,
  modalFunction,
  isLoading,
  modalText
}) => {
  return (
    <Modal
      title={
        <ModalTitle>
          <AppText
            value={modalTitle}
            color="#000"
            weight={600}
            size="25px"
            family={'Assistant'}
            margin={'0 0'}
          />
        </ModalTitle>
      }
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      footer={
        <FooterDiv>
          {modalFunction ? (
            <>
              <CancelBtn onClick={handleCancel}>cancel</CancelBtn>
              <ProceedBtn onClick={handleOk}>
                {isLoading ? <ButtonLoader isSubmitting={isLoading} color="#0966b8" /> : 'Proceed'}
              </ProceedBtn>{' '}
            </>
          ) : (
            <CancelBtn onClick={handleCancel}>close</CancelBtn>
          )}
        </FooterDiv>
      }>
      <Container>
        <AppText
          value={modalText?.[0]}
          color="#5D5D5D"
          weight={400}
          size="30px"
          align={'center'}
          lineHeight="37.41px"
          family={'Assistant'}
        />
        <AppText
          value={modalText?.[1]}
          color="#5D5D5D"
          weight={400}
          size="20px"
          align={'center'}
          lineHeight="18.05px"
          family={'Assistant'}
        />
      </Container>
    </Modal>
  );
};

export default UpdateCoursePopUp;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const FooterDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 4rem;
  gap: 1rem;
`;

const CancelBtn = styled(Button)`
  background: #c4c4c4;
  height: 3rem;
  width: 150px;
`;
const ProceedBtn = styled(Button)`
  background: #0966b8;
  height: 3rem;
  width: 150px;
  color: #fff;
  border: none;
`;
