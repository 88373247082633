import React from 'react';

const useCurrencyFormater = () => {
  /**
   * params (currency, language, value)
   * i.e ('NGN','en-NG')
   * return string
   *
   * example: currencyFormatter('NGN','en-NG', 1000)
   */
  const currencyFormatter = React.useCallback(
    (currency, language, value) =>
      new Intl.NumberFormat(language, {
        style: 'currency',
        currency
      }).format(value),
    []
  );
  return currencyFormatter;
};

export default useCurrencyFormater;
