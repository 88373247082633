import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { ImageWrapper } from 'components/common/ImageWrapper';
import { ButtonCTA } from 'components/common/ButtonCTA';
import Learners from 'assets/images/landing page Assets/Home landing page/learners.png';
import { useMediaQuery } from 'react-responsive';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const LearnerSection = props => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  return (
    <LearnerSectionWrapper>
      <Col md={10} offset={!isMobile && 1}>
        <ImageWrapper width="100%" height="100%" src={Learners} preview={false} />
      </Col>
      <Col md={10} offset={!isMobile && 2}>
        <DivWrapper>
          <Col>
            <SubTitle level={4}>Join more than 7 million</SubTitle>
            <Title>Active Learners Worldwide</Title>
            <Paragraph>
              Skilldorm's ecosystem harbors a wide community of learners for networking, interaction
              and collaboration.
            </Paragraph>
            {/* <ButtonCTA type="primary" size="large" color="#2b704f">
              Read More
            </ButtonCTA> */}
          </Col>
        </DivWrapper>
      </Col>
    </LearnerSectionWrapper>
  );
};

LearnerSection.propTypes = {};

export default LearnerSection;

const LearnerSectionWrapper = styled(Row)`
  min-height: 500px;
  padding: 50px;
  background-color: #ededed;
  @media ${mobile} {
    padding: 6.5rem 1.3rem 0rem 1.3rem;
    min-height: 700px;
  }
`;

const Title = styled(Typography.Title)`
  margin-top: 0 !important;
  position: relative;
  margin-bottom: 1.8rem !important;
  &::before {
    content: '';
    top: 110%;
    left: 0%;
    height: 3px;
    width: 15%;
    color: #0966b8;
    background: #0966b8;
    position: absolute;
  }
  @media ${mobile} {
    font-size: 1.5em !important;
  }
`;

const SubTitle = styled(Typography.Title)`
  margin-left: 4px;
  @media ${mobile} {
    margin-bottom: 0.1em !important;
    font-size: 1em !important;
  }
`;

const Paragraph = styled(Typography.Paragraph)`
  margin-bottom: 3rem !important;
  @media ${mobile} {
    font-size: 0.9em !important;
  }
`;

const DivWrapper = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
`;
