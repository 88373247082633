import React from 'react';
import { Box, Nav, ResponsiveContext } from 'grommet';
import { isMobile } from 'utils';
import IconWrapper from 'components/common/IconWrapper';
import NoteIcon from 'assets/svgs/ic_notes.svg';
import MultipleIcon from 'assets/svgs/ic_multiple.svg';
import PropTypes from 'prop-types';
import LockIcon from 'assets/svgs/ic_lock.svg';
import SettingsIcon from 'assets/svgs/ic_settings.svg';
import NotificationBellIcon from 'assets/svgs/notification-bell.svg';
import SurveyIcon from 'assets/svgs/survey.svg';
import { Link } from 'react-router-dom';
import {
  certificatesettings,
  courseconfiguration,
  managesurvey,
  manageworkflow,
  notificationmanager,
  privacy,
  rolemanagement
} from 'adminRoutes';
import PageHeader from 'components/common/PageHeader';
import SidebarButton from 'components/extras/SidebarButton';
import AppData from 'AppData';
import { useSelector } from 'react-redux';
import MainLayout from 'components/MainLayout';

const Settings = ({ children, selectedKey }) => {
  const size = React.useContext(ResponsiveContext);
  const { permission, role } = useSelector(state => state.auth);

  const navItems = [
    {
      title: 'Course Configuration',
      href: courseconfiguration,
      permissions: [
        AppData.permissions.manageCourseConfiguration,
        AppData.permissions.viewCourseConfiguration
      ],
      icon: <IconWrapper icon={NoteIcon} height="18px" width="18px" />
    },
    {
      title: 'Approval Settings',
      href: manageworkflow,
      permissions: [AppData.permissions.manageworkflow, AppData.permissions.viewworkflow],
      icon: <IconWrapper icon={MultipleIcon} height="18px" width="18px" />
    },
    // {
    //   title: 'Email Management',
    //   href: emailtemplate,
    //   permissions: [AppData.permissions.manageEmailSettings, AppData.permissions.viewEmailSettings],
    //   icon: <IconWrapper icon={ChatIcon} height="18px" width="18px" />,
    //   action: ''
    // },
    {
      title: 'Privacy & Settings',
      href: privacy,
      permissions: [
        AppData.permissions.managePrivacySettings,
        AppData.permissions.viewPrivacySettings
      ],
      icon: <IconWrapper icon={LockIcon} height="18px" width="18px" />
    },
    {
      title: 'Role Management',
      href: rolemanagement,
      permissions: [AppData.permissions.manageRole, AppData.permissions.viewRole],
      icon: <IconWrapper icon={SettingsIcon} height="18px" width="18px" />
    },
    {
      title: 'Certificate Settings',
      href: certificatesettings,
      permissions: [
        AppData.permissions.manageCertificateSettings,
        AppData.permissions.viewCertificateSettings
      ],
      icon: <IconWrapper icon={SettingsIcon} height="18px" width="18px" />
    },
    {
      title: 'Notification Manager',
      href: notificationmanager,
      icon: <IconWrapper icon={NotificationBellIcon} height="18px" width="18px" />,
      role: ['SUPER ADMIN', 'RIVET ADMIN']
    },
    {
      title: 'Manage Survey',
      href: managesurvey,
      icon: <IconWrapper icon={SurveyIcon} height="18px" width="18px" />,
      role: ['SUPER ADMIN', 'RIVET ADMIN']
    }
  ];

  return (
    <MainLayout isAdmin sidebar padded={1} selectedKey="settings">
      <Box
        pad={{ left: 'medium', bottom: 'none', top: 'small' }}
        background="#fff"
        direction={isMobile(size) ? 'column' : 'row'}
        gap="large"
        border={isMobile(size) ? false : 'bottom'}
        wrap={true}>
        <PageHeader pageTitle="Configuration Settings" />
      </Box>
      <Box
        pad="medium"
        background="#fff"
        direction={isMobile(size) ? 'column' : 'row'}
        gap="xxsmall"
        border={isMobile(size) ? false : 'between'}
        wrap={true}>
        <Box gap="medium" margin={{ bottom: 'medium' }}>
          <Nav as="nav">
            {navItems.map((item, index) => {
              if (item?.role) {
                if (!item?.role?.includes(role)) {
                  return null;
                }
              }
              if (item?.permissions) {
                if (!permission.some(perm => item?.permissions?.includes(perm))) {
                  return null;
                }
              }
              return (
                <Link key={index} to={item.href}>
                  <SidebarButton
                    key={index}
                    icon={item.icon}
                    label={item.title}
                    active={item.title === selectedKey}
                  />
                </Link>
              );
            })}
          </Nav>
        </Box>
        <Box pad={{ left: 'medium' }} flex="grow">
          {children}
        </Box>
      </Box>
    </MainLayout>
  );
};

Settings.propTypes = {
  selectedKey: PropTypes.string
};

export default Settings;
