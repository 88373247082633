import React, { useState } from 'react';
import { Box, Heading, ResponsiveContext, Text } from 'grommet';
import PageHeader from 'components/common/PageHeader';
import Steps from 'components/extras/VerticalSteps';
import { animate, isMobile } from 'utils';
import ManageInstitutionUserForm from 'components/forms/ManageInstitutionUserForm';
import BasicInfoInstitutionForm from 'components/forms/BasicInfoInstitutionForm';
import NotificationModal from 'components/modals/NotificationModal';
import InstitutionFinishForm from 'components/forms/InstitutionFinishForm';
import { useMutation, useQuery } from 'react-query';
import { create } from 'api/mutations/Institution';
import { manageinstitution } from 'adminRoutes';
import { useHistory } from 'react-router-dom';
import { getCountries, getBanks } from 'api/queries/Institution';
import { getAllRoles } from 'api/queries/Authentication';
import AppData from 'AppData';
import MainLayout from 'components/MainLayout';

const steps = [
  { title: 'Basic Info' },
  { title: 'Manage Users' },
  {
    title: 'Finish'
  }
];

const institutionType = [
  { value: 1, name: 'Government ' },
  { value: 2, name: 'NonProfitOrganization' },
  { value: 3, name: 'Others' }
];

const CreateInstitution = () => {
  const { data: allRoles } = useQuery(['roles'], getAllRoles);
  const { data: countries } = useQuery(['countries'], getCountries);
  const { isLoading, data: banks } = useQuery('getBanks', getBanks);
  const [courseThumbNailUrl, setCourseThumbNailUrl] = React.useState('');
  const [certificatelogoUrl, setCertificatelogoUrl] = React.useState('');
  const [institutionBannerUrl, setInstitutionBannerUrl] = React.useState('');
  const [institutionLogoUrl, setInstitutionLogoUrl] = React.useState('');
  const [institutionLogoUrlFile, setInstitutionLogoUrlFile] = useState();
  const [certificatelogoUrlFile, setCertificatelogoUrlFile] = useState();
  const [courseThumbNailUrlFile, setCourseThumbNailUrlFile] = useState();
  const [institutionBannerUrlFile, setInstitutionBannerUrlFile] = useState();
  const size = React.useContext(ResponsiveContext);

  const [institutionFormState, setInstitutionFormState] = React.useState([]);
  const [institutionUserState, setInstitutionUserState] = React.useState([]);
  const [current, setCurrent] = React.useState(1);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [createError, setCreateError] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const history = useHistory();
  const [createMessageAnimate] = React.useState([
    animate({ type: 'fadeIn', delay: 500, duration: 500 }),
    animate({ type: 'slideUp', delay: 500, duration: 500 })
  ]);

  const next = () => {
    const currentState = current + 1;
    setCurrent(currentState);
  };

  const prev = () => {
    const currentState = current - 1;
    setCurrent(currentState);
  };

  const [createMutate, info] = useMutation(create, {
    onSuccess: data => {
      const { description, success } = data;
      if (success) {
        setSuccessMessage(description);
      } else {
        setCreateError(description);
        setIsSubmitting(false);
        console.log(description);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setCreateError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        console.log(error);
      }
    }
  });

  const onInstitutionSubmit = data => {
    if (
      institutionLogoUrlFile === undefined ||
      courseThumbNailUrlFile === undefined ||
      institutionBannerUrlFile === undefined ||
      certificatelogoUrlFile === undefined
    ) {
      setCreateError(
        'institutionLogo or courseThumbNail or institutionBanner or certificatelogo cannnot be empty'
      );
      window.scroll(0, 0);
      return;
    } else {
      setCreateError(undefined);
    }
    setInstitutionFormState([
      { name: ['institutionName'], value: data.institutionName },
      {
        name: ['institutionType'],
        value: institutionType.find(d => d.name === data.institutionType).value
      },
      { name: ['website'], value: data.website },
      { name: ['phone'], value: data.phone },
      { name: ['email'], value: data.email },
      { name: ['address'], value: data.address },
      { name: ['country'], value: countries.find(d => d.countryName === data.country).code1 },
      { name: ['code'], value: data.code },
      { name: ['accountNumber'], value: data.accountNumber },
      { name: ['bank'], value: banks.find(d => d.name === data.bank).id },
      { name: ['overView'], value: data.overView },
      { name: ['institutionLogo'], value: institutionLogoUrlFile },
      { name: ['institutionThumbnail'], value: courseThumbNailUrlFile },
      { name: ['institutionBanner'], value: institutionBannerUrlFile },
      { name: ['fullName'], value: data.fullName },
      { name: ['certificateLogo'], value: certificatelogoUrlFile }
    ]);
    next();
  };

  const onUserSubmit = data => {
    setInstitutionUserState([
      { name: ['firstName'], value: data.firstName },
      { name: ['lastName'], value: data.lastName },
      { name: ['email'], value: data.email },
      { name: ['phone'], value: data.phone },
      { name: ['userName'], value: data.userName }
    ]);
    next();
  };

  const onFinish = async () => {
    if (isSubmitting) return;
    let formData = new FormData();
    const institutionDetails = {
      InstitutionModel: {
        Name: institutionFormState[0].value,
        InstitutionType: institutionFormState[1].value,
        Website: institutionFormState[2].value,
        PhoneNumber: institutionFormState[3].value,
        Email: institutionFormState[4].value,
        Address: institutionFormState[5].value,
        Country: institutionFormState[6].value,
        Code: institutionFormState[0].value,
        AccountNumber: institutionFormState[8].value,
        BankId: institutionFormState[9].value,
        overView: institutionFormState[10].value,
        Logo: institutionFormState[11].value,
        Thumbnail: institutionFormState[12].value,
        Banner: institutionFormState[13].value,
        FullName: institutionFormState[14].value,
        CertificateLogo: institutionFormState[15].value
      },
      UserModel: {
        FirstName: institutionUserState[0].value,
        LastName: institutionUserState[1].value,
        Email: institutionUserState[2].value,
        PhoneNumber: institutionUserState[3].value,
        userName: institutionUserState[4].value,
        Roles: [AppData.ROLE.INSTITUTIONADMINID]
      }
    };
    // console.log(institutionDetails);
    for (let key in institutionDetails) {
      if (typeof institutionDetails[key] === 'object') {
        for (let subKey in institutionDetails[key])
          formData.append(`${key}.${subKey}`, institutionDetails[key][subKey]);
      } else formData.append(key, institutionDetails[key]);
    }

    try {
      setCreateError(null);
      setIsSubmitting(true);
      await createMutate(formData);
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <MainLayout isAdmin sidebar padded={1} selectedKey="institution">
      <Box
        pad="medium"
        background="#fff"
        direction={isMobile(size) ? 'column' : 'row'}
        gap="large"
        border={isMobile(size) ? false : 'between'}
        wrap={true}>
        <Box gap="medium" margin={{ bottom: 'medium' }}>
          <Heading
            margin={{ bottom: 'xxsmall', top: 'none' }}
            level={4}
            size={isMobile(size) ? 'small' : 'medium'}
            color="#777">
            Create Partner
          </Heading>
          <Steps current={current} steps={steps.map(step => step.title)} />
        </Box>
        <Box flex="grow">
          <PageHeader
            pageTitle={steps[current - 1].title}
            pageDescription={steps[current - 1].description}
          />
          {createError && (
            <Box margin={{ bottom: 'medium' }} animation={createMessageAnimate[0]}>
              <Box animation={createMessageAnimate[1]}>
                <Text color="status-critical">{createError}</Text>
              </Box>
            </Box>
          )}
          {
            {
              1: (
                <BasicInfoInstitutionForm
                  onInstitutionSubmit={onInstitutionSubmit}
                  institutionType={institutionType}
                  countries={countries}
                  banks={banks}
                  institutionFormState={institutionFormState}
                  courseThumbNailUrl={courseThumbNailUrl}
                  setCourseThumbNailUrl={setCourseThumbNailUrl}
                  institutionBannerUrl={institutionBannerUrl}
                  setInstitutionBannerUrl={setInstitutionBannerUrl}
                  institutionLogoUrl={institutionLogoUrl}
                  setInstitutionLogoUrl={setInstitutionLogoUrl}
                  setCourseThumbNailUrlFile={setCourseThumbNailUrlFile}
                  setInstitutionBannerUrlFile={setInstitutionBannerUrlFile}
                  setInstitutionLogoUrlFile={setInstitutionLogoUrlFile}
                  setCertificatelogoUrl={setCertificatelogoUrl}
                  certificatelogoUrl={certificatelogoUrl}
                  setCertificatelogoUrlFile={setCertificatelogoUrlFile}
                />
              ),
              2: (
                <ManageInstitutionUserForm
                  onUserSubmit={onUserSubmit}
                  institutionFormState={institutionFormState}
                  institutionUserState={institutionUserState}
                  prev={prev}
                />
              ),
              3: (
                <InstitutionFinishForm
                  institutionType={institutionType}
                  countries={countries}
                  allRoles={allRoles}
                  setInstitutionFormState={setInstitutionFormState}
                  institutionUserState={institutionUserState}
                  banks={banks}
                  institutionFormState={institutionFormState}
                  onFinish={onFinish}
                  prev={prev}
                  isSubmitting={isSubmitting}
                />
              )
            }[current]
          }
        </Box>
        {successMessage && (
          <NotificationModal
            message={
              <>
                Partner has been created successfully,
                <br /> A notification email has been sent to the registered email address
              </>
            }
            status="success"
            iconHeight="80px"
            iconWidth="80px"
            actionText="Complete"
            actionFunc={() => {
              history.replace(manageinstitution);
            }}
          />
        )}
      </Box>
    </MainLayout>
  );
};

export default CreateInstitution;
