import React from 'react';
import { Box, ResponsiveContext, Form, Heading, Button } from 'grommet';
import { isMobile } from 'utils';
import ImageUpload from './ImageUpload';
import { emailRegex, phoneRegex } from 'data/regex';
import { Controller, useForm } from 'react-hook-form';
import InputController from 'components/common/InputController';

const AdminBasicForm = props => {
  const { errors, handleSubmit, control } = useForm();
  const { setFileList, viewProfile, onInfoSubmit, userDetail, basicInfoFormState } = props;
  const size = React.useContext(ResponsiveContext);

  const onSubmit = values => {
    onInfoSubmit(values);
    console.log(values);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Box
        direction={isMobile(size) ? 'column' : 'row'}
        gap="medium"
        justify="between"
        margin={{ bottom: 'medium' }}>
        <Box style={{ maxWidth: 575 }}>
          <InputController
            disabled={viewProfile}
            Controller={Controller}
            controllerName="firstName"
            errors={errors}
            errorMessage="First Name is required"
            control={control}
            defaultValue={basicInfoFormState[0]?.value || userDetail?.firstName}
            required
            label="First Name"
            name="firstName"
            htmlfor="firstName"
            placeholder="First Name"
          />
          <InputController
            disabled={viewProfile}
            Controller={Controller}
            controllerName="lastName"
            errors={errors}
            errorMessage="Last Name is required"
            control={control}
            defaultValue={basicInfoFormState[1]?.value || userDetail?.lastName}
            required
            label="Last Name"
            name="lastName"
            htmlfor="lastName"
            placeholder="Last Name"
          />
          <InputController
            disabled={viewProfile}
            Controller={Controller}
            controllerName="email"
            errors={errors}
            errorMessage="Email is required"
            control={control}
            defaultValue={basicInfoFormState[2]?.value || userDetail?.email}
            otherRules={{
              pattern: {
                value: emailRegex,
                message: 'Not a valid email address'
              }
            }}
            required
            label="Email"
            name="email"
            htmlfor="email"
            placeholder="Email"
          />
          <InputController
            disabled={viewProfile}
            Controller={Controller}
            controllerName="phone"
            errors={errors}
            errorMessage="Phone is required"
            control={control}
            defaultValue={basicInfoFormState[3]?.value || userDetail?.phone}
            otherRules={{
              pattern: {
                value: phoneRegex,
                message: 'Not a valid phone number'
              }
            }}
            required
            label="Phone"
            name="phone"
            htmlfor="phone"
            placeholder="Phone"
          />
          <InputController
            disabled={viewProfile}
            Controller={Controller}
            type="textarea"
            controllerName="address1"
            errors={errors}
            errorMessage="Address 1 is required"
            control={control}
            defaultValue={basicInfoFormState[4]?.value || userDetail?.address1}
            required
            resize={false}
            label="Address 1"
            name="address1"
            htmlfor="address1"
            placeholder="Address 1"
          />
          <InputController
            disabled={viewProfile}
            Controller={Controller}
            type="textarea"
            controllerName="address2"
            errors={errors}
            control={control}
            defaultValue={basicInfoFormState[5]?.value || userDetail?.address2}
            resize={false}
            label="Address 2 (Optional)"
            name="address2"
            htmlfor="address2"
            placeholder="Address 2"
          />
        </Box>
        <Box direction="column">
          <Box margin={{ bottom: 'small' }}>
            <Heading
              // style={{ width: isMobile(size) ? '100%' : '350px' }}
              margin={{ bottom: 'xxsmall', top: 'none' }}
              level={4}
              size={isMobile(size) ? 'xsmall' : 'small'}
              color="#777">
              Change Profile Image
            </Heading>
          </Box>
          <Box margin={{ bottom: 'small' }}>
            <ImageUpload
              disabled={viewProfile}
              setImageState={props.setImageState}
              setFileList={setFileList}
              profilePixUrl={props.profilePixUrl}
              description="Upload your course image here. It must meet our course image quality standards to be
            accepted. Important guideline 750 x 422 pixels; .jpg, .jpeg, .gif or .png. No text on
            the image."
            />
          </Box>
        </Box>
      </Box>
      <Box direction="row" justify="center">
        {!viewProfile && (
          <Button type="submit" primary size="medium" margin={{ top: 'xsmall' }} label="Next" />
        )}
      </Box>
    </Form>
  );
};

export default AdminBasicForm;
