// ⚡ THIS IS A GENERATED FILE FROM 'setupRoute.js' 
// ⚠️ DO NOT EDIT THIS FILE DIRECTLY EXCEPT YOU HAVE READ THE 'README.md' file 

import ApproveCourse from './pages/admin/ApproveCourse';
import ChangePassword from './pages/admin/ChangePassword';
import CertificateSettings from './pages/admin/configuration/CertificateSettings';
import EmailTemplate from './pages/admin/configuration/EmailTemplate';
import EditEmail from './pages/admin/configuration/manageemail/edit/[EditEmail]';
import ManageSurvey from './pages/admin/configuration/ManageSurvey';
import ManageWorkflow from './pages/admin/configuration/ManageWorkflow';
import NewEmail from './pages/admin/configuration/NewEmail';
import NotificationManager from './pages/admin/configuration/NotificationManager';
import PreviewEmail from './pages/admin/configuration/PreviewEmail';
import RoleManagement from './pages/admin/configuration/RoleManagement';
import CreateCourse from './pages/admin/CreateCourse';
import CreateInstitution from './pages/admin/CreateInstitution';
import Dashboard from './pages/admin/Dashboard';
import ViewDiscount from './pages/admin/discount/[ViewDiscount]';
import Discounts from './pages/admin/Discounts';
import ExploreCourse from './pages/admin/ExploreCourse';
import ExploreCourse2 from './pages/admin/ExploreCourse2';
import ForgetPassword from './pages/admin/ForgetPassword';
import Home from './pages/admin/Home';
import UpdateInstitution from './pages/admin/Institution/Update/[UpdateInstitution]';
import Login from './pages/admin/Login';
import ManageCourse from './pages/admin/ManageCourse';
import ManageInstitution from './pages/admin/ManageInstitution';
import ManagePartners from './pages/admin/ManagePartners';
import ManageUser from './pages/admin/ManageUser';
import NewPreviewCourse from './pages/admin/NewPreviewCourse';
import PreviewCourse from './pages/admin/PreviewCourse';
import ProfileAdmin from './pages/admin/ProfileAdmin';
import AuditTrail from './pages/admin/report/AuditTrail';
import CourseInsight from './pages/admin/report/CourseInsight';
import CourseInsightsOverview from './pages/admin/report/CourseInsightsOverview';
import CoursePayment from './pages/admin/report/CoursePayment';
import CoursePurchase from './pages/admin/report/CoursePurchase';
import ManageRegisteredUser from './pages/admin/report/ManageRegisteredUser';
import ManageEnrolledStudent from './pages/admin/report/managestudent/[ManageEnrolledStudent]';
import RevenuePerInstitution from './pages/admin/report/RevenuePerInstitution';
import RevenuePerPartner from './pages/admin/report/RevenuePerPartner';
import SurveyReport from './pages/admin/report/survey/[SurveyReport]';
import Revenue from './pages/admin/Revenue';
import CourseConfiguration from './pages/admin/settings/CourseConfiguration';
import Privacy from './pages/admin/settings/Privacy';
import Settings from './pages/admin/Settings';
import UpdateProfile from './pages/admin/UpdateProfile';

export const approvecourse = '/approve-course';
export const changepassword = '/change-password';
export const certificatesettings = '/configuration/certificate-settings';
export const emailtemplate = '/configuration/email-template';
export const editemail = '/configuration/manageemail/edit/:editemail';
export const managesurvey = '/configuration/manage-survey';
export const manageworkflow = '/configuration/manage-workflow';
export const newemail = '/configuration/new-email';
export const notificationmanager = '/configuration/notification-manager';
export const previewemail = '/configuration/preview-email';
export const rolemanagement = '/configuration/role-management';
export const createcourse = '/create-course';
export const createinstitution = '/create-institution';
export const dashboard = '/dashboard';
export const viewdiscount = '/discount/:viewdiscount';
export const discounts = '/discounts';
export const explorecourse = '/explore-course';
export const explorecourse2 = '/explore-course2';
export const forgetpassword = '/forget-password';
export const home = '/';
export const updateinstitution = '/Institution/Update/:updateinstitution';
export const login = '/login';
export const managecourse = '/manage-course';
export const manageinstitution = '/manage-institution';
export const managepartners = '/manage-partners';
export const manageuser = '/manage-user';
export const newpreviewcourse = '/new-preview-course';
export const previewcourse = '/preview-course';
export const profileadmin = '/profile-admin';
export const audittrail = '/report/audit-trail';
export const courseinsight = '/report/course-insight';
export const courseinsightsoverview = '/report/course-insights-overview';
export const coursepayment = '/report/course-payment';
export const coursepurchase = '/report/course-purchase';
export const manageregistereduser = '/report/manage-registered-user';
export const manageenrolledstudent = '/report/managestudent/:manageenrolledstudent';
export const revenueperinstitution = '/report/revenue-per-institution';
export const revenueperpartner = '/report/revenue-per-partner';
export const surveyreport = '/report/survey/:surveyreport';
export const revenue = '/revenue';
export const courseconfiguration = '/settings/course-configuration';
export const privacy = '/settings/privacy';
export const settings = '/settings';
export const updateprofile = '/update-profile';

export default [
	{ path: approvecourse, component: ApproveCourse, permssions: []},
	{ path: changepassword, component: ChangePassword, permssions: []},
	{ path: certificatesettings, component: CertificateSettings, permssions: []},
	{ path: emailtemplate, component: EmailTemplate, permssions: []},
	{ path: editemail, component: EditEmail, permssions: []},
	{ path: managesurvey, component: ManageSurvey, permssions: []},
	{ path: manageworkflow, component: ManageWorkflow, permssions: []},
	{ path: newemail, component: NewEmail, permssions: []},
	{ path: notificationmanager, component: NotificationManager, permssions: []},
	{ path: previewemail, component: PreviewEmail, permssions: []},
	{ path: rolemanagement, component: RoleManagement, permssions: []},
	{ path: createcourse, component: CreateCourse, permssions: []},
	{ path: createinstitution, component: CreateInstitution, permssions: []},
	{ path: dashboard, component: Dashboard, permssions: []},
	{ path: viewdiscount, component: ViewDiscount, permssions: []},
	{ path: discounts, component: Discounts, permssions: []},
	{ path: explorecourse, component: ExploreCourse, permssions: []},
	{ path: explorecourse2, component: ExploreCourse2, permssions: []},
	{ path: forgetpassword, component: ForgetPassword, permssions: []},
	{ path: home, component: Home, permssions: []},
	{ path: updateinstitution, component: UpdateInstitution, permssions: []},
	{ path: login, component: Login, permssions: []},
	{ path: managecourse, component: ManageCourse, permssions: []},
	{ path: manageinstitution, component: ManageInstitution, permssions: []},
	{ path: managepartners, component: ManagePartners, permssions: []},
	{ path: manageuser, component: ManageUser, permssions: []},
	{ path: newpreviewcourse, component: NewPreviewCourse, permssions: []},
	{ path: previewcourse, component: PreviewCourse, permssions: []},
	{ path: profileadmin, component: ProfileAdmin, permssions: []},
	{ path: audittrail, component: AuditTrail, permssions: []},
	{ path: courseinsight, component: CourseInsight, permssions: []},
	{ path: courseinsightsoverview, component: CourseInsightsOverview, permssions: []},
	{ path: coursepayment, component: CoursePayment, permssions: []},
	{ path: coursepurchase, component: CoursePurchase, permssions: []},
	{ path: manageregistereduser, component: ManageRegisteredUser, permssions: []},
	{ path: manageenrolledstudent, component: ManageEnrolledStudent, permssions: []},
	{ path: revenueperinstitution, component: RevenuePerInstitution, permssions: []},
	{ path: revenueperpartner, component: RevenuePerPartner, permssions: []},
	{ path: surveyreport, component: SurveyReport, permssions: []},
	{ path: revenue, component: Revenue, permssions: []},
	{ path: courseconfiguration, component: CourseConfiguration, permssions: []},
	{ path: privacy, component: Privacy, permssions: []},
	{ path: settings, component: Settings, permssions: []},
	{ path: updateprofile, component: UpdateProfile, permssions: []}
];