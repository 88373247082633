import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { manageenrolledstudent } from 'adminRoutes';
import useCurrencyFormater from 'components/hooks/useCurrencyFormater';

/**
 *
 * table is been used on the dashboard page and report
 *
 */
const CourseInsightsTable = ({
  insightResult,
  currentPage,
  pageSize,
  setCurrentPage,
  setPageSize,
  toggleExport
}) => {
  const [pagination, setPagination] = useState();
  const history = useHistory();
  const currencyFormatter = useCurrencyFormater();

  useEffect(() => {
    if (!toggleExport) {
      setPagination({
        current: currentPage,
        pageSize: pageSize,
        total: insightResult?.data[0]?.totalRows
      });
    }
  }, [insightResult, currentPage, pageSize, toggleExport]);

  const columns = [
    {
      title: 'S/NO',
      dataIndex: 'sno',
      key: 'sno'
    },
    {
      title: 'COURSE NAME',
      dataIndex: 'courseName',
      key: 'courseName',
      render: text => <p style={{ color: '#1890ff', margin: 0 }}>{text}</p>
    },
    {
      title: 'ENROLLED USERS',
      dataIndex: 'noOfEnrolledUser',
      key: 'noOfEnrolledUser'
    },
    {
      title: 'TOTAL REVENUE',
      dataIndex: 'totalRevenue',
      key: 'totalRevenue'
    },
    {
      title: 'TOTAL REVENUE',
      dataIndex: 'totalDiscount',
      key: 'totalDiscount'
    },
    {
      title: 'PARTNER REVENUE',
      dataIndex: 'institutionRevenue',
      key: 'institutionRevenue'
    },
    {
      title: 'VAT',
      dataIndex: 'totalVat',
      key: 'totalVat'
    },
    {
      title: 'GATEWAY CHARGE',
      dataIndex: 'totalGatewayCharge',
      key: 'totalGatewayCharge'
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (text, record) => (
        <Button
          onClick={() => {
            history.push(
              manageenrolledstudent.replace('/:manageenrolledstudent', `/${record.courseId}`),
              record
            );
          }}>
          View
        </Button>
      )
    }
  ];

  const handleTableChange = pagination => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };
  const tableData = insightResult?.data?.map((item, i) => ({
    ...item,
    key: i,
    institutionRevenue: currencyFormatter('NGN', 'en-NG', item.institutionRevenue),
    totalRevenue: currencyFormatter('NGN', 'en-NG', item.totalRevenue),
    totalVat: currencyFormatter('NGN', 'en-NG', item.totalVat),
    totalDiscount: currencyFormatter('NGN', 'en-NG', item.totalDiscount),
    totalGatewayCharge: currencyFormatter('NGN', 'en-NG', item.totalGatewayCharge)
  }));
  return (
    <Table
      style={{ width: '100%', overflow: 'auto' }}
      columns={columns}
      dataSource={tableData}
      pagination={pagination}
      onChange={handleTableChange}
    />
  );
};

export default CourseInsightsTable;
