import React, { useState } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Tabs } from 'antd';
import { Box, Button } from 'grommet';
import Settings from 'pages/admin/Settings';
import AddNotificationForm from 'components/forms/AddNotificationForm';
import { listInAppNotification } from 'api/queries/Notification';
import { useMutation, useQuery } from 'react-query';
import moment from 'moment';
import { toggleNotification } from 'api/mutations/Notification';
import { showToast } from 'store/actions/toast';
import { useDispatch } from 'react-redux';
import CurrentNotificationTable from 'components/tables/CurrentNotificationTable';
import PastNotificationTable from 'components/tables/PastNotificationTable';

const NotificationManager = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showNotificationForm, setShowNotificationForm] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isCreate, setIsCreate] = useState(true);
  const [initial, setInitial] = useState(null);

  const { data, refetch: refetchNotifications } = useQuery(
    ['inAppNotify', false],
    listInAppNotification
  );

  const { TabPane } = Tabs;

  // active result
  const inAppActiveData = data
    ?.filter(item => item.current || item.upComing)
    .map((notify, i) => ({
      ...notify,
      key: i,
      sNo: i + 1,
      date: `${notify.startDate.split('T')[0]} - ${notify.startDate.split('T')[1]}`
    }));

  // inactive result
  const inAppPastData = data
    ?.filter(item => item.expired)
    .map((notify, i) => ({
      ...notify,
      key: i,
      sNo: i + 1,
      detail: 'Click to read Message',
      date: `${notify.startDate.split('T')[0]} - ${notify.startDate.split('T')[1]}`
    }));

  const addNotificationHandler = () => {
    setShowNotificationForm(true);
    setIsCreate(true);
    setInitial(null);
  };

  const closeNotificationForm = () => {
    setShowNotificationForm(false);
  };

  const handleEdit = id => {
    const selectedItem = data.find(item => item.id === id);
    selectedItem['range-time-picker'] = [
      moment(selectedItem.startDate),
      moment(selectedItem.endDate)
    ];
    setInitial(selectedItem);
    setIsCreate(false);
    setShowNotificationForm(true);
  };

  /**
   * toogle Api
   */
  const [toggleNotificationMutate] = useMutation(toggleNotification, {
    onSuccess: data => {
      if (data.success) {
        dispatch(showToast('success', `Record toogle successfully`));
        refetchNotifications();
        setSelectedRecord(null);
      } else {
        dispatch(showToast('error', data.description));
      }
    },
    onError: error => {
      if (error.message === 'Network Error') {
        dispatch(
          showToast(
            'error',
            <>
              We couldn't connect to the server.
              <br />
              Check your network or contact your admin
            </>
          )
        );
      } else {
        dispatch(showToast('error', error.message));
      }
    },
    onSettled: () => {
      setIsSubmitting(false);
    }
  });

  /**
   * toogle notification handler
   */
  const handletoggle = async record => {
    try {
      const payload = {
        id: record?.id,
        isActive: record?.isActive ? false : true
      };
      setIsSubmitting(true);

      await toggleNotificationMutate(payload);
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <Settings selectedKey="Notification Manager">
      <PageHeader
        pageActionElement={
          <>
            {!showNotificationForm && (
              <Button primary label="Add Notification" onClick={addNotificationHandler} />
            )}
          </>
        }
        pageDescription="Create and manage notification"
        marginBottom="medium"
        pageTitle="Notification Manager"
      />
      {!showNotificationForm ? (
        <>
          <Box>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Current Notification" key="1">
                <CurrentNotificationTable
                  handleEdit={handleEdit}
                  selectedRecord={selectedRecord}
                  isSubmitting={isSubmitting}
                  handletoggle={handletoggle}
                  setSelectedRecord={setSelectedRecord}
                  inAppActiveData={inAppActiveData}
                />
              </TabPane>
              <TabPane tab="Past Notification" key="2">
                <PastNotificationTable inAppPastData={inAppPastData} />
              </TabPane>
            </Tabs>
          </Box>
        </>
      ) : (
        <>
          {(initial || isCreate) && (
            <AddNotificationForm
              update={initial}
              isCreate={isCreate}
              refetchNotifications={refetchNotifications}
              onCancel={closeNotificationForm}
            />
          )}
        </>
      )}
    </Settings>
  );
};

export default NotificationManager;
