import React, { useEffect, useState } from 'react';
import { Box, Button, FormField, TextInput } from 'grommet';
import AddCourseItem from 'components/utils/CourseBuilder/AddCourseItem';
import CourseSection from 'components/utils/CourseBuilder/CourseSection';
import CloseIcon from 'assets/svgs/ic_close.svg';
import IconWrapper from 'components/common/IconWrapper';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';

const CourseModule = ({
  moduleId,
  setCourseDragData,
  dragContentSourceIndex,
  moduleIndex,
  courseModule,
  setUploads,
  isReview
}) => {
  const [moduleTitle, setModuleTitle] = useState(courseModule.title || '');

  useEffect(() => {
    setCourseDragData(state => {
      const newState = { ...state };
      newState.courseModules[moduleIndex] = {
        ...newState.courseModules[moduleIndex],
        title: moduleTitle
      };
      return newState;
    });
  }, [moduleIndex, moduleTitle, setCourseDragData]);

  return (
    <Draggable isDragDisabled={isReview} draggableId={moduleId} key={moduleId} index={moduleIndex}>
      {provided => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <Box background="#f3f3f3" elevation="small" round="xsmall" pad="small" gap="medium">
            <Box direction="row" fill="horizontal" justify="between" align="center" gap="small">
              <FormField margin="none" width="250px">
                <TextInput
                  placeholder="Enter module name"
                  value={moduleTitle}
                  onChange={e => setModuleTitle(e.target.value)}
                  disabled={isReview}
                />
              </FormField>
              {!isReview && (
                <Button
                  icon={<IconWrapper icon={CloseIcon} width="20px" height="20px" />}
                  onClick={() => {
                    setCourseDragData(state => {
                      const courseModules = [...state.courseModules];
                      courseModules.splice(moduleIndex, 1);
                      const newState = {
                        ...state,
                        courseModules
                      };
                      return newState;
                    });
                  }}
                />
              )}
            </Box>
            <Droppable
              droppableId={courseModule.id}
              key={courseModule.id}
              type={`section-${moduleIndex}`}>
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <Box gap="medium">
                    {courseModule.items.map((section, index) => {
                      return (
                        <CourseSection
                          key={section.id}
                          moduleIndex={moduleIndex}
                          sectionId={section.id}
                          section={section}
                          setCourseDragData={setCourseDragData}
                          dragContentSourceIndex={dragContentSourceIndex}
                          sectionIndex={index}
                          setUploads={setUploads}
                          isReview={isReview}
                        />
                      );
                    })}
                  </Box>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {!isReview && (
              <AddCourseItem
                itemType="section"
                onClick={() => {
                  setCourseDragData(state => {
                    const courseModules = [...state.courseModules];
                    courseModules[moduleIndex].items.push({
                      id: uuidv4(),
                      title: '',
                      courseModuleItemAssets: []
                    });
                    const newState = {
                      ...state,
                      courseModules
                    };
                    return newState;
                  });
                }}
              />
            )}
          </Box>
        </div>
      )}
    </Draggable>
  );
};
export default CourseModule;
