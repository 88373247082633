import React from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  CheckBox,
  Heading,
  Paragraph,
  ResponsiveContext,
  Text
} from 'grommet';
import CourseDetailsForm from 'components/forms/CourseDetailsForm';
import CourseMediaUpload from 'components/forms/CourseMediaUpload';
import { isMobile } from 'utils';
import { useQuery } from 'react-query';
import { showToast } from 'store/actions/toast';
import { useDispatch, useSelector } from 'react-redux';
import { courseOtherDetails } from 'api/mutations/Course';
import { getInstructors, language } from 'api/queries/Course';
import PageLoader from 'components/common/PageLoader';
import AppData from 'AppData';
import { courseAdditionalInfo, courseStepNo } from 'store/actions/course';

const CourseAdditionalInfoForm = ({ isReview, setCurrent, defaultCourseValue }) => {
  const dispatch = useDispatch();
  const size = React.useContext(ResponsiveContext);
  const { institutionId } = useSelector(state => state.auth);
  const course = useSelector(state => state.course);
  const { isLoading, isError, data: languageData, error } = useQuery('language', language);
  const { data: instructorData, refetch: refetchInstructors } = useQuery(
    ['instructors', course.institutionId],
    getInstructors
  );
  const [errors, setErrors] = React.useState(null);
  const [headLine, setHeadLine] = React.useState(defaultCourseValue?.headLine || '');
  const [overView, setOverView] = React.useState(defaultCourseValue?.overView || '');
  const [courseLevel, setCourseLevel] = React.useState(defaultCourseValue?.courseLevel || '');
  const [languageId, setLanguageId] = React.useState(defaultCourseValue?.languageId || '');
  const [objectives, setObjectives] = React.useState(defaultCourseValue?.objectives || '');
  const [effort, setEffort] = React.useState(defaultCourseValue?.effort || '');
  const [instructorName, setInstructorName] = React.useState(
    defaultCourseValue?.instructorName || ''
  );
  const [courseThumbNailUrl, setCourseThumbNailUrl] = React.useState(
    defaultCourseValue?.courseThumbNailUrl || ''
  );
  const [courseBannerUrl, setCourseBannerUrl] = React.useState(
    defaultCourseValue?.courseBannerUrl || ''
  );
  const [coursePromotionalVideoUrl, setCoursePromotionalVideoUrl] = React.useState(
    defaultCourseValue?.coursePromotionalVideoUrl || ''
  );
  const [promote, setPromote] = React.useState(defaultCourseValue?.promote || false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onSubmit = async saveDraft => {
    const errorsObj = {
      headLine: headLine === '' ? 'Headline is required' : null,
      overView: overView === '' ? 'Overview is required' : null,
      courseLevel: courseLevel === '' ? 'Course level is required' : null,
      languageId: languageId === '' ? 'Language is required' : null,
      objectives: objectives === '' ? 'Objectives is required' : null,
      effort: effort === '' ? 'Effort is required' : null,
      instructorName: instructorName === '' ? 'Instructor name is required' : null,
      courseThumbNailUrl: courseThumbNailUrl === '' ? 'Course thumbnail is required' : null,
      courseBannerUrl: courseBannerUrl === '' ? 'Course banner is required' : null
    };
    setErrors(errorsObj);

    if (!Object.values(errorsObj).every(item => item === null)) return;

    if (isReview) return dispatch(courseStepNo({ stepNo: 3 }));

    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      const payload = {
        courseId: course.courseId,
        institutionId: isReview ? course.institutionId : institutionId,
        headLine,
        overView,
        courseLevel,
        languageId,
        objectives,
        effort,
        instructorName,
        courseThumbNail: courseThumbNailUrl,
        courseBanner: courseBannerUrl,
        coursePromotionalVideo: coursePromotionalVideoUrl,
        promote,
        stepNo: AppData.courseAdditionalInfoStep
      };
      const data = await courseOtherDetails(payload);
      if (data.success) {
        setIsSubmitting(false);
        if (!isReview) {
          dispatch(showToast('success', 'Course additional information added successfully'));
        }
        dispatch(
          courseAdditionalInfo({
            ...payload,
            courseThumbNailUrl: data.data.courseThumbNailUrl,
            courseBannerUrl: data.data.courseBannerUrl,
            coursePromotionalVideoUrl: data.data.coursePromotionalVideoUrl
          })
        );
        if (!saveDraft) setCurrent(state => state + 1);
      } else {
        dispatch(showToast('error', data.description));
      }
      refetchInstructors();
    } catch (error) {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        dispatch(
          showToast(
            'error',
            <>
              We couldn't connect to the server.
              <br />
              Check your network or contact your admin
            </>
          )
        );
      } else {
        dispatch(showToast('error', error.message));
      }
    }
  };

  if (isError) return <span>Error: {error.message}</span>;

  return (
    <Box gap="large" margin={{ top: 'small' }}>
      <Card>
        <CardHeader border="bottom">
          <Heading margin="medium" level={4} size="small" color="#777">
            Course Details
          </Heading>
        </CardHeader>
        <CardBody pad="medium">
          <Box>
            <CourseDetailsForm
              languageData={languageData}
              instructorData={instructorData}
              headLine={headLine}
              setHeadLine={setHeadLine}
              overView={overView}
              setOverView={setOverView}
              courseLevel={courseLevel}
              setCourseLevel={setCourseLevel}
              languageId={languageId}
              setLanguageId={setLanguageId}
              objectives={objectives}
              setObjectives={setObjectives}
              effort={effort}
              setEffort={setEffort}
              instructorName={instructorName}
              setInstructorName={setInstructorName}
              errors={errors}
              isReview={isReview}
            />
          </Box>
        </CardBody>
      </Card>
      <Card>
        <CardHeader border="bottom">
          <Heading margin="medium" level={4} size="small" color="#777">
            Course Landing Page
          </Heading>
        </CardHeader>
        <CardBody pad="medium">
          <Box gap="large">
            <CourseMediaUpload
              title="Course Thumbnail"
              description="Upload your course image here. It must meet our course image quality standards to be
            accepted. Important guideline: 1125 by 796 pixels; .jpg, .jpeg, .gif or .png. No text on
            the image."
              courseThumbNailUrl={encodeURI(courseThumbNailUrl)}
              setCourseThumbNailUrl={setCourseThumbNailUrl}
              errorMessage={errors?.courseThumbNailUrl}
              isReview={isReview}
            />
            <CourseMediaUpload
              title="Course Banner"
              description="Upload your course image here. It must meet our course image quality standards to be
            accepted. Important guideline: 1024 by 498 pixels; .jpg, .jpeg, .gif or .png. No text on
            the image."
              courseBannerUrl={encodeURI(courseBannerUrl)}
              setCourseBannerUrl={setCourseBannerUrl}
              errorMessage={errors?.courseBannerUrl}
              isReview={isReview}
            />
          </Box>
        </CardBody>
      </Card>
      <Card>
        <CardHeader border="bottom">
          <Heading margin="medium" level={4} size="small" color="#777">
            Course Promotion
          </Heading>
        </CardHeader>
        <CardBody pad="medium">
          <Box>
            <CourseMediaUpload
              mediaType="video"
              title="Promotional Video"
              description="Students who watch a well-made promo video are 5x more likely to enroll in your course. We've seen that statistics go up to 10x for exceptionally awesome videos."
              coursePromotionalVideoUrl={coursePromotionalVideoUrl}
              setCoursePromotionalVideoUrl={setCoursePromotionalVideoUrl}
              isReview={isReview}
            />
            <Box>
              <Box direction="row" gap="medium" justify="between">
                <Box>
                  <Paragraph style={{ fontWeight: 600 }}>Promote Course</Paragraph>
                  <Text
                    size="small"
                    style={{ width: isMobile(size) ? 250 : 300 }}
                    margin={{ bottom: 'small' }}>
                    By activating this feature, your course will be visible on our featured courses
                    section on the student's landing page.
                  </Text>
                </Box>
                <Box
                  flex="grow"
                  justify="center"
                  align="center"
                  style={{ minHeight: 200 }}
                  width={isMobile(size) ? 'fill' : 'small'}>
                  <CheckBox
                    toggle
                    checked={promote}
                    onChange={e => setPromote(e.target.checked)}
                    disabled={isReview}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </CardBody>
      </Card>
      <Box direction="row" gap="medium" justify="between">
        <Button secondary label="Back" onClick={() => setCurrent(state => state - 1)} />
        <Box direction="row" gap="medium" justify="end">
          {!isReview && (
            <Button
              secondary
              label="Save Draft"
              onClick={() => {
                onSubmit(true);
              }}
            />
          )}
          <Button
            primary
            label="Continue"
            onClick={() => {
              onSubmit(false);
            }}
          />
        </Box>
      </Box>
      <PageLoader isLoading={isLoading || isSubmitting} />
    </Box>
  );
};

export default CourseAdditionalInfoForm;
