import React, { useState } from 'react';
import MainLayout from 'components/MainLayout';
import styled from 'styled-components';
import { Row, Col, Typography, Button, Divider } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';
import CustomButton from 'components/common/CustomButton';
import PageTitle from 'components/common/PageTitle';
import { discounts } from 'adminRoutes';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PageLoader from 'components/common/PageLoader';
import { getDiscountId } from 'api/queries/Discount';
import { useQuery } from 'react-query';
import { showDiscountUpdate } from 'store/actions/discount';
import useCurrencyFormater from 'components/hooks/useCurrencyFormater';

const ViewDiscount = () => {
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });
  const history = useHistory();
  const dispatch = useDispatch();
  const currencyFormatter = useCurrencyFormater();

  const [paramsData, setParamsData] = useState(null);
  //const [isLoading, setIsLoading] = useState(true);
  const { discountId } = useSelector(state => state.discount);

  const { data: discount, refetch, isLoading } = useQuery(
    ['discountResult', discountId],
    getDiscountId
  );

  console.log('discountPage', discount);

  console.log(paramsData);

  const [institutions] = useState([
    'Customer Experience',
    'Digital Marketing',
    'Engineering',
    'Data Science',
    'Digital Marketing',
    'Engineering',
    'Data Science'
  ]);
  const [coursesApplied] = useState([
    'Customer Experience',
    'Digital Marketing',
    'Engineering',
    'Data Science',
    'Digital Marketing',
    'Engineering',
    'Data Science'
  ]);

  return (
    <MainLayout isAdmin sidebar padded selectedKey="discounts">
      <WrapperContainer>
        <HeaderDiv justify="space-between">
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} xxl={{ span: 6 }}>
            <HeaderText
              margin={isMobileDevice ? '0px 0px 10px' : '0px'}
              level={isMobileDevice ? 3 : 2}>
              View Discounts
            </HeaderText>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 2 }} md={{ span: 2 }} xxl={{ span: 2 }}>
            {/* <DiscountBtn bgColor="#4d4d53" color="#fff">
              Export
            </DiscountBtn> */}
          </Col>
        </HeaderDiv>
      </WrapperContainer>
      <Container>
        <SubContainer>
          <Row justify="space-between">
            <Col xs={{ span: 24 }} sm={{ span: 18 }} md={{ span: 18 }} xxl={{ span: 6 }}>
              <div>
                <Typography.Title level={isMobileDevice ? 4 : 3}>
                  ICSAN Membership Discounts
                </Typography.Title>
              </div>
              <DividerLine />
              <div>
                <Content direction={isMobileDevice ? 'column' : 'row'}>
                  <ContentText width={isMobileDevice ? '100%' : '20%'}>Discounts Name</ContentText>
                  <ContentText width={isMobileDevice ? '100%' : '70%'} strong>
                    {discount?.data.name}
                  </ContentText>
                </Content>
                <Content direction={isMobileDevice ? 'column' : 'row'}>
                  <ContentText width={isMobileDevice ? '100%' : '20%'}>Discount Code</ContentText>
                  <ContentText width={isMobileDevice ? '100%' : '70%'} strong>
                    {discount?.data.code}
                  </ContentText>
                </Content>
                <Content direction={isMobileDevice ? 'column' : 'row'}>
                  <ContentText width={isMobileDevice ? '100%' : '20%'}>Description</ContentText>
                  <ContentText width={isMobileDevice ? '100%' : '70%'} strong>
                    {discount?.data.description}
                  </ContentText>
                </Content>
                <Content direction={isMobileDevice ? 'column' : 'row'}>
                  <ContentText width={isMobileDevice ? '100%' : '20%'}>Start Date</ContentText>
                  <ContentText width={isMobileDevice ? '100%' : '70%'} strong>
                    {moment(discount?.data.startDate).format('DD-MM-YYYY')}
                  </ContentText>
                </Content>
                <Content direction={isMobileDevice ? 'column' : 'row'}>
                  <ContentText width={isMobileDevice ? '100%' : '20%'}>End Date</ContentText>
                  <ContentText width={isMobileDevice ? '100%' : '70%'} strong>
                    {moment(discount?.data.endDate).format('DD-MM-YYYY')}
                  </ContentText>
                </Content>
                <Content direction={isMobileDevice ? 'column' : 'row'}>
                  <ContentText width={isMobileDevice ? '100%' : '20%'}>Discount Type</ContentText>
                  <ContentText width={isMobileDevice ? '100%' : '70%'} strong>
                    {discount?.data.discountTypeName}
                  </ContentText>
                </Content>
                <Content direction={isMobileDevice ? 'column' : 'row'}>
                  <ContentText width={isMobileDevice ? '100%' : '20%'}>
                    Discount Charge Type
                  </ContentText>
                  <ContentText width={isMobileDevice ? '100%' : '70%'} strong>
                    {discount?.data.discountChargeTypeName}
                  </ContentText>
                </Content>
                <Content direction={isMobileDevice ? 'column' : 'row'}>
                  <ContentText width={isMobileDevice ? '100%' : '20%'}>Discount Amount</ContentText>
                  <ContentText width={isMobileDevice ? '100%' : '70%'} strong>
                    {discount?.data.discountChargeTypeName === 'Flat'
                      ? currencyFormatter('NGN', 'en-NG', discount?.data.amount)
                      : `${discount?.data.amount}%`}
                  </ContentText>
                </Content>
                <Content direction={isMobileDevice ? 'column' : 'row'}>
                  <ContentText width={isMobileDevice ? '100%' : '20%'}>Usage Limit</ContentText>
                  <ContentText width={isMobileDevice ? '100%' : '70%'} strong>
                    {discount?.data.usageLimit}
                  </ContentText>
                </Content>

                {discount?.data.allCoursesDiscount === false ? (
                  <Content direction={isMobileDevice ? 'column' : 'row'}>
                    <ContentText width={isMobileDevice ? '100%' : '20%'}>
                      Courses Applied
                    </ContentText>
                    <TagWrapper width={isMobileDevice ? '100%' : '70%'}>
                      {discount?.data.courseTitle.map((item, i) => {
                        return <TagContent key={i}>{item.title}</TagContent>;
                      })}
                    </TagWrapper>
                  </Content>
                ) : (
                  ''
                )}

                <Content direction={isMobileDevice ? 'column' : 'row'}>
                  <ContentText width={isMobileDevice ? '100%' : '20%'}>All Partners</ContentText>
                  <TagWrapper width={isMobileDevice ? '100%' : '70%'}>
                    <TagContent>{discount?.data.institution}</TagContent>
                    {/* {institutions.map((item, i) => {
                      return <TagContent key={i}>{item}</TagContent>;
                    })} */}
                  </TagWrapper>
                </Content>
              </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 4 }} md={{ span: 4 }} xxl={{ span: 4 }}>
              <BoxWrapper>
                <HeaderText color={'#11b511'} level={2}>
                  {discount?.data.usageCount}
                </HeaderText>
                <ContentText size="12px">Discounts Usage Count</ContentText>
              </BoxWrapper>
            </Col>
          </Row>
        </SubContainer>
        <Row justify="center">
          <DiscountBtn
            margin={'0px 10px 0px'}
            bgColor="#4d4d53"
            color={'#fff'}
            onClick={() => {
              dispatch(showDiscountUpdate(true));
              history.push('/discounts');
            }}>
            Update
          </DiscountBtn>
          <DiscountBtn onClick={() => history.push('/discounts')} type={'primary'}>
            Close
          </DiscountBtn>
        </Row>
        <PageLoader isLoading={isLoading} tip="Fetching Data" />
      </Container>
    </MainLayout>
  );
};

const WrapperContainer = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #dfdfdf;
  padding: 10px 10px 5px;
`;
const Container = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  background-color: #fff;
  padding: 24px;
`;
const SubContainer = styled.div`
  width: 95%;
  margin: auto;
  margin-top: 18px;
  margin-bottom: 20px;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 1px 5px 0px #afafaf;
`;

const HeaderText = styled(Typography.Title)`
  margin: ${({ margin }) => margin} !important;
  color: ${({ color }) => color} !important;
`;
const HeaderDiv = styled(Row)``;

const DiscountBtn = styled(CustomButton)`
  border-radius: 4px;
  padding: 4px 25px;
  margin: ${({ margin }) => margin};
`;
const DividerLine = styled(Divider)`
  margin-top: 0 !important;
`;

const Content = styled.div`
  display: flex;
  align-item: center;
  flex-direction: ${({ direction }) => direction};
  ${'' /* justify-content: space-between; */}
  width: 100%;
  margin-bottom: 10px;
  ${'' /* flex-wrap: wrap; */}
`;

const ContentText = styled(Typography.Text)`
  font-size: ${({ size }) => (size ? size : '14px')};
  margin-right: ${({ mRight }) => mRight};
  width: ${({ width }) => width};
`;
const TagWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  flex-wrap: wrap;
  width: ${({ width }) => width};
`;
const TagContent = styled.div`
  background-color: #eee;
  width: 32%;
  font-size: 14px;
  padding: 10px;
  text-align: center;
  margin-bottom: 10px;
`;
const BoxWrapper = styled.div`
  border: 1px solid #dfdfdf;
  padding: 5px;
  text-align: center;
`;

export default ViewDiscount;
