import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Select, Input } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import duplicateIcon from 'assets/svgs/ion_duplicate-outline.svg';
import deleteIcon from 'assets/svgs/fluent_delete-48-filled.svg';
import AppText from 'components/common/AppText';
import { SurveyContext } from 'context/SurveyContext';
import AddOption from '../createSurvey/AddOption';
import AddRating from '../createSurvey/AddRating';
import SurveySubmit from '../createSurvey/SurveySubmit';

const { Option } = Select;
const { TextArea } = Input;

export const getId = question => (question.surveyQuestionId ? 'surveyQuestionId' : 'id');
export const hasId = question => question.surveyQuestionId;

const EditSurveyForm = ({ setCreateSurvey, surveyData }) => {
  const [questions, setQuestions] = useState(surveyData.questions);
  const isMounted = useRef(false);
  const questionType = ['', 'Multiple Choice', 'FreeText', 'Rating'];

  const { setSurvey, survey, isEdit } = useContext(SurveyContext);

  useEffect(() => {
    setSurvey(prev => ({ ...surveyData, ...prev }));
  }, []);

  // useEffect(() => {
  //   console.log('survey', survey);
  // }, [survey]);

  useEffect(() => {
    if (isMounted.current) {
      setSurvey(prev => ({ ...prev, questions }));
      // console.log('questions', questions, randomInteger(3000, 10000));
    } else {
      isMounted.current = true;
    }
  }, [questions]);

  const handleChange = (value, option) => {
    if (option) {
      setQuestions(prev => {
        const existingOption = prev.find(el => el[getId(el)] == option.key);
        if (existingOption) {
          prev[prev.indexOf(existingOption)] = {
            ...existingOption,
            [getId(existingOption)]: option.key,
            questionType: value
          };
          return [...prev];
        } else {
          return [...prev, { id: option.key, questionType: value, options: null }];
        }
      });
    } else {
      value.persist();
      setQuestions(prev => {
        const existingOption = prev.find(el => el[getId(el)] == value.target.id);
        if (existingOption) {
          prev[prev.indexOf(existingOption)] = {
            ...existingOption,
            [getId(existingOption)]: value.target.id,
            question: value.target.value
          };
          return [...prev];
        } else {
          return [...prev, { id: value.target.id, question: value.target.value }];
        }
      });
    }
  };

  const isMultipleChoice = id =>
    questions.find(el => el[getId(el)] == id && el.questionType == '1');

  const isRating = id => questions.find(el => el[getId(el)] == id && el.questionType == '3');

  const handleDelete = id => {
    setQuestions(prev =>
      prev.length === 1
        ? [{ id: uuidv4(), questionType: 2 }]
        : prev.filter(question => question[getId(question)] !== id)
    );
  };

  const handleDuplicate = questionType => {
    setQuestions(prev => [...prev, { id: uuidv4(), questionType }]);
  };

  return (
    <Container>
      {questions.map((question, index) => (
        <Div key={question[getId(question)]}>
          <FormHeader>
            <AppText
              value={`Question ${index + 1}`}
              weight="500"
              size="16px"
              color="#000"
              bottom={0}
            />
            <IconDiv>
              <Img src={duplicateIcon} onClick={() => handleDuplicate(question.questionType)} />
              <Img src={deleteIcon} onClick={() => handleDelete(question[getId(question)])} />
            </IconDiv>
          </FormHeader>
          <FormContent>
            <Select
              defaultValue={questionType[question.questionType]}
              style={{ width: '100%', marginBottom: '1rem' }}
              onChange={handleChange}>
              <Option value="2" key={question[getId(question)]}>
                Free Text
              </Option>
              <Option value="1" key={question[getId(question)]}>
                Multiple Choice
              </Option>
              <Option value="3" key={question[getId(question)]}>
                Rating
              </Option>
            </Select>
            <TextArea
              rows={2}
              placeholder="Type Question"
              onChange={handleChange}
              defaultValue={question.question}
              id={question[getId(question)]}
            />
          </FormContent>
          {isMultipleChoice(question[getId(question)]) ? (
            <AddOption
              setQuestions={setQuestions}
              questionId={question[getId(question)]}
              question={question}
              option={question.options}
              isEdit={isEdit}
            />
          ) : (
            isRating(question[getId(question)]) && (
              <AddRating
                setQuestions={setQuestions}
                questionId={question[getId(question)]}
                question={question}
                option={question.options}
                isEdit={isEdit}
              />
            )
          )}
        </Div>
      ))}
      <SurveySubmit setCreateSurvey={setCreateSurvey} />
    </Container>
  );
};

export default EditSurveyForm;

const Container = styled.div``;

const Div = styled.div`
  margin-bottom: 2rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`;
const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const IconDiv = styled.div`
  display: flex;

  gap: 1rem;
  width: 70px;
  justify-content: center;
  height: 31px;
  background: rgba(231, 244, 255, 0.51);
  border-radius: 3px;
`;

const Img = styled.img`
  align-self: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const FormContent = styled.div``;
