import React from 'react';
import { Box, Paragraph, ResponsiveContext, Text, Video } from 'grommet';
import FileUpload from 'components/common/FileUpload';
import { isMobile } from 'utils';

const CourseMediaUpload = ({
  title,
  description,
  mediaType = 'image',
  courseThumbNailUrl,
  setCourseThumbNailUrl,
  courseBannerUrl,
  setCourseBannerUrl,
  coursePromotionalVideoUrl,
  setCoursePromotionalVideoUrl,
  errorMessage,
  isReview = false
}) => {
  const size = React.useContext(ResponsiveContext);
  const [media, setMedia] = React.useState(null);
  const [showError, setShowError] = React.useState(true);
  const videoRef = React.useRef();
  const videoSourceRef = React.useRef();

  const getMedia = media => {
    if (mediaType === 'image') {
      if (media) {
        let reader = new FileReader();
        reader.onload = () => setMedia(reader.result);
        reader.readAsDataURL(media);
        setShowError(false);
      }
    } else if (mediaType === 'video') {
      if (media) {
        const mediaUrl = URL.createObjectURL(media);
        setMedia(mediaUrl);
        setShowError(false);
        if (mediaUrl && videoSourceRef?.current?.src) {
          videoSourceRef.current.src = mediaUrl;
          videoRef.current.load();
        }
      }
      // videoRef.current.load();
    }

    if (title.includes('Thumbnail')) {
      setCourseThumbNailUrl(media);
    } else if (title.includes('Banner')) {
      setCourseBannerUrl(media);
    } else if (title.includes('Promotional')) {
      setCoursePromotionalVideoUrl(media);
    }
  };

  return (
    <Box>
      <Box direction={isMobile(size) ? 'column' : 'row'} gap="medium" justify="between" wrap={true}>
        <Box>
          <Paragraph style={{ fontWeight: 600 }}>{title}</Paragraph>
          <Text
            size="small"
            style={{ width: isMobile(size) ? '100%' : '350px' }}
            margin={{ bottom: 'small' }}>
            {description}
          </Text>
          <Box margin={{ bottom: 'small' }}>
            <FileUpload getMedia={getMedia} mediaType={mediaType} disabled={isReview} />
            {errorMessage && showError && (
              <Text
                color="status-critical"
                size="small"
                alignSelf={isMobile(size) ? 'start' : 'end'}
                margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
                {errorMessage}
              </Text>
            )}
          </Box>
        </Box>
        {
          {
            image: (
              <Box
                flex="grow"
                style={{
                  minHeight: 200,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundImage: `url('${
                    media
                      ? media
                      : courseThumbNailUrl
                      ? courseThumbNailUrl
                      : courseBannerUrl
                      ? courseBannerUrl
                      : 'https://via.placeholder.com/300x300?text=No+Preview+Image'
                  }')`
                }}
                width={isMobile(size) ? 'fill' : 'small'}></Box>
            ),
            video: (
              <Box
                flex="grow"
                style={{
                  minHeight: 200,
                  backgroundImage: !coursePromotionalVideoUrl
                    ? 'url(https://via.placeholder.com/300x300?text=No+Preview+Video)'
                    : null,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
                width={isMobile(size) ? 'fill' : 'small'}>
                {(media || coursePromotionalVideoUrl) && (
                  <Video ref={videoRef} controls={'over'} style={{ width: '100%', height: '100%' }}>
                    <source
                      key="video"
                      ref={videoSourceRef}
                      src={media ? media : coursePromotionalVideoUrl}
                    />
                  </Video>
                )}
              </Box>
            )
          }[mediaType]
        }
      </Box>
    </Box>
  );
};

export default CourseMediaUpload;
