import React, { useRef } from 'react';
import 'styles/utils.css';
import { Box, ResponsiveContext, Grid } from 'grommet';
import CourseCard from 'components/common/CourseCard';
import IconWrapper from 'components/common/IconWrapper';
import Next from 'assets/images/greaterthan.png';
import Previous from 'assets/images/lessthan.png';
import IcsanLogo from 'assets/images/icsan.png';
import SectionHeader from 'components/utils/CourseDiscovery/SectionHeader';
import styled from 'styled-components';
import { Button } from 'antd';
import { AiOutlineRight } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { isMobile, isLargeDesktop, isMediumDesktop, isTablet } from 'utils';
import 'styles/carousel.css';

const CourseCategory = ({
  courseSearchResult,
  title,
  searchType,
  otherPages,
  fontSize,
  setCourseDetails,
  categoryType = 'all',
  left = 'large',
  right = 'medium',
  bottom = 'medium'
}) => {
  const size = React.useContext(ResponsiveContext);
  const history = useHistory();
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });

  let navRef = useRef();
  /* This func handles the scrolling by incrementing or decrementing
   the scrollLeft property */
  const handleNav = direction => {
    if (direction === 'left') {
      navRef.current.scrollLeft -= 400;
    } else {
      navRef.current.scrollLeft += 400;
    }
  };

  return (
    <Box
      margin={{
        left: isMobileDevice ? '0px' : left,
        right: isMobileDevice ? '0px' : right,
        bottom: bottom
      }}>
      <Box
        margin={{ left: isMobileDevice ? '0px' : '10px' }}
        direction={isMobile(size) ? 'column' : 'row'}
        gap="medium"
        style={{ position: 'relative' }}
        align={isMobile(size) ? 'stretch' : 'center'}
        justify="between">
        <SectionHeader fontSize={fontSize} color="#000000" title={title} marginTop="none" />
        <BackIcon
          right={isMobileDevice ? '0px' : '0px'}
          top="-4px"
          type="primary"
          onClick={() => {
            history.push(history.push(history.location.pathname));
          }}
          categorytype={categoryType}>
          <span> &#11207; Back</span>
        </BackIcon>
      </Box>
      <div>
        {categoryType === 'all' ? (
          <div className="menu_container" style={{ position: 'relative', width: '100%' }}>
            <PreviousPosition
              top={isMobileDevice ? '-32px' : '-26px'}
              categoryType={categoryType}
              onClick={() => handleNav('left')}>
              <IconWrapper icon={Previous} width="20px" height="20px" />
            </PreviousPosition>
            <div className="carousel_items carousel_container" ref={navRef}>
              {courseSearchResult &&
                courseSearchResult.map((item, index) => {
                  return (
                    <div key={index}>
                      <CourseCard
                        courseId={item.courseId}
                        key={index}
                        width="350px"
                        margin="10px"
                        price={item?.coursePrice?.amount}
                        title={item.title}
                        description={item.description}
                        modules={item.modules}
                        duration={item.duration}
                        level={
                          item?.courseLevel === 1
                            ? 'Beginner'
                            : item?.courseLevel === 2
                            ? 'Intermedate'
                            : 'Advanced'
                        }
                        institutionLogo={
                          item.institutionLogo === null ? IcsanLogo : item.institutionLogo
                        }
                        institutionName={item.institution}
                        courseThumbNailUrl={item.courseThumbNailUrl}
                        data={item}
                        setCourseDetails={setCourseDetails}
                      />
                    </div>
                  );
                })}
            </div>
            <NextPosition
              top={isMobileDevice ? '-32px' : '-26px'}
              categoryType={categoryType}
              onClick={() => handleNav('right')}>
              <IconWrapper icon={Next} width="20px" height="20px" />
            </NextPosition>
          </div>
        ) : (
          <>
            <Grid
              columns={{
                count: isMobile(size)
                  ? 1
                  : isTablet(size)
                  ? 3
                  : isMediumDesktop(size)
                  ? 3
                  : isLargeDesktop(size)
                  ? 5
                  : 6,
                size: 'auto'
              }}
              gap="small">
              {courseSearchResult &&
                courseSearchResult?.map((item, index) => {
                  return (
                    <CourseCard
                      courseId={item.courseId}
                      key={index}
                      width="350px"
                      price={item?.coursePrice?.amount}
                      title={item.title}
                      description={item.description}
                      modules={item.modules}
                      duration={item.duration}
                      level={
                        item?.courseLevel === 1
                          ? 'Beginner'
                          : item?.courseLevel === 2
                          ? 'Intermedate'
                          : 'Advanced'
                      }
                      institutionLogo={
                        item.institutionLogo === null ? IcsanLogo : item.institutionLogo
                      }
                      institutionName={item.institution}
                      courseThumbNailUrl={item.courseThumbNailUrl}
                      data={item}
                      setCourseDetails={setCourseDetails}
                    />
                  );
                })}
            </Grid>
          </>
        )}
      </div>
      {!otherPages && (
        <Box justify="end" direction="row">
          {categoryType === 'all' && (
            <Button
              type="link"
              onClick={() => {
                history.push(`${history.location.pathname}?category=${searchType}`);
              }}>
              <ButtonWrapper>
                <span>See All Courses</span>
                <AiOutlineRight />
              </ButtonWrapper>
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

const NextPosition = styled.div`
  cursor: pointer;
  position: absolute;
  top: ${({ top }) => top};
  right: 10px;
  display: ${props => (props.categoryType === 'all' ? 'block' : 'none')};
`;

const PreviousPosition = styled.div`
  cursor: pointer;
  position: absolute;
  top: ${({ top }) => top};
  right: 50px;
  display: ${props => (props.categoryType === 'all' ? 'block' : 'none')};
`;

const BackIcon = styled(Button)`
  position: absolute;
  background-color: #0966b8;
  border: #0966b8;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  display: ${props => (props.categorytype === 'all' ? 'none' : 'block')};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: #0849ff;
  font-weight: 600;
  & > span {
    font-size: 1rem;
  }
`;

export default CourseCategory;
