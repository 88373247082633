import React from 'react';
import { Modal, Typography } from 'antd';
import CopyToClipboard from './CopyToClipboard';
import styled from 'styled-components';
import { IconFacebook, IconLinked, IconGram, IconTwitter } from 'components/customIcon/CustomIcon';

const ShareModal = ({ modalOpen, handleShare }) => {
  const openInNewTab = url => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleUrlClick = url => {
    return () => openInNewTab(url);
  };

  const urlPath = () => {
    const baseURLPath = window.location.href;
    return baseURLPath;
  };

  return (
    <Modal
      title={<TitleWrap level={4}>Share this Course</TitleWrap>}
      centered
      visible={modalOpen}
      width={800}
      footer={null}
      onCancel={handleShare}>
      <div>
        <CopyToClipboard copyText={urlPath()} />
        <SocialMediaWrap>
          <IconFacebook
            onClick={handleUrlClick('https://facebook.com')}
            style={{ marginRight: '1rem' }}
          />
          <IconLinked
            onClick={handleUrlClick('https://linkedin.com')}
            style={{ marginRight: '1rem' }}
          />
          <IconGram
            onClick={handleUrlClick('https://instagram.com')}
            style={{ marginRight: '1rem' }}
          />
          <IconTwitter onClick={handleUrlClick('https://twitter.com')} />
        </SocialMediaWrap>
      </div>
    </Modal>
  );
};
export default ShareModal;

const SocialMediaWrap = styled.div`
  margin-top: 3rem;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleWrap = styled(Typography.Title)`
  margin-bottom: 0px !important;
`;
