import React from 'react';
import { Box, Heading, Paragraph } from 'grommet';

const CourseOverview = ({ details }) => {
  return (
    <Box fill margin={{ top: 'small' }}>
      <Heading style={{ fontSize: 24 }} level="4" margin={{ vertical: 'small' }}>
        Course Overview
      </Heading>
      <Paragraph margin={{ bottom: 'small' }} fill>
        {details}
      </Paragraph>
    </Box>
  );
};

export default CourseOverview;
