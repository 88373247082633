import React from 'react';
import IconWrapper from 'components/common/IconWrapper';
import { Box, Text } from 'grommet';
import PropTypes from 'prop-types';
import SuccessIcon from 'assets/svgs/ic_check_circle.svg';
import WarningIcon from 'assets/svgs/ic_warning.svg';
import FailedIcon from 'assets/svgs/ic_cancel.svg';
import { animate } from 'utils';

const Message = ({ message, status }) => {
  const [createMessageAnimate] = React.useState([
    animate({ type: 'fadeIn', delay: 500, duration: 500 }),
    animate({ type: 'slideUp', delay: 500, duration: 500 })
  ]);

  return (
    <Box>
      <Box margin={{ bottom: 'medium' }} animation={createMessageAnimate[0]}>
        <Box animation={createMessageAnimate[1]} align="center" direction="row" gap="xsmall">
          <IconWrapper
            icon={
              status === 'success'
                ? SuccessIcon
                : status === 'warning'
                ? WarningIcon
                : status === 'error'
                ? FailedIcon
                : null
            }
            width="16px"
            height="16px"
          />
          <Text
            color={
              status === 'error' ? 'status-critical' : status === 'success' ? 'neutral-1' : null
            }>
            {message}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

Message.propTypes = {
  message: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
};
export default Message;
