import React, { useEffect, useState } from 'react';

import { Box, Button, ResponsiveContext, Heading, Layer, Text, CheckBox } from 'grommet';
import { isMobile } from 'utils';
import IconWrapper from 'components/common/IconWrapper';
import CloseIcon from 'assets/svgs/ic_close.svg';
import ProfileIcon from 'components/common/ProfileIcon';
import { useMutation, useQuery } from 'react-query';
import { toggleInstitution } from 'api/mutations/Institution';
import NotificationModal from './NotificationModal';
import PageLoader from 'components/common/PageLoader';
import { useHistory } from 'react-router-dom';
import { getCountries, getInstitutionAndUser } from 'api/queries/Institution';
import { updateinstitution } from 'adminRoutes';
import InputValidationLoader from 'components/common/InputValidationLoader';
import { showToast } from 'store/actions/toast';
import { useDispatch } from 'react-redux';
import { toggleUser } from 'api/mutations/Authentication';
import UpdateUserModal from './UpdateUserModal';

const ManageUserModal = ({ onClose, refetchUserUpdate, data, setUpdateModal }) => {
  const size = React.useContext(ResponsiveContext);
  const [status, setStatus] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState(null);
  const [, setToggleError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formFieldStyle = {
    flexDirection: isMobile(size) ? 'column' : 'row',
    alignItems: isMobile(size) ? 'start' : 'center',
    justifyContent: isMobile(size) ? 'space-between' : 'space-between'
  };
  console.log(data);

  useEffect(() => {
    if (data.isActive) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }, [data]);

  useEffect(() => {
    if (successMessage) {
      dispatch(
        showToast('success', data.isActive === true ? 'Record deactivated' : ' Record Activated')
      );
      setSuccessMessage(null);
    }
  }, [data.isActive, dispatch, successMessage]);

  const [toggleMutate] = useMutation(toggleUser, {
    onSuccess: data => {
      const { description, success } = data;
      if (success) {
        setIsSubmitting(false);
        refetchUserUpdate();
        setSuccessMessage(description);
        onClose();
      } else {
        dispatch(showToast('error', data.description));
        setToggleError(description);
        setIsSubmitting(false);
        console.log(description);
      }
    },
    onError: error => {
      // setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setToggleError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        setToggleError(error.message);
        console.log(error.message);
      }
    }
  });

  const toggleStatus = async (id, isActive) => {
    setIsSubmitting(false);
    try {
      setIsSubmitting(true);
      setToggleError(null);
      await toggleMutate({ id, isActive });
    } catch (e) {
      console.log(e);
    }
  };

  // console.log(data.isActive);
  return (
    <Layer responsive={false} onClickOutside={onClose} onEsc={onClose}>
      <Box
        border="bottom"
        direction={isMobile(size) ? 'column' : 'row'}
        pad="small"
        align="center"
        gap={isMobile(size) ? 'medium' : 'small'}>
        <Box width="xsmall" margin={{ left: 'small' }}>
          <ProfileIcon
            // img={data.logo}
            size="large"
          />
        </Box>
        <Box direction="column" width="full" margin={{ left: isMobile(size) ? 'small' : 'none' }}>
          <Box
            direction="row"
            justify="between"
            gap="medium"
            align="start"
            margin={{ top: 'small', right: 'small' }}>
            <Box width={isMobile(size) ? '100%' : '400px'}>
              <Heading
                style={{ marginBottom: '-4px' }}
                margin={{ top: 'none' }}
                size={isMobile(size) ? 'xsmall' : null}
                level={isMobile(size) ? 5 : 4}
                color="#777">
                {`${data.firstName} ${data.lastName}`}
              </Heading>
              <Text>{data.roleName}</Text>
            </Box>
            <Box
              flex="grow"
              align={isMobile(size) ? 'start' : 'end'}
              margin={{ bottom: isMobile(size) ? 'small' : 'none' }}
              justify="start">
              <Box
                flex="shrink"
                direction={isMobile(size) ? 'row' : 'column'}
                gap={isMobile(size) ? 'xsmall' : 'none'}>
                <Button
                  style={{ padding: '0px' }}
                  icon={<IconWrapper icon={CloseIcon} width="26px" height="26px" />}
                  onClick={onClose}
                />
              </Box>
            </Box>
          </Box>
          <Box
            direction={isMobile(size) ? 'column' : 'row'}
            justify={isMobile(size) ? 'center' : 'between'}
            align={isMobile(size) ? 'start' : 'center'}
            margin={{ top: 'xsmall', bottom: 'small' }}
            gap="medium">
            {/* <Box wrap={true}>
              <Button
                label="Send Email"
                style={{ fontSize: '12px' }}
                size={isMobile(size) ? 'small' : 'small'}
              />
            </Box> */}
            <Box>
              <Box flex="shrink">
                <Button
                  disabled={!data.isActive}
                  margin={{ right: 'small' }}
                  label="Update Details"
                  onClick={() => {
                    setUpdateModal(true);
                    onClose();
                  }}
                  primary
                  size={isMobile(size) ? 'small' : 'small'}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box align="center" margin={{ bottom: 'medium' }}>
        <Box
          width="full"
          margin={{ top: 'medium' }}
          pad={isMobile(size) ? 'medium' : null}
          gap="small"
          style={{ maxWidth: 375 }}>
          {/* <Box margin={{ bottom: 'small' }}>
            <Box style={formFieldStyle}>
              <Text>Partner</Text>
              <Text
                size="small"
                style={{ fontWeight: '600', width: isMobile(size) ? '100%' : 200 }}
                alignSelf={isMobile(size) ? 'start' : 'end'}>
                {data.lastName}
              </Text>
            </Box>
          </Box> */}
          <Box margin={{ bottom: 'small' }}>
            <Box style={formFieldStyle}>
              <Text>Username</Text>
              <Text
                size="small"
                style={{ fontWeight: '600', width: isMobile(size) ? '100%' : 200 }}
                alignSelf={isMobile(size) ? 'start' : 'end'}>
                {data.userName}
              </Text>
            </Box>
          </Box>
          <Box margin={{ bottom: 'small' }}>
            <Box style={formFieldStyle}>
              <Text>Email</Text>
              <Text
                size="small"
                style={{ fontWeight: '600', width: isMobile(size) ? '100%' : 200 }}
                alignSelf={isMobile(size) ? 'start' : 'end'}>
                {data.email}
              </Text>
            </Box>
          </Box>
          <Box margin={{ bottom: 'small' }}>
            <Box style={formFieldStyle}>
              <Text>Phone</Text>
              <Text
                size="small"
                style={{ fontWeight: '600', width: isMobile(size) ? '100%' : 200 }}
                alignSelf={isMobile(size) ? 'start' : 'end'}>
                {data.phoneNumber}
              </Text>
            </Box>
          </Box>
          {/* <Box margin={{ bottom: 'small' }}>
            <Box style={formFieldStyle}>
              <Text>Country</Text>
              <Text
                size="small"
                style={{ fontWeight: '600', width: isMobile(size) ? '100%' : 200 }}
                alignSelf={isMobile(size) ? 'start' : 'end'}>
                {!countries
                  ? data.country
                  : countries?.find(d => d.code1 === data.country).countryName}
              </Text>
            </Box>
          </Box> */}
          {/* <Box margin={{ bottom: 'small' }}>
            <Box style={formFieldStyle}>
              <Text>Website</Text>
              <Text
                truncate
                size="small"
                //  style={{ width: isMobile(size) ? '100%' : 200 }}
                style={{ fontWeight: '600', width: isMobile(size) ? '100%' : 200 }}
                alignSelf={isMobile(size) ? 'start' : 'end'}>
                {data.website}
              </Text>
            </Box>
          </Box>
          <Box margin={{ bottom: 'small' }}>
            <Box style={formFieldStyle}>
              <Text>Address</Text>
              <Text
                size="small"
                style={{ fontWeight: '600', width: isMobile(size) ? '100%' : 200 }}
                alignSelf={isMobile(size) ? 'start' : 'start'}>
                {data.address}
              </Text>
            </Box>
          </Box> */}
          <Box margin={{ bottom: 'small' }}>
            <Box style={formFieldStyle}>
              <Text>Status</Text>
              <Box
                style={{
                  position: 'relative',
                  fontWeight: '600',
                  width: isMobile(size) ? '100%' : 200
                }}>
                {isSubmitting ? (
                  <>
                    <InputValidationLoader right="182px" bottom="3px" />{' '}
                    <Box margin={{ left: '34px' }}>
                      {' '}
                      <Text style={{ fontSize: '16px' }}>
                        {data.isActive === true ? 'deactivating record..' : 'Activating record..'}
                      </Text>
                    </Box>{' '}
                  </>
                ) : (
                  <CheckBox
                    toggle
                    checked={status}
                    onChange={() => setStatus(!status)}
                    onClick={() => {
                      toggleStatus(data.id, data.isActive ? false : true);
                      // console.log(data.id,data.isActive ?false: true )
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};

export default ManageUserModal;
