import React, { useEffect } from 'react';
import { Box, Button, Heading, Layer, Select, TextArea } from 'grommet';
import IconWrapper from 'components/common/IconWrapper';
import CloseIcon from 'assets/svgs/ic_close.svg';
import { useDispatch } from 'react-redux';
import { showToast } from 'store/actions/toast';
import AppData from 'AppData';
import { searchRevenue } from 'api/queries/Course';
import { useQuery } from 'react-query';

const ApprovalModal = ({
  comment,
  revenueId,
  setComment,
  setRevenueId,
  setLoading,
  showApprovalModal,
  showRejectionModal,
  setShowApprovalModal,
  setShowRejectionModal,
  onClickHandler
}) => {
  const dispatch = useDispatch();
  const { isLoading, data: revenue } = useQuery('searchRevenue', searchRevenue);

  const onEscCallback = () => {
    setShowApprovalModal(false);
    setShowRejectionModal(false);
  };

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  return (
    <Layer onEsc={onEscCallback} onClickOutside={onEscCallback}>
      <Box width="medium" height={showApprovalModal ? '200px' : '300px'} pad="medium" gap="medium">
        <Box direction="row" justify="between" align="center">
          <Heading level="5" margin="none">
            {showApprovalModal ? 'Revenue' : 'Comment'}
          </Heading>
          <Button
            plain
            icon={<IconWrapper icon={CloseIcon} width="20px" height="20px" />}
            onClick={onEscCallback}
          />
        </Box>
        {showApprovalModal && (
          <Select
            options={revenue?.data || []}
            labelKey="name"
            valueKey="id"
            placeholder="Select Revenue"
            onChange={({ option }) => setRevenueId(option.id)}
          />
        )}
        {showRejectionModal && (
          <TextArea
            value={comment}
            onChange={e => setComment(e.target.value)}
            resize={false}
            fill
            placeholder="Enter your comment"
          />
        )}
        <Button
          primary
          label="Submit"
          onClick={() => {
            if (!comment && showRejectionModal) {
              dispatch(showToast('error', 'Kindly provide a comment'));
              return;
            }
            if (!revenueId && showApprovalModal) {
              dispatch(showToast('error', 'Select a revenue'));
              return;
            }
            onClickHandler(showApprovalModal ? AppData.courseApproval : AppData.courseRejection);
          }}
        />
      </Box>
    </Layer>
  );
};

export default ApprovalModal;
