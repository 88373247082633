import React from 'react';
import { Table, Switch, Button, Typography } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import moment from 'moment';
import Axios from 'axios';

const service = 'Institution';

const ManagePartnersTable = ({ setUpdateValues, setCreatePartner, partner, refetch }) => {
  const { Text } = Typography;
  const activateDeactivatePartners = async (id, isActive) => {
    const { data } = await Axios.delete(
      `/${service}/api/Partner/ActivateDeactivate?id=${id}&isActive=${isActive}`
    );
    refetch();
    return data;
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'BANK',
      dataIndex: 'bankName',
      key: 'bankName'
    },
    {
      title: 'ACCOUNT NO',
      dataIndex: 'account',
      key: 'account'
    },
    {
      title: 'DATE CREATED',
      dataIndex: 'dateCreated',
      key: 'dateCreated'
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive'
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          {' '}
          <Button
            onClick={() => {
              //console.log('record', record.statusType, text);
              record.statusType
                ? activateDeactivatePartners(record.id, false)
                : activateDeactivatePartners(record.id, true);
              refetch();
            }}
            type={record.statusType ? 'danger' : 'primary'}>
            {record.statusType ? 'Deactivate' : 'Activate'}
          </Button>
          <Button
            style={{ marginLeft: '20px' }}
            onClick={() => {
              setCreatePartner(true);
              setUpdateValues(record);
            }}>
            <Text type="warning">Update</Text>
          </Button>
        </>
      )
    }
  ];

  const tableData = partner?.data?.map(rowData => ({
    ...rowData,
    key: rowData.id,
    name: rowData.name,
    statusType: rowData.isActive,
    isActive: (
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        disabled
        checked={rowData.isActive ? true : false}
      />
    ),
    dateCreated: moment(rowData.dateCreated).format('YYYY-MM-DD hh:mm:ss')
  }));

  return <Table columns={columns} dataSource={tableData} />;
};

export default ManagePartnersTable;
