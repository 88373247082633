import Axios from 'axios';

const service = 'Discount';

// export const createDiscount = async ({
//   name,
//   description,
//   discountChargeType,
//   institutionType,
//   startDate,
//   endDate,
//   allcoursesDiscount,
//   discountType,
//   amount,
//   code,
//   setUsage,
//   usage,
//   courseId
// } ) => {
//     const { data } = await Axios.post(`/${service}/api/Discount/Create`, {
//       name,
//       description,
//       discountChargeType,
//       institutionType,
//       startDate,
//       endDate,
//       allcoursesDiscount,
//       discountType,
//       amount,
//       code,
//       setUsage,
//       usage,
//       courseId
//     });
//     return data;
//   };

export const createDiscount = async ({
  name,
  description,
  discountChargeType,
  institutionType,
  startDate,
  endDate,
  allCoursesDiscount,
  discountType,
  amount,
  code,
  setUsage,
  usage,
  courseId,
  file
}) => {
  try {
    const formData = new FormData();
    formData.append('DiscountModel.Name', name);
    formData.append('DiscountModel.Description', description);
    formData.append('DiscountModel.DiscountChargeType', discountChargeType);
    formData.append('DiscountModel.InstitutionId', institutionType);
    formData.append('DiscountModel.StartDate', startDate);
    formData.append('DiscountModel.EndDate', endDate);
    formData.append('DiscountCourseModel.AllCoursesDiscount', allCoursesDiscount);
    formData.append('DiscountCodeModel.DiscountType', discountType);
    formData.append('DiscountModel.Amount', amount);
    formData.append('DiscountCodeModel.Code', code);
    formData.append('DiscountModel.SetUsageLimit', setUsage);
    formData.append('DiscountModel.UsageLimit', usage);
    formData.append('DiscountCourseModel.CourseId', courseId);
    formData.append('DiscountCodeModel.FormFile', file);

    console.log(3, formData, file);

    const { data } = await Axios.post(`/${service}/api/Discount/Create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'multipart/form-data'
      }
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateDiscount = async ({
  discountId,
  name,
  description,
  discountChargeType,
  institutionType,
  startDate,
  endDate,
  allcoursesDiscount,
  discountType,
  amount,
  code,
  setUsage,
  usage,
  courseId,
  file
}) => {
  try {
    // const formData = new FormData();
    // formData.append('DiscountModel.Name', name);
    // formData.append('DiscountModel.Description', description);
    // //formData.append('DiscountModel.DiscountChargeType', discountChargeType);
    // //formData.append('DiscountModel.InstitutionId', institutionType);
    // formData.append('DiscountModel.StartDate', startDate);
    // formData.append('DiscountModel.EndDate', endDate);
    // //formData.append('DiscountCodeModel.AllcoursesDiscount', allcoursesDiscount);
    // //formData.append('DiscountCodeModel.DiscountType', discountType);
    // //formData.append('DiscountModel.Amount', amount);
    // //formData.append('DiscountCodeModel.Code', code);
    // formData.append('DiscountModel.SetUsageLimit', setUsage);
    // formData.append('DiscountModel.UsageLimit', usage);
    // //formData.append('DiscountCourseModel.CourseId', courseId);
    // //formData.append('DiscountCodeModel.FormFile', file);

    const payloadData = {
      id: discountId,
      name: name,
      description: description,
      //discountChargeType: Number(discountChargeType),
      //institutionType: institutionType,
      startDate: startDate,
      endDate: endDate,
      // allcoursesDiscount: allcoursesDiscount,
      //discountType: discountType ? 1 : 2,
      //amount: amount,
      //code: code,
      setUsage: setUsage,
      usageLimit: usage
      //courseId: courseId,
      //file: file
    };
    console.log(3, payloadData);

    const { data } = await Axios.put(`/${service}/api/Discount/Update`, payloadData, {
      headers: {
        'Content-Type': 'application/json-patch+json',
        Accept: 'application/json-patch+json'
      }
    });
    return data;
  } catch (error) {
    throw error;
  }
};
