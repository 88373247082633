import React, { useState, useEffect } from 'react';
import { Form, Input, Button as AntButton } from 'antd';
import styled from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

import AppText from 'components/common/AppText';
import { signIn } from 'store/actions/auth';
import { showToast } from 'store/actions/toast';
import { setPassword, studentlogin } from 'api/mutations/Authentication';
import { home, thirdpartycheckout } from 'userRoutes';
import SmeVilleHeader from '../../../components/smeVille/SmeVilleHeader';
import SuccessIcon from 'assets/svgs/ic_check_circle.svg';
import { getPasswordPasswordStatus } from 'api/queries/Authentication';

const SetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState();
  const [userName, setUserName] = useState();
  const [password, setUserPassword] = useState();
  const [signInLoading, setSignInLoading] = useState();
  const [passwordVisible, setPasswordVisible] = useState();
  const [showSuccess, setShowSuccess] = useState(false);

  const { data: result } = useQuery(['passwordStatus', userName], getPasswordPasswordStatus, {
    enabled: userName,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (result?.data?.isFirstLogin === false) {
      dispatch(showToast('error', 'The Verification link has either been used or expired'));
      history.push(home);
    }
  }, [dispatch, history, result?.data]);

  useEffect(() => {
    const username = window.location.href.substring(window.location.href.lastIndexOf('username') + 9);
    setUserName(username);
  }, []);

  const [mutate] = useMutation(setPassword, {
    onMutate: () => setLoading(true),
    onSuccess: data => {
      setLoading(false);
      if (data?.success) {
        setShowSuccess(true);
      } else {
        dispatch(showToast('error', data.description));
        history.push(`${home}?redirect=${thirdpartycheckout}`);
      }
    },
    onError: error => {
      setLoading(false);
      dispatch(showToast('error', error.message));
      throw error;
    }
  });

  const [signInMutate] = useMutation(studentlogin, {
    onMutate: () => setSignInLoading(true),
    onSuccess: data => {
      setSignInLoading(false);
      dispatch(showToast('success', 'Login successfully'));
      dispatch(signIn(data.data));
      console.log(data?.data);
      if (data?.data?.PendingPayment > 0) history.push(thirdpartycheckout);
      if (data?.data?.PendingPayment === 0 && data?.data?.EnrolledCourse > 0)
        history.push(thirdpartycheckout);
    },
    onError: error => {
      setSignInLoading(false);
      dispatch(showToast('error', error.message));
      throw error;
    }
  });

  const onFinish = data => {
    const { password, confirmPassword } = data;
    if (password !== confirmPassword) return dispatch(showToast('error', 'Passwords do not match'));
    setUserPassword(password);
    mutate({
      password,
      userName
    });
  };

  const handleSignIn = () => {
    signInMutate({
      password,
      userName,
      isEncode: true
    });
  };

  return (
    <ContainerWrapper>
      <SmeVilleHeader />
      <Container>
        {showSuccess ? (
          <Div>
            <SuccessImage src={SuccessIcon} alt="success" />
            <AppText
              top="20px"
              size={'24px'}
              weight={700}
              value={'Password Created Successfully'}
              bottom="20px"
            />
            <AppText
              size={'16px'}
              color={'#808183'}
              value={
                'You may now proceed to Skilldorm check out page to complete your course purchase'
              }
              bottom="20px"
            />

            <BtnDiv onClick={handleSignIn}>
              {signInLoading ? 'Loading...' : ' Proceed'}
              <BsArrowRight color="#0966b8" size={'20px'} />
            </BtnDiv>
          </Div>
        ) : (
          <Div>
            <AppText size={'32px'} weight={700} value={'Set Password'} bottom="15px" />
            <AppText
              size={'16px'}
              color={'#808183'}
              value={
                'You’re required to set a unique password identitity to access Skilldorm platform'
              }
            />
            <Form layout="vertical" onFinish={onFinish}>
              <Form.Item
                name="password"
                label={<AppText value="New Password" weight={700} bottom={0} />}
                style={{ width: '450px' }}
                required>
                <Input.Password
                  placeholder="Enter your password"
                  name="password"
                  size="large"
                  iconRender={visible => (visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />)}
                  style={{ borderRadius: '8px' }}
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label={<AppText value="Confirm New Password" weight={700} bottom={0} />}
                style={{ width: '450px' }}
                required>
                <Input.Password
                  placeholder="Confirm your password"
                  size="large"
                  visibilityToggle={{
                    visible: passwordVisible,
                    onVisibleChange: setPasswordVisible
                  }}
                  style={{ borderRadius: '8px' }}
                />
              </Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Confirm
              </Button>
            </Form>
          </Div>
        )}
      </Container>
    </ContainerWrapper>
  );
};

export default SetPassword;

const ContainerWrapper = styled.div`
  /* width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh; */
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 113px);
`;

const Div = styled.div`
  width: 450px;
  text-align: center;
`;

const Button = styled(AntButton)`
  width: 100%;
  background: #0966b8;
  border-radius: 25px;
  height: 50px;
`;

const BtnDiv = styled.div`
  border-radius: 33px;
  background: #ccefff;
  height: 66px;
  width: 249px;
  color: #0966b8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 1rem;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`;

const SuccessImage = styled.img`
  width: 100px;
`;
