import React from 'react';
import { Row, Col, Typography } from 'antd';
import styled from 'styled-components';

const PieChart = () => {
  return (
    <Container>
      <PieDiv>
        <PieText>4,725</PieText>
      </PieDiv>
      <PieKeys>
        <Col span={12}>
          <KeyContainer>
            <Div color="#22c435"></Div>
            <KeyText>Female</KeyText>
          </KeyContainer>
        </Col>
        <Col span={12}>
          <KeyContainer>
            <Div color="#2c7bef"></Div>
            <KeyText>Male</KeyText>
          </KeyContainer>
        </Col>
      </PieKeys>
    </Container>
  );
};

export default PieChart;

const Container = styled.div`
  min-height: 7em;
`;

const PieDiv = styled.div`
  width: 12em;
  border-radius: 50%;
  margin: auto;
  margin-top: 2em;
  height: 12em;
  padding-top: 4em;
  border-left: 20px solid #22c435;
  border-right: 20px solid #2c7bef;
  border-bottom: 20px solid #2c7bef;
  border-top: 20px solid #2c7bef;
`;

const PieText = styled(Typography.Paragraph)`
  text-align: center;
  color: #333333;
  font-weight: bold;
  font-size: 2em;
`;

const PieKeys = styled(Row)`
  margin: auto;
  margin-top: 2em;
  width: 100%;
`;

const KeyContainer = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  grid-gap: 0.7em;
`;

const Div = styled.div`
  width: 1.5em;
  height: 1.5em;
  background: ${props => props.color};
`;

const KeyText = styled(Typography.Text)``;
