import * as signInTypes from 'store/actions/auth';

// initial sign in state
const initialState = {
  userName: '',
  firstName: '',
  lastName: '',
  initials: '',
  profilePix: null,
  userId: null,
  StudentProfile: null,
  StaffProfileId: null,
  institutionId: '',
  institutionName: '',
  institutionLogo: '',
  accessToken: '',
  accessTokenExpires: '',
  refreshToken: '',
  refreshTokenExpires: '',
  role: '',
  permission: [],
  institutionCode: '',
  isFirstLogin: false,
  success: false,
  sessionTimedOut: false,
  allPermissions: [],
  EnrolledCourse: null,
  PendingPayment: null
};

// auth reducer
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case signInTypes.SIGN_IN_CLEAR:
      return {
        ...state,
        isLoading: false,
        success: false
      };
    case signInTypes.SIGN_IN:
      return {
        ...state,
        userName: action.data.UserName,
        firstName: action.data.FirstName,
        lastName: action.data.LastName,
        initials: action.data.Firstname
          ? `${action?.data?.FirstName[0]}${action?.data?.LastName[0]}`
          : '',
        profilePix: action.data.Profilepix,
        userId: action.data.UserId,
        StudentProfile: action.data.StudentProfile,
        StaffProfileId: action.data.StaffProfileId,
        institutionId: action.data.InstitutionId,
        institutionName: action.data.InstitutionName,
        institutionLogo: action.data.InstitutionLogo,
        accessToken: action.data.access_token,
        accessTokenExpires: action.data.accesstoken_Expires,
        refreshToken: action.data.refreshToken,
        refreshTokenExpires: action.data.refreshToken_Expires,
        role: action.data.role,
        isFirstLogin: action.data.isFirstLogin,
        permission: action.data.Permission,
        institutionCode: action.data.InstitutionCode,
        enrolledCourse: action.data.EnrolledCourse,
        pendingPayment: action.data.PendingPayment,
        success: true,
        sessionTimedOut: false
      };
    case signInTypes.REFRESH_TOKEN_AUTH:
      return {
        ...state,
        accessToken: action.data.accessToken,
        refreshToken: action.data.refreshToken
      };
    case signInTypes.ALL_PERMISSIONS:
      return { ...state, allPermissions: action.data.allPermissions };
    case signInTypes.CHANGE_PROFILE_PHOTO:
      return { ...state, profilePix: action.data };
    case signInTypes.SIGN_OUT:
      return { ...initialState, sessionTimedOut: true };
    default:
      return state;
  }
};

export default authReducer;
