import React, { useState } from 'react';

export const useModal = modalFunction => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    modalFunction ? modalFunction() : setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return {
    isModalVisible,
    showModal,
    handleOk,
    handleCancel
  };
};
