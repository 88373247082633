import React from 'react';
import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
import SkilldormLogo from 'assets/images/skilldormblacklogo.png';
import certificateBg from 'assets/images/certificateBackground.png';

import InstitutionLogo from 'assets/images/partners logo/icsan.png';

const { Paragraph, Title } = Typography;
const Certificate = () => {
  return (
    <Container>
      <Img src={SkilldormLogo} alt="skilldormlogo" />
      <Row>
        <Col span={12}>
          <div style={{ position: 'relative' }}>
            <CertifyRow>
              <FirstCol></FirstCol>
              <SecondCol>
                <Paragraph>This verified certificate is presented to </Paragraph>
              </SecondCol>
            </CertifyRow>
          </div>
          <div>
            <Title>Bimpe Lola</Title>
            <Paragraph>
              Has successfully completed all courses and received passing grade for a verified
              certificate in
            </Paragraph>
          </div>
          <div>
            <Title>Introduction to Financial Management</Title>
            <Paragraph>
              A program offered by institute of chartered secretaries and Administrators of Nigeria
              (icsan) in partnership with Skilldorm an online learning platform
            </Paragraph>
          </div>
        </Col>
        <Col span={6} offset={6}>
          <InstructorDiv>
            <Signature>signature</Signature>
            <Name>Maria Simon</Name>
            <Profession>Professor</Profession>

            <Signature>signature</Signature>
            <Name>Maria Simon</Name>
            <Profession>Professor</Profession>
            <Signature>signature</Signature>
            <Name>Maria Simon</Name>
            <Profession>Professor</Profession>
          </InstructorDiv>
        </Col>
      </Row>
      <Row style={{ marginTop: '3rem' }}>
        <Col span={6}>
          <CertHeader>PROFESSIONAL CERTIFICATE</CertHeader>
          <CertText>Issued May 2021</CertText>
        </Col>
        <Col span={6}>
          <CertHeader>VALID CERTIFICATE ID</CertHeader>
          <CertText> SN:142398</CertText>
        </Col>
        <Col span={8} offset={4}>
          {/* <Img2 src={InstitutionLogo} alt="skilldormlogo" /> */}
          <Institution>ICSAN</Institution>
        </Col>
      </Row>
    </Container>
  );
};

export default Certificate;

const Container = styled.div`
  margin: auto;
  margin-top: 2em;
  background: url(${certificateBg}) no-repeat center center/cover;
  padding: 2em;
  width: 100%;
  min-height: 20em;
`;
const CertifyRow = styled(Row)``;

const FirstCol = styled(Col)``;

const SecondCol = styled(Col)``;

const Img = styled.img`
  margin-left: -1em;
  margin-bottom: 1em;
  width: 25%;
  height: 10%;
`;
const Img2 = styled.img`
  margin-left: 20%;
  margin-top: -1.5em;
`;
const InstructorDiv = styled.div`
  margin: 4em 0 0 0;
`;
const Signature = styled(Paragraph)`
  color: #333;
  margin-bottom: 0.3em !important;
  font-size: 2em;
  font-family: cursive;
  font-weight: lighter;
`;
const Name = styled(Paragraph)`
  color: #444;
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 0.2em !important;
`;
const Profession = styled(Paragraph)`
  color: #afafaf;
  margin-bottom: 2em !important;
`;

const CertHeader = styled(Paragraph)`
  margin-bottom: 0.2em !important;
  font-weight: bold;
  color: #afafaf;
  font-size: 0.9em;
`;
const CertText = styled(Paragraph)`
  font-size: 0.75em;
  color: #7f7f7f;
`;

const Institution = styled(Paragraph)`
  color: #222222;
  font-weight: bold;
  text-align: center;
  font-size: 3em;
`;
