import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Box, Button, Paragraph } from 'grommet';
import VideoIcon from 'assets/svgs/ic_videocam.svg';
import AudioIcon from 'assets/svgs/ic_audio.svg';
import ImageIcon from 'assets/svgs/ic_image.svg';
import FileIcon from 'assets/svgs/ic_file.svg';
import DeleteIcon from 'assets/svgs/ic_delete.svg';
import IconWrapper from 'components/common/IconWrapper';
import { Draggable } from 'react-beautiful-dnd';
import AppData from 'AppData';
import FileUploader from 'components/utils/CourseBuilder/FileUploader';
import Loader from 'components/common/Loader';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const contentType = {
  video: { icon: VideoIcon, assetType: 2 },
  audio: { icon: AudioIcon, assetType: 0 },
  image: { icon: ImageIcon, assetType: 1 },
  article: { icon: FileIcon, assetType: 3 },
  compressVideo: { icon: VideoIcon, assetType: 5 }
};

const CourseContent = ({
  type,
  contentCountData,
  contentId,
  moduleIndex,
  sectionIndex,
  setCourseDragData,
  contentIndex,
  setUploads,
  defaultFileName,
  defaultCourseContentData,
  isReview
}) => {
  const fileRef = useRef(null);
  const fileDeleteButton = useRef(null);
  const [fileName, setFileName] = useState(defaultFileName);
  const [showFileUploader, setShowFileUploader] = useState(!defaultFileName ? true : false);
  const [uploading, setUploading] = useState(false);
  const [courseContentData, setCourseContentData] = useState(defaultCourseContentData);
  // start chunk file uploader region
  const [showProgress, setShowProgress] = useState(false);
  const [counter, setCounter] = useState(1);
  const [fileToBeUpload, setFileToBeUpload] = useState({});
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
  const [endOfTheChunk, setEndOfTheChunk] = useState(AppData.chunkSize);
  const [progress, setProgress] = useState(0);
  const [fileGuid, setFileGuid] = useState('');
  const [fileSize, setFileSize] = useState(0);
  const [chunkCount, setChunkCount] = useState(0);

  useEffect(() => {
    if (fileSize > 0) {
      fileUpload(counter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileToBeUpload, progress]);

  const getFileContext = useCallback(file => {
    resetChunkProperties();
    const _file = file;
    setFileSize(_file.size);

    const _totalCount =
      _file.size % AppData.chunkSize === 0
        ? _file.size / AppData.chunkSize
        : Math.floor(_file.size / AppData.chunkSize) + 1; // Total count of chunks will have been upload to finish the file
    setChunkCount(_totalCount);

    setFileToBeUpload(_file);
    const _fileID = uuidv4() + '.' + _file.name.split('.').pop();
    setFileGuid(_fileID);
  }, []);

  const fileUpload = () => {
    if (counter === 1) {
      setUploading(true);
      setUploads(state => {
        const newState = Array.from(state);
        newState.push(true);
        return newState;
      });
    }
    setCounter(counter + 1);
    if (counter <= chunkCount) {
      var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
      uploadChunk(chunk);
    }
  };

  const uploadChunk = async chunk => {
    try {
      const response = await axios.post('Course/api/Course/UploadChunks', chunk, {
        params: {
          id: counter,
          fileName: fileGuid
        },
        headers: { 'Content-Type': 'application/json' }
      });
      const data = response.data;
      if (data.success) {
        setBeginingOfTheChunk(endOfTheChunk);
        setEndOfTheChunk(endOfTheChunk + AppData.chunkSize);
        if (counter === chunkCount) {
          await uploadCompleted();
        } else {
          var percentage = (counter / chunkCount) * 100;
          setProgress(percentage);
        }
      } else {
        setShowProgress(null);
        setUploading(false);
        setUploads(state => {
          const newState = Array.from(state);
          newState[newState.indexOf(true)] = false;
          return newState;
        });
      }
    } catch (error) {
      setShowProgress(null);
      setUploading(false);
      setUploads(state => {
        const newState = Array.from(state);
        newState[newState.indexOf(true)] = false;
        return newState;
      });
      console.log('error', error);
    }
  };

  const uploadCompleted = async () => {
    var formData = new FormData();
    formData.append('fileName', fileGuid);

    const response = await axios.post(
      'Course/api/Course/UploadComplete',
      {},
      {
        params: {
          fileName: fileGuid
        },
        data: formData
      }
    );

    const data = response.data;
    if (data.success) {
      setShowProgress(false);
      setUploading(false);
      setUploads(state => {
        const newState = Array.from(state);
        newState[newState.indexOf(true)] = false;
        return newState;
      });
      setProgress(100);
      setCourseContentData({
        assetType: contentType[type].assetType,
        guid: data.data.guid,
        url: data.data.url
      });
    }
  };

  const resetChunkProperties = () => {
    setShowProgress(true);
    setProgress(0);
    setCounter(1);
    setBeginingOfTheChunk(0);
    setEndOfTheChunk(AppData.chunkSize);
  };
  // end chunk file uploader region

  const calculateStateValue = (state, method) =>
    ({ increment: state + 1, decrement: state - 1 }[method]);

  const setAssetTypeCount = (type, method) => {
    switch (type) {
      case AppData.contentType.video:
        contentCountData.setVideoCount(state => calculateStateValue(state, method));
        break;
      case AppData.contentType.compressVideo:
        contentCountData.setCompressVideoCount(state => calculateStateValue(state, method));
        break;
      case AppData.contentType.audio:
        contentCountData.setAudioCount(state => calculateStateValue(state, method));
        break;
      case AppData.contentType.image:
        contentCountData.setImageCount(state => calculateStateValue(state, method));
        break;
      case AppData.contentType.article:
        contentCountData.setArticleCount(state => calculateStateValue(state, method));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setAssetTypeCount(type, 'increment');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCourseDragData(state => {
      const newState = { ...state };
      newState.courseModules[moduleIndex].items[sectionIndex].courseModuleItemAssets[
        contentIndex
      ] = {
        ...newState.courseModules[moduleIndex].items[sectionIndex].courseModuleItemAssets[
          contentIndex
        ],
        ...courseContentData
      };
      return newState;
    });
  }, [contentIndex, courseContentData, moduleIndex, sectionIndex, setCourseDragData]);

  useEffect(
    () => async () => {
      if (fileRef?.current?.files[0]) {
        const currentFileRef = fileRef.current;
        fileRef.current = null;
        getFileContext(currentFileRef.files[0]);
      }
    },
    [fileName, getFileContext]
  );

  return (
    <>
      <Draggable
        isDragDisabled={isReview}
        draggableId={contentId}
        key={contentId}
        index={contentIndex}>
        {provided => (
          <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
            <Box>
              <Box
                direction="row"
                align="center"
                justify="between"
                gap="small"
                pad="small"
                background="#d1eacb">
                <Box direction="row" align="center" gap="small">
                  {uploading ? (
                    <Loader color="#707070" containerWidth="80px" />
                  ) : (
                    <IconWrapper icon={contentType[type]?.icon} width="28px" height="28px" />
                  )}
                  {fileName && (
                    <Paragraph margin="none">
                      {fileName}
                      {showProgress !== false &&
                        `${
                          showProgress === null
                            ? ' | upload failed'
                            : showProgress && ` | ${progress.toFixed()}%`
                        }`}
                    </Paragraph>
                  )}
                </Box>
                {!isReview && (
                  <Button
                    ref={fileDeleteButton}
                    icon={<IconWrapper icon={DeleteIcon} width="20px" height="20px" />}
                    onClick={() => {
                      setAssetTypeCount(type, 'decrement');
                      if (uploading) {
                        setUploads(state => {
                          const newState = Array.from(state);
                          newState[newState.indexOf(true)] = false;
                          return newState;
                        });
                      }
                      setCourseDragData(state => {
                        const courseModules = [...state.courseModules];
                        courseModules[moduleIndex].items[
                          sectionIndex
                        ].courseModuleItemAssets.splice(contentIndex, 1);
                        const newState = {
                          ...state,
                          courseModules
                        };
                        return newState;
                      });
                    }}
                  />
                )}
              </Box>
            </Box>
          </div>
        )}
      </Draggable>
      {showFileUploader && (
        <FileUploader
          type={type}
          fileRef={fileRef}
          contentType={contentType}
          setFileName={setFileName}
          setShowFileUploader={setShowFileUploader}
          setCourseContentData={setCourseContentData}
          onClose={() => {
            setShowFileUploader(false);
            fileDeleteButton.current.click();
          }}
        />
      )}
    </>
  );
};

export default CourseContent;
