import React, { useState, useContext, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import AppText from 'components/common/AppText';
import Image from 'assets/images/Rectangle 3453.png';
import { limitLongText } from 'utils';
import { BeneficiaryContext } from 'context/BeneficiaryContext';
import RemoveCoursePrompt from './RemoveCoursePrompt';
import { message } from 'antd';

const ThirdPartyCourseCard = ({
  background,
  bordered,
  course,
  setBeneficiary,
  beneficiary,
  refetch,
  user
}) => {
  const quantityRef = useRef(course.quantity);
  const [quantity, setQuantity] = useState(course.quantity);
  const [showRemoveCourse, setShowRemoveCourse] = useState(false);
  const { setBeneficiaryData, beneficiaryData, setQuantityData } = useContext(BeneficiaryContext);
  const { courseId, price, id } = course;

  useEffect(() => {
    setQuantity(beneficiary.length);
    quantityRef.current = beneficiary.length;
  }, [beneficiary]);

  const handleChange = e => {
    const { value } = e.target;
    if (value !== 0 && !value) {
      return setQuantity(value);
    }
    if (value < 1 || value > 10) return message.warn('you can not add more than 10 beneficiary');
    setQuantity(value);
    if (Number(value) > Number(quantityRef.current)) {
      console.log('hello');
      let newlyAdded = [];
      const callback = prev => {
        console.log(14);
        const length = prev.length;
        const remaining = value - length;
        const newBeneficiaryArray = [...prev];
        for (var i = 0; i < remaining; i++) {
          const newId = uuidv4();
          newBeneficiaryArray.push({
            id: newId,
            firstName: '',
            lastName: '',
            courseId,
            pendingUserCourseId: id,
            email: '',
            courseAmount: price
          });
          newlyAdded.push({
            id: newId,
            firstName: '',
            lastName: '',
            pendingUserCourseId: id,
            courseId,
            email: '',
            courseAmount: price
          });
        }

        return newBeneficiaryArray;
      };

      setBeneficiary(callback);
      setBeneficiaryData(prev => [...prev, ...newlyAdded]);
    } else if (Number(value) < Number(quantityRef.current)) {
      const length = beneficiary.length;
      const removeCount = length - value;
      console.log('else', removeCount);
      const newBen = beneficiary.slice(0, -removeCount);
      const newBenData = beneficiaryData.slice(0, -removeCount);
      setBeneficiary(newBen);
      setBeneficiaryData(newBenData);
    }
    quantityRef.current = value;
  };

  const handleBlur = e => {
    const { value } = e.target;
    if (!value) {
      setQuantity(quantityRef.current);
    }
    onQuantityChange(Number(value), id);
  };

  const handleRemove = () => {
    setShowRemoveCourse(!showRemoveCourse);
  };

  // console.log(beneficiaryData)
  console.log(2, beneficiary, beneficiaryData);

  const onQuantityChange = async (data, id) => {
    const quantityData = [];
    const sendToApi = Array.from(beneficiaryData);
    sendToApi.forEach(item => {
      if (item.firstName === user?.firstName) {
        if (id === item.pendingUserCourseId) {
          quantityData.push({
            courseId: item.courseId,
            quantity: data,
            partnerId: course.partnerId
          });
        } else {
          quantityData.push({
            courseId: item.courseId,
            quantity: course.quantity,
            partnerId: course.partnerId
          });
        }
      }
    });
    const quantityPayload = quantityData;
    setQuantityData(quantityPayload);
  };

  return (
    <Container bg={background} bordered={bordered}>
      <RemoveCoursePrompt
        course={course}
        isModalVisible={showRemoveCourse}
        setIsModalVisible={handleRemove}
        refetch={refetch}
      />
      <LeftDiv>
        <ImgDiv>
          <Img src={course.thumbnailUrl ?? Image} alt="" />
        </ImgDiv>
        <TextDiv>
          <AppText
            value={course.title}
            size={'24px'}
            weight={700}
            lineHeight={'150%'}
            bottom={'5px'}
          />
          <RatingsDiv>
            <AppText
              value={
                <>
                  Course offered by :<strong>ICSAN</strong>
                </>
              }
              size={'12px'}
              weight={500}
              lineHeight={'150%'}
              bottom="0"
            />
          </RatingsDiv>
          <AppText
            value={limitLongText(course.description, 100)}
            size={'12px'}
            color={'#546069'}
            bottom={'8px'}
          />
          <QuantityDiv>
            <AppText value={'QTY'} size={'12px'} weight={700} bottom={0} />
            <Input
              type="number"
              min={1}
              max={10}
              value={quantity}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </QuantityDiv>
        </TextDiv>
      </LeftDiv>
      <RightDiv>
        <AppText value={`N${course.price}`} size={'20px'} weight={700} color={'#0966b8'} />
        <AppText
          value={'Remove'}
          size={'14px'}
          weight={'600'}
          color={'#FC391D'}
          style={{ cursor: 'pointer ' }}
          onClick={handleRemove}
        />
      </RightDiv>
    </Container>
  );
};

export default ThirdPartyCourseCard;

const Container = styled.div`
  width: 100%;
  border: ${props => (props.bordered ? '1px solid #D7DBDD' : 'none')};
  background: ${props => (props.bg ? props.bg : '#fff')};
  padding: 2rem;
  display: flex;
  justify-content: space-between;
`;
const LeftDiv = styled.div`
  display: flex;
  gap: 2rem;
`;
const ImgDiv = styled.div`
  width: 179px;
  height: 146px;
  border-radius: 5px;
`;

const Img = styled.img`
  width: 100%;
  height: auto;
`;
const TextDiv = styled.div``;
const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: flex-end;
`;

const RatingsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 5px;
`;

const QuantityDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Input = styled.input`
  border: 1px solid #0966b8;
  background: #ebf5fe;
  padding: 14px;
  outline: none;
  width: 62px;
  height: 38px;
`;
