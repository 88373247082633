import React, { useEffect } from 'react';
import styled from 'styled-components';

import AppText from 'components/common/AppText';
import image from 'assets/images/image20.png';
import { showToast } from 'store/actions/toast';
import { home } from 'userRoutes';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const NoPendingCourse = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(showToast('error', 'The Verification link has either been used or expired'));
  }, [dispatch]);

  const handleRedirect = () => {
    history.push(home);
  };

  return (
    <Container>
      <Div>
        <img src={image} alt="" />
        <AppText size={'20px'} weight={500} value={'No available Course'} />

        <BtnDiv onClick={handleRedirect}>Return home</BtnDiv>
      </Div>
    </Container>
  );
};

export default NoPendingCourse;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const Div = styled.div`
  padding-top: 7rem;
  text-align: center;
`;

const BtnDiv = styled.div`
  border-radius: 33px;
  background: #ccefff;
  height: 66px;
  width: 249px;
  color: #0966b8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 1rem;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`;
