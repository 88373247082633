import React from 'react';
import { Row, Typography } from 'antd';
import Settings from 'pages/admin/Settings';

const Privacy = () => {
  const { Text } = Typography;

  return (
    <Settings selectedKey="Privacy">
      <Row>
        <Text>Coming soon...</Text>
      </Row>
    </Settings>
  );
};

export default Privacy;
