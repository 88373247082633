import Axios from 'axios';

const service = 'report';

export const auditTrailReport = async (
  key,
  search = '',
  id = 0,
  institutionId,
  page = 1,
  pageSize = 10,
  startDate = '',
  endDate = '',
  toggleExport
) => {
  const { data } = await Axios.get(
    `/${service}/api/Report/AuditTrail?Export=${toggleExport}&Search=${search}&Id=${id}&InstitutionId=${institutionId}${
      startDate ? `&StartDate=${startDate}&EndDate=${endDate}` : ''
    }&Page=${page}&PageSize=${pageSize}`
  );
  return data;
};

export const manageStudent = async (
  key,
  search = '',
  courseId = 0,
  page = 1,
  pageSize = 10,
  startDate = '',
  endDate = '',
  toggleExport
) => {
  const { data } = await Axios.get(
    `/${service}/api/Report/GetStudentEnrolled?Export=${toggleExport}&Search=${search}&CourseId=${courseId}${
      startDate ? `&StartDate=${startDate}&EndDate=${endDate}` : ''
    }&Page=${page}&PageSize=${pageSize}`
  );
  return data;
};

export const coursePaymentReport = async (
  key,
  search = '',
  id = 0,
  page = 1,
  pageSize = 10,
  startDate = '',
  endDate = '',
  tranStatus = 1,
  toggleExport
) => {
  const { data } = await Axios.get(
    `/${service}/api/Report/GetCoursePayment?Export=${toggleExport}&Search=${search}&InstitutionId=${id}${
      startDate ? `&StartDate=${startDate}&EndDate=${endDate}` : ''
    }&Page=${page}&PageSize=${pageSize}&TransactionStatus=${tranStatus}`
  );
  return data;
};

export const revenuePerPartner = async (key, partnerId, page = 1, pageSize = 10, toggleExport) => {
  const { data } = await Axios.get(
    `/${service}/api/Report/GetRevenuePerPartner?Export=${toggleExport}${
      partnerId !== 0 ? `&PartnerId=${partnerId}` : ``
    }&Page=${page}&PageSize=${pageSize}`
  );
  return data;
};
export const revenuePerInstitution = async (
  key,
  institutionId,
  page = 1,
  pageSize = 10,
  toggleExport
) => {
  const { data } = await Axios.get(
    `/${service}/api/Report/GetRevenuePerInstitution?Export=${toggleExport}${
      institutionId !== 0 ? `&InstitutionId=${institutionId}` : ``
    }&Page=${page}&PageSize=${pageSize}`
  );
  return data;
};

export const courseInsights = async (
  key,
  search = '',
  institutionId = 0,
  page = 1,
  pageSize = 10,
  startDate = '',
  endDate = '',
  toggleExport
) => {
  const { data } = await Axios.get(
    `/${service}/api/Report/GetCourseInsights?Export=${toggleExport}&Search=${search}&InstitutionId=${institutionId}${
      startDate ? `&StartDate=${startDate}&EndDate=${endDate}` : ''
    }&Page=${page}&PageSize=${pageSize}`
  );
  return data;
};

export const courseInsightsOverview = async (
  key,
  search = '',
  id = 0,
  page = 1,
  pageSize = 10,
  startDate = '',
  endDate = '',
  toggleExport
) => {
  const { data } = await Axios.get(
    `/${service}/api/Report/GetCourseInsightsOverview?Export=${toggleExport}&Search=${search}${
      startDate ? `&StartDate=${startDate}&EndDate=${endDate}` : ''
    }&Page=${page}&PageSize=${pageSize}`
  );
  return data;
};

export const registeredUser = async (
  key,
  search = '',
  id = 0,
  page = 1,
  pageSize = 10,
  startDate = '',
  endDate = '',
  toggleExport
) => {
  const { data } = await Axios.get(
    `/${service}/api/Report/GetRegisteredUsers?Export=${toggleExport}&Search=${search}${
      startDate ? `&StartDate=${startDate}&EndDate=${endDate}` : ''
    }&Page=${page}&PageSize=${pageSize}`
  );
  return data;
};
