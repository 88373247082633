import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { ButtonCTA } from 'components/common/ButtonCTA';
import { SurveyContext } from 'context/SurveyContext';
import { createSurvey, updateSurvey } from 'api/mutations/Course';
import { showToast } from 'store/actions/toast';

const SurveySubmit = ({ setCreateSurvey }) => {
  const [loading, setLoading] = useState();
  const [isDraft, setIsDraft] = useState(false);
  const [success, setSuccess] = useState(false);
  const { survey, setSurvey, isEdit, setIsEdit } = useContext(SurveyContext);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (success) {
      dispatch(
        showToast(
          'success',
          isEdit && !isDraft
            ? 'Survey updated successfully'
            : !isEdit && isDraft
            ? 'Saved as draft'
            : isEdit && isDraft
            ? 'Draft updated successfully'
            : 'Survey created successfully'
        )
      );
      setCreateSurvey(false);
      setIsEdit(false);
    }
  }, [success]);

  const [mutate] = useMutation(isEdit ? updateSurvey : createSurvey, {
    onMutate: () => setLoading(true),
    onSuccess: data => {
      // setSurvey(null);
      setLoading(false);
      setSuccess(true);
    },
    onError: error => {
      setLoading(false);
      if (error.message === 'Network Error') {
        dispatch(
          showToast(
            'error',
            "We couldn't connect to the network, check your network or contact your admin"
          )
        );
      } else {
        dispatch(showToast('error', error.message));
      }
    }
  });

  const handleClick = draft => {
    setIsDraft(draft);
    const noQuestion = survey.questions.some(el => !el.question);
    if (noQuestion)
      return dispatch(showToast('error', 'Please type in  questions where necessary'));

    const newSurvey = { ...survey };
    const newQuestions = newSurvey.questions.map(question => {
      delete question.id;
      const newOptions = question.options
        ? question.options.map(option => {
            delete option.id;
            return option;
          })
        : null;
      // console.log('newOptions', newOptions);
      question['options'] = newOptions;
      return question;
    });

    // console.log('newSurvey', { ...newSurvey, questions: newQuestions });
    // console.log('oldSurvey', survey);

    mutate([{ ...newSurvey, questions: newQuestions }, draft]);
  };

  const handleCancel = () => {
    setSurvey(null);
    setIsEdit(false);
    setCreateSurvey(false);
  };

  return (
    <BtnDiv>
      <ButtonCTA
        color="#5D5D5D"
        border="1px solid #5D5D5D"
        bgColor="#fff"
        borderRadius="3px"
        width="100px"
        fontSize="16px"
        onClick={handleCancel}
        height="35px">
        Back
      </ButtonCTA>

      <InnerBtnDiv>
        <ButtonCTA
          color="#5D5D5D"
          border="1px solid #5D5D5D"
          bgColor="#fff"
          borderRadius="3px"
          width="127px"
          fontSize="16px"
          loading={loading && isDraft}
          onClick={() => handleClick(true)}
          height="35px">
          Save as draft
        </ButtonCTA>
        <ButtonCTA
          color="#fff"
          bgColor="#0966B8"
          borderRadius="3px"
          width="107px"
          fontSize="16px"
          onClick={() => handleClick(false)}
          loading={loading && !isDraft}
          height="35px">
          Confirm
        </ButtonCTA>
      </InnerBtnDiv>
    </BtnDiv>
  );
};

export default SurveySubmit;

const BtnDiv = styled.div`
  padding-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const InnerBtnDiv = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
