import React from 'react';
import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import VideoBg from 'assets/images/explorelearning.png';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const CareerPath = props => (
  <CareerContainer>
    <CareerTitle>Learning Paths designed by Experts</CareerTitle>
    <CareerVideoCol>
      <CareerVideo
        // ref={videoRef}
        // onDuration={handleDuration}
        // className="react-player"
        // url={videoSrc || firstVideo}
        width="100%"
        height="100%"
        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
        onContextMenu={e => e.preventDefault()}
        // light={VideoBg}
        controls
        // playing={playing}

        // loop={loop}

        onReady={() => {
          console.log('onReady');
          //   setState({ playing: true });
        }}
        onStart={() => console.log('onStart')}
      />
      <CareerInfo>
        <CareerInfoTitle>Explore the power of learning</CareerInfoTitle>
        <CareerInfoParagraph>
          Learn by doing with real-life projects and other hands-on exercises that lead to real
          skill mastery.
        </CareerInfoParagraph>
        <CareerInfoParagraph>
          Discover the fastest most effective way to gain job ready expertise for the Careers of the
          future
        </CareerInfoParagraph>
      </CareerInfo>
    </CareerVideoCol>
  </CareerContainer>
);

export default CareerPath;

const { Title, Paragraph } = Typography;

const CareerContainer = styled(Row)`
  margin: 0rem 0 2rem 0;
  @media ${mobile} {
    margin-top: 26rem;
  }
`;

const CareerTitle = styled(Title)`
  font-size: 3.8em !important;
  color: #0966b8 !important;
  margin: auto;
  @media ${mobile} {
    font-size: 1.5em !important;
    text-align: center;
  }
`;
const CareerVideoCol = styled(Col)`
  margin: auto;
  width: 85%;
  height: 100vh;
  position: relative;
  &::after {
    content: '';
    background: url(${VideoBg}) no-repeat center/cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
  @media ${mobile} {
    height: 10rem;
  }
`;
const CareerVideo = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;
const CareerInfo = styled(Col)`
  position: absolute;
  top: 0%;
  left: 3%;
  width: 25rem;
  height: 32rem;
  background: #0966b8;
  color: #fff !important;
  z-index: 60;
  padding: 4rem 2rem 2rem 2rem;
  @media ${mobile} {
    padding-top: 3rem 1.5rem 1.5rem 1.5rem;
    width: 18rem;
    top: 60%;
    height: 20rem;
    left: 5%;
  }
`;
const CareerInfoTitle = styled(Title)`
  color: #fff !important;
  font-size: 2.5em !important;
  @media ${mobile} {
    font-size: 1.2em !important;
  }
`;
const CareerInfoParagraph = styled(Paragraph)`
  color: #fff;
  font-size: 1.25em;
  line-height: 1.2;
  font-weight: lighter;
  @media ${mobile} {
    font-size: 0.87em !important;
  }
`;
