import React from 'react';
import CourseSetup from 'components/common/CourseSetup';
import MainLayout from 'components/MainLayout';

const CreateCourse = () => {
  return (
    <MainLayout isAdmin sidebar padded selectedKey="manageCourse">
      <CourseSetup />
    </MainLayout>
  );
};

export default CreateCourse;
