import React from 'react';
import styled from 'styled-components';
import { Col, Space, Row, Typography } from 'antd';

import digitalSkills from 'assets/images/landing page Assets/Home landing page/digitalSkills.png';
import digitalLiteracy from 'assets/images/landing page Assets/Home landing page/digitalLiteracy.png';
import telecom from 'assets/images/landing page Assets/Home landing page/telecom.png';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;
const AboutImageGrid = ({ isMobile }) => {
  return (
    <Col md={10} offset={!isMobile && 2}>
      <Row gutter={40}>
        <ImgCol xs={{ span: 24 }} lg={{ span: 12 }} style={{}}>
          <ImgDiv image={digitalSkills}>
            <ImgText>Productivity</ImgText>
          </ImgDiv>
        </ImgCol>
        <ImgCol xs={{ span: 24 }} lg={{ span: 12 }}>
          <ImgDiv image={digitalLiteracy}>
            <ImgText>Digital Skills</ImgText>
          </ImgDiv>
        </ImgCol>
      </Row>
      <Row>
        <BottomCol span={24}>
          <ImgDiv image={telecom}>
            <ImgText>Leadership, Business & Management</ImgText>
          </ImgDiv>
        </BottomCol>
      </Row>
    </Col>
  );
};

AboutImageGrid.propTypes = {};

export default AboutImageGrid;

const ImgCol = styled(Col)`
  @media ${mobile} {
    margin-top: 2.5rem;
  }
`;

const ImgDiv = styled.div`
  background: ${props => `url(${props.image}) no-repeat center center/cover`};
  height: 10em;
  position: relative;
  &::after {
    position: absolute;
    top: 0;
    content: '';
    background: rgba(0, 0, 0, 0.45);
    height: 100%;
    width: 100%;
  }
  @media ${mobile} {
  }
`;

const ImgText = styled(Typography.Paragraph)`
  color: #fff;
  position: relative;
  z-index: 2;
  top: 60%;
  padding-left: 1em;
  font-weight: bolder;
  font-size: 1.4em;
  @media ${mobile} {
  }
`;
const BottomCol = styled(Col)`
  margin-top: 40px;
`;
