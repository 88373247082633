import React, { createContext, useState } from 'react';

export const SurveyContext = createContext();

export const SurveyProvider = ({ children }) => {
  const [survey, setSurvey] = useState();
  const [surveyId, setSurveyId] = useState();
  const [surveyTitle, setSurveyTitle] = useState();
  const [isEdit, setIsEdit] = useState();

  return (
    <SurveyContext.Provider
      value={{
        survey,
        setSurvey,
        isEdit,
        setIsEdit,
        surveyId,
        setSurveyId,
        surveyTitle,
        setSurveyTitle
      }}>
      {children}
    </SurveyContext.Provider>
  );
};
