import React from 'react';

import { Close } from 'grommet-icons';

import { Box, Button, Heading, Layer, ResponsiveContext, Text } from 'grommet';
import { isMobile } from 'utils';

const Modal = ({
  onClose,
  modal,
  title,
  subTitle,
  children,
  footerCancel,
  footerSave,
  footerCustomButtonLabel,
  footerCustomButtonDisabled,
  footerCustomButtonHandler,
  width = '575px'
}) => {
  const size = React.useContext(ResponsiveContext);

  return (
    <Layer
      style={{ zIndex: '10', marginTop: '30px' }}
      responsive={false}
      animation="slide"
      onClickOutside={onClose}
      onEsc={onClose}
      modal={modal}>
      <Box style={{ minWidth: isMobile(size) ? '275px' : width, maxHeight: '500px' }}>
        {title && (
          <Box
            as="header"
            elevation="small"
            style={{ minHeight: '55px' }}
            pad={{ left: 'medium', right: 'medium', top: 'small', bottom: 'small' }}>
            <Box margin={{ bottom: 'xsmall' }} direction="row" align="center" justify="between">
              <Heading level={4} margin="none" size="small">
                {title}
              </Heading>
              <Button style={{ padding: 0 }} icon={<Close />} onClick={onClose} />
            </Box>
            <Text truncate={isMobile(size) && true} size="xsmall">
              {subTitle}
            </Text>
          </Box>
        )}
        <Box flex overflow="auto" pad="medium">
          {children}
        </Box>
        {(footerCancel || footerSave || footerCustomButtonLabel) && (
          <Box
            as="footer"
            border={{ side: 'top' }}
            pad="small"
            gap="small"
            justify="end"
            direction="row"
            align="center"
            style={{ minHeight: '55px' }}>
            {footerCancel && <Button secondary onClick={onClose} label={footerCancel} />}
            {footerSave && <Button type="submit" primary label={footerSave} />}
            {footerCustomButtonLabel && (
              <Button
                primary
                disabled={footerCustomButtonDisabled}
                label={footerCustomButtonLabel}
                onClick={footerCustomButtonHandler}
              />
            )}
          </Box>
        )}
      </Box>
    </Layer>
  );
};
export default Modal;
