import React, { useEffect, useState } from 'react';
import 'styles/utils.css';
import { Box, Text, Button, ResponsiveContext, DataChart } from 'grommet';
import PageTitle from 'components/common/PageTitle';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createcourse } from 'adminRoutes';
import { isMobile } from 'utils';
import { courseStatistics, enrollmentStatistics } from 'api/queries/Course';
import { useQuery } from 'react-query';
import MainLayout from 'components/MainLayout';
import usePermission from 'components/hooks/usePermission';
import { clearCourse } from 'store/actions/course';
import AppData from 'AppData';
import CourseInteractions from 'components/common/CourseInteractions';
import { Tag } from 'antd';

const Dashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [graphData, setGraphData] = useState();
  const { checkUserPermission } = usePermission();
  const size = React.useContext(ResponsiveContext);
  const auth = useSelector(state => state.auth);
  const { data: enrollmentResults } = useQuery(
    ['enrollmentStatistics', auth.institutionId],
    enrollmentStatistics
  );
  const { data: courseResults } = useQuery(
    ['courseStatistics', auth.institutionId],
    courseStatistics
  );

  const createCourseHandler = () => {
    dispatch(clearCourse());
    history.push(createcourse);
  };
  const data = [
    {
      date: 'Jan',
      value: 0
    },
    {
      date: 'Feb',
      value: 0
    },
    {
      date: 'Mar',
      value: 0
    }
  ];
  useEffect(() => {
    if (enrollmentResults?.success) {
      var NewData = enrollmentResults?.data?.map(item => {
        return {
          date: item.key,
          value: item.value
        };
      });
      setGraphData(NewData);
    }
  }, [enrollmentResults]);

  return (
    <MainLayout isAdmin sidebar padded selectedKey="dashboard">
      <Box pad="medium" background="#fff">
        <>
          <Box direction="row" justify="between">
            <Box
              direction="column"
              width={'30%'}
              round="xsmall"
              elevation="small"
              pad={{ left: 'medium', right: 'small' }}
              justify="center"
              align="start"
              background="#0966b8">
              <Text size="xsmall">Welcome Back,</Text>
              <Box direction="row" margin={{ bottom: '20px' }}>
                <Text size="28px">
                  {`${auth.firstName}`}{' '}
                  <Text weight="bold" size="28px">
                    <strong> {`${auth.lastName}!`}</strong>
                  </Text>
                </Text>
              </Box>
              <Text size="xsmall">
                {`${auth?.role?.charAt(0)?.toUpperCase()}${auth?.role?.slice(1)?.toLowerCase()}`} -
                <Tag style={{ marginLeft: 2 }}> {auth?.institutionCode}</Tag>
              </Text>
            </Box>
            <Box
              direction="row"
              width={'30%'}
              round="xsmall"
              elevation="small"
              gap="small"
              pad="medium"
              align="center">
              <DataChart
                style={{ height: '100%' }}
                guide
                size={{ height: 'fill' }}
                pad={{ horizontal: 'xxsmall', vertical: 'xxsmall' }}
                data={graphData ? graphData : data}
                gap="xsmall"
                series={[
                  {
                    property: 'date',
                    render: date => (
                      <Text margin={{ horizontal: '-2px' }}>{date.substring(0, 3)}</Text>
                    )
                  },
                  'value'
                ]}
              />
            </Box>
            <Box gap="small" width={'30%'}>
              <Box
                width="fill"
                background="#0966b8"
                round="xsmall"
                elevation="small"
                pad="small"
                justify="center"
                align="center">
                <Text size="xsmall">Active Courses</Text>
                <Text weight="bold" size="28px">
                  {courseResults ? courseResults?.data.activeCourses : 'N/A'}
                </Text>
              </Box>
              <Box
                width="fill"
                round="xsmall"
                elevation="small"
                pad="small"
                justify="center"
                align="center">
                <Text size="xsmall">Pending Course Approval</Text>
                <Text weight="bold" size="28px">
                  {courseResults ? courseResults?.data.pendingApproval : 'N/A'}
                </Text>
              </Box>
            </Box>
          </Box>
        </>
        <Box margin={{ top: 'large' }} gap={isMobile(size) ? 'large' : 'small'}>
          {checkUserPermission(AppData.permissions.createCourse) && (
            <Box background="#000" pad="large" round="10px">
              <PageTitle
                color="#fff"
                pageTitle="Create an Engaging Course"
                pageDescription="Set your course up for success by building your course with highly impactful content"
                pageActionElement={
                  <Button label="Create Course" primary onClick={createCourseHandler} />
                }
              />
            </Box>
          )}
          <div>
            <CourseInteractions />
          </div>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default Dashboard;
