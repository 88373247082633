import React from 'react';
import { Row, Typography, Button } from 'antd';
import styled from 'styled-components';
import { FaAngleRight } from 'react-icons/fa';
import { discovery } from 'userRoutes';
import { useHistory } from 'react-router-dom';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const ActiveLearningJumbotron = ({ heading, content }) => {
  const history = useHistory();
  return (
    <Container>
      <NewHeading>{heading}</NewHeading>
      <Paragraph>{content}</Paragraph>
      {/* <ButtonDiv>
        <NewButton onClick={() => history.push(discovery)}>
          Explore courses
          <Typography.Text style={{ position: 'relative' }}>
            <Caret />
          </Typography.Text>
        </NewButton>
      </ButtonDiv> */}
    </Container>
  );
};

export default ActiveLearningJumbotron;

const Container = styled(Row)`
  width: 60%;
  margin-top: 3rem;
  @media ${mobile} {
    width: 100%;
  }
`;

const NewHeading = styled(Typography.Title)`
  color: #fff !important;
  font-size: 2.8em !important;
  margin-bottom: 0rem !important;
  line-height: 3rem !important;
  @media ${mobile} {
    font-size: 1.55em !important;
    margin: auto !important;
    margin-top: 3.5rem !important;
    text-align: center;
    line-height: 1.2 !important;
  }
`;

const Paragraph = styled(Typography.Paragraph)`
  font-size: 1.4em;
  margin: 0 0 !important;
  size: 2em;
  font-weight: lighter;
  line-height: 1.7;
  color: #fff;
  margin-top: 0em !important;
  @media ${mobile} {
    font-size: 1.2em !important;
    text-align: center;
  }
`;

const ButtonDiv = styled.div`
  position: relative;
  margin-top: 2em !important;
  height: 3.5rem;
  width: 15em;
  @media ${mobile} {
    height: 2rem;
    width: 14em;
    margin: auto !important;
    margin-top: 2em !important;
  }
`;

const NewButton = styled(Button)`
  position: absolute;
  background: #0966b8;
  border: none;
  height: 3.5rem;
  color: #fff;
  width: 11em;
  font-size: 18px;
  padding: 0 0.2em 0 0em;
  border-radius: 1.5rem;
  margin-top: 2em !important;
  &::placeholder {
    margin: 0 0 0 0;
  }
  @media ${mobile} {
    width: 14em;
    font-size: 15px;
    height: 3rem;
  }
`;

const Caret = styled(FaAngleRight)`
  color: #fff;
  position: absolute;
  top: -0.8em;
  left: 0.3em;
`;
