import React from 'react';
import { Col, Row } from 'antd';
import CourseCard from 'components/common/CourseCard';

const ApproveCourseList = ({ courses, setShowCourseSetup }) => {
  return (
    <Row gutter={[16, 16]}>
      {courses &&
        courses.map(course => (
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            xxl={{ span: 6 }}
            key={course.courseId}>
            <CourseCard
              courseId={course.courseId}
              title={course.title}
              description={course.description}
              modules={course.modules}
              duration={course.duration}
              level={course.courseLevelName}
              category={course.courseCategoryName}
              institutionName={course.institution}
              courseThumbNailUrl={course.courseThumbNailUrl}
              publishDate={course.publishDate}
              price={
                course.coursePrice
                  ? `${course.coursePrice.amount}`
                  : course.price
                  ? `${course.price}`
                  : null
              }
              navigation={false}
              setShowCourseSetup={setShowCourseSetup}
              isPrice
              isReview
            />
          </Col>
        ))}
    </Row>
  );
};

export default ApproveCourseList;
