import React from 'react';
import 'styles/utils.css';
import { Box, Text, Heading, ResponsiveContext, Image, Card, CardBody, CardFooter } from 'grommet';
import CourseImage from 'assets/images/coursesdis.png';
import { isMobile } from 'utils';
import { institutionlanding } from 'userRoutes';
import { useHistory } from 'react-router-dom';

const InstitutionProgramCard = ({ title, learner, course, courseThumbNailUrl, data }) => {
  const size = React.useContext(ResponsiveContext);
  const history = useHistory();

  const redirectToInstitutionLanding = async data => {
    try {
      history.push(institutionlanding, { data: data });
    } catch (error) {}
  };

  return (
    <Box
      width={isMobile(size) ? '100%' : '295px'}
      pad={{ vertical: 'medium' }}
      style={{ zIndex: 1 }}
      align="start">
      <Card className="course-card" elevation="large" width="350px">
        <CardBody style={{ position: 'relative' }} height="small">
          <Image
            fit="cover"
            src={courseThumbNailUrl ? encodeURI(courseThumbNailUrl) : CourseImage}
            onClick={() => {
              redirectToInstitutionLanding(data);
            }}
          />
        </CardBody>
        <Box pad={{ horizontal: 'small' }} responsive={false}>
          <Box margin={{ vertical: 'small' }}>
            <Box>
              <Heading
                style={{ fontSize: 24 }}
                level="4"
                margin="none"
                onClick={() => {
                  redirectToInstitutionLanding(data);
                }}>
                {title}
              </Heading>
            </Box>
          </Box>
        </Box>
        <CardFooter background="none">
          <Box
            width="full"
            wrap={true}
            pad={{ horizontal: 'small', bottom: 'small' }}
            direction={isMobile(size) ? 'column' : 'row'}
            align={isMobile(size) ? 'start' : 'center'}
            justify="between">
            <Box margin={{ top: 'none' }} direction="column" align="start">
              <Text style={{ fontSize: 12 }} color="#7e7e7e">
                {`${learner} ${learner > 1 ? 'Learners' : 'Learner'}`}
              </Text>
              <Text style={{ fontSize: 12 }} color="#7e7e7e">
                {course} Courses
              </Text>
            </Box>
          </Box>
        </CardFooter>
      </Card>
    </Box>
  );
};

export default InstitutionProgramCard;
