import React, { useState } from 'react';
import { Box, Button, ThemeContext, Pagination, Heading } from 'grommet';
import EmailNotifyCard from 'components/common/EmailNotifyCard';
import AddIcon from 'assets/svgs/ic_add.svg';
import IconWrapper from 'components/common/IconWrapper';
import PageHeader from 'components/common/PageHeader';
import { useQuery } from 'react-query';
import { getEmailTemplateList, toggleTemplate } from 'api/queries/Notification';
import { useEffect } from 'react';
import PageLoader from 'components/common/PageLoader';
import { useHistory } from 'react-router-dom';
import ConfigurationSettings from 'pages/admin/Settings';
import { editemail, newemail } from 'adminRoutes';
import { clearEmailState } from 'store/actions/email';
import { useDispatch } from 'react-redux';

const EmailTemplate = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [templateData, setTemplateData] = useState(null);
  const [templateId, setTemplateId] = useState(null);
  const [page, setpage] = useState(1);
  const [pageSize, setpageSize] = useState(10);

  const { data, isLoading, refetch: reload } = useQuery(
    ['emailtemplates', page, pageSize],
    getEmailTemplateList
  );

  const { refetch, isFetching: toggleLoading } = useQuery(['toogle', templateId], toggleTemplate, {
    refetchOnWindowFocus: false,
    enabled: templateId
  });

  useEffect(() => {
    if (data) setTemplateData(data.data);
  }, [data]);

  useEffect(() => {
    dispatch(clearEmailState());
  }, [dispatch]);

  const onPageChanged = currentPage => {
    // const {currentPage} =data
    setpage(currentPage.page);
  };

  return (
    <ConfigurationSettings selectedKey="Email Management">
      <Box margin={{ bottom: 'small' }}>
        <PageHeader marginBottom="none" pageTitle="Email Notification" />
        <Box title="">
          <ThemeContext.Extend value={{ global: { colors: { doc: '' } } }}>
            <Box
              pad="xxsmall"
              margin={{ bottom: 'medium' }}
              width="100%"
              elevation="small"
              background="doc"
            />
          </ThemeContext.Extend>
          <Box background="light-2" pad="small" direction="row" justify="between">
            <Heading margin="none" level="5" style={{ fontSize: '1.25rem' }}>
              Email Name
            </Heading>
            <Heading margin="none" level="5" style={{ fontSize: '1.25rem' }}>
              Action
            </Heading>
          </Box>
          {templateData &&
            templateData?.items?.map(list => (
              <EmailNotifyCard
                key={list.id}
                status={list.isActive}
                checkedFunc={() => {
                  setTemplateId(list.id);
                  if (templateId) {
                    refetch();
                  }
                  setTimeout(() => {
                    reload();
                  }, 2000);
                }}
                cardTitle={list.name}
                cardAction="Edit"
                editFunc={() => {
                  history.push(`${editemail.replace('/:editemail', '')}/${list.id}`);
                }}
              />
            ))}
          {templateData && (
            <Pagination
              step={pageSize}
              page={page}
              numberItems={templateData && templateData.totalCount}
              onChange={onPageChanged}
            />
          )}
          <Box
            direction="row"
            pad={{ top: 'medium', bottom: 'small', right: 'medium' }}
            gap="medium"
            justify="end">
            <Button
              plain
              icon={<IconWrapper icon={AddIcon} width="16px" height="16px" />}
              size="small"
              onClick={() => {
                dispatch(clearEmailState());
                history.push(newemail);
              }}
              margin={{ top: 'medium', right: 'xsmall' }}
              label="Add"
            />
          </Box>
        </Box>
      </Box>
      <PageLoader
        tip={toggleLoading ? 'toggling template...' : 'fetching templates..'}
        isLoading={toggleLoading || isLoading}
      />
    </ConfigurationSettings>
  );
};

export default EmailTemplate;
