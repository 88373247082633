import React from 'react';
import styled from 'styled-components';
import { ImageWrapper } from './ImageWrapper';
import { Typography } from 'antd';
import CourseLanRating from './CourseLanRating';

const CustomLogoWrapper = ({ src, title, subtitle, instructor }) => {
  return (
    <div>
      <CardWrapper>
        <div>
          <ImageContainer>
            <ImageWrapper width="100%" height="100%" src={src} preview={false} />
          </ImageContainer>
        </div>
        <div>
          <Title>{title}</Title>
          <WrapInstructor>
            <SubTitle>{subtitle}</SubTitle>
            {instructor && (
              <WrapRate>
                <CourseLanRating size="16px" reviews={5} />
              </WrapRate>
            )}
          </WrapInstructor>
        </div>
      </CardWrapper>
    </div>
  );
};

export default CustomLogoWrapper;

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const WrapInstructor = styled.div`
  display: flex;
  align-items: center;
`;
const WrapRate = styled.div`
  margin-left: 10px;
`;

const ImageContainer = styled.div`
  margin-right: 5px;
  border-radius: 50%;
  width: 56px;
  overflow: hidden;
  height: 56px;
`;

const Title = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
  font-size: 16px;
`;
const SubTitle = styled(Typography.Text)`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
`;
