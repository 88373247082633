import React from 'react';
import styled from 'styled-components';
import { Input, Col } from 'antd';
import { FaSearch } from 'react-icons/fa';
import { SearchOutlined } from '@ant-design/icons';

const SearchInput2 = ({
  color,
  showFunc,
  marginLeft,
  handleChange,
  focus = false,
  showBorder = true
}) => {
  return (
    <Col span={14}>
      <Search>
        <Span marginLeft={marginLeft} showBorder={showBorder}>
          <Input2
            placeholder="What would you like to learn"
            onFocus={() => showFunc && showFunc(true)}
            onChange={handleChange}
            autoFocus={focus}
          />
          <Suffix>
            <SearchIcon color={color} />
          </Suffix>
        </Span>
      </Search>
    </Col>
  );
};

export default SearchInput2;

const Search = styled.div`
  background: none;
  border: ${({ color }) => `1px solid ${color}`};
  border-radius: 2em;
  z-index: 160;
`;

const Span = styled.span`
  background: none;
  border: ${props => (props.showBorder ? '1px solid #fff' : 'none')};
  border-radius: 2em;
  margin-top: 0.5em;
  z-index: 160;
  padding: 6.5px 11px;
  font-size: 16px;
  position: relative;
  margin-left: ${props => props.marginLeft};
  width: 100%;
  min-width: 0;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  transition: all 0.3s;
  display: inline-flex;
`;

const Input2 = styled.input`
  box-sizing: border-box;
  margin: 0;
  padding: 0 !important;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: none;
  border-radius: 2px;
  transition: all 0.3s;
  font-size: 16px;
  background: none !important;
  color: #fff !important;
  &::placeholder {
    color: #fff !important;
  }
`;
const Suffix = styled.span`
  margin-left: 4px;
  display: flex;
  flex: none;
  align-items: center;
`;

const SearchIcon = styled(SearchOutlined)`
  color: ${props => props.color};
  font-size: 1.4em;
`;
