import React, { useEffect, useRef } from 'react';
import { Box, Collapsible } from 'grommet';
import { useMutation, useQuery } from 'react-query';
import { courseEnrolledDetails } from 'api/queries/Course';
import { useState } from 'react';
import 'styles/global.css';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';
import { coursecompleted, discovery, survey } from 'userRoutes';
import PageLoader from 'components/common/PageLoader';
import { courseUploadViewing } from 'api/mutations/Course';
import { showToast } from 'store/actions/toast';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from 'components/MainLayout';
import CBTController from 'components/utils/CBT/CBTController';
import { clearCBT, setCBT } from 'store/actions/cbt';
import { setModuleList } from 'store/actions/video';
import DeliveryTabs from 'components/utils/CourseDelivery/DeliveryTabs';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'antd';
import AppData from 'AppData';
import ResourceDownloader from 'components/common/ResourceDownloader';
import DefaultPoster from 'assets/images/default-img.png';

const Delivery = () => {
  const videoRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });
  const course = useSelector(state => state.course);
  const { quiz } = useSelector(state => state.cbt);
  const { moduleList } = useSelector(state => state.video);
  const [courseId, setCourseId] = React.useState();
  const [isLastSectionVideo, setIsLastSectionVideo] = React.useState(false);
  const [userId, setUserId] = React.useState();
  const { data, isLoading, refetch } = useQuery(
    ['courseEnrolledDetails', userId, courseId],
    courseEnrolledDetails
  );
  const [openNotification] = React.useState(true);
  const [checkPausing, setCheckPausing] = React.useState(true);
  const [videoModuleIndex, setVideoModuleIndex] = useState(0);
  const [videoSectionIndex, setVideoSectionIndex] = useState(0);
  const [videoTime, setVideoTime] = React.useState(0);
  const [activeIndex, setActiveIndex] = useState([0]);
  const [videoPlay] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [firstVideo, setFirstVideo] = React.useState(null);
  const [poster, setPoster] = useState(DefaultPoster);
  const [resource, setResource] = useState(null);
  const [containsVideo, setContainsVideo] = useState(false);
  const [containsCompressVideo, setContainsCompressVideo] = useState(false);

  useEffect(() => {
    let tawkToWidget = document.getElementsByClassName('widget-visible');
    let tawkToBubble = tawkToWidget[0]?.getElementsByTagName('iframe')[0];
    const intervalId = setInterval(() => {
      if (!tawkToBubble) {
        tawkToWidget = document.getElementsByClassName('widget-visible');
        tawkToBubble = tawkToWidget[0]?.getElementsByTagName('iframe')[0];
        return;
      }
      tawkToBubble.style.display = 'none';
      clearInterval(intervalId);
    }, 1000);
    return () => {
      if (tawkToBubble) tawkToBubble.style.display = 'block';
      if (intervalId) clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (course.courseThumbNailUrl !== null) setPoster(course.courseThumbNailUrl);
  }, [course]);
  const [videoSrc, setVideoSrc] = useState(null);
  const [iframeSrc, setIframeSrc] = useState(null);
  const [state, setState] = useState({
    url: null,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: true
  });

  const { playing, loop } = state;

  // redirect if user has not login && setting of course and userId
  React.useEffect(() => {
    if (history?.location?.state) {
      setCourseId(history.location.state?.courseId);
      setUserId(history.location.state?.userId);
      setActiveIndex(history.location.state?.currentModule);
      setVideoModuleIndex(history.location.state?.currentModule);
      setVideoSectionIndex(history.location.state?.currentSection);
    } else {
      history.push(discovery);
    }
  }, [history, history.location.state]);

  // set first video on landing to this page
  React.useEffect(() => {
    if (data?.data?.videos?.length) {
      let videoAssets = data?.data?.videos[0]?.items[0]?.courseModuleItemAssets;
      let videoIndex = videoAssets?.findIndex(
        item => AppData.assetType[item.assetType] === 'video'
      );
      if (videoAssets && videoAssets[videoIndex]?.url?.includes('http')) {
        setFirstVideo(videoAssets[videoIndex]?.url);
      } else {
        if (videoAssets && videoAssets[videoIndex]?.url) {
          let url = `http://${videoAssets[videoIndex]?.url}`;
          setFirstVideo(url);
        }
      }
    }
  }, [data]);

  // handle duration
  const handleDuration = duration => {
    setVideoTime(duration);
  };
  // handle time watched
  var secondsWatched = videoRef?.current?.getCurrentTime();
  // handle play
  const handlePlay = () => {
    setCheckPausing(true);
    setState({ playing: true });
  };

  // handle videoseeking
  const handleProgress = state => {
    if (!state.seeking) {
    }
  };

  // handle pause
  const handlePause = () => {
    console.log('onPause');
    setState({ playing: false });
    if (checkPausing) {
      handleViewingUpload(
        userId,
        courseId,
        videoModuleIndex,
        videoSectionIndex,
        secondsWatched,
        false
      );
    }
  };

  // on video end
  const handleEnded = () => {
    // console.log('onend');
    setCheckPausing(false);
    // last item in the section
    if (data?.data?.videos[videoModuleIndex]?.items.length - 1 === videoSectionIndex) {
      setIsLastSectionVideo(true);
      let newModule = videoModuleIndex;
      // last item in the module
      if (data?.data?.videos.length - 1 === videoModuleIndex) {
        handleViewingUpload(userId, courseId, newModule, videoSectionIndex, secondsWatched, true);
      } else {
        const [playItem] = moduleList?.videos[videoModuleIndex]?.items[
          videoSectionIndex
        ]?.courseModuleItemAssets;
        if (AppData.assetType[playItem.assetType] === 'quiz') {
          handleContinue();
        } else {
          setShowModal(true);
        }
        let newSection = videoSectionIndex;
        handleViewingUpload(userId, courseId, newModule, newSection, secondsWatched, true);
      }
    } else {
      handleViewingUpload(
        userId,
        courseId,
        videoModuleIndex,
        videoSectionIndex,
        secondsWatched,
        true
      );
    }
    setTimeout(() => {
      handlePlayNext();
    }, 2000);
  };

  // sending watched data to api
  const [uploadViewingMutate] = useMutation(courseUploadViewing, {
    onSuccess: data => {
      const { courseCompleted, isCompleted, hasSurvey } = data.data;
      if (hasSurvey && courseCompleted) {
        history.push(survey.replace(':id', courseId));
      } else if (courseCompleted) {
        history.push(coursecompleted);
      }
      if (isLastSectionVideo && isCompleted) {
        refetch();
      }
      if (isCompleted) {
        refetch();
      }
    },
    onError: error => {
      if (error.message === 'Network Error') {
        dispatch(
          showToast(
            <>
              We couldn't connect to the server.
              <br />
              Check your network or contact your admin
            </>
          )
        );
      } else {
        dispatch(showToast(error.message));
      }
    }
  });

  // upload watched progress function
  const handleViewingUpload = async (
    UserId,
    courseId,
    currentModule,
    currentSection,
    secondsWatched,
    isCompleted
  ) => {
    const pay = {
      UserId,
      courseId,
      currentModule,
      currentSection,
      secondsWatched,
      isCompleted
    };
    try {
      await uploadViewingMutate(pay);
    } catch (e) {
      console.log(e);
    }
  };

  const checkIfMoreThanOneVideoOrQuiz = assetData =>
    assetData.courseModuleItemAssets.filter(
      asset =>
        AppData.assetType[asset.assetType] === 'video' ||
        AppData.assetType[asset.assetType] === 'quiz'
    ).length > 1;

  // clear CBT on component mount
  useEffect(() => {
    dispatch(clearCBT());
  }, [dispatch]);

  // dispatch video to store on component mount
  useEffect(() => {
    if (data) {
      const module = data?.data;
      dispatch(
        setModuleList({
          ...module,
          videos: module?.videos?.map(data => ({
            ...data,
            items: data?.items
              .map(assetData => {
                if (checkIfMoreThanOneVideoOrQuiz(assetData)) {
                  return assetData.courseModuleItemAssets.map(item => ({
                    ...assetData,
                    courseModuleItemAssets: [item]
                  }));
                }

                return [assetData];
              })
              .reduce((acc, cur) => acc.concat(...cur), [])
          }))
        })
      );
    }
  }, [data, dispatch]);

  // handle next section video
  const handlePlayNext = () => {
    const currentPlaying = moduleList?.videos[videoModuleIndex]?.items?.map(e => {
      const [dataVideo] = e?.courseModuleItemAssets;
      return dataVideo?.durationDisplay === videoSrc?.durationDisplay;
    });
    if (
      currentPlaying &&
      videoSectionIndex < moduleList?.videos[videoModuleIndex]?.items?.length - 1
    ) {
      setVideoSectionIndex(videoSectionIndex + 1);
    }
  };

  // continue to next module function
  const handleContinue = () => {
    setActiveIndex(state => {
      const newState = state[0];
      const sum = newState + 1;
      return [sum];
    });
    setShowModal(false);
    if (videoModuleIndex < moduleList?.videos.length - 1) {
      setVideoModuleIndex(videoModuleIndex + 1);
      setVideoSectionIndex(0);
    }
  };

  // check for playlist either quiz or video
  useEffect(() => {
    if (moduleList && moduleList?.videos?.length > videoModuleIndex) {
      let assets =
        moduleList?.videos[videoModuleIndex]?.items[videoSectionIndex]?.courseModuleItemAssets;
      let [playItem] = assets;

      // check whether video is included in each section
      let videoIndex = -1;
      let iframeIndex = -1;
      const hasVideo = assets.some((asset, index) => {
        let isVideo = AppData.assetType[asset?.assetType] === 'video';
        if (isVideo) videoIndex = index;
        return isVideo;
      });
      setContainsVideo(hasVideo);

      const hasCompressVideo = assets.some((asset, index) => {
        let isCompressVideo = AppData.assetType[asset?.assetType] === 'compressVideo';
        if (isCompressVideo) iframeIndex = index;
        return isCompressVideo;
      });
      setContainsCompressVideo(hasCompressVideo);

      dispatch(clearCBT());

      if (AppData.assetType[playItem?.assetType] === 'quiz') {
        setVideoSrc(null);
        dispatch(setCBT(playItem));
        return;
      }

      if (
        AppData.assetType[playItem?.assetType] === 'image' ||
        AppData.assetType[playItem?.assetType] === 'article'
      ) {
        setResource({
          title: moduleList?.videos[videoModuleIndex]?.items[videoSectionIndex].title,
          url: playItem.url
        });
      }

      if (hasVideo) {
        let videoItem = assets[videoIndex];
        if (videoItem?.url?.includes('http')) {
          setVideoSrc(videoItem);
        } else {
          videoItem = { ...videoItem, url: 'http://' + videoItem?.url };
          setVideoSrc(videoItem);
        }
      } else if (hasCompressVideo) {
        let iframeItem = assets[iframeIndex];
        if (iframeItem?.url?.includes('http')) {
          setIframeSrc(iframeItem);
        } else {
          iframeItem = { ...iframeItem, url: 'http://' + iframeItem?.url };
          setIframeSrc(iframeItem);
        }
      }
    }
  }, [dispatch, moduleList, videoModuleIndex, videoSectionIndex]);

  return (
    <MainLayout isVideoPage selectedKey="dashboard" sidebar={false} padded={0}>
      <Box
        style={{
          overflow: isMobileDevice ? 'auto' : 'hidden',
          height: isMobileDevice ? null : '100%'
        }}>
        <Box direction={isMobileDevice ? 'column-reverse' : 'row'} style={{ height: '100%' }}>
          <Collapsible direction="horizontal" open={openNotification}>
            <Box
              className="videoscroll"
              flex
              width="full"
              background="light-2"
              elevation="small"
              style={{
                maxWidth: isMobileDevice ? '100%' : '320px',
                minWidth: isMobileDevice ? '100%' : '320px',
                height: '100%'
              }}>
              <DeliveryTabs
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                setCurrentModule={setVideoModuleIndex}
                setCurrentSection={setVideoSectionIndex}
                currentModule={videoModuleIndex}
                videoTime={videoTime}
                playing={playing}
                videoPlay={videoPlay}
                setVideoSrc={setVideoSrc}
                setResource={setResource}
                currentSection={videoSectionIndex}
                handlePlayNext={handlePlayNext}
              />
            </Box>
          </Collapsible>
          <Box flex align="center" className="topwrapper">
            <Box
              className={!showModal ? 'none_d' : 'controlwrapper'}
              direction="row"
              justify="center"
              width={'100%'}
              height={'100%'}
              style={{ position: 'absolute', zIndex: 2, top: 0, right: 0 }}>
              <Box justify="center" direction="row" gap="medium" align="center">
                <Button
                  type="primary"
                  danger
                  onClick={() => setShowModal(false)}
                  style={{ width: 200 }}
                  size="large">
                  Cancel
                </Button>
                <Button size="large" onClick={handleContinue} type="primary">
                  Complete and Continue
                </Button>
              </Box>
            </Box>
            {quiz ? (
              <CBTController
                handleEnded={handleEnded}
                moduleIndex={videoModuleIndex}
                sectionIndex={videoSectionIndex}
                refetch={refetch}
              />
            ) : resource && !containsVideo && !containsCompressVideo ? (
              <ResourceDownloader
                title={resource.title}
                url={resource.url}
                moduleIndex={videoModuleIndex}
                sectionIndex={videoSectionIndex}
                uploadViewingMutate={uploadViewingMutate}
              />
            ) : (
              <Box
                height="full"
                width="full"
                className={containsCompressVideo ? '' : 'player-wrapper'}>
                {containsVideo && (
                  <ReactPlayer
                    ref={videoRef}
                    onDuration={handleDuration}
                    className="react-player"
                    url={videoSrc?.url || firstVideo}
                    width="100%"
                    height="100%"
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                    onContextMenu={e => e.preventDefault()}
                    light={`'${encodeURI(poster)}'`}
                    controls
                    playing={playing}
                    loop={loop}
                    onReady={() => setState({ playing: true })}
                    onStart={() => {}}
                    onPlay={handlePlay}
                    onSeek={e => console.log('onSeek', e)}
                    onPause={handlePause}
                    onEnded={handleEnded}
                    onProgress={handleProgress}
                  />
                )}
                {containsCompressVideo && (
                  <iframe
                    src={iframeSrc.url}
                    style={{ minHeight: '80vh' }}
                    width="100%"
                    height="100%"
                    title={course.title}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <PageLoader isLoading={isLoading} />
    </MainLayout>
  );
};

export default Delivery;
