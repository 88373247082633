import React from 'react';
import { Row, Typography, Col } from 'antd';
import styled from 'styled-components';
import { FaArrowDown } from 'react-icons/fa';
import { Link } from 'react-scroll';

const ScrollDown = ({ isMobile }) => {
  return (
    <Container>
      <Link
        to="scrollElement"
        spy={true}
        offset={isMobile ? -100 : -180}
        smooth={true}
        duration={500}>
        <IconBorder>
          <ScrollIcon />
        </IconBorder>
      </Link>

      {!isMobile && <ScrollText>Scroll Down</ScrollText>}
    </Container>
  );
};

const Container = styled(Row)`
  z-index: 3;
  position: absolute;
  bottom: 3%;
`;

const IconBorder = styled(Row)`
  border: 2px solid #ff7700;
  padding: 5px;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
    border: 2px solid #fff;
  }
`;
const ScrollIcon = styled(FaArrowDown)`
  color: #ff7700;
  font-size: 1em;
`;
const ScrollText = styled(Typography.Text)`
  color: #fff;
  margin-left: 1em;
`;

export default ScrollDown;
