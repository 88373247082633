import React from 'react';
import IconWrapper from 'components/common/IconWrapper';
import { Box, Button, Layer, Text } from 'grommet';
import SuccessIcon from 'assets/svgs/ic_check_circle.svg';
import WarningIcon from 'assets/svgs/ic_warning.svg';
import FailedIcon from 'assets/svgs/ic_cancel.svg';

const NotificationModal = ({
  title,
  message,
  actionText,
  actionFunc,
  status,
  iconWidth,
  iconHeight
}) => {
  return (
    <Layer responsive={false}>
      <Box align="center" width="400px">
        <Box pad="medium">
          <IconWrapper
            icon={
              status === 'success'
                ? SuccessIcon
                : status === 'warning'
                ? WarningIcon
                : status === 'failed'
                ? FailedIcon
                : null
            }
            width={iconWidth}
            height={iconHeight}
          />
        </Box>
        <Box pad={{ left: 'medium', right: 'medium', bottom: 'xsmall' }}>
          <Text weight="bold" size="xlarge" textAlign="center">
            {title}
          </Text>
        </Box>
        <Box pad={{ left: 'medium', right: 'medium', bottom: 'medium' }}>
          <Text textAlign="center">{message}</Text>
        </Box>
        <Box width="full">
          <Button
            primary
            style={{ color: '#fff', height: '48px' }}
            fill="horizontal"
            label={actionText}
            color={status === 'success' ? '#32cd32' : status === 'warning' ? '#fca40c' : '#dd0808'}
            onClick={() => actionFunc()}
          />
        </Box>
      </Box>
    </Layer>
  );
};

export default NotificationModal;
