import React, { useRef, useState, useEffect } from 'react';
import QuizBuilder from 'components/utils/QuizBuilder';
import { Draggable } from 'react-beautiful-dnd';
import { Button, Card, Typography, Menu, Dropdown } from 'antd';
import AppData from 'AppData';
import styled from 'styled-components';
import CustomButton from 'components/common/CustomButton';
import QuizItem from 'components/utils/QuizBuilder/QuizItem';
import AddQuestion from 'components/utils/QuizBuilder/AddQuestion';

const QUIZ_ASSET_TYPE = 4;

const CourseQuizContent = ({
  type,
  contentCountData,
  contentId,
  contentIndex,
  moduleIndex,
  sectionIndex,
  setCourseDragData,
  quizData,
  isReview
}) => {
  const { Text } = Typography;
  const quizDeleteButton = useRef(null);
  const [showQuizBuilder, setShowQuizBuilder] = useState(!quizData);
  const [defaultQuiz, setDefaultQuiz] = useState(!quizData);
  const [quizType, setQuizType] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(-1);
  const [quiz, setQuiz] = useState(
    quizData || {
      name: '',
      description: '',
      duration: 0,
      pointPerQuestion: 1,
      randomize: false,
      timed: false,
      requiredNextModule: false,
      passMark: 0,
      data: []
    }
  );

  const quizItemClickHandler = (index, type) => {
    setQuestionIndex(index);
    setQuizType(type);
  };

  const quizMenuTypeHandler = ({ key }) => {
    setQuiz(state => ({
      ...state,
      data: [...state.data, { type: key, question: '', options: [] }]
    }));
    setQuestionIndex(-1);
    setQuizType(key);
  };

  const quizTypeMenu = (
    <Menu onClick={quizMenuTypeHandler}>
      <Menu.Item key="0">Single Choice</Menu.Item>
      <Menu.Item key="1">Multiple Choice</Menu.Item>
    </Menu>
  );

  useEffect(() => {
    setCourseDragData(state => {
      const newState = { ...state };
      newState.courseModules[moduleIndex].items[sectionIndex].courseModuleItemAssets[
        contentIndex
      ] = {
        ...newState.courseModules[moduleIndex].items[sectionIndex].courseModuleItemAssets[
          contentIndex
        ],
        assetType: QUIZ_ASSET_TYPE,
        quiz
      };
      return newState;
    });
  }, [contentIndex, moduleIndex, quiz, sectionIndex, setCourseDragData]);

  useEffect(() => {
    contentCountData.setQuizCount(state => state + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Draggable
        isDragDisabled={isReview}
        draggableId={contentId}
        key={contentId}
        index={contentIndex}>
        {provided => (
          <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
            <Card
              title={
                <>
                  Quiz Name:&ensp;<Text style={{ maxWidth: 200 }}>{quiz.name}</Text>
                </>
              }
              extra={
                !isReview && (
                  <>
                    <Button type="link" onClick={() => setShowQuizBuilder(true)}>
                      Edit
                    </Button>
                    <Button
                      type="link"
                      ref={quizDeleteButton}
                      onClick={() => {
                        if (type === AppData.contentType.quiz)
                          contentCountData.setQuizCount(state => state - 1);

                        setCourseDragData(state => {
                          const courseModules = [...state.courseModules];
                          courseModules[moduleIndex].items[
                            sectionIndex
                          ].courseModuleItemAssets.splice(contentIndex, 1);
                          const newState = {
                            ...state,
                            courseModules
                          };
                          return newState;
                        });
                      }}>
                      Delete
                    </Button>
                  </>
                )
              }>
              {quiz.data.map((item, index) => (
                <QuizItem
                  key={index}
                  index={index}
                  type={item.type}
                  setQuiz={setQuiz}
                  quizItemClickHandler={() => quizItemClickHandler(index, item.type)}
                  isReview={isReview}
                />
              ))}
              {!isReview && (
                <DropdownWrapper>
                  <Dropdown overlay={quizTypeMenu} trigger={['click']}>
                    <CustomButton key="submit" bgColor="#0966b8" color="#fff" size="large">
                      Add New Question
                    </CustomButton>
                  </Dropdown>
                </DropdownWrapper>
              )}
            </Card>
          </div>
        )}
      </Draggable>
      {showQuizBuilder && (
        <QuizBuilder
          showQuizBuilder={showQuizBuilder}
          setShowQuizBuilder={setShowQuizBuilder}
          quiz={quiz}
          setQuiz={setQuiz}
          quizDeleteButton={quizDeleteButton}
          defaultQuiz={defaultQuiz}
          setDefaultQuiz={setDefaultQuiz}
        />
      )}
      {quizType !== null && (
        <AddQuestion
          quizData={quiz.data[questionIndex === -1 ? quiz.data.length - 1 : questionIndex]}
          questionIndex={questionIndex}
          setQuiz={setQuiz}
          setQuizType={setQuizType}
          isReview={isReview}
        />
      )}
    </>
  );
};

const DropdownWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export default CourseQuizContent;
