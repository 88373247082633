import React from 'react';
import {
  Box,
  Button,
  FormField,
  ResponsiveContext,
  // Calendar,
  // DropButton,
  Stack,
  Text,
  TextInput,
  Select
} from 'grommet';
import { isMobile } from 'utils';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useSelector } from 'react-redux';
import InputController from 'components/common/InputController';
// import { FormDown, FormClose } from 'grommet-icons';
// import { useHistory } from 'react-router-dom';

const BulkEmailForm = props => {
  const {
    register,
    errors,
    setValue,
    setCategoryListId,
    emailData,
    data,
    handlePreview,
    Controller,
    control,
    setOpenModal,
    setNewVar,
    setPlaceholderDisabled,
    placeholderDisabled
  } = props;
  const size = React.useContext(ResponsiveContext);
  const { results } = useSelector(state => state.email);
  // const [date, setDate] = useState('');
  // const [open, setOpen] = useState(false);
  // const history = useHistory();

  // const onSelect = selectedDate => {
  //   const localDate = new Date(selectedDate).toLocaleDateString();
  //   setDate(localDate);
  //   setOpen(false);
  // };

  const formFieldStyle = {
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: isMobile(size) ? 'flex-start' : 'space-between'
  };

  React.useEffect(() => {
    register('message', { required: 'message is required' });
    register('emailCategoryId', { required: 'email categoryId is required' });
  }, [register]);

  //  console.log(emailData?.emailCategoryName);
  //  console.log(34,results);

  return (
    <Box style={{ maxWidth: '100%' }}>
      <InputController
        Controller={Controller}
        controllerName="name"
        errors={errors}
        desktopStyle={formFieldStyle}
        errorMessage="Name is required"
        control={control}
        width="100%"
        defaultValue={results?.name || emailData?.name}
        required
        label="Subject"
        marginBottom="medium"
        name="name"
        htmlfor="name"
        placeholder="Name"
      />
      {!emailData && (
        <InputController
          Controller={Controller}
          controllerName="code"
          errors={errors}
          desktopStyle={formFieldStyle}
          errorMessage="Code is required"
          control={control}
          width="100%"
          defaultValue={results?.code || emailData?.code}
          required
          label="Code"
          marginBottom="medium"
          name="code"
          htmlfor="code"
          placeholder="Code"
        />
      )}
      {/* <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Email Category"
          name="emailCategoryId"
          htmlfor="emailCategoryId"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          {data && (
            <Select
              id="emailCategoryId"
              name="emailCategoryId"
              placeholder="Email Category"
              style={{ width: isMobile(size) ? '100%' : 200 }}
              options={data.data.map(d => d.name)}
              onChange={({ option }) => {
                setValue('emailCategoryId', data.data.find(d => d.name === option).id, {
                  shouldValidate: true
                });
                if (!emailData) {
                  setCategoryListId(data.data.find(d => d.name === option).id);
                }
              }}
            />
          )}
        </FormField>
        {errors.emailCategoryId && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.emailCategoryId.message}
          </Text>
        )}
      </Box>
      */}
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Email Category"
          name="emailCategoryId"
          htmlfor="emailCategoryId"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          {data && (
            <Controller
              name="emailCategoryId"
              control={control}
              defaultValue={() => {
                if (results !== null) {
                  const emailCategoryName = results?.emailCategoryId;
                  setCategoryListId(data.data.find(d => d.name === emailCategoryName).id);
                  setValue('emailCategoryId', emailCategoryName);
                  return emailCategoryName;
                } else if (emailData) {
                  setValue('emailCategoryId', emailData.emailCategoryName);
                  return emailData.emailCategoryName;
                }
                return null;
              }}
              rules={{ required: 'email categoryId is required' }}
              render={props => (
                <Select
                  id="emailCategoryId"
                  name="emailCategoryId"
                  placeholder="Email Category"
                  style={{ width: isMobile(size) ? '100%' : '100%', border: 'none' }}
                  options={data.data.map(d => d.name)}
                  value={props.value}
                  onChange={({ option }) => {
                    props.onChange(option);
                    setValue('emailCategoryId', option, {
                      shouldValidate: true
                    });
                    setCategoryListId(data.data.find(d => d.name === option).id);
                    // if (!emailData) {
                    // }
                  }}
                />
              )}
            />
          )}
        </FormField>
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <Box
          direction={isMobile(size) ? 'column' : 'row'}
          align={isMobile(size) ? 'stretch' : 'center'}
          gap="medium"
          margin={{ bottom: 'small' }}
          justify={isMobile(size) ? 'start' : 'between'}>
          <Text>
            <strong>Message</strong>
          </Text>
          <Button
            disabled={placeholderDisabled}
            size="small"
            style={{ padding: 3 }}
            onClick={() => {
              setOpenModal(true);
              setPlaceholderDisabled(true);
            }}
            label="Insert Placeholder"
          />
        </Box>
        <Box style={{ width: '100%' }}>
          <Stack anchor="top-right">
            <FormField
              name="message"
              htmlfor="message"
              margin={{ bottom: 'none' }}
              style={formFieldStyle}>
              <CKEditor
                editor={ClassicEditor}
                data={results?.message || emailData?.message}
                onInit={editor => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Editor is ready to use!', editor);
                  if (emailData?.message || results?.message) {
                    const data = editor.getData();
                    setValue('message', data, { shouldValidate: true });
                  }
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setValue('message', data, { shouldValidate: true });
                }}
                onBlur={(event, editor) => {
                  setNewVar(editor);
                }}
                onFocus={(event, editor) => {
                  setPlaceholderDisabled(false);
                  // editor.model.change(writer => {
                  //   const insertPosition = editor.model.document.selection.getFirstPosition();

                  //   writer.insertText(selectedToken ? selectedToken : '', insertPosition);
                  // });
                }}
              />
            </FormField>
            <Button
              primary
              size={isMobile(size) ? 'small' : 'small'}
              onClick={handlePreview}
              label="Preview"
            />
          </Stack>
        </Box>
        {errors.message && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.message.message}
          </Text>
        )}
      </Box>

      {/* <Box
        margin={{ top: 'large' }}
        direction={isMobile(size) ? 'column' : 'row'}
        align="center"
        gap="medium"
        justify="end">
        <DropButton
            open={open}
            // align={{ left: 'right' }}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            dropContent={<Calendar size="small" date={date} onSelect={onSelect} />}>
            <Box border direction="row" gap="medium" align="center" pad="xsmall">
              <Text>{date ? new Date(date).toLocaleDateString() : 'Schedule For Later '}</Text>
              {!date ? (
                <FormDown color="brand" />
              ) : (
                <FormClose
                  color="brand"
                  onClick={() => {
                    setDate('');
                    setOpen(false);
                    return;
                  }}
                />
              )}
            </Box>
          </DropButton>
         
        <Button secondary size="small" label="Cancel" />
        <Button type="submit" primary size="small" label="Save" />
      </Box> */}
    </Box>
  );
};

export default BulkEmailForm;
