import styled from 'styled-components';
import { Typography, Col, Input, Button } from 'antd';

export const ContainerFluid = styled.div`
  height: 100px;
  display: flex;
  padding: 28px;
  background: ${({ img }) =>
    `linear-gradient(to right, rgba(0,0,0,1),rgba(0,0,0,1), rgba(0,0,0,.1) ), url(${img})`};
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const HeaderTitle = styled(Typography.Title)`
  color: #fff !important;
  word-spacing: 5px;
  font-weight: 600 !important;
  font-size: 2rem !important;
`;

export const HeaderSubTitle = styled(Typography.Title)`
  font-size: 1.5rem !important;
  font-weight: 600 !important;
`;

export const Wrapper = styled.div`
  background-color: #fff;
  height: 100%;
`;

export const Container = styled(Col)`
  max-width: 1200px;
  padding: 42px 50px 136px;
  background-color: #fff;
  margin: 0 auto;

  @media (max-width: 992px) {
    padding: 24px 16px;
    padding-bottom: 40px;
    max-width: 600px;
  }
`;

export const CourseDetailWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 20px;
  margin-bottom: 44px;
  background: #f6f6f6;

  @media (max-width: 992px) {
    flex-direction: column;
    padding: 0;
    margin-bottom: 28px;
    background-color: #fff;
  }
`;

export const CourseInfoFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
  width: 100%;

  @media (max-width: 992px) {
    align-items: flex-start;
  }
`;

export const CourseThumbNail = styled.img`
  width: 142px;
  height: 135px;
  border: 1px solid #eee;
  border-radius: 5px;

  @media (max-width: 992px) {
    display: none;
  }
`;

export const CourseBanner = styled.img`
  display: none;
  width: 100%;
  height: 200px;
  object-fit: cover;

  @media (max-width: 992px) {
    display: block;
  }
`;

export const CourseInfoWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  font-size: 0.8rem !important;

  @media (max-width: 992px) {
    flex-direction: column-reverse;
  }
`;

export const CourseInfoTextHighlight = styled(Typography.Text)`
  color: #0966b8;
  font-size: 1rem !important;
  font-weight: 600;

  @media (max-width: 992px) {
    display: none;
  }
`;

export const CourseTitle = styled(Typography.Paragraph)`
  font-weight: 600;
  font-size: 2rem;
  margin: 8px 0 !important;
  max-width: 400px;

  @media (max-width: 992px) {
    max-width: 200px;
    font-size: 1.5rem !important;
  }
`;

export const CoursePartner = styled(Typography.Text)`
  font-weight: 600 !important;
`;

export const CourseDetailPrice = styled(Typography.Text)`
  font-size: 2rem !important;
  font-weight: 600 !important;
  margin-top: 16px;

  @media (max-width: 992px) {
    position: relative;
    top: 32px;
    font-size: 1.5rem !important;
    margin-top: 0px;
  }
`;

export const PriceExtraText = styled(Typography.Text)`
  font-weight: 500;
  font-size: 0.5rem;
  color: #3d75a6;
  display: block;
  margin-top: 4px;
`;

export const SummaryWrapper = styled.div`
  display: flex;
  margin-left: 20px;

  @media (max-width: 992px) {
    margin-left: 0;
    flex-direction: column-reverse;
  }
`;

export const SummaryFlex = styled.div`
  flex: 1;
  max-width: ${props => props.maxWidth || '100%'};

  @media (max-width: 992px) {
    max-width: 100%;

    & + div {
      height: 28px;
      width: 100%;
    }
  }
`;

export const SummaryTextBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SummaryText = styled(Typography.Text)`
  color: #000000;
  font-weight: 300;
  font-size: 1rem;
`;

export const SummaryValue = styled(SummaryText)`
  font-weight: 500;
`;

export const SummaryTotalText = styled(SummaryValue)`
  font-size: 1.25rem;
  color: #1c7e4f;
`;

export const SummaryTotalValue = styled(SummaryTotalText)`
  font-weight: 600;
`;

export const DiscountWrapper = styled.div`
  padding: 48px 55px;
  background: #f7fbff;
  border: 1.5px dashed #73bdff;
  display: block;

  @media (max-width: 992px) {
    padding: 20px 24px;
    display: ${props => (props.hide ? 'none' : 'block')};
  }
`;

export const DiscountLabel = styled.label`
  font-size: 0.875rem;
`;

export const DiscountForm = styled.form`
  display: flex;
  align-items: stretch;
  height: 50px;
`;

export const DiscountInput = styled(Input)`
  border-radius: 0;
  border: none;
`;

export const DiscountButton = styled(Button)`
  background: #0966b8;
  border-color: #0966b8;
  color: #fff !important;
  width: 150px;
  height: 100%;
  border-radius: 0;

  &:disabled {
    background: #92c5f1;
    border-color: #92c5f1;

    &:hover {
      background: #92c5f1;
      border-color: #92c5f1;
    }
  }
`;

export const DiscountSummary = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const VoucherAlert = styled.div`
  background: #f7fbff;
  padding: 22px 20px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  & span {
    color: #0966b8;
    cursor: pointer;
  }
`;

export const PayButton = styled(Button)`
  background: #0966b8;
  color: #fff !important;
  border-radius: 3px;
  font-weight: 600;
  font-size: 1.25rem;
  width: 100%;
  height: 62px;

  &:hover,
  &:focus {
    background: #0966b8;
    border-color: #0966b8;
  }
`;

export const PaymentFooterText = styled(Typography.Text)`
  font-size: 0.875rem;
  text-align: center;
  display: block;
  margin-top: 20px;

  & > a {
    color: #0966b8;
  }

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Divider = styled.hr`
  background: #d9d9d9;
  width: 100%;
  height: 1px;
  border: none;
`;

export const Spacer = styled.div`
  width: ${props => props.width || 0}px;
  height: ${props => props.height || 0}px;
`;

export const DesktopHidden = styled.div`
  display: none;

  @media (max-width: 992px) {
    display: revert;
  }
`;

export const MobileHidden = styled.div`
  @media (max-width: 992px) {
    display: none;
  }
`;
