import Axios from 'axios';

const service = 'Institution';

export const create = async model => {
  const { data } = await Axios.post(`/${service}/api/Institution/Create`, model);
  console.log('data', data);
  return data;
};

export const createInst = model => {
  Axios.post(`/${service}/api/Institution/Create`, model)
    .then(res => {
      console.log('data', res.data);
    })
    .catch(err => {
      console.log(err);
    });
};

export const search = async ({
  key,
  searchVal = '',
  page = 1,
  pageSize = 1000,
  id = 0,
  isActive = false
}) => {
  const { data } = await Axios.get(
    `/${service}/api/Institution/Search?Page=${page}&PageSize=${pageSize}${
      id !== 0 ? `&Id=${id}` : ''
    }${isActive ? `&IsActive=${isActive}` : ''}${searchVal !== '' ? `&Search=${searchVal}` : ''}`
  );
  return data;
};

export const toggleInstitution = async ({ Id, isActive }) => {
  const { data } = await Axios.post(`/${service}/api/Institution/ActivateDeactivate`, {
    Id,
    isActive
  });
  return data;
};

export const updateInstitution = async formdata => {
  const { data } = await Axios.put(`/${service}/api/Institution/Update`, formdata, {
    header: { 'Content-Type': 'multipart/form-data', Accept: 'multipart/form-data' }
  });
  return data;
};

export const updatePartners = async ({ id, bankId, account }) => {
  const { data } = await Axios.put(
    `/${service}/api/Partner/Update?id=${id}&bankId=${bankId}&accountno=${account}`
  );
  return data;
};

// export const generateCertificate = async ({ courseId, userId, institutionId }) => {
//   const { data } = await Axios.post(
//     `/${service}/api/Certificate/GenerateCertificate?courseId=${courseId}&userId=${userId}&institutionId=${institutionId}`
//   );

//   return data;
// };

export const tooglePartners = async (id, isActive) => {
  const { data } = await Axios.delete(
    `/${service}/api/Partner/ActivateDeactivate?id=${id}&isActive=${isActive}`
  );
  return data;
};

export const createPartner = async ({ name, bankId, account }) => {
  console.log('data3', name, bankId, account);

  const { data } = await Axios.post(`/${service}/api/Partner/Create`, { name, bankId, account });
  return data;
};

export const uploadSignatureAndName = async ({ name, jobTitle, institutionId, signature }) => {
  try {
    const formData = new FormData();
    formData.append('signature', signature);
    const queryString = `?name=${name}&jobTitle=${jobTitle}&institutionId=${institutionId}`;
    const { data } = await Axios.post(
      `/${service}/api/Certificate/UploadSignatureAndName${queryString}`,
      formData,
      {
        header: { 'Content-Type': 'multipart/form-data', Accept: 'multipart/form-data' }
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateSignatureAndName = async ({ name, jobTitle, id, signature }) => {
  try {
    const formData = new FormData();
    formData.append('signature', signature);
    const queryString = `?id=${id}&name=${name}&jobTitle=${jobTitle}`;
    const { data } = await Axios.put(
      `/${service}/api/Certificate/UpdateSignatureAndName${queryString}`,
      formData,
      {
        header: { 'Content-Type': 'multipart/form-data', Accept: 'multipart/form-data' }
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const toggleSignature = async ({ id, isActive }) => {
  try {
    const queryString = `?id=${id}&isActive=${isActive}`;
    const { data } = await Axios.delete(
      `/${service}/api/Certificate/ActivateDeactivate${queryString}`
    );
    return data;
  } catch (error) {
    throw error;
  }
};
