import React, { createContext, useState } from 'react';

export const BeneficiaryContext = createContext();

export const BeneficiaryProvider = ({ children }) => {
  const [beneficiaryData, setBeneficiaryData] = useState([]);
  const [quantityData, setQuantityData] = useState([]);

  // const clearBeneficiaries = () => {
  //   setQuantityData([]);
  // };

  return (
    <BeneficiaryContext.Provider
      value={{
        beneficiaryData,
        setBeneficiaryData,
        setQuantityData,
        quantityData
      }}>
      {children}
    </BeneficiaryContext.Provider>
  );
};
