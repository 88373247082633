import React from 'react';

import { FormClose } from 'grommet-icons';

import { Box, Button, Layer, ResponsiveContext, Text } from 'grommet';
import { isMobile } from 'utils';

const ModalCard = ({ onClose, header, subHeader, data, onSelectToken, footer, modal = true }) => {
  const size = React.useContext(ResponsiveContext);
  return (
    <Layer
      responsive={false}
      animation="slide"
      onClickOutside={onClose}
      onEsc={onClose}
      modal={modal}>
      <Box style={{ minWidth: isMobile(size) ? '275px' : '575px' }}>
        {header && (
          <Box
            // direction="row"
            // align="center"
            as="header"
            elevation="small"
            // justify="between"
            pad="small">
            <Box
              direction="row"
              align="center"
              justify="between"
              // pad="small"
            >
              <Text margin={{ left: 'small' }} size={isMobile(size) ? 'small' : 'large'}>
                <strong>{header}</strong>
              </Text>
              <Button icon={<FormClose />} style={{ padding: 0 }} onClick={onClose} />
            </Box>
            <Text truncate={isMobile(size) && true} margin={{ left: 'small' }} size="xsmall">
              {subHeader}
            </Text>
          </Box>
        )}
        <Box width={data ? '40%' : 'none'} flex overflow="auto" pad="medium">
          {data ? (
            data.map((item, index) => (
              <Button
                hoverIndicator
                onClick={() => {
                  onSelectToken(item);
                }}
                margin={{ bottom: 'xsmall' }}
                plain
                key={index}
                label={`${index + 1}. ${item}}`}
              />
            ))
          ) : (
            <Box justify="center" align="center">
              {' '}
              <Text align="center" size="medium">
                {' '}
                Select a Category to view the list{' '}
              </Text>
            </Box>
          )}
        </Box>
        {footer && (
          <Box
            as="footer"
            border={{ side: 'top' }}
            pad="small"
            justify="end"
            direction="row"
            align="center">
            <Button primary label={footer} />
          </Box>
        )}
      </Box>
    </Layer>
  );
};
export default ModalCard;
