import React from 'react';
import styled from 'styled-components';
import { Row, Col, Typography, Form } from 'antd';
import {
  RiTwitterFill,
  RiFacebookFill,
  RiLinkedinFill,
  RiGoogleFill,
  RiYoutubeFill,
  RiGithubFill,
  RiTumblrFill
} from 'react-icons/ri';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import SocialmediaCard from './SocialmediaCard';
import { updateStudentProfile } from 'api/mutations/Authentication';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const SocialIntegration = ({ setLoading, setSuccessMessage, isMobile, profileData, refetch }) => {
  const { userId, StudentProfile } = useSelector(state => state.auth);
  const LinkedAccount = [
    {
      color: '#305c99',
      name: 'faceBook',
      Icon: <RiFacebookFill />
    },
    {
      color: '#00cdff',
      name: 'twitter',
      Icon: <RiTwitterFill />
    },
    {
      color: '#e22b26',
      name: 'youTube',
      Icon: <RiYoutubeFill />
    },
    {
      color: '#007bb6',
      name: 'linkedIn',
      Icon: <RiLinkedinFill />
    }
  ];

  const UnlinkedAccount = [
    {
      color: '#2c4762',
      name: 'tumblr',
      Icon: <RiTumblrFill />
    },
    {
      color: '#42c0fb',
      name: 'github',
      Icon: <RiGithubFill />
    }
  ];

  const [updateMutate] = useMutation(updateStudentProfile, {
    onMutate: variables => setLoading(true),
    onSuccess: data => {
      setLoading(false);
      setSuccessMessage(data);
      refetch();
    },
    onError: error => {
      console.log(error);
      setSuccessMessage(error);
    }
  });

  const onFinish = async ({ twitter, youtube, facebook, linkedin }) => {
    let formData = new FormData();

    let SocialMedia = {
      Id: userId,
      ProfileId: StudentProfile,
      Twitter: twitter,
      YouTube: youtube,
      FaceBook: facebook,
      LinkedIn: linkedin
    };

    for (let key in SocialMedia) {
      formData.append(`UpdateProfiles.${key}`, SocialMedia[key]);
    }

    try {
      await updateMutate(formData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Heading>Social Integration</Heading>
      <Paragraph>Synchronize your social accounts to improve your learning</Paragraph>
      <Div>
        <AccountType>Linked Accounts</AccountType>
        <Form id="Social Integration" onFinish={onFinish}>
          {LinkedAccount.map(item => {
            const { name, color, Icon } = item;

            return (
              <AccountDiv1>
                <SocialmediaCard color={color} name={name} icon={Icon} />
                <FormField
                  name={name}
                  rules={[
                    {
                      pattern: new RegExp(
                        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
                      ),
                      message: 'Invalid url'
                    }
                  ]}>
                  <Input
                    defaultValue={
                      profileData[name] !== 'undefined' ? profileData[name] : 'Enter link...'
                    }
                  />
                </FormField>
              </AccountDiv1>
            );
          })}
        </Form>
      </Div>
      {/* <Div>
        <AccountType>unlinked Accounts</AccountType>
        <AccountDiv2>
          <AccountGrid gutter={isMobile ? 136 : 40}>
            {UnlinkedAccount.map(item => (
              <Col span={12}>
                <SocialmediaCard color={item.color} name={item.name} icon={item.Icon} />
              </Col>
            ))}
          </AccountGrid>
        </AccountDiv2>
      </Div> */}
    </Container>
  );
};

export default SocialIntegration;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
const Heading = styled(Typography.Title)`
  font-size: 1.6em !important;
  font-weight: bold !important;
  color: #afafaf !important;
  margin-bottom: 0.5em !important;
  @media ${mobile} {
    font-size: 1.2em !important;
  }
`;
const Paragraph = styled(Typography.Paragraph)`
  color: #afafaf;
  @media ${mobile} {
    font-size: 0.9em !important;
  }
`;
const Div = styled.div`
  margin: 2.5em 0 0 0;
`;

const AccountType = styled(Typography.Paragraph)`
  color: #7f7f7f;
  font-weight: bold;
`;

const AccountDiv1 = styled(Row)`
  min-height: 2em;
  padding-left: 0.5em;
  margin-bottom: 1em;
  @media ${mobile} {
    /* flex-direction: vertical!important; */
  }
`;

const FormField = styled(Form.Item)`
  @media ${mobile} {
    display: block !important;
  }
`;

const AccountDiv2 = styled(Row)`
  width: 55%;
  height: 5.6em;
  padding-left: 0.5em;
`;

const Input = styled.input`
  height: 2em;
  width: 19em;
  border: 1px solid #dfdfdf;
  margin-left: 2em;
  &::placeholder {
    color: #dfdfdf;
    padding-left: 1em;
  }
  @media ${mobile} {
    display: block !important;
    margin: 1em 0em 1.5em -0.5em;
  }
`;

const AccountGrid = styled(Row)``;
