import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MainLayout from 'components/MainLayout';
import CourseImage from 'assets/images/coursesdis.png';
import IcsanLogo from 'assets/images/icsan.png';
import CourseCard from 'components/common/CourseCard';
import { Row, Col, Avatar, Typography, Image, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FaPenAlt, FaUser, FaBuilding, FaGlobeAfrica, FaPhone, FaUserMd } from 'react-icons/fa';
import PageLoader from 'components/common/PageLoader';
import ProfileUpdate from 'components/profileUpdate';
import { getStudentByUserId } from 'api/queries/Authentication';
import { completedCourses } from 'api/queries/Course';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Fragment } from 'react';
import ProfileDetailsCard from 'components/profileUpdate/ProfileDetailsCard';
import { useMediaQuery } from 'react-responsive';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const Profile = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });
  const [profileData, setProfileData] = useState();
  const [completedCourse, setCompletedCourse] = useState(null);
  const [update, setUpdate] = useState(false);
  const { userId } = useSelector(state => state.auth);
  const { data, isLoading, isError, error, refetch } = useQuery(userId, getStudentByUserId);
  const {
    data: courseData,
    isLoading: dataLoaded,
    isError: isDataError,
    error: dataError
  } = useQuery(['completedCourse', userId], completedCourses);
  const handleUpdate = () => {
    setUpdate(prev => !prev);
  };

  useEffect(() => {
    if (!isLoading || data) {
      setProfileData(data.data);
    } else if (isError) {
      message.error(error?.response?.data?.message);
    }
  }, [data]);

  useEffect(() => {
    if (!dataLoaded || courseData) {
      setCompletedCourse(courseData.data);
    } else if (isDataError) {
      message.error(dataError.message);
    }
  }, [courseData]);

  const getLoader = loading => loading;

  const profileDataArr = () => {
    if (profileData) {
      const { firstName, lastName, organization, country, phoneNumber, jobTitle } = profileData;
      const fullName = `${firstName} ${lastName}`;
      return [
        { name: { fullName }, icon: <FaUser style={IconStyle} /> },
        { name: { organization }, icon: <FaBuilding style={IconStyle} /> },
        { name: { country }, icon: <FaGlobeAfrica style={IconStyle} /> },
        { name: { phoneNumber }, icon: <FaPhone style={IconStyle} /> },
        { name: { jobTitle }, icon: <FaUserMd style={IconStyle} /> }
      ];
    }
  };

  const IconStyle = {
    color: '#fff',
    display: 'block',
    margin: 'auto',
    height: '70%',
    width: '70%'
  };

  return (
    <MainLayout sidebar={isMobileDevice ? false : userId ? true : false}>
      {profileData ? (
        <Fragment>
          <ProfileHeader>
            <ProfileContainer gutter={16}>
              <AvatarContainer
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 10 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
                update={update}>
                <ProfileAvatar
                  icon={
                    profileData.profilePixUrl ? (
                      <img src={profileData.profilePixUrl} />
                    ) : (
                      <UserOutlined />
                    )
                  }
                  size={isMobileDevice ? 110 : 200}
                />
              </AvatarContainer>
              <UpdateContainer
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 14 }}
                lg={{ span: 15 }}
                xl={{ span: 15 }}
                offset={!isMobileDevice && 1}>
                <UserName update={update} isMobile={isMobileDevice}>
                  {update ? `Update Profile` : `${profileData.firstName} ${profileData.lastName}`}
                </UserName>
                <UpdateLink onClick={handleUpdate} update={update}>
                  <PenIcon></PenIcon>
                  <UpdateText>Update profile</UpdateText>
                </UpdateLink>
              </UpdateContainer>
            </ProfileContainer>
          </ProfileHeader>
          {!update ? (
            <CourseContainer>
              <CourseDiv>
                <Heading>Your Details</Heading>
                <DetailsRow gutter={[16, 16]}>
                  {profileDataArr() &&
                    profileDataArr().map((profile, index) => (
                      <ProfileDetailsCard
                        name={Object.values(profile.name)[0]}
                        icon={profile.icon}
                        index={index}
                        mainData={profileDataArr()}
                      />
                    ))}
                </DetailsRow>
                <Heading>Completed courses</Heading>
                <CourseText>
                  {completedCourse && completedCourse.length !== 0
                    ? 'You have successfully completed the following courses'
                    : 'No completed course yet'}
                </CourseText>
                <Courses gutter={32}>
                  {completedCourse &&
                    completedCourse.map(course => (
                      <CompletedCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 6 }}
                        xl={{ span: 6 }}>
                        <CourseCard
                          key={course.courseId}
                          courseId={course.courseId}
                          userId={userId}
                          renderState={course}
                          price={course.coursePrice.amount}
                          title={course.title}
                          description={course.description}
                          modules={course.modules}
                          duration={`${course.duration}hrs`}
                          level={course.courseLevelName}
                          institutionLogo={course.institutionLogo}
                          institutionName={course.institution}
                          courseThumbNailUrl={course.courseThumbNailUrl}
                          width={null}
                        />
                      </CompletedCol>
                    ))}
                </Courses>
              </CourseDiv>
            </CourseContainer>
          ) : (
            <ProfileUpdate
              getLoader={getLoader}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              handleUpdate={handleUpdate}
              profileData={profileData}
              refetch={refetch}
              isMobile={isMobileDevice}
            />
          )}
        </Fragment>
      ) : (
        <PageLoader isLoading={dataLoaded} fullHeight={'100%'} fullWidth={'100%'} />
      )}
    </MainLayout>
  );
};

export default Profile;

const ProfileHeader = styled(Row)`
  background: #000;
  min-height: 12em;
  padding: 1.5em 0 0 3.5em;
  @media ${mobile} {
    min-height: 14em;
  }
`;
const ProfileContainer = styled(Row)`
  width: 100%;
`;
const AvatarContainer = styled(Col)`
  position: relative;
  transition: all 0.5s ease-out;
  opacity: ${({ update }) => (update ? `0` : `1`)};
  pointer-events: ${({ update }) => (update ? `none` : 'auto')};
`;

const ProfileAvatar = styled(Avatar)`
  display: block !important;
  position: absolute !important;
  top: 0.1em;
  @media ${mobile} {
    top: -0.2em;
    left: 1.4em;
  }
`;

const UpdateContainer = styled(Col)`
  padding-top: 4.4em;
  margin-left: -12%;
  @media ${mobile} {
    /* margin: auto!important; */
    margin-left: -1.4rem !important;
    text-align: center;
  }
`;

const UserName = styled(Typography.Paragraph)`
  color: #fff;
  font-weight: bold;
  line-height: 0.8;
  font-size: 3em;
  margin-bottom: 0.3em !important;
  margin-top: ${({ update, isMobile }) => (!update ? 0 : isMobile ? '1.8em' : '.6em')};
  margin-left: ${({ update, isMobile }) => (!update ? 0 : isMobile ? '3em' : '-3em')};
  transition: all 0.5s ease-out;
  transform: ${({ update }) =>
    update ? `translate(-5em, -1em) scale(0.6)` : 'translate(0em, 0em) scale(1)'};

  @media ${mobile} {
    font-size: ${({ update }) => (update ? '1.7em' : '1.7em')};
    margin-bottom: 1rem !important;
  }
`;

const UpdateLink = styled(Typography.Paragraph)`
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.5s ease-out;
  opacity: ${({ update }) => (update ? `0` : `1`)};
  pointer-events: ${({ update }) => (update ? `none` : 'auto')};
  @media ${mobile} {
    margin: 0 0 !important;
    font-size: 1.1em;
  }
`;

const UpdateText = styled(Typography.Text)`
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
  margin-left: 6px;
`;
const PenIcon = styled(FaPenAlt)``;

const CourseContainer = styled.div`
  margin: auto;
  padding: 0 2rem 0 2rem;
  min-height: 20rem;
  transition: all 0.5s ease-out;
`;
const CourseDiv = styled.div`
  margin: auto;
  padding-top: 5.5em;
  min-height: 30rem;
`;
const Heading = styled(Typography.Title)`
  color: #7f7f7f !important;
  font-size: 1.7em !important;
  font-weight: bold;
`;
const CourseText = styled(Typography.Paragraph)`
  color: #7f7f7f;
  font-size: 1.3em;
  font-weight: bold;
  @media ${mobile} {
    font-size: 1em;
  }
`;

const CompletedCol = styled(Col)`
  @media ${mobile} {
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;

const DetailsRow = styled(Row)`
  margin-bottom: 4rem;
`;

const Courses = styled(Row)``;
