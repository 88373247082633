import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Row, Col, Typography, Button } from 'antd';
import Axios from 'axios';
import { FaLayerGroup } from 'react-icons/fa';
import { ImAttachment } from 'react-icons/im';
import { AiOutlineClockCircle, AiOutlineEdit, AiOutlineEye } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';

import NotificationModal from 'components/modals/NotificationModal';
import { showToast } from 'store/actions/toast';
import ButtonLoader from 'components/common/ButtonLoader';
import { courseDetails } from 'store/actions/course';
import { MainContext } from 'index';
import { checkIfCourseUpdateIsAWaitingApproval, getCourseBuilder } from 'api/queries/Course';
import {
  createcourse,
  previewcourse
  // surveyreport
} from 'adminRoutes';
import AppData from 'AppData';
import { useModal } from 'components/hooks/useModal';
import Collaboration from 'assets/images/landing page Assets/Home landing page/collaboration.png';
import UpdateCoursePopUp from './UpdateCoursePopUp';

const ExploreCourseCard = ({ courseDetail }) => {
  const [activate, setActivate] = useState('Deactivate');
  const [activateData, setActivateData] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [modalFunction, setModalFunction] = useState();
  const [modalTitle, setModalTitle] = useState();
  const [modalText, setModalText] = useState([]);
  const [activateMessage, setActivateMessage] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const mainContext = useContext(MainContext);
  const { data: isAwaitingApproval } = useQuery(
    ['isAwaitingApproval', courseDetail[0]?.courseId],
    checkIfCourseUpdateIsAWaitingApproval
  );
  const { isModalVisible, showModal, handleOk, handleCancel } = useModal(modalFunction);

  useEffect(() => {
    if (activateData?.success === true && activateData?.code === '00') {
      activate === 'Deactivate' ? setActivate('Activate') : setActivate('Deactivate');
    } else if (activateData?.success === false) {
      setActivateMessage('Action could not be completed');
    }
  }, [activateData]);

  const toggleActivate = () => {
    if (courseDetail[0]?.courseId) {
      setisLoading(true);
      const getActivateData = async courseId => {
        const { data } = await Axios.get(`/Course/api/Course/Toggle/${courseId}`);
        return data;
      };

      getActivateData(courseDetail[0]?.courseId).then(data => {
        handleCancel();
        setActivateData(data);
        setisLoading(false);
        dispatch(
          showToast(
            'success',
            `Course ${activate === 'Activate' ? 'Activated' : 'Deactivated'} successfully`
          )
        );
        console.log('data', data);
      });
    }
  };

  const courseBuilderDetails = async courseId => {
    setisLoading(true);
    try {
      const { data } = await getCourseBuilder(courseId);
      if (data) {
        setisLoading(false);
        dispatch(courseDetails(data));
        if (mainContext.build === AppData.adminBuildType) history.push(createcourse);
      }
    } catch (error) {
      setisLoading(false);
      console.log(error);
      // setIsLoading(false);
    }
  };

  const handleActivate = () => {
    showModal();
    setModalFunction(() => toggleActivate);
    setModalText([
      `Are you sure you want to  ${
        activate === 'Deactivate' ? 'deactivate' : 'activate'
      } this course ?`,
      `This course will ${activate === 'Deactivate' ? 'no longer' : ''}  be published live`
    ]);
    setModalTitle(activate === 'Deactivate' ? 'Deactivation Request' : 'Activate Request');
  };

  const handleUpdate = () => {
    showModal();
    if (isAwaitingApproval?.data) {
      setModalFunction(() => () => courseBuilderDetails(courseDetail[0]?.courseId));
      setModalText([
        'Are you sure you want to Update this course ?',
        'your updates would be reviewed before being published'
      ]);
      setModalTitle('Update Request');
    } else {
      setModalFunction(null);
      setModalText([
        'Course cannot be updated now because previous updates are being reviewed',
        'you can try again later'
      ]);
      setModalTitle('Awaiting Approval');
    }
  };

  const handlePreview = async courseId => {
    setisLoading(true);
    try {
      const { data } = await getCourseBuilder(courseId);
      setisLoading(false);
      if (data) {
        history.push(previewcourse, data);
      }
    } catch (error) {
      setisLoading(false);
      console.log(error);
    }
  };

  // generate nav title
  // const navPath = title => {
  //   return title.split(' ').join('-').toLowerCase();
  // };
  //  handle survey report function
  // const handlesurvey = () => {
  //   const [courseData] = courseDetail;
  //   history.push(surveyreport.replace(':surveyreport', navPath(courseData.title)), courseData);
  // };

  return (
    <>
      {activateMessage && (
        <NotificationModal
          status="failed"
          message={activateMessage}
          iconHeight="80px"
          iconWidth="80px"
          actionText="close"
          actionFunc={() => window.location.reload()}
        />
      )}
      <UpdateCoursePopUp
        isModalVisible={isModalVisible}
        modalTitle={modalTitle}
        isLoading={isLoading}
        modalFunction={modalFunction}
        modalText={modalText}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <CourseRow>
        <CourseCol1 span={7}>
          <CourseBg bgImage={courseDetail[0]?.courseThumbNailUrl}></CourseBg>
        </CourseCol1>
        <CourseCol2 span={17}>
          <Row gutter={32}>
            <Col span={16}>
              <CourseTitle>{courseDetail[0]?.title}</CourseTitle>
              <CourseInstitution>
                partner: <InstitutionName>{courseDetail[0]?.institution}</InstitutionName>
              </CourseInstitution>
              <CourseParagraph>{courseDetail[0]?.description}</CourseParagraph>
              <Ul>
                <Li>
                  <Icon>
                    <ImAttachment color="#737475" />
                  </Icon>
                  {`${courseDetail[0]?.modules}${
                    courseDetail[0]?.modules === 1 ? 'module' : 'modules'
                  }`}
                </Li>
                <Li>
                  <Icon>
                    <AiOutlineClockCircle color="#737475" />
                  </Icon>
                  {`${courseDetail[0]?.duration}hrs`}
                </Li>
                <Li>
                  <Icon>
                    <FaLayerGroup color="#737475" />
                  </Icon>
                  {courseDetail[0]?.courseLevel === 1 ? 'Beginner Level' : 'Advanced Level'}
                </Li>
              </Ul>
              {/* <SurveyButton onClick={handlesurvey}>
                <Icon margin={'2px 12px 0px 0px'}>
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.4583 0.5V2H13.8389C14.2727 2 14.625 2.33375 14.625 2.74475V14.7553C14.6248 14.9527 14.5419 15.142 14.3945 15.2816C14.2471 15.4213 14.0473 15.4998 13.8389 15.5H1.16113C0.952696 15.4998 0.752864 15.4213 0.605482 15.2816C0.4581 15.142 0.37521 14.9527 0.375 14.7553V2.74475C0.375 2.33375 0.727292 2 1.16113 2H3.54167V0.5H11.4583ZM3.54167 3.5H1.95833V14H13.0417V3.5H11.4583V5H3.54167V3.5ZM5.125 11V12.5H3.54167V11H5.125ZM5.125 8.75V10.25H3.54167V8.75H5.125ZM5.125 6.5V8H3.54167V6.5H5.125ZM9.875 2H5.125V3.5H9.875V2Z"
                      fill="#0966B8"
                    />
                  </svg>
                </Icon>
                <span> View survey report</span>
              </SurveyButton> */}
            </Col>
            <Col span={8}>
              <CourseStatus>Published</CourseStatus>
              <CourseDate>{courseDetail[0]?.publishDate}</CourseDate>
            </Col>
          </Row>
          <BtnRow>
            <Col span={8}></Col>
            <Col span={16}>
              <BtnDiv>
                <CourseBtn
                  background="#fff"
                  block={true}
                  icon={
                    isLoading ? (
                      <></>
                    ) : (
                      <AiOutlineEye color={'#5d5d5d'} style={{ marginRight: '2rem' }} />
                    )
                  }
                  color={'#5d5d5d'}
                  onClick={() => handlePreview(courseDetail[0]?.courseId)}>
                  {isLoading ? (
                    <ButtonLoader isSubmitting={isLoading} color="#0966b8" />
                  ) : (
                    'Preview'
                  )}
                </CourseBtn>

                <CourseBtn
                  background="#F4F4F4"
                  color="#5d5d5d"
                  block={true}
                  icon={<AiOutlineEdit color={'#5d5d5d'} style={{ marginRight: '2rem' }} />}
                  onClick={handleUpdate}>
                  Update
                </CourseBtn>
                <CourseBtn block={true} onClick={handleActivate} background="#0966b8" color="#fff">
                  {activate}
                </CourseBtn>
              </BtnDiv>
            </Col>
          </BtnRow>
        </CourseCol2>
      </CourseRow>
    </>
  );
};

export default ExploreCourseCard;

const Container = styled.div`
  margin: auto;
  margin-top: 1.2em;
  width: 98%;
  min-height: 20em;
  /* background: #fff; */
`;
const CourseRow = styled(Row)`
  width: 100%;
  border-radius: 7px;
  /* box-shadow: 1px 1px 3px 2px #cfcfcf; */
`;

const CourseCol1 = styled(Col)`
  padding: 0.4em;
  background: #fff;
`;

const CourseBg = styled(Col)`
  background: url(${props => (props.bgImage ? props.bgImage : Collaboration)}) no-repeat
    center/cover;
  position: relative;
  height: 100%;
  &::after {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 2;
  }
`;

const CourseCol2 = styled(Col)`
  padding: 1.2rem 1.2rem;
  background: #fff;
`;

const CourseTitle = styled(Typography.Title)`
  color: #000 !important;
  margin: 0 0 1rem 0 !important;
  font-size: 1.5em !important;
`;

const CourseInstitution = styled(Typography.Paragraph)`
  font-size: 20px;
  color: #000;
`;

const InstitutionName = styled(Typography.Text)`
  font-size: 20px;
  font-weight: 400px;
  color: #000;
`;

const CourseParagraph = styled(Typography.Paragraph)`
  color: #000;
  margin-bottom: 1.3em !important;
  font-size: 0.8em;
  line-height: 1.4em;
`;

// const SurveyButton = styled(Button)`
//   display: flex;
//   align-items: center;
//   font-size: 1rem;
//   border: none;
//   cursor: pointer;
//   padding-left: 0px;
// `;

const Ul = styled.ul`
  padding: 0 0 !important;
  margin-top: 2em;
`;

const Li = styled.li`
  display: inline-block;
  font-size: 0.76em;
  margin-right: 1rem;
  color: ##000;
`;

const Icon = styled(Typography.Text)`
  margin-right: 5px;
  margin: ${props => props.margin};
`;

const CourseStatus = styled(Typography.Paragraph)`
  text-align: right;
  margin-bottom: 0.2em !important;
  color: #0966b8;
  width: 149px;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background: #0966b81a;
  height: 28px !important;
  text-align: center;
  border-radius: 14px;
  letter-spacing: 28%;
  height: 27px;
  font-weight: 500;
`;
const CourseDate = styled(Typography.Paragraph)`
  color: #000;
  font-size: 15px;
  width: 149px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BtnRow = styled(Row)`
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  padding-top: 2rem;
  margin-top: 1rem;
`;

const BtnDiv = styled.div`
  display: flex;
  gap: 1rem;
`;

const CourseBtn = styled(Button)`
  background: ${props => props.background};
  color: ${props => props.color};
  height: 3.5rem;
  font-size: 1em;
  font-weight: lighter;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
`;
