import React from 'react';
import styled from 'styled-components';
import { Row, Col, Typography, Button, Progress } from 'antd';
import DataScience from 'assets/images/datascience.jpg';
import { FaClock, FaLayerGroup, FaBook } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { delivery } from 'userRoutes';
import { useSelector } from 'react-redux';

const CourseCard2 = ({
  span,
  title,
  institution,
  description,
  percentage,
  courseThumbNailUrl,
  amount,
  modules,
  duration,
  courseId,
  level
}) => {
  const history = useHistory();
  const { userId } = useSelector(state => state.auth);

  const handleResume = () => {
    history.push(delivery, { userId, courseId });
  };

  return (
    <Container span={span}>
      <TopCard img={courseThumbNailUrl || DataScience}></TopCard>
      <BottomCard>
        <CourseTitle>
          {title}
          <CoursePrice>{amount > 0 ? `N${amount}` : 'Free'}</CoursePrice>
        </CourseTitle>
        <CourseText1>
          Partner: <Name>{institution}</Name>
        </CourseText1>
        <CourseText2>{description}</CourseText2>
        <ProgressRow>
          <Col span={16}>
            <Progress percent={percentage} size="small" />
          </Col>
          <Col span={7} offset={1}>
            <ResumeBtn onClick={handleResume}>Resume</ResumeBtn>
          </Col>
        </ProgressRow>
        <Ul>
          <Li>
            <Icon>
              <FaBook color="7e7e7e" />
            </Icon>{' '}
            `${modules} ${modules > 1 ? 'Modules' : 'Module'}`
          </Li>
          <Li>
            <Icon>
              <FaClock color="7e7e7e" />
            </Icon>
            {duration}
          </Li>
          <Li>
            <Icon>
              <FaLayerGroup color="7e7e7e" />
            </Icon>
            {level} Level
          </Li>
        </Ul>
      </BottomCard>
    </Container>
  );
};

export default CourseCard2;

const Container = styled(Col)`
  width: 23em;
  height: 23em;
  border-radius: 10px;
  margin-top: 2rem;
  background: #eee;
`;

const TopCard = styled(Row)`
  width: 100%;
  height: 55%;
  background: url(${({ img }) => `"${img}"`}) no-repeat center/cover;
`;

const BottomCard = styled(Row)`
  width: 100%;
  height: 45%;
  background: #eee;
  padding: 1em;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 20%);
`;

const CourseTitle = styled(Typography.Paragraph)`
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  font-weight: bold;
  color: #7f7f7f;
  margin-bottom: -2em !important;
`;

const CoursePrice = styled(Typography.Text)`
  display: block !important;
  color: #468366;
  font-size: 1.8em;
  font-weight: bold;
`;
const CourseText1 = styled(Typography.Paragraph)`
  font-size: 0.75em;
  margin: 2em 0 0 0 !important;
  line-height: 1.5;
`;

const Name = styled(Typography.Text)`
  color: #468366;
  font-weight: bold;
`;

const CourseText2 = styled(Typography.Paragraph)`
  font-size: 0.75em;
  margin: 0.4em 0 0 0 !important;
  line-height: 1.5;
`;

const ProgressRow = styled(Row)`
  width: 100%;
  margin-top: 0.5em;
`;
const ResumeBtn = styled(Button)`
  background: #1d9800;
  border: none;
  display: block !important;
  color: #fff;
  width: 100%;
`;
const Ul = styled.ul`
  padding: 0 0 !important;
`;

const Li = styled.li`
  display: inline-block;
  font-size: 0.6em;
  margin-right: 1rem;
  color: #7e7e7e;
`;

const Icon = styled(Typography.Text)`
  margin-right: 5px;
`;
