import React from 'react';
import 'styles/utils.css';
import { Box, Text, ResponsiveContext, Paragraph, Card, Button } from 'grommet';
import { FormEdit } from 'grommet-icons';
import { isMobile } from 'utils';
import ProfileIcon from './ProfileIcon';

const WorkFlowCard = ({ title }) => {
  const size = React.useContext(ResponsiveContext);
  return (
    <Box>
      <Card
        direction={isMobile(size) ? 'column' : 'row'}
        pad="xsmall"
        align="center"
        gap={isMobile(size) ? 'medium' : 'small'}>
        <Box width="xxsmall">
          <ProfileIcon size="medium" />
        </Box>
        <Box wrap>
          <Paragraph
            style={{ fontSize: 13 }}
            margin={{ bottom: 'xsmall', top: 'none' }}
            color="#777">
            {title}
          </Paragraph>
        </Box>
      </Card>
    </Box>
  );
};

export default WorkFlowCard;
