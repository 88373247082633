import React from 'react';
import { Box, Image } from 'grommet';
import SkillDormLogo from 'assets/images/skilldormblacklogo.png';
import { animate } from 'utils';
import ChangePasswordForm from 'components/forms/ChangePasswordForm';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ForgetPasswordForm from 'components/forms/ForgetPasswordForm';
import NotificationModal from 'components/modals/NotificationModal';
import { login } from 'userRoutes';

const ForgotPassword = () => {
  const history = useHistory();
  const [successMessage, setSuccessMessage] = React.useState(null);
  const dispatch = useDispatch();

  return (
    <Box pad="medium" align="center" justify="center" height="100vh">
      <Box height="xsmall" width="small">
        <img src={SkillDormLogo} style={{ margin: '2.2rem 0 0 -.1rem' }} alt="" />
      </Box>
      <Box align="center" animation={animate({ type: 'slideUp' })}>
        <ForgetPasswordForm successMessage={successMessage} setSuccessMessage={setSuccessMessage} />
      </Box>
      {successMessage && (
        <NotificationModal
          title="Password Reset"
          message={successMessage}
          status="success"
          iconHeight="80px"
          iconWidth="80px"
          actionText="Got it"
          actionFunc={() => {
            history.replace(login);
          }}
        />
      )}
    </Box>
  );
};

export default ForgotPassword;
