import React from 'react';
import { Box } from 'grommet';
import MainLayout from 'components/MainLayout';
import CourseSurvey from 'components/utils/CourseSurvey';
import { useMediaQuery } from 'react-responsive';

const CourseSurveyPage = () => {
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });

  return (
    <MainLayout isVideoPage selectedKey="dashboard" sidebar={false} padded={0}>
      <Box style={{ overflow: 'auto', height: 'initial' }}>
        <Box direction={isMobileDevice ? 'column-reverse' : 'row'} style={{ height: '100%' }}>
          <Box flex align="center" className="topwrapper">
            <CourseSurvey />
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default CourseSurveyPage;
