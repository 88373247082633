import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';

import { ButtonCTA } from 'components/common/ButtonCTA';
import image from 'assets/images/Img-highlight.png';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const StartTeaching = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  return (
    <Container>
      <Inner>
        <Content>
          <Heading>Start Teaching Now!</Heading>
          <SubText>Let’s take you through the journey of becoming a world class Instructor</SubText>
          <ButtonCTA
            bgColor="#0966b8"
            color="#fff"
            fontSize="20px"
            fontWeight="600"
            width="250px"
            data-tf-popup="t1R5fOuZ"
            data-tf-hide-headers
            data-tf-iframe-props="title=My typeform"
            data-tf-medium="snippet"
            height={isMobile ? '59px' : '65px'}>
            Sign up as an instructor
          </ButtonCTA>
        </Content>
      </Inner>
    </Container>
  );
};

export default StartTeaching;

const Container = styled.div`
  display: flex;
  position: relative;
  margin: auto;
  background: url(${image}) no-repeat center center/cover;
  height: 512px;
  /* padding-top: 4rem 0 0 0; */

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
  }

  @media ${mobile} {
    padding: 2.5rem 1.5rem;
    height: 442px;
  }
`;
const Inner = styled.div`
  display: flex;
  width: 70rem;
  position: relative;
  z-index: 2;
  margin: auto;
  @media ${mobile} {
    margin: 0;
    width: 100%;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 560px;
  @media ${mobile} {
    gap: 2.6rem;
    width: 100%;
  }
`;
const Heading = styled(Typography.Paragraph)`
  color: #fff;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  @media ${mobile} {
    text-align: left;
    font-size: 36px;
    line-height: 43.57px;
    margin-bottom: 0rem !important;
  }
`;
const SubText = styled(Typography.Paragraph)`
  color: #fff;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  @media ${mobile} {
    font-size: 18px;
    line-height: 25.78px;
`;
