import React from 'react';
import { Box, Button, Tabs, Tab, Paragraph } from 'grommet';
// import { isMobile } from 'utils';
import ButtonLoader from 'components/common/ButtonLoader';

const InstitutionFinishForm = props => {
  const {
    institutionUserState,
    institutionFormState,
    countries,
    banks,
    prev,
    isSubmitting,
    institutionType
  } = props;
  // const size = React.useContext(ResponsiveContext);
  return (
    <Box style={{ maxWidth: 475 }}>
      <Box margin={{ bottom: 'small' }}>
        <Tabs>
          <Tab title="Partner Details">
            <Box pad="medium">
              <>
                {institutionFormState &&
                  [
                    'Name',
                    'InstitutionType',
                    'Website',
                    'PhoneNumber',
                    'Email',
                    'Address',
                    'Country',
                    'AccountNumber',
                    'Bank',
                    'Overview',
                    'FullName'
                  ].map((item, i) => {
                    let newState = [...institutionFormState];
                    newState[1] = {
                      ...institutionFormState[1],
                      value: institutionType.find(d => d.value === institutionFormState[1].value)
                        ?.name
                    };
                    newState[6] = {
                      ...institutionFormState[6],
                      value: countries.find(d => d.code1 === institutionFormState[6].value)
                        ?.countryName
                    };
                    newState[7] = {
                      ...institutionFormState[8],
                      value: institutionFormState[8].value
                    };
                    newState[8] = {
                      ...institutionFormState[9],
                      value: banks.find(d => d.id === institutionFormState[9].value)?.name
                    };
                    newState[9] = {
                      ...institutionFormState[10],
                      value: institutionFormState[10].value?.replace(/<[^>]+>/g, '')
                    };
                    newState[10] = {
                      ...institutionFormState[14],
                      value: institutionFormState[14].value
                    };
                    return (
                      <Box key={i}>
                        <Paragraph>{`${item}: ${newState[i].value}`}</Paragraph>
                      </Box>
                    );
                  })}
              </>
            </Box>
          </Tab>
          <Tab title="User Details">
            <Box pad="medium">
              {institutionUserState &&
                ['FirstName', 'LastName', 'Email', 'PhoneNumber', 'userName'].map((item, i) => {
                  return (
                    <Box key={i}>
                      <Paragraph>{`${item}: ${institutionUserState[i].value}`}</Paragraph>
                    </Box>
                  );
                })}
            </Box>
          </Tab>
        </Tabs>
      </Box>

      <Box direction="row" gap="medium" justify="end">
        <Button size="small" margin={{ top: 'xsmall' }} label="Prev" onClick={() => prev()} />
        <Button
          onClick={() => {
            props.onFinish();
          }}
          primary
          size="small"
          margin={{ top: 'xsmall' }}
          label={!isSubmitting && 'Submit'}
          icon={isSubmitting ? <ButtonLoader isSubmitting={isSubmitting} /> : null}
        />
      </Box>
    </Box>
  );
};

export default InstitutionFinishForm;
