import React, { useState } from 'react';
import 'styles/utils.css';
import { Box, Text, ResponsiveContext, CheckBox, Button } from 'grommet';
import { isMobile } from 'utils';
import { FormEdit } from 'grommet-icons';

const EmailNotifyCard = ({ cardTitle, status, checkedFunc, editFunc, cardAction }) => {
  const size = React.useContext(ResponsiveContext);
  const [notify, setNotify] = useState(status);

  return (
    <Box
      direction={isMobile(size) ? 'column' : 'row'}
      gap={isMobile(size) ? 'medium' : 'none'}
      margin={{ bottom: isMobile(size) ? 'large' : 'none' }}
      justify="between"
      pad={{ left: 'medium', bottom: 'small', right: 'medium' }}>
      <Box gap="large" align="center" direction="row" width={isMobile(size) ? 'fill' : 'medium'}>
        <CheckBox
          toggle
          checked={notify}
          onChange={() => {
            setNotify(!notify);
          }}
          onClick={() => checkedFunc()}
        />
        <Text>{cardTitle}</Text>
      </Box>
      <Button
        size="small"
        margin={{ top: 'xsmall' }}
        onClick={() => editFunc()}
        icon={
          <FormEdit
            color="#FF8C00"
            size="medium"
            style={{ border: '2px solid #FF8C00', borderRadius: '50%' }}
          />
        }
      />
    </Box>
  );
};

export default EmailNotifyCard;
