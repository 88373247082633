import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

import bg from 'assets/images/stepsBg.png';
import icon1 from 'assets/images/Group 14.png';
import icon2 from 'assets/images/Group 16.png';
import icon3 from 'assets/images/Group 15.png';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const EasySteps = () => {
  return (
    <Container>
      <Inner>
        <Heading>Easy Steps on how to get started</Heading>
        <StepDiv>
          <Step>
            <ImgDiv isBordered>
              <Img src={icon1} width="" height="" />
            </ImgDiv>

            <TextDiv>
              <Header>Sign up</Header>
              <SubText>
                Get started as an instructor on skillDorm by filling out required details
              </SubText>
            </TextDiv>
          </Step>
          <Step>
            <ImgDiv isBordered>
              <Img src={icon2} width="" height="" />
            </ImgDiv>

            <TextDiv>
              <Header>Outline course</Header>
              <SubText>Select your teaching category and upload contents</SubText>
            </TextDiv>
          </Step>
          <Step>
            <ImgDiv>
              <Img src={icon3} width="" height="" />
            </ImgDiv>

            <TextDiv>
              <Header>Publish</Header>
              <SubText>After publishing, your content goes live on Skilldorm</SubText>
            </TextDiv>
          </Step>
        </StepDiv>
      </Inner>
    </Container>
  );
};

export default EasySteps;
const Container = styled.div`
  display: flex;
  padding: 4rem 0 0 0;
  background: url(${bg}) no-repeat center center/cover;
  max-height: 67rem;
  @media ${mobile} {
    padding: 4rem 1.5rem;
  }
`;
const Inner = styled.div`
  display: flex;
  width: 70rem;
  margin: auto;
  margin-top: 0;
  flex-direction: column;
  align-items: center;
  @media ${mobile} {
    align-items: left;
    width: 100%;
  }
`;
const Heading = styled(Typography.Paragraph)`
  position: relative;
  font-size: 48px;
  font-style: normal;
  color: #fff;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  margin-bottom: 6rem !important;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 76px;
    width: 125px;
    height: 6px;
    background: #0966b8;
  }
  @media ${mobile} {
    text-align: left;
    font-size: 36px;
    line-height: 43.57px;
    margin-bottom: 4.5rem !important;
    &::before {
      top: 106px;
    }
  }
`;

const StepDiv = styled.div`
  width: 585px;
  @media ${mobile} {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
`;
const Step = styled.div`
  display: flex;
  gap: 3.5rem;
  align-items: center;
  margin-bottom: 4rem;
  @media ${mobile} {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 0rem;
  }
`;

const ImgDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &::before {
    position: absolute;
    content: '';
    width: 3px;
    height: 76px;
    display: ${props => (props.isBordered ? 'block' : 'none')};
    background: -webkit-linear-gradient(180deg, #0966b8 11.46%, #6eb8fd 100%);
    top: 106px;
    left: 50%;
  }
  @media ${mobile} {
    ::before {
      display: none;
    }
  }
`;

const Img = styled.img`
  width: 94px;
  height: 94px;
  @media ${mobile} {
    width: 55.24px;
    height: 55.24px;
  }
`;

const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Header = styled(Typography.Paragraph)`
  background: -webkit-linear-gradient(180deg, #0966b8 11.46%, #6eb8fd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  @media ${mobile} {
    font-size: 24px;
    line-height: 29.26px;
  }
`;
const SubText = styled(Typography.Paragraph)`
  font-size: 25px;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  @media ${mobile} {
    font-size: 18px;
    line-height: 21.78px;
  }
`;
