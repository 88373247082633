import React, { useState } from 'react';
import { Box, Button, Form, ResponsiveContext, ThemeContext } from 'grommet';
// import { isMobile } from 'utils';
import ButtonLoader from 'components/common/ButtonLoader';
import PageHeader from 'components/common/PageHeader';
import { useMutation, useQuery } from 'react-query';
import { categoryList, fetchTokens } from 'api/queries/Notification';
// import ConfigurationSettings from '../../../ConfigurationSettings';
import PageLoader from 'components/common/PageLoader';
import { useHistory } from 'react-router-dom';
import ConfigurationSettings from 'pages/admin/Settings';
import BulkEmail from 'components/forms/BulkEmailForm';
import { useForm, Controller } from 'react-hook-form';
import { createEmail } from 'api/mutations/Notification';
import Message from 'components/common/Message';
import NotificationModal from 'components/modals/NotificationModal';
import { emailtemplate, previewemail } from 'adminRoutes';
import { isMobile } from 'utils';
import ModalCard from 'components/modals/ModalCard';
import { useDispatch } from 'react-redux';
import { clearEmailState, emailState } from 'store/actions/email';

const NewEmail = props => {
  const { register, errors, setValue, getValues, handleSubmit, control } = useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const size = React.useContext(ResponsiveContext);
  const [placeholderDisabled, setPlaceholderDisabled] = useState(true);
  // const { editemail } = useParams();
  const [successMessage, setSuccessMessage] = useState(null);
  const [createEmailError, setCreateEmailError] = useState(null);
  const [selectedToken, setSelectedToken] = useState(null);
  const [categoryListId, setCategoryListId] = useState(null);
  const [newVar, setNewVar] = useState(null);
  const { data } = useQuery(['categoryList'], categoryList);
  const { data: tokensData } = useQuery(['tokens', categoryListId], fetchTokens, {
    refetchOnWindowFocus: false,
    enabled: categoryListId // turned off by default, manual refetch is needed
  });
  // const [emailData, setEmailData] = useState(null);

  const [createEmailMutate] = useMutation(createEmail, {
    onSuccess: data => {
      const { description, success } = data;
      if (success) {
        setSuccessMessage(description);
      } else {
        setCreateEmailError(description);
        setIsSubmitting(false);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setCreateEmailError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        setCreateEmailError(error.message);
      }
    }
  });

  const onSubmit = async ({ name, code, message, emailCategoryId }) => {
    const payload = {
      Name: name,
      Code: code,
      Template: message,
      EmailCategoryId: data?.data.find(d => d.name === emailCategoryId).id
    };

    try {
      setCreateEmailError(null);
      setIsSubmitting(true);
      await createEmailMutate(payload);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handlePreview = () => {
    const values = getValues();
    // console.log(values);
    dispatch(emailState(values));
    if (values) history.push(previewemail, values);
  };

  return (
    <ConfigurationSettings selectedKey="Email Management">
      <Box margin={{ bottom: 'small' }}>
        <PageHeader marginBottom="none" pageTitle="Add New Templates" />
        <Box title="">
          <ThemeContext.Extend value={{ global: { colors: { doc: '' } } }}>
            <Box
              pad="xxsmall"
              margin={{ bottom: 'medium' }}
              width="100%"
              elevation="small"
              background="doc"
            />
          </ThemeContext.Extend>
          {createEmailError && <Message message={createEmailError} status="error" />}
          <Form style={{ maxWidth: 752 }} onSubmit={handleSubmit(onSubmit)}>
            <BulkEmail
              setPlaceholderDisabled={setPlaceholderDisabled}
              placeholderDisabled={placeholderDisabled}
              Controller={Controller}
              control={control}
              data={data}
              register={register}
              errors={errors}
              setSelectedToken={setSelectedToken}
              selectedToken={selectedToken}
              setOpenModal={setOpenModal}
              setCategoryListId={setCategoryListId}
              setValue={setValue}
              newVar={newVar}
              setNewVar={setNewVar}
              handlePreview={handlePreview}
            />
            <Box
              margin={{ top: 'large' }}
              direction={isMobile(size) ? 'column' : 'row'}
              align="center"
              gap="medium"
              justify="end">
              <Button
                secondary
                size="small"
                onClick={() => {
                  dispatch(clearEmailState());
                  history.push(emailtemplate);
                }}
                label="Cancel"
              />
              <Button
                type="submit"
                primary
                size="small"
                label={!isSubmitting && 'Save'}
                icon={isSubmitting ? <ButtonLoader isSubmitting={isSubmitting} /> : null}
              />
              {openModal && (
                <ModalCard
                  data={tokensData?.data}
                  setSelectedToken={setSelectedToken}
                  onClose={() => {
                    setOpenModal(false);
                  }}
                  onSelectToken={token => {
                    if (newVar) {
                      newVar.model.change(writer => {
                        const insertPosition = newVar.model.document.selection.getFirstPosition();

                        writer.insertText(token ? token : '', insertPosition);
                      });
                    }
                  }}
                  header="Insert Placeholders"
                  subHeader="Click to insert placeholder in the content which will dynamically get resolved into the appropriate data"
                />
              )}
            </Box>
          </Form>
        </Box>
      </Box>

      <PageLoader isLoading={false} />
      {successMessage && (
        <NotificationModal
          message="Email Created Successfully"
          status="success"
          iconHeight="80px"
          iconWidth="80px"
          actionText="Complete"
          actionFunc={() => {
            history.push(emailtemplate);
            dispatch(clearEmailState());
          }}
        />
      )}
    </ConfigurationSettings>
  );
};

export default NewEmail;
