import React from 'react';
import { Row } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { home, discovery, programs, partner } from 'userRoutes';

const Menu = ({ color }) => {
  return (
    <Row>
      <Ul>
        <Li>
          <NavLink to={home} color={color}>
            Home
          </NavLink>
        </Li>

        <Li>
          <NavLink to={discovery} color={color}>
            Courses
          </NavLink>
        </Li>
        {/* <Li>
          <NavLink to={programs} color={color}>
            Programs
          </NavLink>
        </Li> */}

        <Li>
          <NavLink to={partner} color={color}>
            Partners
          </NavLink>
        </Li>
      </Ul>
    </Row>
  );
};

Menu.propTypes = {};

export default Menu;

const Ul = styled.ul`
  padding-inline-start: 0;
  margin: 0.7em 0 0 0 !important;
`;
const Li = styled.li`
  list-style: none;
  display: inline;
  margin-left: 3rem;
  color: #fff;
  font-weight: bold;
`;

const NavLink = styled(Link)`
  color: ${props => `${props.color}!important`};
`;
