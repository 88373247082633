import React from 'react';
import styled from 'styled-components';
import { Row, Col, Typography, List, Switch } from 'antd';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const Notification = () => {
  const data = [
    {
      title: 'Onboarding',
      description: 'Tips and tricks to help you get started learning'
    },
    {
      title: 'Reminders',
      description: 'Get notifications when your subscription is set to renew'
    },
    {
      title: 'Course Launches',
      description: 'Get notified when we launch new courses'
    },
    {
      title: 'Newsletters',
      description: 'Receive Skilldorms weekly newsletters, updates and learning tips'
    },
    {
      title: 'Special Offers',
      description: 'Be informed when there is a course promotion!'
    },
    {
      title: 'Instructors Notification',
      description:
        'Stay informed about news and events from your course instructors like webinars and meet-ups'
    }
  ];

  return (
    <Container>
      <Heading>Notifications</Heading>
      <Paragraph>
        I want to receive emails about: <Text>status</Text>
      </Paragraph>
      <List
        dataSource={data}
        renderItem={item => (
          <Item key={''}>
            <List.Item.Meta
              title={<ItemTitle>{item.title}</ItemTitle>}
              description={<ItemDescription>{item.description}</ItemDescription>}
            />
            <div>
              <Switch defaultChecked />
            </div>
          </Item>
        )}
      />
    </Container>
  );
};

export default Notification;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
const Heading = styled(Typography.Title)`
  font-size: 1.6em !important;
  font-weight: bold !important;
  color: #afafaf !important;
  margin-bottom: 1em !important;
  @media ${mobile} {
    font-size: 1.2em !important;
  }
`;
const Paragraph = styled(Typography.Paragraph)`
  color: #afafaf;
  @media ${mobile} {
    font-size: 0.9em !important;
  }
`;

const Item = styled(List.Item)`
  padding: 1em 1em;
  &:nth-of-type(odd) {
    background: #f7f7f7;
  }
`;

const ItemTitle = styled(Typography.Title)`
  color: #7f7f7f !important;
  font-size: 1.7em !important;
  @media ${mobile} {
    font-size: 1.5em !important;
  }
`;
const ItemDescription = styled(Typography.Text)`
  color: #afafaf !important;
  font-weight: bold !important;
  @media ${mobile} {
    font-size: 0.9em !important;
    text-align: justify;
  }
`;

const Text = styled(Typography.Text)`
  float: right;
  color: #afafaf;
`;
