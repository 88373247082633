import { Popconfirm, Space, Switch, Table } from 'antd';
import React from 'react';
import { BiEdit } from 'react-icons/bi';

const CurrentNotificationTable = ({
  inAppActiveData,
  handleEdit,
  selectedRecord,
  isSubmitting,
  handletoggle,
  setSelectedRecord
}) => {
  const confirm = () => {
    // setActiveNotification(state => !state);
    handletoggle(selectedRecord);
    // message.success(selectedRecord.isActive ? 'Set Active Cancelled' : 'Click on Continue');
  };

  const cancel = () => {
    // if (activeNotification) {
    //   return;
    // }
    // setActiveNotification(false);
    // message.error('Click on Cancel');
  };

  /** table column */
  const columns = [
    {
      title: 'S/N',
      dataIndex: 'sNo',
      key: 'sNo'
    },
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      render: text => <span style={{ color: '#5B97FF' }}>{text}</span>
    },
    {
      title: 'CATEGORY',
      dataIndex: 'inAppNotificationCategoryName',
      key: 'inAppNotificationCategoryName'
    },
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {!record?.isActive && (
            <BiEdit
              onClick={() => {
                handleEdit(record.id);
              }}
              size="1.5em"
              style={{ marginTop: 8, color: '#5B97FF', cursor: 'pointer' }}
            />
          )}
          <Popconfirm
            title={
              record?.isActive
                ? 'Notification disabled ?'
                : 'Current Active Notification will be disabled ?. If your start date is today and the time is now!!!. '
            }
            onConfirm={confirm}
            onCancel={cancel}
            okText={record?.isActive ? '' : 'Continue'}
            cancelText="Cancel">
            <Switch
              size="small"
              checked={record?.isActive}
              loading={selectedRecord?.id === record?.id && isSubmitting}
              onChange={() => {
                if (record?.id) {
                  setSelectedRecord(record);
                }
              }}
            />
          </Popconfirm>
        </Space>
      )
    }
  ];

  return <Table columns={columns} dataSource={inAppActiveData} style={{ marginTop: 20 }} />;
};

export default CurrentNotificationTable;
