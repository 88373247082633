import React from 'react';
import { Card, Row, Tabs, Typography } from 'antd';
import CourseCard2 from 'components/common/CourseCard2';
import styled from 'styled-components';
import ActiveComCard from 'components/common/ActiveComCard';

const tabHeader = {
  tab1: 'Active Courses',
  tab2: 'Completed Courses'
};

const HeaderTitle = title => <TabTitle level={3}>{title}</TabTitle>;

const DashboardTabs = ({ completedCourse, coursesEnrolled }) => {
  const { TabPane } = Tabs;
  console.log('completed', completedCourse);
  return (
    <div>
      <Tabs size={'large'} defaultActiveKey="1" centered>
        <TabPane tab={HeaderTitle(tabHeader.tab1)} key="1">
          <CardRow gutter={[24, 40]}>
            {coursesEnrolled?.length !== 0 ? (
              coursesEnrolled?.map((course, index) => (
                <ActiveComCard
                  key={index}
                  dashboard
                  price={course?.coursePrice?.amount}
                  title={course.title}
                  institutionName={course.institution}
                  description={course.description}
                  percentage={course.percentage}
                  courseThumbNailUrl={course.courseThumbNailUrl}
                  amount={course?.coursePrice?.amount}
                  // span={6}
                  institutionLogo={course.institutionLogo}
                  modules={course.modules}
                  courseId={course.courseId}
                  duration={course.duration}
                  level={course.courseLevelName}
                  tabType={tabHeader.tab1}
                />
              ))
            ) : (
              <Div>
                <DivCard>
                  <DivText>You have no active courses yet</DivText>
                </DivCard>
              </Div>
            )}
          </CardRow>
        </TabPane>
        <TabPane tab={HeaderTitle(tabHeader.tab2)} key="2">
          <CardRow gutter={[24, 24]}>
            {completedCourse?.length !== 0 ? (
              completedCourse?.map((course, index) => (
                <ActiveComCard
                  key={index}
                  completed
                  price={course?.coursePrice?.amount}
                  title={course.title}
                  institutionName={course.institution}
                  description={course.description}
                  percentage={course.percentage}
                  courseThumbNailUrl={course.courseThumbNailUrl}
                  amount={course?.coursePrice?.amount}
                  span={8}
                  institutionId={course?.institutionId}
                  institutionLogo={course.institutionLogo}
                  modules={course.modules}
                  courseId={course.courseId}
                  duration={course.duration}
                  level={course.courseLevelName}
                  tabType={tabHeader.tab2}
                />
              ))
            ) : (
              <Div>
                <DivCard>
                  <DivText>You have no completed courses yet</DivText>
                </DivCard>
              </Div>
            )}
          </CardRow>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default DashboardTabs;

const CardRow = styled(Row)`
  margin: 1rem 0 5rem 0;
`;

const TabTitle = styled(Typography.Title)`
  margin: 0px !important;
`;

const Div = styled.div`
  min-height: 10rem;
  margin: auto;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const DivCard = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 5px;
  padding: 2rem;
`;

const DivText = styled(Typography.Paragraph)`
  font-weight: bold;
  font-size: 1.2em;
  color: #0966b8;
`;
