import React from 'react';
import styled from 'styled-components';
import {
  Row,
  Col,
  Typography,
  Button,
  Form,
  Input,
  Select,
  Switch,
  DatePicker,
  Checkbox,
  Upload
} from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import PageLoader from 'components/common/PageLoader';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from 'store/actions/toast';
import { updateDiscount } from 'api/mutations/Discount';
import { search } from 'api/mutations/Institution';
import moment from 'moment';
import { getCourses } from 'api/mutations/Course';
import { getDiscountId } from 'api/queries/Discount';

const DiscountUpdate = ({
  discountType,
  handleSwitch,
  checkBoxAllCourses,
  handleCourseSwitch,
  cancel,
  refetch,
  discount
}) => {
  const dispatch = useDispatch();
  const { register, errors, setValue, handleSubmit, control } = useForm();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [selectedInstitutionId, setSelectedInstitutionId] = React.useState(0);

  const { data: institutions } = useQuery(['institutions'], search);

  const { data: courses } = useQuery(['courses', selectedInstitutionId], getCourses);
  const { initialVaules, setInitialVaules } = React.useState({
    discountType: true
  });

  //console.log(232, institutions)
  const { discountId } = useSelector(state => state.discount);

  // const { data: discount, refresh, isLoading } = useQuery(
  //   ['discountResult', discountId],
  //   getDiscountId
  // );

  console.log('ghy', discount);

  function handleChange(value) {
    setSelectedInstitutionId(value);
  }

  const [updateDiscountMutate] = useMutation(updateDiscount, {
    onSuccess: data => {
      setIsSubmitting(false);
      if (data.success) {
        dispatch(showToast('success', 'Discount updated successfully'));
        cancel();
        refetch();
      } else {
        dispatch(showToast('error', data.description));
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        dispatch(
          showToast(
            'error',
            <>
              We couldn't connect to the server.
              <br />
              Check your network or contact your admin
            </>
          )
        );
      } else {
        dispatch(showToast('error', error.message));
      }
    }
  });

  const handleFormSubmit = async ({
    name,
    description,
    discountChargeType,
    institutionType,
    startDate,
    endDate,
    allcoursesDiscount,
    discountType,
    amount,
    code,
    setUsage,
    usage,
    courseId,
    file
  }) => {
    try {
      const data = {
        discountId: discountId,
        name: name,
        description: description,
        //discountChargeType: Number(discountChargeType),
        //institutionType: institutionType,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        // allcoursesDiscount: allcoursesDiscount,
        //discountType: discountType ? 1 : 2,
        //amount: amount,
        //code: code,
        setUsage: setUsage,
        usage: Number(usage)
        //courseId: courseId,
        //file: file
      };
      console.log(111, data, discountType, allcoursesDiscount, setUsage);

      await updateDiscountMutate(data);
    } catch (error) {}
  };

  return (
    <Container
      labelCol={{
        span: 4
      }}
      wrapperCol={{
        span: 14
      }}
      layout="horizontal">
      <Form
        initialValues={{
          discountType: true,
          allcoursesDiscount: true,
          setUsage: true,
          name: discount?.data.name,
          description: discount?.data.description,
          usage: discount?.data.usageLimit,
          startDate: moment(discount?.data.startDate, 'YYYY/MM/DD'),
          endDate: moment(discount?.data.endDate, 'YYYY/MM/DD')
        }}
        onFinish={handleFormSubmit}>
        <FormContainer>
          <Form.Item>
            <FormHeader>Update Discount</FormHeader>
          </Form.Item>

          <Form.Item
            label="Discount Name"
            name="name"
            rules={[
              {
                required: false,
                message: 'Please enter discount name'
              }
            ]}>
            <Input defaultValue={discount?.data.name} />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: false,
                message: 'Please enter discount description'
              }
            ]}>
            <Input.TextArea defaultValue={discount?.data.description} />
          </Form.Item>

          {/* <Form.Item
            label="Discount Type"
            name="discountType"
            rules={[
              {
                required: true,
                message: 'Please enter discount type'
              }
            ]}>
            <Switch
              checkedChildren="Default"
              unCheckedChildren="Advanced"
              onChange={handleSwitch}
              defaultChecked
            />
          </Form.Item>

          <Form.Item
            label="Select Partner"
            name="institutionType"
            rules={[
              {
                required: true,
                message: 'Please select partner'
              }
            ]}>
            <Select
              placeholder="All Partners"
              onChange={handleChange}
              defaultValue={discount?.data.institution}>
              {institutions?.map(item => (
                <Select.Option value={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </Form.Item> */}

          <Form.Item
            label="Start Date"
            name="startDate"
            rules={[
              {
                required: false,
                message: 'Please enter discount start date'
              }
            ]}>
            <DatePicker
              defaultValue={moment(discount?.data.startDate, 'YYYY/MM/DD')}
              format={'YYYY/MM/DD'}
            />
          </Form.Item>

          <Form.Item
            label="End Date"
            name="endDate"
            rules={[
              {
                required: false,
                message: 'Please enter discount start date'
              }
            ]}>
            <DatePicker
              defaultValue={moment(discount?.data.endDate, 'YYYY/MM/DD')}
              format={'YYYY/MM/DD'}
            />
          </Form.Item>

          {/* <Form.Item
            label="Select Courses"
            name="allcoursesDiscount"
            rules={[
              {
                required: true,
                message: 'Please select all courses discount'
              }
            ]}>
            <Switch
              checkedChildren="Apply discount to all courses for the selected partner"
              unCheckedChildren="Selected Courses Only"
              onChange={handleCourseSwitch}
              defaultChecked={true}
            /> */}

          {/* <Checkbox onChange={handleCourseSwitch} value={checkBoxAllCourses}>Apply discount to all courses for the selected partner</Checkbox> */}
          {/* </Form.Item>
          {!checkBoxAllCourses ? (
            <>
              <Form.Item label="Courses" name="courseId">
                <Select mode="multiple" style={{ width: '100%' }} placeholder="Please select">
                  {courses?.data &&
                    courses?.data?.map(item => (
                      <Select.Option key={item.courseId}>{item.title}</Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </>
          ) : null} */}

          {/* <Form.Item
            label="Discount Charge Type"
            name="discountChargeType"
            rules={[
              {
                required: true,
                message: 'Please select discount charge type'
              }
            ]}>
            <Select placeholder="Select" defaultValue={discount?.data.discountChargeTypeName}>
              <Select.Option value="1">Flat Amount</Select.Option>
              <Select.Option value="2">Percentage</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Discount Amount"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please enter discount amount'
              }
            ]}>
            <Input type="number" min="0" defaultValue={discount?.data.amount} />
          </Form.Item> */}

          {discountType ? (
            <>
              {/* <Form.Item
                label="Discount Code"
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Please enter discount code'
                  }
                ]}>
                <Input defaultValue={discount?.data.code} />
              </Form.Item> */}

              <Form.Item
                label="Set Usage Limit"
                name="setUsage"
                rules={[
                  {
                    required: false,
                    message: 'Please set usage limit'
                  }
                ]}>
                <Switch checkedChildren="True" unCheckedChildren="False" defaultChecked={true} />
              </Form.Item>

              <Form.Item
                label="Usage Limit"
                name="usage"
                rules={[
                  {
                    required: false,
                    message: 'Please enter usage limit'
                  }
                ]}>
                <Input type="number" min="0" defaultValue={discount?.data.usageLimit} />
              </Form.Item>
            </>
          ) : (
            <UploadDiv>
              {/* <Col span={10}>
                <UploadHeader>Bulk Upload Unique Discount Code</UploadHeader>
                <UploadText>
                  Upload your unique ocument code and/or membership id for authentication and
                  validation here. It must meet our course count quality standard template to be
                  accepted. Important guidelines: .xlsx, .pdf, .docx, or .png no text on the image
                </UploadText>
              </Col>
              <Col span={10} offset={4}>
                <Upload
                  /// action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture"
                  name="file"
                  // defaultFileList={[...fileList]}
                >
                  <UploadBtn icon={<UploadOutlined />}>Upload File</UploadBtn>
                </Upload>
                <Typography.Paragraph style={{ marginTop: '.7em' }}>
                  Download Template here&nbsp;&nbsp;
                  <DownloadOutlined />
                </Typography.Paragraph>
              </Col> */}
            </UploadDiv>
          )}
        </FormContainer>
        <ButtonDiv>
          <CancelBtn onClick={cancel}>Cancel</CancelBtn>
          <SaveBtn htmlType="submit">Update Discount</SaveBtn>
        </ButtonDiv>
      </Form>
    </Container>
  );
};

export default DiscountUpdate;

const Container = styled(Form)`
  width: 65%;
  min-height: 30em;
  margin: auto;
`;

const FormContainer = styled.div`
  border: 1px solid #dfdfdf;
  width: 100%;
  min-height: 10em;
  margin: auto;
  margin-top: 2em;
  padding: 1.5em 2em;
`;
const UploadDiv = styled(Row)`
  width: 90%;
  margin: auto;
  margin-left: -0.1em;
  margin-top: 2em;
`;
const UploadHeader = styled(Typography.Paragraph)`
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0.2em !important;
`;
const UploadText = styled(Typography.Paragraph)`
  font-size: 0.9em;
  text-align: justify;
`;

const UploadBtn = styled(Button)`
  color: #fff;
  background: #173253;
  border: 1px solid #173253;
  border-radius: 2px;
`;

const ButtonDiv = styled.div`
  float: right;
  margin-top: 2em;
`;

const FormHeader = styled(Typography.Paragraph)`
  font-size: 1.8em;
  color: #afafaf;
  font-weight: 1000;
  margin-bottom: 10px !important;
`;
const CancelBtn = styled(Button)`
  border: none;
  background: #7f7f7f;
  color: #fff;
  width: 7em;
`;
const SaveBtn = styled(Button)`
  border: none;
  background: #2c7be5;
  color: #fff;
  margin-left: 1.2em;
`;
