import React, { useState, useMemo, useEffect } from 'react';
import CBTIntro from 'components/utils/CBT/CBTIntro';
import CBTMain from 'components/utils/CBT/CBTMain';
import CBTComplete from 'components/utils/CBT/CBTComplete';
import CBTResult from 'components/utils/CBT/CBTResult';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { shuffleArray } from 'utils';

const CBTController = ({ handleEnded, moduleIndex, sectionIndex, refetch }) => {
  const { quiz } = useSelector(state => state.cbt);
  const [quizData, setQuizData] = useState([]);
  const [canStartQuiz, setCanStartQuiz] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [userChoices, setUserChoices] = useState([]);
  const [score, setScore] = useState(0);
  const [userPassedQuiz, setUserPassedQuiz] = useState(false);

  const resultInfo = useMemo(
    () => (
      <>
        You got {score / quiz.pointPerQuestion} out of {quiz.data.length} question
        {quiz.data.length > 1 ? 's' : ''} correct on your first attempt
      </>
    ),
    [quiz, score]
  );

  const handleRetry = () => {
    setShowResult(false);
    setCanStartQuiz(false);
    setIsComplete(false);
    setUserChoices([]);
    setScore(0);
    if (quiz.randomize) {
      setQuizData(shuffleArray(quiz.data));
    }
  };

  useEffect(() => {
    handleRetry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quiz]);

  useEffect(() => {
    if (quiz.randomize) setQuizData(shuffleArray(quiz.data));
    else setQuizData(quiz.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {!canStartQuiz && (
        <CBTIntro
          title={quiz.name}
          description={quiz.description}
          numberOfQuestions={quiz.data.length}
          setCanStartQuiz={setCanStartQuiz}
        />
      )}
      {canStartQuiz && !isComplete && (
        <CBTMain
          quizData={quizData}
          score={score}
          setScore={setScore}
          setIsComplete={setIsComplete}
          setUserChoices={setUserChoices}
          setUserPassedQuiz={setUserPassedQuiz}
          moduleIndex={moduleIndex}
          sectionIndex={sectionIndex}
          refetch={refetch}
        />
      )}
      {isComplete && !showResult && (
        <CBTComplete
          score={score}
          setShowResult={setShowResult}
          resultInfo={resultInfo}
          handleEnded={handleEnded}
          userPassedQuiz={userPassedQuiz}
        />
      )}
      {showResult && (
        <CBTResult
          quizData={quizData}
          handleEnded={handleEnded}
          userChoices={userChoices}
          resultInfo={resultInfo}
          handleRetry={handleRetry}
          userPassedQuiz={userPassedQuiz}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
`;
export default CBTController;
