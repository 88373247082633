import React from 'react';
import styled from 'styled-components';
import { Typography, Form, Input, Button } from 'antd';

import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const DeleteAccount = () => {
  return (
    <div>
      <Container>
        <Heading>Account deletion</Heading>
        <Paragraph>All your profile and learning data will be permanently deleted</Paragraph>
        <Form
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 14
          }}
          layout="horizontal">
          <Form.Item label="Enter Password">
            <NewInput type="password"></NewInput>
          </Form.Item>
        </Form>
      </Container>
    </div>
  );
};

export default DeleteAccount;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Heading = styled(Typography.Title)`
  font-size: 1.6em !important;
  font-weight: bold !important;
  color: #afafaf !important;
  margin-bottom: 0.5em !important;
  @media ${mobile} {
    font-size: 1.2em !important;
  }
`;

const Paragraph = styled(Typography.Paragraph)`
  color: #afafaf;
  @media ${mobile} {
    font-size: 0.9em !important;
  }
`;
const NewInput = styled(Input)`
  margin-left: 1em;
  width: 17em !important;
`;
