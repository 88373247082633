import React from 'react';
import ReactDOM from 'react-dom';
import './styles/global.css';
import store from './store/store';
import { Provider } from 'react-redux';
import { saveState } from './utils';
import axiosConfig from './configs/axiosConfig';
import { createBrowserHistory } from 'history';
import buildJson from './configs/json/build.json';
import AppData from 'AppData';
import { Grommet } from 'grommet';
import theme from './theme.json';
import Toast from 'components/common/Toast';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import AppAdmin from 'AppAdmin';
import AppUser from 'AppUser';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import ScrollToTop from 'components/common/ScrollToTop';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

// context object
const mainContext = {
  build: buildJson.build
};

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      retry: (failureCount, error) => {
        switch (error.response?.status) {
          case 400:
          case 401:
          case 403:
          case 404:
          case 405:
            return false;
          default:
            return failureCount < 1;
        }
      }
    }
  }
});

export const history = createBrowserHistory();

export const MainContext = React.createContext(mainContext);

window.onbeforeunload = () => {
  saveState(store.getState());
};

// axios config
axiosConfig();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MainContext.Provider value={mainContext}>
        <Grommet theme={theme}>
          <ReactQueryCacheProvider queryCache={queryCache}>
            {buildJson.build === AppData.userBuildType ? (
              <Router>
                <ScrollToTop />
                <AppUser />
              </Router>
            ) : buildJson.build === AppData.adminBuildType ? (
              <Router>
                <AppAdmin />
              </Router>
            ) : (
              <></>
            )}
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </ReactQueryCacheProvider>
          <Toast />
        </Grommet>
      </MainContext.Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
