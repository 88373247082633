import React from 'react';
import 'styles/utils.css';
import { Box, ResponsiveContext, ThemeContext, Anchor, Carousel } from 'grommet';
import CourseCard from 'components/common/CourseCard';
import IconWrapper from 'components/common/IconWrapper';
import Next from 'assets/images/greaterthan.png';
import Previous from 'assets/images/lessthan.png';
import IcsanLogo from 'assets/images/icsan.png';
import { isMobile, isTablet } from 'utils';
import SectionHeader from 'components/utils/CourseDiscovery/SectionHeader';
import { Search } from 'grommet-icons';
import { FormNext } from 'grommet-icons';
import DiscoveryImg from 'assets/images/discover.png';
import styled from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import { getUserInfoByInstitutionId } from 'api/queries/Authentication';
import { courseCategoryList, getSummaryByCatgory } from 'api/queries/Course';
import { courseSearch, courseLanguageSearch } from 'api/mutations/Course';
import { search } from 'api/mutations/Institution';
import { Button as AntButton, Typography, Col, Row, Card } from 'antd';
import { ButtonCTA } from 'components/common/ButtonCTA';
import MainLayout from 'components/MainLayout';
import CustomLogoWrapper from 'components/common/CustomLogoWrapper';
import PageHeader from 'components/common/PageHeader';
import { useMediaQuery } from 'react-responsive';

const NextPosition = styled.div`
  position: absolute;
  top: -26px;
  right: 10px;
`;

const PreviousPosition = styled.div`
  position: absolute;
  top: -26px;
  right: 50px;
`;

const InstitutionLanding = () => {
  const { Title, Text } = Typography;
  const size = React.useContext(ResponsiveContext);
  const [courses] = React.useState([1, 2, 3]);
  const [scrolled, setScrolled] = React.useState(false);
  const [searchBar, setSearchBar] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [courseSearchResult, setCourseSearchResult] = React.useState(null);
  const [institutionSearchResult, setInstitutionSearchResult] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [createError, setCreateError] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });

  const handleScroll = () => {
    var offset = window.scrollY;
    if (offset > 2) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
    if (offset > 271) {
      setSearchBar(true);
    } else {
      setSearchBar(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const NextButton = () => {
    return (
      <NextPosition>
        <IconWrapper icon={Next} width="20px" height="20px" />
      </NextPosition>
    );
  };
  const PreviousButton = () => {
    return (
      <PreviousPosition>
        <IconWrapper icon={Previous} width="20px" height="20px" />
      </PreviousPosition>
    );
  };

  const { data: summaryByCatgory } = useQuery(['summaryByCatgory'], getSummaryByCatgory);
  //console.log('summaryByCatgory', summaryByCatgory)

  const [createMutate, info] = useMutation(courseSearch, {
    onSuccess: dataResult => {
      const { description, data, success } = dataResult;
      if (success) {
        setCourseSearchResult(data);
        setSuccessMessage(description);
      } else {
        setCreateError(description);
        setIsSubmitting(false);
        console.log(description);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setCreateError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        // setCreateError(error.message);
        console.log(error);
      }
    }
  });
  //console.log("CourseSearchResult", courseSearchResult);

  const [createInstitutionMutate] = useMutation(search, {
    onSuccess: dataResult => {
      const { description, success } = dataResult;
      setInstitutionSearchResult(dataResult);
      if (success) {
        setInstitutionSearchResult(dataResult);
        setSuccessMessage(description);
      } else {
        setCreateError(description);
        setIsSubmitting(false);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setCreateError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        // setCreateError(error.message);
        console.log(error);
      }
    }
  });

  React.useEffect(() => {
    try {
      const search = () => {
        const param = {
          page: 1,
          pageSize: 50,
          courseCategoryIds: [],
          parameter: '',
          institutionIds: [],
          courseLevels: [],
          languageIds: []
        };
        createMutate(param);
      };
      search();

      const instituionSearch = () => {
        const param = {
          searchVal: ''
        };
        createInstitutionMutate(param);
      };
      instituionSearch();
    } catch (error) {}
  }, [createInstitutionMutate, createMutate]);

  console.log(33, courseSearchResult);

  return (
    <MainLayout sidebar selectedKey="institution">
      <Box
        height="260px"
        justify="center"
        pad={{ top: 'large', bottom: 'large', right: 'large', left: 'large' }}
        background={{
          dark: true,
          position: '50% 0%',
          repeat: 'no-repeat',
          size: 'cover',
          image: `linear-gradient(to right, rgba(0,0,0,1),rgba(0,0,0,1), rgba(0,0,0,.1) ), url(${DiscoveryImg})`
        }}>
        <div>
          <Title level={1} style={{ marginBottom: '10px', wordSpacing: 5, color: '#fff' }}>
            Lagos Business School
          </Title>

          <SpaceBetween direction="column">
            <Text style={{ fontSize: 'small', color: '#fff' }}>info@lbs.edu.ng</Text>
            <Text style={{ fontSize: 'small', color: '#fff' }}>234-(0)-80987656786</Text>
          </SpaceBetween>
        </div>
      </Box>
      <Box pad="large" background="#fff">
        <Box gap={isMobile(size) ? 'large' : 'small'}>
          <Box direction="row" gap="medium" justify="between">
            <Box width="100%">
              {!isMobileDevice && (
                <MainHeader direction="row">
                  <CardContainer>
                    <IconWrapper icon={IcsanLogo} width="130px" height="45px" />
                  </CardContainer>
                  <InnerDiv direction="row">
                    <CustomLogoWrapper title="Info@lbsn.org" subtitle="LBSN" />
                    <CustomButton type="primary" shape="round">
                      Visit Website
                    </CustomButton>
                  </InnerDiv>
                </MainHeader>
              )}
              <Box direction="row" align="center" margin={{ vertical: 'xsmall' }}>
                <div>
                  <PageHeader pageTitle="LAGOS BUSINESS SCHOOL" />
                  <Text style={{ fontSize: 'small' }}>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugiat recusandae
                    ducimus est exercitationem culpa consequatur repellendus quae libero eius ipsa
                    iste quos sit, praesentium porro dolorum maiores rerum reiciendis aut.
                    <br />
                    <br />
                    In this track, you'll learn how this versatile language allows you to import,
                    clean, manipulate, and visualize data-all integral skills for any aspiring data
                    professional or researcher. <br />
                    Through interactive exercises, you will get hands on with
                    <br />
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia eius possimus
                    facere cum. Provident, magni debitis. Repellat possimus omnis doloremque.
                    <br />
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugiat recusandae
                    ducimus est exercitationem culpa consequatur repellendus quae libero eius ipsa
                    iste quos sit, praesentium porro dolorum maiores rerum reiciendis aut.
                    <br />
                    <br />
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem inventore
                    sapiente similique dolorem aliquid modi ab harum eaque non, beatae, deserunt
                    error porro minus velit debitis repellendus iure officiis dicta! Mollitia culpa
                    nobis earum amet voluptatem. Corporis ab incidunt culpa nesciunt eum
                    reprehenderit itaque inventore veniam cumque distinctio vero quos tempore quas
                    veritatis a in dicta quo recusandae, hic quidem.
                  </Text>
                </div>
              </Box>
              <Box margin={{ top: 'large' }}>
                <Box
                  direction={isMobile(size) ? 'column' : 'row'}
                  gap="medium"
                  align={isMobile(size) ? 'stretch' : 'center'}
                  justify="between">
                  <SectionHeader color="#686868" title="Top Programs" marginTop="none" />
                </Box>
                <ThemeContext.Extend
                  value={{
                    carousel: {
                      animation: {
                        duration: 400
                      },
                      icons: {
                        color: 'blue',
                        next: NextButton,

                        previous: PreviousButton
                      },
                      disabled: {
                        icons: {
                          color: 'grey'
                        }
                      }
                    }
                  }}>
                  <Carousel controls={isMobile(size) ? false : 'arrows'}>
                    {courseSearchResult?.items?.map(item => (
                      <Box
                        key={item.courseId}
                        direction={isMobile(size) ? 'column' : 'row'}
                        margin={{ top: 'medium' }}
                        gap="small"
                        justify="between">
                        {courseSearchResult &&
                          courseSearchResult?.items.slice(2, 6).map(item => {
                            return (
                              <CourseCard
                                key={item.courseId}
                                careerRelated
                                course="6"
                                price={item.coursePrice?.amount}
                                title={item.title}
                                description={item.description}
                                modules={item.modules}
                                duration="9h 45mins"
                                level={
                                  item?.courseLevel === 1
                                    ? 'Beginner'
                                    : item?.courseLevel === 2
                                    ? 'Intermedate'
                                    : 'Advanced'
                                }
                                institutionLogo={
                                  item.institutionLogo === null ? IcsanLogo : item.institutionLogo
                                }
                                institutionName={item.institution}
                                courseThumbNailUrl={item.courseThumbNailUrl}
                              />
                            );
                          })}
                      </Box>
                    ))}
                  </Carousel>
                </ThemeContext.Extend>
              </Box>
              <Box margin={{ top: 'medium' }}>
                <Box
                  direction={isMobile(size) ? 'column' : 'row'}
                  gap="medium"
                  align={isMobile(size) ? 'stretch' : 'center'}
                  justify="between">
                  <SectionHeader color="#686868" title="Top Popular Courses" marginTop="none" />
                </Box>
                <ThemeContext.Extend
                  value={{
                    carousel: {
                      animation: {
                        duration: 400
                      },
                      icons: {
                        color: 'blue',
                        next: NextButton,

                        previous: PreviousButton
                      },
                      disabled: {
                        icons: {
                          color: 'grey'
                        }
                      }
                    }
                  }}>
                  <Carousel controls={isMobile(size) ? false : 'arrows'}>
                    {courseSearchResult?.items?.map(item => (
                      <Box
                        key={item.courseId}
                        direction={isMobile(size) ? 'column' : 'row'}
                        margin={{ top: 'medium' }}
                        gap="small"
                        justify="between">
                        {courseSearchResult &&
                          courseSearchResult?.items.slice(2, 6).map(item => {
                            return (
                              <CourseCard
                                key={item.courseId}
                                careerRelated
                                course="6"
                                price={item.coursePrice?.amount}
                                title={item.title}
                                description={item.description}
                                modules={item.modules}
                                duration="9h 45mins"
                                level={
                                  item?.courseLevel === 1
                                    ? 'Beginner'
                                    : item?.courseLevel === 2
                                    ? 'Intermedate'
                                    : 'Advanced'
                                }
                                institutionLogo={
                                  item.institutionLogo === null ? IcsanLogo : item.institutionLogo
                                }
                                institutionName={item.institution}
                                courseThumbNailUrl={item.courseThumbNailUrl}
                              />
                            );
                          })}
                      </Box>
                    ))}
                  </Carousel>
                </ThemeContext.Extend>
                <Box justify="end" direction="row">
                  <Anchor
                    icon={<FormNext />}
                    label="See All Programs"
                    color="#0849ff"
                    href="#"
                    reverse
                  />
                </Box>
              </Box>{' '}
              <Box margin={{ top: 'medium' }}>
                <Box
                  direction={isMobile(size) ? 'column' : 'row'}
                  gap="medium"
                  align={isMobile(size) ? 'stretch' : 'center'}
                  justify="between">
                  <SectionHeader color="#686868" title="Discover All Courses" marginTop="none" />
                </Box>
                <ThemeContext.Extend
                  value={{
                    carousel: {
                      animation: {
                        duration: 400
                      },
                      icons: {
                        color: 'blue',
                        next: NextButton,

                        previous: PreviousButton
                      },
                      disabled: {
                        icons: {
                          color: 'grey'
                        }
                      }
                    }
                  }}>
                  <Carousel controls={isMobile(size) ? false : 'arrows'}>
                    {courseSearchResult?.items?.map(item => (
                      <Box
                        key={item.courseId}
                        direction={isMobile(size) ? 'column' : 'row'}
                        margin={{ top: 'medium' }}
                        gap="small"
                        justify="between">
                        {courseSearchResult &&
                          courseSearchResult?.items.slice(2, 6).map(item => {
                            return (
                              <CourseCard
                                key={item.courseId}
                                careerRelated
                                course="6"
                                price={item.coursePrice?.amount}
                                title={item.title}
                                description={item.description}
                                modules={item.modules}
                                duration="9h 45mins"
                                level={
                                  item?.courseLevel === 1
                                    ? 'Beginner'
                                    : item?.courseLevel === 2
                                    ? 'Intermedate'
                                    : 'Advanced'
                                }
                                institutionLogo={
                                  item.institutionLogo === null ? IcsanLogo : item.institutionLogo
                                }
                                institutionName={item.institution}
                                courseThumbNailUrl={item.courseThumbNailUrl}
                              />
                            );
                          })}
                      </Box>
                    ))}
                  </Carousel>
                </ThemeContext.Extend>
                <Box justify="end" direction="row">
                  <Anchor
                    icon={<FormNext />}
                    label="See All Programs"
                    color="#0849ff"
                    href="#"
                    reverse
                  />
                </Box>
              </Box>{' '}
            </Box>
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default InstitutionLanding;

const CardContainer = styled(Card)`
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
  top: 240px;
  position: absolute;
  top: -110px;
`;

const CustomButton = styled(ButtonCTA)`
  margin-left: 25px;
`;

const MainHeader = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  flex-direction: ${({ direction }) => direction};
  justify-content: space-between;
  position: relative;
  padding-left: ${({ padLeft }) => padLeft};
  flex-wrap: wrap;
`;

const InnerDiv = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  flex-direction: ${({ direction }) => direction};
  padding-left: ${({ padLeft }) => padLeft};
  flex-wrap: wrap;
  position: absolute;
  top: -30px;
  right: 0;
`;
const SpaceBetween = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  border-bottom: ${({ border }) => (border === 'bottom' ? 'solid 1px #cccccc' : 'none')};
  flex-direction: ${({ direction }) => direction};
  justify-content: space-between;
  padding-bottom: 10px;
  padding-left: ${({ padLeft }) => padLeft};
  flex-wrap: wrap;
`;
