import React from 'react';
import { Row, Typography, Button } from 'antd';
import styled from 'styled-components';
import { FaAngleRight } from 'react-icons/fa';
import { discovery } from 'userRoutes';
import { useHistory } from 'react-router-dom';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const ExporeButton = props => {
  const history = useHistory();
  return (
    <ButtonDiv>
      <NewButton onClick={() => history.push(discovery)}>
        Explore courses
        <Typography.Text style={{ position: 'relative' }}>
          <Caret />
        </Typography.Text>
      </NewButton>
    </ButtonDiv>
  );
};

const ButtonDiv = styled.div`
  position: static;
  margin: 1em 0em 0 0em !important;
  height: 3.5rem;
  width: 15em;
  z-index: 20;
  @media ${mobile} {
    height: 2rem;
    width: 14em;
    margin: auto !important;
    margin-top: 1em !important;
    text-align: center;
  }
`;

const NewButton = styled(Button)`
  position: static;
  background: #0966b8;
  border: none;
  height: 3.5rem;
  color: #fff;
  width: 11em;
  font-size: 18px;
  padding: 0 0.2em 0 0em;
  border-radius: 1.5rem;
  top: -20em;
  margin-top: 2em !important;
  &::placeholder {
    margin: 0 0 0 0;
  }
  &:hover {
    background: none;
    border: 3px solid #0966b8;
    color: #fff;
  }
  @media ${mobile} {
    width: 12em;
    font-size: 15px;
    height: 3rem;
    top: -17em;
    right: 6em;
  }
`;

const Caret = styled(FaAngleRight)`
  color: #fff;
  position: absolute;
  top: -0.8em;
  left: 0.3em;
`;

export default ExporeButton;
