import { css } from 'styled-components';

const AppData = {
  ROLE: {
    STUDENT: 'STUDENT',
    SUPERADMIN: 'SUPER ADMIN',
    RIVETADMIN: 'RIVET ADMIN',
    INSTITUTIONADMIN: 'INSTITUTION ADMIN',
    RIVETREPORT: 'RIVET REPORT',
    INSTITUTIONREPORT: 'INSTITUTION REPORT',

    INSTITUTIONADMINID: 14
  },
  permissions: {
    createCourse: 'createCourse',
    manageuser: 'manageuser',
    viewuser: 'viewuser',
    manageinstitution: 'manageinstitution',
    viewinstitution: 'viewinstitution',
    manageRole: 'manageRole',
    viewRole: 'viewRole',
    viewReport: 'viewReport',
    viewInstitutionReport: 'viewInstitutionReport',
    viewRivetReport: 'viewRivetReport',
    viewAuditTrail: 'viewAuditTrail',
    manageworkflow: 'manageworkflow',
    viewworkflow: 'viewworkflow',
    approvecourse: 'approvecourse',
    viewAwaitingApproveCourse: 'viewAwaitingApproveCourse',
    manageCourseQA: 'manageCourseQA',
    viewCourseQA: 'viewCourseQA',
    manageCourseConfiguration: 'manageCourseConfiguration',
    viewCourseConfiguration: 'viewCourseConfiguration',
    manageCourseWorkflowApprovalSettings: 'manageCourseWorkflowApprovalSettings',
    viewCourseWorkflowApprovalSettings: 'viewCourseWorkflowApprovalSettings',
    manageEmailSettings: 'manageEmailSettings',
    viewEmailSettings: 'viewEmailSettings',
    managePrivacySettings: 'managePrivacySettings',
    viewPrivacySettings: 'viewPrivacySettings',
    manageCertificateSettings: 'manageCertificateSettings',
    viewCertificateSettings: 'viewCertificateSettings'
  },
  MAINTANANCE: 'Maintenance',
  chunkSize: 1048576 * 1, //its 1MB, increase the number measure in mb
  paymentChannel: 1,
  basicCourseStep: 1,
  courseAdditionalInfoStep: 2,
  courseBuilder: 3,
  coursePricing: 4,
  userBuildType: 'user',
  adminBuildType: 'admin',
  courseApproval: 2,
  courseRejection: 3,
  contentType: {
    video: 'video',
    audio: 'audio',
    image: 'image',
    article: 'article',
    quiz: 'quiz',
    compressVideo: 'compressVideo'
  },
  assetType: {
    2: 'video',
    1: 'image',
    3: 'article',
    4: 'quiz',
    5: 'compressVideo'
  },
  videoPageTabTheme: {
    tab: {
      active: {
        background: 'light-1',
        color: 'dark-1'
      },
      background: 'light-3',
      border: undefined,
      color: 'dark',
      hover: {
        background: 'light-1'
      },
      margin: undefined,
      pad: {
        bottom: '20px',
        horizontal: '16px'
      }
    },
    tabs: {
      header: {
        extend: ({ theme }) => css`
          ${'' /* padding: 10px; */}
          /* or 'padding: ${theme.global.edgeSize
            .small}' */
           box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
          /* or 'box-shadow: ${theme.global.elevation.light.medium}' */
        `
      }
    }
  },
  videoReviewTabTheme: {
    tab: {
      active: {
        background: 'light-3',
        color: 'dark-1'
      },
      background: 'light-1',
      border: {
        side: 'bottom',
        color: 'background-back',
        active: {
          color: 'border'
        },
        hover: {
          color: 'dark-1'
        }
      },
      color: 'dark',
      hover: {
        background: 'light-1'
      },
      margin: undefined,
      pad: {
        bottom: 'small',
        horizontal: 'medium',
        top: 'small'
      },
      extend: ({ theme }) => css`
        border-radius: 4px;
        /* or 'border-radius: ${theme.global.control.border.radius}' */
        ${'' /* box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5); */}/* or 'box-shadow: ${theme.global
          .elevation.light.small}' */
      `
    },
    tabs: {
      // background: 'dark-3',
      gap: 'medium',
      header: {
        // background: 'dark-2',
        extend: ({ theme }) => css`
          ${'' /* padding: 10px; */}
          /* or 'padding: ${theme.global.edgeSize
            .small}' */
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.50);
          /* or 'box-shadow: ${theme.global.elevation.light.medium}' */
        `
      }
    }
  },
  carousel: {
    carousel: {
      animation: {
        duration: 400
      },
      icons: {
        color: 'blue'
      },
      disabled: {
        icons: {
          color: 'grey'
        }
      }
    }
  },
  infinitePage: 1000000
};

export default AppData;
