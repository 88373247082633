import React, { useState } from 'react';
import { message, Modal, Typography } from 'antd';
import styled from 'styled-components';
import CustomButton from 'components/common/CustomButton';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AiOutlinePlus } from 'react-icons/ai';
import OptionItem from 'components/utils/QuizBuilder/OptionItem';

const AddQuestion = ({ quizData, questionIndex, setQuiz, setQuizType, isReview }) => {
  const { Paragraph } = Typography;
  const [options, setOptions] = useState(quizData?.options || []);

  const addOptionHandler = () => {
    setOptions(state => [
      ...state,
      { id: state.length, title: '', description: '', isAnswer: false, errorMessage: null }
    ]);
  };

  const onCloseModal = () => {
    setOptions([]);
    setQuizType(null);
  };

  const addQuestionHandler = () => {
    if (options.length < 2) {
      message.error('Please add more than one option');
      return;
    }

    if (options.some(item => item.title === '')) {
      setOptions(state =>
        state.map(item => ({
          ...item,
          errorMessage: item.title === '' ? 'Please enter an answer' : null
        }))
      );
      return;
    }

    if (options.every(item => item.isAnswer === false)) {
      message.error('Please select a correct answer');
      return;
    }

    setQuiz(state => {
      const data = [...state.data];
      data[questionIndex === -1 ? state.data.length - 1 : questionIndex].options = options.map(
        ({ title, description, isAnswer }) => ({
          title,
          description,
          isAnswer
        })
      );
      return { ...state, data };
    });
    onCloseModal();
  };

  const cancelHandler = () => {
    onCloseModal();
    if (quizData?.options) return;

    setQuiz(state => {
      const data = [...state.data];
      data.pop();
      return { ...state, data };
    });
  };

  return (
    <Modal
      title={
        isReview
          ? 'Review Question'
          : quizData.options.length > 0
          ? 'Update Question'
          : 'Add Question'
      }
      visible={true}
      destroyOnClose
      onCancel={isReview ? onCloseModal : cancelHandler}
      footer={
        isReview
          ? null
          : [
              <CustomButton
                key="back"
                bgColor="#4d4d53"
                color="#fff"
                size="large"
                onClick={isReview ? onCloseModal : cancelHandler}>
                Cancel
              </CustomButton>,
              <CustomButton
                key="submit"
                onClick={addQuestionHandler}
                bgColor="#0966b8"
                color="#fff"
                size="large">
                Save
              </CustomButton>
            ]
      }>
      <Paragraph>Question</Paragraph>
      <CKEditor
        editor={ClassicEditor}
        data={quizData?.question}
        onChange={(event, editor) => {
          const editorData = editor.getData();
          setQuiz(state => {
            const data = [...state.data];
            data[state.data.length - 1].question = editorData;
            return { ...state, data };
          });
        }}
        disabled={isReview}
      />
      <Spacer />
      <Paragraph>Answer</Paragraph>
      {options.map((option, index) => (
        <OptionItem
          key={option.id}
          quizType={quizData.type}
          optionData={option}
          setOptions={setOptions}
          index={index}
          errorMessage={option.errorMessage}
          isReview={isReview}
        />
      ))}
      {!isReview && (
        <Center>
          <CustomButton
            onClick={addOptionHandler}
            bgColor="transparent"
            icon={<AiOutlinePlus style={{ paddingTop: 2, marginRight: 5 }} />}
            color="#0966b8">
            Add Option
          </CustomButton>
        </Center>
      )}
    </Modal>
  );
};

const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Spacer = styled.div`
  width: 100%;
  height: ${props => props.space || '20px'};
`;

export default AddQuestion;
