import React, { useEffect, useRef } from 'react';
import { Box, Collapsible } from 'grommet';
import { useMutation, useQuery } from 'react-query';
import { courseEnrolledDetails } from 'api/queries/Course';
import { useState } from 'react';
import 'styles/global.css';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';
import { coursecompleted, discovery } from 'userRoutes';
import PageLoader from 'components/common/PageLoader';
import { courseUploadViewing } from 'api/mutations/Course';
import { showToast } from 'store/actions/toast';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from 'components/MainLayout';
import CBTController from 'components/utils/CBT/CBTController';
import { clearCBT, setCBT } from 'store/actions/cbt';
import { setModuleList } from 'store/actions/video';
import { adminPreviewCourse } from 'api/queries/Course';
import DeliveryTabs from 'components/utils/CourseDelivery/DeliveryTabs';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'antd';
import AppData from 'AppData';
import ResourceDownloader from 'components/common/ResourceDownloader';
import PreviewDeliveryTab from 'components/common/PreviewDeliveryTab';

const PreviewCourse = () => {
  const [activeIndex, setActiveIndex] = useState([0]);
  const [videoModuleIndex, setVideoModuleIndex] = useState(0);
  const [videoSectionIndex, setVideoSectionIndex] = useState(0);
  const [resource, setResource] = useState(null);
  const videoRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  // const [courseId, setCourseId] = React.useState();
  // const [userId, setUserId] = React.useState();
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });
  const { userId: uid } = useSelector(state => state.auth);
  const { courseId: cid } = useSelector(state => state.course);
  const { quiz } = useSelector(state => state.cbt);
  const { data, isLoading } = useQuery(['PreviewCourse', cid], adminPreviewCourse);
  // const [data, setData] = useState();
  // const [isLoading, setLoading ] = useState(true);

  // useEffect( () => {
  //   const getPreview = async () => {
  //     const dta = await Axios.get(`https://sbapidemov2.skillbeta.com.ng/Course/api/Course/AdminPreviewCourse/${cid}`);
  //     return dta;
  //   };
  //   // getPreview() && setLoading(prev => !prev);
  //   getPreview().then(data => {
  //     console.log(data.data);
  //     setData(data.data);
  //   });
  // }, []);

  useEffect(() => {
    console.log(uid);
    console.log(cid);
  }, []);
  const [openNotification, setOpenNotification] = React.useState(true);
  const [currentModule, setCurrentModule] = React.useState(1);
  const [currentSection, setCurrentSection] = React.useState(1);
  const [videoTime, setVideoTime] = React.useState(0);
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoPlay, setVideoPlay] = React.useState('');
  const [current, setCurrent] = React.useState(false);
  const [firstVideo, setFirstVideo] = React.useState(null);

  const [poster] = useState(
    'https://images.unsplash.com/photo-1600267165477-6d4cc741b379?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
  );

  const [state, setState] = useState({
    url: null,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: true
  });

  // console.log(data?.data.videos);

  const {
    url,
    playing,
    controls,
    light,
    volume,
    muted,
    loop,
    played,
    loaded,
    duration,
    playbackRate,
    pip
  } = state;

  // React.useEffect(() => {
  //   if (history?.location?.state) {
  //     setCourseId(history.location.state?.courseId);
  //     setUserId(history.location.state?.userId);
  //   } else {
  //     history.push(createcourse);
  //   }
  // }, [history, history.location.state]);

  React.useEffect(() => {
    console.log(data?.data);
    if (data?.data?.videos !== undefined) {
      setFirstVideo(data?.data?.videos[0]?.items[0]?.courseModuleItemAssets[0]?.url);
      console.log('firstvideo', data?.data?.videos[0]?.items[0]?.courseModuleItemAssets[0]?.url);
      // if (data?.data?.videos[0]?.items[0]?.courseModuleItemAssets[0]?.url?.includes('http://')) {
      //   setFirstVideo(data?.data.videos[0]?.items[0]?.courseModuleItemAssets[0]?.url);
      // } else if (
      //   data?.data?.videos[0]?.items[0]?.courseModuleItemAssets[0]?.url?.includes('https://')
      // ) {
      //   setFirstVideo(data?.data?.videos[0]?.items[0]?.courseModuleItemAssets[0]?.url);
      // } else {
      //   let url = `http://${data?.data?.videos[0]?.items[0]?.courseModuleItemAssets[0]?.url}`;
      //   setFirstVideo(url);
      // }
    }
  }, [data]);

  // handle duration
  const handleDuration = duration => {
    console.log('onDuration', duration);
    setVideoTime(duration);
  };

  var secondsWatched = videoRef?.current?.getCurrentTime();
  // handle play
  const handlePlay = () => {
    console.log('onPlay');
    setState({ playing: true });
    setTimeout(() => {
      handleViewingUpload(uid, cid, currentModule, currentSection, secondsWatched, false);
    }, 5000);
  };

  const handleProgress = state => {
    // console.log('onProgress', state)
    // We only want to update time slider if we are not currently seeking
    if (!state.seeking) {
      // this.setState(state)
    }
  };

  const handlePause = () => {
    console.log('onPause');
    setState({ playing: false });
    setTimeout(() => {
      handleViewingUpload(uid, cid, currentModule, currentSection, secondsWatched, false);
    }, 5000);
  };

  const handleEnded = () => {
    console.log('onEnded');
    setState({ playing: state.loop });
    setTimeout(() => {
      handleViewingUpload(uid, cid, currentModule, currentSection, secondsWatched, true);
    }, 2000);
  };

  const [UploadViewingMutate, { isLoading: load }] = useMutation(courseUploadViewing, {
    onSuccess: data => {
      // const ress = data;
      // console.log(ress);
    },
    onError: error => {
      // setIsSubmitting(false);
      if (error.message === 'Network Error') {
        dispatch(
          showToast(
            <>
              We couldn't connect to the server.
              <br />
              Check your network or contact your admin
            </>
          )
        );
      } else {
        console.log(error);
        dispatch(showToast(error.message));
        console.log(error.message);
        console.log(error.status);
      }
    }
  });

  const handleViewingUpload = async (
    UserId,
    courseId,
    currentModule,
    currentSection,
    secondsWatched,
    isCompleted,
    lastDateVisted = new Date().toLocaleString()
  ) => {
    const pay = {
      UserId,
      courseId,
      currentModule,
      currentSection,
      secondsWatched,
      isCompleted,
      lastDateVisted
    };
    console.log(pay);
    try {
      await UploadViewingMutate(pay);
    } catch (e) {
      console.log(e);
    }
  };

  console.log(videoRef?.current?.getCurrentTime());

  return (
    <MainLayout isVideoPage selectedKey="dashboard" sidebar={false} padded={0}>
      <Box
        style={{
          overflow: isMobileDevice ? 'auto' : 'hidden',
          height: isMobileDevice ? null : '100%'
        }}>
        <Box direction={isMobileDevice ? 'column-reverse' : 'row'} style={{ height: '100%' }}>
          <Collapsible direction="horizontal" open={openNotification}>
            <Box
              className="videoscroll"
              flex
              width="full"
              background="light-2"
              elevation="small"
              style={{
                maxWidth: isMobileDevice ? '100%' : '320px',
                minWidth: isMobileDevice ? '100%' : '320px',
                height: '100%'
              }}>
              <PreviewDeliveryTab
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                courseModules={data?.data?.videos}
                setCurrentModule={setVideoModuleIndex}
                setCurrentSection={setVideoSectionIndex}
                currentModule={videoModuleIndex}
                videoTime={videoTime}
                playing={playing}
                videoPlay={videoPlay}
                setVideoSrc={setVideoSrc}
                setResource={setResource}
                currentSection={videoSectionIndex}
                // handlePlayNext={handlePlayNext}
              />
            </Box>
          </Collapsible>
          <Box flex align="center" className="topwrapper">
            {/* <Box
              className={!showModal ? 'none_d' : 'controlwrapper'}
              direction="row"
              justify="center"
              width={'100%'}
              height={'100%'}
              style={{ position: 'absolute', zIndex: 2, top: 0, right: 0 }}>
              <Box justify="center" direction="row" gap="medium" align="center">
                <Button
                  type="primary"
                  danger
                  onClick={() => setShowModal(false)}
                  style={{ width: 200 }}
                  size="large">
                  Cancel
                </Button>
                <Button size="large" onClick={handleContinue} type="primary">
                  Complete and Continue
                </Button>
              </Box>
            </Box> */}
            {/* {quiz ? (
              <CBTController
                handleEnded={handleEnded}
                moduleIndex={videoModuleIndex}
                sectionIndex={videoSectionIndex}
                refetch={refetch}
              />
            ) : resource ? (
              <ResourceDownloader
                title={resource.title}
                url={resource.url}
                moduleIndex={videoModuleIndex}
                sectionIndex={videoSectionIndex}
                refetch={refetch}
              />
            ) : ( */}
            <Box width="full" className="player-wrapper">
              <ReactPlayer
                ref={videoRef}
                onDuration={handleDuration}
                className="react-player"
                url={videoSrc?.url || firstVideo}
                width="100%"
                height="100%"
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                onContextMenu={e => e.preventDefault()}
                light={poster}
                controls
                playing={playing}
                setVideoSrc={setVideoSrc}
                loop={loop}
                onReady={() => setState({ playing: true })}
                onStart={() => {}}
                onPlay={handlePlay}
                onSeek={e => console.log('onSeek', e)}
                onPause={handlePause}
                onEnded={handleEnded}
                onProgress={handleProgress}
              />
            </Box>
            {/* )} */}
          </Box>
        </Box>
      </Box>
      <PageLoader isLoading={isLoading} />
    </MainLayout>
  );
};

export default PreviewCourse;
