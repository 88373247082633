import React from 'react';
import { Box, Button, FormField, ResponsiveContext, Text, TextInput } from 'grommet';
import { isMobile } from 'utils';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ButtonLoader from 'components/common/ButtonLoader';

const EmailPreview = props => {
  const { register, errors, setValue, previewData, isSubmitting } = props;
  const size = React.useContext(ResponsiveContext);
  const formFieldStyle = {
    flexDirection: isMobile(size) ? 'column' : 'row',
    alignItems: isMobile(size) ? 'stretch' : 'center',
    justifyContent: isMobile(size) ? 'flex-start' : 'space-between'
  };

  // const onSubmit = data => {
  //   console.log(data);
  // };
  React.useEffect(() => {
    register('message', { required: false });
  }, [register]);

  return (
    <Box>
      <Box style={{ maxWidth: 452 }}>
        <Box margin={{ bottom: 'small' }}>
          <Box margin={{ bottom: 'small' }}>
            <FormField
              label="Subject"
              name="name"
              disabled
              htmlfor="name"
              margin={{ bottom: 'none' }}
              style={formFieldStyle}>
              <TextInput
                value={previewData?.name}
                id="name"
                name="name"
                disabled
                placeholder="name"
                style={{ width: isMobile(size) ? '100%' : 340 }}
              />
            </FormField>
          </Box>
        </Box>
        <Box margin={{ bottom: 'small' }}>
          <FormField
            label="Message"
            name="message"
            disabled
            htmlfor="message"
            margin={{ bottom: 'none' }}
            style={formFieldStyle}>
            <Box style={{ width: isMobile(size) ? '100%' : 340 }}>
              <CKEditor
                editor={ClassicEditor}
                disabled
                data={previewData?.message}
                onInit={editor => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                  console.log({ event, editor });
                }}
                onBlur={(event, editor) => {
                  console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                  console.log('Focus.', editor);
                }}
              />
            </Box>
          </FormField>
        </Box>
      </Box>
      <Box margin={{ top: 'large' }} direction="row" gap="medium" justify="start">
        <FormField name="email" htmlfor="email" margin={{ bottom: 'none' }} style={formFieldStyle}>
          <TextInput
            ref={register({ required: 'Email is required' })}
            id="email"
            type="email"
            name="email"
            placeholder="Email"
            style={{ width: isMobile(size) ? '100%' : 275 }}
          />
        </FormField>
        {errors.email && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.email.message}
          </Text>
        )}
        <Button
          type="submit"
          primary
          size="small"
          label={!isSubmitting && 'Send Text Email'}
          icon={isSubmitting ? <ButtonLoader isSubmitting={isSubmitting} /> : null}
        />
      </Box>
    </Box>
  );
};

export default EmailPreview;
