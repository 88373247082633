import React, { useEffect, useState } from 'react';
import { Select, Divider, Input, Typography, Space, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from 'react-query';
import { createFeature } from 'api/mutations/Notification';
import { showToast } from 'store/actions/toast';
import { useDispatch } from 'react-redux';

const { Option } = Select;

const FeatureTagInputFormItem = ({ data, refetch }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onNameChange = event => {
    setName(event.target.value);
  };

  const addItem = async e => {
    e.preventDefault();
    let newVal = name.trim();
    if (newVal === '') return;
    try {
      setIsSubmitting(true);

      await createFeatureMutate(newVal);
    } catch (err) {
      console.log('err', err);
    }
    setName('');
  };

  const [createFeatureMutate] = useMutation(createFeature, {
    onSuccess: data => {
      if (data.success) {
        refetch();
      } else {
        dispatch(showToast('error', data.description));
      }
    },
    onError: error => {
      if (error.message === 'Network Error') {
        dispatch(
          showToast(
            'error',
            <>
              We couldn't connect to the server.
              <br />
              Check your network or contact your admin
            </>
          )
        );
      } else {
        dispatch(showToast('error', error.message));
      }
    },
    onSettled: () => {
      setIsSubmitting(false);
    }
  });

  return (
    <Form.Item
      name="inAppFeaturesId"
      label="Select Affected Features"
      rules={[
        {
          required: true,
          message: 'Please select your affected feature!',
          type: 'array'
        }
      ]}>
      <Select
        mode="multiple"
        loading={isSubmitting}
        style={{
          width: '100%'
        }}
        placeholder="select features"
        dropdownRender={menu => (
          <>
            <Divider
              style={{
                margin: '8px 0'
              }}
            />
            {menu}
            <Space
              align="center"
              style={{
                padding: '0 8px 4px'
              }}>
              <Input placeholder="Please enter item" value={name} onChange={onNameChange} />
              <Typography.Link
                disabled={name === ''}
                onClick={addItem}
                style={{
                  whiteSpace: 'nowrap'
                }}>
                <PlusOutlined /> Add item
              </Typography.Link>
            </Space>
          </>
        )}>
        {data?.map(item => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default FeatureTagInputFormItem;
