import React, { useState } from 'react';
import { Box, Button, Form, FormField, ResponsiveContext, Select, Text, TextInput } from 'grommet';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { animate, isMobile } from 'utils';
import ButtonLoader from 'components/common/ButtonLoader';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from 'store/actions/toast';
import { getAllRoles } from 'api/queries/Authentication';
import { createUser, updateUserInfo } from 'api/mutations/Authentication';
import { emailRegex, newPhoneRegex } from 'data/regex';
import InputValidationLoader from 'components/common/InputValidationLoader';
import AppData from 'AppData';
import InputController from 'components/common/InputController';

const CreateUserForm = props => {
  const [createError, setCreateError] = useState();
  const dispatch = useDispatch();
  const { institutionId, institutionCode, role } = useSelector(state => state.auth);
  const { data } = useQuery(
    ['getAllRoles', role.includes(AppData.ROLE.SUPERADMIN) ? null : 1],
    getAllRoles
  );
  const { register, errors, reset, control, setValue, handleSubmit } = useForm();
  const size = React.useContext(ResponsiveContext);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [createMessageAnimate] = React.useState([
    animate({ type: 'fadeIn', delay: 500, duration: 500 }),
    animate({ type: 'slideUp', delay: 500, duration: 500 })
  ]);
  const formFieldStyle = {
    flexDirection: isMobile(size) ? 'column' : 'row',
    alignItems: isMobile(size) ? 'stretch' : 'center',
    justifyContent: isMobile(size) ? 'flex-start' : 'space-between'
  };
  const [createUserMutate] = useMutation(props.updatedata ? updateUserInfo : createUser, {
    onSuccess: data => {
      if (data.success) {
        dispatch(
          showToast(
            'success',
            props.updatedata ? 'User updated successfully' : 'User created successfully'
          )
        );
        props.refetchUserUpdate();
        props.onClose();
      } else {
        setCreateError(data.description);
        dispatch(showToast('error', data.description));
      }
    },
    onError: error => {
      if (error.message === 'Network Error') {
        dispatch(
          showToast(
            'error',
            <>
              We couldn't connect to the server.
              <br />
              Check your network or contact your admin
            </>
          )
        );
      } else {
        dispatch(showToast('error', error.message));
        setCreateError(error.message);
      }
    },
    onSettled: () => {
      setIsSubmitting(false);
    }
  });

  React.useEffect(() => {
    if (props.updatedata) {
      let initialValue = { ...props.updatedata };
      initialValue.role = props.updatedata.roleName;
      initialValue.phone = props.updatedata.phoneNumber;
      reset(initialValue);
      setValue('role', initialValue.roleId);
    }
  }, [props.updatedata, reset]);

  React.useEffect(() => {
    register('role', { required: 'Role is required' });
  }, [register]);

  const onSubmit = async ({ firstName, lastName, userName, role, email, phone: phoneNumber }) => {
    if (isSubmitting) return;

    try {
      setCreateError(null);
      setIsSubmitting(true);
      const data = {
        institutionId,
        firstName,
        institutionCode,
        lastName,
        userName,
        roles: role,
        email,
        phoneNumber
      };
      if (props.updatedata) {
        await createUserMutate({
          UserId: props.updatedata.id,
          firstName,
          userName,
          lastName,
          phoneNumber,
          roleId: role
        });
      } else {
        await createUserMutate(data);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: 450 }}>
      {createError && (
        <Box margin={{ bottom: 'medium' }} animation={createMessageAnimate[0]}>
          <Box animation={createMessageAnimate[1]}>
            <Text color="status-critical">{createError}</Text>
          </Box>
        </Box>
      )}
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="First Name"
          name="firstName"
          htmlfor="firstName"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <TextInput
            ref={register({ required: 'Firstname is required' })}
            id="firstName"
            name="firstName"
            placeholder="First Name"
            style={{ width: isMobile(size) ? '100%' : 248 }}
          />
        </FormField>
        {errors.firstName && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.firstName.message}
          </Text>
        )}
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Last Name"
          name="lastName"
          htmlfor="lastName"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <TextInput
            ref={register({ required: 'Lastname is required' })}
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            style={{ width: isMobile(size) ? '100%' : 248 }}
          />
        </FormField>
        {errors.lastName && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.lastName.message}
          </Text>
        )}
      </Box>
      <InputController
        Controller={Controller}
        controllerName="userName"
        errors={errors}
        disabled={props.updatedata}
        errorMessage="Username is required"
        control={control}
        defaultValue={props.updatedata?.userName || ''}
        required
        label="Username"
        name="userName"
        htmlfor="userName"
        placeholder="Username"
        width={'248px'}
      />

      {!props.updatedata && (
        <Box margin={{ bottom: 'small' }}>
          <FormField
            label="Email"
            name="email"
            htmlfor="email"
            margin={{ bottom: 'none' }}
            style={formFieldStyle}>
            <TextInput
              ref={register({
                required: 'Email is required',
                pattern: {
                  value: emailRegex,
                  message: 'Not a valid email address'
                }
              })}
              id="email"
              name="email"
              type="email"
              placeholder="Email"
              style={{ width: isMobile(size) ? '100%' : 248 }}
            />
          </FormField>
          {errors.email && (
            <Text
              color="status-critical"
              size="small"
              alignSelf={isMobile(size) ? 'start' : 'end'}
              margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
              {errors.email.message}
            </Text>
          )}
        </Box>
      )}

      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Phone"
          name="phone"
          htmlfor="phone"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <TextInput
            ref={register({
              required: false,
              pattern: {
                value: newPhoneRegex,
                message: 'Not a valid phone number'
              }
            })}
            id="phone"
            name="phone"
            placeholder="Phone"
            style={{ width: isMobile(size) ? '100%' : 248 }}
          />
        </FormField>
        {errors.phone && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.phone.message}
          </Text>
        )}
      </Box>

      <Box margin={{ bottom: 'small' }} style={{ position: 'relative' }}>
        {!data && <InputValidationLoader message={errors.role} />}
        <FormField
          label="Role"
          name="role"
          htmlfor="role"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          {data && (
            <Select
              id="role"
              name="role"
              defaultValue={props.updatedata ? props.updatedata.roleName : ''}
              placeholder="Role"
              style={{ width: isMobile(size) ? '100%' : 200 }}
              options={data.data.map(d => d.name.toUpperCase())}
              onChange={({ option }) =>
                setValue('role', data.data.find(d => d.name.toUpperCase() === option).id, {
                  shouldValidate: true
                })
              }
            />
          )}
        </FormField>
        {errors.role && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.role.message}
          </Text>
        )}
      </Box>
      <Box margin={{ bottom: 'medium' }}>
        <Button
          type="submit"
          primary
          margin={{ top: 'small' }}
          label={!isSubmitting && `${!props.updatedata ? 'Save' : 'Update'}`}
          icon={isSubmitting ? <ButtonLoader isSubmitting={isSubmitting} /> : null}
        />
      </Box>
    </Form>
  );
};

export default CreateUserForm;
