import React from 'react';
import { Box, Button, Heading, ResponsiveContext, Text } from 'grommet';
import PageHeader from 'components/common/PageHeader';
import CreateCourseForm from 'components/forms/CreateCourseForm';
import Steps from 'components/extras/VerticalSteps';
import { isMobile, isTablet } from 'utils';
import CourseAdditionalInfoForm from 'components/forms/CourseAdditionalInfoForm';
import CourseBuilder from 'components/utils/CourseBuilder/index';
import BulkUploader from 'components/utils/CourseBuilder/BulkUploader';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CoursePricingForm from 'components/forms/CoursePricingForm';
import SelectSurveyForm from 'components/forms/SelectSurveyForm';
import ApprovalModal from 'components/modals/ApprovalModal';
import { showToast } from 'store/actions/toast';
import { courseApproval, qaCourseApproval } from 'api/mutations/Workflow';
import PageLoader from 'components/common/PageLoader';
import AppData from 'AppData';
import usePermission from 'components/hooks/usePermission';
import { courseBuilderAction } from 'store/actions/course';
import { approvecourse } from 'adminRoutes';

const CourseSetup = ({ isReview, canShowBackButton }) => {
  const size = React.useContext(ResponsiveContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const { checkUserPermission } = usePermission();
  const [showBulkUploader, setShowBulkUploader] = React.useState(false);
  const [current, setCurrent] = React.useState(null);
  const [comment, setComment] = React.useState();
  const [revenueId, setRevenueId] = React.useState(0);
  const [uploads, setUploads] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [previewClicked, setPreviewClicked] = React.useState(false);
  const [showApprovalModal, setShowApprovalModal] = React.useState(false);
  const [showRejectionModal, setShowRejectionModal] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const course = useSelector(state => state.course);
  const { institutionId } = useSelector(state => state.auth);

  const handlePreview = async (BuilderData, App, previewRoute, courseBuilder) => {
    try {
      setIsSubmitting(true);
      const payload = {
        ...BuilderData,
        courseId: course.courseId,
        stepNo: App.courseBuilder
        // quiz: { courseAssetId: 0, name: 'tester' }
      };
      const data = await courseBuilder({ payload, saveDraft: true, publish: false });

      if (data.success) {
        dispatch(courseBuilderAction({ ...payload }));
        history.push(previewRoute, BuilderData);
        // const win = window.open(previewRoute, '_blank');
        // win.focus();
      } else {
        console.log('result', data);
        dispatch(showToast('error', data.description));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const steps = [
    {
      title: 'Basic Setup',
      description: 'Start by creating your course details',
      action: canShowBackButton && (
        <Button secondary label="Back" onClick={() => history.push(approvecourse)} />
      )
    },
    {
      title: 'Additional Information',
      description: (
        <Text size="small">
          Provide additional course details before the course can be published
          <br />
          This includes headline, image and a short summary of your background 50 words minimum
        </Text>
      )
    },
    {
      title: 'Build Curriculum',
      description: (
        <Box
          width={isTablet(size) || isMobile(size) ? '100%' : '350px'}
          margin={{ bottom: 'small' }}>
          <Text size="small">
            Start creating your course content by creating sections, adding lecture components and
            practice modules (quizzes, exercises and assignments).
          </Text>
        </Box>
      ),
      action: (
        <Box direction="row" gap="medium">
          {!isReview && (
            <Button
              color="brand"
              secondary
              label="Bulk Upload"
              onClick={() => setShowBulkUploader(true)}
            />
          )}
          <Button
            color="brand"
            primary
            label="Preview Course"
            onClick={() => setPreviewClicked(true)}
          />
        </Box>
      )
    },
    course.hasSurvey && {
      title: 'End of course Survey',
      description: (
        <Box
          width={isTablet(size) || isMobile(size) ? '100%' : '350px'}
          margin={{ bottom: 'small' }}>
          <Text size="small">Select the end of course survey</Text>
        </Box>
      )
    },
    course.isPaid && {
      title: 'Pricing',
      description: 'Set the pricing for the course before it can be published.'
    }
  ];

  const courseApprovalHandler = async status => {
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      const data = checkUserPermission([AppData.permissions.manageCourseQA])
        ? await qaCourseApproval({
            courseId: course.courseId,
            approvalStatus: status,
            institutiondId: course.institutionId,
            revenueId,
            comment
          })
        : await courseApproval({
            courseId: course.courseId,
            comment,
            status,
            courseCategoryId: course.courseCategoryId,
            institutiondId: institutionId
          });
      if (data.success) {
        dispatch(
          showToast(
            'success',
            status === AppData.courseApproval ? 'Course Approved' : 'Course Rejected'
          )
        );
        if (status === AppData.courseRejection) setShowRejectionModal(false);
        history.push(approvecourse);
        return;
      }

      dispatch(showToast('error', data.description));
    } catch (error) {
      console.log('error', error);
      dispatch(showToast('error', 'Failed to submit course rejection'));
    } finally {
      setIsSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (isReview) {
      setCurrent(course.stepNo);
      return;
    }

    if (course.stepNo === 0 && !current) {
      setCurrent(1);
    } else if (course.stepNo !== 0 && !current) {
      setCurrent(course.stepNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, isReview]);

  return (
    <>
      {current && (
        <Box
          pad="medium"
          background="#fff"
          direction={isMobile(size) ? 'column' : 'row'}
          gap="large"
          border={isMobile(size) ? false : 'between'}
          wrap={true}>
          <Box gap="medium" margin={{ bottom: 'medium' }}>
            <Heading
              margin={{ bottom: 'xxsmall', top: 'none' }}
              level={4}
              size={isMobile(size) ? 'small' : 'medium'}
              color="#777">
              {isReview ? 'Review Course' : 'Create a Course'}
            </Heading>
            <Steps
              current={current}
              setCurrent={setCurrent}
              steps={steps.map(step => step?.title)}
              canGoBack
            />
          </Box>
          <Box flex="grow">
            <PageHeader
              pageTitle={steps[current - 1].title}
              pageDescription={steps[current - 1].description}
              pageActionElement={steps[current - 1].action}
              pageActionPosition="start"
            />
            {
              {
                1: (
                  <CreateCourseForm
                    isReview={isReview}
                    setCurrent={setCurrent}
                    defaultCourseValue={course.stepNo || isReview ? course : null}
                  />
                ),
                2: (
                  <CourseAdditionalInfoForm
                    isReview={isReview}
                    setCurrent={setCurrent}
                    defaultCourseValue={course.stepNo || isReview ? course : null}
                  />
                ),
                3: (
                  <CourseBuilder
                    isReview={isReview}
                    setCurrent={setCurrent}
                    handlePreview={handlePreview}
                    previewClicked={previewClicked}
                    setPreviewClicked={setPreviewClicked}
                    uploads={uploads}
                    setUploads={setUploads}
                    defaultCourseValue={course.stepNo || isReview ? course : null}
                    setShowRejectionModal={setShowRejectionModal}
                    courseApprovalHandler={courseApprovalHandler}
                    setIsSubmitting={setIsSubmitting}
                  />
                ),
                4: (
                  <SelectSurveyForm
                    isReview={isReview}
                    setCurrent={setCurrent}
                    defaultCourseValue={course.stepNo || isReview ? course : null}
                    setShowRejectionModal={setShowRejectionModal}
                    courseApprovalHandler={courseApprovalHandler}
                  />
                ),
                5: (
                  <CoursePricingForm
                    isReview={isReview}
                    defaultCourseValue={course.stepNo || isReview ? course : null}
                    setShowApprovalModal={setShowApprovalModal}
                    setShowRejectionModal={setShowRejectionModal}
                    courseApprovalHandler={courseApprovalHandler}
                  />
                )
              }[current]
            }
          </Box>
        </Box>
      )}
      {(showRejectionModal || showApprovalModal) && (
        <ApprovalModal
          comment={comment}
          revenueId={revenueId}
          setRevenueId={setRevenueId}
          setComment={setComment}
          setLoading={setLoading}
          showApprovalModal={showApprovalModal}
          showRejectionModal={showRejectionModal}
          setShowApprovalModal={setShowApprovalModal}
          setShowRejectionModal={setShowRejectionModal}
          onClickHandler={courseApprovalHandler}
        />
      )}
      {showBulkUploader && <BulkUploader onClose={() => setShowBulkUploader(false)} />}
      <PageLoader fullWidth={loading} fullHeight={loading} isLoading={isSubmitting || loading} />
    </>
  );
};

export default CourseSetup;
