import React, { useEffect, useState } from 'react';
import MainLayout from 'components/MainLayout';
import { Card, Col, Input, Row, Typography, Select, DatePicker } from 'antd';
import styled from 'styled-components';
import CustomButton from 'components/common/CustomButton';
import CoursePaymentTable from 'components/tables/CoursePaymentTable';
import PageLoader from 'components/common/PageLoader';
import { coursePaymentReport } from 'api/queries/Report';
import { useQuery } from 'react-query';
import { searchInstitutionById } from 'api/queries/Institution';
import AppData from 'AppData';
import { useDebounce } from 'components/hooks/useDebounce';

const CoursePayment = () => {
  const { Text } = Typography;
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [searchVal, setSearchVal] = useState('');
  const [value, setValue] = useState(0);
  const [debounce] = useDebounce(searchVal, 500);
  const [toggleExport, setToggleExport] = useState(false);
  const [transactionStatus] = useState([
    { name: 'pending', value: 0 },
    { name: 'Successful', value: 1 },
    { name: 'Failed', value: 2 }
  ]);
  const [tranStatus, setTranStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const { isLoading, data: result, refetch: refetchReport } = useQuery(
    [
      'coursePaymentReport',
      debounce,
      value,
      toggleExport ? 1 : currentPage,
      toggleExport ? AppData.infinitePage : pageSize,
      startDate,
      endDate,
      tranStatus,
      toggleExport
    ],
    coursePaymentReport
  );

  useEffect(() => {
    if (result && toggleExport && !isLoading) {
      base64Download(result?.file);
      setToggleExport(false);
    }
  }, [result, isLoading, toggleExport]);

  const { data: institutionResult } = useQuery(['searchInstitution'], searchInstitutionById);

  const base64Download = (base64Url, fileName = 'CoursePayment.xlsx') => {
    const a = document.createElement('a');
    console.log('inside', base64Url);
    document.body.appendChild(a);
    a.href = `data:application/octet-stream;base64,${base64Url}`;
    a.download = fileName;
    a.click();
  };

  const onSearch = e => setSearchVal(e.target.value);
  const handleSelect = option => {
    setValue(option);
  };
  const handleTranSelect = option => {
    setTranStatus(option);
  };

  const onDateChange = (date, dateString) => {
    // setValue(dateString);
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
    // console.log(date, dateString);
  };

  return (
    <MainLayout isAdmin sidebar padded selectedKey="coursePurchase">
      <Card>
        <HeaderRow gutter={[16, 16]}>
          <Col sm={{ span: 16 }} md={{ span: 7 }} xs={{ span: 24 }}>
            <Title level={3}>Course Payment Reports</Title>
            <Text>See details about who purchases your course</Text>
          </Col>
          <ExportCol sm={{ span: 16 }} md={{ span: 4 }} xs={{ span: 24 }}></ExportCol>
        </HeaderRow>
        <HeaderRow gutter={[16, 16]}>
          <Col sm={{ span: 16 }} md={{ span: 6 }} xs={{ span: 24 }}>
            <Search placeholder="Search Course, Student" onChange={onSearch} size="large" />
          </Col>
          <Col sm={{ span: 16 }} md={{ span: 6 }} xs={{ span: 24 }}>
            <SelectInput placeholder="All Partner" size="large" onChange={handleSelect}>
              {institutionResult &&
                institutionResult.map(data => {
                  return (
                    <Option key={data.id} value={data.id}>
                      {data.name}
                    </Option>
                  );
                })}
            </SelectInput>
          </Col>
          <Col sm={{ span: 16 }} md={{ span: 6 }} xs={{ span: 24 }}>
            <DateWrap>
              <RangePicker size="large" onChange={onDateChange} />
            </DateWrap>
          </Col>
          <Col sm={{ span: 16 }} md={{ span: 6 }} xs={{ span: 24 }}>
            <SelectInput placeholder="Transaction Status" size="large" onChange={handleTranSelect}>
              {transactionStatus?.map(data => {
                return (
                  <Option key={data.value} value={data.value}>
                    {data.name}
                  </Option>
                );
              })}
            </SelectInput>
          </Col>
        </HeaderRow>
        <HeaderRow gutter={[16, 16]}>
          <Col sm={{ span: 16 }} md={{ span: 7 }} xs={{ span: 24 }}></Col>
          <CenterCol sm={{ span: 16 }} md={{ span: 13 }} xs={{ span: 24 }}></CenterCol>
          <ExportCol sm={{ span: 16 }} md={{ span: 4 }} xs={{ span: 24 }}>
            <CustomButton
              onClick={() => {
                setToggleExport(true);
              }}
              bgColor="#4d4d53"
              color="#fff"
              size="large">
              Export
            </CustomButton>
          </ExportCol>
        </HeaderRow>
        {result && (
          <CoursePaymentTable
            result={result}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            toggleExport={toggleExport}
          />
        )}
      </Card>
      <PageLoader fullWidth={isLoading} fullHeight={isLoading} isLoading={isLoading} />
    </MainLayout>
  );
};

const Title = styled(Typography.Title)`
  margin-bottom: 0px !important;
`;

const CenterCol = styled(Col)`
  display: flex;
  justify-content: center;
  margin: auto;
`;

const ExportCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const Search = styled(Input.Search)`
  width: 100%;
  margin-right: 10px;
`;
const DateWrap = styled.div`
  width: 100%;
  margin-right: 10px;
  display: inline;
`;

const SelectInput = styled(Select)`
  width: 100%;
`;

const HeaderRow = styled(Row)`
  margin-bottom: 20px;
`;

export default CoursePayment;
