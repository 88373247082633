import React from 'react';
import { Box, Calendar, Drop, Keyboard, MaskedInput } from 'grommet';
import IconWrapper from 'components/common/IconWrapper';
import CalendarIcon from 'assets/svgs/ic_calendar.svg';

// Yes, these are for the odd but conventional U.S. way of representing dates.
// const MONTHS = ['[2-9]', '0[1-9]', '1[0-2]'];
// const DAYS = ['[4-9]', '0[1-9]', '[1-2][0-9]', '3[0-1]'];
// const YEAR = ['(\\d{4})'];
// const YEAR_REGEXP = new RegExp(YEAR.map(y => `^${y}$`).join('|'));
// const YEAR_MONTH_REGEXP = new RegExp(
//   MONTHS.map(m => YEAR.map(y => `^${y}-${m}$`).join('|')).join('|')
// );
// const YEAR_MONTH_DAY_REGEXP = new RegExp(
//   DAYS.map(d => MONTHS.map(m => YEAR.map(y => `^${y}-${m}-${d}$`).join('|')).join('|')).join('|')
// );
// const YEAR_MONTH_DAY_REGEXP = new RegExp('^(\\d{4})-(\\d{1,2})-(\\d{1,2})$');

const _DateInput = props => {
  const [text, setText] = React.useState('');
  const [date, setDate] = React.useState(props.value);
  // const [dates, setDates] = React.useState(props.value);
  const [active, setActive] = React.useState(false);
  const [textRef, setTextRef] = React.useState(null);
  const [focusInput, setFocusInput] = React.useState(false);

  const setDateCallback = (isoDate, props, setText, setDate, setActive) => {
    if (Array.isArray(isoDate[0])) {
      isoDate[0].forEach((d, i) => {
        const dateValue = new Date(d);
        const value = `${dateValue.getFullYear()}-${
          dateValue.getMonth() + 1 < 10 ? `0${dateValue.getMonth() + 1}` : dateValue.getMonth() + 1
        }-${dateValue.getDate() < 10 ? `0${dateValue.getDate()}` : dateValue.getDate()}`;
        setText(state => {
          let newState = i === 0 ? '' : state;
          return newState === '' ? newState.concat(value) : newState.concat(`, ${value}`);
        });
        setDate((state = []) => {
          let newState = i === 0 ? [] : state;
          return newState.concat(dateValue);
        });
        // setDates((state = []) => state.concat(dateValue.toISOString()));
      });
      setActive(false);
    } else {
      const dateValue = new Date(isoDate);
      const value = `${dateValue.getFullYear()}-${
        dateValue.getMonth() + 1 < 10 ? `0${dateValue.getMonth() + 1}` : dateValue.getMonth() + 1
      }-${dateValue.getDate() < 10 ? `0${dateValue.getDate()}` : dateValue.getDate()}`;
      if (props.onChange) {
        props.onChange(value);
      }
      setText(value);
      setDate(dateValue);
      setActive(false);
    }
  };

  React.useEffect(() => {
    if (props.value) {
      setDateCallback(props.value, props, setText, setDate, setActive);
    }
  }, [props]);

  React.useEffect(() => {
    if (props.range && props.onChange) props.onChange(text);
  }, [props, text]);

  const onFocus = () => {
    setFocusInput(true);
    setActive(true);
  };

  const onBlur = () => {
    setFocusInput(false);
  };

  // const onInput = event => {
  //   let {
  //     target: { value }
  //   } = event;
  //   let dateValue;
  //   const match = value.match(YEAR_MONTH_DAY_REGEXP);
  //   if (match) {
  //     dateValue = new Date(match[3], parseInt(match[1], 10) - 1, match[2]);
  //   } else if (value.length > text.length) {
  //     // never add characters if the user is backspacing
  //     // add trailing '/' when it looks appropriate
  //     if (value.match(YEAR_REGEXP)) {
  //       value = `${value}-`;
  //     } else if (value.match(YEAR_MONTH_REGEXP)) {
  //       value = `${value}-`;
  //     }
  //   }
  //   if (props.onChange) {
  //     props.onChange(value);
  //   }
  //   setText(value);
  //   setDate(dateValue);
  //   setActive(true);
  // };

  const onSelect = isoDate => {
    if (props.range && !Array.isArray(isoDate)) return;
    setDateCallback(isoDate, props, setText, setDate, setActive);
  };

  return (
    <Box width={props.width}>
      <Keyboard onDown={() => setActive(props.disabled ? false : true)}>
        <MaskedInput
          ref={ref => setTextRef(ref)}
          icon={<IconWrapper icon={CalendarIcon} height="16px" width="16px" />}
          reverse
          disabled={props.disabled}
          placeholder={props.placeholder}
          mask={
            props.range
              ? [
                  {
                    length: 4,
                    regexp: /^[0-9]{1,4}$/
                    // placeholder: 'yyyy'
                  },
                  { fixed: '-' },
                  {
                    length: [1, 2],
                    regexp: /^(0?[1-9]|1[012])$/
                    // placeholder: 'mm'
                  },
                  { fixed: '-' },
                  {
                    length: [1, 2],
                    regexp: /^(0?[1-9]|1[0-9]|2[0-9]|3[01])$/
                    // placeholder: 'dd'
                  },
                  { fixed: ' ' },
                  {
                    length: 4,
                    regexp: /^[0-9]{1,4}$/
                    // placeholder: 'yyyy'
                  },
                  { fixed: '-' },
                  {
                    length: [1, 2],
                    regexp: /^(0?[1-9]|1[012])$/
                    // placeholder: 'mm'
                  },
                  { fixed: '-' },
                  {
                    length: [1, 2],
                    regexp: /^(0?[1-9]|1[0-9]|2[0-9]|3[01])$/
                    // placeholder: 'dd'
                  }
                ]
              : [
                  {
                    length: 4,
                    regexp: /^[0-9]{1,4}$/
                    // placeholder: 'yyyy'
                  },
                  { fixed: '-' },
                  {
                    length: [1, 2],
                    regexp: /^(0?[1-9]|1[012])$/
                    // placeholder: 'mm'
                  },
                  { fixed: '-' },
                  {
                    length: [1, 2],
                    regexp: /^(0?[1-9]|1[0-9]|2[0-9]|3[01])$/
                    // placeholder: 'dd'
                  }
                ]
          }
          value={text}
          // onInput={onInput}
          onFocus={onFocus}
          onClick={onFocus}
          onBlur={onBlur}
        />
      </Keyboard>
      {active ? (
        <Drop
          target={textRef}
          align={{ top: 'bottom', left: 'left' }}
          onClickOutside={() => {
            // if (!focusInput) setActive(false);
            setActive(false);
          }}>
          <Box pad="small" align="center">
            <Calendar
              animate={false}
              size="small"
              date={!Array.isArray(date) ? date?.toISOString() : ''}
              // dates={dates ? dates : null}
              range={props.range}
              onSelect={onSelect}
            />
          </Box>
        </Drop>
      ) : null}
    </Box>
  );
};

export default _DateInput;
