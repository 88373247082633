import React, { useEffect, useState } from 'react';
import {
  Select,
  Typography,
  Input,
  DatePicker,
  Form,
  Checkbox,
  Button,
  Popconfirm,
  message
} from 'antd';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import styled from 'styled-components';
import { showToast } from 'store/actions/toast';
import { useMutation, useQuery } from 'react-query';
import { listCategory, listFeature } from 'api/queries/Notification';
import { createNotification, updateNotification } from '../../api/mutations/Notification';
import { useDispatch } from 'react-redux';
import CategorySelectFormItem from 'components/notificationUtils/CategorySelectFormItem';
import FeatureTagInputFormItem from 'components/notificationUtils/FeatureTagInputFormItem';
import moment from 'moment';

const AddNotificationForm = ({ onCancel, isCreate, update, refetchNotifications }) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState();
  const { RangePicker } = DatePicker;
  const [isMaintenanceNotice, setIsMaintenanceNotice] = useState(false);
  const [isSeveritySelected, setIsSeveritySelected] = useState(false);
  const [isModalSelected, setIsModalSelected] = useState(false);
  const [activeNotification, setActiveNotification] = useState(false);
  const { Option } = Select;
  const { data, refetch } = useQuery(['getAllCategory', true], listCategory);
  const { data: feature, refetch: refetchFeature } = useQuery(['getAllFeature', true], listFeature);

  //category change handler
  const setSelectedCategory = selectedCategory => {
    if (selectedCategory === 1) setIsMaintenanceNotice(true);
    else {
      setIsMaintenanceNotice(false);
      setIsSeveritySelected(false);
    }
  };

  // severity change handler
  const setSelectedSeverity = () => {
    setIsSeveritySelected(true);
  };

  //modal change handler
  const onModalChange = () => {
    setIsModalSelected(state => !state);
  };

  // create notification api
  const [createNotificationMutate] = useMutation(update ? updateNotification : createNotification, {
    onSuccess: data => {
      if (data.success) {
        dispatch(
          showToast('success', `Notification ${update ? 'updated' : 'created'} successfully`)
        );
        refetchNotifications();
        setIsSubmitting(false);
        onCancel();
      } else {
        setIsSubmitting(false);
        dispatch(showToast('error', data.description));
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        dispatch(
          showToast(
            'error',
            <>
              We couldn't connect to the server.
              <br />
              Check your network or contact your admin
            </>
          )
        );
      } else {
        dispatch(showToast('error', error.message));
      }
    }
  });

  //handle form submit
  const onFinish = async fieldsValue => {
    const rangeTimeValue = fieldsValue['range-time-picker'];

    const payload = {
      title: fieldsValue?.title,
      startDate: moment(rangeTimeValue[0]).format(),
      endDate: moment(rangeTimeValue[1]).format(),
      inAppNotificationCategoryId: fieldsValue?.inAppNotificationCategoryId,
      severity: fieldsValue?.severity === undefined ? 1 : fieldsValue?.severity,
      inAppFeaturesId:
        fieldsValue?.inAppFeaturesId === undefined ? [] : fieldsValue?.inAppFeaturesId,
      stripMessage: fieldsValue?.stripMessage,
      modalMessage: fieldsValue?.modalMessage,
      showModal: fieldsValue?.showModal,
      showStrip: true,
      isActive: activeNotification
    };

    if (update) {
      payload.id = update.id;
      payload.inAppNotificationCategoryName = '';
      payload.inAppFeaturesName = [''];
    }

    try {
      setIsSubmitting(true);

      await createNotificationMutate(payload);
    } catch (err) {
      console.log('err', err);
    }
  };

  //log form errors
  const onFinishFailed = () => {
    console.log('falied');
  };

  // daterange config
  const rangeConfig = {
    rules: [
      {
        type: 'array',
        required: true,
        message: 'Please select time!'
      }
    ]
  };

  //update notification side effect
  useEffect(() => {
    if (update) {
      if (update?.inAppNotificationCategoryId === 1) setIsMaintenanceNotice(true);
      if (update?.inAppFeaturesId !== null) setIsSeveritySelected(true);
      if (update?.showModal) setIsModalSelected(true);
    }
  }, [update]);

  const confirm = () => {
    setActiveNotification(state => !state);
    message.success(activeNotification ? 'Set Active Cancelled' : 'Click on Continue');
  };

  const cancel = () => {
    if (activeNotification) {
      return;
    }
    setActiveNotification(false);
    message.error('Click on Cancel');
  };

  return (
    <Form
      initialValues={update}
      name="basic"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      <CategorySelectFormItem data={data} onChange={setSelectedCategory} refetch={refetch} />
      <Form.Item
        label="Notification Title"
        name="title"
        rules={[{ required: true, message: 'Please input your title!' }]}>
        <Input style={{ width: '100%' }} />
      </Form.Item>

      {isMaintenanceNotice && (
        <Form.Item
          label="Severity"
          name="severity"
          rules={[{ required: false, message: 'Please select a value!' }]}>
          <Select style={{ width: '100%' }} onChange={setSelectedSeverity}>
            <Option value={1}>Low</Option>
            <Option value={2}>Medium</Option>
            <Option value={3}>High</Option>
          </Select>
        </Form.Item>
      )}

      {isMaintenanceNotice && isSeveritySelected && (
        <FeatureTagInputFormItem data={feature} refetch={refetchFeature} />
      )}
      <LabelHeading>Duration</LabelHeading>
      <div style={{ marginTop: 10 }}>
        <Form.Item label="Date & Time" name="range-time-picker" {...rangeConfig}>
          <RangePicker showTime style={{ width: '100%' }} />
        </Form.Item>
      </div>
      <LabelHeading>Message</LabelHeading>

      <div style={{ marginTop: 10 }}>
        <Label>Strip</Label>
        <Form.Item
          className="margin-bottom-0"
          name="stripMessage"
          valuePropName="data"
          getValueFromEvent={(_event, editor) => {
            const data = editor.getData();
            return data;
          }}
          rules={[{ required: true, message: 'Please enter the message' }]}>
          <CKEditor editor={ClassicEditor} />
        </Form.Item>
      </div>

      {isModalSelected && (
        <>
          {' '}
          <Label style={{ marginTop: 20 }}>Modal</Label>
          <Form.Item
            className="margin-bottom-0"
            name="modalMessage"
            valuePropName="data"
            getValueFromEvent={(_event, editor) => {
              const data = editor.getData();
              return data;
            }}
            rules={[{ required: true, message: 'Please enter the modal value' }]}>
            <CKEditor editor={ClassicEditor} />
          </Form.Item>
        </>
      )}
      <SpaceBetween style={{ marginBottom: 60 }}>
        <Form.Item
          initialValue={isCreate ? false : undefined}
          valuePropName="checked"
          name="showModal">
          <Checkbox onChange={onModalChange} style={{ marginTop: 2 }}>
            Show Modal
          </Checkbox>
        </Form.Item>

        <Popconfirm
          title={
            activeNotification
              ? 'Activate Notification Cancelled ?'
              : 'Current Active Notification will be disabled ?. If your start date is today and the time is now!!!. '
          }
          onConfirm={confirm}
          onCancel={cancel}
          okText={activeNotification ? '' : 'Continue'}
          cancelText="Cancel">
          <CheckWrapper>
            <Check bgColor={activeNotification} style={{ marginTop: 2 }}>
              {' '}
              <InnerCheck display={activeNotification} />{' '}
            </Check>
            Activate Notification
          </CheckWrapper>
        </Popconfirm>
      </SpaceBetween>
      <Form.Item>
        <SpaceBetween>
          <Button
            style={{ width: 90, backgroundColor: '#FFFBFB', borderRadius: 4 }}
            size="large"
            onClick={onCancel}>
            Cancel
          </Button>
          <Button
            loading={isSubmitting}
            htmlType="submit"
            style={{ width: 90, backgroundColor: '#0966BB', borderRadius: 4 }}
            type="primary"
            size="large">
            {update ? 'Update' : 'Save'}
          </Button>
        </SpaceBetween>
      </Form.Item>
    </Form>
  );
};

export default AddNotificationForm;

const Label = styled(Typography.Text)`
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 5px;
`;

const LabelHeading = styled(Typography.Text)`
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Check = styled.span`
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 5px;
  border: 1px solid;
  border-color: ${props => (props.bgColor ? ' #1890ff' : '#767676')};
  background: ${props => (props.bgColor ? ' #1890ff' : '#fff')};
  &:hover {
    border-color: #1890ff;
  }
  border-radius: 3px;
`;

const CheckWrapper = styled.label`
  margin-top: 5px;
  display: inline-flex;
  align-items: flex-start;
`;
const InnerCheck = styled.span`
  background: #fff;
  width: 6px;
  height: 6px;
  display: ${props => (props.display ? 'block' : 'none')};
  margin: 4px auto;
`;
