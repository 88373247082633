import { Box, Text } from 'grommet';
import React from 'react';
import { timeFormat } from 'utils';
import 'styles/utils.css';
import AppData from 'AppData';
import { useDispatch, useSelector } from 'react-redux';
import { setCBT, clearCBT } from 'store/actions/cbt';
import VideoIcon from 'assets/svgs/ic_videocam.svg';
import ImageIcon from 'assets/svgs/ic_image.svg';
import FileIcon from 'assets/svgs/ic_file.svg';
import QuizIcon from 'assets/svgs/ic_question_answer.svg';
import IconWrapper from 'components/common/IconWrapper';
import { Checkbox, CheckboxSelected } from 'grommet-icons';
import { Dropdown, Button, Menu } from 'antd';

const PlayListCard = ({
  setCurrentSection,
  indexNumber,
  currentPlayingModule,
  currentPlayingSection,
  playList,
  mylearning,
  setVideoSrc,
  setResource,
  previewMode
}) => {
  const dispatch = useDispatch();
  let playListVideoItem = playList.courseModuleItemAssets.find(
    item => AppData.assetType[item.assetType] === 'video'
  );
  let playListCompressVideoItem = playList.courseModuleItemAssets.find(
    item => AppData.assetType[item.assetType] === 'compressVideo'
  );
  let videoIndex = playList.courseModuleItemAssets.findIndex(
    item => AppData.assetType[item.assetType] === 'video'
  );
  const playListArticleItem = playList.courseModuleItemAssets.find(
    item => AppData.assetType[item.assetType] === 'article'
  );
  const playListImageItem = playList.courseModuleItemAssets.find(
    item => AppData.assetType[item.assetType] === 'image'
  );
  const playListQuizItem = playList.courseModuleItemAssets.find(
    item => AppData.assetType[item.assetType] === 'quiz'
  );
  // let [playlistItem] = playList.courseModuleItemAssets;
  const { moduleList } = useSelector(state => state.video);

  const menu = (
    <Menu>
      {playListArticleItem && (
        <Menu.Item>
          <a
            download={playList?.title}
            target="_blank"
            rel="noopener noreferrer"
            href={playListArticleItem?.url}
            style={{ color: '#555' }}>
            Document
          </a>
        </Menu.Item>
      )}
      {playListImageItem && (
        <Menu.Item>
          <a
            download={playList?.title}
            target="_blank"
            rel="noopener noreferrer"
            href={playListImageItem?.url}
            style={{ color: '#555' }}>
            Image
          </a>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Box
      background={currentPlayingModule && currentPlayingSection ? '#0966b8' : '#EDEDED'}
      style={{ color: moduleList?.currentSection > indexNumber ? '#fff' : null }}
      className="playcardwrapper"
      onClick={e => {
        e.stopPropagation();
        setCurrentSection(indexNumber);
        dispatch(clearCBT());
        if (!mylearning) setResource(null);

        if (playListQuizItem) {
          if (!mylearning) setVideoSrc(null);
          dispatch(setCBT(playListQuizItem));
          return;
        }

        if (playListVideoItem) {
          if (playListVideoItem.url.includes('http')) {
            if (!mylearning) setVideoSrc(playListVideoItem);
          } else {
            playListVideoItem = { ...playListVideoItem, url: 'http://' + playListVideoItem?.url };
            if (!mylearning) setVideoSrc(playListVideoItem);
          }
          return;
        }

        if (playListArticleItem && !mylearning) {
          setResource({ title: playList.title, url: playListArticleItem.url });
          return;
        }

        if (playListImageItem && !mylearning) {
          setResource({ title: playList.title, url: playListImageItem.url });
        }
      }}
      direction="row"
      gap="medium"
      align="center"
      pad={{
        top: 'small',
        bottom: 'small',
        left: 'xsmall',
        right: 'small'
      }}
      margin={{
        bottom: '1px'
      }}>
      {!mylearning && (
        <>
          {playListVideoItem?.isCompleted ||
          playListQuizItem?.isCompleted ||
          playListArticleItem?.isCompleted ||
          playListImageItem?.isCompleted ? (
            <CheckboxSelected
              size="18px"
              color={currentPlayingModule && currentPlayingSection ? '#fff' : 'blue'}
            />
          ) : (
            <Checkbox
              size="18px"
              color={currentPlayingModule && currentPlayingSection ? '#fff' : 'dark-3'}
            />
          )}
        </>
      )}

      <Box direction="column" gap="xsmall" justify="between" fill="horizontal">
        <Box direction="row" gap="xsmall" align="center">
          <Box>
            <Text
              color={currentPlayingModule && currentPlayingSection ? '#fff' : '#000'}
              size="small">
              {playList?.title}
            </Text>
          </Box>
        </Box>
        <Box direction="row" align="center" justify="between" fill="horizontal">
          <Box direction="row" align="center" gap="small">
            <Box direction="row" align="center" gap="small">
              <IconWrapper
                style={{
                  filter:
                    currentPlayingModule && currentPlayingSection
                      ? 'brightness(0) invert(1)'
                      : 'contrast(400%)'
                }}
                icon={
                  playListQuizItem
                    ? QuizIcon
                    : playListVideoItem || playListCompressVideoItem
                    ? VideoIcon
                    : playListImageItem
                    ? ImageIcon
                    : playListArticleItem
                    ? FileIcon
                    : null
                }
                width="20px"
                height="20px"
              />
              <Text
                color={currentPlayingModule && currentPlayingSection ? '#fff' : '#000'}
                size="xsmall">
                {playListQuizItem
                  ? 'Quiz'
                  : playListVideoItem
                  ? 'Video'
                  : playListCompressVideoItem
                  ? 'Interactive'
                  : playListImageItem
                  ? 'Image'
                  : playListArticleItem
                  ? 'File'
                  : null}
              </Text>
            </Box>
            {playListVideoItem && !previewMode && (
              <Text
                color={currentPlayingModule && currentPlayingSection ? '#fff' : '#000'}
                size="xsmall">
                <span style={{ marginRight: '5px' }}>-</span>{' '}
                {` ${timeFormat(playList?.courseModuleItemAssets[videoIndex]?.duration)} MIN`}
              </Text>
            )}
          </Box>
          {(playListArticleItem || playListImageItem) && playListVideoItem && !mylearning && (
            <Dropdown overlay={menu} placement="bottomRight">
              <Button style={{ fontWeight: 'bold' }}>Download</Button>
            </Dropdown>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PlayListCard;
