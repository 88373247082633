import React, { useState } from 'react';
import {
  Box,
  Form,
  //  ResponsiveContext,
  ThemeContext
} from 'grommet';
// import { isMobile } from 'utils';
// import ButtonLoader from 'components/common/ButtonLoader';
import PageHeader from 'components/common/PageHeader';
import { useMutation } from 'react-query';
// import { categoryList, fetchTokens } from 'api/queries/Notification';
import { useEffect } from 'react';
// import ConfigurationSettings from '../../../ConfigurationSettings';
import PageLoader from 'components/common/PageLoader';
import { useHistory } from 'react-router-dom';
import ConfigurationSettings from 'pages/admin/Settings';
import { useForm } from 'react-hook-form';
import { sendEmail } from 'api/mutations/Notification';
import Message from 'components/common/Message';
import NotificationModal from 'components/modals/NotificationModal';
import { emailtemplate } from 'adminRoutes';
// import { isMobile } from 'utils';
import EmailPreview from 'components/forms/EmailPreview';

const PreviewEmail = props => {
  const { register, errors, setValue, handleSubmit } = useForm();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const size = React.useContext(ResponsiveContext);
  const [successMessage, setSuccessMessage] = useState(null);
  const [createEmailError, setCreateEmailError] = useState(null);
  const [previewData, setPreviewData] = useState(null);

  const [sendEmailMutate] = useMutation(sendEmail, {
    onSuccess: data => {
      const { description, success } = data;
      if (success) {
        setSuccessMessage(description);
      } else {
        setCreateEmailError(description);
        setIsSubmitting(false);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setCreateEmailError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        setCreateEmailError(error.message);
      }
    }
  });

  useEffect(() => {
    if (history.location) setPreviewData(history.location.state);
  }, [history]);

  const onSubmit = async ({ email }) => {
    // console.log(data);
    const payload = {
      subject: previewData.name,
      recipients: [email],
      body: previewData.message
    };
    // console.log(payload);
    try {
      setCreateEmailError(null);
      setIsSubmitting(true);
      await sendEmailMutate(payload);
    } catch (err) {
      console.log('err', err);
    }
  };

  // console.log(previewData && previewData);

  return (
    <ConfigurationSettings selectedKey="Email Management">
      <Box margin={{ bottom: 'small' }}>
        <PageHeader
          canGoBack
          marginBottom="none"
          pageDescription="This preview uses sample content for placeholders to generate the message"
          pageTitle="Message Preview"
        />
        <Box title="">
          <ThemeContext.Extend value={{ global: { colors: { doc: '' } } }}>
            <Box
              pad="xxsmall"
              margin={{ bottom: 'medium' }}
              width="100%"
              elevation="small"
              background="doc"
            />
          </ThemeContext.Extend>
          {createEmailError && <Message message={createEmailError} status="error" />}
          {previewData && (
            <Form style={{ maxWidth: 752 }} onSubmit={handleSubmit(onSubmit)}>
              <EmailPreview
                register={register}
                isSubmitting={isSubmitting}
                errors={errors}
                previewData={previewData}
                setValue={setValue}
                handleSubmit={handleSubmit}
              />
            </Form>
          )}
        </Box>
      </Box>

      <PageLoader isLoading={false} />
      {successMessage && (
        <NotificationModal
          message="Email Sent Successfully"
          status="success"
          iconHeight="80px"
          iconWidth="80px"
          actionText="Complete"
          actionFunc={() => {
            setIsSubmitting(false);
            history.push(emailtemplate);
          }}
        />
      )}
    </ConfigurationSettings>
  );
};

export default PreviewEmail;
