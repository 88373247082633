import { Table } from 'antd';
// import Message from 'components/common/Message';
import React from 'react';
// import { limitText } from 'utils';

const PastNotificationTable = ({ inAppPastData }) => {
  const removeTag = value => value?.replace(/<[^>]+>/g, '');
  // const _render = record => {
  //   return (
  //     <div>
  //       <span>{removeTag(record.stripMessage)}</span>
  //       <Divider style={{ margin: '10px 0px 5px' }} />
  //       <span style={{ fontWeight: 'bold' }}> Modal Message</span>
  //       <Divider style={{ margin: '5px 0px' }} />
  //       <span>{removeTag(record.modalMessage)}</span>
  //     </div>
  //   );
  // };

  /** past table column */
  const pastColumns = [
    {
      title: 'S/N',
      dataIndex: 'sNo',
      key: 'sNo'
    },
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      render: text => <span style={{ color: '#5B97FF' }}>{text}</span>
    },
    {
      title: 'CATEGORY',
      dataIndex: 'inAppNotificationCategoryName',
      key: 'inAppNotificationCategoryName'
    },
    // {
    //   title: 'MESSAGE',
    //   dataIndex: 'stripMessage',
    //   key: 'stripMessage',
    //   render: (_text, record) => {
    //     if (record.modalMessage) {
    //       return (
    //         <Popover
    //           title={<span style={{ fontWeight: 'bold' }}> Strip Message</span>}
    //           content={_render(record)}>
    //           <span style={{ color: '#5B97FF' }}>
    //             {' '}
    //             {limitText(record?.stripMessage?.replace(/<[^>]+>/g, ''))}{' '}
    //           </span>
    //         </Popover>
    //       );
    //     } else {
    //       return (
    //         <Popover title="Message" content={_text?.replace(/<[^>]+>/g, '')}>
    //           <span style={{ color: '#5B97FF' }}>
    //             {' '}
    //             {limitText(_text?.replace(/<[^>]+>/g, ''))}{' '}
    //           </span>
    //         </Popover>
    //       );
    //     }
    //   }
    // },
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'MESSAGE',
      dataIndex: 'detail',
      key: 'detail'
    }
  ];

  return (
    <Table
      expandable={{
        expandedRowRender: record => (
          <p
            style={{
              margin: 0
            }}>
            <span>
              <span style={{ fontWeight: 'bold' }}> Strip Message</span> -{' '}
              {removeTag(record.stripMessage)}
            </span>{' '}
            <br />
            {record?.modalMessage && (
              <span>
                <span style={{ fontWeight: 'bold' }}> Modal Message</span> -{' '}
                {removeTag(record.modalMessage)}
              </span>
            )}
          </p>
        ),
        expandRowByClick: true,
        rowExpandable: record => record.name !== 'Not Expandable'
      }}
      columns={pastColumns}
      dataSource={inAppPastData}
      style={{ marginTop: 20 }}
    />
  );
};

export default PastNotificationTable;
