import React, { useState } from 'react';
import { Avatar, Col, Image, message, Progress, Tooltip, Typography } from 'antd';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { ButtonCTA } from './ButtonCTA';
import DataScience from 'assets/images/datascience.jpg';
import { useHistory } from 'react-router';
import { coursecompleted, delivery } from 'userRoutes';
import { useSelector, useDispatch } from 'react-redux';

import { getCourseBuilder } from 'api/queries/Course';
import { courseDetails } from 'store/actions/course';

const ActiveComCard = ({
  span,
  title,
  institution,
  description,
  institutionName,
  institutionId,
  completed,
  percentage,
  courseThumbNailUrl,
  institutionLogo,
  price,
  modules,
  duration,
  courseId,
  level,
  careerRelated,
  course,
  tabType
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId } = useSelector(state => state.auth);

  const handleResume = async () => {
    try {
      setIsLoading(true);
      const { data } = await getCourseBuilder(courseId);
      if (data) {
        setIsLoading(false);
        dispatch(courseDetails(data));
        history.push(delivery, { userId, courseId });
      }
    } catch (error) {
      setIsLoading(false);
      message.error(error.message);
    }
  };

  const getCertificate = async () => {
    try {
      setIsLoading(true);
      const { data } = await getCourseBuilder(courseId);
      if (data) {
        setIsLoading(false);
        dispatch(courseDetails(data));
        history.push(coursecompleted);
      }
    } catch (error) {
      setIsLoading(false);
      message.error(error.message);
    }
  };

  console.log(modules);

  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });
  return (
    <Col
      xs={{ span: 24 }}
      sm={{ span: 24 }}
      md={{ span: 12 }}
      lg={{ span: 8 }}
      xl={{ span: 6 }}
      xxl={{ span: 4 }}>
      <DivCard>
        <ImageContainer>
          <LogoWrapper>
            <Avatar size="large" src={institutionLogo} />
          </LogoWrapper>
          <ImageDiv src={courseThumbNailUrl || DataScience} />
        </ImageContainer>
        <CardBody>
          <BodyTitle>
            <DivFlexBetween>
              <Tooltip placement="topLeft" title={title}>
                <Heading weight={'600'} size={20} color="#000" level={3} margin={0}>
                  {title}
                </Heading>
              </Tooltip>
            </DivFlexBetween>
            <InstitutionWrapper>
              <Text color="#7e7e7e" size={12} weight={700}>
                Partner:{' '}
                <Text color="#4e896d" strong>
                  {' '}
                  {institutionName}
                </Text>
              </Text>
            </InstitutionWrapper>
          </BodyTitle>
          {/* <DescriptionWrapper>{description}</DescriptionWrapper> */}
          <DivProgress>{!completed && <Progress percent={percentage} size="small" />}</DivProgress>
        </CardBody>

        <CardFooter>
          <CardFooterDiv completed={completed}>
            {completed && (
              <CertificateButton loading={isLoading} onClick={getCertificate}>
                Get certificate
              </CertificateButton>
            )}
            <CustomButton loading={isLoading} onClick={handleResume}>
              {`${!completed ? 'Resume' : 'View'}`}
            </CustomButton>
          </CardFooterDiv>
        </CardFooter>
      </DivCard>
    </Col>
  );
};

const CertificateButton = styled(ButtonCTA)`
  border-radius: 20px;
  width: ${({ width }) => width};
  border: 2px solid #0966b8;
  color: #fff !important;
  background: #0966b8 !important;
  font-size: 15px;
  height: inherit !important;
  font-weight: 600;
  transition: all 0.2s ease-in;

  &:hover {
    transform: scale(1.1);
    background: #0966b8 !important;
    color: #fff !important;
    opacity: 1;
  }
`;
const CustomButton = styled(ButtonCTA)`
  border-radius: 20px;
  width: ${({ width }) => width};
  border: 2px solid #0966b8;
  color: #0966b8 !important;
  font-size: 15px;
  height: inherit !important;
  font-weight: 600;

  &:hover {
    box-shadow: 0px 0px 0px 2px #0966b8;
    border-color: #0966b8;
    opacity: 1;
  }
`;

const DivCard = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
  min-height: 355px;
  height: 355px;
  ${'' /* max-height: 25rem; */}
  ${'' /* min-height: 25rem;  */}
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 297px;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 20%);
  transition: 0.2s ease-in-out;
  &:hover {
    transform: scale(1.03);
  }
`;
const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 180px;
`;
const ImageDiv = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const CardBody = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 12px;
  padding-right: 12px;
`;

const BodyTitle = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  margin-top: 12px;
  margin-bottom: 0px;
  min-width: 0;
  height: 90px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
`;

const DivFlexBetween = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
`;

const Heading = styled.h3`
  margin: ${({ margin }) => margin + 'px'}!important;
  font-size: ${({ size }) => size + 'px'}!important;
  line-height: 22px !important;
  font-weight: ${({ weight }) => weight};
  color: ${({ color }) => color} !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;
const Text = styled(Typography.Text)`
  font-size: ${({ size }) => size + 'px'};
  ${'' /* line-height: 24px; */};
  color: ${({ color }) => color};
  font-weight: ${({ weight }) => weight};
`;
const InstitutionWrapper = styled.div``;

const DescriptionWrapper = styled(Typography.Paragraph)`
  line-height: 20px;
  font-size: 14px;
  text-align: justify;
  width: 100%;
  height: 60px;
  margin-bottom: 0.5em !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const CardFooter = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: none;
  min-width: 0;
  min-height: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
`;
const CardFooterDiv = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: justify;
  justify-content: ${props => (props.completed ? 'space-between' : 'flex-end')};
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
`;

const IconWrappers = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  margin-bottom: 0px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
`;

const Dot = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  background-color: #7e7e7e;
  color: #333333;
  min-width: 0;
  min-height: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 5px;
  width: 5px;
  border-radius: 100%;
`;
const Divider = styled.div`
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  width: 6px;
`;

const DivProgress = styled.div`
  width: 100%;
  margin-bottom: 0.5em;
  height: 22px;
`;

export default ActiveComCard;
