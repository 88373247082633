import React, { useEffect, useState, useRef } from 'react';
import 'styles/utils.css';
import DiscoveryImg from 'assets/images/discover.png';
import DiscountClose from 'assets/svgs/discount-close.svg';
import VoucherIcon from 'assets/svgs/voucher.svg';
import { Typography, message } from 'antd';
import AppData from 'AppData';
import MainLayout from 'components/MainLayout';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import useCurrencyFormater from 'components/hooks/useCurrencyFormater';
import { terms, coursetitle, delivery } from 'userRoutes';
import { usePaystackPayment } from 'react-paystack';
import { showToast } from 'store/actions/toast';
import { useHistory } from 'react-router-dom';
import { courseEnrollment, coursePayment, computePricing } from 'api/mutations/Course';
import {
  ContainerFluid,
  HeaderTitle,
  HeaderSubTitle,
  Wrapper,
  Container,
  CourseDetailWrapper,
  CourseInfoFlex,
  CourseThumbNail,
  CourseBanner,
  CourseInfoWrapper,
  CourseInfoTextHighlight,
  CourseTitle,
  CoursePartner,
  CourseDetailPrice,
  PriceExtraText,
  SummaryWrapper,
  SummaryFlex,
  SummaryTextBlock,
  SummaryText,
  SummaryValue,
  SummaryTotalText,
  SummaryTotalValue,
  DiscountWrapper,
  DiscountLabel,
  DiscountForm,
  DiscountInput,
  DiscountButton,
  DiscountSummary,
  VoucherAlert,
  PayButton,
  PaymentFooterText,
  Divider,
  Spacer,
  DesktopHidden,
  MobileHidden
} from 'components/utils/CourseCheckout/Checkout.styled';

const Checkout = () => {
  const { Text } = Typography;
  const dispatch = useDispatch();
  const history = useHistory();
  const discountInputRef = useRef();
  const auth = useSelector(state => state.auth);
  const course = useSelector(state => state.course);
  const [amount, setAmount] = useState(course?.coursePrice?.amount);
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });
  const currencyFormatter = useCurrencyFormater();
  const [reference] = useState(new Date().getTime());
  const [loading, setLoading] = useState(false);
  const [discountCode, setDiscountCode] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountCodeInSummary, setDiscountCodeInSummary] = useState('');
  const [showDiscountSummary, setShowDiscountSummary] = useState(false);
  const [discountLoading, setDiscountLoading] = useState(false);
  const [hideDiscountMobile, setHideDiscountMobile] = useState(true);
  const config = {
    reference,
    email: auth.userName,
    amount: +(amount * 100).toFixed(0),
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
  };
  const initializePayment = usePaystackPayment(config);

  const handleCourseEnroll = async () => {
    const params = {
      userId: auth.userId,
      courseId: course.courseId
    };

    const { description, success } = await courseEnrollment({
      userId: auth.userId,
      courseId: course.courseId
    });
    if (success) {
      dispatch(showToast('success', description));
      history.push(delivery, { ...params });
    } else {
      dispatch(showToast('error', description));
    }
  };

  const coursePaymentAction = async action => {
    const data = await coursePayment({
      referenceNo: reference,
      courseId: course.courseId,
      userId: auth.userId,
      amount: amount - discountAmount,
      paymentChannel: AppData.paymentChannel,
      email: auth.userName,
      discountCode,
      action
    });
    return data;
  };

  const handleLogPayment = async () => {
    try {
      setLoading(true);
      const { success, description } = await coursePaymentAction('Log');
      if (success) {
        if (amount > discountAmount) {
          initializePayment(onSuccess);
        } else {
          handleCourseEnroll();
        }
      } else {
        dispatch(showToast('error', description));
      }
    } catch (error) {
      console.log('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const onSuccessCallback = async () => {
    try {
      const data = await coursePaymentAction('Process');
      if (data.success) {
        message.success('Payment Succcessful');
        history.replace(
          coursetitle
            .replace(':id', course.courseId)
            .replace(':coursetitle', course.title.replaceAll(' ', '-'))
        );
      } else {
        message.error(data.description);
      }
    } catch (error) {
      console.log(error);
      message.error('Payment Failed');
    }
  };

  const onSuccess = data => {
    console.log(data, reference);
    onSuccessCallback();
  };

  const applyCodeHandler = async () => {
    try {
      setDiscountLoading(true);
      const data = await computePricing({
        courseId: course.courseId,
        institutionId: course.institutionId,
        discountCode
      });
      if (data.success) {
        setAmount(data.data.amount);
        setDiscountCodeInSummary(discountCode);
        message.success('Discount code applied');
      } else {
        message.error(data.description);
      }
    } catch (error) {
      setDiscountCodeInSummary('');
      message.error(error.message);
    } finally {
      setDiscountLoading(false);
    }
  };

  const toggleDiscountWrapper = () => {
    setHideDiscountMobile(!hideDiscountMobile);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <MainLayout sidebar={isMobileDevice ? false : true} selectedKey="course">
      <ContainerFluid img={DiscoveryImg}>
        <div>
          <HeaderTitle level={1}>Check Out</HeaderTitle>
        </div>
      </ContainerFluid>
      <Wrapper>
        <Container>
          <HeaderSubTitle level={3}>Course Details</HeaderSubTitle>
          <CourseDetailWrapper>
            <CourseThumbNail
              src={course?.courseThumbNailUrl}
              alt={course?.title}
              title={course?.title}
            />
            <CourseBanner
              src={course?.courseThumbNailUrl}
              alt={course?.title}
              title={course?.title}
            />
            <Spacer width="28" />
            <CourseInfoFlex>
              <CourseInfoWrapper>
                <CourseInfoTextHighlight>Course</CourseInfoTextHighlight>
                <CourseTitle>{course?.title}</CourseTitle>
                <Text>
                  Partner: <CoursePartner>{course?.institution}</CoursePartner>
                </Text>
              </CourseInfoWrapper>
              <CourseDetailPrice>
                {currencyFormatter('NGN', 'en-NG', course?.coursePrice?.amount)}
                <PriceExtraText>ONE-TIME PURCHASE</PriceExtraText>
              </CourseDetailPrice>
            </CourseInfoFlex>
          </CourseDetailWrapper>
          <MobileHidden>
            <HeaderSubTitle level={3}>Billing Summary</HeaderSubTitle>
          </MobileHidden>
          <SummaryWrapper>
            <SummaryFlex maxWidth="400px">
              <DesktopHidden>
                <HeaderSubTitle level={3}>Billing Summary</HeaderSubTitle>
              </DesktopHidden>
              <SummaryTextBlock>
                <SummaryText>Course Price</SummaryText>
                <SummaryValue>
                  {currencyFormatter('NGN', 'en-NG', course?.coursePrice?.amount)}
                </SummaryValue>
              </SummaryTextBlock>
              <Spacer height="20" />
              <SummaryTextBlock>
                <SummaryText>Discount Code</SummaryText>
                {discountCodeInSummary && <SummaryValue>{discountCodeInSummary}</SummaryValue>}
              </SummaryTextBlock>
              <Spacer height="30" />
              <Divider />
              <Spacer height="25" />
              <SummaryTextBlock>
                <SummaryTotalText>Amount</SummaryTotalText>
                <SummaryTotalValue>
                  {currencyFormatter(
                    'NGN',
                    'en-NG',
                    amount - discountAmount < 0 ? 0 : amount - discountAmount
                  )}
                </SummaryTotalValue>
              </SummaryTextBlock>
            </SummaryFlex>
            <Spacer width="90" />
            <SummaryFlex>
              <DesktopHidden>
                <VoucherAlert>
                  <img style={{ marginRight: '10px' }} src={VoucherIcon} alt="voucher icon" />
                  <div>
                    Got a voucher?{' '}
                    <span onClick={toggleDiscountWrapper}>Click here to enter your code</span>
                  </div>
                </VoucherAlert>
              </DesktopHidden>
              <DiscountWrapper hide={hideDiscountMobile}>
                <DiscountLabel for="discountInput">
                  <MobileHidden>Got a voucher / discount code ?</MobileHidden>
                  <DesktopHidden>Apply the voucher below </DesktopHidden>
                </DiscountLabel>
                <Spacer height="14" />
                <DiscountForm>
                  <DiscountInput
                    ref={discountInputRef}
                    name="discountInput"
                    placeholder="Enter Discount code"
                    onChange={e => {
                      if (showDiscountSummary) {
                        setShowDiscountSummary(false);
                      }
                      setDiscountCode(e.target.value);
                    }}
                  />
                  <DiscountButton
                    type="primary"
                    disabled={!discountCode}
                    loading={discountLoading}
                    onClick={applyCodeHandler}>
                    Apply
                  </DiscountButton>
                </DiscountForm>
              </DiscountWrapper>
              {/* {showDiscountSummary && (
                <DiscountSummary>
                  <SummaryText style={{ marginRight: 'auto' }}>{`${currencyFormatter(
                    'NGN',
                    'en-NG',
                    discountAmount
                  )} off promo Discount`}</SummaryText>
                  <SummaryValue>{discountCode}</SummaryValue>
                  <SummaryText>&nbsp;is applied</SummaryText>
                  <img
                    style={{ cursor: 'pointer', marginLeft: 20, marginTop: 3 }}
                    src={DiscountClose}
                    alt="close icon"
                    onClick={() => setShowDiscountSummary(false)}
                  />
                </DiscountSummary>
              )} */}
            </SummaryFlex>
          </SummaryWrapper>
          <Spacer height="50" />
          <PayButton disabled={discountLoading} loading={loading} onClick={handleLogPayment}>
            {amount > discountAmount
              ? `Pay ${currencyFormatter(
                  'NGN',
                  'en-NG',
                  amount - discountAmount < 0 ? 0 : amount - discountAmount
                )}`
              : 'Proceed to Course'}
          </PayButton>
          <PaymentFooterText>
            <span>By Completing your purchase, you agree to this </span>
            <a href={terms} target="_blank" rel="noreferrer">
              Terms & Privacy policy
            </a>
          </PaymentFooterText>
        </Container>
      </Wrapper>
    </MainLayout>
  );
};

export default Checkout;
