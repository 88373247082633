import styled from 'styled-components';
import { Row, Typography, Button, Radio, Input } from 'antd';
import SurveyIntroImage from 'assets/images/survey-intro.png';

export const SurveyWrapper = styled(Row)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  width: 100%;
  min-height: calc(100vh - 64px);
  padding: 32px;
  background: ${({ noBackgroundImage }) =>
    noBackgroundImage ? '#fff' : `url(${SurveyIntroImage})`};
  background-size: cover;
`;

export const IntroDetails = styled(Typography.Paragraph)`
  max-width: 800px;
  width: 100%;
  font-size: 22px;
  line-height: 1.4;
  text-align: center;
  margin: ${({ noMargin }) => (noMargin ? '0px !important' : 'initial')};
`;

export const OutroDetailsWrapper = styled(Row)`
  width: 800px;
  background: #f5faff;
  border-radius: 4px;
  padding: 16px;
`;

export const TitleText = styled(Typography.Paragraph)`
  font-size: 36px;
  font-weight: 600;
  color: #0966b8;
`;

export const HighlightText = styled(Typography.Text)`
  font-size: 16px;
  color: #0966b8;
`;

export const FlexRow = styled(Row)`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  padding: ${({ padding }) => padding || '0px'};
  width: 100%;
  gap: ${({ gap }) => gap || 'initial'};
  ${({ responsive }) =>
    responsive &&
    ` @media (max-width: 580px) {
    flex-direction: column;
  }`}
`;

export const FlexCol = styled(Row)`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  padding: ${({ padding }) => padding || '0px'};
  width: 100%;
  ${({ responsive }) =>
    responsive &&
    ` @media (max-width: 580px) {
    padding: 0px;
  }`}
`;

export const LineSpacer = styled.div`
  background-color: #cccccc;
  height: 22px;
  width: 1.5px;
  margin: 0 20px;
`;

export const ActionButton = styled(Button)`
  height: 50px;
  background-color: ${({ type }) =>
    type === 'primary' ? '#0966b8' : type === 'outline' ? '#fff' : 'transparent'};
  color: ${({ type }) =>
    type === 'primary' ? '#fff' : type === 'outline' ? '#0966b8' : 'rgb(93, 93, 93, 0.34)'};
  font-size: 22px;
  border-color: ${({ type }) => (type === 'primary' ? '#0966b8' : 'currentcolor')};
  border-radius: 70px;
  margin: ${({ margin }) => margin || '0px'};
`;

export const QuestionWrapper = styled(Row)`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'initial'};
  border-radius: 3px;
  background-color: rgba(241, 245, 249, 0.43);
  display: flex;
  align-items: center;
  align-self: ${({ alignSelf }) => alignSelf || 'initial'};
  flex: ${({ flex }) => flex || 'initial'};
  padding: 16px;
  margin: ${({ margin }) => margin || '0'};
  ${({ responsive }) =>
    responsive &&
    ` @media (max-width: 580px) {
    margin: 0px 0px 16px 0px;
  }`}
`;

export const Question = styled(Typography.Text)`
  font-weight: 500;
  font-size: 24px;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  width: 100%;
  display: inline-block;
`;

export const OptionGroup = styled(Radio.Group)`
  width: 100%;
  padding: 20px 0px;
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SurveyInput = styled(Input.TextArea)`
  margin: 30px 0px;
`;

export const RateButton = styled(Button)`
  background-color: #fff;
  border: 1px solid #b9b9b9;
  width: 50px;
  height: 50px;
  margin: 10px 0px;
  font-size: 18px;
  &:hover,
  &:focus {
    background-color: #0966b8;
    border: 1px solid #0966b8;
    color: #fff;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const SurveyProgress = styled(Row)`
  height: 40px;
  width: ${({ progressValue }) =>
    progressValue ? `${progressValue.toString().replace('%', '')}%` : '0%'};
  border-bottom: 4px solid #2f80ed;
`;
