import React from 'react';
import { isMobile } from 'utils';
import { Box, ResponsiveContext, Text } from 'grommet';
import Loader from './Loader';

const PageLoader = ({ tip, isLoading, top, left, fullWidth, fullHeight }) => {
  const size = React.useContext(ResponsiveContext);
  return (
    <Box
      height="100%"
      background="rgba(2555,255,255,0.5)"
      style={{
        position: 'fixed',
        zIndex: 9999,
        left: fullWidth ? 0 : left ? left : isMobile(size) ? 0 : 200,
        right: 0,
        top: fullHeight ? 0 : top ? top : isMobile(size) ? 54 : 66,
        bottom: 0,
        display: !isLoading ? 'none' : 'block'
      }}>
      <Box justify="center" align="center" fill={true}>
        <Box gap="small">
          <Loader color="#0966b8" />
          <Text textAlign="center">{tip}</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default PageLoader;
