import React from 'react';
import { Button, Table } from 'antd';

const CoursePurchaseTable = () => {
  const columns = [
    {
      title: 'STUDENT NAME',
      dataIndex: 'studentName',
      key: 'studentName'
    },
    {
      title: 'ENROLLED',
      dataIndex: 'enrolled',
      key: 'enrolled'
    },
    {
      title: 'COURSE NAME',
      dataIndex: 'courseName',
      key: 'courseName',
      render: text => <p style={{ color: '#1890ff' }}>{text}</p>
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      key: 'amount'
    },
    {
      title: 'DISCOUNT',
      dataIndex: 'discount',
      key: 'discount'
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'SOURCE CHANNEL',
      dataIndex: 'sourceChannel',
      key: 'sourceChannel'
    },
    {
      title: 'PLATFORM',
      dataIndex: 'platform',
      key: 'platform'
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => <Button>View</Button>
    }
  ];

  const data = [
    {
      key: '1',
      studentName: 'Adam Smith',
      enrolled: '11/03/2021',
      courseName: 'Excel Fundamentals',
      amount: 'N1,000',
      discount: 'N150',
      id: '124',
      sourceChannel: 'Organic',
      platform: 'Web/Mobile'
    },
    {
      key: '2',
      studentName: 'Jane Doe',
      enrolled: '15/03/2021',
      courseName: 'Introduction to Data Science',
      amount: 'N900',
      discount: 'N200',
      id: '2',
      sourceChannel: 'Promotion',
      platform: 'Mobile'
    },
    {
      key: '3',
      studentName: 'James Peter',
      enrolled: '20/04/2021',
      courseName: 'Digital Science',
      amount: 'N1,500',
      discount: 'N200',
      id: '12',
      sourceChannel: 'Social Media',
      platform: 'Web'
    }
  ];
  return <Table columns={columns} dataSource={data} />;
};

export default CoursePurchaseTable;
