export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_CLEAR = 'SIGN_IN_CLEAR';
export const REFRESH_TOKEN_AUTH = 'REFRESH_TOKEN_AUTH';
export const SIGN_OUT = 'SIGN_OUT';
export const ALL_PERMISSIONS = 'ALL_PERMISSIONS';
export const CHANGE_PROFILE_PHOTO = 'CHANGE_PROFILE_PHOTO';

export const signIn = data => ({
  type: SIGN_IN,
  data
});

export const signInClear = data => ({
  type: SIGN_IN_CLEAR,
  data
});

export const refreshTokenAuth = data => ({
  type: REFRESH_TOKEN_AUTH,
  data
});

export const signOut = () => ({
  type: SIGN_OUT
});

export const allPermissionsAction = data => ({
  type: ALL_PERMISSIONS,
  data
});

export const changeProfilePhoto = data => ({
  type: CHANGE_PROFILE_PHOTO,
  data
});
