export const BASIC_COURSE = 'BASIC_COURSE';
export const COURSE_ADDITIONAL_INFO = 'COURSE_ADDITIONAL_INFO';
export const COURSE_BUILDER = 'COURSE_BUILDER';
export const COURSE_PRICING = 'COURSE_PRICING';
export const COURSE_DETAILS = 'COURSE_DETAILS';
export const COURSE_ID = 'COURSE_ID';
export const ADD_SURVEY_ID = 'ADD_SURVEY_ID';
export const COURSE_STEP_NO = 'COURSE_STEP_NO';
export const CLEAR_COURSE = 'CLEAR_COURSE';
export const CLEAR_COURSE_MODULES = 'CLEAR_COURSE_MODULES';

export const basicCourse = data => ({ type: BASIC_COURSE, data });

export const courseAdditionalInfo = data => ({ type: COURSE_ADDITIONAL_INFO, data });

export const courseBuilderAction = data => ({ type: COURSE_BUILDER, data });

export const coursePricingAction = data => ({ type: COURSE_PRICING, data });

export const courseDetails = data => ({ type: COURSE_DETAILS, data });

export const courseId = data => ({ type: COURSE_ID, data });

export const addSurveyId = data => ({ type: ADD_SURVEY_ID, data });

export const courseStepNo = data => ({ type: COURSE_STEP_NO, data });

export const clearCourse = () => ({ type: CLEAR_COURSE });

export const clearCourseModules = () => ({ type: CLEAR_COURSE_MODULES });
