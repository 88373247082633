import React, { useEffect, useState } from 'react';
import { Box, FormField, ResponsiveContext, Text, Form, Select, Button } from 'grommet';
import { isMobile } from 'utils';
import { AccountNumberRegex, emailRegex, newPhoneRegex, webSiteRegex } from 'data/regex';
import { useHistory } from 'react-router-dom';
import { queryInstitution } from 'api/queries/Institution';
import InputValidationLoader from 'components/common/InputValidationLoader';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import InstitutionInfoForm from './InstitutionInfoForm';
import InputController from 'components/common/InputController';
import { useForm, Controller } from 'react-hook-form';
import ButtonLoader from 'components/common/ButtonLoader';

const BasicInfoInstitutionForm = props => {
  const { errors, setError, clearErrors, setValue, register, handleSubmit, control } = useForm();
  const {
    setImageState,
    countries,
    institutionType,
    isSubmitting,
    institutionFormState,
    banks,
    courseThumbNailUrl,
    setCourseThumbNailUrl,
    institutionBannerUrl,
    setInstitutionBannerUrl,
    institutionLogoUrl,
    setInstitutionLogoUrl,
    setCourseThumbNailUrlFile,
    setInstitutionBannerUrlFile,
    setInstitutionLogoUrlFile,
    setCertificatelogoUrl,
    certificatelogoUrl,
    setCertificatelogoUrlFile,
    onInstitutionSubmit
  } = props;

  const [countryOptions, setCountryOptions] = useState(null);
  const [bankOptions, setBankOptions] = useState(null);
  const size = React.useContext(ResponsiveContext);
  const [loading, setLoading] = useState(false);
  const [codeLoading, setCodeLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const history = useHistory();
  const formFieldStyle = {
    flexDirection: isMobile(size) ? 'column' : 'row',
    alignItems: isMobile(size) ? 'stretch' : 'center',
    justifyContent: isMobile(size) ? 'flex-start' : 'space-between'
  };

  React.useEffect(() => {
    register('overView', { required: 'Overview  is required' });
  }, [register]);

  useEffect(() => {
    if (countries) {
      setCountryOptions(countries);
    }
  }, [countries]);

  useEffect(() => {
    if (banks) {
      setBankOptions(banks);
    }
  }, [banks]);

  const onBlurValidate = (inputName, value) => {
    inputName === 'institutionName'
      ? setLoading(true)
      : inputName === 'code'
      ? setCodeLoading(true)
      : setEmailLoading(true);
    return queryInstitution(
      inputName === 'institutionName' ? value : null,
      inputName === 'code' ? value : null,
      inputName === 'email' ? value : null
    )
      .then(({ data }) => {
        return !!!data;
      })
      .catch(e => console.log(e))
      .finally(() => {
        setLoading(false);
        setCodeLoading(false);
        setEmailLoading(false);
      });
  };

  const onSubmit = data => {
    onInstitutionSubmit(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '100%' }}>
      <Box style={{ maxWidth: 475 }}>
        <InputController
          Controller={Controller}
          controllerName="fullName"
          errors={errors}
          errorMessage="Partner FullName is required"
          control={control}
          defaultValue={institutionFormState.fullName || institutionFormState[14]?.value}
          required
          label="Partner FullName "
          name="fullName"
          htmlfor="fullName"
          placeholder="Partner FullName"
        />
        <InputController
          loader={loading}
          Controller={Controller}
          controllerName="institutionName"
          onBlurCallback={async e => {
            if (!history.location.pathname.includes('Institution/Update') && e !== '') {
              const inputVal = await onBlurValidate('institutionName', e);
              if (!inputVal) {
                setError('institutionName', {
                  type: 'validate',
                  message: 'Name already exist'
                });
              } else {
                clearErrors(['institutionName']);
              }
            }
          }}
          width="248px"
          errors={errors}
          errorMessage="Partner Name is required"
          control={control}
          defaultValue={institutionFormState[0]?.value || institutionFormState.institutionName}
          label="Partner ShortName"
          name="institutionName"
          htmlfor="institutionName"
          placeholder="Partner Name"
        />
        {!history.location.pathname.includes('Institution/Update') && (
          <Box margin={{ bottom: 'small' }}>
            <FormField
              className="bd_remove"
              label="Partner Type"
              name="institutionType"
              htmlfor="institution-type"
              margin={{ bottom: 'none' }}
              style={formFieldStyle}>
              {institutionType && (
                <Controller
                  name="institutionType"
                  control={control}
                  defaultValue={() => {
                    if (institutionFormState[1]?.value) {
                      const institutionTypeName = institutionType.find(
                        d => d.value === institutionFormState[1]?.value
                      )?.name;
                      setValue('institutionType', institutionTypeName);
                      return institutionTypeName;
                    } else return null;
                  }}
                  rules={{
                    required: !history.location.pathname.includes('Institution/Update')
                      ? 'Partner Type is required'
                      : false
                  }}
                  render={props => (
                    <Select
                      id="institution-type"
                      name="institutionType"
                      placeholder="Partner Type"
                      value={props.value}
                      style={{ width: isMobile(size) ? '100%' : 200 }}
                      options={institutionType.map(d => d.name)}
                      onChange={({ option }) => {
                        props.onChange(option);
                        setValue('institutionType', option, {
                          shouldValidate: true
                        });
                      }}
                    />
                  )}
                />
              )}
            </FormField>
            {errors.institutionType && (
              <Text
                color="status-critical"
                size="small"
                alignSelf={isMobile(size) ? 'start' : 'end'}
                margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
                {errors.institutionType.message}
              </Text>
            )}
          </Box>
        )}

        {/* {!history.location.pathname.includes('Institution/Update') && (
          <InputController
            loader={codeLoading}
            Controller={Controller}
            controllerName="code"
            onBlurCallback={async e => {
              if (e !== '') {
                const inputVal = await onBlurValidate('code', e);
                if (!inputVal) {
                  setError('code', {
                    type: 'validate',
                    message: 'Code already exist'
                  });
                } else {
                  clearErrors(['code']);
                }
              }
            }}
            width="248px"
            errors={errors}
            errorMessage="Code is required"
            otherRules={{
              maxLength: {
                value: 4,
                message: 'maximum of 4 letters'
              },
              minLength: {
                value: 4,
                message: 'minimum of 4 letters'
              }
            }}
            control={control}
            defaultValue={institutionFormState[7]?.value || ''}
            label="Code"
            name="code"
            htmlfor="code"
            placeholder="Code"
          />
        )} */}

        <InputController
          loader={emailLoading}
          Controller={Controller}
          controllerName="email"
          onBlurCallback={async e => {
            if (!history.location.pathname.includes('Institution/Update') && e) {
              const inputVal = await onBlurValidate('email', e);
              if (!inputVal) {
                setError('email', {
                  type: 'validate',
                  message: 'Email already exist'
                });
              } else {
                clearErrors(['email']);
              }
            }
          }}
          width="248px"
          errors={errors}
          otherRules={{
            pattern: {
              value: emailRegex,
              message: 'Not a valid email address'
            }
          }}
          control={control}
          defaultValue={institutionFormState[4]?.value || institutionFormState.email}
          label="Email"
          name="email"
          type="email"
          htmlfor="email"
          placeholder="Email"
        />

        <InputController
          Controller={Controller}
          controllerName="phone"
          width="248px"
          errors={errors}
          otherRules={{
            pattern: {
              value: newPhoneRegex,
              message: 'Not a valid phone number'
            },
            minLength: {
              value: 11,
              message: 'minimum of 11 letters'
            }
          }}
          control={control}
          defaultValue={institutionFormState[3]?.value || institutionFormState.phone}
          label="Phone"
          name="phone"
          htmlfor="phone"
          placeholder="Phone"
        />

        <Box margin={{ bottom: 'small' }} style={{ position: 'relative' }}>
          {!countries && <InputValidationLoader message={errors.country} />}
          <FormField
            className="bd_remove"
            label="Country"
            name="country"
            htmlfor="country"
            margin={{ bottom: 'none' }}
            style={formFieldStyle}>
            {countryOptions && (
              <Controller
                name="country"
                control={control}
                defaultValue={() => {
                  if (institutionFormState[6]?.value) {
                    const newCountryName = countries.find(
                      d => d.code1 === institutionFormState[6]?.value
                    )?.countryName;
                    setValue('country', newCountryName);
                    return newCountryName;
                  } else if (institutionFormState?.country) {
                    setValue('country', institutionFormState?.country);
                    return institutionFormState?.country;
                  } else return null;
                }}
                rules={{ required: 'Country is required' }}
                render={props => (
                  <Select
                    style={{ width: isMobile(size) ? '100%' : 200 }}
                    id="country"
                    name="country"
                    value={props.value}
                    placeholder="Country"
                    options={countryOptions.map(a => a.countryName)}
                    onChange={({ option }) => {
                      props.onChange(option);
                      setValue('country', option, {
                        shouldValidate: true
                      });
                    }}
                    onClose={() => setCountryOptions(countries)}
                    onSearch={text => {
                      // The line below escapes regular expression special characters:
                      // [ \ ^ $ . | ? * + ( )
                      const escapedText = text
                        .toLowerCase()
                        .replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

                      // Create the regular expression with modified value which
                      // handles escaping special characters. Without escaping special
                      // characters, errors will appear in the console
                      const exp = new RegExp(escapedText, 'i');
                      setCountryOptions(
                        countries.filter(o => exp.test(o.countryName.toLowerCase()))
                      );
                    }}
                  />
                )}
              />
            )}
          </FormField>
          {errors.country && (
            <Text
              color="status-critical"
              size="small"
              alignSelf={isMobile(size) ? 'start' : 'end'}
              margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
              {errors.country.message}
            </Text>
          )}
        </Box>

        <InputController
          Controller={Controller}
          controllerName="address"
          errorMessage={
            !history.location.pathname.includes('Institution/Update')
              ? 'Address is required'
              : false
          }
          width="248px"
          errors={errors}
          control={control}
          defaultValue={institutionFormState[5]?.value || institutionFormState?.address}
          label="Address"
          resize={false}
          name="address"
          type="textarea"
          htmlfor="address"
          placeholder="Address"
        />

        <InputController
          Controller={Controller}
          controllerName="website"
          width="248px"
          errors={errors}
          otherRules={{
            pattern: {
              value: webSiteRegex,
              message: 'Not a valid Url'
            }
          }}
          control={control}
          defaultValue={institutionFormState[2]?.value || institutionFormState.website}
          label="Website"
          type="url"
          name="website"
          htmlfor="website"
          placeholder="Website"
        />
        <InputController
          Controller={Controller}
          controllerName="accountNumber"
          errors={errors}
          errorMessage="Account Number is required"
          control={control}
          defaultValue={institutionFormState[8]?.value || institutionFormState?.accountNumber}
          required
          otherRules={{
            pattern: {
              value: AccountNumberRegex,
              message: 'Not a valid Account Number number'
            }
          }}
          type={'number'}
          label="Account Number"
          name="accountNumber"
          htmlfor="accountNumber"
          placeholder="Account Number"
        />

        <Box margin={{ bottom: 'small' }} style={{ position: 'relative' }}>
          {!banks && <InputValidationLoader message={errors.bank} />}
          <FormField
            className="bd_remove"
            label="Bank"
            name="bank"
            htmlfor="bank"
            margin={{ bottom: 'none' }}
            style={formFieldStyle}>
            {bankOptions && (
              <Controller
                name="bank"
                control={control}
                defaultValue={() => {
                  if (institutionFormState[9]?.value) {
                    const newBankName = banks.find(d => d.id === institutionFormState[9]?.value)
                      ?.name;
                    setValue('bank', newBankName);
                    return newBankName;
                  } else if (institutionFormState.bank) {
                    const newBankName = institutionFormState.bank;
                    setValue('bank', newBankName);
                    return newBankName;
                  } else return null;
                }}
                rules={{ required: 'Bank is required' }}
                render={props => (
                  <Select
                    style={{ width: isMobile(size) ? '100%' : 200 }}
                    id="bank"
                    name="bank"
                    value={props.value}
                    placeholder="Bank"
                    options={bankOptions.map(a => a.name)}
                    onChange={({ option }) => {
                      props.onChange(option);
                      setValue('bank', option, {
                        shouldValidate: true
                      });
                    }}
                    onClose={() => setBankOptions(banks)}
                    onSearch={text => {
                      // The line below escapes regular expression special characters:
                      // [ \ ^ $ . | ? * + ( )
                      const escapedText = text
                        .toLowerCase()
                        .replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

                      // Create the regular expression with modified value which
                      // handles escaping special characters. Without escaping special
                      // characters, errors will appear in the console
                      const exp = new RegExp(escapedText, 'i');
                      setBankOptions(banks.filter(o => exp.test(o.name.toLowerCase())));
                    }}
                  />
                )}
              />
            )}
          </FormField>
          {errors.bank && (
            <Text
              color="status-critical"
              size="small"
              alignSelf={isMobile(size) ? 'start' : 'end'}
              margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
              {errors.bank.message}
            </Text>
          )}
        </Box>

        <Box margin={{ bottom: 'small' }}>
          <FormField
            label="Overview"
            name="overView"
            htmlfor="overView"
            margin={{ bottom: 'none' }}
            style={formFieldStyle}>
            <Box style={{ width: isMobile(size) ? '100%' : 248 }}>
              <CKEditor
                editor={ClassicEditor}
                data={institutionFormState.overView || institutionFormState[10]?.value}
                onInit={editor => {
                  // console.log('Editor is ready to use!', editor);
                  if (institutionFormState[10]?.value || institutionFormState.overView) {
                    const data = editor.getData();
                    setValue('overView', data);
                  }
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setValue('overView', data);
                  // console.log({ event, editor, data });
                }}
              />
            </Box>
          </FormField>
          {errors.overView && (
            <Text
              color="status-critical"
              size="small"
              alignSelf={isMobile(size) ? 'start' : 'end'}
              margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
              {errors.overView.message}
            </Text>
          )}
        </Box>
      </Box>
      <Box>
        <InstitutionInfoForm
          setImageState={setImageState}
          courseThumbNailUrl={courseThumbNailUrl}
          setCourseThumbNailUrl={setCourseThumbNailUrl}
          institutionBannerUrl={institutionBannerUrl}
          setInstitutionBannerUrl={setInstitutionBannerUrl}
          institutionLogoUrl={institutionLogoUrl}
          setInstitutionLogoUrl={setInstitutionLogoUrl}
          setCourseThumbNailUrlFile={setCourseThumbNailUrlFile}
          setInstitutionBannerUrlFile={setInstitutionBannerUrlFile}
          setInstitutionLogoUrlFile={setInstitutionLogoUrlFile}
          setCertificatelogoUrl={setCertificatelogoUrl}
          certificatelogoUrl={certificatelogoUrl}
          setCertificatelogoUrlFile={setCertificatelogoUrlFile}
        />
      </Box>
      <Box margin={{ top: 'small' }} direction="row" gap="medium" justify="end">
        {history.location.pathname.includes('Institution/Update') ? (
          <Button
            type="submit"
            primary
            size="small"
            margin={{ top: 'xsmall' }}
            label={!isSubmitting && 'Update'}
            icon={isSubmitting ? <ButtonLoader isSubmitting={isSubmitting} /> : null}
          />
        ) : (
          <Button type="submit" primary size="small" margin={{ top: 'xsmall' }} label="Next" />
        )}
      </Box>
    </Form>
  );
};

export default BasicInfoInstitutionForm;
