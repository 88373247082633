import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { timeStampFormat } from 'utils';

const AuditTrailTable = ({
  audittrailResult,
  currentPage,
  pageSize,
  setCurrentPage,
  setPageSize,
  toggleExport
}) => {
  const [pagination, setPagination] = useState();

  useEffect(() => {
    if (!toggleExport) {
      setPagination({
        current: currentPage,
        pageSize: pageSize,
        total: audittrailResult?.data[0]?.totalRows
      });
    }
  }, [audittrailResult, currentPage, pageSize, toggleExport]);

  const columns = [
    {
      title: 'S/NO',
      dataIndex: 'sno',
      key: 'sno',
      width: 100
    },
    {
      title: 'USER NAME',
      dataIndex: 'userName',
      key: 'userName',
      width: 100
    },
    {
      title: 'IP ADDRESS',
      dataIndex: 'ipAdress',
      key: 'ipAddress',
      width: 100
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      width: 100
    },
    {
      title: 'RECORD',
      dataIndex: 'record',
      key: 'record',
      width: 100
    },
    {
      title: 'ACTIVITY',
      dataIndex: 'moduleName',
      key: 'activity',
      width: 100
    },
    {
      title: 'ACTION',
      dataIndex: 'moduleAction',
      key: 'action',
      width: 100
    },
    {
      title: 'TIME STAMP',
      dataIndex: 'dateCreated',
      key: 'timeStamp',
      width: 100
    },
    {
      title: 'BROWSER',
      dataIndex: 'apiKey',
      key: 'browser',
      width: 100
    }
  ];

  const handleTableChange = pagination => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const tableData = audittrailResult?.data?.map((item, i) => ({
    ...item,
    key: i,
    dateCreated: timeStampFormat(item?.dateCreated)
  }));

  return (
    <Table
      style={{ width: '100%', overflow: 'auto' }}
      columns={columns}
      dataSource={tableData}
      pagination={pagination}
      onChange={handleTableChange}
    />
  );
};

export default AuditTrailTable;
