import { Input, message } from 'antd';
import { ButtonCTA } from 'components/common/ButtonCTA';
import React, { useState } from 'react';
import styled from 'styled-components';

const CopyToClipboard = ({ copyText }) => {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        message.success('copied!!!');
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Wrap>
      <InputWrap size="large" type="text" value={copyText} readOnly />

      <ButtonWrap
        width="10%"
        type="primary"
        size="large"
        // size={isMobileDevice ? 'medium' : 'large'}
        color="#fff"
        disabled={isCopied}
        onClick={handleCopyClick}>
        <span>{isCopied ? 'Copied!' : 'Copy'}</span>
      </ButtonWrap>
    </Wrap>
  );
};

export default CopyToClipboard;

const InputWrap = styled(Input)`
  width: 88%;
`;
const Wrap = styled.div`
  margin: 1rem 0px ;
  width: 100%;
  display flex;
  justify-content: space-between;
  align-items: center;
`;
const ButtonWrap = styled(ButtonCTA)`
  &:disabled {
    background: #52c41a !important;
    color: #fff !important;
  }
`;
