import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardBody, CardHeader, FormField, TextInput } from 'grommet';
import CourseContent from 'components/utils/CourseBuilder/CourseContent';
import AddCourseItem from 'components/utils/CourseBuilder/AddCourseItem';
import CloseIcon from 'assets/svgs/ic_close.svg';
import IconWrapper from 'components/common/IconWrapper';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import AppData from 'AppData';
import CourseQuizContent from 'components/utils/CourseBuilder/CourseQuizContent';

const CourseSection = ({
  moduleIndex,
  sectionId,
  section,
  setCourseDragData,
  dragContentSourceIndex,
  sectionIndex,
  setUploads,
  isReview
}) => {
  const maxCount = 1;
  const [videoCount, setVideoCount] = useState(0);
  const [compressVideoCount, setCompressVideoCount] = useState(0);
  const [audioCount, setAudioCount] = useState(0);
  const [imageCount, setImageCount] = useState(0);
  const [articleCount, setArticleCount] = useState(0);
  const [quizCount, setQuizCount] = useState(0);
  const [sectionTitle, setSectionTitle] = useState(section.title || '');

  useEffect(() => {
    setCourseDragData(state => {
      const newState = { ...state };
      newState.courseModules[moduleIndex].items[sectionIndex] = {
        ...newState.courseModules[moduleIndex].items[sectionIndex],
        title: sectionTitle
      };
      return newState;
    });
  }, [moduleIndex, sectionIndex, sectionTitle, setCourseDragData]);

  const addContentType = contentType => {
    setCourseDragData(state => {
      const courseModules = [...state.courseModules];
      courseModules[moduleIndex].items[sectionIndex].courseModuleItemAssets.push({
        id: uuidv4(),
        type: contentType
      });
      const newState = {
        ...state,
        courseModules
      };
      return newState;
    });
  };

  return (
    <Draggable
      isDragDisabled={isReview}
      draggableId={sectionId}
      key={sectionId}
      index={sectionIndex}>
      {provided => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <Card>
            <CardHeader background="#fff" pad="small" border="bottom">
              <Box direction="row" fill="horizontal" justify="between" align="center" gap="small">
                <FormField margin="none" width="250px">
                  <TextInput
                    placeholder="Enter section name"
                    value={sectionTitle}
                    onChange={e => setSectionTitle(e.target.value)}
                    disabled={isReview}
                  />
                </FormField>
                {!isReview && (
                  <Button
                    icon={<IconWrapper icon={CloseIcon} width="20px" height="20px" />}
                    onClick={() => {
                      setCourseDragData(state => {
                        const courseModules = [...state.courseModules];
                        courseModules[moduleIndex].items.splice(sectionIndex, 1);
                        const newState = {
                          ...state,
                          courseModules
                        };
                        return newState;
                      });
                    }}
                  />
                )}
              </Box>
            </CardHeader>
            <CardBody background="#fff" pad="small" gap="medium">
              <Droppable droppableId={section.id} key={section.id} type={`content-${sectionIndex}`}>
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <Box gap="medium">
                      {section.courseModuleItemAssets.map((content, index) => {
                        const isQuiz = content.type === AppData.contentType.quiz;
                        const courseContentUrl = content?.url;
                        let startIndex;

                        if (courseContentUrl) {
                          if (courseContentUrl.startsWith('http')) {
                            startIndex = courseContentUrl.indexOf('_') + 1;
                          } else {
                            startIndex = courseContentUrl.indexOf('/') + 1;
                          }
                        }

                        return isQuiz ? (
                          <CourseQuizContent
                            key={content.id}
                            type={content.type}
                            contentId={content.id}
                            contentIndex={index}
                            moduleIndex={moduleIndex}
                            sectionIndex={sectionIndex}
                            setCourseDragData={setCourseDragData}
                            quizData={content.quiz}
                            contentCountData={{
                              setQuizCount
                            }}
                            isReview={isReview}
                          />
                        ) : (
                          <CourseContent
                            key={content.id}
                            contentId={content.id}
                            moduleIndex={moduleIndex}
                            sectionIndex={sectionIndex}
                            setCourseDragData={setCourseDragData}
                            dragContentSourceIndex={dragContentSourceIndex}
                            contentIndex={index}
                            type={content.type}
                            contentCountData={{
                              setVideoCount,
                              setCompressVideoCount,
                              setAudioCount,
                              setImageCount,
                              setArticleCount
                            }}
                            setUploads={setUploads}
                            defaultFileName={courseContentUrl?.slice(
                              startIndex,
                              courseContentUrl.length
                            )}
                            defaultCourseContentData={content}
                            isReview={isReview}
                          />
                        );
                      })}
                    </Box>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              {!isReview && (
                <AddCourseItem
                  itemType="content"
                  addContentType={addContentType}
                  contentCountData={{
                    maxCount,
                    videoCount,
                    compressVideoCount,
                    audioCount,
                    imageCount,
                    articleCount,
                    quizCount
                  }}
                />
              )}
            </CardBody>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default CourseSection;
