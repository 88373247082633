import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import NosurveyImg from 'assets/images/nosurvey.png';
import AppText from 'components/common/AppText';

const NoSurvey = ({ setCreateSurvey, isDraft }) => {
  return (
    <Div>
      <img src={NosurveyImg} />
      <AppText
        size="16px"
        color="#98A2B3"
        value={`No ${isDraft ? 'draft' : 'Survey'} created`}
        bottom=".3rem"
      />
      {!isDraft && (
        <Link onClick={() => setCreateSurvey(true)}>
          <AppText
            size="16px"
            color="#0966B8"
            value="Create Survey"
            style={{ textDecoration: 'underline' }}
          />
        </Link>
      )}
    </Div>
  );
};

export default NoSurvey;

const Div = styled.div`
  padding-top: 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
