import React from 'react';
import 'styles/utils.css';
import UserLayout from 'components/layouts/UserLayout';
import {
  Box,
  ResponsiveContext,
  Heading,
  TextInput,
  Accordion,
  AccordionPanel,
  ThemeContext,
  CheckBoxGroup,
  Button,
  Anchor,
  Carousel,
  Grid
} from 'grommet';
import CourseCard from 'components/common/CourseCard';
import IconWrapper from 'components/common/IconWrapper';
import CourseImage from 'assets/images/coursesdis.png';
import Next from 'assets/images/greaterthan.png';
import Previous from 'assets/images/lessthan.png';
import IcsanLogo from 'assets/images/icsan.png';
import { isMobile, isTablet } from 'utils';
import CourseCategoryCard from 'components/common/CourseCategoryCard';
import SectionHeader from 'components/utils/CourseDiscovery/SectionHeader';
import { Search } from 'grommet-icons';
import { FormNext } from 'grommet-icons';
import { FormPrevious } from 'grommet-icons';
import { Link } from 'react-router-dom';
import DiscoveryImg from 'assets/images/discover.png';
import styled from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import { getUserInfoByInstitutionId } from 'api/queries/Authentication';
import { courseCategoryList, getSummaryByCatgory } from 'api/queries/Course';
import { courseSearch, courseLanguageSearch } from 'api/mutations/Course';
import { search } from 'api/mutations/Institution';
import { Button as AntButton, Typography, Col, Row } from 'antd';
import MainLayout from 'components/MainLayout';
import { useSelector } from 'react-redux';

const CategoryNextIcon = styled.div`
  padding: 2px;
  border-radius: 100%;
  height: 30px;
  display: flex;
  background: #fff;
  width: 30px;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  position: absolute;
  right: -18px;
  box-shadow: 1px 4px 6px rgb(0 0 0);
  z-index: 3;
  ${'' /* box-shadow: 0px 8px 16px rgb(0 0 0 / 20%); */}
`;

const CategoryPrevIcon = styled.div`
  padding: 2px;
  border-radius: 100%;
  height: 30px;
  display: flex;
  background: #fff;
  width: 30px;
  left: -41px;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  position: absolute;
  box-shadow: 1px 4px 6px rgb(0 0 0);
  z-index: 3;
  ${'' /* box-shadow: 0px 8px 16px rgb(0 0 0 / 20%); */}
`;

const NextPosition = styled.div`
  position: absolute;
  top: -26px;
  right: 10px;
`;

const wrapper = styled(Box)`
  background: 'red';
`;

const PreviousPosition = styled.div`
  position: absolute;
  top: -26px;
  right: 50px;
`;

const CourseCategoryLanding = () => {
  const { Title, Text } = Typography;
  const size = React.useContext(ResponsiveContext);
  const auth = useSelector(state => state.auth);
  const [courses] = React.useState([1, 2, 3]);
  const [scrolled, setScrolled] = React.useState(false);
  const [searchBar, setSearchBar] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [courseSearchResult, setCourseSearchResult] = React.useState(null);
  const [institutionSearchResult, setInstitutionSearchResult] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [createError, setCreateError] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleScroll = () => {
    var offset = window.scrollY;
    if (offset > 10) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
    if (offset > 271) {
      setSearchBar(true);
    } else {
      setSearchBar(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const NextButton = () => {
    return (
      <NextPosition>
        <IconWrapper icon={Next} width="20px" height="20px" />
      </NextPosition>
    );
  };
  const PreviousButton = () => {
    return (
      <PreviousPosition>
        <IconWrapper icon={Previous} width="20px" height="20px" />
      </PreviousPosition>
    );
  };

  const { data: summaryByCatgory } = useQuery(['summaryByCatgory'], getSummaryByCatgory);
  //console.log('summaryByCatgory', summaryByCatgory)

  const [createMutate, info] = useMutation(courseSearch, {
    onSuccess: dataResult => {
      const { description, data, success } = dataResult;
      if (success) {
        setCourseSearchResult(data);
        setSuccessMessage(description);
      } else {
        setCreateError(description);
        setIsSubmitting(false);
        console.log(description);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setCreateError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        // setCreateError(error.message);
        console.log(error);
      }
    }
  });
  //console.log("CourseSearchResult", courseSearchResult);

  const [createInstitutionMutate] = useMutation(search, {
    onSuccess: dataResult => {
      const { description, success } = dataResult;
      setInstitutionSearchResult(dataResult);
      if (success) {
        setInstitutionSearchResult(dataResult);
        setSuccessMessage(description);
      } else {
        setCreateError(description);
        setIsSubmitting(false);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setCreateError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        // setCreateError(error.message);
        console.log(error);
      }
    }
  });

  React.useEffect(() => {
    try {
      const search = () => {
        const param = {
          page: 1,
          pageSize: 50,
          courseCategoryIds: [],
          parameter: '',
          institutionIds: [],
          courseLevels: [],
          languageIds: []
        };
        createMutate(param);
      };
      search();

      const instituionSearch = () => {
        const param = {
          searchVal: ''
        };
        createInstitutionMutate(param);
      };
      instituionSearch();
    } catch (error) {}
  }, [createInstitutionMutate, createMutate]);

  console.log(33, courseSearchResult);

  return (
    <MainLayout sidebar={auth.userId && true} selectedKey="course">
      <Box
        height="380px"
        pad={{ top: 'large', bottom: 'none', right: '', left: 'none' }}
        background={{
          dark: true,
          position: '50% 0%',
          repeat: 'no-repeat',
          size: 'cover',
          image: `linear-gradient(to right, rgba(0,0,0,1),rgba(0,0,0,1), rgba(0,0,0,.1) ), url(${DiscoveryImg})`
        }}>
        <div style={{ padding: '48px 48px 0px' }}>
          <Title level={1} style={{ wordSpacing: 5, color: '#fff' }}>
            Digital Literacy{' '}
            <AntButton type="danger" shape="round" size="small">
              Career Track Available
            </AntButton>
          </Title>

          <div>
            <Text style={{ fontSize: 'small', color: '#fff' }}>
              Gain the career building R Skills you need to succeed as a data Scientist. No prior
              coding experience required.
              <br />
              <br />
              In this track, you'll learn how this versatile language allows you to import, clean,
              manipulate, and visualize data
              <br /> all integral skills for any aspiring data professional or researcher. Through
              interactive exercises, you will get hands on with
            </Text>
            <div style={{ width: '50px', marginTop: 20 }}>
              <AntButton
                style={{
                  borderRadius: '5px',
                  border: '1px solid transparent',
                  backgroundColor: 'green',
                  color: '#fff'
                }}
                size="middle">
                Start Career Track
              </AntButton>
            </div>
          </div>
        </div>
      </Box>
      <Box pad="large" background="#fff">
        <Box gap={isMobile(size) ? 'large' : 'small'}>
          <Box direction="row" gap="medium" justify="between">
            <Box width="100%">
              <Box direction="row" align="center" margin={{ vertical: 'xsmall' }}>
                <Text style={{ fontSize: 'small' }}>
                  Gain the career building R Skills you need to succeed as a data Scientist. No
                  prior coding experience required.
                  <br />
                  <br />
                  In this track, you'll learn how this versatile language allows you to import,
                  clean, manipulate, and visualize data all integral skills for any aspiring data
                  professional or researcher. <br />
                  Through interactive exercises, you will get hands on with
                </Text>
              </Box>
              <Box margin={{ top: 'medium' }}>
                <Box
                  direction={isMobile(size) ? 'column' : 'row'}
                  gap="medium"
                  align={isMobile(size) ? 'stretch' : 'center'}
                  justify="between">
                  <SectionHeader color="#686868" title="Top Programs" marginTop="none" />
                </Box>

                <Grid gap="medium" columns={{ count: 'fit', size: 'small' }}>
                  {courseSearchResult?.items &&
                    courseSearchResult?.items.slice(2, 6).map(item => {
                      return (
                        <CourseCard
                          key={item}
                          careerRelated
                          course="6"
                          price={item.coursePrice?.amount}
                          title={item.title}
                          description={item.description}
                          modules={item.modules}
                          duration="9h 45mins"
                          level={
                            item?.courseLevel === 1
                              ? 'Beginner'
                              : item?.courseLevel === 2
                              ? 'Intermedate'
                              : 'Advanced'
                          }
                          institutionLogo={
                            item.institutionLogo === null ? IcsanLogo : item.institutionLogo
                          }
                          institutionName={item.institution}
                          courseThumbNailUrl={item.courseThumbNailUrl}
                        />
                      );
                    })}
                </Grid>
              </Box>
              <Box margin={{ top: 'medium' }}>
                <Box
                  direction={isMobile(size) ? 'column' : 'row'}
                  gap="medium"
                  align={isMobile(size) ? 'stretch' : 'center'}
                  justify="between">
                  <SectionHeader color="#686868" title="Top Courses" marginTop="none" />
                </Box>
                <ThemeContext.Extend
                  value={{
                    carousel: {
                      animation: {
                        duration: 400
                      },
                      icons: {
                        color: 'blue',
                        next: NextButton,

                        previous: PreviousButton
                      },
                      disabled: {
                        icons: {
                          color: 'grey'
                        }
                      }
                    }
                  }}>
                  <Carousel controls={isMobile(size) ? false : 'arrows'}>
                    {courseSearchResult?.items?.map(item => (
                      <Box
                        key={item.courseId}
                        direction={isMobile(size) ? 'column' : 'row'}
                        margin={{ top: 'medium' }}
                        justify="between">
                        {courseSearchResult &&
                          courseSearchResult?.items.slice(2, 6).map(item => {
                            return (
                              <CourseCard
                                key={item.courseId}
                                careerRelated
                                course="6"
                                price={item.coursePrice?.amount}
                                title={item.title}
                                description={item.description}
                                modules={item.modules}
                                duration="9h 45mins"
                                level={
                                  item?.courseLevel === 1
                                    ? 'Beginner'
                                    : item?.courseLevel === 2
                                    ? 'Intermedate'
                                    : 'Advanced'
                                }
                                institutionLogo={
                                  item.institutionLogo === null ? IcsanLogo : item.institutionLogo
                                }
                                institutionName={item.institution}
                                courseThumbNailUrl={item.courseThumbNailUrl}
                              />
                            );
                          })}
                      </Box>
                    ))}
                  </Carousel>
                </ThemeContext.Extend>
                <Box justify="end" direction="row">
                  <Anchor
                    icon={<FormNext />}
                    label="See All Programs"
                    color="#0849ff"
                    href="#"
                    reverse
                  />
                </Box>
              </Box>{' '}
              <Box>
                <Box
                  direction={isMobile(size) ? 'column' : 'row'}
                  gap="medium"
                  align={isMobile(size) ? 'stretch' : 'center'}
                  justify="between">
                  <SectionHeader color="#686868" title="Other Related Courses" />
                </Box>
                <div>
                  <Row gutter={[8, 8]}>
                    {courseSearchResult &&
                      courseSearchResult?.items?.map(item => {
                        return (
                          <Col span={6}>
                            <CourseCard
                              key={item.courseId}
                              careerRelated
                              course="6"
                              price={item.coursePrice?.amount}
                              title={item.title}
                              description={item.description}
                              modules={item.modules}
                              duration="9h 45mins"
                              level={
                                item?.courseLevel === 1
                                  ? 'Beginner'
                                  : item?.courseLevel === 2
                                  ? 'Intermedate'
                                  : 'Advanced'
                              }
                              institutionLogo={
                                item.institutionLogo === null ? IcsanLogo : item.institutionLogo
                              }
                              institutionName={item.institution}
                              courseThumbNailUrl={item.courseThumbNailUrl}
                            />
                          </Col>
                        );
                      })}
                  </Row>
                </div>
                <Box justify="end" direction="row">
                  <Anchor
                    icon={<FormNext />}
                    label="See All Programs"
                    color="#0849ff"
                    href="#"
                    reverse
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default CourseCategoryLanding;
