// import React from 'react';
import { useSelector } from 'react-redux';

const useRole = () => {
  const { role } = useSelector(state => state.auth);

  /**
   * check if user has any of the role keys
   * @param {string | string[]} roleKeys
   * @returns {boolean}
   */
  const checkUserRole = roleKeys =>
    Array.isArray(roleKeys) ? roleKeys.some(key => role.includes(key)) : role.includes(roleKeys);

  return { checkUserRole };
};

export default useRole;
