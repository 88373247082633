import React from 'react';
import styled from 'styled-components';
import 'styles/utils.css';
import { Box, Text, Heading, ResponsiveContext, Image, Card, CardBody, CardFooter } from 'grommet';
import CourseImage from 'assets/images/coursesdis.png';
import { isMobile } from 'utils';
import { institutionlanding } from 'userRoutes';
import { useHistory } from 'react-router-dom';

const PartnersCard = ({ title, learner, course, courseThumbNailUrl, data, logoUrl }) => {
  const size = React.useContext(ResponsiveContext);
  const history = useHistory();

  const redirectToInstitutionLanding = async data => {
    try {
      history.push(institutionlanding, { data: data });
    } catch (error) {}
  };

  return (
    <Container
      width={isMobile(size) ? '85%' : '295px'}
      pad={{ vertical: 'medium' }}
      style={{ zIndex: 1, margin: isMobile(size) && 'auto' }}
      align="start">
      <Card className="course-card" elevation="large" width="350px">
        <CardBody style={{ position: 'relative', maxHeight: '10rem', minHeight: '10rem' }}>
          <Image
            fit="cover"
            src={logoUrl ? encodeURI(logoUrl) : CourseImage}
            onClick={() => null}
          />
        </CardBody>
        <BottomCard>
          <Box pad={{ horizontal: 'small' }} responsive={false}>
            <Box margin={{ vertical: 'small' }}>
              <Box>
                <NewHeading style={{ fontSize: 24 }} level="4" margin="none" onClick={() => null}>
                  {title}
                </NewHeading>
              </Box>
            </Box>
          </Box>
          <CardFooter background="none">
            <Box
              width="full"
              wrap={true}
              pad={{ horizontal: 'small', bottom: 'small' }}
              direction={isMobile(size) ? 'column' : 'row'}
              align={isMobile(size) ? 'start' : 'center'}
              justify="between">
              <Box margin={{ top: 'none' }} direction="column" align="start">
                {/* <Text style={{ fontSize: 12 }} color="#7e7e7e">
                {`${learner} ${learner > 1 ? 'Learners' : 'Learner'}`}
              </Text> */}
                <NewText style={{ fontSize: '1em' }} color="#7e7e7e">
                  {course} Courses
                </NewText>
              </Box>
            </Box>
          </CardFooter>
        </BottomCard>
      </Card>
    </Container>
  );
};

export default PartnersCard;

const BottomCard = styled.div``;
const NewHeading = styled(Heading)``;
const NewText = styled(Text)``;
const Container = styled(Box)`
  transition: all 0.3s ease-out;
  &:hover {
    transform: scale(1.05);
  }
  &:hover ${BottomCard} {
    background: #000;
  }
  &:hover ${NewHeading} {
    color: #fff;
    font-weight: bold;
  }
  &:hover ${NewText} {
    color: #fff;
    font-weight: bold;
  }
`;
