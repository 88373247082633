import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import MainLayout from 'components/MainLayout';
import AppText from 'components/common/AppText';
import updateImg from 'assets/images/Group 106.png';
import ExploreCourseCard from 'components/updateCourse/ExploreCourseCard';
import Statistics from 'components/updateCourse/Statistics';
import UpdateHistory from 'components/updateCourse/UpdateHistory';

const ExploreCourse2 = () => {
  const [courseDetail, setCourseDetail] = useState();
  const history = useHistory();

  useEffect(() => {
    setCourseDetail(history.location.state);
  }, [history.location.state, courseDetail]);
  return (
    <MainLayout isAdmin sidebar padded selectedKey="manageCourse">
      <Container>
        {courseDetail && <ExploreCourseCard courseDetail={courseDetail} />}
        <Statistics />
        <UpdateDiv>
          <TitleDiv>
            <img src={updateImg} style={{ alignSelf: 'center' }} />
            <div>
              <AppText value={'What’s New'} size="24px" color="#000" weight="600" bottom={'0'} />
              <AppText
                value={'see updates in the course module'}
                size="14px"
                color="#7F7F7F"
                bottom={'0'}
              />
            </div>
          </TitleDiv>
          <UpdateHistory courseId={history.location.state[0]?.courseId} />
        </UpdateDiv>
      </Container>
    </MainLayout>
  );
};
export default ExploreCourse2;

const Container = styled.div`
  margin: auto;
  margin-top: 1.2em;
  width: 98%;
  min-height: 20em;
  /* background: #fff; */
`;

const UpdateDiv = styled.div`
  width: 100%;
  background: #fff;
  min-height: 7rem;
  margin-top: 2rem;
`;

const TitleDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #dadada;
`;
