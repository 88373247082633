import React, { useState, useEffect } from 'react';
import MainLayout from 'components/MainLayout';
import { Typography, Col, Row, Space, Image } from 'antd';
import styled from 'styled-components';
import ProgramLandingCard from 'components/common/ProgramLandingCard';
import { useSelector } from 'react-redux';
import { jsPDF } from 'jspdf';
import { useMediaQuery } from 'react-responsive';

import { dashboard } from 'userRoutes';
import { useHistory } from 'react-router';
import { useQuery } from 'react-query';
import { generateCertificate } from 'api/queries/Institution';
import CustomButton from 'components/common/CustomButton';
import Loader from 'assets/images/Hourglass.gif';
import device from 'configs/responsive/mediaQueries';
import { saveAs } from 'utils';

const { mobile } = device;

const CourseCompleted = () => {
  const [certificateData, setCertificateData] = useState();

  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });
  const course = useSelector(state => state.course);
  const { userId } = useSelector(state => state.auth);
  const { data } = useQuery(
    ['certData', course.courseId, userId, course.institutionId],
    generateCertificate
  );
  const history = useHistory();

  console.log('certData', certificateData);

  useEffect(() => {
    if (data?.data) {
      // const blob = base64toBlob(data?.data);
      // const url = URL.createObjectURL(blob);
      // console.log(url);
      setCertificateData(data?.data);
    }
  }, [data?.data]);

  const limitCourseDescription = description => {
    const descriptionSubstring = description.substring(0, 85);

    return description.length > 50
      ? `${descriptionSubstring.slice(0, descriptionSubstring.lastIndexOf(' '))}...`
      : description;
  };

  const doc = new jsPDF({
    orientation: 'landscape'
  });

  const downloadAsPNG = () => saveAs(certificateData && certificateData, 'Certificate.png');
  const downloadAsPDF = () => {
    doc.addImage(certificateData && certificateData, 'JPEG', -1.5, 7, 300, 200);
    doc.save('Certificate.pdf');
  };

  return (
    <MainLayout sidebar={isMobileDevice && false} selectedKey="course">
      <Container>
        <CongratulationBox>
          <CongratulationText>Congratulations!</CongratulationText>
          <SubText>
            You have successfully completed all the requirement for the&nbsp;
            <strong>{course?.title}</strong>&nbsp; course by <strong>{course?.institution}</strong>.
          </SubText>
          <Row>
            <Space size="middle">
              <CustomButton
                onClick={() => {
                  history.push(dashboard);
                }}
                bgColor="#4d4d53"
                color="#fff"
                size="large"
                width={isMobileDevice && '5rem'}
                height={isMobileDevice && '2rem'}
                fontSize={isMobileDevice && '.8rem'}>
                Continue
              </CustomButton>
            </Space>
          </Row>
        </CongratulationBox>

        <Row gutter={!isMobileDevice && 26} style={{ marginBottom: '7em' }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 14 }}
            xl={{ span: 14 }}>
            {!isMobileDevice && (
              <ProgramLandingCard
                institutionName={course?.institution}
                isPrice
                logoTitle="Lagos Business School"
                logoSubtitle="Lagos Business School"
                title={course?.title}
                description={limitCourseDescription(course?.description)}
                modules={course?.courseModules?.length}
                weeks="9h 45mins"
                duration={course?.duration}
                level={course?.courseLevelName}
                category="All Levels"
                isCompleted
                courseThumbNailUrl={course?.courseThumbNailUrl}
              />
            )}

            <AdditionalNoteTitle level={3}>Completed Course Modules</AdditionalNoteTitle>
            <SubText>
              <ol>
                {course?.courseModules.map(el => (
                  <li>{el.title}</li>
                ))}
              </ol>
            </SubText>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 10 }}
            xl={{ span: 10 }}>
            {certificateData && <CertHeader>Your Course Certification</CertHeader>}
            {certificateData ? (
              <DownloadBtn download={'certificate.pdf'} href={certificateData}>
                Download Certificate
              </DownloadBtn>
            ) : (
              // <CertDiv certURL={thumbnail}></CertDiv>
              <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                <Typography.Paragraph>
                  <Image src={Loader} />
                </Typography.Paragraph>
                <Typography.Paragraph style={{ fontWeight: 'bold', color: '#0966bb' }}>
                  Loading Certificate...
                </Typography.Paragraph>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

const Container = styled(Col)`
  padding: 28px;
  background-color: #fff;
`;

const CongratulationBox = styled(Col)`
  padding: 28px;
  background-color: #f2f2f2;
  margin-bottom: 28px;
  @media ${mobile} {
    padding: 12px;
  }
`;

const CongratulationText = styled(Typography.Title)`
  color: #0966b8 !important;
  @media ${mobile} {
    font-size: 1em !important;
  }
`;

const AdditionalNoteTitle = styled(Typography.Title)`
  margin-top: 28px !important;
  @media ${mobile} {
    font-size: 1em !important;
  }
`;

const SubText = styled(Typography.Paragraph)`
  margin-bottom: 16px !important;
  font-size: 20px;
  @media ${mobile} {
    font-size: 0.7em !important;
  }
`;

const CertHeader = styled(Typography.Paragraph)`
  margin-top: 24px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem !important;
  @media ${mobile} {
    font-size: 1em !important;
  }
`;

const CertDiv = styled.div`
  background: url(${props => props.certURL});
  min-height: 22rem;
  width: 100%;
  min-width: 30rem;
  max-width: 40rem;
  background-position: center;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-origin: content-box;
  background-repeat: no-repeat;
  @media ${mobile} {
    background: url(${props => props.certURL}) no-repeat center center/cover;
    min-height: 10rem;
    min-width: 13rem;
    max-width: 13rem;
  }
`;
const BtnDiv = styled.div`
  height: 10%;
  margin-top: 1rem;
  @media ${mobile} {
    margin-top: 1rem;
  }
`;
const DownloadBtn = styled.a`
  color: #fff;
  background: #0966b8;
  display: inline-block;
  margin-top: 1rem;
  border-radius: 5px;
  border: 1px solid #0966b8;
  font-weight: normal;
  padding: 1rem;
  @media ${mobile} {
    margin-top: 0rem;
  }
`;

export default CourseCompleted;
