import React from 'react';
import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';
import BackgroundMainImg from 'assets/images/MicrosoftTeamsimage.png';
import SkillDormLogo from 'assets/images/skilldormwhitelogo.png';

const RegisterLayout = () => {
  return (
    <Row>
      <Col span={24}>
        <Container>
          <ContainerImage>
            <InnerWord>
              <ImageWrapper>
                <Image src={SkillDormLogo} alt="" />
              </ImageWrapper>
              <InnerText>
                Sign up today and explore exciting courses from top learning partners,
                <br />
                subject matter expert and professionals to provide you with an unparalled <br />{' '}
                access to quality inclusive learning
              </InnerText>
            </InnerWord>
          </ContainerImage>
        </Container>
      </Col>
    </Row>
  );
};

export default RegisterLayout;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
`;

const ContainerImage = styled.div`
  background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2)),
    url(${BackgroundMainImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  min-height: 100vh;
`;
const InnerWord = styled.div`
  width: 100%;
  min-height: 100vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 50px 70px 60px;
  letter-spacing: 1px;
`;
const InnerText = styled(Typography.Text)`
  margin-top: 30px;
  color: #fff;
  font-weight: 100;
  font-size: 14px;
  text-align: center;
`;
const ImageWrapper = styled.div`
  width: 400px;
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
`;
