import React, { useEffect, useState } from 'react';
import 'styles/utils.css';
import { Box, Button, ResponsiveContext, Text, Pagination, TextInput, Grid } from 'grommet';
import { Search } from 'grommet-icons';
import PageHeader from 'components/common/PageHeader';
import { useHistory } from 'react-router-dom';
import { animate, isMobile } from 'utils';
import ManageCardWrapper from 'components/common/ManageCardWrapper';
import ManageInstitutionModal from 'components/modals/ManageInstitutionModal';
import { useMutation } from 'react-query';
import { search } from 'api/mutations/Institution';
import { createinstitution } from 'adminRoutes';
import PageLoader from 'components/common/PageLoader';
import useIsMounted from 'components/hooks/useIsMounted';
import MainLayout from 'components/MainLayout';
import { useDebounce } from 'components/hooks/useDebounce';

const ManageInstitution = () => {
  const [institutionData, setInstitutionData] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [searchVal, setSearchVal] = useState(null);
  const [searchError, setSearchError] = useState(null);
  const [institutionUpdate, setInstitutionUpdate] = useState(false);
  const [open, setOpen] = useState();
  const [debounce] = useDebounce(searchVal, 500);
  const [page, setpage] = useState(1);
  const [pageSize] = useState(10);
  const [createMessageAnimate] = React.useState([
    animate({ type: 'fadeIn', delay: 500, duration: 500 }),
    animate({ type: 'slideUp', delay: 500, duration: 500 })
  ]);
  const history = useHistory();
  const size = React.useContext(ResponsiveContext);

  const [searchMutate, { isLoading }] = useMutation(search, {
    onSuccess: data => {
      const ress = data;
      setInstitutionData(ress);
      console.log();
    },
    onError: error => {
      if (error.message === 'Network Error') {
        setSearchError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        console.log(error);
        setSearchError(error.message);
        console.log(error.message);
        console.log(error.status);
      }
    }
  });

  const searchInstitution = async searchVal => {
    getAllInstitution(searchVal, 1, pageSize);
    setSearchVal(searchVal);
  };

  const getAllInstitution = async (searchVal, page, pageSize, id, isActive) => {
    try {
      await searchMutate({ searchVal, page, pageSize, id, isActive });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAllInstitution(searchVal ? searchVal : '', page, pageSize);
    setInstitutionUpdate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [institutionUpdate, page]);

  const onPageChanged = currentPage => {
    setpage(currentPage.page);
    console.log(currentPage.page);
  };

  return (
    <MainLayout isAdmin sidebar padded={1} selectedKey="institution">
      <Box pad="medium" background="#fff">
        <>
          <PageHeader pageTitle="Manage Partners" />
        </>
        <Box margin={{ top: 'large' }} gap={isMobile(size) ? 'large' : 'small'}>
          {searchError && (
            <Box margin={{ bottom: 'medium' }} animation={createMessageAnimate[0]}>
              <Box animation={createMessageAnimate[1]}>
                <Text color="status-critical">{searchError}</Text>
              </Box>
            </Box>
          )}

          <Box direction={isMobile(size) ? 'column' : 'row'} justify={'between'}>
            <Box width="20%"></Box>
            {/* <Box
              direction={isMobile(size) ? 'column' : 'row'}
              align="center"
              gap={isMobile(size) ? 'medium' : '210px'}>
            
            </Box> */}
            <Box
              margin={{ bottom: isMobile(size) ? 'medium' : '0px' }}
              width="medium"
              direction="row"
              align="center"
              pad={{ horizontal: 'small', vertical: 'xsmall' }}
              round="xsmall"
              elevation="small">
              <Search color="brand" />
              <TextInput
                type="search"
                plain
                // value={searchVal}
                onChange={e => searchInstitution(e.target.value)}
                placeholder="Search Name..."
              />
            </Box>

            <Box>
              <Button
                size={isMobile(size) ? 'medium' : 'large'}
                label="Add Partner"
                primary
                onClick={() => history.push(createinstitution)}
              />
            </Box>
          </Box>

          <Box margin={{ top: 'medium' }}>
            <Grid columns={!isMobile(size) ? 'medium' : '100%'} gap="medium">
              {institutionData &&
                institutionData.map(data => (
                  <ManageCardWrapper
                    key={data.id}
                    img={data.logoUrl}
                    title={data.name}
                    address={data.address}
                    isActive={data.isActive}
                    mobile={data.phoneNumber}
                    clickFunc={() => {
                      setModalData(data);
                      setOpen(true);
                    }}
                  />
                ))}
            </Grid>
          </Box>
          {institutionData && (
            <Pagination
              step={pageSize}
              page={page}
              numberItems={institutionData && Number(institutionData[0]?.totalRows)}
              onChange={onPageChanged}
            />
          )}
        </Box>
        {open && (
          <ManageInstitutionModal
            setInstitutionUpdate={setInstitutionUpdate}
            data={modalData}
            onClose={() => setOpen(false)}
          />
        )}
        <PageLoader isLoading={isLoading} tip="Fetching Data" />
      </Box>
    </MainLayout>
  );
};

export default ManageInstitution;
