import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import AppText from 'components/common/AppText';
import timesIcon from 'assets/svgs/times.svg';
import { getId, hasId } from '../editSurvey/EditSurveyForm';

const getOptionNumber = prev => (prev.length === 0 ? 0 : prev[prev.length - 1].value);
// const getRandomId = isEdit => (isEdit ? randomInteger(3000, 10000) : uuidv4());
const ratingToArray = option => {
  let ratingArr = [];
  for (let i = option[0].minValue; i <= option[0].maxValue; i++) {
    ratingArr.push({ value: i, id: uuidv4() });
  }
  return ratingArr;
};

const AddRating = ({ setQuestions, questionId, question, isEdit, option }) => {
  const optionVal =
    option && option.length !== 0
      ? option
      : isEdit
      ? [{ option: '', minValue: 0, maxValue: 3 }]
      : [{ option: '', minValue: 0, maxValue: 3 }];
  // const surveyQuestionId = isEdit ? 'surveyQuestionId' : 'id';
  // const surveyQuestionOptionId = isEdit ? 'surveyQuestionOptionId' : 'id';
  const [optionData, setOptionData] = useState(optionVal);
  const [options, setOption] = useState(ratingToArray(optionVal));

  useEffect(() => {
    setQuestions(prev => {
      const question = prev.find(el => el[getId(el)] === questionId);
      prev[prev.indexOf(question)] = {
        ...question,
        options: optionData ? optionData : null
      };
      return [...prev];
    });
    // console.log('optionData', optionData);
  }, [optionData]);

  useEffect(() => {
    setOptionData(prev => [
      {
        option: '',
        minValue: options?.[0]?.value,
        maxValue: options?.[options?.length - 1]?.value,
        surveyQuestionId: isEdit && hasId(question) ? questionId : 0,
        surveyQuestionOptionId:
          isEdit && prev.surveyQuestionOptionId ? prev.surveyQuestionOptionId : 0
      }
    ]);
  }, [options]);

  const handleClick = () => {
    setOption(prev => {
      return [...prev, { value: getOptionNumber(prev) + 1, id: uuidv4() }];
    });
  };

  const handleDelete = id => {
    setOption(prev => prev.filter(option => option.id !== id));
  };

  return (
    <Container>
      {options.map((el, index) => (
        <OptionDiv>
          <RangeDiv>
            <AppText value={el.value} color="#ABABAB" bottom="0" size="14px" />
          </RangeDiv>
          <Img src={timesIcon} onClick={() => handleDelete(el.id)} />
        </OptionDiv>
      ))}
      <OptionDiv style={{ cursor: 'pointer' }} onClick={handleClick}>
        <RangeDiv style={{ background: '#B9B9B9', border: '0.5px solid #B9B9B9' }}>
          <AppText value="+" color="#000" bottom="0" size="14px" />
        </RangeDiv>
        <AppText value="Add box" color="#B9B9B9" bottom="0" size={'14px'} />
      </OptionDiv>
    </Container>
  );
};

export default AddRating;

const Container = styled.div`
  margin-top: 1rem;
  gap: 2rem;
  display: flex;
  width: 100%;
  flex-flow: row wrap;
`;
const OptionDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const RangeDiv = styled.div`
  width: 29px;
  height: 29px;
  border: 1px solid #5d5d5d;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  align-self: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    transform: scale(1.2);
  }
`;
