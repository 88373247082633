const size = {
  // mobileS: '320px',
  // mobileM: '375px',
  mobile: '480px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
};

const device = {
  // mobileS: `(m-width: ${size.mobileS})`,
  // mobileM: `(min-width: ${size.mobileM})`,
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};

export default device;
