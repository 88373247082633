import React, { createRef, useState } from 'react';
import 'styles/utils.css';
import UserLayout from 'components/layouts/UserLayout';
import {
  Box,
  ResponsiveContext,
  Heading,
  Paragraph,
  TextInput,
  ThemeContext,
  Button,
  Anchor,
  Carousel,
  Grid
} from 'grommet';
import CourseCard from 'components/common/CourseCard';
import InstitutionProgramCard from 'components/common/InstitutionProgramCard';
import IconWrapper from 'components/common/IconWrapper';
import CourseImage from 'assets/images/coursesdis.png';
import Next from 'assets/images/greaterthan.png';
import Previous from 'assets/images/lessthan.png';
import IcsanLogo from 'assets/images/icsan.png';
import { isMobile } from 'utils';
import SectionHeader from 'components/utils/CourseDiscovery/SectionHeader';
import { Search } from 'grommet-icons';
import { FormNext } from 'grommet-icons';
import { FormPrevious } from 'grommet-icons';
import PartnerImg from 'assets/images/partner.png';
import styled from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import { getUserInfoByInstitutionId } from 'api/queries/Authentication';
import { courseCategoryList, getSummaryByCatgory } from 'api/queries/Course';
import { courseSearch, courseLanguageSearch } from 'api/mutations/Course';
import { search } from 'api/mutations/Institution';
import { Carousel as AntCarousel } from 'antd';
import MainLayout from 'components/MainLayout';
import { useSelector } from 'react-redux';

const CategoryNextIcon = styled(FormNext)`
  padding: 2px;
  border-radius: 100%;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  border: 1px solid #0966b869;
  position: absolute;
  right: -18px;
  top: -200px;
  z-index: 3;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 4px 8px 0 rgb(0 0 0 / 20%);
  cursor: pointer;
  height: 36px;
  width: 36px;
`;

const CategoryPrevIcon = styled(FormPrevious)`
  padding: 2px;
  border-radius: 100%;
  display: flex;
  background: #fff;
  left: -38px;
  top: -200px;
  align-items: center;
  justify-content: center;
  border: 1px solid #0966b869;
  position: absolute;
  z-index: 3;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 4px 8px 0 rgb(0 0 0 / 20%);
  cursor: pointer;
  height: 36px;
  width: 36px;
`;

const NextPosition = styled.div`
  position: absolute;
  top: -26px;
  right: 10px;
`;

const PreviousPosition = styled.div`
  position: absolute;
  top: -26px;
  right: 50px;
`;

const Programs = () => {
  const size = React.useContext(ResponsiveContext);
  const [institutionProgramCard, setInstitutionProgramCard] = useState();
  const [courses] = React.useState([1, 2, 3]);
  const [scrolled, setScrolled] = React.useState(false);
  const [searchBar, setSearchBar] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [courseSearchResult, setCourseSearchResult] = React.useState(null);
  const [institutionSearchResult, setInstitutionSearchResult] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [createError, setCreateError] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { userId } = useSelector(state => state.auth);
  const handleScroll = () => {
    var offset = window.scrollY;
    if (offset > 0 && !userId) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
    if (offset > 271) {
      setSearchBar(true);
    } else {
      setSearchBar(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const NextButton = () => {
    return (
      <NextPosition>
        <IconWrapper icon={Next} width="20px" height="20px" />
      </NextPosition>
    );
  };
  const PreviousButton = () => {
    return (
      <PreviousPosition>
        <IconWrapper icon={Previous} width="20px" height="20px" />
      </PreviousPosition>
    );
  };

  const { data: summaryByCatgory } = useQuery(['summaryByCatgory'], getSummaryByCatgory);
  //console.log('summaryByCatgory', summaryByCatgory)

  const [createMutate, info] = useMutation(courseSearch, {
    onSuccess: dataResult => {
      const { description, data, success } = dataResult;
      if (success) {
        setCourseSearchResult(data);
        setSuccessMessage(description);
      } else {
        setCreateError(description);
        setIsSubmitting(false);
        console.log(description);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setCreateError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        // setCreateError(error.message);
        console.log(error);
      }
    }
  });

  const searchResult = async e => {
    setValue(e.target.value);
    const param = {
      page: 1,
      pageSize: 50,
      courseCategoryIds: [],
      parameter: value,
      institutionIds: [],
      courseLevels: [],
      languageIds: []
    };
    createMutate(param);
  };

  const [createInstitutionMutate] = useMutation(search, {
    onSuccess: dataResult => {
      // const { description, success } = dataResult;

      setInstitutionSearchResult(
        dataResult.reduce((acc, cur, index) => {
          if (index % 4 === 0) {
            acc.push([cur]);
            return acc;
          } else {
            let arr = acc.pop();
            acc.push([...arr, cur]);
            return acc;
          }
        }, [])
      );
      // if (success) {
      //   setInstitutionSearchResult(dataResult);
      //   setSuccessMessage(description);
      // } else {
      //   setCreateError(description);
      //   setIsSubmitting(false);
      // }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setCreateError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        // setCreateError(error.message);
        console.log(error);
      }
    }
  });

  React.useEffect(() => {
    try {
      const search = () => {
        const param = {
          page: 1,
          pageSize: 50,
          courseCategoryIds: [],
          parameter: '',
          institutionIds: [],
          courseLevels: [],
          languageIds: []
        };
        createMutate(param);
      };
      search();

      const instituionSearch = () => {
        const param = {
          searchVal: ''
        };
        createInstitutionMutate(param);
      };
      instituionSearch();
    } catch (error) {}
  }, [createInstitutionMutate, createMutate]);

  //ant carousel implementation
  const carousel = createRef();
  const handleNext = () => carousel.current.next();

  const handlePrev = () => carousel.current.prev();

  return (
    <MainLayout
      background={userId ? '#fff' : scrolled ? 'black' : 'transparent'}
      elevation={scrolled ? 1 : 0}
      selectedKey={'dashboard'}
      padded={0}
      noMargin={!userId ? 1 : 0}
      sidebar={false}>
      <Box
        justify="end"
        height="280px"
        pad={{ top: 'large', bottom: 'small', right: 'medium', left: 'none' }}
        background={{
          dark: true,
          position: '50% 0%',
          repeat: 'no-repeat',
          size: 'cover',
          image: `linear-gradient(to right, rgba(0,0,0,1),rgba(0,0,0,1), rgba(0,0,0,.1) ), url(${PartnerImg})`
        }}>
        <Box pad="large">
          <Heading color="light-1" style={{ wordSpacing: 5 }} level={3} margin={{ bottom: 'none' }}>
            Programs
          </Heading>

          <Paragraph margin={{ top: 'none' }} fill style={{ fontSize: 'small', color: '#fff' }}>
            Explore a wide array of career development programs from all partners
          </Paragraph>
        </Box>
      </Box>
      <Box pad="large" background="#fff">
        <Box gap={isMobile(size) ? 'large' : 'small'}>
          <Box direction="row" gap="medium" justify="between">
            <Box width="100%">
              <Box direction="row" align="center" margin={{ vertical: 'xsmall' }}>
                <Box
                  // width="small"
                  border={{
                    color: 'border',
                    size: '1px',
                    style: 'solid',
                    side: 'all'
                  }}
                  direction="row"
                  align="center"
                  pad={{ right: 'small' }}
                  round="large"
                  style={{
                    width: isMobile(size) ? '100%' : 400,
                    background: '#fff',
                    color: '#000'
                  }}>
                  <TextInput
                    plain
                    placeholder="What would you like to learn ?"
                    onChange={event => searchResult(event)}
                  />
                  {/* <Search color="#0849ff" /> */}
                </Box>
                <Box>
                  <Button
                    margin={{ left: 'medium' }}
                    label="Search"
                    primary
                    size="medium"
                    style={{ borderRadius: '20px' }}
                  />
                </Box>
              </Box>
              <Box margin={{ top: 'medium' }}>
                <Box
                  direction={isMobile(size) ? 'column' : 'row'}
                  gap="medium"
                  align={isMobile(size) ? 'stretch' : 'center'}
                  justify="between">
                  <SectionHeader
                    color="#686868"
                    title="Programs from Top Partners"
                    marginTop="none"
                  />
                </Box>

                <Grid gap="medium" columns={{ count: 'fit', size: 'small' }}>
                  {courseSearchResult?.items &&
                    courseSearchResult?.items.slice(2, 6).map(item => {
                      return (
                        <CourseCard
                          key={item}
                          careerRelated
                          course="6"
                          price={item.coursePrice?.amount}
                          title={item.title}
                          description={item.description}
                          modules={item.modules}
                          duration="9h 45mins"
                          level={
                            item?.courseLevel === 1
                              ? 'Beginner'
                              : item?.courseLevel === 2
                              ? 'Intermedate'
                              : 'Advanced'
                          }
                          institutionLogo={
                            item.institutionLogo === null ? IcsanLogo : item.institutionLogo
                          }
                          institutionName={item.institution}
                          courseThumbNailUrl={item.courseThumbNailUrl}
                        />
                      );
                    })}
                </Grid>
                <Box justify="end" direction="row">
                  <Anchor
                    icon={<FormNext />}
                    label="See All Programs"
                    color="#0849ff"
                    href="#"
                    reverse
                  />
                </Box>
              </Box>
              <Box margin={{ top: 'medium' }}>
                <Box
                  direction={isMobile(size) ? 'column' : 'row'}
                  gap="medium"
                  align={isMobile(size) ? 'stretch' : 'center'}
                  justify="between">
                  <SectionHeader color="#686868" title="Career Related Programs" marginTop="none" />
                </Box>
                <ThemeContext.Extend
                  value={{
                    carousel: {
                      animation: {
                        duration: 400
                      },
                      icons: {
                        color: 'blue',
                        next: NextButton,

                        previous: PreviousButton
                      },
                      disabled: {
                        icons: {
                          color: 'grey'
                        }
                      }
                    }
                  }}>
                  <Carousel controls={isMobile(size) ? false : 'arrows'}>
                    {courseSearchResult?.items.map(item => (
                      <Box
                        key={item.courseId}
                        direction={isMobile(size) ? 'column' : 'row'}
                        margin={{ top: 'medium' }}
                        justify="between">
                        {courseSearchResult &&
                          courseSearchResult?.items.slice(2, 6).map(item => {
                            return (
                              <CourseCard
                                key={item.courseId}
                                careerRelated
                                course="6"
                                price={item.coursePrice?.amount}
                                title={item.title}
                                description={item.description}
                                modules={item.modules}
                                duration="9h 45mins"
                                level={
                                  item?.courseLevel === 1
                                    ? 'Beginner'
                                    : item?.courseLevel === 2
                                    ? 'Intermedate'
                                    : 'Advanced'
                                }
                                institutionLogo={
                                  item.institutionLogo === null ? IcsanLogo : item.institutionLogo
                                }
                                institutionName={item.institution}
                                courseThumbNailUrl={item.courseThumbNailUrl}
                              />
                            );
                          })}
                      </Box>
                    ))}
                  </Carousel>
                </ThemeContext.Extend>
              </Box>
              <Box>
                <Box
                  direction={isMobile(size) ? 'column' : 'row'}
                  gap="medium"
                  align={isMobile(size) ? 'stretch' : 'center'}
                  justify="between">
                  <SectionHeader color="#686868" title="Programs by Partner" />
                </Box>
                <AntCarousel ref={carousel} dots={false}>
                  {institutionSearchResult &&
                    institutionSearchResult.map(data => (
                      <div
                        style={{ display: 'flex', backgroundColor: 'red' }}
                        // direction='row'
                        // margin={{ top: 'medium' }}
                        // gap="small"
                      >
                        {data.map(item => {
                          return (
                            <InstitutionProgramCard
                              key={item}
                              price={8500}
                              title={item.name}
                              description="Explore the Fundamental of Data Anallytics and Data Visualization with end to end data
                             encryption and Protection"
                              modules={item}
                              duration="9h 45mins"
                              level="Beginner"
                              learner={item.students}
                              institutionLogo={item.logo ? item.logo : IcsanLogo}
                              institutionName={'Institute of Chartered Secretaries Admin'}
                              course={item.courses}
                            />
                          );
                        })}
                      </div>
                    ))}
                </AntCarousel>
                <div style={{ position: 'relative' }}>
                  <CategoryPrevIcon color="#0966b8" onClick={handlePrev}>
                    Previous
                  </CategoryPrevIcon>
                  <CategoryNextIcon color="#0966b8" onClick={handleNext}>
                    Next
                  </CategoryNextIcon>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default Programs;
