import IconWrapper from 'components/common/IconWrapper';
import { Box, ResponsiveContext, Text } from 'grommet';
import React from 'react';
import { isMobile } from 'utils';
import VideoIcon from 'assets/svgs/ic_video.svg';
import TimeIcon from 'assets/svgs/ic_time.svg';
// import TuneIcon from 'assets/svgs/ic_tune.svg';
import SchoolIcon from 'assets/svgs/ic_school.svg';
import ModuleIcon from 'assets/svgs/ic_module.svg';
import AccessIcon from 'assets/svgs/access.svg';
import InstituteIcon from 'assets/svgs/institute.svg';
// import MobileIcon from 'assets/svgs/ic_mobile.svg';
import AwardIcon from 'assets/svgs/ic_award.svg';
// import BadgeIcon from 'assets/svgs/ic_badge.svg';
// import IconRuler from 'assets/images/iconruler.png';

const CourseLandingDetails = ({ details }) => {
  const size = React.useContext(ResponsiveContext);
  return (
    <Box width="full" fill={isMobile(size)} margin={{ left: '1.5rem' }}>
      {/* <Heading level="4" margin="none">
        Course Details
      </Heading> */}
      {details.map((item, i) => {
        if (item.key === 'paced') {
          return (
            <Box key={i} direction="row" align="center" justify="between" pad="small">
              <Box key={i} direction="row" align="center" gap="medium">
                <IconWrapper
                  icon={VideoIcon}
                  style={{ filter: 'grayscale(100%)' }}
                  height="16px"
                  width="16px"
                />
                <Text size="16px">{item.value}</Text>
              </Box>
              {/* <Text size="16px" style={{ textAlign: 'right' }}>
                {item.value}
              </Text> */}
            </Box>
          );
        } else if (item.key === 'Modules') {
          return (
            <Box key={i} direction="row" align="center" justify="between" pad="small">
              <Box key={i} direction="row" align="center" gap="medium">
                <IconWrapper
                  icon={ModuleIcon}
                  style={{ filter: 'grayscale(100%)' }}
                  height="16px"
                  width="16px"
                />
                <Text size="14px" style={{ textAlign: 'right' }}>
                  {item.value} {item.value > 1 ? ' Modules' : ' Module'}
                </Text>
              </Box>
              {/* <Text size="14px" style={{ textAlign: 'right' }}>
                {item.value} {item.value > 1 ? ' Modules' : ' Module'}
              </Text> */}
            </Box>
          );
        } else if (item.key === 'Level') {
          return (
            <Box key={i} direction="row" align="center" justify="between" pad="small">
              <Box key={i} direction="row" align="center" gap="medium">
                <IconWrapper
                  icon={SchoolIcon}
                  style={{ filter: 'grayscale(100%)' }}
                  height="16px"
                  width="16px"
                />
                <Text size="14px">{item.value} Level</Text>
              </Box>
              {/* <Text size="14px" style={{ textAlign: 'right' }}>
                {item.value}
              </Text> */}
            </Box>
          );
        } else if (item.key === 'access') {
          return (
            <Box key={i} direction="row" align="center" justify="between" pad="small">
              <Box key={i} direction="row" align="center" gap="medium">
                <IconWrapper
                  icon={AccessIcon}
                  style={{ filter: 'grayscale(100%)' }}
                  height="17px"
                  width="12px"
                />
                <Text size="14px">{item.value}</Text>
              </Box>
              {/* <Text size="14px" style={{ textAlign: 'right' }}>
                {item.value}
              </Text> */}
            </Box>
          );
        } else if (item.key === 'Offered by') {
          return (
            <Box key={i} direction="row" align="center" justify="between" pad="small">
              <Box key={i} direction="row" align="center" gap="medium">
                <IconWrapper
                  icon={InstituteIcon}
                  style={{ filter: 'grayscale(100%)' }}
                  height="16px"
                  width="16px"
                />
                <Text size="14px"> {item.value}</Text>
              </Box>
              {/* <Text size="14px" style={{ textAlign: 'right' }}>
                {item.value}
              </Text> */}
            </Box>
          );
        } else if (item.key === 'duration') {
          return (
            <Box key={i} direction="row" align="center" justify="between" pad="small">
              <Box key={i} direction="row" align="center" gap="medium">
                <IconWrapper
                  icon={TimeIcon}
                  style={{ filter: 'grayscale(100%)' }}
                  height="16px"
                  width="16px"
                />
                <Text size="14px">{item.value}</Text>
              </Box>
              {/* <Text size="14px" style={{ textAlign: 'right' }}>
                {item.value}
              </Text> */}
            </Box>
          );
        } else if (item.key === 'Certification' && item.value) {
          return (
            <Box key={i} direction="row" align="center" justify="between" pad="small">
              <Box key={i} direction="row" align="center" gap="medium">
                <IconWrapper
                  style={{ filter: 'grayscale(100%)' }}
                  icon={AwardIcon}
                  height="16px"
                  width="16px"
                />
                <Text size="14px">{item.value}</Text>
              </Box>
              {/* <Text size="14px" style={{ textAlign: 'right' }}>
                {item.value}
              </Text> */}
            </Box>
          );
        } else return null;
      })}
    </Box>
  );
};

export default CourseLandingDetails;
