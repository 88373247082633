import React from 'react';
import styled from 'styled-components';
import { Typography, Button, Input, Row, Col } from 'antd';
const { Title } = Typography;

const FirstRow = styled(Row)`
  background: #0966b8;
  padding: 3rem 3rem 3rem 3rem;
`;

const SubscribeTitle = styled(Title)`
  color: #fff !important;
  margin: 0 0 0 0 !important;
`;

const InputCol = styled(Col)`
  padding-top: 0.5rem;
`;

const SubscribeBtn = styled(Button)`
  height: 2.3rem;
  width: 100%;
  display: block !important;
  color: #fff;
  background: #212121;
  border: 1px solid #212121;
`;
const SubscribeInput = styled(Input)`
  display: block !important;
  width: 90%;
  height: 2.3rem;
  &::placeholder {
    color: #7f7f7f;
  }
`;

const SubscribeSection = props => (
  <FirstRow>
    <Col span={14}>
      <SubscribeTitle>Subscribe to our weekly newsletter</SubscribeTitle>
    </Col>
    <InputCol span={10}>
      <Row>
        <Col span={14}>
          <SubscribeInput placeholder="Enter your email to subscribe" />
        </Col>
        <Col span={10}>
          <SubscribeBtn>Subscribe</SubscribeBtn>
        </Col>
      </Row>
    </InputCol>
  </FirstRow>
);

export default SubscribeSection;
