import React from 'react';
import styled from 'styled-components';
import SkillDormLogo from 'assets/images/skilldormblacklogo.png';

const SmeVilleHeader = () => {
  return (
    <Container>
      <CustomImage src={SkillDormLogo} alt="" />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(128, 129, 131, 0.46);
  align-items: center;
  height: 113px;
`;
const CustomImage = styled.img``;

export default SmeVilleHeader;
