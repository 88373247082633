import { combineReducers } from 'redux';
import courseReducer from 'store/reducers/courseReducer';
import toastReducer from 'store/reducers/toastReducer';
import authReducer from './authReducer';
import cbtReducer from 'store/reducers/cbtReducer';
import videoReducer from './videoReducer';
import discountReducer from './discountReducer';
import emailReducer from './email';
import inAppNotifyReducer from './inAppNotifyReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  toast: toastReducer,
  course: courseReducer,
  cbt: cbtReducer,
  video: videoReducer,
  email: emailReducer,
  discount: discountReducer,
  inAppNotify: inAppNotifyReducer
});

export default rootReducer;
