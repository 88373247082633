import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';

import { ButtonCTA } from 'components/common/ButtonCTA';
import { showToast } from 'store/actions/toast';
import { SurveyContext } from 'context/SurveyContext';
import { toggleActivateSurvey, getCourseSurveyList } from 'api/queries/Course';
import NoSurvey from './NoSurvey';
import PageLoader from 'components/common/PageLoader';

const SurveyTable = ({ setCreateSurvey, setAssignSurvey, isDraft }) => {
  const [isLoading, setIsLoading] = useState();
  const { userName } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { setIsEdit, setSurveyId, setSurveyTitle } = useContext(SurveyContext);

  const { data, isLoading: dataLoading, refetch } = useQuery(
    ['surveyList', isDraft],
    getCourseSurveyList
  );

  const toggleActivate = (surveyId, isActive) => {
    toggleActivateSurvey(surveyId).then(data => {
      setIsLoading(false);
      dispatch(
        showToast('success', `Survey ${isActive ? 'Deactivated' : 'Activated'} successfully`)
      );
      refetch();
    });
  };

  const handleEdit = surveyId => {
    setIsEdit(true);
    setSurveyId(surveyId);
    setCreateSurvey(true);
  };

  const handleAssign = (surveyId, title) => {
    setSurveyId(surveyId);
    setSurveyTitle(title);
    setAssignSurvey(true);
  };

  const handleDeactivate = (surveyId, isActive) => {
    setIsLoading(true);
    toggleActivate(surveyId, isActive);
  };
  return (
    <>
      <PageLoader isLoading={isLoading || dataLoading} />
      {data?.data ? (
        <Table>
          <Tr>
            <th>S/N</th>
            <th>Title</th>
            <th>Date Created</th>
            <th>Created by</th>
            <th>Responses</th>
            <th>Action</th>
          </Tr>

          {data?.data
            ?.sort((a, b) => b.surveyId - a.surveyId)
            .map((el, index) => (
              <Tr>
                <td>{index + 1}</td>
                <td>{el.title}</td>
                <td>{el.date}</td>
                <td>{userName}</td>
                <td>
                  <ButtonCTA disabled borderRadius="25px">
                    view responses
                  </ButtonCTA>
                </td>
                <td>
                  <ActionDiv>
                    <ButtonCTA
                      color="#091452"
                      padding="0 0"
                      bgColor="#E9EEFA"
                      borderRadius="4px"
                      width="36px"
                      fontSize="8px"
                      onClick={() => handleEdit(el.surveyId)}
                      height="22px">
                      Edit
                    </ButtonCTA>
                    {!isDraft && el.isActive && (
                      <ButtonCTA
                        color="#0966B8"
                        padding="0 0"
                        bgColor="#DBEEFF"
                        borderRadius="4px"
                        width="36px"
                        fontSize="8px"
                        onClick={() => handleAssign(el.surveyId, el.title)}
                        height="22px">
                        Assign
                      </ButtonCTA>
                    )}
                    <ButtonCTA
                      color={el.isActive ? '#FC391D' : '#4ECB71'}
                      padding="0 0"
                      bgColor={el.isActive ? '#FFE8E4' : '#DFFDE8'}
                      borderRadius="4px"
                      width="56px"
                      fontSize="8px"
                      onClick={() => handleDeactivate(el.surveyId, el?.isActive)}
                      height="22px">
                      {el.isActive ? 'Deactivate' : 'Activate'}
                    </ButtonCTA>
                  </ActionDiv>
                </td>
              </Tr>
            ))}
        </Table>
      ) : (
        <NoSurvey setCreateSurvey={setCreateSurvey} />
      )}
    </>
  );
};

export default SurveyTable;

const ActionDiv = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const Img = styled.img`
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const Table = styled.table`
  width: 100%;
`;

const Tr = styled.tr`
  height: 46px;
  &:nth-child(even) {
    background: rgba(243, 250, 255, 0.8);
  }
`;
