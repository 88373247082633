import Axios from 'axios';

const service = 'ContentLibraryBE';

export const uploadMultipleFileFromLocal = async (userName, files) => {
  const formData = new FormData();
  formData.append('UserName', userName);
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  try {
    const { data } = await Axios.post(
      `/${service}/api/FileManager/UploadMultipleFileFromLocal`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        baseURL: window['configs'].contentLibraryBaseURL
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteFile = async fileId => {
  try {
    const { data } = await Axios.delete(`/${service}/api/FileManager/DeleteFile?fileId=${fileId}`, {
      baseURL: window['configs'].contentLibraryBaseURL
    });
    return data;
  } catch (error) {
    throw error;
  }
};
