import React from 'react';
import { Row, Col, Typography } from 'antd';
import styled from 'styled-components';
import cancelIcon from 'assets/images/cancel.png';
import { useDispatch } from 'react-redux';
import { studentInteraction } from 'store/actions/inAppNotify';
import htmr from 'htmr';
import AppData from 'AppData';

const MaintenanceNotification = ({ setIsMaintenanceShowing, data }) => {
  const { inAppNotificationCategoryName, stripMessage } = data;
  const dispatch = useDispatch();
  const hideNotification = () => {
    setIsMaintenanceShowing(false);
    dispatch(studentInteraction(false));
  };

  return (
    <Row>
      <NotificationContainer
        bgcolor={inAppNotificationCategoryName === AppData.MAINTANANCE ? '#fe7500' : '#0966b8'}
        span={24}>
        <TextWrapper>
          <NotificationText
            color={inAppNotificationCategoryName === AppData.MAINTANANCE ? '#000000' : '#ffffff'}>
            {stripMessage ? htmr(stripMessage) : 'SKillDorm Team'}
          </NotificationText>
        </TextWrapper>
        <CloseIcon src={cancelIcon} alt="close-icon" onClick={hideNotification} />
      </NotificationContainer>
    </Row>
  );
};

const CloseIcon = styled.img`
  cursor: pointer;
  margin-left: 1rem;
  z-index: 10;
`;

const NotificationText = styled(Typography.Text)`
  color: ${({ color }) => (color ? color : '#fff')};
  font-weight: 600;
  text-align: center;
`;
const TextWrapper = styled.div`
  flex: 1;
`;

const NotificationContainer = styled(Col)`
  background: ${({ bgcolor }) => (bgcolor ? bgcolor : '#487321')};
  width: 100%;
  height: 73px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
`;

export default MaintenanceNotification;
