import * as courseTypes from 'store/actions/course';
import produce from 'immer';

const initialState = {
  courseModules: [],
  headLine: null,
  languageId: null,
  objectives: null,
  audience: null,
  effort: null,
  approvalStatus: 0,
  coursePromotionalVideoUrl: null,
  promote: false,
  overView: null,
  courseBannerUrl: null,
  instructors: [],
  courseInstructors: [],
  courseId: 0,
  title: null,
  courseCategoryId: 0,
  courseType: 0,
  courseTypeName: '',
  description: null,
  startDate: null,
  endDate: null,
  institutionId: 0,
  institution: null,
  institutionLogo: null,
  courseCategoryName: null,
  modules: 0,
  draft: true,
  courseThumbNailUrl: null,
  duration: null,
  prerequisite: null,
  courseLevel: 0,
  courseLevelName: null,
  stepNo: 0,
  isPaid: false,
  hasSurvey: true,
  surveyId: 0,
  coursePrice: null,
  instructorName: ''
};

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case courseTypes.CLEAR_COURSE:
      return initialState;
    case courseTypes.CLEAR_COURSE_MODULES:
      return {
        ...state,
        courseModules: []
      };
    case courseTypes.BASIC_COURSE:
      return {
        ...state,
        institutionId: action.data.institutionId,
        title: action.data.title,
        description: action.data.description,
        courseCategoryId: action.data.courseCategoryId,
        courseType: action.data.courseType,
        courseTypeName: action.data.courseTypeName,
        startDate: action.data.startDate,
        endDate: action.data.endDate,
        stepNo: action.data.stepNo,
        isPaid: action.data.isPaid,
        hasSurvey: action.data.hasSurvey
      };
    case courseTypes.COURSE_BUILDER:
      return produce(state, draft => {
        draft.courseModules = action.data.courseModules;
        draft.stepNo = action.data.stepNo;
      });
    case courseTypes.COURSE_PRICING:
      return {
        ...state,
        coursePrice: action.data.coursePrice
      };
    case courseTypes.COURSE_ADDITIONAL_INFO:
      return {
        ...state,
        courseId: action.data.courseId,
        institutionId: action.data.institutionId,
        headLine: action.data.headLine,
        overView: action.data.overView,
        courseLevel: action.data.courseLevel,
        languageId: action.data.languageId,
        objectives: action.data.objectives,
        effort: action.data.effort,
        instructorName: action.data.instructorName,
        courseThumbNailUrl: action.data.courseThumbNailUrl,
        courseBannerUrl: action.data.courseBannerUrl,
        coursePromotionalVideoUrl: action.data.coursePromotionalVideoUrl,
        promote: action.data.promote,
        stepNo: action.data.stepNo
      };
    case courseTypes.COURSE_DETAILS:
      return {
        ...state,
        courseModules: action.data.courseModules,
        headLine: action.data.headLine,
        languageId: action.data.languageId,
        objectives: action.data.objectives,
        audience: action.data.audience,
        effort: action.data.effort,
        approvalStatus: action.data.approvalStatus,
        coursePromotionalVideoUrl: action.data.coursePromotionalVideoUrl,
        promote: action.data.promote,
        overView: action.data.overView,
        courseBannerUrl: action.data.courseBannerUrl,
        instructors: action.data.instructors,
        instructorName: action.data.instructorName,
        courseInstructors: action.data.courseInstructors,
        courseId: action.data.courseId,
        title: action.data.title,
        courseCategoryId: action.data.courseCategoryId,
        courseType: action.data.courseType,
        courseTypeName: action.data.courseTypeName,
        description: action.data.description,
        startDate: action.data.startDate,
        endDate: action.data.endDate,
        institutionId: action.data.institutionId,
        institution: action.data.institution,
        institutionLogo: action.data.institutionLogo,
        courseCategoryName: action.data.courseCategoryName,
        modules: action.data.modules,
        draft: action.data.draft,
        courseThumbNailUrl: action.data.courseThumbNailUrl,
        duration: action.data.duration,
        prerequisite: action.data.prerequisite,
        courseLevel: action.data.courseLevel,
        courseLevelName: action.data.courseLevelName,
        stepNo: action.data.stepNo,
        isPaid: action.data.isPaid,
        hasSurvey: action.data.hasSurvey,
        surveyId: action.data.surveyId,
        coursePrice: action.data.coursePrice
      };
    case courseTypes.COURSE_ID:
      return {
        ...state,
        courseId: action.data.courseId
      };
    case courseTypes.ADD_SURVEY_ID:
      return {
        ...state,
        surveyId: action.data.surveyId
      };
    case courseTypes.COURSE_STEP_NO:
      return {
        ...state,
        stepNo: action.data.stepNo
      };
    default:
      return state;
  }
};

export default courseReducer;
