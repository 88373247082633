import React from 'react';
import { Box, Image } from 'grommet';

const IconWrapper = ({ icon, width, height, style, fit = 'cover' }) => {
  return (
    <Box height={height} width={width}>
      <Image fit={fit} src={icon} style={style} />
    </Box>
  );
};

export default IconWrapper;
