// ⚡ THIS IS A GENERATED FILE FROM 'setupRoute.js' 
// ⚠️ DO NOT EDIT THIS FILE DIRECTLY EXCEPT YOU HAVE READ THE 'README.md' file 

import Certificate from './pages/user/Certificate';
import ChangePassword from './pages/user/ChangePassword';
import Checkout from './pages/user/course/Checkout';
import CourseCategoryLanding from './pages/user/course/CourseCategoryLanding';
import CourseCompleted from './pages/user/course/CourseCompleted';
import Delivery from './pages/user/course/Delivery';
import CourseTitle from './pages/user/course/details/[id]/[CourseTitle]';
import Discovery from './pages/user/course/Discovery';
import Facilitators from './pages/user/course/Facilitators';
import InstitutionLanding from './pages/user/course/InstitutionLanding';
import MyLearnings from './pages/user/course/MyLearnings';
import ProgramLanding from './pages/user/course/ProgramLanding';
import Programs from './pages/user/course/Programs';
import Survey from './pages/user/course/[id]/Survey';
import Dashboard from './pages/user/Dashboard';
import ForgotPassword from './pages/user/ForgotPassword';
import Home from './pages/user/Home';
import Login from './pages/user/Login';
import Partner from './pages/user/Partner';
import Profile from './pages/user/Profile';
import Register from './pages/user/Register';
import SetPassword from './pages/user/smeVille/SetPassword';
import ThirdPartyCheckout from './pages/user/smeVille/ThirdPartyCheckout';
import Terms from './pages/user/Terms';

export const certificate = '/certificate';
export const changepassword = '/change-password';
export const checkout = '/course/checkout';
export const coursecategorylanding = '/course/course-category-landing';
export const coursecompleted = '/course/course-completed';
export const delivery = '/course/delivery';
export const coursetitle = '/course/details/:id/:coursetitle';
export const discovery = '/course/discovery';
export const facilitators = '/course/facilitators';
export const institutionlanding = '/course/institution-landing';
export const mylearnings = '/course/my-learnings';
export const programlanding = '/course/program-landing';
export const programs = '/course/programs';
export const survey = '/course/:id/survey';
export const dashboard = '/dashboard';
export const forgotpassword = '/forgot-password';
export const home = '/';
export const login = '/login';
export const partner = '/partner';
export const profile = '/profile';
export const register = '/register';
export const setpassword = '/smeville/set-password';
export const thirdpartycheckout = '/smeville/third-party-checkout';
export const terms = '/terms';

export default [
	{ path: certificate, component: Certificate},
	{ path: changepassword, component: ChangePassword},
	{ path: checkout, component: Checkout},
	{ path: coursecategorylanding, component: CourseCategoryLanding},
	{ path: coursecompleted, component: CourseCompleted},
	{ path: delivery, component: Delivery},
	{ path: coursetitle, component: CourseTitle},
	{ path: discovery, component: Discovery},
	{ path: facilitators, component: Facilitators},
	{ path: institutionlanding, component: InstitutionLanding},
	{ path: mylearnings, component: MyLearnings},
	{ path: programlanding, component: ProgramLanding},
	{ path: programs, component: Programs},
	{ path: survey, component: Survey},
	{ path: dashboard, component: Dashboard},
	{ path: forgotpassword, component: ForgotPassword},
	{ path: home, component: Home},
	{ path: login, component: Login},
	{ path: partner, component: Partner},
	{ path: profile, component: Profile},
	{ path: register, component: Register},
	{ path: setpassword, component: SetPassword},
	{ path: thirdpartycheckout, component: ThirdPartyCheckout},
	{ path: terms, component: Terms}
];