import React from 'react';
import IconWrapper from 'components/common/IconWrapper';
import { Box, Button, Heading, Layer, Paragraph, ResponsiveContext, TextInput } from 'grommet';
import CloseIcon from 'assets/svgs/ic_close.svg';
// import OneDriveIcon from 'assets/svgs/onedrive.svg';
import GoogleDriveIcon from 'assets/svgs/google_drive.svg';
// import DropboxIcon from 'assets/svgs/dropbox.svg';
import { isMobile } from 'utils';
import { downloadGoogleDriveFileMultiple, getAllFilesByOwnerId } from 'api/queries/ContentLibrary';
import { deleteFile, uploadMultipleFileFromLocal } from 'api/mutations/ContentLibrary';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from 'components/common/PageLoader';
import { showToast } from 'store/actions/toast';
import { useQuery } from 'react-query';
import DeleteIcon from 'assets/svgs/ic_delete.svg';

const BulkUploader = ({ onClose }) => {
  const size = React.useContext(ResponsiveContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const { userName } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { data: files, refetch: refetchContentLibraryFiles } = useQuery(
    ['contentLibraryFiles', userName],
    getAllFilesByOwnerId
  );

  // Google Drive Code starts here

  //The Browser API key obtained from the Google API Console.
  // Replace with your own Browser API key, or your own key. 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

  //AIzaSyAJMNOA0YQatS2oWH3zJpowpeviUlZkk-M
  //var developerKey_old = 'AIzaSyAZKKE9Aqo0BkWXL9Lh14hYd1f1ciiAPZs';
  var developerKey = 'AIzaSyAJMNOA0YQatS2oWH3zJpowpeviUlZkk-M';

  // The Client ID obtained from the Google API Console. Replace with your own Client ID. 221719329929-4t33nrv7rpchj2v9d894115nujmm5rjk.apps.googleusercontent.com
  //var clientId_old = '221719329929-vt17b8rsoq1ppepp0sc6cnij4294srbf.apps.googleusercontent.com';
  //253124920280-1mtquu2foovfthlo4muhr05557goa829.apps.googleusercontent.com
  //var clientId_old2 = '221719329929-4t33nrv7rpchj2v9d894115nujmm5rjk.apps.googleusercontent.com';
  var clientId = '253124920280-1mtquu2foovfthlo4muhr05557goa829.apps.googleusercontent.com';

  // Replace with your own project number from console.developers.google.com.
  // See "Project number" under "IAM & Admin" > "Settings"
  //253124920280
  var appId_old = '221719329929';
  var appId = '253124920280';

  // Scope to use to access user's Drive items.
  const scope = ['https://www.googleapis.com/auth/drive.file'];

  let pickerApiLoaded = false;
  var oauthToken;

  // Use the Google API Loader script to load the google.picker script.
  const loadPicker = () => {
    console.log('uio');
    window.gapi.load('auth', { callback: onAuthApiLoad });
    window.gapi.load('picker', { callback: onPickerApiLoad });
  };

  const onAuthApiLoad = () => {
    window.gapi.auth.authorize(
      {
        client_id: clientId,
        scope: scope,
        immediate: false
      },
      handleAuthResult
    );
  };

  let onPickerApiLoad = () => {
    pickerApiLoaded = true;
    createPicker();
  };

  const handleAuthResult = authResult => {
    console.log('nh1', authResult);
    if (authResult && !authResult.error) {
      oauthToken = authResult.access_token;
      createPicker();
    }
  };

  // Create and render a Picker object for searching images.
  const createPicker = () => {
    console.log('s1', pickerApiLoaded, oauthToken);
    if (pickerApiLoaded && oauthToken) {
      const view = new window.google.picker.View(window.google.picker.ViewId.FOLDERS);
      view.setMimeTypes(
        'image/png,image/jpeg,image/jpg,application/pdf,application/zip,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      );
      const picker = new window.google.picker.PickerBuilder()
        //.enableFeature(window.google.picker.Feature.NAV_HIDDEN)
        .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
        .setAppId(appId)
        .setOAuthToken(oauthToken)
        .addView(view)
        .addView(window.google.picker.ViewId.VIDEO_SEARCH)
        .addView(window.google.picker.ViewId.DOCUMENTS)
        .addView(window.google.picker.ViewId.PDFS)
        .addView(window.google.picker.ViewId.SPREADSHEETS)
        .addView(window.google.picker.ViewId.PRESENTATIONS)
        .addView(window.google.picker.ViewId.YOUTUBE)
        .addView(window.google.picker.ViewId.DOCS_VIDEOS)
        .addView(new window.google.picker.DocsUploadView())
        .setDeveloperKey(developerKey)
        .setCallback(pickerCallback)
        .build();
      picker.setVisible(true);
    }
  };

  // A simple callback implementation.
  const pickerCallback = async data => {
    if (data.action === window.google.picker.Action.PICKED) {
      const fileId = data.docs;
      let dataFileId = [];
      for (let i = 0; i < fileId.length; i++) {
        const element = fileId[i].id;
        dataFileId.push(element);
      }
      try {
        setIsLoading(true);
        const apiData = await downloadGoogleDriveFileMultiple({ userName, fileIds: dataFileId });
        if (apiData.success) {
          dispatch(showToast('success', apiData.description));
          refetchContentLibraryFiles();
        }
      } catch (error) {
        console.log('google_error', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Google Drive Code ends here

  return (
    <Layer
      responsive={false}
      animation="slide"
      onEsc={onClose}
      modal
      style={{ width: 650, marginTop: 30 }}>
      <Box pad="medium">
        <Box direction="row" fill="horizontal" justify="between" align="center" gap="small">
          <Heading margin="none" level={4} size="small">
            Bulk Uploader
          </Heading>
          <Button
            plain
            icon={<IconWrapper icon={CloseIcon} width="20px" height="20px" />}
            onClick={onClose}
          />
        </Box>
        <div style={{ position: 'relative' }}>
          <Box
            height="200px"
            style={{ border: '2px dashed #acacac', position: 'relative' }}
            margin="15px 0px"
            align="center"
            justify="center">
            <Paragraph>Drag & Drop your files here</Paragraph>
            <Button primary label="Browse" />
          </Box>
          <TextInput
            type="file"
            multiple
            style={{ position: 'absolute', top: -215, left: 0, height: 200, opacity: 0 }}
            onChange={async e => {
              try {
                setIsLoading(true);
                const data = await uploadMultipleFileFromLocal(userName, e.target.files);
                if (data.success) {
                  dispatch(showToast('success', data.description));
                  refetchContentLibraryFiles();
                }
              } catch (error) {
                dispatch(showToast('error', 'Failed to upload files from local'));
                console.log(error);
              } finally {
                setIsLoading(false);
              }
            }}
          />
        </div>
        <Box>
          <Paragraph margin={{ top: 'none', bottom: '10px' }}>Other ways to upload</Paragraph>
          <Box direction={isMobile(size) ? 'column' : 'row'} gap="medium">
            {/* <Button
              primary
              color="#eee"
              icon={<IconWrapper icon={OneDriveIcon} width="20px" height="20px" fit="contain" />}
              label="OneDrive"
            /> */}
            {/* <Button
              primary
              color="#eee"
              icon={<IconWrapper icon={GoogleDriveIcon} width="20px" height="20px" fit="contain" />}
              label="Google Drive"
              onClick={loadPicker}
            /> */}
            {/* <Button
              primary
              color="#eee"
              icon={<IconWrapper icon={DropboxIcon} width="20px" height="20px" fit="contain" />}
              label="Dropbox"
            /> */}
          </Box>
        </Box>
        <Box border="between" gap="small">
          <Box>
            <Heading level="4" size="small" margin={{ bottom: '10px' }}>
              Uploaded Files
            </Heading>
          </Box>
          <div style={{ overflowY: 'auto', height: 108, paddingRight: 20 }}>
            {files ? (
              files.data.length > 0 ? (
                files.data.map(file => (
                  <Box key={file.id} direction="row" justify="between" align="center">
                    <Paragraph
                      className="one-line-paragraph"
                      margin={{ top: 'xsmall', bottom: 'xsmall' }}>
                      {file.fileLocationUrl.slice(file.fileLocationUrl.indexOf('/') + 1)}
                    </Paragraph>
                    <Button
                      icon={<IconWrapper icon={DeleteIcon} width="20px" height="20px" />}
                      onClick={async () => {
                        try {
                          const apiData = await deleteFile(file.id);
                          if (apiData.success) {
                            dispatch(showToast('success', 'File deleted successfully'));
                            refetchContentLibraryFiles();
                          }
                        } catch (error) {
                          dispatch(showToast('error', 'Failed to delete file'));
                          console.log(error);
                        }
                      }}
                    />
                  </Box>
                ))
              ) : (
                <Paragraph>No uploaded file</Paragraph>
              )
            ) : (
              <Paragraph>Fetching uploaded files...</Paragraph>
            )}
          </div>
        </Box>
        <PageLoader isLoading={isLoading} left="0px" top="0px" />
      </Box>
    </Layer>
  );
};

export default BulkUploader;
