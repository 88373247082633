import React from 'react';
import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';

const SocialmediaCard = ({ color, name, icon }) => {
  return (
    <Container gutter={16} color={color}>
      <IconDiv span={8}>
        <Icon>{icon}</Icon>
      </IconDiv>
      <TextDiv span={16}>{name}</TextDiv>
    </Container>
  );
};

export default SocialmediaCard;

const Container = styled(Row)`
  background: ${props => props.color};
  height: 2em;
  width: 7em;
  border-radius: 3px !important;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:hover {
    transform: scale(1.1, 1.1);
  }
`;
const Icon = styled(Typography.Text)`
  font-size: 1em;
  border-right: 1px solid #bfbfbf;
  padding-right: 0.3em;
  display: block !important;
  color: #fff;
`;

const IconDiv = styled(Col)`
  padding-top: 0.5em;
`;

const TextDiv = styled(Col)`
  padding-top: 0.19em;
  text-align: center;
  font-weight: bold;
  color: #fff;
`;
