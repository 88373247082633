import React from 'react';
import { Row, Carousel } from 'antd';
import styled from 'styled-components';
import ExporeButton from './ExporeButton.jsx';
import ScrollDown from './ScrollDown.jsx';
import ActiveLearningSection from './ActiveLearningSection';
import bannerContent from 'contentData/bannerContent';
import device from 'configs/responsive/mediaQueries';
import { useMediaQuery } from 'react-responsive';
import BannerImg from 'assets/images/LandingBanner.png';

const { mobile } = device;

const Banner = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  return (
    // <Carousel autoplay autoplaySpeed={7000} infinite>
    <>
      {/* {bannerContent.map(banner => ( */}
      <Container background={BannerImg}>
        <BannerRow>
          <ActiveLearningSection
            heading={'Localized, quality and on-demand courses'}
            content={''}
            isMobile={isMobile}
          />
          <ExporeButton />
          <ScrollDown isMobile={isMobile} />
        </BannerRow>
      </Container>
      {/* ))} */}

      {/* </Carousel> */}
    </>
  );
};

export default Banner;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ background }) => `url(${background}) no-repeat center/cover `};
  position: relative;
  &:after {
    content: '';
    background: rgba(0, 0, 0, 0.45);
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
  }
  @media ${mobile} {
    height: 70vh;
  }
`;

const BannerRow = styled(Row)`
  padding: 2em;
`;
