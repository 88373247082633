import React from 'react';
import {
  Box,
  Button,
  CheckBox,
  Form,
  FormField,
  Layer,
  ResponsiveContext,
  Select,
  Text,
  TextInput
} from 'grommet';
import { isMobile } from 'utils';
import Modal from 'components/common/Modal';
import { useForm } from 'react-hook-form';
import ApprovalTable from 'components/tables/ApprovalTable';
import { useMutation, useQuery } from 'react-query';
import { create } from 'api/mutations/Workflow';
import { getUserInfoByInstitutionId, getWorkflowUserInfo } from 'api/queries/Authentication';
import { courseCategoryList } from 'api/queries/Course';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from 'store/actions/toast';
import { useHistory } from 'react-router-dom';
import { manageworkflow } from 'adminRoutes';

const ManageWorkFlowModal = ({ onClose, refetchWorkflow }) => {
  const size = React.useContext(ResponsiveContext);
  const history = useHistory();

  const dispatch = useDispatch();
  const formFieldStyle = {
    flexDirection: isMobile(size) ? 'column' : 'row',
    alignItems: 'stretch',
    justifyContent: isMobile(size) ? 'flex-start' : 'space-between'
  };
  const formFieldStyle2 = {
    flexDirection: isMobile(size) ? 'column' : 'row',
    alignItems: 'stretch',
    justifyContent: isMobile(size) ? 'flex-start' : 'flex-start'
  };

  const auth = useSelector(state => state.auth);
  const { data: UserInfoByInstitutionId } = useQuery(
    ['UserInfoByInstitutionId', auth.institutionId],
    getWorkflowUserInfo
  );
  const { data: courses } = useQuery(['courses'], courseCategoryList);
  const { register, setValue, handleSubmit, errors } = useForm();
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [createError, setCreateError] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [addedUsers, setAddedUsers] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [name, setName] = React.useState();
  const [checkFor, setCheckFor] = React.useState();
  const [courseCategory, setCourseCategory] = React.useState();
  const [workflowName, setWorkflowName] = React.useState('');

  const [createMutate, info] = useMutation(create, {
    onSuccess: data => {
      const { description, success } = data;
      if (success) {
        refetchWorkflow();
        setSuccessMessage(description);
        dispatch(showToast('success', 'Workflow created successfully'));
      } else {
        setCreateError(description);
        setIsSubmitting(false);
        console.log(description);
        dispatch(showToast('warning', description));
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setCreateError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        // setCreateError(error.message);
        console.log(error);
      }
    }
  });

  const addUser = () => {
    const isAdded = addedUsers.some(user => user.name === name);
    if (isAdded) return; //TODO: add notification here
    if (name === undefined) {
      dispatch(showToast('warning', 'Please select a user from the dropdown!'));
      return;
    }

    setAddedUsers(state => {
      const newState = Array.from(state);
      const currentUserName = name;
      return newState.concat([
        {
          name,
          action: (
            <Button
              secondary
              label="Remove"
              onClick={() =>
                setAddedUsers(state => state.filter(user => user.name !== currentUserName))
              }
            />
          )
        }
      ]);
    });
  };

  const onSubmit = async ({ workflowname }) => {
    if (isSubmitting) return;
    const validate = checkFor > addedUsers.length ? true : false;
    if (validate) {
      dispatch(showToast('warning', 'Number of approvals cant be more than added users!'));
      return;
    }

    const workflowDetails = {
      institutionId: auth.institutionId,
      name: workflowName,
      levelNo: 0,
      courseCategoryId: courseCategory.value,
      workflowLevels: [
        {
          approval: checkFor,
          users: addedUsers.map(user => {
            const userFirstName = user.name.split(' ')[0];
            return {
              userId: UserInfoByInstitutionId.find(item => item.firstName === userFirstName)?.id
            };
          })
        }
      ]
    };
    console.log(workflowDetails);

    try {
      setCreateError(null);
      setIsSubmitting(true);
      await createMutate(workflowDetails);
      onClose(false);
      history.push(manageworkflow);
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <Box>
      <Modal
        onClose={onClose}
        title="Manage Authorization"
        subTitle="User and groups who can approve transaction request">
        <Box pad={{ horizontal: 'medium' }} margin={{ bottom: 'medium' }}>
          <Form onSubmit={onSubmit}>
            <Box margin={{ bottom: 'medium' }}>
              <FormField
                label="Workflow Name"
                name="workflowname"
                htmlfor="workflowname"
                margin={{ bottom: 'none' }}
                style={formFieldStyle}>
                <TextInput
                  ref={register({ required: 'Workflow Name is required' })}
                  id="workflowname"
                  name="workflowname"
                  value={workflowName}
                  onChange={e => setWorkflowName(e.target.value)}
                  placeholder=" Workflow Name"
                  style={{ width: isMobile(size) ? '100%' : 300 }}
                />
              </FormField>
              {errors.name && (
                <Text
                  color="status-critical"
                  size="small"
                  alignSelf={isMobile(size) ? 'start' : 'end'}
                  margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
                  {errors.name.message}
                </Text>
              )}
            </Box>

            <Box margin={{ bottom: 'medium' }}>
              {courses && (
                <>
                  <FormField
                    label="Course Category"
                    name="courseCategory"
                    htmlfor="courseCategory"
                    margin={{ bottom: 'none' }}
                    style={formFieldStyle}>
                    <Select
                      id="courseCategory"
                      name="courseCategory"
                      placeholder="Course Category"
                      //style={{ width: isMobile(size) ? '100%' : 50 }}
                      // children=((option, index, options, { active, disabled, selected }) => {...})
                      labelKey="label"
                      valueKey="value"
                      options={courses.data.map(item => ({ label: item.name, value: item.id }))}
                      value={courseCategory}
                      onChange={({ value, option }) => setCourseCategory(option)}
                    />
                  </FormField>
                  {errors.name && (
                    <Text
                      color="status-critical"
                      size="small"
                      alignSelf={isMobile(size) ? 'start' : 'end'}
                      margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
                      {errors.name.message}
                    </Text>
                  )}
                </>
              )}
            </Box>

            <Box margin={{ bottom: 'medium' }}>
              <FormField
                label="Check for at least any"
                name="checkFor"
                htmlfor="check-for"
                margin={{ bottom: 'none' }}
                style={formFieldStyle2}>
                <Select
                  ref={register({ required: 'Workflow checkFor is required' })}
                  id="check-for"
                  name="checkFor"
                  placeholder="any"
                  style={{ width: isMobile(size) ? '100%' : 50 }}
                  options={['1', '2', '3', '4']}
                  value={checkFor}
                  onChange={({ value, option }) => setCheckFor(option)}
                />
              </FormField>
              {errors.checkFor && (
                <Text
                  color="status-critical"
                  size="small"
                  alignSelf={isMobile(size) ? 'start' : 'end'}
                  margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
                  {errors.checkFor.message}
                </Text>
              )}
            </Box>
            <Box direction="row" align="center" justify="between" margin={{ bottom: 'medium' }}>
              {UserInfoByInstitutionId && (
                <>
                  <FormField
                    name="name"
                    htmlfor="name"
                    margin={{ bottom: 'none' }}
                    style={formFieldStyle}>
                    <Select
                      id="name"
                      name="name"
                      placeholder="name"
                      style={{ width: isMobile(size) ? '100%' : 200 }}
                      options={UserInfoByInstitutionId.map(
                        user => user.firstName + ' ' + user.lastName
                      )}
                      value={name}
                      onChange={({ option }) => setName(option)}
                    />
                  </FormField>
                  {errors.name && (
                    <Text
                      color="status-critical"
                      size="small"
                      alignSelf={isMobile(size) ? 'start' : 'end'}
                      margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
                      {errors.name.message}
                    </Text>
                  )}
                </>
              )}

              <Box>
                {' '}
                <Button primary size="large" label="Add User" onClick={addUser} />
              </Box>
            </Box>
            <ApprovalTable data={addedUsers} />
            <br></br>
            <Box
              as="footer"
              border={{ side: 'top' }}
              pad="small"
              gap="small"
              justify="end"
              direction="row"
              align="center">
              <Button
                disabled={
                  addedUsers.length === 0
                    ? true
                    : false || courseCategory === undefined
                    ? true
                    : checkFor === undefined
                    ? true
                    : false
                }
                type="submit"
                primary
                label="Create Workflow"
              />
            </Box>
          </Form>
        </Box>
      </Modal>
    </Box>
  );
};

export default ManageWorkFlowModal;
