import Axios from 'axios';

const service = 'Institution';

export const getCountries = async () => {
  const { data } = await Axios.get(`/${service}/api/Institution/Countries`);
  return data;
};

export const getInstitution = async (key, id) => {
  const { data } = await Axios.get(`/${service}/api/Institution/GetInstitution?Id=${id}`);
  return data;
};

export const getCertificate = async (key, courseId, userId, institutionId) => {
  const { data } = await Axios.get(
    `/${service}/api/Certificate/GetCertificate?courseId=${courseId}&userId=${userId}&institutionId=${institutionId}`
  );
  return data;
};
export const generateCertificate = async (key, courseId, userId, institutionId) => {
  const { data } = await Axios.get(
    `/${service}/api/Certificate/GenerateCertificate?courseId=${courseId}&userId=${userId}&institutionId=${institutionId}`
  );
  return data;
};

export const searchInstitutionById = async (
  key,
  id,
  pageSize = 50,
  page = 1,
  isActive = 'true'
) => {
  const { data } = await Axios.get(
    `/${service}/api/Institution/Search?Page=${page}&PageSize=${pageSize}&Id=${id}&IsActive=${isActive}`
  );
  return data;
};

export const queryInstitution = async (name, code, email) => {
  const { data } = await Axios.get(
    `/${service}/api/Institution/QueryInstitution?${code ? `code=${code}` : ''}${
      name ? `name=${name}` : ''
    }${email ? `email=${email}` : ''}`
  );
  return data;
};

export const getBanks = async (id = 0) => {
  const { data } = await Axios.get(`/${service}/api/Partner/Banks?id=${id}`);
  return data;
};

export const getPartners = async (key, search = '', id = 0, page = 1, pageSize = 50) => {
  const { data } = await Axios.get(
    `/${service}/api/Partner/Search?search=${search}&id=${id}&page=${page}&pageSize=${pageSize}`
  );
  return data;
};

export const viewSignature = async (key, instituionId) => {
  const { data } = await Axios.get(
    `/${service}/api/Certificate/ViewSignatureAndName?institutionId=${instituionId}`
  );
  return data;
};
