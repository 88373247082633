import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import Header from './Header';
import AdminSidebar from './AdminSidebar';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import Sidebar from './Sidebar';
import { home } from 'userRoutes';
import Footer from 'components/landingPageComponents/Footer';
import AppData from 'AppData';
import { MainContext } from 'index';
import { useHistory } from 'react-router';
import MaintenanceModal from 'components/Maintenance/MaintenanceModal';
import { useQuery } from 'react-query';
import { getInAppNotification } from 'api/queries/Notification';
import { useSelector } from 'react-redux';

const MainLayout = props => {
  const {
    selectedKey,
    children,
    isAdmin,
    background,
    setShowSignin,
    sidebar,
    noMargin,
    padded,
    isVideoPage
  } = props;
  const [show, setShow] = React.useState(false);
  const { inAppModal } = useSelector(state => state.inAppNotify);
  const [showMaintenanceModal, setShowMaintenanceModal] = React.useState(true);
  const mainContext = useContext(MainContext);
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });
  const history = useHistory();
  const { data } = useQuery(['inAppNotify'], getInAppNotification);

  const homePage = home === history.location.pathname;

  useEffect(() => {
    if (data?.length > 0) {
      if (data[0]?.showModal) {
        setShowMaintenanceModal(true);
      } else {
        setShowMaintenanceModal(false);
      }
    }
  }, [data]);

  if (isVideoPage) {
    return (
      <Wrapp>
        <Header
          homePage={homePage}
          isAdmin={isAdmin}
          setShowSignin={setShowSignin}
          isVideoPage={isVideoPage}
          background={background}
        />
        <VideoWrapper nomargin={noMargin} padded={padded}>
          {children}
        </VideoWrapper>
      </Wrapp>
    );
  }

  return (
    <Wrapper>
      <Header
        homePage={homePage}
        isAdmin={isAdmin}
        setShowSignin={setShowSignin}
        isVideoPage={isVideoPage}
        background={background}
      />
      {showMaintenanceModal &&
        inAppModal &&
        mainContext.build === AppData.userBuildType &&
        data?.map((item, i) => {
          if (item.showModal) {
            return (
              <MaintenanceModal key={i} message={item} setShowModal={setShowMaintenanceModal} />
            );
          }
        })}
      {sidebar ? (
        <LayoutWrapper ismobile={isMobileDevice || show ? '80px' : '200px'}>
          {isAdmin ? (
            <AdminSidebar show={show} setShow={setShow} selectedKey={selectedKey} />
          ) : (
            <Sidebar show={show} setShow={setShow} selectedKey={selectedKey} />
          )}
          <Layout>
            <ContentWrapper nomargin={noMargin} padded={padded}>
              {children}
            </ContentWrapper>
            {mainContext.build === AppData.userBuildType && (
              <>
                {/* <SubscribeSection /> */}
                <Footer />
              </>
            )}
          </Layout>
        </LayoutWrapper>
      ) : (
        <>
          <ContentWrapper nomargin={noMargin} padded={padded}>
            {children}
          </ContentWrapper>
          {mainContext.build === AppData.userBuildType && !isVideoPage && (
            <>
              {/* <SubscribeSection /> */}
              <Footer />
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

MainLayout.propTypes = {
  selectedKey: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  sidebar: PropTypes.bool,
  isVideoPage: PropTypes.bool
};
export default MainLayout;

const Wrapper = styled(Layout)`
  min-height: 100vh;
`;
const LayoutWrapper = styled(Layout)`
  min-height: calc(100vh - 64px);
  background: #f7f8fa;
  margin-left: ${({ ismobile }) => ismobile};
`;

const ContentWrapper = styled(Layout.Content)`
  padding: ${({ padded }) => `${padded ? '12px' : 0}`};
  position: relative;
  margin-top: ${({ nomargin }) => (nomargin ? '0px' : '64px')};
`;

const VideoWrapper = styled(Layout.Content)`
  padding: ${({ padded }) => `${padded ? '12px' : 0}`};
  position: relative;
  height: calc(100vh - 64px);
  margin-top: ${({ nomargin }) => (nomargin ? '0px' : '64px')};
`;
const Wrapp = styled(Layout)`
  height: 100vh;
`;
