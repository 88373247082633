import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Col, Row, Typography } from 'antd';
import { ImageWrapper } from 'components/common/ImageWrapper';
import { ButtonCTA } from 'components/common/ButtonCTA';
import Explore from 'assets/images/landing page Assets/Home landing page/explore.png';
import { useMediaQuery } from 'react-responsive';
import device from 'configs/responsive/mediaQueries';
import { Link } from 'react-router-dom';
import { discovery } from 'userRoutes';

const { mobile } = device;

const ExploreSection = props => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  return (
    <ExploreSectionWrapper>
      <ImageWrapper width="100%" height="100%" src={Explore} preview={false} />
      <ExploreContentWrapper>
        <Row>
          <Title>
            Explore Remote and interactive <br />
            Learning at your finger tips
          </Title>
          <Paragraph>Get unlimited access to SkillDorm's top courses for your team</Paragraph>
          <BtnDiv>
            <Link to={discovery}>
              <ButtonCTA type="primary" size="large" color="#fff">
                Start Learning Now
              </ButtonCTA>
            </Link>
          </BtnDiv>
        </Row>
      </ExploreContentWrapper>
    </ExploreSectionWrapper>
  );
};

ExploreSection.propTypes = {};

export default ExploreSection;

const ExploreSectionWrapper = styled(Row)`
  margin-top: 0rem;
  height: 500px;
  position: relative;
  @media ${mobile} {
    margin-top: 14rem;
  }
`;

const ExploreContentWrapper = styled(Row)`
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  padding: 50px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  @media ${mobile} {
    padding: 30px 1.3rem 1.3rem 1.3em;
  }
`;

const Title = styled(Typography.Title)`
  margin-top: 0 !important;
  color: #fff !important;
  width: 100%;
  line-height: 1.3 !important;
  @media ${mobile} {
    font-size: 1.3em !important;
    text-align: center;
    margin-bottom: 1.5rem !important;
  }
`;

const Paragraph = styled(Typography.Paragraph)`
  color: #fff !important;
  display: block;
  width: 100%;
  margin-bottom: 3rem !important;
  @media ${mobile} {
    font-size: 0.9em !important;
    text-align: center;
    margin-bottom: 2rem !important;
  }
`;

const BtnDiv = styled.div`
  @media ${mobile} {
    text-align: center;
    margin: auto;
  }
`;
