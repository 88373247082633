import React from 'react';
import 'styles/utils.css';
import { Box, Text, ResponsiveContext, Paragraph, Card, Button } from 'grommet';
import { FormEdit } from 'grommet-icons';
import { isMobile } from 'utils';
import ProfileIcon from './ProfileIcon';

const ManageCardWrapper = ({ title, address, mobile, clickFunc, isActive, img }) => {
  const size = React.useContext(ResponsiveContext);
  return (
    <Box>
      <Card
        direction={isMobile(size) ? 'column' : 'row'}
        pad="small"
        height="157px"
        background={isActive ? '#fffff' : '#eae7e2'}
        gap={isMobile(size) ? 'medium' : 'none'}>
        <Box width="xsmall" margin={{ left: 'small', top: 'small' }}>
          <ProfileIcon size="large" img={img} />
        </Box>
        <Box flex="shrink" margin={{ left: isMobile(size) ? 'small' : 'none' }}>
          <Box
            direction={isMobile(size) ? 'column' : 'row'}
            justify="between"
            gap="medium"
            margin={{ left: 'small', top: 'small', bottom: 'small', right: 'small' }}>
            <Box width={isMobile(size) ? '100%' : '400px'}>
              <Paragraph margin={{ bottom: 'xsmall', top: 'none' }} level={4} color="#777">
                {title.toUpperCase()}
              </Paragraph>
              <Text size="xsmall">{address}</Text>
              <Text size="xsmall">{mobile && `Mobile: ${mobile}`}</Text>
              <Text size="xsmall">{`Status: ${isActive ? 'Active' : 'Inactive'}`}</Text>
            </Box>
            <Box
              flex="grow"
              align={isMobile(size) ? 'start' : 'end'}
              margin={{ bottom: isMobile(size) ? 'small' : 'none' }}
              justify="start">
              <Box
                flex="shrink"
                direction={isMobile(size) ? 'row' : 'row'}
                gap={isMobile(size) ? 'xsmall' : 'small'}>
                <Button
                  style={{ padding: '0px' }}
                  icon={<FormEdit color="brand" size="medium" />}
                  onClick={clickFunc}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default ManageCardWrapper;
