import React, { useState } from 'react';
import { Modal, Row, Switch, Typography, Input, InputNumber, Form } from 'antd';
import styled from 'styled-components';
import CustomButton from 'components/common/CustomButton';

const QuizBuilder = ({
  showQuizBuilder,
  setShowQuizBuilder,
  quiz,
  setQuiz,
  quizDeleteButton,
  defaultQuiz,
  setDefaultQuiz
}) => {
  const [name, setName] = useState(quiz?.name || '');
  const [description, setDescription] = useState(quiz?.description || '');
  const [duration, setDuration] = useState(quiz?.duration || 0);
  const [pointPerQuestion, setPointPerQuestion] = useState(quiz?.pointPerQuestion || 0);
  const [randomize, setRandomize] = useState(quiz?.randomize || false);
  const [timed, setTimed] = useState(quiz?.timed || false);
  const [requiredNextModule, setRequiredNextModule] = useState(quiz?.requiredNextModule || false);
  const [passMark, setPassMark] = useState(quiz?.passMark || 0);

  const handleCancel = () => {
    if (defaultQuiz) quizDeleteButton.current.click();
    setShowQuizBuilder(false);
  };

  const handleNameChange = e => setName(e.target.value);

  const handleDescriptionChange = e => setDescription(e.target.value);

  const handleDurationChange = value => setDuration(value);

  const handlePointPerQuestionChange = value => setPointPerQuestion(value);

  const handleRandomizeChange = checked => setRandomize(checked);

  const handleTimedChange = checked => setTimed(checked);

  const handleRequiredNextModuleChange = checked => setRequiredNextModule(checked);

  const handlePassMarkChange = value => setPassMark(value);

  const onFinish = values => {
    setQuiz(state => ({ ...state, ...values }));
    setDefaultQuiz(false);
    setShowQuizBuilder(false);
  };

  return (
    <Modal
      title="Quiz Setup"
      visible={showQuizBuilder}
      onCancel={handleCancel}
      footer={null}
      centered>
      <Form name="quizSetup" initialValues={{ ...quiz }} onFinish={onFinish}>
        <FlexRow>
          <Label>Quiz Name</Label>
          <FormItem name="name" rules={[{ required: true, message: 'Please enter quiz name' }]}>
            <Input value={name} onChange={handleNameChange} />
          </FormItem>
        </FlexRow>
        <FlexRow>
          <Label>Description</Label>
          <FormItem
            name="description"
            rules={[{ required: true, message: 'Please enter description' }]}>
            <Input value={description} onChange={handleDescriptionChange} />
          </FormItem>
        </FlexRow>
        <FlexRow>
          <Label>Timed Questions</Label>
          <FormItem name="timed">
            <CustomSwitch checked={timed} onChange={handleTimedChange} activeswitch={timed} />
          </FormItem>
        </FlexRow>
        <FlexRow>
          <Label>Duration</Label>
          <Wrapper>
            <FormItem name="duration" width="auto">
              <InputNumber
                min={0}
                max={60}
                value={duration}
                onChange={handleDurationChange}
                disabled={timed === false}
              />
            </FormItem>
            <Label>&ensp;minutes</Label>
          </Wrapper>
        </FlexRow>
        <FlexRow>
          <Label>Point per question</Label>
          <FormItem name="pointPerQuestion">
            <InputNumber
              min={1}
              max={100}
              value={pointPerQuestion}
              onChange={handlePointPerQuestionChange}
            />
          </FormItem>
        </FlexRow>
        <FlexRow>
          <Label>Required for Certificate</Label>
          <FormItem name="requiredNextModule">
            <CustomSwitch
              checked={requiredNextModule}
              onChange={handleRequiredNextModuleChange}
              activeswitch={requiredNextModule}
            />
          </FormItem>
        </FlexRow>
        <FlexRow>
          <Label>Pass mark</Label>
          <Wrapper>
            <FormItem name="passMark" width="auto">
              <InputNumber
                min={0}
                max={100}
                value={passMark}
                onChange={handlePassMarkChange}
                disabled={requiredNextModule === false}
              />
            </FormItem>
            <Label>&ensp;%</Label>
          </Wrapper>
        </FlexRow>
        <FlexRow>
          <Label>Randomize Questions</Label>
          <FormItem name="randomize">
            <CustomSwitch
              checked={randomize}
              onChange={handleRandomizeChange}
              activeswitch={randomize}
            />
          </FormItem>
        </FlexRow>
        <ButtonGroup>
          <CustomButton htmlType="submit" bgColor="#0966b8" color="#fff" size="large">
            {defaultQuiz ? 'Add Quiz' : 'Update Quiz'}
          </CustomButton>
        </ButtonGroup>
      </Form>
    </Modal>
  );
};

const FlexRow = styled(Row)`
  align-items: center;
  flex-flow: row nowrap;
  padding: 10px 40px;
`;

const Label = styled(Typography.Text)`
  flex-basis: 250px;
  margin-top: ${props => props.margintop || '5px'};
`;

const FormItem = styled(Form.Item)`
  margin: 0;
  width: ${props => props.width || '100%'};
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

const CustomSwitch = styled(Switch)`
  background-color: ${props => props.activeswitch && '#19b780'};
`;

const ButtonGroup = styled(Row)`
  flex-flow: row nowrap;
  gap: 20px;
  padding: 20px 40px;
`;

export default QuizBuilder;
