import * as inAppTypes from 'store/actions/inAppNotify';

// initial sign in state
const initialState = {
  inAppData: true,
  inAppModal: true
};

const inAppNotifyReducer = (state = initialState, action) => {
  switch (action.type) {
    case inAppTypes.STUDENT_INTERACTION_STATE:
      return {
        ...state,
        inAppData: action.data
      };
    case inAppTypes.CLEAR_STUDENT_INTERACTION_STATE:
      return { ...state, inAppData: true };
    case inAppTypes.STUDENT_MODAL_STATE:
      return {
        ...state,
        inAppModal: action.data
      };
    case inAppTypes.CLEAR_MODAL_STATE:
      return { ...state, inAppModal: true };
    default:
      return state;
  }
};

export default inAppNotifyReducer;
