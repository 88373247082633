import React, { useState, useContext, useEffect } from 'react';
import 'styles/utils.css';
import { createcourse } from 'adminRoutes';
import { Box, ResponsiveContext, Anchor } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import CourseCard from 'components/common/CourseCard';
import IcsanLogo from 'assets/images/icsan.png';
import { isMobile } from 'utils';
import SectionHeader from 'components/utils/CourseDiscovery/SectionHeader';
import { FormNext } from 'grommet-icons';
import DiscoveryImg from 'assets/images/discover.png';
import { useQuery } from 'react-query';
import { getCourses } from 'api/mutations/Course';
import { Button as AntButton, Typography, Col, Row } from 'antd';
import ProgramLandingCard from 'components/common/ProgramLandingCard';
import MainLayout from 'components/MainLayout';
import { useHistory } from 'react-router-dom';
import { clearCourse } from 'store/actions/course';
import PageLoader from 'components/common/PageLoader';

const ManageCourse = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { institutionId } = useSelector(state => state.auth);
  const { Title, Text } = Typography;
  const size = useContext(ResponsiveContext);
  const [published, setPublished] = useState();
  const [drafted, setDrafted] = useState();

  const { data, isLoading } = useQuery(['AllCourses', institutionId], getCourses);
  const allCourses = data?.data;

  const handleCreateNewCourse = () => {
    dispatch(clearCourse());
    history.push(createcourse);
  };

  useEffect(() => {
    if (allCourses) {
      setPublished(allCourses.filter(course => !course.draft));
      setDrafted(allCourses.filter(course => course.draft));
    }
  }, [allCourses]);

  return (
    <MainLayout padded isAdmin sidebar selectedKey="manageCourse">
      <Box
        height="180px"
        justify="center"
        round="10px"
        pad={{ right: 'large', left: 'large' }}
        background={{
          dark: true,
          position: '50% 0%',
          repeat: 'no-repeat',
          size: 'cover',
          image: `linear-gradient(to right, rgba(0,0,0,1),rgba(0,0,0,1), rgba(0,0,0,.1) ), url(${DiscoveryImg})`
        }}>
        <div>
          <Title level={1} style={{ wordSpacing: 5, color: '#fff', paddingBottom: 0 }}>
            Manage Courses
          </Title>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Text style={{ fontSize: 'small', color: '#fff' }}>
              Review and approve course materials related to your experience and professional
              background
            </Text>
            <AntButton type="primary" size="large" onClick={handleCreateNewCourse}>
              Create New Course
            </AntButton>
          </div>
        </div>
      </Box>
      <Box pad={{ horizontal: 'large' }} background="#fff">
        <Box gap={isMobile(size) ? 'large' : 'small'}>
          <Box direction="row" gap="medium" justify="between">
            <Box width="100%">
              <Box margin={{ top: 'medium' }}>
                <Box
                  direction={isMobile(size) ? 'column' : 'row'}
                  gap="medium"
                  align={isMobile(size) ? 'stretch' : 'center'}
                  justify="between">
                  <SectionHeader color="#686868" title="Draft Courses" marginTop="none" />
                </Box>
                {/* <ProgramLandingCard
                  institutionName="NOUN"
                  // isPrice
                  draft
                  logoTitle="Lagos Business School"
                  logoSubtitle="LBSN"
                  price="N8,500"
                  title="Data Manipulation with dplys"
                  description="To begin, I recommend taking a few minutes to explore the course site. Review the material we'll cover each week, and preview the assignments you'll need to complete to pass the course."
                  modules={7}
                  weeks="9h 45mins"
                  duration="9h 45mins"
                  level="Beginner"
                  isAdmin
                  isReview
                  category="All Levels"
                  // courseThumbNailUrl={item.courseThumbNailUrl}
                /> */}
                {drafted &&
                  drafted.map(course => {
                    return (
                      <ProgramLandingCard
                        key={course.courseId}
                        courseId={course.courseId}
                        institutionName={course.institution}
                        draft
                        // isPrice
                        logoTitle="Lagos Business School"
                        logoSubtitle="LBSN"
                        price={course.isPaid}
                        title={course.title}
                        description={course.description}
                        modules={course.modules}
                        weeks="9h 45mins"
                        duration={`${course.duration}hrs`}
                        level={course.courseLevelName}
                        percentage={course.percentage}
                        isAdmin
                        isReview
                        category="All Levels"
                        courseThumbNailUrl={course.courseThumbNailUrl}
                      />
                    );
                  })}
              </Box>
              <Box>
                <Box
                  direction={isMobile(size) ? 'column' : 'row'}
                  gap="medium"
                  align={isMobile(size) ? 'stretch' : 'center'}
                  justify="between">
                  <SectionHeader color="#686868" title="Published Courses" />
                </Box>
                <div>
                  <Row gutter={[8, 8]}>
                    {published &&
                      published.map(item => {
                        return (
                          <Col span={6}>
                            <CourseCard
                              key={item.courseId}
                              courseId={item.courseId}
                              careerRelated
                              course="6"
                              price={item.coursePrice?.amount}
                              title={item.title}
                              description={item.description}
                              modules={item.modules}
                              duration="9h 45mins"
                              renderState={item}
                              level={
                                item?.courseLevel === 1
                                  ? 'Beginner'
                                  : item?.courseLevel === 2
                                  ? 'Intermedate'
                                  : 'Advanced'
                              }
                              institutionLogo={
                                item.institutionLogo === null ? IcsanLogo : item.institutionLogo
                              }
                              institutionName={item.institution}
                              courseThumbNailUrl={item.courseThumbNailUrl}
                            />
                          </Col>
                        );
                      })}
                  </Row>
                </div>
                <Box justify="end" direction="row">
                  <Anchor
                    icon={<FormNext />}
                    label="See All Programs"
                    color="#0849ff"
                    href="#"
                    reverse
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <PageLoader isLoading={isLoading} />
    </MainLayout>
  );
};

export default ManageCourse;
