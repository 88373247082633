import React from 'react';
import styled from 'styled-components';
import { Typography, Row, Col } from 'antd';

const { Paragraph } = Typography;

const AppText = ({
  size,
  color,
  weight,
  bottom,
  top,
  align,
  pointer,
  decoration,
  transform,
  value,
  icon,
  family,
  left,
  onClick,
  style,
  iconSuffix,
  lineHeight
}) => {
  return (
    <P
      size={size}
      color={color}
      weight={weight}
      bottom={bottom}
      top={top}
      left={left}
      align={align}
      pointer={pointer}
      decoration={decoration}
      onClick={onClick}
      style={style}
      family={family}
      transform={transform}
      lineHeight={lineHeight}>
      <Row>
        {icon && (
          <Col>
            <span style={{ marginTop: '0px', display: 'block' }}>{icon}</span>
          </Col>
        )}
        {value && (
          <Col style={{ textAlign: align }} span={!icon && !iconSuffix && 24}>
            <span>{value}</span>
          </Col>
        )}
        {iconSuffix && (
          <Col>
            <span style={{ marginTop: '3px', display: 'block' }}>{iconSuffix}</span>
          </Col>
        )}
      </Row>
    </P>
  );
};

export default AppText;

const P = styled(Paragraph)`
  font-size: ${({ size }) => (size ? size : '14px')};
  color: ${({ color }) => color};
  font-weight: ${({ weight }) => (weight ? weight : 'normal')};
  margin-bottom: ${({ bottom }) => bottom}!important;
  margin-top: ${({ top }) => top}!important;
  margin-left: ${({ left }) => left}!important;
  text-align: ${({ align }) => align};
  line-height: ${({ lineHeight }) => lineHeight};
  cursor: ${({ pointer }) => pointer && 'pointer'};
  font-family: ${props => props.family};
  text-decoration: ${({ decoration }) => decoration};
  text-transform: ${({ transform }) => transform};
`;
