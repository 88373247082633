import React from 'react';
import { Row, Col, Typography, message } from 'antd';
import styled from 'styled-components';
import warningIcon from 'assets/images/warning.png';
import cancelAltIcon from 'assets/images/cancel-alt.png';
import htmr from 'htmr';
import { useDispatch } from 'react-redux';
import { studentInteractionModal } from 'store/actions/inAppNotify';

const MaintenanceModal = ({ message, setShowModal }) => {
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(studentInteractionModal(false));
  };

  return (
    <>
      <Row>
        <Overlay span={24}>
          <Modal>
            <ModalHeader>
              <ModalHeaderTextContainer>
                <img src={warningIcon} alt="warning-icon" />
                <ModalHeaderText level={3}>
                  {message?.inAppNotificationCategoryName}
                </ModalHeaderText>
                <img src={cancelAltIcon} alt="close-icon" onClick={handleCloseModal} />
              </ModalHeaderTextContainer>
            </ModalHeader>
            <ModalBodyText>
              {message?.modalMessage ? htmr(message?.modalMessage) : 'SkillDorm Team.'}
            </ModalBodyText>
          </Modal>
        </Overlay>
      </Row>
    </>
  );
};

const Overlay = styled(Col)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
`;

const Modal = styled.div`
  max-width: 656px;
  width: 70%;
  z-index: 100;
  background: #ffffff;
  box-shadow: 0px 4px 50px 1px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
`;

const ModalHeader = styled.div`
  width: 100%;
  height: 88px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const ModalHeaderTextContainer = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 25px;
`;

const ModalHeaderText = styled(Typography.Title)`
  margin-bottom: 0 !important;
  flex: 1;
  margin-left: 25px;
  color: #0966b8 !important;
`;

const ModalBodyText = styled(Typography.Paragraph)`
  padding: 25px;
  width: 100%;
  height: 286px;
`;

export default MaintenanceModal;
