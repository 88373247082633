import Axios from 'axios';

const service = 'Notification';

export const createEmail = async param => {
  const { data } = await Axios.post(`/${service}/api/Email/Email/Template/Create`, param);
  return data;
};
export const updateEmail = async param => {
  const { data } = await Axios.post(`/${service}/api/Email/Email/Template/Update`, param);
  return data;
};

export const sendEmail = async param => {
  const { data } = await Axios.post(`/${service}/api/Email/Send`, param);
  return data;
};

// InApp Notification

export const createNotification = async param => {
  const { data } = await Axios.post(`/${service}/api/InAppNotification/Create`, param);
  return data;
};
export const updateNotification = async param => {
  const { data } = await Axios.put(`/${service}/api/InAppNotification/Update`, param);
  return data;
};

export const toggleNotification = async param => {
  const { data } = await Axios.delete(
    `/${service}/api/InAppNotification/ToggleInAppNotification?id=${param?.id}&isActive=${param?.isActive}`
  );
  return data;
};

export const createCategory = async param => {
  const { data } = await Axios.post(`/${service}/api/InAppNotification/Category`, param);
  return data;
};

export const createFeature = async param => {
  const { data } = await Axios.post(`/${service}/api/InAppNotification/Features?name=${param}`);
  return data;
};
