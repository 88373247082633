import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  CheckBox,
  Grid,
  Heading,
  Text,
  ResponsiveContext
} from 'grommet';
import AddIcon from 'assets/svgs/ic_add.svg';
import { FormEdit, FormTrash } from 'grommet-icons';
import { isMobile } from 'utils';
import WorkFlowCard from 'components/common/WorkFlowCard';
import IconWrapper from 'components/common/IconWrapper';
import { getWorkflowByInstitutionId, getWorkflowById } from 'api/queries/Workflow';
import { deactivateWorkflow } from 'api/mutations/Workflow';

import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from 'store/actions/toast';

const WorkFlowAuthorizationCard = props => {
  const size = React.useContext(ResponsiveContext);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [createError, setCreateError] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  // const { data: workflows, refetch } = useQuery(
  //   ['workflows', auth.institutionId],
  //   getWorkflowByInstitutionId
  // );

  const [allowEdit, setAllowEdit] = useState(false);

  const handleChange = param => {
    remove();
    refetch();
    setSelectedId(param);
    setAllowEdit(true);
  };
  const { data: singleWorkflows, refetch, remove } = useQuery(
    ['singleWorkflows', selectedId],
    getWorkflowById
  );

  React.useEffect(() => {
    if (singleWorkflows && allowEdit) {
      props.setOpenEditModal(true);
      setAllowEdit(false);
      chk();
    }
  }, [allowEdit, singleWorkflows]);

  const chk = () => {
    //console.log('singleWorkflows33', singleWorkflows);
    props.setSelectedItem(singleWorkflows);
  };

  const [createMutate, info] = useMutation(deactivateWorkflow, {
    onSuccess: dataResult => {
      const { description, data, success } = dataResult;
      if (success) {
        setSuccessMessage(description);
        dispatch(showToast('success', 'successfully deactivated'));
        props.refetchWorkflow();
      } else {
        setCreateError(description);
        setIsSubmitting(false);
        console.log(description);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setCreateError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        // setCreateError(error.message);
        console.log(error);
      }
    }
  });

  const deactivate = param => {
    createMutate(param);
  };

  // console.log('workflows', singleWorkflows);
  //console.log('workflows2', props.workflows.data);

  return (
    <Box>
      {props.workflows && (
        <>
          {props.workflows.code !== '06' ? (
            props.workflows.data.map(item => (
              <Card pad="small" gap="medium">
                <CardHeader
                  direction={isMobile(size) ? 'column' : 'row'}
                  align={isMobile(size) ? 'start' : 'center'}
                  background="#fff"
                  pad="small"
                  border="bottom">
                  <Box
                    justify="between"
                    align="center"
                    direction="row"
                    gap={isMobile(size) ? 'xsmall' : 'medium'}>
                    <Heading
                      margin="none"
                      size={isMobile(size) ? 'xsmall' : 'xsmall'}
                      level={isMobile(size) ? 5 : 4}>
                      Name: {item.name}
                    </Heading>
                    <Heading
                      margin="none"
                      size={isMobile(size) ? 'xsmall' : 'xsmall'}
                      level={isMobile(size) ? 5 : 4}>
                      Category: {item.courseCategoryName}
                    </Heading>
                    {item.isActive ? (
                      <Button
                        style={{ padding: '0px' }}
                        icon={<FormEdit color="brand" size="medium" />}
                        onClick={() => handleChange(item.id)}
                      />
                    ) : null}
                    {item.isActive ? (
                      <Button
                        style={{ padding: '0px' }}
                        icon={<FormTrash color="brand" size="medium" />}
                        onClick={() => deactivate(item.id)}
                      />
                    ) : null}
                  </Box>

                  {/* <Box
                    justify="between"
                    align="center"
                    direction="row"
                    gap={isMobile(size) ? 'xsmall' : 'medium'}>
                    {item.isActive ? (
                      <Button
                        style={{ padding: '0px' }}
                        icon={<FormTrash color="brand" size="medium" />}
                        onClick={() => deactivate(item.id)}
                      />
                    ) : null}
                  </Box> */}

                  <Box>
                    {/* <CheckBox
                      toggle
                      checked={item.isActive}
                      label="Status"
                      onChange={event => setChecked(event.target.checked)}
                    /> */}
                    <Text>
                      Status:{' '}
                      {item.isActive ? (
                        <Text color={'green'}>Active</Text>
                      ) : (
                        <Text color={'red'}>Deactivated</Text>
                      )}
                    </Text>
                  </Box>
                  <Box align={isMobile(size) ? 'start' : 'end'}>
                    {/* <Heading
                      margin="none"
                      size={isMobile(size) ? 'xsmall' : undefined}
                      level={isMobile(size) ? 5 : 5}>
                      Authorization level 1
                    </Heading> */}
                    <Heading
                      margin="none"
                      size={isMobile(size) ? 'xsmall' : undefined}
                      level={isMobile(size) ? 5 : 5}>
                      ({item.noOfApprovals}) approvals required
                    </Heading>
                  </Box>
                </CardHeader>
                <CardBody background="#fff" pad="small" gap="medium">
                  <Grid
                    columns={{
                      count: 4,
                      size: 'auto'
                    }}
                    gap="small">
                    {item.workflowLevels.map(item =>
                      item.users.map(i => (
                        <WorkFlowCard title={i.name} address="dddd" mobile="dfffff" />
                      ))
                    )}

                    {/* <Box direction="row" align="center" gap="small" pad="small">
      <Box
        onClick={() => props.setOpenModal(true)}
        style={{ borderRadius: '100%', border: '1px dashed #777' }}
        width="40px"
        height="40px"
        direction="row"
        align="center"
        gap="small"
        pad="small"
      >
        <IconWrapper icon={AddIcon} width="30px" height="30px" />
      </Box>
    </Box> */}
                  </Grid>
                </CardBody>
              </Card>
            ))
          ) : (
            <Box
              justify="between"
              align="center"
              direction="row"
              gap={isMobile(size) ? 'xsmall' : 'medium'}>
              <Heading
                margin="none"
                size={isMobile(size) ? 'xsmall' : 'xsmall'}
                level={isMobile(size) ? 5 : 4}>
                No Workflow created!
              </Heading>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default WorkFlowAuthorizationCard;
