import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import useCurrencyFormater from 'components/hooks/useCurrencyFormater';
import { timeStampFormat } from 'utils';

const CoursePaymentTable = ({
  result,
  currentPage,
  pageSize,
  setCurrentPage,
  setPageSize,
  toggleExport
}) => {
  const [pagination, setPagination] = useState();
  const currencyFormatter = useCurrencyFormater();

  useEffect(() => {
    if (!toggleExport) {
      setPagination({
        current: currentPage,
        pageSize: pageSize,
        total: result?.data[0]?.totalRows
      });
    }
  }, [result, currentPage, pageSize, toggleExport]);
  const columns = [
    {
      title: 'S/NO',
      dataIndex: 'sno',
      width: 100,
      key: 'sno'
    },
    {
      title: 'ID',
      dataIndex: 'id',
      width: 100,
      key: 'id'
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      width: 100,
      key: 'name'
    },
    {
      title: 'ENROLLED',
      dataIndex: 'enrolledDate',
      width: 100,
      key: 'enrolled'
    },
    {
      title: 'COURSE NAME',
      dataIndex: 'courseName',
      width: 100,
      key: 'courseName',
      ellipsis: true,
      render: text => <p style={{ color: '#1890ff' }}>{text}</p>
    },
    {
      title: 'AMOUNT',
      dataIndex: 'courseAmount',
      width: 100,
      key: 'courseAmount'
    },
    {
      title: 'DISCOUNT',
      dataIndex: 'discount',
      width: 100,
      key: 'discount'
    },
    {
      title: 'INST.',
      dataIndex: 'institution',
      width: 100,
      key: 'institution'
    },
    {
      title: 'AMOUNT PAID',
      dataIndex: 'pricePaid',
      width: 100,
      key: 'pricePaid'
    },
    {
      title: 'STATUS',
      dataIndex: 'transactionStatusName',
      width: 100,
      key: 'transactionStatusName'
    }
  ];

  const handleTableChange = pagination => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const tableData = result?.data?.map((item, i) => ({
    ...item,
    key: i,
    enrolledDate: timeStampFormat(item?.enrolledDate),
    courseAmount: currencyFormatter('NGN', 'en-NG', item.courseAmount),
    pricePaid: currencyFormatter('NGN', 'en-NG', item.pricePaid),
    discount: currencyFormatter('NGN', 'en-NG', item.discount)
  }));

  return (
    <Table
      style={{ width: '100%', overflow: 'auto' }}
      columns={columns}
      dataSource={tableData}
      pagination={pagination}
      onChange={handleTableChange}
    />
  );
};

export default CoursePaymentTable;
