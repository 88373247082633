import React from 'react';
import { Box, Form, Button, ResponsiveContext, Text, FormField } from 'grommet';
import { Controller, useForm } from 'react-hook-form';
import InputController from 'components/common/InputController';
import ButtonLoader from 'components/common/ButtonLoader';
import { isMobile } from 'utils';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { webSiteRegex } from 'data/regex';

const AdminPublicForm = props => {
  const size = React.useContext(ResponsiveContext);
  const formFieldStyle = {
    flexDirection: isMobile(size) ? 'column' : 'row',
    alignItems: isMobile(size) ? 'stretch' : 'center',
    justifyContent: isMobile(size) ? 'flex-start' : 'space-between'
  };
  const { errors, handleSubmit, setValue, register, control, unregister } = useForm();
  React.useEffect(() => {
    register('biography', { required: false });
    return () => {
      unregister('biography');
    };
  }, [register, unregister]);

  const {
    prev,
    headlineValue,
    setHeadlineValue,
    websiteValue,
    setWebsiteValue,
    biographyValue,
    setBiographyValue,
    youTubeValue,
    setYouTubeValue,
    faceBookValue,
    setFaceBookValue,
    linkedInValue,
    setLinkedInValue,
    twitterValue,
    setTwitterValue,
    isSubmitting,
    viewProfile,
    onProfileSubmit,
    adminUserDetails
  } = props;

  const onSubmit = values => {
    onProfileSubmit(values);
    console.log(values);
  };
  // console.log(22, headlineValue);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: 475 }}>
      <InputController
        disabled={viewProfile}
        Controller={Controller}
        controllerName="headline"
        onChangeCallback={e => setHeadlineValue(e)}
        width="275px"
        errors={errors}
        control={control}
        defaultValue={headlineValue || adminUserDetails?.headline}
        label="Headline"
        name="headline"
        htmlfor="headline"
        placeholder="Headline"
      />
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Biography"
          name="biography"
          htmlfor="biography"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <Box style={{ width: isMobile(size) ? '100%' : 275 }}>
            <CKEditor
              editor={ClassicEditor}
              data={biographyValue || adminUserDetails?.biography}
              onInit={editor => {
                // You can store the "editor" and use when it is needed.
                console.log('Editor is ready to use!', editor);
                if (adminUserDetails?.biography) {
                  const data = editor.getData();
                  setValue('biography', data);
                }
              }}
              onChange={(event, editor, value) => {
                const data = editor.getData();
                setValue('biography', data);
                setBiographyValue(data);
                console.log({ event, editor, data });
              }}
              onBlur={(event, editor) => {
                console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                console.log('Focus.', editor);
              }}
            />
          </Box>
        </FormField>
        {errors.biography && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.biography.message}
          </Text>
        )}
      </Box>
      <InputController
        disabled={viewProfile}
        Controller={Controller}
        controllerName="website"
        errors={errors}
        onChangeCallback={e => setWebsiteValue(e)}
        type="url"
        width="275px"
        otherRules={{
          pattern: {
            value: webSiteRegex,
            message: 'Not a valid Url'
          }
        }}
        helpMessage="e.g http://www.example.com"
        control={control}
        defaultValue={websiteValue || adminUserDetails?.website}
        label="Website"
        name="website"
        htmlfor="website"
        placeholder="Website"
      />
      <InputController
        disabled={viewProfile}
        Controller={Controller}
        controllerName="twitter"
        onChangeCallback={e => setTwitterValue(e)}
        errors={errors}
        otherRules={{
          pattern: {
            value: webSiteRegex,
            message: 'Not a valid Url'
          }
        }}
        type="url"
        width="275px"
        helpMessage="e.g http://www.example.com"
        control={control}
        defaultValue={twitterValue || adminUserDetails?.twitter}
        label="Twitter"
        name="twitter"
        htmlfor="twitter"
        placeholder="Twitter"
      />
      <InputController
        disabled={viewProfile}
        Controller={Controller}
        controllerName="youTube"
        onChangeCallback={e => setYouTubeValue(e)}
        errors={errors}
        otherRules={{
          pattern: {
            value: webSiteRegex,
            message: 'Not a valid Url'
          }
        }}
        type="url"
        width="275px"
        helpMessage="e.g http://www.example.com"
        control={control}
        defaultValue={youTubeValue || adminUserDetails?.youTube}
        label="YouTube"
        name="youTube"
        htmlfor="youTube"
        placeholder="YouTube"
      />
      <InputController
        disabled={viewProfile}
        Controller={Controller}
        controllerName="faceBook"
        onChangeCallback={e => setFaceBookValue(e)}
        errors={errors}
        otherRules={{
          pattern: {
            value: webSiteRegex,
            message: 'Not a valid Url'
          }
        }}
        type="url"
        width="275px"
        helpMessage="e.g http://www.example.com"
        control={control}
        defaultValue={faceBookValue || adminUserDetails?.faceBook}
        label="FaceBook"
        name="faceBook"
        htmlfor="faceBook"
        placeholder="FaceBook"
      />
      <InputController
        disabled={viewProfile}
        Controller={Controller}
        controllerName="linkedIn"
        onChangeCallback={e => setLinkedInValue(e)}
        errors={errors}
        otherRules={{
          pattern: {
            value: webSiteRegex,
            message: 'Not a valid Url'
          }
        }}
        type="url"
        width="275px"
        helpMessage="e.g http://www.example.com"
        control={control}
        defaultValue={linkedInValue || adminUserDetails?.linkedIn}
        label="LinkedIn"
        name="linkedIn"
        htmlfor="linkedIn"
        placeholder="LinkedIn"
      />

      <Box direction="row" gap="medium" justify="end">
        <Box>
          <Button
            size="small"
            margin={{ top: 'xsmall' }}
            onClick={() => {
              prev();
            }}
            label="Prev"
          />
        </Box>
        <Button
          type="submit"
          primary
          size="small"
          margin={{ top: 'xsmall' }}
          label={!isSubmitting && 'Save'}
          icon={isSubmitting ? <ButtonLoader isSubmitting={isSubmitting} /> : null}
        />
      </Box>
    </Form>
  );
};

export default AdminPublicForm;
