import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import styled from 'styled-components';
import { useQuery, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import AppText from 'components/common/AppText';
import radioActive from 'assets/svgs/radioActive.svg';
import radioOutline from 'assets/svgs/radioOutline.svg';
import cancelIcon from 'assets/svgs/cancelIconBlack.svg';
import noUpdate from 'assets/images/noUpdate.png';
import { showToast } from 'store/actions/toast';
import ButtonLoader from 'components/common/ButtonLoader';
import { getUnAssignedCourses } from 'api/queries/Course';
import PageLoader from 'components/common/PageLoader';
import { assignSurvey } from 'api/mutations/Course';

const AssignCourseModal = ({ visible, setOpen, surveyId, refetch }) => {
  const [loading, setLoading] = useState();
  const [courseIds, setCourseIds] = useState([]);
  const dispatch = useDispatch();
  const { data, isLoading, refetch: refetchUnAssignedCourses } = useQuery(
    ['unAssignedSurveyCourses'],
    getUnAssignedCourses
  );

  const [mutate] = useMutation(assignSurvey, {
    onMutate: () => setLoading(true),
    onSuccess: data => {
      refetch();
      refetchUnAssignedCourses();
      setLoading(false);
      dispatch(showToast('success', 'Courses Assigned Successfully'));
      setOpen(false);
    },
    onError: error => {
      setLoading(false);
      if (error.message === 'Network Error') {
        dispatch(
          showToast(
            'error',
            "We couldn't connect to the network, check your network or contact your admin"
          )
        );
      } else {
        dispatch(showToast('error', error.message));
      }
    }
  });

  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleSelect = courseId => {
    setCourseIds(prev =>
      prev.includes(courseId) ? [...prev.filter(id => id !== courseId)] : [...prev, courseId]
    );
  };

  const handleAssign = () => {
    mutate({ courseIds, surveyId });
  };

  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      width="45rem"
      footer={
        <FooterDiv>
          <ApplyBtn
            onClick={handleAssign}
            disabled={data?.data?.length === 0 || courseIds.length === 0}>
            {loading ? <ButtonLoader isSubmitting={loading} color="#0966b8" /> : 'Apply'}
          </ApplyBtn>
        </FooterDiv>
      }>
      <HeaderDiv>
        <div>
          <AppText value="Courses" bottom="7px" weight={'400px'} size="24px" color="#2A2828" />
          <AppText
            value="Select the available courses and apply to the survey"
            bottom="0"
            size="12px"
          />
        </div>
        <img
          src={cancelIcon}
          alt=""
          loading="lazy"
          style={{ cursor: 'pointer' }}
          onClick={handleCancel}
        />
      </HeaderDiv>
      <ContentDiv>
        {data?.data?.map(course => (
          <CourseDiv
            active={courseIds.includes(course.courseId)}
            onClick={() => handleSelect(course?.courseId)}>
            <img
              src={courseIds.includes(course.courseId) ? radioActive : radioOutline}
              alt="radio button"
            />
            <AppText value={course.title} size="16px" bottom={'0'} />
          </CourseDiv>
        ))}
        {data?.data?.length === 0 && (
          <NoDataDiv>
            <img src={noUpdate} alt="" />
            <AppText
              value={'All Courses have been assigned to a survey'}
              size="25px"
              top=".5rem"
              color={'#7f7f7f'}
            />
          </NoDataDiv>
        )}
      </ContentDiv>
      <PageLoader isLoading={isLoading} />
    </Modal>
  );
};

export default AssignCourseModal;

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const ContentDiv = styled.div`
  padding: 1.5rem 0;
  padding-right: 2rem;
  max-height: 17rem;
  overflow-y: auto;
`;
const CourseDiv = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  gap: 2rem;
  width: inherit;
  margin-bottom: 1rem;
  background: #f8f8fa;
  word-wrap: break-word;
  min-height: 48px;
  border: ${props => (props.active ? '1px solid #0966b8' : '1px solid #fdfdfd')};
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    border: 1px solid #0966b8;
  }
`;

const FooterDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  min-height: 4rem;
  gap: 1rem;
`;

const ApplyBtn = styled(Button)`
  background: #0966b8;
  height: 3rem;
  border-radius: 3px;
  width: 90px;
  color: #fff;
  border: none;
`;

const NoDataDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: '10rem';
`;
