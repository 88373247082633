import React, { useEffect, useState } from 'react';
import { Table } from 'antd';

const ManageRegisteredUserTable = ({
  userResults,
  currentPage,
  pageSize,
  setCurrentPage,
  setPageSize,
  toggleExport
}) => {
  const [pagination, setPagination] = useState();

  useEffect(() => {
    if (!toggleExport) {
      setPagination({
        current: currentPage,
        pageSize: pageSize,
        total: userResults?.data[0]?.totalRows
      });
    }
  }, [userResults, currentPage, pageSize, toggleExport]);

  const columns = [
    {
      title: 'S/NO',
      dataIndex: 'sno',
      key: 'sno'
    },
    {
      title: 'FIRSTNAME',
      dataIndex: 'firstName',
      key: 'firstName'
    },
    {
      title: 'LASTNAME',
      dataIndex: 'lastName',
      key: 'lastName'
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'COUNTRY',
      dataIndex: 'countryName',
      key: 'countryName'
    },
    {
      title: 'ENROLLED COURSES',
      dataIndex: 'noOfEnrolledCourses',
      key: 'noOfEnrolledCourses'
    },
    {
      title: 'TIME STAMP',
      dataIndex: 'dateCreated',
      key: 'timeStamp'
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive'
    }
  ];

  const handleTableChange = pagination => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };
  const tableData = userResults?.data?.map((item, i) => ({
    ...item,
    key: i,
    isActive: `${item.isActive ? 'Active' : 'Inactive'}`,
    dateCreated: `${item?.dateCreated?.split('T')[0]} - ${
      item?.dateCreated?.split('T')[1]?.split('.')[0]
    }`
  }));

  return (
    <Table
      style={{ width: '100%', overflow: 'auto' }}
      columns={columns}
      dataSource={tableData}
      pagination={pagination}
      onChange={handleTableChange}
    />
  );
};

export default ManageRegisteredUserTable;
