import React from 'react';
import { Box, Button, Select, TextInput } from 'grommet';
import DateInput from 'components/extras/DateInput';

const ApproveCourseSearch = ({
  search,
  setSearch,
  setDateRange,
  courseCategory,
  courseCategoryId,
  setCourseCategoryId,
  fetchCoursesForApproval,
  approvalStatus
}) => {
  return (
    <Box direction="row" gap="medium" justify="start">
      <Box>
        <TextInput placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} />
      </Box>
      <DateInput range={true} placeholder="Date Range" onChange={value => setDateRange(value)} />
      <Select
        placeholder="Course Category"
        options={courseCategory?.data.map(d => d.name) || []}
        value={courseCategory?.data.find(item => item.id === courseCategoryId)?.name}
        onChange={({ option }) => {
          setCourseCategoryId(courseCategory?.data.find(item => item.name === option)?.id);
        }}
      />
      <Button
        primary
        label="Search"
        style={{ width: 200 }}
        onClick={() => fetchCoursesForApproval(approvalStatus)}
      />
    </Box>
  );
};

export default ApproveCourseSearch;
