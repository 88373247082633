import React from 'react';
import {
  SurveyWrapper,
  IntroDetails,
  FlexRow,
  HighlightText,
  LineSpacer,
  ActionButton
} from 'components/utils/CourseSurvey/CourseSurvey.styled';

const SurveyIntro = ({ description, numberOfQuestions, setSurveyStep, setSurveySkipped }) => {
  return (
    <SurveyWrapper>
      <IntroDetails>{description}</IntroDetails>
      <FlexRow>
        <HighlightText>
          {numberOfQuestions} {numberOfQuestions > 1 ? 'Questions' : 'Question'}
        </HighlightText>
        <LineSpacer />
        <HighlightText>Less than 5 Minutes</HighlightText>
      </FlexRow>
      <FlexRow padding="60px 0px 0px">
        <ActionButton
          type="primary"
          margin="8px 24px"
          onClick={() => {
            setSurveySkipped(false);
            setSurveyStep(1);
          }}>
          Start Survey
        </ActionButton>
        <ActionButton
          margin="8px 24px"
          onClick={() => {
            setSurveySkipped(true);
            setSurveyStep(2);
          }}>
          Skip survey
        </ActionButton>
      </FlexRow>
    </SurveyWrapper>
  );
};

export default SurveyIntro;
