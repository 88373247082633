import * as emailTypes from 'store/actions/email';

// initial sign in state
const initialState = {
  results: null
};

const emailReducer = (state = initialState, action) => {
  switch (action.type) {
    case emailTypes.EMAIL_STATE:
      return {
        results: action.data
      };
    case emailTypes.CLEAR_EMAIL_STATE:
      return initialState;
    default:
      return state;
  }
};

export default emailReducer;
