import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Typography } from 'antd';
import styled from 'styled-components';
import { ButtonCTA } from 'components/common/ButtonCTA';
import device from 'configs/responsive/mediaQueries';
import { Element } from 'react-scroll';

const { mobile } = device;

const AboutInfo = ({ isMobile }) => {
  return (
    <Col md={10} offset={!isMobile && 2}>
      <DivWrapper>
        <Row>
          <Element name="scrollElement">
            <Title>
              Why <SkillDormTextWrapper>SkillDorm?</SkillDormTextWrapper>
            </Title>
          </Element>

          <Paragraph>
            We are building, partnering, and promoting a software platform that brings together the
            best learning institute and universities from all around the world to create courses for
            everyone, everywhere in the world.
          </Paragraph>
          {/* <ButtonCTA type="primary" size="large" color="#0966b8">
            Read More
          </ButtonCTA> */}
        </Row>
      </DivWrapper>
    </Col>
  );
};

AboutInfo.propTypes = {};

export default AboutInfo;

const DivWrapper = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  color: #4d4d53 !important;
  @media ${mobile} {
    text-align: justify;
  }
`;

const SkillDormTextWrapper = styled.span`
  color: #0966b8;
`;

const Title = styled(Typography.Title)`
  position: relative;
  margin-bottom: 1.8rem !important;
  &::before {
    content: '';
    top: 110%;
    left: 0%;
    height: 3px;
    width: 15%;
    color: #0966b8;
    background: #0966b8;
    position: absolute;
  }
  @media ${mobile} {
    font-size: 1.5em !important;
  }
`;

const Paragraph = styled(Typography.Paragraph)`
  @media ${mobile} {
    font-size: 0.9em !important;
  }
`;
