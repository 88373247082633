import React, { useState, useEffect, useCallback } from 'react';
import { Radio } from 'antd';
import {
  SurveyWrapper,
  FlexRow,
  FlexCol,
  HighlightText,
  ActionButton,
  QuestionWrapper,
  Question,
  OptionGroup,
  SurveyInput,
  RateButton,
  SurveyProgress
} from 'components/utils/CourseSurvey/CourseSurvey.styled';
import ArrowLeftIcon from 'assets/svgs/arrow-left.svg';
import ArrowRightIcon from 'assets/svgs/arrow-right.svg';
import axios from 'axios';
import { range } from 'utils';
import { useSelector, useDispatch } from 'react-redux';
import { showToast } from 'store/actions/toast';

const SurveyMain = ({ questions, setSurveyStep }) => {
  const dispatch = useDispatch();
  const { userId } = useSelector(state => state.auth);
  const { courseId } = useSelector(state => state.course);
  const [isLoading, setIsLoading] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [userResponses, setUserResponses] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [surveyInputText, setSurveyInputText] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);
  const [canSubmitUserResponses, setCanSubmitUserResponses] = useState(false);

  const handlePrevious = async questionIndex => {
    updateStateWithUserResponse(questionIndex);
    setQuestionIndex(questionIndex);
  };

  const handleNext = async questionIndex => {
    handleSetUserResponse();
    resetUserInputState();
    updateStateWithUserResponse(questionIndex);
    setQuestionIndex(questionIndex);
  };

  const updateStateWithUserResponse = questionIndex => {
    const userAnswer = userResponses[questionIndex]?.answer ?? null;
    switch (questions[questionIndex].questionType) {
      case 1:
        setSelectedOption(userAnswer);
        break;
      case 2:
        setSurveyInputText(userAnswer);
        break;
      case 3:
        setSelectedRating(userAnswer);
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = useCallback(
    (function () {
      let hasSubmit = false;
      return function () {
        if (hasSubmit) return setCanSubmitUserResponses(true);
        handleSetUserResponse();
        setCanSubmitUserResponses(true);
        hasSubmit = true;
      };
    })(),
    [questionIndex, selectedOption, surveyInputText, selectedRating]
  );

  const handleSetUserResponse = () => {
    setUserResponses(state => {
      const newState = [...state];
      const userResponseData = {
        surveyQuestionId: getQuestionId(),
        surveyQuestionOptionId:
          questions[questionIndex].questionType === 1
            ? getSelectedOptionDetails()?.surveyQuestionOptionId
            : null,
        answer:
          questions[questionIndex].questionType === 1
            ? getSelectedOptionDetails()?.option
            : questions[questionIndex].questionType === 2
            ? surveyInputText
            : questions[questionIndex].questionType === 3
            ? selectedRating
            : null
      };
      if (newState[questionIndex]) newState[questionIndex] = userResponseData;
      else newState.push(userResponseData);

      return newState;
    });
  };

  const resetUserInputState = () => {
    setSelectedOption(null);
    setSurveyInputText(null);
    setSelectedRating(null);
  };

  const getQuestionId = () => questions[questionIndex].surveyQuestionId;

  const getSelectedOptionDetails = () =>
    questions[questionIndex].options.find(option => option.option === selectedOption);

  const handleRadioOptionChange = e => setSelectedOption(e.target.value);

  const submitUserResponse = useCallback(async () => {
    try {
      setIsLoading(true);
      await axios.post('/Course/api/Survey/Response', {
        responses: userResponses,
        userId,
        courseId
      });
      setCanSubmitUserResponses(false);
      setIsLoading(false);
      setSurveyStep(2);
    } catch (error) {
      setCanSubmitUserResponses(false);
      setIsLoading(false);
      dispatch(showToast('error', 'Failed to submit survey, please try again'));
    }
  }, [userResponses, userId, courseId, dispatch, setSurveyStep]);

  useEffect(() => {
    if (canSubmitUserResponses) {
      submitUserResponse();
    }
  }, [canSubmitUserResponses, submitUserResponse]);

  return (
    <SurveyWrapper noBackgroundImage justifyContent="flex-start">
      <FlexRow justifyContent="flex-start">
        <SurveyProgress progressValue={((questionIndex + 1) / questions.length) * 100} />
      </FlexRow>
      <FlexRow justifyContent="flex-end">
        <HighlightText>
          {questionIndex + 1}/{questions.length} {questions.length > 1 ? 'Questions' : 'Question'}
        </HighlightText>
      </FlexRow>
      <FlexRow justifyContent="flex-start" alignItems="flex-start" padding="48px 0 0" responsive>
        <QuestionWrapper width="64px" height="60px" margin="0px 24px 0px 0px" responsive>
          <Question textAlign="center">Q{questionIndex + 1}</Question>
        </QuestionWrapper>
        <QuestionWrapper flex="1" alignSelf="stretch">
          <Question>{questions[questionIndex].question}</Question>
        </QuestionWrapper>
      </FlexRow>
      <FlexCol padding="0px 0px 0px 88px" responsive>
        {
          {
            1: (
              <OptionGroup onChange={handleRadioOptionChange} value={selectedOption}>
                {questions[questionIndex].options.map((option, index) => (
                  <Radio key={index} value={option.option}>
                    {option.option}
                  </Radio>
                ))}
              </OptionGroup>
            ),
            2: (
              <SurveyInput
                rows={4}
                value={surveyInputText}
                onChange={e => setSurveyInputText(e.target.value)}
              />
            ),
            3: (
              <FlexRow justifyContent="flex-start" padding="30px 0px 0px 0px" gap="20px">
                {range(
                  questions[questionIndex].options[0]?.minValue ?? 0,
                  questions[questionIndex].options[0]?.maxValue ?? 10,
                  questions[questionIndex].options[0]?.step ?? 1
                ).map(item => (
                  <RateButton key={item} onClick={() => setSelectedRating(item)}>
                    {item}
                  </RateButton>
                ))}
              </FlexRow>
            )
          }[questions[questionIndex].questionType]
        }
        <FlexRow
          justifyContent="space-between"
          alignItems="center"
          padding="64px 0px 0px"
          responsive>
          <ActionButton
            margin="8px 0px"
            disabled={questionIndex < 1}
            onClick={() => handlePrevious(questionIndex - 1)}>
            <img style={{ marginRight: 12, marginTop: -2 }} src={ArrowLeftIcon} alt="arrow-left" />
            Previous
          </ActionButton>
          <ActionButton
            type="outline"
            margin="8px 0px"
            loading={isLoading}
            disabled={(!selectedOption && !surveyInputText && !selectedRating) || isLoading}
            onClick={() =>
              questionIndex < questions.length - 1 ? handleNext(questionIndex + 1) : handleSubmit()
            }>
            {questionIndex < questions.length - 1 ? 'Next' : 'Submit'}
            <img style={{ marginLeft: 12, marginTop: -2 }} src={ArrowRightIcon} alt="arrow-right" />
          </ActionButton>
        </FlexRow>
      </FlexCol>
    </SurveyWrapper>
  );
};

export default SurveyMain;
