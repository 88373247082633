import React from 'react';
import { Box, Heading, Button } from 'grommet';
import styled from 'styled-components';
import { Typography } from 'antd';
import { FaChalkboardTeacher, FaHandshake } from 'react-icons/fa';
import { HiBadgeCheck } from 'react-icons/hi';
import { discovery, partner, programs } from 'userRoutes';
import { useHistory } from 'react-router-dom';
// import { Button } from 'react-scroll';

const Header = styled(Typography.Title)`
  text-align: center;
  position: relative;
  margin-bottom: 2rem !important;
  &::before {
    content: '';
    top: 110%;
    left: 25%;
    height: 3px;
    width: 15%;
    color: #0966b8;
    background: #0966b8;
    position: absolute;
  }
`;
const Paragraph = styled(Typography.Paragraph)`
  margin-bottom: 3rem !important;
  text-align: center;
`;

const Features = props => {
  const history = useHistory();

  return (
    <Box pad="24px" margin={{ top: 'xlarge' }}>
      <Box margin="auto">
        <Header>Featured Solutions</Header>
        <Paragraph>
          We are building, partnering and promoting a software platform that brings together
        </Paragraph>
      </Box>
      <Box direction="row" gap="2.5rem">
        <Box
          height="25rem"
          width="100%"
          background={{ color: '#fff' }}
          pad="large"
          style={{
            boxShadow: '.7px 3px 10px 2px #dfdfdf'
          }}>
          <Box
            height="5rem"
            width="5rem"
            style={{ margin: '0 auto 0rem auto', borderRadius: '50%', position: 'relative' }}
            background={{ color: '#FF7600' }}>
            {' '}
            <FaChalkboardTeacher
              style={{
                color: '#fff',
                fontSize: '2em',
                position: 'absolute',
                left: '.6em',
                top: '.5em',
                right: '.45em'
              }}
            />
          </Box>
          <Heading level="4" textAlign="center" margin={{ top: '2rem', bottom: '1.5rem' }}>
            Active Learning
          </Heading>
          <Paragraph textAlign="center">
            Increase access to high quality education to everyone, everywhere
          </Paragraph>
          <Button
            label="Learn more"
            onClick={() => {
              history.push(discovery);
            }}
            style={{
              color: '#fff',
              background: '#333',
              height: '3rem',
              width: '60%',
              border: '1px solid #333',
              display: 'block',
              margin: 'auto'
            }}></Button>
        </Box>

        <Box
          height="25rem"
          width="100%"
          background={{ color: '#fff' }}
          pad="large"
          style={{
            boxShadow: '.7px 3px 10px 2px #dfdfdf'
          }}>
          <Box
            height="5rem"
            width="5rem"
            style={{ margin: '0 auto 0rem auto', borderRadius: '50%', position: 'relative' }}
            background={{ color: '#0966B8' }}>
            {' '}
            <FaHandshake
              style={{
                color: '#fff',
                fontSize: '2em',
                position: 'absolute',
                left: '.6em',
                top: '.5em',
                right: '.45em'
              }}
            />
          </Box>
          <Heading level="4" textAlign="center" margin={{ top: '2rem', bottom: '1.5rem' }}>
            Accredited Partners
          </Heading>
          <Paragraph textAlign="center">
            Enhanced teaching and learning on camps and online
          </Paragraph>
          <Button
            label="Learn more"
            onClick={() => {
              history.push(partner);
            }}
            style={{
              color: '#fff',
              background: '#333',
              height: '3rem',
              width: '60%',
              border: '1px solid #333',
              display: 'block',
              margin: 'auto'
            }}></Button>
        </Box>

        <Box
          height="25rem"
          width="100%"
          background={{ color: '#fff' }}
          pad="large"
          style={{
            boxShadow: '.7px 3px 10px 2px #dfdfdf'
          }}>
          <Box
            height="5rem"
            width="5rem"
            style={{ margin: '0 auto 0rem auto', borderRadius: '50%', position: 'relative' }}
            background={{ color: '#E31D1A' }}>
            {' '}
            <HiBadgeCheck
              style={{
                color: '#fff',
                fontSize: '2em',
                position: 'absolute',
                left: '.6em',
                top: '.5em',
                right: '.45em'
              }}
            />
          </Box>
          <Heading level="4" textAlign="center" margin={{ top: '2rem', bottom: '1.5rem' }}>
            Verified Certifications
          </Heading>
          <Paragraph textAlign="center">
            Boost your resume with verified certifications from completed courses
          </Paragraph>
          <Button
            label="Learn more"
            onClick={() => {
              history.push(programs);
            }}
            style={{
              color: '#fff',
              background: '#333',
              height: '3rem',
              width: '60%',
              border: '1px solid #333',
              display: 'block',
              margin: 'auto'
            }}></Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Features;
