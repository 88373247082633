import React, { useState, useContext } from 'react';
import { useQuery } from 'react-query';

import styled from 'styled-components';
import SurveyForm from './SurveyForm';
import { SurveyContext } from 'context/SurveyContext';
import CreateSurveyTitle from './CreateSurveyTitle';
import { getCourseSurveyDetails } from 'api/queries/Course';
import PageLoader from 'components/common/PageLoader';
import EditSurveyForm from '../editSurvey/EditSurveyForm';

const CreateSurvey = ({ setCreateSurvey }) => {
  const [showForm, setShowForm] = useState();
  const { surveyId, isEdit } = useContext(SurveyContext);
  const { data, isLoading } = useQuery(['surveyDetails', surveyId], getCourseSurveyDetails);

  return (
    <Container>
      <>
        {showForm ? (
          <>
            {isEdit ? (
              <>
                {data?.data && (
                  <EditSurveyForm setCreateSurvey={setCreateSurvey} surveyData={data?.data} />
                )}
              </>
            ) : (
              <SurveyForm setCreateSurvey={setCreateSurvey} />
            )}
          </>
        ) : isEdit ? (
          <>
            {data?.data && (
              <CreateSurveyTitle
                setShowForm={setShowForm}
                setCreateSurvey={setCreateSurvey}
                surveyData={data?.data}
              />
            )}
          </>
        ) : (
          <CreateSurveyTitle setShowForm={setShowForm} setCreateSurvey={setCreateSurvey} />
        )}
      </>
      <PageLoader isLoading={isLoading} />
    </Container>
  );
};

export default CreateSurvey;

const Container = styled.div``;
