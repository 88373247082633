import React from 'react';
import { Box } from 'grommet';
import Modal from 'components/common/Modal';
import CreateUserForm from 'components/forms/CreateUserForm';

const UpdateUserModal = ({ refetchUserUpdate, updatedata, onClose }) => {
  return (
    <Box>
      <Modal width="475px" onClose={onClose} title="Update User" subTitle="Basic Information">
        <Box pad={{ horizontal: 'medium' }} margin={{ bottom: 'medium' }}>
          <CreateUserForm
            refetchUserUpdate={refetchUserUpdate}
            updatedata={updatedata}
            onClose={onClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default UpdateUserModal;
