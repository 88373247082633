import React, { useEffect } from 'react';
import IconWrapper from 'components/common/IconWrapper';
import { Box, Button, CheckBoxGroup, Heading, Layer, Paragraph, TextInput } from 'grommet';
import CloseIcon from 'assets/svgs/ic_close.svg';
import { getAllFilesByOwnerId } from 'api/queries/ContentLibrary';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from 'components/common/PageLoader';
import { showToast } from 'store/actions/toast';
import { useQuery } from 'react-query';

const FileUploader = ({
  type,
  fileRef,
  contentType,
  setFileName,
  setShowFileUploader,
  setCourseContentData,
  onClose
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { userName } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { data: files } = useQuery(['contentLibraryFiles', userName], getAllFilesByOwnerId);
  const [filteredFiles, setFilteredFiles] = React.useState([]);

  useEffect(() => {
    if (!files) return;

    if (files?.data && Array.isArray(files?.data)) {
      setFilteredFiles(() =>
        files?.data?.filter(file => {
          if (type === 'video') {
            if (file.fileLocationUrl.endsWith('.mp4')) return file;
            else return null;
          } else if (type === 'compressVideo') {
            if (file.fileLocationUrl.endsWith('.zip')) return file;
            else return null;
          } else if (type === 'image') {
            if (
              file.fileLocationUrl.endsWith('.png') ||
              file.fileLocationUrl.endsWith('.jpeg') ||
              file.fileLocationUrl.endsWith('.jpg') ||
              file.fileLocationUrl.endsWith('.gif')
            )
              return file;
            else return null;
          } else if (type === 'article' || type === 'quiz') {
            if (file.fileLocationUrl.endsWith('.txt')) return file;
            else return null;
          } else {
            if (file.fileLocationUrl.endsWith('.mp3')) return file;
            else return null;
          }
        })
      );
    } else {
      dispatch(showToast('error', files?.data || 'An error occured kindly contact your admin'));
    }
  }, [dispatch, files, type]);

  return (
    <Layer responsive={false} animation="slide" onEsc={onClose} modal style={{ width: 650 }}>
      <Box pad="medium">
        <Box direction="row" fill="horizontal" justify="between" align="center" gap="small">
          <Heading margin="none" level={4} size="small">
            File Uploader
          </Heading>
          <Button
            plain
            icon={<IconWrapper icon={CloseIcon} width="20px" height="20px" />}
            onClick={onClose}
          />
        </Box>
        <div style={{ position: 'relative' }}>
          <Box
            height="200px"
            style={{ border: '2px dashed #acacac', position: 'relative' }}
            margin="15px 0px"
            align="center"
            justify="center">
            <Paragraph>Drag & Drop your files here</Paragraph>
            <Button primary label="Browse" />
          </Box>
          <TextInput
            type="file"
            ref={fileRef}
            accept={
              {
                image: '.png, .jpeg, .jpg, .gif',
                video: '.mp4',
                audio: '.mp3',
                article: '.txt, .pdf, .xls, .xlsx',
                quiz: '.txt',
                compressVideo: '.zip'
              }[type]
            }
            style={{ position: 'absolute', top: -215, left: 0, height: 200, opacity: 0 }}
            onChange={async () => {
              try {
                setIsLoading(true);
                if (fileRef && fileRef.current?.files) setFileName(fileRef.current.files[0].name);
                setShowFileUploader(false);
              } catch (error) {
                dispatch(showToast('error', 'Failed to upload files from local'));
                console.log(error);
              } finally {
                setIsLoading(false);
              }
            }}
          />
        </div>
        <Box border="between" gap="small">
          <Box>
            <Heading level="4" size="small" margin={{ bottom: '10px' }}>
              Uploaded{' '}
              {
                {
                  image: 'Images',
                  video: 'Videos',
                  audio: 'Audios',
                  article: 'Articles',
                  quiz: 'Quiz'
                }[type]
              }
            </Heading>
          </Box>
          <div style={{ overflowY: 'auto', height: 108, paddingRight: 20 }}>
            {files ? (
              files.data.length > 0 ? (
                <>
                  {filteredFiles.length > 0 ? (
                    <CheckBoxGroup
                      valueKey="url"
                      options={filteredFiles.map(file => ({
                        label: file.fileLocationUrl.slice(file.fileLocationUrl.indexOf('/') + 1),
                        url: file.fileLocationUrl
                      }))}
                      onChange={({ option }) => {
                        setCourseContentData({
                          assetType: contentType[type].assetType,
                          guid: null,
                          url: option.url
                        });
                        setFileName(option.label);
                        setShowFileUploader(false);
                      }}
                    />
                  ) : (
                    <Paragraph>
                      No uploaded{' '}
                      {
                        {
                          image: 'images',
                          video: 'videos',
                          audio: 'audios',
                          article: 'articles',
                          quiz: 'quiz'
                        }[type]
                      }
                    </Paragraph>
                  )}
                </>
              ) : (
                <Paragraph>No uploaded file</Paragraph>
              )
            ) : (
              <Paragraph>Fetching uploaded files...</Paragraph>
            )}
          </div>
        </Box>
        <PageLoader isLoading={isLoading} left="0px" top="0px" />
      </Box>
    </Layer>
  );
};

export default FileUploader;
