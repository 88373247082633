import React from 'react';
import { Typography } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { discovery, coursecompleted, terms } from 'userRoutes';
import {
  SurveyWrapper,
  IntroDetails,
  FlexRow,
  TitleText,
  OutroDetailsWrapper,
  ActionButton
} from 'components/utils/CourseSurvey/CourseSurvey.styled';
import SurveyCompletedImage from 'assets/images/survey-completed.png';
import ArrowRightIcon from 'assets/svgs/arrow-right.svg';
import AchievementIcon from 'assets/svgs/achievement.svg';

const SurveyOutro = ({ surveySkipped }) => {
  const history = useHistory();
  const { Text } = Typography;

  return (
    <SurveyWrapper noBackgroundImage>
      <img
        src={SurveyCompletedImage}
        alt="survey-completed"
        style={{ width: 150, height: 200, objectFit: 'contain' }}
      />
      <TitleText>{surveySkipped ? 'Course Completed' : 'Survey Submitted'}</TitleText>
      <OutroDetailsWrapper>
        <IntroDetails noMargin>
          {surveySkipped
            ? 'Thank you for taking the time to complete this course.'
            : 'Thank you for taking the time to complete this survey. Our feedback will be shared anonymously with educators to improve the course.'}
        </IntroDetails>
      </OutroDetailsWrapper>
      {!surveySkipped && (
        <FlexRow padding="30px 0px 0px">
          <Text>
            Read our{' '}
            <Link target="_blank" rel="noopener noreferrer" to={terms}>
              privacy policy
            </Link>{' '}
            to find out how we store and use your data.
          </Text>
        </FlexRow>
      )}
      <FlexRow padding="60px 0px 0px">
        <ActionButton type="outline" margin="8px 24px" onClick={() => history.push(discovery)}>
          Explore more courses
          <img style={{ marginLeft: 12, marginTop: -2 }} src={ArrowRightIcon} alt="arrow-right" />
        </ActionButton>
        <Link style={{ textDecoration: 'underline' }} to={coursecompleted}>
          View your Achievement
          <img style={{ marginLeft: 8 }} src={AchievementIcon} alt="achievement" />
        </Link>
      </FlexRow>
    </SurveyWrapper>
  );
};

export default SurveyOutro;
