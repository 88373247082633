import React from 'react';
import { Box, Form, FormField, TextInput, Button, Text, ResponsiveContext, Stack } from 'grommet';
import { Hide, View } from 'grommet-icons';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { changePassword } from 'api/mutations/Authentication';
import { animate, isMobile } from 'utils';
import ButtonLoader from 'components/common/ButtonLoader';
import { useSelector } from 'react-redux';

const ChangePasswordForm = ({ setSuccessMessage }) => {
  const { userName } = useSelector(state => state.auth);
  const [reveal, setReveal] = React.useState(false);
  const { register, errors, handleSubmit } = useForm();
  const size = React.useContext(ResponsiveContext);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [changePasswordError, setChangePasswordError] = React.useState(null);
  const [formMessageAnimate] = React.useState([
    animate({ type: 'fadeIn', delay: 500, duration: 500 }),
    animate({ type: 'slideUp', delay: 500, duration: 500 })
  ]);

  const [changePasswordMutate] = useMutation(changePassword, {
    onSuccess: data => {
      const { description, success } = data;
      if (success) {
        setSuccessMessage(description);
      } else {
        setChangePasswordError(description);
        setIsSubmitting(false);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setChangePasswordError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        setChangePasswordError(error.message);
      }
    }
  });

  const onSubmit = async ({ currentPassword, newPassword, confirmNewPassword }) => {
    console.log(userName, newPassword, currentPassword);
    if (currentPassword === newPassword) {
      setChangePasswordError('Current password and New password can not be the same');
      return;
    } else if (newPassword !== confirmNewPassword) {
      setChangePasswordError('New password and Confirm password must be the same');
      return;
    }
    if (isSubmitting) return;

    try {
      setChangePasswordError(null);
      setIsSubmitting(true);
      await changePasswordMutate({
        userName: userName,
        oldPassword: currentPassword,
        password: newPassword
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <>
      {!changePasswordError && (
        <Box margin={{ bottom: 'medium' }} animation={formMessageAnimate[0]}>
          <Box animation={formMessageAnimate[1]}>
            <Text>You are about to change password</Text>
          </Box>
        </Box>
      )}
      {changePasswordError && (
        <Box margin={{ bottom: 'medium' }} animation={formMessageAnimate[0]}>
          <Box animation={formMessageAnimate[1]}>
            <Text color="status-critical">{changePasswordError}</Text>
          </Box>
        </Box>
      )}
      <Form
        style={isMobile(size) ? { width: 250 } : { width: 300 }}
        onSubmit={handleSubmit(onSubmit)}>
        <Box margin={{ bottom: 'small' }}>
          <FormField
            name="currentPassword"
            htmlfor="currentPassword-id"
            margin={{ bottom: 'none' }}>
            <Stack anchor="right">
              <TextInput
                ref={register({ required: 'Current Password is required' })}
                id="currentPassword-id"
                name="currentPassword"
                placeholder="Current Password"
                type={reveal ? 'text' : 'password'}
                style={{ paddingRight: '45px' }}
              />
              <Button
                icon={reveal ? <View size="medium" /> : <Hide size="medium" />}
                onClick={() => setReveal(!reveal)}
              />
            </Stack>
          </FormField>
          {errors.currentPassword && (
            <Text color="status-critical" size="small">
              {errors.currentPassword.message}
            </Text>
          )}
        </Box>
        <Box margin={{ bottom: 'small' }}>
          <FormField name="newPassword" htmlfor="newPassword-id" margin={{ bottom: 'none' }}>
            <TextInput
              ref={register({
                required: 'New Password is required',
                pattern: {
                  value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/,
                  message:
                    'Password must contain at least 8 characters, an uppercase alphabet,a lower case alphabet, a number and special character'
                }
              })}
              id="newPassword-id"
              name="newPassword"
              placeholder="New Password"
              type="password"
            />
          </FormField>
          {errors.newPassword && (
            <Text color="status-critical" size="small">
              {errors.newPassword.message}
            </Text>
          )}
        </Box>
        <Box margin={{ bottom: 'medium' }}>
          <FormField
            name="confirmNewPassword"
            htmlfor="confirmNewPassword-id"
            margin={{ bottom: 'none' }}>
            <TextInput
              ref={register({ required: 'Confirm New Password is required' })}
              id="confirmNewPassword-id"
              name="confirmNewPassword"
              placeholder="Confirm New Password"
              type="password"
            />
          </FormField>
          {errors.confirmNewPassword && (
            <Text color="status-critical" size="small">
              {errors.confirmNewPassword.message}
            </Text>
          )}
        </Box>
        <Button
          type="submit"
          primary
          label={!isSubmitting && 'Submit'}
          fill="horizontal"
          icon={isSubmitting ? <ButtonLoader isSubmitting={isSubmitting} /> : null}
        />
      </Form>
    </>
  );
};

export default ChangePasswordForm;
