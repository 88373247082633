import Axios from 'axios';

const service = 'Authentication';

export const adminlogin = async ({ userName, password, isEncode = false }) => {
  try {
    const { data } = await Axios.post(`/${service}/api/Account/Admin/SignIn`, {
      userName,
      password,
      isEncode
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const login = async ({ userName, password, isEncode = false }) => {
  try {
    const { data } = await Axios.post(`/${service}/api/Account/Admin/SignIn`, {
      userName,
      password,
      isEncode
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const studentlogin = async ({ userName, password, isEncode = false }) => {
  try {
    const { data } = await Axios.post(`/${service}/api/Account/Student/SignIn`, {
      userName,
      password,
      isEncode
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const forgetPassword = async ({ userName, student }) => {
  const { data } = await Axios.post(`/${service}/api/Account/User/ForgotPassword`, {
    userName,
    student
  });
  return data;
};

export const changePassword = async ({ userName, oldPassword, password }) => {
  const { data } = await Axios.post(`/${service}/api/Account/User/ChangePassword`, {
    userName,
    oldPassword,
    password
  });
  return data;
};

//this is for users from smeville or other third parties to set their password
export const setPassword = async ({ userName, password }) => {
  const { data } = await Axios.post(`/${service}/api/Account/Student/SetPassword`, {
    userName,
    password
  });
  return data;
};

export const updateAdminUser = async formdata => {
  const { data } = await Axios.put(`/${service}/api/Account/Admin/UpdateAdminUser`, formdata);
  return data;
};

export const updateUser = async formdata => {
  const { data } = await Axios.post(`/${service}/api/Account/User/UpdateUser`, formdata);
  return data;
};

export const updateStudentProfile = async formdata => {
  const { data } = await Axios.put(
    `/${service}/api/Account/Student/UpdateStudentProfile`,
    formdata
  );
  return data;
};

export const toggleUser = async ({ id, isActive }) => {
  const { data } = await Axios.delete(
    `/${service}/api/Account/User/ActivateDeactivateUser?id=${id}&isActive=${isActive}`
  );
  return data;
};

export const updateUserInfo = async ({ UserId, firstName, lastName, phoneNumber, roleId }) => {
  const { data } = await Axios.put(
    `/${service}/api/Account/Admin/UpdateUserInfo?UserId=${UserId}&firstName=${firstName}&lastName=${lastName}&phoneNumber=${phoneNumber}&roleId=${roleId}`
  );
  return data;
};

export const createUser = async ({
  institutionId,
  firstName,
  userName,
  institutionCode,
  lastName,
  roles,
  age,
  password,
  middleName,
  email,
  phoneNumber
}) => {
  const { data } = await Axios.post(`/${service}/api/Account/Admin/Create`, {
    institutionId,
    firstName,
    userName,
    institutionCode,
    lastName,
    age,
    middleName,
    password,
    roles,
    email,
    phoneNumber
  });
  return data;
};

export const refreshToken = async ({ token }) => {
  const { data } = await Axios.post(`/${service}/api/Account/RefreshToken`, { token });
  return data;
};

export const createUpdateRole = async ({ roleId, roleType, name, permissionId }) => {
  try {
    const { data } = await Axios.post(
      `/${service}/api/RolePermissionManager/CreateRoleandPermission`,
      {
        roleId,
        roleType,
        name,
        permissionId
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const activateDeactivateRole = async ({ roleId, isActive }) => {
  try {
    const { data } = await Axios.delete(
      `/${service}/api/RolePermissionManager/ActivateDeactivateRole?roleId=${roleId}&isActive=${isActive}`
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const googleSignIn = async () => {
  const { data } = await Axios.get(`/${service}/api/Account/GoogleLogin`);
  return data;
};

export const registerStudent = async ({
  institutionId,
  firstName,
  institutionCode,
  lastName,
  roles,
  age,
  id,
  password,
  middleName,
  email,
  country
}) => {
  const { data } = await Axios.post(`/${service}/api/Account/Student/Register`, {
    institutionId,
    firstName,
    institutionCode,
    lastName,
    age,
    middleName,
    password,
    roles,
    id,
    email,
    country
  });
  return data;
};
