import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Typography, Button, Form, Input, Select } from 'antd';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import { getPartners } from 'api/queries/Institution';
import { useMutation, useQuery } from 'react-query';
import { showToast } from 'store/actions/toast';
import { createRevenue } from 'api/mutations/Course';
import { useDispatch } from 'react-redux';
import { blockInvalidChar } from '../../utils/blockInvalidChar';
import { search } from 'api/mutations/Institution';

const RevenueForm = ({ handleClick, showFormular, refetch }) => {
  const [rows, setRows] = useState([0]);
  const addRow = () => {
    setRows(prev => [...prev, rows.length]);
  };

  const dispatch = useDispatch();

  // const { isLoading, data: partners } = useQuery('getPartners', getPartners);
  const { data: partners } = useQuery(['institutions'], search);
  const [name, setName] = useState('');
  const [percent, setPercent] = useState('');
  const [sumPercent, setSumPercent] = useState(0);
  const [selectedPartnerIds, setSelectedPartnerIds] = useState([]);
  const [selectedinstitutionIds, setSelectedInstitutionIds] = useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const percentageInputRef = React.useRef();

  const [revenuePartnerModel, setRevenuePartnerModel] = useState([
    {
      sNo: 0,
      revenuePatnerType: 0,
      partnerId: 0,
      percentage: 0
    }
  ]);

  var sum = 0;
  for (var i = 0; i < revenuePartnerModel.length; i++) {
    sum += isNaN(revenuePartnerModel[i].percentage) ? 0 : Number(revenuePartnerModel[i].percentage);
  }

  const addPartnerHandler = () => {
    setRevenuePartnerModel(state => {
      const newState = [...state];
      newState.push({
        revenuePatnerType: 0,
        partnerId: 0,
        percentage: 0
      });
      return newState;
    });
  };

  const [createRevenueMutate] = useMutation(createRevenue, {
    onSuccess: data => {
      setIsSubmitting(false);
      if (data.success) {
        dispatch(showToast('success', 'Revenue created successfully'));
        handleClick();
        refetch();
      } else {
        dispatch(showToast('error', data.description));
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        dispatch(
          showToast(
            'error',
            <>
              We couldn't connect to the server.
              <br />
              Check your network or contact your admin
            </>
          )
        );
      } else {
        dispatch(showToast('error', error.message));
      }
    }
  });

  const onSubmit = async () => {
    try {
      const data = {
        name: name,
        revenuePartnerModel: revenuePartnerModel
      };
      let requirePartnerId = false;
      let requireRevenuePatnerType = false;
      revenuePartnerModel.forEach(element => {
        if (element.partnerId === undefined) {
          requirePartnerId = true;
          return;
        }
        if (element.revenuePatnerType === undefined) {
          requireRevenuePatnerType = true;
          return;
        }
      });
      if (requirePartnerId) {
        dispatch(showToast('warning', 'Revenue partnerId is required!'));
        return;
      }
      if (requireRevenuePatnerType) {
        dispatch(showToast('warning', 'Revenue revenuePatnerType is required!'));
        return;
      }
      if (name === '') {
        dispatch(showToast('warning', 'Revenue name is required!'));
        return;
      }

      await createRevenueMutate(data);
    } catch (error) {
      console.log(error);
    }
  };

  const RemoveRow = (index, row) => {
    setRows(state => {
      const newState = [...state];
      newState.pop();
      return newState;
    });
    setRevenuePartnerModel(state => {
      let newState = [...state];
      newState = newState.filter((row, i) => i !== index);
      setSelectedPartnerIds(newState.map(data => data.sNo));
      return newState;
    });
  };

  const revenuePartnerTypeChange = (value, index) => {
    setRevenuePartnerModel(state => {
      const newState = [...state];
      newState[index] = {
        ...newState[index],
        sNo:
          value === '0'
            ? 0
            : Number(
                partners?.filter(i => i.isActive === true)?.find(partner => partner.id === value)
                  ?.sNo
              ),
        partnerId: Number(value)
      };
      setSelectedPartnerIds(newState.map(data => data.sNo));
      return newState;
    });
  };

  const institutionTypeChange = (value, index) => {
    setRevenuePartnerModel(state => {
      const newState = [...state];
      newState[index] = {
        ...newState[index],
        revenuePatnerType: Number(value)
      };
      return newState;
    });
  };

  const percentageChange = (value, index) => {
    setRevenuePartnerModel(state => {
      const newState = [...state];
      newState[index] = {
        ...newState[index],
        percentage: Number(value)
      };
      return newState;
    });
  };

  React.useEffect(() => {
    institutionTypeChange(rows.length > 1 ? '1' : '0', rows.length - 1);
    if (rows.length === 1) {
      revenuePartnerTypeChange('0', 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  return (
    <Container>
      <Form
        labelCol={{
          span: 6
        }}
        wrapperCol={{
          span: 12
        }}
        layout="horizontal">
        <Inputdiv>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter revenue name'
              }
            ]}>
            <Input
              placeholder="Enter revenue name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Form.Item>
        </Inputdiv>
        <HeaderRow>
          <Col span={8}>
            <HeaderText>fee party type</HeaderText>
          </Col>
          <Col span={8}>
            <HeaderText>party name</HeaderText>
          </Col>
          <Col span={8}>
            <HeaderText>percentage value</HeaderText>
          </Col>
          {sum < 100 ? <AddBtn onClick={addRow} /> : ''}
        </HeaderRow>
        <FormDiv>
          {rows.map((row, index) => (
            <InputRow gutter={16} key={index}>
              <Col span={8} name="institution">
                {row > 0 ? (
                  <NewSelect value="1" disabled>
                    <Select.Option value="1">Partner</Select.Option>
                  </NewSelect>
                ) : (
                  <NewSelect value="0" disabled>
                    <Select.Option value="0">Platform Owner</Select.Option>
                  </NewSelect>
                )}
              </Col>
              <Col span={8} name="partner">
                {row > 0 ? (
                  <NewSelect
                    value={revenuePartnerModel[index]?.partnerId}
                    onChange={value => revenuePartnerTypeChange(value, index)}>
                    {rows.length > 1 && row > 0
                      ? partners
                          ?.filter(i => i.isActive === true)
                          ?.map(partner => (
                            <Select.Option
                              key={partner.id}
                              value={partner.id}
                              disabled={selectedPartnerIds.some(id => id === Number(partner.sNo))}>
                              {partner.name}
                            </Select.Option>
                          ))
                      : null}
                  </NewSelect>
                ) : (
                  <NewSelect value="0" disabled>
                    <Select.Option value="0">Rivet</Select.Option>
                  </NewSelect>
                )}
              </Col>
              <Col span={8} name="percentage">
                <Input
                  type="number"
                  min="0"
                  onKeyDown={blockInvalidChar}
                  value={revenuePartnerModel[index]?.percentage || null}
                  onChange={e => percentageChange(e.target.value, index)}
                />
              </Col>
              {row > 0 && <RemoveBtn onClick={() => RemoveRow(index, row)} />}
            </InputRow>
          ))}
        </FormDiv>
        <PercentageText>
          Total Percentage: <Percentage>{sum}</Percentage>
        </PercentageText>
        <ButtonDiv>
          <CloseBtn
            onClick={() => {
              handleClick();
              showFormular(false);
            }}>
            Close
          </CloseBtn>

          <SaveBtn disabled={sum < 100 ? true : sum > 100 ? true : false} onClick={onSubmit}>
            Save
          </SaveBtn>
        </ButtonDiv>
      </Form>
    </Container>
  );
};

export default RevenueForm;

const Container = styled.div`
  width: 85%;
  min-height: 5em;
  margin: auto;
  margin-top: 3.5em;
`;

const Inputdiv = styled.div`
  width: 85%;
  margin: auto;
`;

const HeaderRow = styled(Row)`
  background: #afafaf;
  padding: 1em 1em;
  position: relative;
`;

const AddBtn = styled(FaPlusCircle)`
  color: #0849ff;
  position: absolute;
  height: 2.2em;
  width: 2.2em;
  right: -3.5em;
  top: 0.6em;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:hover {
    transform: scale(1.2, 1.2);
  }
`;

const HeaderText = styled(Row)`
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const FormDiv = styled.div`
  margin-top: 2em;
`;
const InputRow = styled(Row)`
  margin: 1em 0;
  position: relative;
`;

const RemoveBtn = styled(FaMinusCircle)`
  color: #f00;
  position: absolute;
  height: 1.7em;
  width: 1.7em;
  right: -3em;
  top: 0.35em;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:hover {
    transform: scale(1.2, 1.2);
  }
`;

const Institution = styled(Typography.Paragraph)`
  color: #afafaf;
  font-size: 1.1em;
  font-weight: bold;
`;

const PercentageText = styled(Typography.Paragraph)`
  font-size: 0.9em;
  float: right;
  color: #7f7f7f;
`;

const Percentage = styled(Typography.Text)`
  font-size: 1.5em;
  color: #7f7f7f;
  font-weight: bold;
`;

const ButtonDiv = styled.div`
  margin: auto;
  margin-top: 3em;
  width: 50%;
`;

const CloseBtn = styled(Button)`
  border: none;
  background: #4d4f5c;
  color: #fff;
  width: 5.5em;
`;
const SaveBtn = styled(Button)`
  border: none;
  background: #173253;
  color: #fff;
  margin-left: 1.2em;
  width: 9em;
`;

const NewSelect = styled(Select)`
  width: 100%;
`;

// input[type=number]::-webkit-inner-spin-button,
// input[type=number]::-webkit-outer-spin-button {
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     margin: 0;
// }
