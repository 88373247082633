import { Button } from 'antd';
import styled from 'styled-components';

const CustomButton = styled(Button)`
  background-color: ${props => props.bgColor};
  border-color: ${props => props.bgColor};
  color: ${props => props.color};
  width: ${props => props.width};
  height: ${props => props.height};
  font-size: ${props => props.fontSize};
  border-radius: ${props => `${props.radius}!important`};
  &:hover {
    background: ${props => props.bgColor};
    border-color: ${props => props.bgColor};
    color: ${props => props.color};
    opacity: 0.8;
  }
  &:focus {
    background-color: ${props => props.bgColor};
    border-color: ${props => props.bgColor};
    color: ${props => props.color};
    border-radius: ${props => `${props.radius}!important`};
  }
`;

export default CustomButton;
