import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Pagination } from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';

import cancelIcon from 'assets/svgs/cancelcon.svg';
import { ButtonCTA } from 'components/common/ButtonCTA';
import noUpdate from 'assets/images/noUpdate.png';
import PageLoader from 'components/common/PageLoader';
import { showToast } from 'store/actions/toast';
import { SurveyContext } from 'context/SurveyContext';
import AppText from 'components/common/AppText';
import AssignCourseModal from './AssignCourseModal';
import { getCoursesAssignedToSurvey } from 'api/queries/Course';
import { unAssignSurvey } from 'api/queries/Course';

const AssignSurvey = ({ setAssignSurvey }) => {
  const [loading, setLoading] = useState();
  const [courseData, setCourseData] = useState();
  const [isModalOpen, setIsModalOpen] = useState();
  const [courseId, setCourseId] = useState();
  const [pagination, setPagination] = useState();
  const dispatch = useDispatch();
  const { surveyId, surveyTitle } = useContext(SurveyContext);

  const { data, isLoading, refetch } = useQuery(
    ['surveyCourses', surveyId, pagination?.page, pagination?.pageSize],
    getCoursesAssignedToSurvey
  );

  const { data: unAssignResponse, isLoading: unAssignCourseLoading, error } = useQuery(
    ['unAssignCourses', courseId],
    unAssignSurvey
  );

  useEffect(() => {
    if (unAssignResponse?.description == 'Success') {
      refetch();
      dispatch(showToast('success', 'Course unAssigned Successfully'));
    } else if (error) dispatch(showToast('error', error));
  }, [unAssignResponse]);

  useEffect(() => {
    setCourseData(data?.data?.items);
  }, [data?.data]);

  const handleSearch = e => {
    setCourseData(
      e.target.value
        ? data?.data?.items?.filter(course =>
            course?.title?.toLowerCase().includes(e.target.value?.toLowerCase())
          )
        : data?.data?.items
    );
  };

  const handleCancel = () => setAssignSurvey(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleRemove = courseId => {
    setCourseId(courseId);
  };

  const handlePagination = (page, pageSize) => {
    console.log(page, pageSize);
    setPagination({ page, pageSize });
  };

  return (
    <Container>
      <TitleDiv>
        <AppText value={surveyTitle} weight={400} size="24px" color="#000" bottom={'16px'} />
        <AppText
          value={'Create a generic end of course survey'}
          weight={400}
          size="14px"
          color="#000"
        />
      </TitleDiv>
      {isModalOpen && (
        <AssignCourseModal
          visible={isModalOpen}
          setOpen={setIsModalOpen}
          surveyId={surveyId}
          refetch={refetch}
        />
      )}
      <TopDiv>
        <SearchDiv>
          <Input placeholder="search course" onChange={handleSearch} />
          <AiOutlineSearch color="#9f9f9f" size="14px" />
        </SearchDiv>
        <ButtonCTA
          color="#0966B8"
          bgColor="#fff"
          borderRadius="5px"
          border="1px solid #0966B8"
          width="129px"
          fontSize="16px"
          onClick={showModal}
          height="35px">
          Add Course
        </ButtonCTA>
      </TopDiv>
      <AddedCoursesDiv>
        {courseData?.map((course, index) => (
          <AddedCoursesItem>
            <CourseDiv>
              <AppText value={index + 1} bottom="0" />
              <AppText value={course.title} bottom="0" />
            </CourseDiv>
            <CancelDiv>
              <img
                src={cancelIcon}
                loading="lazy"
                alt="cancel icon"
                style={{ cursor: 'pointer' }}
                onClick={() => handleRemove(course.courseId)}
              />
            </CancelDiv>
          </AddedCoursesItem>
        ))}
      </AddedCoursesDiv>
      {courseData?.length === 0 && (
        <NoDataDiv>
          <img src={noUpdate} alt="" />
          <AppText value={'No assigned courses'} size="25px" top=".5rem" color={'#7f7f7f'} />
        </NoDataDiv>
      )}
      <BtnDiv>
        <ButtonCTA
          color="#5D5D5D"
          border="1px solid #5D5D5D"
          bgColor="#fff"
          borderRadius="3px"
          width="100px"
          fontSize="16px"
          onClick={handleCancel}
          height="35px">
          Cancel
        </ButtonCTA>

        {/* <InnerBtnDiv>
          <ButtonCTA
            color="#fff"
            loading={loading}
            bgColor="#0966B8"
            borderRadius="3px"
            width="129px"
            fontSize="16px"
            onClick={() => {
              setLoading(true);
              setTimeout(() => {
                setLoading(false);
              }, 5000);
            }}
            height="35px">
            Apply changes
          </ButtonCTA>
        </InnerBtnDiv> */}
      </BtnDiv>
      <div>
        {data?.data && (
          <Pagination
            defaultCurrent={1}
            current={data?.data?.page}
            total={data?.data?.totalCount}
            pageSize={data?.data?.pageSize}
            onChange={handlePagination}
          />
        )}
      </div>
      <PageLoader isLoading={isLoading || loading || unAssignCourseLoading} />
    </Container>
  );
};

export default AssignSurvey;

const Container = styled.div`
  padding-right: 5rem;
`;

const TitleDiv = styled.div`
  margin-top: -2rem;
  margin-bottom: 2rem;
`;

const TopDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SearchDiv = styled.div`
  width: 187px;
  height: 35px;
  background: #f8f8f8;
  border-radius: 4px;
  display: flex;
  justify-content: center;

  align-items: center;
`;
const Input = styled.input`
  border: none;
  width: 70%;
  outline: none;
  font-size: 15px;
  background: none;
  &::placeholder {
    color: #bfbfbf;
    font-size: 12px;
  }
`;

const AddedCoursesDiv = styled.div`
  padding-top: 3rem;
`;
const AddedCoursesItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;
const CourseDiv = styled.div`
  display: flex;
  gap: 3rem;
  padding-left: 3rem;
  height: 42px;
  width: 70%;
  align-items: center;
  border-bottom: 1px solid #efefef;
`;
const CancelDiv = styled.div`
  background: #fc391d0a;
  width: 44px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BtnDiv = styled.div`
  padding-top: 3rem;
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
  align-items: center;
`;
const InnerBtnDiv = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const NoDataDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: '10rem';
`;
