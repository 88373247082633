import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
// import { timeStampFormat } from 'utils';

const SurveyReportTable = ({
  audittrailResult,
  currentPage,
  pageSize,
  setCurrentPage,
  setPageSize,
  toggleExport
}) => {
  const [pagination, setPagination] = useState();

  useEffect(() => {
    if (!toggleExport) {
      setPagination({
        current: currentPage,
        pageSize: pageSize,
        total: audittrailResult?.data[0]?.totalRows
      });
    }
  }, [audittrailResult, currentPage, pageSize, toggleExport]);

  const handleTableChange = pagination => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'age'
    },
    {
      title: 'User',
      dataIndex: 'name'
    },
    {
      title: 'Question 1',
      dataIndex: 'address'
    },
    {
      title: 'Question 2',
      dataIndex: 'address'
    },
    {
      title: 'Question 3',
      dataIndex: 'address'
    },
    {
      title: 'Question 4',
      dataIndex: 'address'
    },
    {
      title: 'Question 5',
      dataIndex: 'address'
    },
    {
      title: 'Question 6',
      dataIndex: 'address'
    },
    {
      title: 'Question 7',
      dataIndex: 'address'
    }
  ];
  const data = [];
  for (let i = 0; i < 46; i++) {
    data.push({
      key: i,
      name: `Edward King ${i}`,
      age: 32,
      address: `London, Park Lane no. ${i}`
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const start = () => {
  //   setLoading(true); // ajax request after empty completing
  //   setTimeout(() => {
  //     setSelectedRowKeys([]);
  //     setLoading(false);
  //   }, 1000);
  // };
  const onSelectChange = newSelectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys, 'noe', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div>
      <div
        style={{
          marginBottom: 16
        }}>
        {/* <Button type="primary" onClick={start} disabled={!hasSelected} loading={loading}>
            Reload
          </Button> */}
        <span
          style={
            {
              // marginLeft: 8
            }
          }>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
        </span>
      </div>
      <Table bordered rowSelection={rowSelection} columns={columns} dataSource={data} />
    </div>
  );
};

export default SurveyReportTable;
