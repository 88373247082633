import IconWrapper from 'components/common/IconWrapper';
import { Box, Button, Paragraph } from 'grommet';
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hideToast } from 'store/actions/toast';
import closeIcon from 'assets/svgs/ic_close.svg';

const Toast = () => {
  const toastTimeout = useRef();
  const dispatch = useDispatch();
  const toast = useSelector(state => state.toast);
  const [node] = useState(document.createElement('div'));

  const removeNode = () => {
    if (document.querySelector('#toast').children.length) {
      document.querySelector('#toast').childNodes[0].remove();
    }
  };

  useEffect(() => {
    if (toast.show) {
      const timeout = 5000;

      if (toastTimeout.current) {
        clearTimeout(toastTimeout.current);
      }

      const elementClassList = document.querySelector('#toast').appendChild(node).classList;

      elementClassList.add('toast-container');

      if (toast.status === 'success') {
        elementClassList.add('toast-container-success');
        elementClassList.remove('toast-container-warning');
        elementClassList.remove('toast-container-error');
      } else if (toast.status === 'warning') {
        elementClassList.add('toast-container-warning');
        elementClassList.remove('toast-container-success');
        elementClassList.remove('toast-container-error');
      } else {
        elementClassList.add('toast-container-error');
        elementClassList.remove('toast-container-success');
        elementClassList.remove('toast-container-warning');
      }

      toastTimeout.current = setTimeout(() => {
        removeNode();
        dispatch(hideToast());
      }, timeout);
    } else {
      removeNode();
    }

    return () => removeNode();
  }, [node, dispatch, toast]);

  if (typeof toast?.message === 'object') return null;

  return ReactDOM.createPortal(
    <Box
      direction="row"
      align="start"
      justify="between"
      gap="small"
      style={{
        fontFamily: 'Assistant',
        width: '100%',
        height: '100%'
      }}>
      <Paragraph size="small" fill>
        <span dangerouslySetInnerHTML={{ __html: toast.message }}></span>
      </Paragraph>
      <Button
        plain
        icon={<IconWrapper width="20px" height="20px" icon={closeIcon} />}
        onClick={() => {
          if (toastTimeout.current) {
            clearTimeout(toastTimeout.current);
            removeNode();
            dispatch(hideToast());
          }
        }}
      />
    </Box>,
    node
  );
};

export default Toast;
