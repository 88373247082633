/** email regex */
export const emailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
/** phone number regex */
export const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
/** new phone number regex */
export const newPhoneRegex = /^[0]\d{5,10}$/;

export const AccountNumberRegex = /^[0-9]{10}$/;
/** website regex */
export const webSiteRegex = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
/** change [<br/>, <br>] to \n */
export const brToNewLine = str => str.replace(/<br[^>]*>/gm, '\n');
/** change [<br/>, <br>] to \n */
// export const brToNewLine = (str) => str.replace(/<br[^>]*>/gm, "\n");
