import styled from 'styled-components';
import { Button } from 'antd';

export const ButtonCTA = styled(Button)`
  background: ${props => props.bgColor};
  border-radius: ${props => props.borderRadius};
  color: ${props => props.color};
  border-color: ${props => props.bgColor};
  border: ${props => props.border};
  height: ${props => props.height};
  width: ${props => props.width};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  padding: ${props => props.padding};

  &:hover {
    background: ${props => props.bgColor};
    border-color: ${props => props.bgColor};
    opacity: 0.8;
  }

  &:focus {
    background: ${props => props.bgColor};
    border-color: ${props => props.bgColor};
  }
`;
