import React, { useEffect, useState } from 'react';
import { Box, Button, Form, ResponsiveContext, ThemeContext } from 'grommet';
import { isMobile } from 'utils';
import ButtonLoader from 'components/common/ButtonLoader';
import PageHeader from 'components/common/PageHeader';
import { useMutation, useQuery } from 'react-query';
import { categoryList, fetchTokens, getTemplateListById } from 'api/queries/Notification';
import PageLoader from 'components/common/PageLoader';
import { useHistory, useParams } from 'react-router-dom';
import ConfigurationSettings from 'pages/admin/Settings';
import BulkEmail from 'components/forms/BulkEmailForm';
import { useForm, Controller } from 'react-hook-form';
import { updateEmail } from 'api/mutations/Notification';
import Message from 'components/common/Message';
import NotificationModal from 'components/modals/NotificationModal';
import { emailtemplate, previewemail } from 'adminRoutes';
import ModalCard from 'components/modals/ModalCard';
import { clearEmailState, emailState } from 'store/actions/email';
import { useDispatch } from 'react-redux';

const EditEmail = props => {
  const { register, errors, setValue, getValues, handleSubmit, control } = useForm();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categoryListId, setCategoryListId] = useState(null);
  const [placeholderDisabled, setPlaceholderDisabled] = useState(true);
  const [selectedToken, setSelectedToken] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState(null);
  const [updateEmailError, setUpdateEmailError] = useState(null);
  const size = React.useContext(ResponsiveContext);
  const [emailData, setEmailData] = useState(null);
  const [newVar, setNewVar] = useState(null);
  const { editemail } = useParams();
  const { data: tokensData } = useQuery(['tokens', categoryListId], fetchTokens, {
    refetchOnWindowFocus: false,
    enabled: categoryListId // turned off by default, manual refetch is needed
  });
  const { data: editData } = useQuery(['templatesById', editemail], getTemplateListById);
  const { data } = useQuery(['categoryList'], categoryList);

  const [updateEmailMutate] = useMutation(updateEmail, {
    onSuccess: data => {
      const { description, success } = data;
      if (success) {
        setSuccessMessage(description);
        dispatch(clearEmailState());
      } else {
        setUpdateEmailError(description);
        setIsSubmitting(false);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setUpdateEmailError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        setUpdateEmailError(error.message);
      }
    }
  });

  useEffect(() => {
    if (editData && data) {
      const result = {
        name: editData?.data.name,
        message: editData?.data.template,
        emailCategoryName: data?.data.find(d => d.id === editData?.data.emailCategoryId).name
      };
      setEmailData(result);
    }
  }, [data, editData]);

  const onSubmit = async ({ name, emailCategoryId, message }) => {
    // console.log(data);
    const payload = {
      Id: editData?.data.id,
      Name: name,
      Code: editData?.data.code,
      Template: message,
      EmailCategoryId: data?.data.find(d => d.name === emailCategoryId).id
      // Tokens: ['string'],
      // IsActive:editData?.data.isActive
    };
    // console.log(payload);
    try {
      setUpdateEmailError(null);
      setIsSubmitting(true);
      await updateEmailMutate(payload);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handlePreview = () => {
    const values = getValues();
    dispatch(emailState(values));
    history.push(previewemail, values);
  };

  return (
    <ConfigurationSettings selectedKey="Email Management">
      <Box margin={{ bottom: 'small' }}>
        <PageHeader marginBottom="none" pageTitle="Manage Email Notification" />
        <Box title="">
          <ThemeContext.Extend value={{ global: { colors: { doc: '' } } }}>
            <Box
              pad="xxsmall"
              margin={{ bottom: 'medium' }}
              width="100%"
              elevation="small"
              background="doc"
            />
          </ThemeContext.Extend>
          {updateEmailError && <Message message={updateEmailError} status="error" />}
          {emailData && (
            <Form style={{ maxWidth: 752 }} onSubmit={handleSubmit(onSubmit)}>
              <BulkEmail
                setPlaceholderDisabled={setPlaceholderDisabled}
                placeholderDisabled={placeholderDisabled}
                Controller={Controller}
                control={control}
                handlePreview={handlePreview}
                register={register}
                errors={errors}
                setCategoryListId={setCategoryListId}
                setValue={setValue}
                setSelectedToken={setSelectedToken}
                selectedToken={selectedToken}
                data={data}
                newVar={newVar}
                setNewVar={setNewVar}
                setOpenModal={setOpenModal}
                emailData={emailData}
                handleSubmit={handleSubmit}
              />
              <Box
                margin={{ top: 'large' }}
                direction={isMobile(size) ? 'column' : 'row'}
                align="center"
                gap="medium"
                justify="end">
                <Button
                  secondary
                  size="small"
                  onClick={() => {
                    history.push(emailtemplate);
                    dispatch(clearEmailState());
                  }}
                  label="Cancel"
                />
                <Button
                  type="submit"
                  primary
                  size="small"
                  label={!isSubmitting && 'Update'}
                  icon={isSubmitting ? <ButtonLoader isSubmitting={isSubmitting} /> : null}
                />

                {openModal && (
                  <ModalCard
                    data={tokensData?.data || editData?.data.tokens}
                    setSelectedToken={setSelectedToken}
                    onClose={() => {
                      setOpenModal(false);
                    }}
                    onSelectToken={token => {
                      if (newVar) {
                        newVar.model.change(writer => {
                          const insertPosition = newVar.model.document.selection.getFirstPosition();

                          writer.insertText(token ? token : '', insertPosition);
                        });
                      }
                    }}
                    header="Insert Placeholders"
                    subHeader="Click to insert placeholder in the content which will dynamically get resolved into the appropriate data"
                  />
                )}
              </Box>
            </Form>
          )}
        </Box>
      </Box>

      <PageLoader isLoading={false} />
      {successMessage && (
        <NotificationModal
          message="Email Updated Successfully"
          status="success"
          iconHeight="80px"
          iconWidth="80px"
          actionText="Complete"
          actionFunc={() => {
            history.push(emailtemplate);
          }}
        />
      )}
    </ConfigurationSettings>
  );
};

export default EditEmail;
