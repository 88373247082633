import React, { useEffect, useState } from 'react';
import MainLayout from 'components/MainLayout';
import { Card, Col, Input, Row, Typography } from 'antd';
import styled from 'styled-components';
import CustomButton from 'components/common/CustomButton';
import ManageRegisteredUserTable from 'components/tables/ManageRegisteredUserTable';
import { useQuery } from 'react-query';
import { registeredUser } from 'api/queries/Report';
import PageLoader from 'components/common/PageLoader';
import AppData from 'AppData';

const ManageRegisteredUser = () => {
  const { Text } = Typography;
  const [searchVal, setSearchVal] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [toggleExport, setToggleExport] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const { isLoading, data: userResults, refetch: refetchReport } = useQuery(
    [
      'registeredUser',
      searchVal,
      0,
      toggleExport ? 1 : currentPage,
      toggleExport ? AppData.infinitePage : pageSize,
      startDate,
      endDate,
      toggleExport
    ],
    registeredUser
  );

  useEffect(() => {
    if (userResults && toggleExport && !isLoading) {
      base64Download(userResults?.file);
      setToggleExport(false);
    }
  }, [userResults, isLoading, toggleExport]);

  const base64Download = (base64Url, fileName = 'ManageRegisterUser.xlsx') => {
    const a = document.createElement('a');
    console.log('inside', base64Url);
    document.body.appendChild(a);
    a.href = `data:application/octet-stream;base64,${base64Url}`;
    a.download = fileName;
    a.click();
  };
  const onSearch = value => setSearchVal(value);

  return (
    <MainLayout isAdmin sidebar padded selectedKey="manageRegUser">
      <Card>
        <HeaderRow gutter={[16, 16]}>
          <Col sm={{ span: 16 }} md={{ span: 8 }} xs={{ span: 24 }}>
            <Title level={2}>Manage Registered Users</Title>
            <Text>Discover your overall course Revenue and Analytics</Text>
          </Col>
          <CenterCol sm={{ span: 16 }} md={{ span: 8 }} xs={{ span: 24 }}>
            <Search placeholder="Search Course, Student" onSearch={onSearch} size="large" />
          </CenterCol>
          <ExportCol sm={{ span: 4 }} md={{ span: 4 }} xs={{ span: 24 }}>
            <CustomButton
              onClick={() => {
                setToggleExport(true);
              }}
              bgColor="#4d4d53"
              color="#fff"
              size="large">
              Export
            </CustomButton>
          </ExportCol>
        </HeaderRow>
        {userResults && (
          <ManageRegisteredUserTable
            userResults={userResults}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            toggleExport={toggleExport}
          />
        )}
      </Card>
      <PageLoader fullWidth={isLoading} fullHeight={isLoading} isLoading={isLoading} />
    </MainLayout>
  );
};

const Title = styled(Typography.Title)`
  margin-bottom: 0px !important;
`;

const CenterCol = styled(Col)`
  display: flex;
  margin: auto;
`;

const ExportCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const Search = styled(Input.Search)`
  width: 100%;
`;

const HeaderRow = styled(Row)`
  margin-bottom: 20px;
`;

export default ManageRegisteredUser;
