import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const WhyTeach = () => {
  const reasons = [
    {
      reason: (
        <span>
          Reach a wide range of <br />
          learners
        </span>
      ),
      color: '#0966b8'
    },
    {
      reason: (
        <span>
          Start your journey as <br />
          an instructor
        </span>
      ),
      color: '#FC391D'
    },
    {
      reason: (
        <span>
          Earn money for the <br />
          content you create
        </span>
      ),
      color: '#FF7600'
    },
    {
      reason: <span>Impact your community</span>,
      color: '#61A60F'
    }
  ];
  return (
    <Container>
      <Inner>
        <Heading>Why teach on SkillDorm?</Heading>
        <Reasons>
          {reasons.map(value => (
            <CardDiv color={value.color}>
              <ReasonText>{value.reason}</ReasonText>
            </CardDiv>
          ))}
        </Reasons>
      </Inner>
    </Container>
  );
};

export default WhyTeach;

const Container = styled.div`
  display: flex;
  margin: auto;
  padding: 9rem 0;
  background: #fff;
  @media ${mobile} {
    padding: 5rem 1.5rem;
  }
`;
const Inner = styled.div`
  display: flex;
  width: 70rem;
  margin: auto;
  flex-direction: column;
  align-items: center;
  @media ${mobile} {
    width: 100%;
  }
`;
const Heading = styled(Typography.Paragraph)`
  position: relative;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  margin-bottom: 6rem !important;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 76px;
    width: 125px;
    height: 6px;
    background: #0966b8;
  }
  @media ${mobile} {
    text-align: left;
    font-size: 36px;
    line-height: 43.57px;
    margin-bottom: 3.5rem !important;
    &::before {
      top: 96px;
    }
  }
`;

const Reasons = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  gap: 3rem;
`;

const CardDiv = styled.div`
  background: #ffffff;
  position: relative;
  box-shadow: 7px 2px 15px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  width: 528px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    position: absolute;
    content: '';
    left: 0;
    width: 5px;
    height: 110px;
    background: ${props => props.color};
  }
  @media ${mobile} {
    width: 100%;
    height: 100px;
    &::before {
      width: 5px;
      height: 55px;
    }
  }
`;

const ReasonText = styled(Typography.Paragraph)`
  margin: 0 !important;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  @media ${mobile} {
    font-weight: 600;
    font-size: 16px;
    line-height: 19.36px;
  }
`;
