import React, { useEffect, useRef, useState } from 'react';
import MainLayout from 'components/MainLayout';
import { Card, Col, DatePicker, Input, Row, Typography, Select, PageHeader } from 'antd';
import styled from 'styled-components';
import CustomButton from 'components/common/CustomButton';
import { auditTrailReport } from 'api/queries/Report';
import { useQuery } from 'react-query';
import PageLoader from 'components/common/PageLoader';
import AppData from 'AppData';
import { useHistory } from 'react-router-dom';
import { FileAddOutlined } from '@ant-design/icons';
import SurveyReportTable from 'components/tables/SurveyReportTable';

const SurveyReport = () => {
  const history = useHistory();
  const searchRef = useRef();
  const [searchVal, setSearchVal] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [toggleExport, setToggleExport] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [value, setValue] = React.useState();
  const { isLoading, data: audittrailResult, refetch: refetchReport } = useQuery(
    [
      'auditTrailReport',
      searchVal,
      0,
      0,
      toggleExport ? 1 : currentPage,
      toggleExport ? AppData.infinitePage : pageSize,
      '',
      '',
      toggleExport
    ],
    auditTrailReport
  );

  const onSearch = value => setSearchVal(value);

  useEffect(() => {
    if (audittrailResult && toggleExport && !isLoading) {
      base64Download(audittrailResult?.file);
      setToggleExport(false);
    }
  }, [audittrailResult, isLoading, toggleExport]);

  const base64Download = (base64Url, fileName = 'AuditTrail.xlsx') => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = `data:application/octet-stream;base64,${base64Url}`;
    a.download = fileName;
    a.click();
  };
  return (
    <MainLayout isAdmin sidebar padded selectedKey="auditTrail">
      <Card>
        <HeaderRow gutter={[16, 24]}>
          <Col sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
            <PageHeader
              onBack={() => history.goBack()}
              className="paddingleft-0"
              title={<Title level={2}>Survey Report</Title>}
              // tags={<TagRender />}
            />
          </Col>
        </HeaderRow>
        <HeaderRow gutter={[16, 24]}>
          <Col sm={{ span: 16 }} md={{ span: 14 }} xs={{ span: 24 }}>
            <Search
              placeholder="Search responses"
              onSearch={onSearch}
              ref={searchRef}
              size="large"
            />
          </Col>
          <CenterCol sm={{ span: 24 }} md={{ span: 3 }} xs={{ span: 24 }}></CenterCol>
          <ExportCol sm={{ span: 24 }} md={{ span: 7 }} xs={{ span: 24 }}>
            <Select
              size="large"
              style={{ width: '130px', marginRight: '10px' }}
              placeholder="Sort By">
              <Select.Option>Newest</Select.Option>
              <Select.Option>Oldest</Select.Option>
            </Select>
            <CustomButton
              icon={<FileAddOutlined />}
              color="#fff"
              type="primary"
              size="large"
              onClick={() => {
                setToggleExport(true);
              }}>
              Export to excell
            </CustomButton>
          </ExportCol>
        </HeaderRow>
        <TableWrapper>
          {audittrailResult && (
            <SurveyReportTable
              audittrailResult={audittrailResult}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              setPageSize={setPageSize}
              toggleExport={toggleExport}
            />
          )}
        </TableWrapper>
      </Card>
      <PageLoader fullWidth={isLoading} fullHeight={isLoading} isLoading={isLoading} />
    </MainLayout>
  );
};

const Title = styled(Typography.Title)`
  margin-bottom: 0px !important;
`;

const CenterCol = styled(Col)`
  display: flex;
  margin: auto;
`;

const ExportCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Search = styled(Input.Search)`
  width: 50%;
  margin-right: 10px;
`;

const HeaderRow = styled(Row)`
  margin-bottom: 20px;
`;
const TableWrapper = styled.div`
  margin-top: 2.5rem;
`;

export default SurveyReport;
