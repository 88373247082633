import React from 'react';
import styled from 'styled-components';
import 'styles/utils.css';
import { Box, ResponsiveContext, Heading, Paragraph, Carousel, Grid } from 'grommet';
import PartnersCard from 'components/common/PartnersCard';
import Icsan from 'assets/images/icsan.png';
import { isMobile } from 'utils';
import PartnerImg from 'assets/images/partner.png';
import RivetLogo from 'assets/images/partners logo/rivetLogo.png';
import InlaksLogo from 'assets/images/partners logo/inlaks.jpg';
import IcsanLogo from 'assets/images/partners logo/icsan.png';
import PartnersDetailCard from 'components/utils/CourseEnrollment/PartnersDetailCard';
import { search } from 'api/mutations/Institution';
import { useMutation, useQuery } from 'react-query';
import MainLayout from 'components/MainLayout';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const Partner = () => {
  const size = React.useContext(ResponsiveContext);
  const [institutionSearchResult, setInstitutionSearchResult] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [createError, setCreateError] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [createInstitutionMutate] = useMutation(search, {
    onSuccess: dataResult => {
      const { description, success } = dataResult;
      setInstitutionSearchResult(dataResult);
      if (success) {
        setInstitutionSearchResult(dataResult);
        setSuccessMessage(description);
      } else {
        setCreateError(description);
        setIsSubmitting(false);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setCreateError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        // setCreateError(error.message);
        console.log(error);
      }
    }
  });

  React.useEffect(() => {
    try {
      const instituionSearch = () => {
        const param = {
          searchVal: ''
        };
        createInstitutionMutate(param);
      };
      instituionSearch();
    } catch (error) {}
  }, [createInstitutionMutate]);

  return (
    <MainLayout selectedKey={'dashboard'} padded={0} noMargin={0} sidebar={false}>
      <Box
        justify="end"
        height="240px"
        pad={{ top: 'large', bottom: 'small', right: 'medium', left: 'large' }}
        background={{
          dark: true,
          position: '0% 98%',
          repeat: 'no-repeat',
          size: 'cover',
          image: `linear-gradient(to right, rgba(0,0,0,1),rgba(0,0,0,1), rgba(0,0,0,.1) ), url(${PartnerImg})`
        }}>
        <Box pad={{ horizontal: 'large' }}>
          <Heading
            color="light-1"
            style={{ wordSpacing: 5 }}
            level={3}
            margin={{ bottom: 'medium' }}>
            Partners
          </Heading>
        </Box>
      </Box>
      <Box
        //  pad="large"
        background="#fff">
        <Box gap={isMobile(size) ? 'large' : 'small'}>
          <Box direction="row" gap="medium" justify="between">
            <Box width="100%">
              <Box
              // margin={{ top: 'medium' }}
              >
                <Box
                  direction={isMobile(size) ? 'column' : 'row'}
                  pad={{
                    left: isMobile(size) ? 'small' : 'large',
                    right: isMobile(size) ? 'small' : 'large',
                    top: 'none',
                    bottom: 'medium'
                  }}
                  gap="medium">
                  <Box width="large">
                    <PartnersDetailCard
                      background="#fff"
                      details="Skilldorm offers the highest quality online courses in partnership with major institutions and professional bodies across Africa who share our commitment to excellence in teaching and learning."
                      // detailsExtra=" or looking for jobs, this is the fastest
                      // way to refresh your skill or Jump Start your skills. Within 1 hour, you can use Excel the
                      // way a working professional would use."
                    />
                  </Box>

                  <PartnerDiv>
                    <FirstDiv>
                      <LogoDiv logo={RivetLogo}></LogoDiv>
                      <LogoDiv
                        logo={IcsanLogo}
                        margin={isMobile(size) ? '.8rem' : '1.3rem'}></LogoDiv>
                    </FirstDiv>
                    <SecondDiv logo={InlaksLogo}></SecondDiv>
                  </PartnerDiv>
                </Box>
              </Box>
              {/* <Box
                // pad="large"
                direction={isMobile(size) ? 'column' : 'row'}
                // width='full'
                background="#000"
                align={isMobile(size) ? 'stretch' : 'center'}
                justify="center">
                <Box
                  direction={isMobile(size) ? 'column' : 'row'}
                  gap="medium"
                  width="full"
                  // pad="medium"
                  align={isMobile(size) ? 'stretch' : 'center'}
                  justify="evenly"
                  margin={{ top: 'large', bottom: 'medium' }}>
                  <Box>
                    <Heading
                      style={{ wordSpacing: 5, textAlign: 'center' }}
                      level={3}
                      color="light-1"
                      margin="none">
                      3+
                    </Heading>
                    <Paragraph
                      margin={{ top: 'none' }}
                      fill
                      style={{ fontSize: 'xsmall', color: '#fff', textAlign: 'center' }}>
                      Certified Courses Available
                    </Paragraph>
                  </Box>
                  <Box>
                    <Heading
                      style={{ wordSpacing: 5, textAlign: 'center' }}
                      level={3}
                      color="light-1"
                      margin="none">
                      1974+
                    </Heading>
                    <Paragraph
                      margin={{ top: 'none' }}
                      fill
                      style={{ fontSize: 'xsmall', color: '#fff', textAlign: 'center' }}>
                      Active learner Nationwide
                    </Paragraph>
                  </Box>
                  <Box>
                    <Heading
                      style={{ wordSpacing: 5, textAlign: 'center' }}
                      level={3}
                      color="light-1"
                      margin="none">
                      10250+
                    </Heading>
                    <Paragraph
                      margin={{ top: 'none' }}
                      fill
                      style={{ fontSize: 'xsmall', color: '#fff', textAlign: 'center' }}>
                      Enrollment Across Skilldorm
                    </Paragraph>
                  </Box>
                </Box>
              </Box> */}
              <Box>
                <Box
                  margin={{ vertical: 'large' }}
                  align={isMobile(size) ? 'stretch' : 'center'}
                  pad={{
                    left: isMobile(size) && 'large',
                    right: isMobile(size) && 'large'
                  }}>
                  <Heading level="3" margin="none" style={{ marginTop: isMobile(size) && '2rem' }}>
                    SkillDorm Learning Partners
                  </Heading>
                  <Paragraph margin={{ top: 'none' }} fill style={{ fontSize: 'medium' }}>
                    Skilldorm offers a robust learning online platform for partners to upload and
                    digitize their educational content.
                  </Paragraph>
                </Box>
                <PartnerWrapper style={{ paddingLeft: isMobile(size) ? 0 : 48 }}>
                  <RowWrapper>
                    {institutionSearchResult &&
                      institutionSearchResult
                        .filter(item => item.isActive)
                        .map((item, i) => {
                          return (
                            <InnerWrap key={i}>
                              <PartnersCard
                                key={item}
                                price={8500}
                                title={item.name}
                                description="Explore the Fundamental of Data Anallytics and Data Visualization with end to end data
                             encryption and Protection"
                                modules={item}
                                duration="9h 45mins"
                                level="Beginner"
                                logoUrl={item.logoUrl}
                                learner={item.students}
                                institutionLogo={item.logo ? item.logo : Icsan}
                                institutionName={'Institute of Chartered Secretaries Admin'}
                                course={item.courses}
                                data={institutionSearchResult}
                              />
                            </InnerWrap>
                          );
                        })}
                  </RowWrapper>
                </PartnerWrapper>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default Partner;

const PartnerWrapper = styled.div`
  padding: 0px 38px 0px;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  @media ${mobile} {
    width: 100vw;
  }
`;

const InnerWrap = styled.div`
  flex: 1, 1, 23%;
`;

const PartnerDiv = styled.div`
  margin-top: 2rem;
  min-height: 281px;
  padding-top: 3.8rem;
  width: 625px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${mobile} {
    width: 100%;
    margin-top: -2rem;
    padding-top: 0;
    min-height: 200px;
  }
`;

const FirstDiv = styled.div`
  background: #fff;
  width: 70%;
  min-height: 3rem;
  display: flex;
  justify-content: space-between;
`;
const LogoDiv = styled.div`
  background: url(${props => props.logo}) no-repeat center center/cover;
  margin: ${({ margin }) => margin && margin};
  width: 11rem;
  height: 6rem;
  @media ${mobile} {
    width: 9rem;
    height: 4rem;
  }
`;
const SecondDiv = styled.div`
  background: url(${props => props.logo}) no-repeat center center/cover;
  width: 53%;
  height: 5rem;
  margin-top: -0.9rem;
`;
