import React, { useEffect } from 'react';
import 'styles/utils.css';
import styled from 'styled-components';
import MainLayout from 'components/MainLayout';
// import CourseCard from 'components/common/CourseCard';
import Collaboration from 'assets/images/landing page Assets/Home landing page/collaboration.png';
import { Row, Col, Typography, Button, Progress, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import {
  courseEnrolled,
  recent,
  completedCourses,
  courseOverallPercentage,
  courseEnrolledDetails
} from 'api/queries/Course';
import { useSelector } from 'react-redux';
import DashboardTabs from 'components/tabs/DashboardTabs';
import { delivery } from 'userRoutes';
// import DiscoverNewCourse from 'components/common/DiscoverNewCourse';
import { courseSearch } from 'api/mutations/Course';
import CourseCategory from 'components/common/CourseCategory';
import PageLoader from 'components/common/PageLoader';
import { useMediaQuery } from 'react-responsive';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const Dashboard = () => {
  const { userId, firstName, lastName } = useSelector(state => state.auth);
  const [
    ,
    // courseSearchResult
    setCourseSearchResult
  ] = React.useState(null);
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });
  const history = useHistory();

  const { isLoading: loadingPercentage, data: overAllPercentage } = useQuery(
    ['overallPercentage'],
    courseOverallPercentage
  );

  const { isLoading, data: completedCourseResult } = useQuery(
    ['completecourse', userId],
    completedCourses
  );
  const { isLoading: loading, data: coursesEnrolledResult } = useQuery(
    ['courseenrolled', userId],
    courseEnrolled
  );

  const { isLoading: loader, data: recentFile } = useQuery(['recent', userId], recent);

  const { data: videoData } = useQuery(
    ['courseEnrolledVideos', userId, recentFile?.data?.courseId],
    courseEnrolledDetails
  );

  const handleProgress = () => {
    history.push(delivery, {
      userId,
      courseId: recentFile?.data?.courseId,
      currentModule: videoData?.data?.currentModule,
      currentSection: videoData?.data?.currentSection
    });
    // history.push(delivery, { userId, courseId: recentFile.data.courseId });
  };

  // fetch course API
  const [createMutate, info] = useMutation(courseSearch, {
    onSuccess: dataResult => {
      const { description, data, success } = dataResult;
      if (success) {
        setCourseSearchResult(data?.items);
        // if (data) {
        //   const courses = data?.items;
        //   const n = 3;
        //   const result = new Array(Math.ceil(courses.length / n))
        //     .fill(0)
        //     .map(_ => courses.splice(0, n));
        // }
      } else {
        return null;
      }
    },
    onError: error => {
      if (error.message === 'Network Error') {
        message.error("We couldn't connect to the server");
      } else {
        return;
      }
    }
  });

  // fetch course API effect
  useEffect(() => {
    const search = () => {
      const param = {
        page: 1,
        pageSize: 50,
        courseCategoryIds: [],
        parameter: '',
        institutionIds: [],
        courseLevels: [],
        languageIds: []
      };
      createMutate(param);
    };
    search();
  }, [createMutate]);

  return (
    <MainLayout
      sidebar={isMobileDevice ? false : userId ? true : false}
      padded={0}
      selectedKey={'dashboard'}>
      <Container>
        <TopRow>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 10 }}
            xl={{ span: 10 }}>
            <Row style={{ marginTop: '2rem' }}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Text>Welcome back, </Text>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 16 }}>
                <BigText>
                  {firstName} <strong>{lastName}!</strong>
                </BigText>
              </Col>
            </Row>
            {/* <Paragraph>Reach 250xp to continue your streak...</Paragraph> */}
          </Col>
          <ProgressionCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 10 }}
            xl={{ span: 10 }}
            offset={!isMobileDevice && 4}>
            <Row align={'middle'}>
              <Col
                xs={{ span: 8 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}>
                <ProgressText>
                  Overall <br></br>Course progression<br></br>
                  <span style={{ fontSize: '.8em!important' }}>
                    ({coursesEnrolledResult?.data?.length} active courses)
                  </span>
                </ProgressText>
              </Col>
              <Col
                xs={{ span: 16 }}
                sm={{ span: 16 }}
                md={{ span: 16 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}>
                <Progress
                  type="circle"
                  strokeColor="#87d068"
                  percent={overAllPercentage?.data?.percentage}
                  format={percent => <PercentText>{`${percent}%`}</PercentText>}
                />
              </Col>
            </Row>
          </ProgressionCol>
        </TopRow>
        {recentFile?.data && (
          <CourseRow>
            <CourseCol1
              img={recentFile?.data?.courseThumbNailUrl || Collaboration}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 6 }}
              xl={{ span: 6 }}></CourseCol1>
            <CourseCol2
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 18 }}
              xl={{ span: 18 }}>
              <Row>
                <Col span={24}>
                  <CourseTitle2>{recentFile?.data?.title}</CourseTitle2>
                  <CourseParagraph>{recentFile?.data?.description}</CourseParagraph>
                  <CourseParagraph>
                    <Row>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                        style={{ marginTop: '1rem' }}>
                        You are enrolled in the <CourseText>{recentFile?.data?.title}</CourseText>{' '}
                        with &nbsp;<CourseText>{recentFile?.data?.institution}</CourseText>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}
                        xl={{ span: 8 }}
                        offset={!isMobileDevice && 4}>
                        <CourseBtn onClick={handleProgress} size="small">
                          Keep making progress
                        </CourseBtn>
                      </Col>
                    </Row>
                  </CourseParagraph>
                </Col>
              </Row>
            </CourseCol2>
          </CourseRow>
        )}
        <MarginTop>
          <DashboardTabs
            completedCourse={completedCourseResult?.data}
            coursesEnrolled={coursesEnrolledResult?.data}
          />
        </MarginTop>
        {/* {courseSearchResult && (
          <CourseCategory
            otherPages
            left={'none'}
            right={'none'}
            // categoryType={categoryType}
            searchType="top"
            title="Career Related Programs"
            courseSearchResult={courseSearchResult}
          />
        )} */}
        {/* <DiscoverNewCourse
          onClick={() => {
            history.push(discovery);
          }}
        /> */}
      </Container>
      <PageLoader fullWidth fullHeight isLoading={loading || isLoading} />
    </MainLayout>
  );
};

export default Dashboard;

const Container = styled.div`
  overflow-x: hidden;
  position: relative;
  padding: 2em 2em 0 2em;
`;

const MarginTop = styled.div`
  margin-top: 1rem;
`;
const TopRow = styled(Row)`
  background: #0966b8;
  ${'' /* height: 14rem; */}
  width: 100%;
  padding: 1.5rem 2rem 1.5rem 2rem;
  margin: 0 0 3rem 0;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 20%);
  border-radius: 7px !important;
`;

const ProgressionCol = styled(Col)`
  @media ${mobile} {
    margin-left: 0.7rem;
    margin-top: 3.5rem;
  }
`;
const ProgressTitle = styled(Typography.Title)`
  color: #fff !important;
  text-align: center;
  margin: 1.7rem 0 0 0 !important;
`;
const Text = styled(Typography.Text)`
  color: #fff !important;
  @media ${mobile} {
    display: block !important;
    text-align: center;
  }
`;
const BigText = styled(Typography.Text)`
  color: #fff;
  font-size: 2em;
  margin: 1rem 0 0 -1rem;
  line-height: 12px;
  text-transform: capitalize;
  @media ${mobile} {
    display: block !important;
    text-align: center;
    margin: 1rem 0 0 0 !important;
    font-size: 1.5em;
    line-height: 10px;
  }
`;
const ProgressText = styled(Typography.Text)`
  color: #fff;
  float: right;
  font-weight: bold;
  margin-right: 1rem;
`;
const Paragraph = styled(Typography.Paragraph)`
  color: #fff;
  margin-top: 1rem;
`;

const ProgressParagraph = styled(Typography.Paragraph)`
  color: #fff;
  text-align: center;
  font-size: 0.6em;
`;

const ProgressChart = styled.div`
  background: none;
  width: 7em;
  height: 7em;
  border-radius: 50%;
  border: 0.5rem solid #fff;
  margin-left: 1rem;
`;

const CourseRow = styled(Row)`
  width: 100%;
  border-radius: 7px;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 20%);
`;
const CourseCol1 = styled(Col)`
  background: ${({ img }) => `url('${img}') no-repeat center/cover`};
  position: relative;
  &::after {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 2;
  }
  @media ${mobile} {
    min-height: 13rem;
  }
`;
const CourseCol2 = styled(Col)`
  padding: 1rem 2rem;
  background: #fff;
  @media ${mobile} {
    padding: 1rem 1rem;
  }
`;
const PercentText = styled(Typography.Text)`
  color: #fff;
  font-weight: bold;
`;
const CourseTitle2 = styled(Typography.Title)`
  color: #000 !important;
  margin: 0 0 1rem 0 !important;
  font-size: 1.3em !important;
`;

const CourseParagraph = styled(Typography.Paragraph)`
  color: #7f7f7f;
  margin-bottom: 0.7em !important;
  font-size: 0.87em;
`;
const CourseText = styled(Text)`
  color: #0966b8 !important;
  font-weight: bolder;
  @media ${mobile} {
    display: inline !important;
  }
`;
const CourseBtn = styled(Button)`
  margin: 0 0 0 0;
  background: #0966b8;
  height: 3.5rem;
  font-size: 1em;
  color: #fff;
  border-radius: 10px;
  width: 76%;
  margin-left: 1rem;
  @media ${mobile} {
    display: block !important;
    margin: auto !important;
    margin-top: 2rem !important;
    height: 2.5rem;
    font-size: 0.9em;
    width: 100%;
  }
`;
