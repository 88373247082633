import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { AiFillCloseCircle } from 'react-icons/ai';

const QuizItem = ({ index, type, setQuiz, quizItemClickHandler, isReview }) => {
  const deleteQuizItemHandler = e => {
    e.stopPropagation();
    setQuiz(state => {
      const newState = { ...state };
      newState.data = newState.data.filter((_, i) => index !== i);
      return newState;
    });
  };

  return (
    <QuizItemWrapper onClick={quizItemClickHandler}>
      <QuizItemTitleText>Question {index + 1}</QuizItemTitleText>
      <FlexContainer>
        <QuizItemOptionText>
          {{ 0: 'Single Choice', 1: 'Multiple Choice' }[type]}
        </QuizItemOptionText>
        {!isReview && (
          <AiFillCloseCircle
            size="1.5em"
            color="#999"
            style={{ marginLeft: 8, height: 20, width: 20 }}
            onClick={deleteQuizItemHandler}
          />
        )}
      </FlexContainer>
    </QuizItemWrapper>
  );
};

const QuizItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f8f8f8;
  margin-bottom: 10px;
  border-radius: 4px;
  &:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
`;

const QuizItemTitleText = styled(Typography.Text)`
  flex: 1;
`;

const QuizItemOptionText = styled(Typography.Text)`
  width: 100px;
  text-align: right;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default QuizItem;
