import React from 'react';
import { Table, Button, Popover } from 'antd';
import { EditOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';

const CertificateTable = ({ dataResult, editCertificateHandler, toggleSignatureHandler }) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Job Title',
      dataIndex: 'jobTitle',
      key: 'jobTitle'
    },
    {
      title: 'Signature',
      dataIndex: 'signature',
      key: 'signature',
      render: signature => (
        <Popover
          content={
            <div style={{ width: '100%', height: '100px' }}>
              <img src={signature} alt="" style={{ width: '100%', height: '100%' }} />
            </div>
          }
          title="Signature">
          <span style={{ color: '#0966b8', textDecoration: 'underline' }}>
            {signature?.substr(signature?.length - 16)}
          </span>
        </Popover>
      )
    },
    {
      title: ' Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <div>
          <Button
            style={{ border: '1px solid #0966b8', borderRadius: '100%', marginRight: 12 }}
            shape="circle"
            size="small"
            icon={<EditOutlined style={{ color: '#0966b8' }} />}
            onClick={() => {
              editCertificateHandler(record.name, record.jobTitle, record.signature, record.key);
            }}></Button>
          <Button
            style={{
              border: `1px solid ${record.isActive ? '#ff0000' : '#0966b8'}`,
              borderRadius: '100%'
            }}
            shape="circle"
            size="small"
            icon={
              record.isActive ? (
                <CloseOutlined style={{ color: 'red' }} />
              ) : (
                <CheckOutlined style={{ color: '#0966b8' }} />
              )
            }
            onClick={() => toggleSignatureHandler(record.key, record.isActive)}></Button>
        </div>
      )
    }
  ];
  const tableData = dataResult?.map(item => {
    return {
      ...item,
      key: item.id,
      name: item?.name?.toUpperCase(),
      jobTitle: item?.jobTitle?.toUpperCase(),
      signature: item?.signatureUrl
    };
  });

  return <Table columns={columns} dataSource={tableData} />;
};

export default CertificateTable;
