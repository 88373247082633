import React from 'react';
import MainLayout from 'components/MainLayout';
import { Card, Col, Input, Row, Typography } from 'antd';
import styled from 'styled-components';
import CustomButton from 'components/common/CustomButton';
import CoursePurchaseTable from 'components/tables/CoursePurchaseTable';

const CoursePurchase = () => {
  const { Text } = Typography;

  const onSearch = value => console.log(value);

  return (
    <MainLayout isAdmin sidebar padded selectedKey="coursePurchase">
      <Card>
        <HeaderRow gutter={16}>
          <Col span={8}>
            <Title level={2}>Course Purchase</Title>
            <Text>Manage your Enrolled Students</Text>
          </Col>
          <CenterCol span={8}>
            <Search placeholder="Search Course, Student" onSearch={onSearch} size="large" />
          </CenterCol>
          <ExportCol span={8}>
            <CustomButton bgColor="#4d4d53" color="#fff" size="large">
              Export
            </CustomButton>
          </ExportCol>
        </HeaderRow>
        <CoursePurchaseTable />
      </Card>
    </MainLayout>
  );
};

const Title = styled(Typography.Title)`
  margin-bottom: 0px !important;
`;

const CenterCol = styled(Col)`
  display: flex;
  margin: auto;
`;

const ExportCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const Search = styled(Input.Search)`
  width: 100%;
`;

const HeaderRow = styled(Row)`
  margin-bottom: 20px;
`;

export default CoursePurchase;
