import React from 'react';
import 'styles/utils.css';
import { Box, Text, Heading, ResponsiveContext, Button } from 'grommet';
import { isMobile, isTablet } from 'utils';
import { LinkPrevious } from 'grommet-icons';
import { useHistory } from 'react-router-dom';

const PageHeader = ({
  pageTitle,
  pageDescription,
  pageActionElement,
  canGoBack,
  marginBottom = 'small',
  pageActionPosition = 'end',
  color = '#777'
}) => {
  const size = React.useContext(ResponsiveContext);
  const history = useHistory();

  return (
    <Box
      direction={isMobile(size) ? 'column' : 'row'}
      justify="between"
      align={isMobile(size) ? 'stretch' : 'center'}
      width="100%"
      wrap={true}
      margin={{ bottom: isMobile(size) ? 'medium' : marginBottom }}>
      <Box flex="grow">
        <Box direction="row" align="center">
          {canGoBack && (
            <Button
              margin={{ right: 'xsmall' }}
              style={{ padding: '0px' }}
              icon={<LinkPrevious color="brand" size="medium" />}
              onClick={() => history.goBack()}
            />
          )}
          <Heading
            margin={{ bottom: 'xxsmall', top: 'none' }}
            level={4}
            size={isMobile(size) ? 'small' : 'medium'}
            color={color}>
            {pageTitle}
          </Heading>
        </Box>
        <Text size="small">{pageDescription}</Text>
      </Box>
      {pageActionElement && (
        <Box
          direction="row"
          gap="small"
          justify={isTablet(size) || isMobile(size) ? 'start' : pageActionPosition}>
          {pageActionElement}
        </Box>
      )}
    </Box>
  );
};

export default PageHeader;
