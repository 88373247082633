import Axios from 'axios';

const service = 'Discount';

export const getDiscounts = async (key, institutionId, search, startDate, endDate) => {
  const { data } = await Axios.get(
    `/${service}/api/Discount/Search?institutionId=${institutionId}`
  );
  return data;
};

export const getDiscountId = async (key, discountId) => {
  const { data } = await Axios.get(
    `/${service}/api/Discount/GetDiscountById?DiscountId=${discountId}`
  );
  return data;
};

export const getDiscountCode = async (code, courseId) => {
  const { data } = await Axios.get(
    `/${service}/api/Discount/GetDiscountCode?code=${code}&courseId=${courseId}`
  );
  return data;
};
