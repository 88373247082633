import React from 'react';
import { Box, Image, Anchor } from 'grommet';
import SkillDormBlackLogo from 'assets/images/skilldormblacklogo.png';
import { changepassword, dashboard, forgetpassword, login } from 'adminRoutes';
import { animate } from 'utils';
import LoginForm from 'components/forms/LoginForm';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { signInClear } from 'store/actions/auth';

const Login = () => {
  const [canRedirect, setCanRedirect] = React.useState(false);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  // clear last location
  React.useEffect(() => {
    const loc = sessionStorage.getItem('_loc');
    if (loc && loc.length === 1) {
      sessionStorage.removeItem('_loc');
    }
  }, []);

  // clear sign in error side effect
  React.useEffect(() => {
    dispatch(signInClear());
  }, [dispatch]);

  // redirect on success
  React.useEffect(() => {
    if (auth.success) {
      setCanRedirect(auth.success);
    }
  }, [auth.success]);

  if (canRedirect) {
    // redirect to change password on first login
    if (auth.isFirstLogin) return <Redirect to={changepassword} />;
    else if (auth.userId) {
      if (sessionStorage.getItem('_loc')) {
        const location = sessionStorage.getItem('_loc');

        if (location.includes(login)) return <Redirect to={dashboard} />;

        // redirect to last location
        if (localStorage.getItem('_u') === auth.userId.toString())
          return <Redirect to={location} />;
      }
      // redirect to dashboard on login successful
      return <Redirect to={dashboard} />;
    }
  }

  return (
    <Box pad="medium" align="center" justify="center" height="100vh">
      <Box margin={{ bottom: '20px' }} height="50px" width="178px">
        <Image fit="cover" src={SkillDormBlackLogo} />
      </Box>
      <Box align="center" animation={animate({ type: 'slideUp' })}>
        <LoginForm />
        <Anchor
          className="anchor-brand-secondary"
          style={{ fontWeight: 400 }}
          size="medium"
          margin={{ top: 'medium' }}
          href={forgetpassword}
          label="Forgot Password"
        />
      </Box>
    </Box>
  );
};

export default Login;
