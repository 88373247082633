import PageHeader from 'components/common/PageHeader';
import PageLoader from 'components/common/PageLoader';
import ManageCourseCategoryModal from 'components/modals/ManageCourseCategoryModal';
import { Box, Button, Heading, Text } from 'grommet';
import Settings from 'pages/admin/Settings';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { FormEdit, FormSubtract, FormCheckmark } from 'grommet-icons';
import { useDispatch } from 'react-redux';
import { showToast } from 'store/actions/toast';
import { courseCategoryList, courseCategoryToggle } from 'api/queries/Course';

const CourseConfiguration = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCourseCategoryId, setSelectedCourseCategoryId] = useState(null);
  const [selectedCourseCategoryName, setSelectedCourseCategoryName] = useState(null);
  const [selectedCourseCategoryCode, setSelectedCourseCategoryCode] = useState(null);
  const { isLoading, data: courseCategory, refetch: refetchCourseCategory } = useQuery(
    'courseCategory',
    courseCategoryList
  );

  const reset = () => {
    setSelectedCourseCategoryId(null);
    setSelectedCourseCategoryName(null);
    setSelectedCourseCategoryCode(null);
  };

  const refetchCourseCategoryHandler = () => {
    reset();
    refetchCourseCategory();
  };

  const addCourseCategoryHandler = () => {
    reset();
    setShowModal(true);
  };

  const editRoleHandler = (courseCategoryId, courseCategoryName, courseCategoryCode) => {
    setSelectedCourseCategoryId(courseCategoryId);
    setSelectedCourseCategoryName(courseCategoryName);
    setSelectedCourseCategoryCode(courseCategoryCode);
    setShowModal(true);
  };

  const courseCategoryToggleHandler = async (courseCategoryId, isActive) => {
    try {
      setLoading(true);
      const data = await courseCategoryToggle(courseCategoryId);
      if (data.success) {
        dispatch(
          showToast(
            'success',
            isActive ? 'Course Category Deactivated' : 'Course Category Activated'
          )
        );
        refetchCourseCategoryHandler();
      }
    } catch (error) {
      console.log(error);
      dispatch(
        showToast('error', `Failed to ${isActive ? 'deactivate' : 'activate'} course category`)
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Settings selectedKey="Course Configuration">
      <PageHeader
        pageActionElement={
          <>
            <Button primary label="Add Category" onClick={addCourseCategoryHandler} />
          </>
        }
        pageDescription="Create and manage course configuration"
        marginBottom="medium"
        pageTitle="Course Configuration"
      />
      <Box>
        <Box>
          <Box background="light-2" pad="small" direction="row" justify="between">
            <Heading margin="none" level="5" style={{ fontSize: '1.25rem' }}>
              Course Category
            </Heading>
            <Heading margin="none" level="5" style={{ fontSize: '1.25rem' }}>
              Action
            </Heading>
          </Box>
          {courseCategory?.data?.map(item => (
            <Box
              key={item.id}
              pad="small"
              direction="row"
              justify="between"
              align="center"
              onClick={() => {}}
              hoverIndicator={{ color: 'light-1' }}>
              <Text color={item.isActive ? 'dark-2' : 'light-6'}>{item.name.toUpperCase()}</Text>
              <Box direction="row" gap="small">
                <Button
                  plain
                  icon={
                    <FormEdit
                      color="#FF8C00"
                      size="medium"
                      style={{ border: '2px solid #FF8C00', borderRadius: '50%' }}
                    />
                  }
                  onClick={() =>
                    editRoleHandler(item.id, item.name.toUpperCase(), item.code.toUpperCase())
                  }
                />
                <Button
                  plain
                  icon={
                    item.isActive ? (
                      <FormSubtract
                        color="#F00"
                        size="medium"
                        style={{ border: '2px solid #F00', borderRadius: '50%' }}
                      />
                    ) : (
                      <FormCheckmark
                        color="#008000"
                        size="medium"
                        style={{ border: '2px solid #008000', borderRadius: '50%' }}
                      />
                    )
                  }
                  onClick={() => courseCategoryToggleHandler(item.id, item.isActive)}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      {showModal && (
        <ManageCourseCategoryModal
          onClose={() => setShowModal(false)}
          setLoading={setLoading}
          refetchCourseCategoryHandler={refetchCourseCategoryHandler}
          selectedCourseCategoryId={selectedCourseCategoryId}
          selectedCourseCategoryName={selectedCourseCategoryName}
          selectedCourseCategoryCode={selectedCourseCategoryCode}
        />
      )}
      <PageLoader fullWidth={loading} fullHeight={loading} isLoading={isLoading} />
    </Settings>
  );
};

export default CourseConfiguration;
