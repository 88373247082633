import React, { useContext, useEffect, useState } from 'react';
import 'styles/utils.css';
import DiscoveryImg from 'assets/images/discover.png';
import styled from 'styled-components';
import { Typography, Col, Row } from 'antd';
import MainLayout from 'components/MainLayout';
// import CourseLanRating from 'components/common/CourseLanRating';
// import IconWrapper from 'components/common/IconWrapper';
// import ModuleIcon from 'assets/svgs/ic_module.svg';
// import TimeIcon from 'assets/svgs/ic_time.svg';
// import TuneIcon from 'assets/svgs/ic_tune.svg';
// import SchoolIcon from 'assets/svgs/ic_school.svg';
import EnrollmentCard from 'components/utils/CourseEnrollment/EnrollmentCard';
// import CourseDescription from 'components/utils/CourseLanding/CourseDescription';
import Requirements from 'components/utils/CourseEnrollment/Requirements';
import InstructorsNote from 'components/utils/CourseEnrollment/InstructorsNote';
import htmr from 'htmr';
import { searchInstitutionById } from 'api/queries/Institution';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { courseDetails as courseDetailsAction } from 'store/actions/course';
import { courseSearch } from 'api/mutations/Course';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import DefaultPoster from 'assets/images/default-img.png';
import CourseOverview from 'components/utils/CourseLanding/CourseOverview';
import { MainContext } from 'index';
import { getCourseBuilder } from 'api/queries/Course';
import PageLoader from 'components/common/PageLoader';
import { discovery } from 'userRoutes';
import { LeftOutlined } from '@ant-design/icons';
import CourseCategory from 'components/common/CourseCategory';
import { useRef } from 'react';
import MyLearnings from '../../MyLearnings';

const CourseLanding = () => {
  const [courseSearchResult, setCourseSearchResult] = React.useState(null);
  const [activeRef, setActiveRef] = React.useState(null);
  const [courseDetails, setCourseDetails] = React.useState(null);
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });
  const [institutionDetails, setInstitutionDetails] = React.useState(null);
  const auth = useSelector(state => state.auth);
  const [poster, setPoster] = useState(DefaultPoster);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  // const mainContext = useContext(MainContext);
  const history = useHistory();
  // const location = useLocation();
  const { id } = useParams();
  const aboutPartner = useRef(null);
  const courseDescriptionPartner = useRef(null);

  const getCoursedetails = async () => {
    try {
      setIsLoading(true);
      const { data } = await getCourseBuilder(id);
      if (data) {
        setIsLoading(false);
        setCourseDetails(data);
        dispatch(courseDetailsAction(data));
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCoursedetails();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [courseDetails]);

  useEffect(() => {
    setPoster(courseDetails?.courseBannerUrl);
  }, [courseDetails]);

  // searchInstitutionById
  const { data: institutionResult } = useQuery(
    ['institutionResult', courseDetails?.institutionId],
    searchInstitutionById
  );
  // const { data: courseCategory } = useQuery(['courseCategory'], courseCategoryList);
  useEffect(() => {
    if (institutionResult) setInstitutionDetails(institutionResult[0]);
  }, [institutionResult]);

  // fetch course API
  const [createMutate] = useMutation(courseSearch, {
    onSuccess: dataResult => {
      const { description, data, success } = dataResult;
      if (success) {
        setCourseSearchResult(data?.items);
        // if (data) {
        //   const courses = data?.items;
        //   const n = 3;
        //   const result = new Array(Math.ceil(courses.length / n))
        //     .fill(0)
        //     .map(_ => courses.splice(0, n));
        // }
      } else {
        console.log(description);
      }
    },
    onError: error => {
      if (error.message === 'Network Error') {
        console.log(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        console.log(error);
      }
    }
  });

  // fetch course API effect
  useEffect(() => {
    const search = () => {
      const param = {
        page: 1,
        pageSize: 50,
        courseCategoryIds: [],
        parameter: '',
        institutionIds: [],
        courseLevels: [],
        languageIds: []
      };
      createMutate(param);
    };
    search();
  }, [createMutate]);

  const backtoDiscovery = () => {
    history.push(discovery);
  };

  const scrollDown = (ref, name) => {
    window.scrollTo({
      top: ref?.current?.offsetTop + 100,
      behavior: 'smooth'
    });
    console.log(name);
    setActiveRef(name);
  };

  return (
    <MainLayout sidebar={isMobileDevice ? false : auth.userId ? true : false} selectedKey="course">
      <ContainerFluid
        height={isMobileDevice ? '60px' : '200px'}
        img={
          courseDetails?.courseBannerUrl ? encodeURI(courseDetails?.courseBannerUrl) : DiscoveryImg
        }>
        <div style={{ width: '100%' }}>
          <CanGoBackWrapper onClick={backtoDiscovery}>
            <CanGoBackIcon>
              <LeftOutlined style={{ color: '#fff', fontSize: 14 }} />
            </CanGoBackIcon>
            <CanGoBackText>Back to Courses</CanGoBackText>
          </CanGoBackWrapper>

          {!isMobileDevice && (
            <>
              <HeaderTitle level={isMobileDevice ? 4 : 1}>{courseDetails?.title}</HeaderTitle>
              <div style={{ width: isMobileDevice ? '100%' : '50%' }}>
                <DescriptionWrapper>{courseDetails?.description}</DescriptionWrapper>
              </div>{' '}
            </>
          )}
          {/* <CanGoBackWrapper>
            <RateDiv margin="20px 0px">
              <CourseLanRating reviews={3} left={'0px'} />
            </RateDiv>
            <InstitutionBy>
              offered by{' '}
              <Typography.Text size="small" style={{ fontSize: '14px', color: '#fff' }}>
                {courseDetails?.institution}
              </Typography.Text>
            </InstitutionBy>
          </CanGoBackWrapper>
          <ContainerIcon
            width={isMobileDevice ? '100%' : '38%'}
            display={isMobileDevice ? ' -webkit-box' : 'flex'}
            justify="space-between">
            <AlignFlex pad="0px 10px 0px 0px" direction="row">
              <IconWrapper
                style={{ filter: 'brightness(0) invert(1)' }}
                icon={ModuleIcon}
                height="14px"
                width="14px"
              />
              <CustomText color="#fff" size={isMobileDevice ? '9px' : '12px'} marginleft="8px">
                {courseDetails?.modules} Modules
              </CustomText>
            </AlignFlex>
            <AlignFlex pad="0px 10px 0px 0px" direction="row">
              <IconWrapper
                style={{ filter: 'brightness(0) invert(1)' }}
                icon={TimeIcon}
                height="14px"
                width="14px"
              />
              <CustomText color="#fff" size={isMobileDevice ? '9px' : '12px'} marginleft="8px">
                {courseDetails?.duration}
              </CustomText>
            </AlignFlex>
            <AlignFlex pad="0px 10px 0px 0px" direction="row">
              <IconWrapper
                style={{ filter: 'brightness(0) invert(1)' }}
                icon={SchoolIcon}
                height="14px"
                width="14px"
              />
              <CustomText color="#fff" size={isMobileDevice ? '9px' : '12px'} marginleft="8px">
                {courseDetails?.courseLevelName} Level
              </CustomText>
            </AlignFlex>
            <AlignFlex pad="0px 10px 0px 0px" direction="row">
              <IconWrapper
                style={{ filter: 'brightness(0) invert(1)' }}
                icon={TuneIcon}
                height="15px"
                width="15px"
              />
              <CustomText color="#fff" size={isMobileDevice ? '9px' : '12px'} marginleft="8px">
                1 Year Access
              </CustomText>
            </AlignFlex>
          </ContainerIcon> */}
        </div>
      </ContainerFluid>
      <Container>
        <Row justify="space-between" style={{ marginBottom: '20px' }}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 16 }} xxl={{ span: 17 }}>
            {isMobileDevice && (
              <EnrollmentCard
                data={courseDetails}
                courseId={courseDetails?.courseId}
                newPrice={courseDetails?.coursePrice?.amount}
                userId={auth.userId}
              />
            )}
            {!isMobileDevice && (
              <SpaceBetween align="center" direction="row">
                <HeaderSubTitleWrap background={activeRef === 'description' ? '#f7fbff' : '#fff'}>
                  <HeaderSubTitle
                    onClick={() => scrollDown(courseDescriptionPartner, 'description')}
                    level={4}>
                    Course Description
                  </HeaderSubTitle>{' '}
                </HeaderSubTitleWrap>
                <HeaderSubTitleWrap background={activeRef === 'partner' ? '#f7fbff' : '#fff'}>
                  <HeaderSubTitle onClick={() => scrollDown(aboutPartner, 'partner')} level={4}>
                    About Partner
                  </HeaderSubTitle>
                </HeaderSubTitleWrap>
              </SpaceBetween>
            )}
            <DetailWrapper ref={courseDescriptionPartner}>
              {/* <CourseDescription details={courseDetails?.description} /> */}
              {!isMobileDevice && (
                <VideoContainer>
                  {courseDetails && (
                    <ReactPlayer
                      url={courseDetails?.coursePromotionalVideoUrl}
                      width="100%"
                      height="100%"
                      config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                      onContextMenu={e => e.preventDefault()}
                      light={poster}
                      controls
                    />
                  )}
                </VideoContainer>
              )}
              {courseDetails && (
                <Requirements size="16px" items={htmr(courseDetails?.objectives)} />
              )}
              {courseDetails && <CourseOverview details={htmr(courseDetails?.overView)} />}
            </DetailWrapper>
            <div>
              <MyLearnings
                courseDetails={courseDetails}
                state={{ courseId: courseDetails?.courseId, userId: auth?.userId }}
              />
            </div>
            <section ref={aboutPartner}>
              {institutionDetails && (
                <InstructorsNote
                  details={htmr(institutionDetails?.overView)}
                  institutionEmail={institutionDetails?.email}
                  // institutionCode={institutionDetails?.code}
                  logo={institutionDetails?.logoUrl}
                />
              )}
            </section>

            {courseSearchResult && (
              <CourseCategory
                otherPages
                fontSize={'24px'}
                left={'none'}
                right={'none'}
                // categoryType={categoryType}
                searchType="top"
                title={`Other courses offered by ${courseDetails?.institution}`}
                courseSearchResult={courseSearchResult.filter(
                  item =>
                    item?.courseId !== courseDetails?.courseId &&
                    item?.institutionId === courseDetails?.institutionId
                )}
                setCourseDetails={setCourseDetails}
              />
            )}
          </Col>
          {!isMobileDevice && (
            <CustomColumn xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 7 }} xxl={{ span: 6 }}>
              <CourseSubTitle>
                <HeaderSubTitle level={4}>Course Detail</HeaderSubTitle>{' '}
              </CourseSubTitle>

              <EnrollmentCard
                poster={poster}
                data={courseDetails}
                courseId={courseDetails?.courseId}
                newPrice={courseDetails?.coursePrice?.amount}
                userId={auth.userId}
              />
            </CustomColumn>
          )}
        </Row>

        {/* {courseSearchResult && (
          <CourseCategory
            otherPages
            left={'none'}
            right={'none'}
            // categoryType={categoryType}
            searchType="top"
            title={`Other courses offered by ${courseDetails?.institution}`}
            courseSearchResult={courseSearchResult.filter(
              item => item.courseId !== courseDetails?.courseId
            )}
          />
        )} */}
        {/* {courseSearchResult && (
          <CourseCategory
            otherPages
            left={'none'}
            right={'none'}
            // categoryType={categoryType}
            searchType="top"
            title="Other Popular courses"
            courseSearchResult={courseSearchResult.filter(
              item => item.courseId !== courseDetails?.courseId
            )}
          />
        )} */}
        {/* {courseCategory && <TopCategories otherPages courseCategory={courseCategory?.data} />} */}
      </Container>
      <PageLoader isLoading={isLoading} />
    </MainLayout>
  );
};

export default CourseLanding;

const VideoContainer = styled.div`
  display: flex;
  ${'' /* padding: 28px; */}
  margin-bottom: 20px;
  height: 400px;
`;

const ContainerFluid = styled.div`
  height: ${({ height }) => height};
  display: flex;
  align-items: center;
  padding: 28px;
  background: ${({ img }) =>
    `linear-gradient(to right, rgba(0,0,0,1),rgba(0,0,0,1), rgba(0,0,0,.1) ), url('${img}')`};
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
`;

// const RateDiv = styled.div`
//   margin: ${({ margin }) => margin};
// `;
// const RateDiv = styled.div `
//   margin: ${({ margin }) => margin};
// `

// const CustomText = styled(Typography.Text)`
//   color: ${({ color }) => color};
//   font-size: ${({ size }) => size};
//   margin-left: ${({ marginleft }) => marginleft};
// `;

const DescriptionWrapper = styled(Typography.Paragraph)`
  line-height: 20px;
  font-size: 15px;
  color: #fff;
  text-align: left;
  width: 100%;
  height: 60px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

// const AlignFlex = styled.div`
//   display: flex;
//   box-sizing: border-box;
//   max-width: 100%;
//   border-bottom: ${({ border }) => (border === 'bottom' ? 'solid 1px #cccccc' : 'none')};
//   flex-direction: ${({ direction }) => direction};
//   justify-content: ${({ justify }) => justify};
//   ${'' /* padding-bottom: 10px; */}
//   padding: ${({ pad }) => pad};
//   flex-wrap: wrap;
//   align-items: center;
// `;

const SpaceBetween = styled.div`
  position: sticky;
  top: 64px;
  left: 0;
  z-index: 999;
  display: flex;
  background: #ffffff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  max-width: 100%;
  border-bottom: ${({ border }) => (border === 'bottom' ? 'solid 1px #cccccc' : 'none')};
  flex-direction: ${({ direction }) => direction};
  ${'' /* justify-content: space-between; */}
  ${'' /* padding: 10px; */}
  padding-left: ${({ padLeft }) => padLeft};
  flex-wrap: wrap;
  gap: 30px;
  align-items: ${({ align }) => align};
`;

const HeaderTitle = styled(Typography.Title)`
  color: #fff !important;
  word-spacing: 5px;
`;
const HeaderSubTitle = styled(Typography.Title)`
  margin-bottom: 0px !important;
  color: #0966b8;
`;
const HeaderSubTitleWrap = styled.div`
  padding: 10px;
  background: ${({ background }) => background};
  cursor: pointer;
`;
const CourseSubTitle = styled.div`
  padding: 10px 10px 10px 0px;
  position: sticky;
  z-index: 999;
  ${'' /* margin-bottom: 10px; */}
  top: 64px;
  background: #fff;
  ${'' /* left: 0; */}
`;

const Container = styled.div`
  padding: 28px;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
`;
const CustomColumn = styled(Col)``;

const DetailWrapper = styled.div`
  margin-top: 10px; ;
`;

// const ContainerIcon = styled.div`
//   display: ${({ display }) => display};
//   flex-direction: ${({ direction }) => direction};
//   justify-content: ${({ justify }) => justify};
//   width: ${({ width }) => width};
// `;

// const LoaderWrapper = styled.div`
//   font-size: 20px;
//   font-weight: 700;
// `;

const CanGoBackWrapper = styled.div`
  display: flex;
  margin: 6px 0px 20px;
  align-items: center;
  cursor: pointer;
`;
const CanGoBackIcon = styled.span`
  margin-right: 1rem;
`;
// const InstitutionBy = styled.span`
//   margin-left: 1rem;
//   color: #fff;
//   font-size: 14px;
// `;

const CanGoBackText = styled(Typography.Paragraph)`
  font-size: 14px;
  margin: 0px !important;
  color: #fff;
`;
