import * as discountTypes from 'store/actions/discount';

const initialState = {
  overviewDiscount: null,
  discountId: 0,
  showDiscountUpdated: false
};

const discountReducer = (state = initialState, action) => {
  // console.log('action', action);
  switch (action.type) {
    case discountTypes.CLEAR_DISCOUNT:
      return initialState;
    case discountTypes.VIEW_DISCOUNT:
      return {
        ...state,
        overviewDiscount: action.data
      };
    case discountTypes.DISCOUNTID:
      return {
        ...state,
        discountId: action.data
      };
    case discountTypes.SHOWDISCOUNTUPDATE:
      return {
        ...state,
        showDiscountUpdated: action.data
      };
    default:
      return state;
  }
};

export default discountReducer;
