import React from 'react';
import styled from 'styled-components';
import { Table, Button, Typography, Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import moment from 'moment';
import { viewdiscount, viewdiscountpage } from 'adminRoutes';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { discountId, viewDiscount } from 'store/actions/discount';
import Axios from 'axios';
import { showToast } from 'store/actions/toast';
import useCurrencyFormater from 'components/hooks/useCurrencyFormater';

const DiscountTable = props => {
  const { discount, refetch } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const currencyFormatter = useCurrencyFormater();

  const service = 'Discount';

  const activateDeactivateDiscount = async (id, isActive) => {
    const { data } = await Axios.delete(
      `/${service}/api/Discount/ActivateDeactivate?id=${id}&isActive=${isActive}`
    );
    refetch();
    console.log('data', data);
    dispatch(showToast('warning', data.description));
    return data;
  };

  const columns = [
    {
      title: ' Discount Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: ' Discount Charge Type',
      dataIndex: 'discountChargeTypeName',
      key: 'discountChargeTypeName'
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate'
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate'
    },
    {
      title: 'Value',
      key: 'amount',
      dataIndex: 'amount'
    },
    {
      title: 'Discount Code',
      key: 'code',
      dataIndex: 'code'
    },
    {
      title: 'Usage Limit',
      key: 'usageLimit',
      dataIndex: 'usageLimit'
    },
    {
      title: 'Status',
      key: 'isActive',
      dataIndex: 'isActive'
    },
    {
      title: 'Partner',
      key: 'institution',
      dataIndex: 'institution'
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button
          onClick={() => {
            console.log('record', record);
            record.isActive = true;
            dispatch(discountId(record.id));
            history.replace(`${viewdiscount.replace('/:viewdiscount', '')}/${record.id}`);
          }}
          type="primary">
          View
        </Button>
      )
    }
  ];
  //{currencyFormatter('NGN', 'en-NG', discountAmount)}
  console.log('discount', discount);
  const tableData = discount?.data.map(rowData => ({
    ...rowData,
    key: rowData.id,
    isActive: (
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        //disabled
        checked={rowData.isActive ? true : false}
        onClick={() => {
          rowData.isActive
            ? activateDeactivateDiscount(rowData.id, false)
            : activateDeactivateDiscount(rowData.id, true);
        }}
      />
    ),
    amount:
      rowData.discountChargeType === 1
        ? currencyFormatter('NGN', 'en-NG', rowData.amount)
        : rowData.discountChargeType === 2
        ? rowData.amount + ' %'
        : null,
    startDate: moment(rowData.startDate).format('YYYY-MM-DD hh:mm:ss'),
    endDate: moment(rowData.endDate).format('YYYY-MM-DD hh:mm:ss')
  }));

  return <Table columns={columns} dataSource={tableData} />;
};

export default DiscountTable;

const ViewBtn = styled(Button)`
  border: none;
  background: #2c7be5;
  color: #fff;
`;
