import React, { useState } from 'react';
import { Box, Button } from 'grommet';
import PageHeader from 'components/common/PageHeader';
import PageLoader from 'components/common/PageLoader';
import { useHistory } from 'react-router-dom';
import NotificationModal from 'components/modals/NotificationModal';
import ConfigurationSettings from '../Settings';
import { emailtemplate } from 'adminRoutes';
import WorkFlowAuthorizationCard from 'components/common/WorkFlowAuthorizationCard';
import ManageWorkFlowModal from 'components/modals/ManageWorkFlowModal';
import EditManageWorkFlowModal from 'components/modals/EditManageWorkFlowModal';
import { getWorkflowByInstitutionId } from 'api/queries/Workflow';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

const ManageWorkflow = props => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [refetchWorkflow, setRefetchWorkflow] = useState();
  const auth = useSelector(state => state.auth);

  const { data: workflows, refetch } = useQuery(
    ['workflows', auth.institutionId],
    getWorkflowByInstitutionId
  );

  return (
    <ConfigurationSettings selectedKey="Approval Settings">
      <Box margin={{ bottom: 'small' }}>
        <PageHeader
          pageActionElement={
            <>
              <Button onClick={() => setOpenModal(true)} label="Add WorkFlow" primary />
            </>
          }
          pageDescription="Create and manage your partners approval workflow"
          marginBottom="none"
          pageTitle="Manage Workflow"
        />
        <Box margin={{ top: 'small' }}>
          <WorkFlowAuthorizationCard
            setOpenEditModal={setOpenEditModal}
            setSelectedItem={setSelectedItem}
            setRefetchWorkflow={setRefetchWorkflow}
            workflows={workflows}
            refetchWorkflow={refetch}
          />
        </Box>
      </Box>
      {openModal && (
        <ManageWorkFlowModal refetchWorkflow={refetch} onClose={() => setOpenModal(false)} />
      )}
      {openEditModal && (
        <EditManageWorkFlowModal
          onClose={() => setOpenEditModal(false)}
          selectedItem={selectedItem}
          refetchWorkflow={refetch}
        />
      )}
      <PageLoader isLoading={false} />
      {successMessage && (
        <NotificationModal
          message="Email Created Successfully"
          status="success"
          iconHeight="80px"
          iconWidth="80px"
          actionText="Complete"
          actionFunc={() => {
            history.push(emailtemplate);
          }}
        />
      )}
    </ConfigurationSettings>
  );
};

export default ManageWorkflow;
