import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { timeStampFormat } from 'utils';

const ManageEnrolledStudentTable = ({
  result,
  currentPage,
  pageSize,
  setCurrentPage,
  setPageSize,
  toggleExport
}) => {
  const [pagination, setPagination] = useState();

  useEffect(() => {
    if (!toggleExport) {
      setPagination({
        current: currentPage,
        pageSize: pageSize,
        total: result?.data[0]?.totalRows
      });
    }
  }, [result, currentPage, pageSize, toggleExport]);

  const columns = [
    {
      title: 'FIRSTNAME',
      dataIndex: 'firstName',
      key: 'firstname'
    },
    {
      title: 'LASTNAME',
      dataIndex: 'lastName',
      key: 'lastName'
    },
    {
      title: 'ENROLLED DATE',
      dataIndex: 'erolledDate',
      key: 'erolledDate'
    },
    {
      title: 'LAST VISITED',
      dataIndex: 'lastVisited',
      key: 'lastVisited',
      render: text => <p style={{ color: '#1890ff' }}>{text}</p>
    },
    {
      title: 'AMOUNT PAID',
      dataIndex: 'amountPaid',
      key: 'amountPaid'
    },
    {
      title: 'DISCOUNT',
      dataIndex: 'discountAmount',
      key: 'discountAmount'
    },
    {
      title: 'COURSE BASE PRICE',
      dataIndex: 'courseBasePrice',
      key: 'courseBasePrice'
    },
    {
      title: 'VAT',
      dataIndex: 'vat',
      key: 'vat'
    },
    {
      title: 'GATEWAY FEE',
      dataIndex: 'gatewayFee',
      key: 'gatewayFee'
    },
    {
      title: 'STATUS',
      dataIndex: 'studentActive',
      key: 'studentActive'
    }
  ];

  const handleTableChange = pagination => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };
  const tableData = result?.data?.map((item, i) => ({
    ...item,
    key: i,
    erolledDate: timeStampFormat(item?.enrolledDate),
    studentActive: `${item?.studentActive ? 'InActive' : 'Active'}`,
    // lastVisited: item.lastDateVisted.split('T')[0],
    lastVisited:
      item?.lastDateVisted === null
        ? timeStampFormat(item?.enrolledDate)
        : timeStampFormat(item?.lastDateVisted)
  }));

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={pagination}
      onChange={handleTableChange}
    />
  );
};

export default ManageEnrolledStudentTable;
