import React from 'react';
import { Box, ResponsiveContext, Text } from 'grommet';
import FileUpload from 'components/common/FileUpload';
import { isMobile } from 'utils';

const ImageUpload = ({
  setFileList,
  setImageState,
  profilePixUrl,
  description,
  disabled,
  mediaType = 'image'
}) => {
  const size = React.useContext(ResponsiveContext);
  const [media, setMedia] = React.useState(null);
  // const videoRef = React.useRef();
  const getMedia = media => {
    if (mediaType === 'image') {
      if (media) {
        let reader = new FileReader();

        reader.onload = () => {
          setMedia(reader.result);
          setImageState(reader.result);
        };

        reader.readAsDataURL(media);
      }
    }
  };

  // console.log(media);

  return (
    <Box>
      <Box direction="column" gap="medium" justify="between" wrap={true}>
        {
          {
            image: (
              <Box
                flex="grow"
                style={{
                  minHeight: 150,
                  backgroundImage: `url(${
                    media
                      ? media
                      : profilePixUrl
                      ? profilePixUrl
                      : 'https://via.placeholder.com/300x300?text=No+Preview+Image'
                  })`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
                width={isMobile(size) ? 'fill' : 'small'}></Box>
            )
          }[mediaType]
        }
        <Text
          size="small"
          style={{ width: isMobile(size) ? '100%' : '350px' }}
          margin={{ bottom: 'small' }}>
          {description}
        </Text>
        <Box margin={{ bottom: 'small' }}>
          <FileUpload
            setFileList={setFileList}
            disabled={disabled}
            getMedia={getMedia}
            mediaType={mediaType}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ImageUpload;
