import React from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  CheckBox,
  Heading,
  Paragraph,
  ResponsiveContext,
  Text
} from 'grommet';
import CourseMediaUpload from 'components/forms/CourseMediaUpload';
import { useQuery } from 'react-query';
import { showToast } from 'store/actions/toast';
import { useDispatch, useSelector } from 'react-redux';
import { language } from 'api/queries/Course';
import InstitutionMediaUpload from './InstitutionMediaUpload';

const InstitutionInfoForm = ({
  isReview,
  setCurrent,
  defaultCourseValue,
  courseThumbNailUrl,
  setCourseThumbNailUrl,
  institutionBannerUrl,
  setInstitutionBannerUrl,
  institutionLogoUrl,
  setInstitutionLogoUrl,
  setCourseThumbNailUrlFile,
  setInstitutionBannerUrlFile,
  setInstitutionLogoUrlFile,
  setCertificatelogoUrl,
  setCertificatelogoUrlFile,
  certificatelogoUrl
}) => {
  const dispatch = useDispatch();
  const size = React.useContext(ResponsiveContext);
  const { isLoading, isError, data: languageData, error } = useQuery('language', language);
  const { institutionId } = useSelector(state => state.auth);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { courseId } = useSelector(state => state.course);

  const onSubmit = async saveDraft => {
    if (isSubmitting) return;
  };

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <Box gap="large" margin={{ top: 'small' }}>
      <Card>
        <CardHeader border="bottom">
          <Heading margin="medium" level={4} size="small" color="#777">
            Branding
          </Heading>
        </CardHeader>
        <CardBody pad="medium">
          <Box>
            <InstitutionMediaUpload
              title="Partner Logo"
              description="Upload your course image here. It must meet our course image quality standards to be
            accepted. Important guideline 750 x 422 pixels; .jpg, .jpeg, .gif or .png. No text on
            the image."
              institutionLogoUrl={encodeURI(institutionLogoUrl)}
              setInstitutionLogoUrl={setInstitutionLogoUrl}
              setInstitutionLogoUrlFile={setInstitutionLogoUrlFile}
            />
          </Box>
        </CardBody>
      </Card>

      <Card>
        <CardHeader border="bottom">
          <Heading margin="medium" level={4} size="small" color="#777">
            Landing Page
          </Heading>
        </CardHeader>
        <CardBody pad="medium">
          <Box gap="large">
            <InstitutionMediaUpload
              title="Default Partner Image Thumbnail"
              description="Upload your course image here. It must meet our course image quality standards to be
            accepted. Important guideline 750 x 422 pixels; .jpg, .jpeg, .gif or .png. No text on
            the image."
              courseThumbNailUrl={encodeURI(courseThumbNailUrl)}
              setCourseThumbNailUrl={setCourseThumbNailUrl}
              setCourseThumbNailUrlFile={setCourseThumbNailUrlFile}
            />
            <InstitutionMediaUpload
              title="Partner Banner"
              description="Upload your course image here. It must meet our course image quality standards to be
            accepted. Important guideline 750 x 422 pixels; .jpg, .jpeg, .gif or .png. No text on
            the image."
              institutionBannerUrl={encodeURI(institutionBannerUrl)}
              setInstitutionBannerUrl={setInstitutionBannerUrl}
              setInstitutionBannerUrlFile={setInstitutionBannerUrlFile}
            />
          </Box>
        </CardBody>
      </Card>
      <Card>
        <CardHeader border="bottom">
          <Heading margin="medium" level={4} size="small" color="#777">
            Certificate
          </Heading>
        </CardHeader>
        <CardBody pad="medium">
          <Box gap="large">
            <InstitutionMediaUpload
              title="Certificate Logo"
              description="Upload your certificate image here. It must meet our course image quality standards to be
            accepted. Important guideline 750 x 422 pixels; .jpg, .jpeg, .gif or .png. No text on
            the image."
              certificatelogoUrl={encodeURI(certificatelogoUrl)}
              setCertificatelogoUrl={setCertificatelogoUrl}
              setCertificatelogoUrlFile={setCertificatelogoUrlFile}
            />
          </Box>
        </CardBody>
      </Card>
    </Box>
  );
};

export default InstitutionInfoForm;
