import * as toastTypes from 'store/actions/toast';

// initial sign in state
const initialState = {
  status: null,
  show: false,
  message: null
};

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case toastTypes.SHOW_TOAST:
      const { status, message } = action.data;
      return {
        ...state,
        status,
        message,
        show: true
      };
    case toastTypes.HIDE_TOAST:
      return {
        ...state,
        status: null,
        message: null,
        show: false
      };
    default:
      return state;
  }
};

export default toastReducer;
