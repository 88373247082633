import React, { useState, useEffect } from 'react';
import RegisterButton from './RegisterButton.js';
import styled from 'styled-components';
import Menu from './Menu.js';
import SearchInput from 'components/common/SearchInput';
import Logo from './Logo.js';
import { Row, Col, Typography, Button } from 'antd';
import UserAvatar from 'components/common/UserAvatar';
import SearchResult from 'components/common/SearchResult';
import { useHistory, NavLink, Link } from 'react-router-dom';
import { dashboard, discovery, facilitators, home, partner, programs, register } from 'userRoutes';
import device from 'configs/responsive/mediaQueries';
import MenuIcon from 'assets/svgs/ic_menu.svg';
import CloseIcon from 'assets/svgs/ic_close.svg';
import IconWrapper from 'components/common/IconWrapper';
import { useMediaQuery } from 'react-responsive';

const { mobile } = device;

const Header = ({ auth, setShowSignin }) => {
  const [scroll, setScroll] = useState(0);
  const [scrollPosition] = useState(window.pageYOffset);
  const [showSearch, setShowSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [autoFocus, setAutoFocus] = useState(false);
  const history = useHistory();
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });

  const isHomePage = home === history.location.pathname;
  console.log('home', isHomePage);

  useEffect(() => {
    window.scrollTo(0, scrollPosition);
    return () => {
      window.scrollTo(0, scrollPosition);
    };
  }, []);

  const showFunc = val => {
    setAutoFocus(true);
    setShowSearch(val);
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const handleSignin = () => {
    setShowSignin(true);
    setShowMenu(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const mobileNav = (
    <SubMenuContainer width="86%" align="flex-start" justify="space-evenly" direction="column">
      <NavLink to={home} exact>
        <NavText color="#000">Home</NavText>
      </NavLink>
      {/* <NavLink to={programs} exact>
        <NavText color="#000">Programs</NavText>
      </NavLink> */}
      <NavLink to={discovery} exact>
        <NavText color="#000">Courses</NavText>
      </NavLink>
      <NavLink exact to={partner}>
        <NavText color="#000">Partners</NavText>
      </NavLink>
      <NavLink exact to={facilitators}>
        <NavText color="#000">Become an Instructor</NavText>
      </NavLink>
      {!auth?.userId && (
        <NavLink exact to={home}>
          <NavText color="#000" onClick={handleSignin}>
            Signin
          </NavText>
        </NavLink>
      )}
      {!auth?.userId && (
        <RegisterButton isMobile={isMobileDevice} setShowSignin={String(setShowSignin)} />
      )}
    </SubMenuContainer>
  );

  return (
    <div style={{ position: 'relative' }}>
      <Container scroll={scroll} home={isHomePage}>
        <Col span={isMobileDevice ? 6 : 4}>
          <Link to={home}>
            <Logo dashboard={false} isMobileDevice={isMobileDevice} />
          </Link>
        </Col>
        {!isMobileDevice && (
          <Col span={10}>
            <Menu color={'#fff'} />
          </Col>
        )}
        <Col span={10} offset={isMobileDevice ? 8 : 0}>
          {!isMobileDevice ? (
            <Row>
              <SearchInput color={'#fff'} showFunc={showFunc} marginLeft={'0em'} />
              {auth?.userId ? <UserAvatar auth={auth} /> : <RegisterButton />}
            </Row>
          ) : (
            <Row>
              {auth?.userId && <UserAvatar auth={auth} isMobile={isMobileDevice} />}
              <Col span={8} offset={auth?.userId ? 2 : 14}>
                <Button
                  style={{ padding: 1, border: '2px solid #777', borderRadius: 4 }}
                  icon={
                    <IconWrapper
                      width="25px"
                      height="25px"
                      icon={showMenu ? CloseIcon : MenuIcon}
                    />
                  }
                  onClick={() => {
                    if (isMobileDevice) {
                      setShowMenu(state => !state);
                      setShowSignin(false);
                    }
                  }}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Container>
      {autoFocus && (
        <SearchResult
          showSearch={showSearch}
          showFunc={showFunc}
          focus={autoFocus}
          setFocus={setAutoFocus}
        />
      )}
      {showMenu && isMobileDevice && <MobileMenuDiv>{mobileNav}</MobileMenuDiv>}
    </div>
  );
};

Header.propTypes = {};

export default Header;

const Container = styled(Row)`
  background: ${({ scroll, home }) => (home && scroll === 0 ? 'rgba(0, 0, 0, 0.3)' : '#000')};
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  padding: 0.9em 1.5em;
  @media ${mobile} {
    padding: 1em;
  }
`;

const NavText = styled(Typography.Text)`
  color: ${({ color }) => color};
  margin: 2rem 0 2rem 0;
  display: block;
`;

const SubMenuContainer = styled.div`
  display: flex;
  width: ${({ width }) => `${width}`};
  justify-content: ${({ justify }) => `${justify}`};
  flex-direction: ${({ direction }) => `${direction}`};
  align-items: ${({ align }) => `${align}`};
`;

const MobileMenuDiv = styled.div`
  width: 100%;
  position: fixed;
  background: white;
  top: 4rem;
  bottom: 0;
  left: 0;
  min-height: 332px;
  z-index: 1000;
  padding-left: 20px;
  transition: all 0.3s ease-in-out;
`;
