import React, { useState } from 'react';
import styled from 'styled-components';
import { BsChevronDown, BsCaretDownFill, BsCaretRightFill } from 'react-icons/bs';
import { useQuery } from 'react-query';

import CompareTable from './CompareTable';
import Loader from 'components/common/Loader';
import AppText from 'components/common/AppText';
import iconImage from 'assets/images/icon-image.png';
import iconQuiz from 'assets/images/icon-quiz.png';
import iconModule from 'assets/images/icon-module.png';
import iconVideo from 'assets/images/icon-video.png';
import iconPrice from 'assets/images/icon-price.png';
import iconDetails from 'assets/images/icon-details.png';
import noUpdate from 'assets/images/noUpdate.png';
import { getCourseUpdateHistory } from 'api/queries/Course';

const getIconType = section => {
  switch (section) {
    case 0:
      return iconImage;
    case 1:
      return iconVideo;
    case 2:
      return iconDetails;
    case 3:
      return iconQuiz;
    case 4:
      return iconPrice;
    case 5:
      return iconModule;

    default:
      return iconDetails;
  }
};

const UpdateHistory = ({ courseId }) => {
  const [currentUpdate, setCurrentUpdate] = useState();
  const [innerUpdate, setInnerUpdate] = useState();
  const [currentUpdateContent, setCurrentUpdateContent] = useState();
  const { data, isLoading } = useQuery(['updateHistory', courseId], getCourseUpdateHistory);
  // console.log('updateData', data);
  return (
    <Container>
      {isLoading ? (
        <NoDataDiv>
          <Loader color={'#0966b8'} />
          <AppText value={'Fetching updates...'} size="22px" top=".5rem" />
        </NoDataDiv>
      ) : data?.data?.length === 0 ? (
        <NoDataDiv>
          <img src={noUpdate} alt="" />
          <AppText value={'No current updates'} size="25px" top=".5rem" color={'#7f7f7f'} />
        </NoDataDiv>
      ) : (
        <>
          {data?.data?.map(update => (
            <div>
              <HeaderDiv onClick={() => setCurrentUpdate(prev => !prev)}>
                <Div>
                  <BsChevronDown color="#0A2E65" />
                  <AppText value={update?.date} bottom="0" color="#0A2E65" size={'18px'} />
                </Div>
                <AppText
                  value={`${update?.currentUpdates?.length} updates`}
                  bottom="0"
                  color="#0A2E65"
                  size={'18px'}
                />
              </HeaderDiv>
              <ContentDiv active={currentUpdate}>
                <>
                  <UpdateTitle
                    justify="space-between"
                    onClick={() => setInnerUpdate(prev => !prev)}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
                      <BsChevronDown />
                      <AppText
                        value={
                          <span>
                            {update?.userName} modified &nbsp;
                            <strong>{update?.courseTitle}</strong>
                          </span>
                        }
                        bottom="0"
                        color="#000"
                        size={'18px'}
                      />
                    </div>
                    <AppText value={update?.date} bottom="0" color="#5D5D5D" size={'12px'} />
                  </UpdateTitle>
                  <Inner>
                    <InnerUpdate active={innerUpdate}>
                      <UpdateDiv>
                        <UpdateTitle
                          justify="flex-start"
                          gap="1rem"
                          onClick={() => setCurrentUpdateContent(prev => !prev)}
                          cursor="pointer">
                          {currentUpdateContent ? (
                            <BsCaretDownFill color="#0A2E65" />
                          ) : (
                            <BsCaretRightFill color="#0A2E65" />
                          )}
                          <AppText
                            value={'See what is Added'}
                            bottom="0"
                            color="#0A2E65"
                            size={'18px'}
                          />
                        </UpdateTitle>
                        <UpdateContent active={currentUpdateContent}>
                          {update?.currentUpdates?.map(el => (
                            <UpdateTitle justify="space-between" margin="0 0 .5rem 0">
                              <AppText
                                value={el?.description}
                                bottom="0"
                                color="#000000B0"
                                icon={
                                  <img
                                    src={getIconType(el?.section)}
                                    style={{ marginRight: '1.2rem' }}
                                  />
                                }
                                size={'15px'}
                              />
                              <AppText
                                value={el?.time}
                                bottom="0"
                                color="#000000B0"
                                size={'15px'}
                              />
                            </UpdateTitle>
                          ))}
                        </UpdateContent>
                      </UpdateDiv>
                      {/* <UpdateDiv>
              <UpdateTitle justify="flex-start" gap="1rem">
                {currentUpdateContent ? (
                  <BsCaretDownFill color="#0A2E65" />
                ) : (
                  <BsCaretRightFill color="#0A2E65" />
                )}
                <AppText
                  value={'Compare previous and new update'}
                  bottom="0"
                  color="#0A2E65"
                  size={'18px'}
                />
              </UpdateTitle>
              <UpdateContent active={currentUpdateContent}>
                <CompareTable />
              </UpdateContent>
            </UpdateDiv> */}
                    </InnerUpdate>
                  </Inner>
                </>
              </ContentDiv>
            </div>
          ))}
        </>
      )}
    </Container>
  );
};

export default UpdateHistory;

const Container = styled.div`
  padding: 2rem 4rem;
`;

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafbff;
  padding: 1rem 2rem;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
`;
const ContentDiv = styled.div`
  margin: 1.5rem 2.5rem;
  padding: 0rem 3rem;
  border-left: 1px solid #c4c4c4;
  max-height: ${props => (props.active ? 'auto' : '0px')};
  transition: max-height 0.3s ease-in;
  overflow: hidden;
`;

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  width: 10rem;
`;

const NoDataDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: '10rem';
`;

const Inner = styled.div`
  padding-left: 0.5rem;
  padding-top: 1rem;
`;

const InnerUpdate = styled.div`
  border-left: 1px solid #c4c4c4;
  padding: 0rem 2rem;
  max-height: ${props => (props.active ? 'auto' : '0px')};
`;

const UpdateTitle = styled.div`
  display: flex;
  justify-content: ${props => props.justify};
  gap: ${props => props.gap};
  cursor: ${props => props.cursor};
  margin: ${props => props.margin};
  align-items: center;
`;

const UpdateDiv = styled.div``;
const UpdateContent = styled.div`
  border-left: 1px solid #c4c4c4;
  padding: 0rem 2rem;
  margin-left: 0.5rem;
  max-height: ${props => (props.active ? 'auto' : '0px')};
  transition: max-height 0.3s ease-in;
  overflow: hidden;
  margin-top: 0.7rem;
`;
