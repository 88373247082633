import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Select } from 'antd';
import { useQuery, useMutation } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getCourseSurveyList } from 'api/queries/Course';
import { dashboard } from 'userRoutes';
import PageLoader from 'components/common/PageLoader';
import { showToast } from 'store/actions/toast';
import { selectSurvey } from 'api/mutations/Course';
import { ButtonCTA } from 'components/common/ButtonCTA';
import { addSurveyId, courseStepNo } from 'store/actions/course';
import AppData from 'AppData';

const { Option } = Select;

const SelectSurveyForm = ({
  isReview,
  setCurrent,
  defaultCourseValue,
  setShowRejectionModal,
  courseApprovalHandler
}) => {
  const [loading, setLoading] = useState();
  const [surveyId, setSurveyId] = useState();
  const { courseId, isPaid } = useSelector(state => state.course);
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, isLoading } = useQuery(['surveyListings'], getCourseSurveyList);

  const [mutate] = useMutation(selectSurvey, {
    onMutate: () => setLoading(true),
    onSuccess: data => {
      setLoading(false);
      dispatch(addSurveyId({ surveyId }));
      if (isPaid) return setCurrent(state => state + 1);
      dispatch(showToast('success', 'Course submitted for review'));
      history.push(dashboard);
    },
    onError: error => {
      setLoading(false);
      console.log(error.response);
    }
  });

  const handleClick = () => {
    setCurrent(state => state - 1);
  };

  const onFinish = data => {
    if (isReview && isPaid) return dispatch(courseStepNo({ stepNo: 5 }));
    if (isReview && !isPaid) return courseApprovalHandler(AppData.courseApproval);
    const { surveyId } = data;
    setSurveyId(surveyId);
    mutate({ courseId, surveyId });
  };

  return (
    <Container>
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          className="margin-bottom-10"
          name="surveyId"
          initialValue={defaultCourseValue?.surveyId ? defaultCourseValue?.surveyId : ''}
          rules={[
            {
              required: true,
              message: 'Please select a Survey'
            }
          ]}>
          {data?.data && (
            <Select disabled={isReview}>
              {data?.data.map(survey => (
                <Option key={survey.surveyId} value={survey.surveyId}>
                  {survey.title}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <BtnDiv>
          <ButtonCTA
            color="#5D5D5D"
            border="1px solid #5D5D5D"
            bgColor="#fff"
            borderRadius="3px"
            width="100px"
            fontSize="16px"
            onClick={handleClick}
            height="35px">
            Back
          </ButtonCTA>
          <InnerBtnDiv>
            {isReview && (
              <ButtonCTA
                color="#5D5D5D"
                border="1px solid #5D5D5D"
                bgColor="#fff"
                borderRadius="3px"
                width="100px"
                fontSize="16px"
                onClick={() => setShowRejectionModal(true)}
                height="35px">
                Reject
              </ButtonCTA>
            )}
            <ButtonCTA
              color="#fff"
              bgColor="#0966B8"
              borderRadius="3px"
              width={isPaid || isReview ? '107px' : '190px'}
              htmlType="submit"
              fontSize="16px"
              height="35px">
              {isPaid ? 'Continue' : isReview ? 'Approve' : 'Submit for Review'}
            </ButtonCTA>
          </InnerBtnDiv>
        </BtnDiv>
      </Form>

      <PageLoader isLoading={isLoading || loading} fullHeight fullWidth />
    </Container>
  );
};

export default SelectSurveyForm;

const Container = styled.div``;

const BtnDiv = styled.div`
  padding-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InnerBtnDiv = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
