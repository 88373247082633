import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { changepassword as adminChangePassword, dashboard, login as adminLogin } from 'adminRoutes';
import { home as userLogin } from 'userRoutes';
import PropTypes from 'prop-types';
import { MainContext } from 'index';
import AppData from 'AppData';

const RouteGuard = props => {
  const mainContext = useContext(MainContext);
  const { userId, role, isFirstLogin, permission } = useSelector(state => state.auth);

  if (isFirstLogin) {
    if (role === AppData.ROLE.STUDENT) return props.children;
  } else if (userId === null) {
    return <Redirect to={mainContext.build === AppData.adminBuildType ? adminLogin : userLogin} />;
  }

  if (props.permissions?.length > 0 && mainContext.build === AppData.adminBuildType) {
    if (!permission.some(perm => props.permissions.includes(perm)))
      return <Redirect to={dashboard} />;
  }

  return props.children;
};

// prop types
RouteGuard.propTypes = {
  path: PropTypes.string.isRequired
};

export default RouteGuard;
