import * as cbtTypes from 'store/actions/cbt';

const initialState = {
  quiz: null
};

const cbtReducer = (state = initialState, action) => {
  switch (action.type) {
    case cbtTypes.CLEAR_CBT:
      return initialState;
    case cbtTypes.SET_CBT:
      return {
        ...state,
        quiz: action.data.quiz
      };
    default:
      return state;
  }
};

export default cbtReducer;
