import React, { useState, useEffect } from 'react';
import 'styles/utils.css';
import styled from 'styled-components';
import { Accordion, AccordionPanel, Box } from 'grommet';
import { ThemeContext } from 'styled-components';
import { Typography, Col, Row, Card, Button } from 'antd';
import MainLayout from 'components/MainLayout';
import IconWrapper from 'components/common/IconWrapper';
import ModuleIcon from 'assets/svgs/ic_module.svg';
import TimeIcon from 'assets/svgs/ic_time.svg';
import SchoolIcon from 'assets/svgs/ic_school.svg';
import CourseOverview from 'components/utils/CourseLanding/CourseOverview';
import Requirements from 'components/utils/CourseEnrollment/Requirements';
import ModulePlayList from 'components/utils/CourseDelivery/ModulePlayList';
import { useSelector } from 'react-redux';
import htmr from 'htmr';
import { useHistory } from 'react-router';
import { delivery, discovery } from 'userRoutes';
import { useQuery } from 'react-query';
import { courseEnrolledDetails } from 'api/queries/Course';
import ReactPlayer from 'react-player';
import DefaultPoster from 'assets/images/default-img.png';
import { useMediaQuery } from 'react-responsive';

const MyLearnings = props => {
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });

  const [courseDetails, setCourseDetails] = React.useState(null);
  const course = useSelector(state => state.course);
  const history = useHistory();
  const [courseId, setCourseId] = React.useState();
  const [currentModule, setCurrentModule] = React.useState(0);
  const [currentSection, setCurrentSection] = React.useState(0);
  const [userId, setUserId] = React.useState();
  const [setVideoSrc] = useState(null);
  const [activeIndex, setActiveIndex] = useState();
  const { data, isLoading, refetch } = useQuery(
    ['courseEnrolledDetails', userId, courseId],
    courseEnrolledDetails
  );
  const [poster, setPoster] = useState(DefaultPoster);

  useEffect(() => {
    setCourseDetails(course);
    setPoster(course.courseThumbNailUrl);
  }, [course]);

  React.useEffect(() => {
    if (props?.state) {
      setCourseId(props?.state?.courseId);
      setUserId(props?.state?.userId);
    }
  }, [props]);

  const ProceedToCourse = () => {
    //console.log('check');
    if (userId && courseId) history.push(delivery, { userId, courseId });
  };

  return (
    <Container>
      <Row gutter={[40, 24]}>
        <CustomColumn xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} xxl={{ span: 24 }}>
          <PlaylistContainer>
            <DetailWrapper
              direction={isMobileDevice ? 'column' : 'row'}
              align={isMobileDevice ? 'flex-start' : 'center'}>
              <HeaderSubTitle level={3}>Course curriculum</HeaderSubTitle>
              <MyLearningFooter>
                <AlignFlex pad="0px 10px 0px 0px" direction="row">
                  <IconWrapper
                    style={{ filter: 'sepia(100%) hue-rotate(190deg) saturate(500%)' }}
                    icon={ModuleIcon}
                    height="14px"
                    width="14px"
                  />
                  <CustomText
                    color="#0965B6"
                    size={isMobileDevice ? '9px' : '12px'}
                    marginleft="8px">
                    {courseDetails?.modules} Module{courseDetails?.modules > 1 ? 's' : ''}
                  </CustomText>
                </AlignFlex>
                <AlignFlex margin="0px 0px 0px 10px" pad="0px 10px 0px 0px" direction="row">
                  <IconWrapper
                    style={{ filter: 'sepia(100%) hue-rotate(190deg) saturate(500%)' }}
                    icon={TimeIcon}
                    height="14px"
                    width="14px"
                  />
                  <CustomText
                    color="#0965B6"
                    size={isMobileDevice ? '9px' : '12px'}
                    marginleft="8px">
                    {courseDetails?.duration}
                  </CustomText>
                </AlignFlex>
              </MyLearningFooter>
            </DetailWrapper>
            <Box>
              <ThemeContext.Extend
                value={{
                  accordion: {
                    heading: { level: '5', margin: 'small' },
                    icons: { color: '#000' }
                  }
                }}>
                <Box
                  width="full"
                  // direction="row"
                  // background={disabled ? 'light-5' : 'light-3'}
                  // style={{ cursor: disabled && 'not-allowed' }}
                >
                  <Accordion
                    activeIndex={activeIndex}
                    onActive={newActiveIndex => setActiveIndex(newActiveIndex)}
                    animate={false}
                    style={{
                      width: '100%'
                      // pointerEvents: disabled ? 'none' : 'unset'
                    }}
                    // onClick={() => setCurrentModule(mIndex)}
                  >
                    {course?.courseModules?.map((module, index) => {
                      return (
                        <ModulePlayList
                          mylearning
                          disabled={false}
                          key={index}
                          mIndex={index}
                          setCurrentModule={setCurrentModule}
                          setCurrentSection={setCurrentSection}
                          // data={data?.data}
                          moduleName={module.title}
                          item={module.items}
                          setVideoSrc={setVideoSrc}
                          currentModule={currentModule}
                          currentSection={currentSection}
                        />
                      );
                    })}
                  </Accordion>
                </Box>
              </ThemeContext.Extend>
            </Box>
          </PlaylistContainer>
        </CustomColumn>
      </Row>
    </Container>
  );
};

export default MyLearnings;

const PlaylistContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  background-color: #fff;
  color: #333333;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  ${'' /* box-shadow: 0px 2px 4px rgb(0 0 0 / 20%); */}
`;

const MyLearningFooter = styled.div`
  display: flex;
`;

const VideoContainer = styled.div`
  display: flex;
  ${'' /* padding: 28px; */}
  height: 400px;
`;
const ContainerFluid = styled.div`
  display: flex;
`;
const HeaderTitle = styled(Typography.Title)`
  color: #fff;
  word-spacing: 5px;
`;

const CustomText = styled(Typography.Text)`
  color: ${({ color }) => color};
  font-size: ${({ size }) => size};
  margin-left: ${({ marginleft }) => marginleft};
`;

const HeaderSubTitle = styled(Typography.Title)`
  margin: 0px;
`;

const Container = styled(Col)`
  ${'' /* padding: 28px; */}
  background-color: #fff;
`;

const CustomColumn = styled(Col)``;

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: space-between;
  align-items: ${({ align }) => align};
`;

const CustomButton = styled(Button)``;

const SpaceBetween = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  border-bottom: ${({ border }) => (border === 'bottom' ? 'solid 1px #cccccc' : 'none')};
  flex-direction: ${({ direction }) => direction};
  justify-content: space-between;
  padding-bottom: 10px;
  padding-left: ${({ padLeft }) => padLeft};
  flex-wrap: wrap;
`;
const SpaceContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  border-bottom: ${({ border }) => (border === 'bottom' ? 'solid 1px #cccccc' : 'none')};
  flex-direction: ${({ direction }) => direction};
  justify-content: space-between;
  padding-bottom: 10px;
  padding-left: ${({ padLeft }) => padLeft};
  flex-wrap: wrap;
`;

const AlignFlex = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  border-bottom: ${({ border }) => (border === 'bottom' ? 'solid 1px #cccccc' : 'none')};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  ${'' /* padding-bottom: 10px; */}
  padding: ${({ pad }) => pad};
  margin: ${({ margin }) => margin};
  flex-wrap: wrap;
  align-items: center;
`;
// import React, { useState, useEffect } from 'react';
// import 'styles/utils.css';
// import styled from 'styled-components';
// import { Accordion, AccordionPanel, Box } from 'grommet';
// import { ThemeContext } from 'styled-components';
// import { Typography, Col, Row, Card, Button } from 'antd';
// import MainLayout from 'components/MainLayout';
// import IconWrapper from 'components/common/IconWrapper';
// import ModuleIcon from 'assets/svgs/ic_module.svg';
// import TimeIcon from 'assets/svgs/ic_time.svg';
// import SchoolIcon from 'assets/svgs/ic_school.svg';
// import CourseOverview from 'components/utils/CourseLanding/CourseOverview';
// import Requirements from 'components/utils/CourseEnrollment/Requirements';
// import ModulePlayList from 'components/utils/CourseDelivery/ModulePlayList';
// import { useSelector } from 'react-redux';
// import htmr from 'htmr';
// import { useHistory } from 'react-router';
// import { delivery, discovery } from 'userRoutes';
// import { useQuery } from 'react-query';
// import { courseEnrolledDetails } from 'api/queries/Course';
// import ReactPlayer from 'react-player';
// import DefaultPoster from 'assets/images/default-img.png';

// const MyLearnings = () => {
//   const [courseDetails, setCourseDetails] = React.useState(null);
//   const course = useSelector(state => state.course);
//   const history = useHistory();
//   const [courseId, setCourseId] = React.useState();
//   const [currentModule, setCurrentModule] = React.useState(0);
//   const [currentSection, setCurrentSection] = React.useState(0);
//   const [userId, setUserId] = React.useState();
//   const [setVideoSrc] = useState(null);
//   const [activeIndex, setActiveIndex] = useState([0]);
//   const { data, isLoading, refetch } = useQuery(
//     ['courseEnrolledDetails', userId, courseId],
//     courseEnrolledDetails
//   );
//   const [poster, setPoster] = useState(DefaultPoster);

//   useEffect(() => {
//     setCourseDetails(course);
//     setPoster(course.courseThumbNailUrl);
//   }, [course]);

//   React.useEffect(() => {
//     if (history?.location?.state) {
//       setCourseId(history.location.state?.courseId);
//       setUserId(history.location.state?.userId);
//     } else {
//       history.push(discovery);
//     }
//   }, [history, history.location.state]);

//   const ProceedToCourse = () => {
//     //console.log('check');
//     if (userId && courseId) history.push(delivery, { userId, courseId });
//   };

//   return (
//     <MainLayout sidebar selectedKey="course">
//       <Container>
//         <Row gutter={[40, 24]}>
//           <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 16 }} xxl={{ span: 18 }}>
//             <div>
//               <HeaderTitle level={2}>{courseDetails?.title}</HeaderTitle>
//               <SpaceBetween direction="row">
//                 <CardFooterWrapper>
//                   <Typography.Text style={{ fontSize: '15px', color: '#6c6c6c' }}>
//                     {courseDetails?.description}
//                   </Typography.Text>
//                   <SpaceContainer>
//                     <ContainerFluid>
//                       <AlignFlex pad="0px 10px 0px 0px" direction="row">
//                         <IconWrapper icon={ModuleIcon} height="14px" width="14px" />
//                         <CustomText size="12px" marginleft="8px">
//                           {courseDetails?.modules} Module{courseDetails?.modules > 1 ? 's' : ''}
//                         </CustomText>
//                       </AlignFlex>
//                       <AlignFlex pad="0px 10px 0px 0px" direction="row">
//                         <IconWrapper icon={TimeIcon} height="14px" width="14px" />
//                         <CustomText size="12px" marginleft="8px">
//                           {courseDetails?.duration}
//                         </CustomText>
//                       </AlignFlex>
//                       <AlignFlex pad="0px 10px 0px 0px" direction="row">
//                         <IconWrapper icon={SchoolIcon} height="14px" width="14px" />
//                         <CustomText size="12px" marginleft="8px">
//                           {courseDetails?.courseLevelName} Level
//                         </CustomText>
//                       </AlignFlex>
//                     </ContainerFluid>
//                     <CustomButton onClick={ProceedToCourse} type="primary" shape="round">
//                       Continue Learning
//                     </CustomButton>
//                   </SpaceContainer>
//                 </CardFooterWrapper>
//               </SpaceBetween>
//             </div>
//             <VideoContainer>
//               {/* <CourseOverview videoUrl={courseDetails?.coursePromotionalVideoUrl} /> */}
//               {courseDetails && (
//                 <ReactPlayer
//                   url={courseDetails?.coursePromotionalVideoUrl}
//                   width="100%"
//                   height="100%"
//                   config={{ file: { attributes: { controlsList: 'nodownload' } } }}
//                   onContextMenu={e => e.preventDefault()}
//                   light={poster}
//                   controls
//                 />
//               )}
//             </VideoContainer>
//           </Col>
//           <CustomColumn xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xxl={{ span: 6 }}>
//             <div style={{ marginBottom: 20 }}>
//               {courseDetails && (
//                 <Requirements size="16px" items={htmr(courseDetails?.objectives)} />
//               )}
//             </div>
//             <PlaylistContainer>
//               <HeaderSubTitle level={3}>Course Content</HeaderSubTitle>
//               <Box>
//                 <ThemeContext.Extend
//                   value={{
//                     accordion: {
//                       heading: { level: '5', margin: 'small' },
//                       icons: { color: '#000' }
//                     }
//                   }}>
//                   <Box
//                     width="full"
//                     // direction="row"
//                     // background={disabled ? 'light-5' : 'light-3'}
//                     // style={{ cursor: disabled && 'not-allowed' }}
//                   >
//                     <Accordion
//                       activeIndex={activeIndex}
//                       onActive={newActiveIndex => setActiveIndex(newActiveIndex)}
//                       animate={false}
//                       style={{
//                         width: '100%'
//                         // pointerEvents: disabled ? 'none' : 'unset'
//                       }}
//                       // onClick={() => setCurrentModule(mIndex)}
//                     >
//                       {data?.data?.videos?.map((module, index) => {
//                         return (
//                           <ModulePlayList
//                             mylearning
//                             disabled={false}
//                             key={index}
//                             mIndex={index}
//                             setCurrentModule={setCurrentModule}
//                             setCurrentSection={setCurrentSection}
//                             data={data?.data}
//                             moduleName={module.title}
//                             item={module.items}
//                             setVideoSrc={setVideoSrc}
//                             currentModule={currentModule}
//                             currentSection={currentSection}
//                           />
//                         );
//                       })}
//                     </Accordion>
//                   </Box>
//                 </ThemeContext.Extend>
//               </Box>
//             </PlaylistContainer>
//           </CustomColumn>
//         </Row>
//       </Container>
//     </MainLayout>
//   );
// };

// export default MyLearnings;

// const PlaylistContainer = styled(Card)`
//   display: flex;
//   box-sizing: border-box;
//   max-width: 100%;
//   background-color: #fff;
//   color: #333333;
//   -webkit-flex-direction: column;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   border-radius: 6px;
//   overflow: hidden;
//   box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
// `;

// const CardFooterWrapper = styled.div`
//   min-width: 100%;
// `;

// const VideoContainer = styled.div`
//   display: flex;
//   ${'' /* padding: 28px; */}
//   height: 400px;
// `;
// const ContainerFluid = styled.div`
//   display: flex;
// `;
// const HeaderTitle = styled(Typography.Title)`
//   color: #fff;
//   word-spacing: 5px;
// `;
// const CustomText = styled(Typography.Text)`
//   color: ${({ color }) => color};
//   font-size: ${({ size }) => size};
//   margin-left: ${({ marginleft }) => marginleft};
// `;

// const HeaderSubTitle = styled(Typography.Title)``;

// const Container = styled(Col)`
//   padding: 28px;
//   background-color: #fff;
// `;

// const CustomColumn = styled(Col)``;

// const DetailWrapper = styled.div`
//   margin-top: 30px; ;
// `;

// const CustomButton = styled(Button)``;

// const SpaceBetween = styled.div`
//   display: flex;
//   box-sizing: border-box;
//   max-width: 100%;
//   border-bottom: ${({ border }) => (border === 'bottom' ? 'solid 1px #cccccc' : 'none')};
//   flex-direction: ${({ direction }) => direction};
//   justify-content: space-between;
//   padding-bottom: 10px;
//   padding-left: ${({ padLeft }) => padLeft};
//   flex-wrap: wrap;
// `;
// const SpaceContainer = styled.div`
//   display: flex;
//   box-sizing: border-box;
//   max-width: 100%;
//   border-bottom: ${({ border }) => (border === 'bottom' ? 'solid 1px #cccccc' : 'none')};
//   flex-direction: ${({ direction }) => direction};
//   justify-content: space-between;
//   padding-bottom: 10px;
//   padding-left: ${({ padLeft }) => padLeft};
//   flex-wrap: wrap;
// `;

// const AlignFlex = styled.div`
//   display: flex;
//   box-sizing: border-box;
//   max-width: 100%;
//   border-bottom: ${({ border }) => (border === 'bottom' ? 'solid 1px #cccccc' : 'none')};
//   flex-direction: ${({ direction }) => direction};
//   justify-content: ${({ justify }) => justify};
//   ${'' /* padding-bottom: 10px; */}
//   padding: ${({ pad }) => pad};
//   flex-wrap: wrap;
//   align-items: center;
// `;
