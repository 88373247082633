import React from 'react';
import { Box } from 'grommet';

const InputValidationLoader = ({
  message,
  right = '-19px',
  bottom = message ? '37px' : '16px'
}) => {
  return (
    <Box
      width="15px"
      height="15px"
      style={{
        position: 'absolute',
        right: right,
        bottom: bottom
      }}>
      <Box
        direction="row"
        border={[
          { side: 'all', color: 'transparent', size: 'xsmall' },
          { side: 'horizontal', color: 'brand', size: 'xsmall' }
        ]}
        pad="xsmall"
        round="full"
        animation={{ type: 'rotateRight', duration: 1500 }}
      />
    </Box>
  );
};
export default InputValidationLoader;
