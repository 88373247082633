import React, { useState, useEffect } from 'react';
import CBTWrapper from 'components/utils/CBT/CBTWrapper';
import styled from 'styled-components';
import { Alert, Checkbox, Radio, Typography } from 'antd';
import CustomButton from 'components/common/CustomButton';
import { useSelector } from 'react-redux';
import htmr from 'htmr';
import { countdownTimer } from 'utils';
import { updateScore } from 'api/mutations/Course';

const CBTMain = ({
  quizData,
  score,
  setScore,
  setIsComplete,
  setUserChoices,
  setUserPassedQuiz,
  moduleIndex,
  sectionIndex,
  refetch
}) => {
  const { Text } = Typography;
  const {
    quiz: { duration, pointPerQuestion, timed, courseQuizId }
  } = useSelector(state => state.cbt);
  const { userId } = useSelector(state => state.auth);
  const { courseId } = useSelector(state => state.course);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [displayTimer, setDisplayTimer] = useState('');
  const [loading, setLoading] = useState(false);

  const Paragraph = styled.p`
    margin-bottom: 0;
  `;

  const transform = {
    p: Paragraph
  };

  const handleRadioOptionChange = e => setSelectedOption(e.target.value);

  const handleCheckboxOptionChange = values => setSelectedOption(values);

  const checkAnswer = () => {
    if (Number(quizData[questionIndex].type) === 0) {
      const isAnswer = quizData[questionIndex].options[selectedOption].isAnswer;
      setUserChoices(state => state.concat(selectedOption));
      setIsCorrectAnswer(isAnswer);
      setScore(isAnswer ? score + pointPerQuestion : score);
      setShowAnswer(true);
      return;
    }

    const filteredOptions = quizData[questionIndex].options.filter(option => option.isAnswer);
    let allIsAnswer = filteredOptions.every(option => selectedOption.includes(option.title));

    if (filteredOptions.length !== selectedOption.length) allIsAnswer = false;

    setUserChoices(state => [...state, selectedOption]);
    setIsCorrectAnswer(allIsAnswer);
    setScore(allIsAnswer ? score + pointPerQuestion : score);
    setShowAnswer(true);
  };

  const checkIfUserPassed = async () => {
    try {
      setLoading(true);
      const data = await updateScore(
        userId,
        courseQuizId,
        courseId,
        moduleIndex,
        sectionIndex,
        score
      );
      setUserPassedQuiz(data.data.isCompleted);
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleProceed = async () => {
    if (questionIndex === quizData.length - 1) {
      await checkIfUserPassed();
      setIsComplete(true);
    } else {
      setSelectedOption(null);
      setIsCorrectAnswer(false);
      setShowAnswer(false);
      setQuestionIndex(questionIndex + 1);
    }
  };

  useEffect(() => {
    if (timed) {
      countdownTimer(duration, setDisplayTimer);
    }

    return () => {
      if (window.clearQuizTImer) window.clearQuizTImer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!timed) return;

    if (displayTimer === '00:00') {
      setUserChoices(state => {
        const newState = [...state];
        for (let i = state.length; i < quizData.length; i++) {
          newState[i] = quizData[i].type === 0 ? null : [null];
        }
        return newState;
      });
      setIsComplete(true);
    }
  }, [displayTimer, quizData, setIsComplete, setUserChoices, timed]);

  return (
    <CBTWrapper>
      {quizData.length > 0 && (
        <Container>
          {showAnswer && (
            <Alert
              style={{ marginBottom: 20 }}
              message={`${isCorrectAnswer ? 'Correct' : 'Wrong'} Answer`}
              description={
                quizData[questionIndex].type === 0
                  ? quizData[questionIndex].options[selectedOption].description
                  : htmr(
                      quizData[questionIndex].options
                        .filter(option => option.isAnswer && option.description !== '')
                        .map(item => item.description)
                        .join('<br />')
                    )
              }
              type={isCorrectAnswer ? 'success' : 'error'}
              showIcon
            />
          )}
          <CBTLayoutArea>
            <Text>Score: {score}</Text>
            <Text>
              {questionIndex + 1} of {quizData.length} Question{quizData.length > 1 ? 's' : ''}
            </Text>
            {timed && <Text>{displayTimer}</Text>}
          </CBTLayoutArea>
          <CBTQuestionArea>{htmr(quizData[questionIndex].question, { transform })}</CBTQuestionArea>
          {Number(quizData[questionIndex].type) === 0 && (
            <CBTOptionRadio
              onChange={handleRadioOptionChange}
              value={selectedOption}
              disabled={showAnswer}>
              {quizData[questionIndex].options.map((option, index) => (
                <Radio key={index} value={index}>
                  {option.title}
                </Radio>
              ))}
            </CBTOptionRadio>
          )}
          {Number(quizData[questionIndex].type) === 1 && (
            <CBTOptionCheckbox
              options={quizData[questionIndex].options.map(option => ({
                label: option.title,
                value: option.title
              }))}
              onChange={handleCheckboxOptionChange}
              value={selectedOption}
              disabled={showAnswer}
            />
          )}
          <CBTLayoutArea>
            {/* <CustomButton radius="40px" bgColor="#0966b8" color="#fff" size="large">
            Previous Question
          </CustomButton> */}
            <CustomButton
              radius="40px"
              bgColor="#0966b8"
              color="#fff"
              size="large"
              disabled={selectedOption === null}
              loading={loading}
              onClick={showAnswer ? handleProceed : checkAnswer}>
              {showAnswer ? 'Proceed' : 'Check Answer'}
            </CustomButton>
          </CBTLayoutArea>
        </Container>
      )}
    </CBTWrapper>
  );
};

const Container = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
`;

const CBTLayoutArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CBTQuestionArea = styled.div`
  width: 100%;
  background-color: #eff2f5;
  padding: 20px;
  margin: 20px 0px;
`;

const CBTOptionRadio = styled(Radio.Group)`
  width: 100%;
  padding: 20px;
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CBTOptionCheckbox = styled(Checkbox.Group)`
  width: 100%;
  padding: 20px;
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default CBTMain;
