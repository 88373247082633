import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Table, Typography, Button } from 'antd';
import { getRevenuePartners } from 'api/queries/Course';
import { useQuery } from 'react-query';
import { search } from 'api/mutations/Institution';

const RevenueFormularTable = ({ handleClick, showFormular, revenueId, revenueName }) => {
  const { data: partners } = useQuery(['institutions'], search);
  const { isLoading, data: revenuePartners } = useQuery(
    ['getRevenuePartners', revenueId],
    getRevenuePartners
  );

  const columns = [
    {
      title: 'PARTY NAME',
      dataIndex: 'partnerName',
      key: 'partnerName'
    },
    {
      title: 'PARTY TYPE',
      dataIndex: 'revenuePartyTypeName',
      key: 'revenuePartyTypeName'
    },
    {
      title: 'PERCENTAGE VALUE',
      dataIndex: 'percentage',
      key: 'percentage'
    }
  ];

  const tableData = revenuePartners?.data.map(rowData => ({
    ...rowData,
    key: rowData.id,
    partnerName:
      rowData.partnerId === 0
        ? 'Rivet'
        : partners?.find(partner => partner.id === rowData.partnerId)?.name,
    revenuePartyTypeName: rowData.revenuePatnerType === 0 ? 'Platform Owner' : 'Partner'
  }));

  return (
    <>
      <Name>
        Name : <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{revenueName}</span>
      </Name>
      <Table dataSource={tableData} columns={columns} />
      <PercentageText>
        Total Percentage: <Percentage>100</Percentage>
      </PercentageText>
      <ButtonDiv>
        <CloseBtn
          onClick={() => {
            handleClick();
            showFormular(false);
          }}>
          Close
        </CloseBtn>
      </ButtonDiv>
    </>
  );
};

export default RevenueFormularTable;

const Name = styled(Typography.Paragraph)`
  text-align: center;
  font-size: 1.2em;
`;

const PercentageText = styled(Typography.Paragraph)`
  font-size: 1.1em;
  float: right;
  color: #7f7f7f;
`;

const Percentage = styled(Typography.Text)`
  font-size: 1.6em;
  color: #7f7f7f;
  font-weight: bold;
`;

const ButtonDiv = styled.div`
  margin: auto;
  margin-top: 5em;
  width: 50%;
  text-align: center;
`;

const CloseBtn = styled(Button)`
  border: none;
  background: #4d4f5c;
  color: #fff;
  width: 9em;
`;
