import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import CustomButton from 'components/common/CustomButton';
import CBTWrapper from 'components/utils/CBT/CBTWrapper';
import { IoIosClose } from 'react-icons/io';
import { BiCheck } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import htmr from 'htmr';
import { clearCBT } from 'store/actions/cbt';

const CBTResult = ({
  quizData,
  userChoices,
  resultInfo,
  handleEnded,
  handleRetry,
  userPassedQuiz
}) => {
  const { Title, Text, Paragraph } = Typography;
  const dispatch = useDispatch();

  return (
    <CBTWrapper>
      <Container>
        <ResultHeader>
          <Title level={2} style={{ color: '#fff' }}>
            Result
          </Title>
          <ResultHeaderContainer>
            <Text style={{ color: '#fff' }}>
              Review the course materials to expand your learning
            </Text>
            <Text style={{ color: '#fff' }}>{resultInfo}</Text>
          </ResultHeaderContainer>
        </ResultHeader>
        <ResultItemContainer>
          {quizData.map((item, index) => {
            if (Number(quizData[index].type) === 0) {
              return (
                <ResultItem key={index}>
                  {userChoices[index] !== null &&
                  item.options.find((_, optionIndex) => optionIndex === userChoices[index])
                    ?.isAnswer ? (
                    <BiCheck size="2em" color="green" style={{ marginRight: 10 }} />
                  ) : (
                    <IoIosClose size="2em" color="red" style={{ marginRight: 10 }} />
                  )}
                  <div>
                    <Paragraph style={{ fontWeight: 'bold', marginBottom: 5 }}>
                      {htmr(item.question)}
                    </Paragraph>
                    <Text>
                      {userChoices[index] !== null
                        ? item.options.find((_, optionIndex) => optionIndex === userChoices[index])
                            ?.title
                        : 'Question was not answered'}
                    </Text>
                  </div>
                </ResultItem>
              );
            }

            return (
              <ResultItem key={index}>
                {userChoices[index] !== null &&
                item.options
                  .filter(option => option.isAnswer)
                  .every(option => userChoices[index].includes(option.title)) ? (
                  <BiCheck size="2em" color="green" style={{ marginRight: 10 }} />
                ) : (
                  <IoIosClose size="2em" color="red" style={{ marginRight: 10 }} />
                )}
                <div>
                  <Paragraph style={{ fontWeight: 'bold', marginBottom: 5 }}>
                    {htmr(item.question)}
                  </Paragraph>
                  <ul>
                    {userChoices[index].map((choice, index) => (
                      <li key={index}>
                        {choice !== null
                          ? item.options.find(option => option.title === choice).title
                          : 'Question was not answered'}
                      </li>
                    ))}
                  </ul>
                </div>
              </ResultItem>
            );
          })}
        </ResultItemContainer>
        <ButtonGroup>
          <CustomButton
            radius="40px"
            bgColor="transparent"
            color="#0966b8"
            size="large"
            onClick={handleRetry}>
            Retry Quiz
          </CustomButton>
          {userPassedQuiz && (
            <CustomButton
              onClick={() => {
                handleEnded();
                dispatch(clearCBT());
              }}
              radius="40px"
              bgColor="#0966b8"
              color="#fff"
              size="large">
              Continue
            </CustomButton>
          )}
        </ButtonGroup>
      </Container>
    </CBTWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const ResultHeader = styled.div`
  width: 100%;
  background-color: #307ec3;
  padding: 20px;
`;

const ResultHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ResultItemContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 50px;
  flex: 1;
  overflow-y: auto;
`;

const ResultItem = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 80px;
  padding-bottom: 20px;
  padding-right: 50px;
  width: 100%;
`;

export default CBTResult;
