import React from 'react';
import { Box, Button, ResponsiveContext, TextInput } from 'grommet';
import { isMobile } from 'utils';
import { Search } from 'grommet-icons';
import styled from 'styled-components';

const CustomSearchInput = ({ isScrolled, onClick }) => {
  const size = React.useContext(ResponsiveContext);
  return (
    <Box direction="row" align="center" margin={{ vertical: 'xsmall' }}>
      {isScrolled && (
        <Box
          // width="small"
          border={{
            color: '#fff',
            size: '1px',
            style: 'solid',
            side: 'all'
          }}
          height="40px"
          direction="row"
          align="center"
          pad={{ right: 'xsmall' }}
          round="large"
          style={{
            width: isMobile(size) ? '100%' : 350,
            background: 'transparent',
            color: '#fff'
          }}>
          <CustomInput plain placeholder="What would you like to learn ?" />
          <Search color="#fff" />
        </Box>
      )}
      {/* <Box>
        <Button
          margin={{ left: 'medium' }}
          label="Register Now"
          primary
          onClick={onClick}
          size="medium"
          style={{ borderRadius: '20px' }}
        />
      </Box> */}
    </Box>
  );
};

const CustomInput = styled(TextInput)`
  font-size: 16px;
  &::placeholder {
    color: #fff !important;
  }
`;

export default CustomSearchInput;
