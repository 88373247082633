import { refreshToken } from 'api/mutations/Authentication';
import Axios from 'axios';
import { login as adminLogin } from 'adminRoutes';
import { home as userLogin } from 'userRoutes';
import { refreshTokenAuth, signOut } from 'store/actions/auth';
import store from 'store/store';
import buildJson from 'configs/json/build.json';
import AppData from 'AppData';
import { showToast } from 'store/actions/toast';

export default function () {
  // set axios base url
  Axios.defaults.baseURL = window['configs'].baseURL;
  // Request interceptor for API calls
  Axios.interceptors.request.use(
    config => {
      const token = store.getState().auth.accessToken;

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    },
    error => {
      Promise.reject(error);
    }
  );
  // Response interceptor for API calls
  Axios.interceptors.response.use(
    response => response,
    async error => {
      if (error.response) {
        const originalRequest = error.config;
        const { status } = error.response;

        if (status === 401 && originalRequest.url.includes('RefreshToken')) {
          if (!store.getState().auth.sessionTimedOut) {
            store.dispatch(showToast('error', 'Session timed out'));
            localStorage.setItem('_u', store.getState().auth.userId);
            store.dispatch(signOut()); // handle sign out dispatch
            // const _loc = sessionStorage.getItem('_loc');
            // sessionStorage.clear(); // clear session storage
            // sessionStorage.setItem('_loc', _loc);
            window.location.replace(
              buildJson.build === AppData.adminBuildType ? adminLogin : userLogin
            );
          }
          return Promise.reject(error);
        } else if (status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const data = await refreshToken(store.getState().auth.refreshToken);
            store.dispatch(refreshTokenAuth(data));
            return Axios(originalRequest);
          } catch (err) {
            return Promise.reject(err);
          }
        }
      }
    }
  );
}
