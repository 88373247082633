import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';
import { ImageWrapper } from 'components/common/ImageWrapper';
import { ButtonCTA } from 'components/common/ButtonCTA';
import Collaborate from 'assets/images/landing page Assets/Home landing page/collaboration.png';
import { useMediaQuery } from 'react-responsive';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const CollaborationSection = props => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  return (
    <CollaborationSectionWrapper>
      <Col md={10} offset={!isMobile && 1}>
        <DivWrapper>
          <Col>
            <Title>Easy Collaboration</Title>
            <Paragraph>
              Team up with other learners on the platform to work on skill assessments, hands-on
              projects and other learning activities.
            </Paragraph>
            {/* <ButtonCTA type="primary" size="large" color="#ff7600">
              Read More
            </ButtonCTA> */}
          </Col>
        </DivWrapper>
      </Col>
      <Col md={10} offset={!isMobile && 2}>
        <ImageWrapper width="100%" height="100%" src={Collaborate} preview={false} />
      </Col>
    </CollaborationSectionWrapper>
  );
};

CollaborationSection.propTypes = {};

export default CollaborationSection;

const CollaborationSectionWrapper = styled(Row)`
  height: 500px;
  padding: 50px;
  @media ${mobile} {
    padding: 0 1.3rem;
  }
`;

const Title = styled(Typography.Title)`
  margin-top: 0 !important;
  position: relative;
  margin-bottom: 1.8rem !important;
  &::before {
    content: '';
    top: 110%;
    left: 0%;
    height: 3px;
    width: 15%;
    color: #0966b8;
    background: #0966b8;
    position: absolute;
  }
  @media ${mobile} {
    font-size: 1.5em !important;
  }
`;

const Paragraph = styled(Typography.Paragraph)`
  margin-bottom: 3rem !important;
  @media ${mobile} {
    font-size: 0.9em !important;
  }
`;

const DivWrapper = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
`;
