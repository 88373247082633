import React from 'react';
import { Box, Text } from 'grommet';

const VideoTabTitle = ({ icon, label }) => (
  <Box
    pad={{ horizontal: 'large' }}
    direction="row"
    align="center"
    gap="xsmall"
    margin={{ horizontal: 'large', vertical: 'small' }}>
    {icon}
    <Text size="medium">
      <strong style={{ color: '#000' }}>{label}</strong>
    </Text>
  </Box>
);

export default VideoTabTitle;
