import React, { useEffect, useState } from 'react';
import { Box, Button, ResponsiveContext, Heading, Layer, Text, CheckBox, Grid } from 'grommet';
import { isMobile } from 'utils';
import IconWrapper from 'components/common/IconWrapper';
import CloseIcon from 'assets/svgs/ic_close.svg';
import ProfileIcon from 'components/common/ProfileIcon';
import { useMutation, useQuery } from 'react-query';
import { toggleInstitution } from 'api/mutations/Institution';
import { useHistory } from 'react-router-dom';
import { getCountries, getInstitution } from 'api/queries/Institution';
import { updateinstitution } from 'adminRoutes';
import InputValidationLoader from 'components/common/InputValidationLoader';
import { showToast } from 'store/actions/toast';
import { useDispatch } from 'react-redux';
import { getAdminUserByInstitutionId } from 'api/queries/Authentication';
import ManageAdminCard from 'components/common/ManageAdminCard';
import ManageAdminImages from 'components/common/ManageAdminImages';

const ManageInstitutionModal = ({ onClose, setInstitutionUpdate, data }) => {
  const { data: countries } = useQuery(['countries'], getCountries);
  const size = React.useContext(ResponsiveContext);
  const [status, setStatus] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState(null);
  const [, setToggleError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formFieldStyle = {
    flexDirection: isMobile(size) ? 'column' : 'row',
    alignItems: isMobile(size) ? 'start' : 'center',
    justifyContent: isMobile(size) ? 'space-between' : 'space-between'
  };

  useEffect(() => {
    if (data.isActive === true) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }, [data]);

  useEffect(() => {
    if (successMessage) {
      dispatch(
        showToast('success', data.isActive === true ? 'Record deactivated' : ' Record Activated')
      );
      setSuccessMessage(null);
    }
  }, [data.isActive, dispatch, successMessage]);

  // if (data.success) {
  //   dispatch(showToast('success', 'User created successfully'));
  //   props.onClose();
  // } else {
  //   dispatch(showToast('error', data.description));
  // }
  const [toggleMutate] = useMutation(toggleInstitution, {
    onSuccess: data => {
      const { description, success } = data;
      if (success) {
        setIsSubmitting(false);
        setInstitutionUpdate(true);
        setSuccessMessage(description);
        onClose();
      } else {
        dispatch(showToast('error', data.description));
        setToggleError(description);
        setIsSubmitting(false);
        console.log(description);
      }
    },
    onError: error => {
      // setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setToggleError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        setToggleError(error.message);
        console.log(error.message);
      }
    }
  });

  const toggleStatus = async () => {
    setIsSubmitting(false);
    try {
      setIsSubmitting(true);
      setToggleError(null);
      await toggleMutate({
        Id: data.id,
        isActive: data.isActive === true ? false : true
      });
    } catch (e) {
      console.log(e);
    }
  };

  const { data: institutionResult } = useQuery(['institutionResult', data.id], getInstitution);
  const { data: adminUserResult } = useQuery(
    ['adminUserResult', data.id],
    getAdminUserByInstitutionId
  );
  // console.log(1,institutionResult && institutionResult[0]);
  // const [institutionResultData, setInstitutionResultData] = useState();

  // console.log(34, adminUserResult?.data);
  return (
    <Layer responsive={false} onClickOutside={onClose} onEsc={onClose}>
      <Box
        border="bottom"
        direction={isMobile(size) ? 'column' : 'row'}
        pad="small"
        align="center"
        gap={isMobile(size) ? 'medium' : 'small'}>
        <Box width="xsmall" margin={{ left: 'small' }}>
          <ProfileIcon img={data?.logoUrl} size="large" />
        </Box>
        <Box direction="column" width="full" margin={{ left: isMobile(size) ? 'small' : 'none' }}>
          <Box
            direction="row"
            justify="between"
            gap="medium"
            align="center"
            margin={{ top: 'small', right: 'small' }}>
            <Box width={isMobile(size) ? '100%' : '400px'}>
              <Heading
                margin={{ bottom: 'xsmall', top: 'none' }}
                size={isMobile(size) ? 'xsmall' : null}
                level={isMobile(size) ? 5 : 4}
                color="#777">
                {data.name}
              </Heading>
            </Box>
            <Box
              flex="grow"
              align={isMobile(size) ? 'start' : 'end'}
              margin={{ bottom: isMobile(size) ? 'small' : 'none' }}
              justify="start">
              <Box
                flex="shrink"
                direction={isMobile(size) ? 'row' : 'column'}
                gap={isMobile(size) ? 'xsmall' : 'none'}>
                <Button
                  style={{ padding: '0px' }}
                  icon={<IconWrapper icon={CloseIcon} width="26px" height="26px" />}
                  onClick={onClose}
                />
              </Box>
            </Box>
          </Box>
          <Box
            direction={isMobile(size) ? 'column' : 'row'}
            justify={isMobile(size) ? 'center' : 'between'}
            align={isMobile(size) ? 'start' : 'center'}
            margin={{ top: 'xsmall', bottom: 'small' }}
            gap="medium">
            <Box>
              <Box flex="shrink">
                <Button
                  disabled={data.isActive === false}
                  margin={{ right: 'small' }}
                  label="Update Details"
                  onClick={() => {
                    if (institutionResult) {
                      history.push(
                        `${updateinstitution.replace('/:updateinstitution', '')}/${data.id}`,
                        institutionResult[0]
                      );
                    }
                  }}
                  primary
                  size={isMobile(size) ? 'xsmall' : 'small'}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        align="center"
        margin={{ bottom: 'medium' }}
        style={{ height: 300, overflowY: 'scroll' }}>
        <Box width="full" margin={{ top: 'medium' }} gap="small" style={{ maxWidth: 375 }}>
          <div>
            <Box style={formFieldStyle}>
              <Text>Partner</Text>
              <Text
                size="small"
                style={{ fontWeight: '600', width: isMobile(size) ? '100%' : 200 }}
                alignSelf={isMobile(size) ? 'start' : 'end'}>
                {data.name}
              </Text>
            </Box>
          </div>
          <div>
            <Box style={formFieldStyle}>
              <Text>Email</Text>
              <Text
                size="small"
                style={{ fontWeight: '600', width: isMobile(size) ? '100%' : 200 }}
                alignSelf={isMobile(size) ? 'start' : 'end'}>
                {data.email}
              </Text>
            </Box>
          </div>
          <div>
            <Box style={formFieldStyle}>
              <Text>Phone</Text>
              <Text
                size="small"
                style={{ fontWeight: '600', width: isMobile(size) ? '100%' : 200 }}
                alignSelf={isMobile(size) ? 'start' : 'end'}>
                {data.phoneNumber}
              </Text>
            </Box>
          </div>
          <div>
            <Box style={formFieldStyle}>
              <Text>Country</Text>
              <Text
                size="small"
                style={{ fontWeight: '600', width: isMobile(size) ? '100%' : 200 }}
                alignSelf={isMobile(size) ? 'start' : 'end'}>
                {!countries
                  ? data.country
                  : countries?.find(d => d.code1 === data.country).countryName}
              </Text>
            </Box>
          </div>
          <div>
            <Box style={formFieldStyle}>
              <Text>Website</Text>
              <Text
                truncate
                size="small"
                //  style={{ width: isMobile(size) ? '100%' : 200 }}
                style={{ fontWeight: '600', width: isMobile(size) ? '100%' : 200 }}
                alignSelf={isMobile(size) ? 'start' : 'end'}>
                {data.website}
              </Text>
            </Box>
          </div>
          <div>
            <Box style={formFieldStyle}>
              <Text>Address</Text>
              <Text
                size="small"
                style={{ fontWeight: '600', width: isMobile(size) ? '100%' : 200 }}
                alignSelf={isMobile(size) ? 'start' : 'start'}>
                {data.address}
              </Text>
            </Box>
          </div>
          <div>
            <Box style={formFieldStyle}>
              <Text>Status</Text>
              <Box
                style={{
                  position: 'relative',
                  fontWeight: '600',
                  width: isMobile(size) ? '100%' : 200
                }}>
                {isSubmitting ? (
                  <>
                    <InputValidationLoader right="182px" bottom="3px" />{' '}
                    <Box margin={{ left: '34px' }}>
                      {' '}
                      <Text style={{ fontSize: '16px' }}>
                        {data.isActive === true ? 'deactivating record..' : 'Activating record..'}
                      </Text>
                    </Box>{' '}
                  </>
                ) : (
                  <CheckBox
                    toggle
                    checked={status}
                    onChange={() => setStatus(!status)}
                    onClick={toggleStatus}
                  />
                )}
              </Box>
            </Box>
          </div>

          <div style={{ marginTop: '24px' }}>
            <ManageAdminImages title={'Partner Thumbnail'} media={data.thumbnailUrl} />
            <ManageAdminImages title={'Partner BannerUrl'} media={data.bannerUrl} />
            <ManageAdminImages title={'Partner Logo'} media={data.logoUrl} />
            <ManageAdminImages title={'Certificate Logo'} media={data.certificateLogoUrl} />
          </div>

          <div style={{ marginTop: '24px', marginBottom: '10px' }}>
            <Heading
              style={{ fontSize: '20px', borderBottom: '1px solid #ccc' }}
              margin={{ bottom: 'small', top: 'none' }}
              level={4}
              color="#777">
              Admin Details
            </Heading>
            <Grid columns={!isMobile(size) ? 'medium' : '100%'} gap="medium">
              {adminUserResult?.data?.map((data, i) => (
                <ManageAdminCard
                  userName={data.userName}
                  key={i}
                  isActive={data.isActive}
                  adminName={data.firstName}
                  adminEmail={data.email}
                  adminUsername={data.lastName}
                />
              ))}
              {adminUserResult?.data?.length === 0 && <Text size="xsmall">No admin details</Text>}
            </Grid>
          </div>
        </Box>
      </Box>
      {/* <PageLoader
        isLoading={isSubmitting}
        tip={data.isActive === true ? 'deactivating record..' : 'Activating record..'}
      /> */}
      {/* {successMessage && (
        <NotificationModal
          // title="Password Reset"
          message={
            <>{data.isActive === true ? 'deactivate successfully' : 'Activate successfully'}</>
          }
          status="success"
          iconHeight="80px"
          iconWidth="80px"
          actionText="Complete"
          actionFunc={() => {
            onClose();
            setSuccessMessage(null);
          }}
        />
      )} */}
    </Layer>
  );
};

export default ManageInstitutionModal;
