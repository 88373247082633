import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import ThirdPartyCourseCard from './ThirdPartyCourseCard';
import AppText from 'components/common/AppText';
import closeIcon from 'assets/svgs/typcn_delete.svg';
import { BeneficiaryContext } from 'context/BeneficiaryContext';

const BeneficiarySelect = ({ course, index, user, refetch }) => {
  const { userName, userId, firstName, lastName } = user;
  const { courseId, price, quantity, id: pendingUserCourseId } = course;

  const [beneficiary, setBeneficiary] = useState([
    {
      id: uuidv4(),
      firstName,
      lastName,
      courseId,
      pendingUserCourseId,
      userId,
      email: userName,
      courseAmount: price
    }
  ]);

  const { setBeneficiaryData, beneficiaryData } = useContext(BeneficiaryContext);

  useEffect(() => {
    if (quantity > 1) {
      let beneficiaryArray = [];
      for (let i = 0; i < quantity - 1; i++) {
        beneficiaryArray.push({
          id: uuidv4(),
          firstName: '',
          lastName: '',
          courseId,
          pendingUserCourseId,
          email: '',
          courseAmount: price
        });
      }
      setBeneficiary(prev => [...prev, ...beneficiaryArray]);
      setBeneficiaryData(prev => [...prev, ...beneficiaryArray]);
    }
    setBeneficiaryData(prev => [...beneficiary, ...prev]);
  }, []);

  // console.log(1,beneficiary,beneficiaryData)

  const handleChange = (e, id) => {
    const { value, name } = e.target;
    setBeneficiary(prev =>
      prev.map(beneficiary => {
        if (beneficiary.id === id)
          return {
            ...beneficiary,
            [name]: value
          };
        return beneficiary;
      })
    );
    setBeneficiaryData(prev =>
      prev.map(beneficiary => {
        if (beneficiary.id === id)
          return {
            ...beneficiary,
            [name]: value
          };
        return beneficiary;
      })
    );
  };

  const handleDelete = id => {
    setBeneficiary(prev => prev.filter(el => el.id !== id));
    setBeneficiaryData(prev => prev.filter(el => el.id !== id));
  };

  return (
    <div>
      <ThirdPartyCourseCard
        refetch={refetch}
        background={index === 0 ? '#f1faff' : '#fff'}
        course={course}
        beneficiary={beneficiary}
        setBeneficiary={setBeneficiary}
        bordered={index !== 0}
        user={user}
      />
      {beneficiary.length > 1 && (
        <div>
          <AppText
            size={'14px'}
            color="#546069"
            top={'1rem'}
            bottom={'1rem'}
            align={'center'}
            value={
              <>
                <strong style={{ color: '#000' }}>Disclaimer </strong>: Kindly confirm the accuracy
                of the course beneficiaries' email addresses before making payments as this cannot
                be reversed upon successful payment.
              </>
            }
            weight={700}
          />

          <RecepientDiv>
            {beneficiary
              .filter(user => user.email !== userName)
              .map(el => (
                <Recepient key={el.id}>
                  <InputDiv>
                    <AppText
                      value={
                        <strong>
                          Recipient First Name <span style={{ color: '#FF6868' }}>*</span>
                        </strong>
                      }
                      bottom="0"
                    />
                    <Input
                      placeholder="firstName"
                      name="firstName"
                      style={{ borderRadius: '8px', padding: '17px 21px' }}
                      onChange={e => handleChange(e, el.id)}
                    />
                  </InputDiv>
                  <InputDiv>
                    <AppText
                      value={
                        <strong>
                          Recipient Last Name <span style={{ color: '#FF6868' }}>*</span>
                        </strong>
                      }
                      bottom="0"
                    />
                    <Input
                      placeholder="lastName"
                      name="lastName"
                      style={{ borderRadius: '8px', padding: '17px 21px' }}
                      onChange={e => handleChange(e, el.id)}
                    />
                  </InputDiv>
                  <InputDiv>
                    <AppText
                      value={
                        <strong>
                          Email Address <span style={{ color: '#FF6868' }}>*</span>
                        </strong>
                      }
                      bottom="0"
                    />
                    <Input
                      name="email"
                      placeholder="Enter your email address"
                      style={{ borderRadius: '8px', padding: '17px 21px' }}
                      onChange={e => handleChange(e, el.id)}
                    />
                  </InputDiv>

                  <img
                    src={closeIcon}
                    alt=""
                    style={{ marginTop: '26px', cursor: 'pointer' }}
                    onClick={() => handleDelete(el.id)}
                  />
                </Recepient>
              ))}
          </RecepientDiv>
        </div>
      )}
    </div>
  );
};

export default BeneficiarySelect;

const RecepientDiv = styled.div`
  border-top: 0.5px solid rgba(4, 41, 74, 0.3);
  padding: 2rem;
`;
const Recepient = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  align-items: center;
  padding-bottom: 2rem;
`;
const InputDiv = styled.div`
  width: 100%;
`;
