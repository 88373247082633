import React from 'react';
import IconWrapper from 'components/common/IconWrapper';
import { Box, Heading } from 'grommet';
import StarIcon from 'assets/svgs/ic_star.svg';
import { Star } from 'grommet-icons';

const CourseLanRating = ({ reviews, size = 'medium', left = '2px' }) => {
  // const [ratings] = React.useState([1, 2, 3, 4, 5]);

  return (
    <Box margin={{ left: left }} direction="row">
      <Star size={size} color={reviews > 0 ? 'accent-4' : '#fff'} />
      <Star size={size} color={reviews > 1 ? 'accent-4' : '#fff'} />
      <Star size={size} color={reviews > 2 ? 'accent-4' : '#fff'} />
      <Star size={size} color={reviews > 3 ? 'accent-4' : '#fff'} />
      <Star size={size} color={reviews > 4 ? 'accent-4' : '#fff'} />
    </Box>
  );
};

export default CourseLanRating;
