import React from 'react';
import styled from 'styled-components';

const IconDisplay = ({ icon }) => {
  return <Container>{icon}</Container>;
};

export default IconDisplay;

const Container = styled.div`
  height: 4rem;
  width: 4rem;
  background: #0966b8;
  padding-top: 0.9rem;
  border-radius: 50%;
`;
