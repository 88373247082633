import { useEffect, useRef, useState } from 'react';

export const useDebounce = (value, time) => {
  const [_value, setValue] = useState(value);
  const mountedRef = useRef(false);
  const timeRef = useRef(null);
  const cancel = () => window.clearTimeout(timeRef.current);

  useEffect(() => {
    cancel();
    timeRef.current = window.setTimeout(() => {
      setValue(value);
    }, time);
  }, [value]);

  useEffect(() => {
    mountedRef.current = true;
    return cancel;
  }, []);
  return [_value, cancel];
};
