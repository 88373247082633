import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import routes, { changepassword, forgetpassword, login } from './adminRoutes';
import RouteGuard from 'RouteGuard';
import NotFound from 'NotFound';
import { useDispatch } from 'react-redux';
import { clearStudentInteraction, clearStudentInteractionModal } from 'store/actions/inAppNotify';
import { MainContext } from 'index';
import AppData from 'AppData';

function AppAdmin() {
  const location = useLocation();
  const dispatch = useDispatch();
  const unGuardedRoutes = [login, forgetpassword];
  const mainContext = React.useContext(MainContext);

  React.useEffect(() => {
    if (mainContext.build === AppData.adminBuildType) {
      let tawkToWidget = document.getElementsByClassName('widget-visible');
      let tawkToBubble = tawkToWidget[0]?.getElementsByTagName('iframe')[0];
      const intervalId = setInterval(() => {
        if (!tawkToBubble) {
          tawkToWidget = document.getElementsByClassName('widget-visible');
          tawkToBubble = tawkToWidget[0]?.getElementsByTagName('iframe')[0];
          return;
        }
        tawkToBubble.style.display = 'none';
        clearInterval(intervalId);
      }, 1000);
      return () => {
        if (tawkToBubble) tawkToBubble.style.display = 'block';
        if (intervalId) clearInterval(intervalId);
      };
    }
  }, []);

  React.useEffect(() => {
    if (location.pathname.includes('login') || location.pathname.length === 1) {
      return;
    } else if (!routes.includes(location.pathname)) {
      return;
    }
    sessionStorage.setItem('_loc', location.pathname);
  }, [location]);

  React.useEffect(() => {
    dispatch(clearStudentInteraction());
    dispatch(clearStudentInteractionModal());
  }, []);

  return (
    <Switch>
      {routes.map((route, i) => (
        <Route key={i} exact path={route.path}>
          {unGuardedRoutes.includes(route.path) ? (
            <route.component />
          ) : (
            <RouteGuard path={route.path} permissions={route.permssions || []}>
              {<route.component />}
            </RouteGuard>
          )}
        </Route>
      ))}
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

export default AppAdmin;
