import React from 'react';
import styled from 'styled-components';
import { Col, Typography } from 'antd';

import IconDisplay from 'components/common/IconDisplay';

const ProfileDetailsCard = ({ name, icon, index, mainData }) => {
  return (
    <DetailsCol
      xs={{ span: 24 }}
      sm={{ span: 24 }}
      md={{ span: 24 }}
      lg={{ span: 8 }}
      xl={{ span: 8 }}>
      <DetailsDiv>
        <div>
          <IconDisplay icon={icon} />
        </div>
        <div>
          <DetailsTitle>{`${name ? name : 'None'}`}</DetailsTitle>
          <DetailsText>{Object.keys(mainData[index].name)[0]}</DetailsText>
        </div>
      </DetailsDiv>
    </DetailsCol>
  );
};

export default ProfileDetailsCard;

const DetailsCol = styled(Col)``;

const DetailsDiv = styled.div`
  background: #fff;
  padding: 1rem;
  min-height: 7rem;
  border-radius: 6px;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-gap: 1rem;
`;

const DetailsTitle = styled(Typography.Title)`
  font-size: 1.3em !important;
  font-weight: bold !important;
  margin-bottom: 7px !important;
  text-transform: capitalize;
`;
const DetailsText = styled(Typography.Text)`
  font-size: 0.9em;
  color: #0966b8;
  text-transform: capitalize;
`;
