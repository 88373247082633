import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Layout, Typography, Button, Popover, Avatar } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SkillDormBlackLogo from 'assets/images/skilldormblacklogo.png';
import SkillDormWhiteLogo from 'assets/images/skilldormwhitelogo.png';
import styled from 'styled-components';
import { signOut } from 'store/actions/auth';
import { dashboard, discovery, home, partner, register, profile, facilitators } from 'userRoutes';
import { dashboard as adminDashboard, profileadmin } from 'adminRoutes';
import { MainContext } from 'index';
import AppData from 'AppData';
import { useMediaQuery } from 'react-responsive';
import IconWrapper from 'components/common/IconWrapper';
import MenuIcon from 'assets/svgs/ic_menu.svg';
import CloseIcon from 'assets/svgs/ic_close.svg';
import SearchResult from 'components/common/SearchResult';
import SearchInput from 'components/common/SearchInput';
import device from 'configs/responsive/mediaQueries';
import MaintenanceNotification from 'components/Maintenance/MaintenanceNotification';
import { useQuery } from 'react-query';
import { getInAppNotification } from 'api/queries/Notification';

const { mobile } = device;

const Header = ({ isAdmin, setShowSignin, isVideoPage, homePage, background }) => {
  const [autoFocus, setAutoFocus] = useState(false);
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const mainContext = useContext(MainContext);
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const { inAppData } = useSelector(state => state.inAppNotify);
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });
  const [showSearch, setShowSearch] = useState(false);
  const [isMaintenanceShowing, setIsMaintenanceShowing] = useState(false);
  const { data } = useQuery(['inAppNotify'], getInAppNotification);

  const showFunc = val => {
    setAutoFocus(true);
    setShowSearch(val);
  };

  const handleSignin = () => {
    if (homePage) setShowSignin(true);
    setShowMenu(false);
  };

  useEffect(() => {
    if (data?.length > 0) {
      if (data[0]?.showStrip) {
        setIsMaintenanceShowing(true);
      } else {
        setIsMaintenanceShowing(false);
      }
    }
  }, [data]);

  const popoverContent = (
    <div>
      {mainContext.build === AppData.userBuildType && (
        <DropdownStyles
          onClick={() => {
            history.push(dashboard);
          }}>
          <UserOutlined style={{ marginRight: 5 }} />
          My Dashboard
        </DropdownStyles>
      )}
      <DropdownStyles
        onClick={() => {
          if (mainContext.build === AppData.userBuildType) {
            history.push(profile);
          } else {
            history.push(profileadmin);
          }
        }}>
        <UserOutlined style={{ marginRight: 5 }} />
        Profile
      </DropdownStyles>
      <DropdownStyles
        onClick={() => {
          dispatch(signOut()); // handle sign out dispatch
          sessionStorage.clear(); // clear session storage
          history.push(home); // redirect to login
          window.location.reload(); // reload browser
        }}>
        <LogoutOutlined style={{ marginRight: 5 }} />
        Logout
      </DropdownStyles>
    </div>
  );
  const mobileNav = (
    <SubMenuContainer width="86%" align="flex-start" justify="space-evenly" direction="column">
      <NavLink to={home} exact>
        <NavText color="#000">Home</NavText>
      </NavLink>
      {/* <NavLink to={programs} exact>
        <NavText color="#000">Programs</NavText>
      </NavLink> */}
      <NavLink to={discovery} exact>
        <NavText color="#000">Courses</NavText>
      </NavLink>
      <NavLink exact to={partner}>
        <NavText color="#000">Partners</NavText>
      </NavLink>
      <NavLink exact to={facilitators}>
        <NavText color="#000">Become an Instructor</NavText>
      </NavLink>
      {!auth?.userId && (
        <NavLink exact to={home}>
          <NavText color="#000" onClick={handleSignin}>
            Signin
          </NavText>
        </NavLink>
      )}
      {!auth?.userId && (
        <CustomButton
          color={'#000'}
          size="large"
          type="primary"
          shape="round"
          onClick={() => history.push(register)}>
          Register Now
        </CustomButton>
      )}
    </SubMenuContainer>
  );

  return (
    <>
      {isMaintenanceShowing &&
        inAppData &&
        mainContext.build === AppData.userBuildType &&
        data?.map((item, i) => {
          if (item.showStrip) {
            return (
              <MaintenanceNotification
                key={i}
                data={item}
                setIsMaintenanceShowing={setIsMaintenanceShowing}
              />
            );
          }
        })}
      <HeaderContainer
        ismaintenanceshowing={
          isMaintenanceShowing && inAppData && mainContext.build === AppData.userBuildType
            ? '73px'
            : '0px'
        }
        background={background ? background : isAdmin ? '#fff' : '#000'}>
        <LogoContainer>
          <LogoWrapper
            to={mainContext.build === AppData.userBuildType ? home : adminDashboard}
            width={isMobileDevice ? '150px' : '200px'}>
            <img
              src={isAdmin ? SkillDormBlackLogo : SkillDormWhiteLogo}
              alt="Logo"
              height="100%"
              width="100%"
            />
          </LogoWrapper>
        </LogoContainer>
        <SubHeaderContainer>
          <MenuWrapper>
            {mainContext.build === AppData.userBuildType && !isMobileDevice && (
              <SubMenuContainer width="100%" align="center" justify="space-between" direction="row">
                <NavLink to={home} exact>
                  <NavText color={'#ffffff'}>Home</NavText>
                </NavLink>

                <NavLink to={discovery} exact>
                  <NavText color={'#fff'}>Courses</NavText>
                </NavLink>

                {/* <NavLink to={programs} exact>
                <NavText color={'#fff'}>
                  Programs
                </NavText>
              </NavLink> */}

                <NavLink exact to={partner}>
                  <NavText color={'#fff'}>Partners</NavText>
                </NavLink>

                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push(facilitators);
                    window.location.reload();
                  }}>
                  <NavText color={'#fff'}>Become an Instructor</NavText>
                </span>
              </SubMenuContainer>
            )}
          </MenuWrapper>
          {mainContext.build === AppData.userBuildType && (
            <InputContainer width={isMobileDevice ? '100%' : '50%'}>
              {!isMobileDevice && !isVideoPage && (
                <SearchInput color={'#fff'} showFunc={showFunc} marginLeft={'0em'} />
              )}
            </InputContainer>
          )}
          {auth?.userId ? (
            <DivFlexCenter>
              {!isMobileDevice && (
                <Typography>
                  <TextWrapper strong color={isAdmin ? '#000' : '#fff'}>
                    {auth.firstName.charAt(0).toUpperCase()}
                    {auth.firstName.slice(1).toLowerCase()}
                  </TextWrapper>
                </Typography>
              )}
              <Popover placement="bottomLeft" content={popoverContent} trigger="click">
                {!auth.profilePix ? (
                  <ProfileAvatar type="primary" shape="circle">
                    {auth.firstName.charAt(0).toUpperCase()}
                    {auth.lastName.charAt(0).toUpperCase()}
                  </ProfileAvatar>
                ) : (
                  <Avatar icon={<img src={auth.profilePix} alt="profile" />} />
                )}
                {!isMobileDevice && <CaretDownOutlinedIcon />}
              </Popover>
            </DivFlexCenter>
          ) : (
            <>
              {mainContext.build === AppData.userBuildType &&
                !isMobileDevice &&
                history.location.pathname !== '/' && (
                  <SignInButton
                    size="large"
                    type="primary"
                    shape="round"
                    onClick={() => history.push(home)}>
                    Sign In
                  </SignInButton>
                )}

              {mainContext.build === AppData.userBuildType && !isMobileDevice && (
                <CustomButton
                  size="large"
                  type="primary"
                  shape="round"
                  onClick={() => history.push(register)}>
                  Register Now
                </CustomButton>
              )}
            </>
          )}
          {mainContext.build === AppData.userBuildType && isMobileDevice && (
            <div style={{ display: 'flex', marginLeft: '10px' }}>
              <Button
                style={{
                  padding: 1,
                  border: '2px solid #777',
                  borderRadius: 4,
                  marginLeft: '1rem'
                }}
                icon={<IconWrapper width="25px" icon={showMenu ? CloseIcon : MenuIcon} />}
                onClick={() => {
                  if (isMobileDevice) {
                    if (homePage) setShowSignin(false);
                    setShowMenu(state => !state);
                  }
                }}
              />
              {showMenu && <MobileMenuDiv>{mobileNav}</MobileMenuDiv>}
            </div>
          )}
        </SubHeaderContainer>

        {autoFocus && (
          <SearchResult
            showSearch={showSearch}
            showFunc={showFunc}
            focus={autoFocus}
            setFocus={setAutoFocus}
          />
        )}
      </HeaderContainer>
    </>
  );
};

Header.propTypes = {
  background: PropTypes.string
};
export default Header;

const MobileMenuDiv = styled.div`
  width: 100%;
  position: absolute;
  background: white;
  top: 60px;
  bottom: 0;
  left: 0;
  height: 412px;
  z-index: 1000;
  padding-left: 20px;
  transition: all 0.3s ease-in-out;
`;

const DropdownStyles = styled.p`
  cursor: pointer;
  margin-bottom: 8px;
  text-align: left;
`;
const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  width: ${({ width }) => width};
`;
const LogoContainer = styled.div`
  min-width: 250px;
`;

const CaretDownOutlinedIcon = styled(CaretDownOutlined)`
  font-size: 14px;
  color: #a09f9f;
  margin-left: 2px;
`;

const HeaderContainer = styled(Layout.Header)`
  background: ${({ background }) => (background ? background : '#000')};
  padding: 0;
  display: flex;
  align-items: center;
  z-index: 1000;
  position: fixed;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
  left: 0;
  right: 0;
  top: ${({ ismaintenanceshowing }) => ismaintenanceshowing};
`;

const SubHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
`;

const LogoWrapper = styled(Link)`
  width: ${({ width }) => (width ? width : '150px')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;
const DivFlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: ${props => (props.isUserWidth ? '13%' : '13%')};
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 55%;
`;
const TextWrapper = styled(Typography.Text)`
  font-size: 17px;
  margin-right: 10px;
  color: ${({ color }) => color};
`;

const NavText = styled(Typography.Text)`
  color: ${({ color }) => color};
  font-weight: 500;
`;

const SubMenuContainer = styled.div`
  display: flex;
  width: ${({ width }) => `${width}`};
  justify-content: ${({ justify }) => `${justify}`};
  flex-direction: ${({ direction }) => `${direction}`};
  align-items: ${({ align }) => `${align}`};
`;

const SignInButton = styled(Button)`
  border: 2px solid #fff;
  padding: 4px 22px !important;
  font-size: 18px !important;
  color: #ffffff;
  background-color: transparent;
  transition-property: color, background-color, border-color, box-shadow;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
  font-weight: 600;
  &:hover {
    background-color: #fff;
    color: #0966b8;
  }
  @media ${mobile} {
    margin-left: 0;
  }
`;
const CustomButton = styled(Button)`
  border: 2px solid #0966b8;
  padding: 4px 22px !important;
  font-size: 18px !important;
  background-color: #0966b8;
  margin-left: 1.3rem;
  box-shadow: 1px 3px 8.5px 3px #333;
  color: #ffffff;
  transition-property: color, background-color, border-color, box-shadow;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
  font-weight: 600;
  &:hover {
    background-color: transparent;
    color: ${({ color }) => color};
  }
  @media ${mobile} {
    margin-left: 0;
  }
`;

const ProfileAvatar = styled(Avatar)`
  background-color: #0966b8 !important;
  border: #0966b8 !important;
`;
