import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import MainLayout from 'components/MainLayout';
import { Row, Col, Avatar, Typography, Image, Button } from 'antd';
import { UserOutlined, HomeOutlined } from '@ant-design/icons';
import { FaPenAlt } from 'react-icons/fa';
import PageLoader from 'components/common/PageLoader';
import ProfileUpdate from 'components/profileUpdate';
import { getAdminByUserId2 } from 'api/queries/Authentication';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Fragment } from 'react';
import InstitutionBG from 'assets/images/institutionBG.jpg';

const Profile = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [profileData, setProfileData] = useState();
  const [update, setUpdate] = useState(false);
  const { userId, institutionName, institutionLogo, role, institutionCode } = useSelector(
    state => state.auth
  );
  const { data, isLoading, isError, error, refetch } = useQuery(
    ['getAdmin', userId],
    getAdminByUserId2
  );

  const handleUpdate = () => {
    setUpdate(prev => !prev);
  };

  useEffect(() => {
    if (!isLoading || data) {
      console.log(data);
      setProfileData(data?.data);
    } else if (isError) {
      console.log(error.message);
    }
  }, [data]);

  const getLoader = loading => loading;

  return (
    <MainLayout isAdmin sidebar padded>
      {profileData ? (
        <Fragment>
          <ProfileHeader>
            <ProfileContainer gutter={16}>
              <AvatarContainer span={8} update={update}>
                <ProfileAvatar
                  icon={
                    profileData.profilePixUrl ? (
                      <img src={profileData.profilePixUrl} />
                    ) : (
                      <UserOutlined />
                    )
                  }
                  size={200}
                />
              </AvatarContainer>
              <UpdateContainer span={15} offset={1}>
                <UserName update={update}>
                  {update ? `Update Profile` : `${profileData.firstName} ${profileData.lastName}`}
                </UserName>
                <UpdateLink onClick={handleUpdate} update={update}>
                  <PenIcon></PenIcon>
                  <UpdateText>Update profile</UpdateText>
                </UpdateLink>
              </UpdateContainer>
            </ProfileContainer>
          </ProfileHeader>
          {!update ? (
            <>
              <HeaderDiv>
                <Col span={6}>
                  <About>About Partner</About>
                </Col>
                <Col span={6} offset={12}>
                  {institutionLogo ? (
                    <Avatar src={institutionLogo} size={50} />
                  ) : (
                    <Avatar icon={<HomeOutlined />} />
                  )}
                  <Span>{institutionName}</Span>
                </Col>
              </HeaderDiv>
              <InstitutionContainer>
                <Row>
                  <Col span={12} style={{ zIndex: '4' }}>
                    <InstitutionText>Partner</InstitutionText>
                    <InstitutionTitle>{institutionName}</InstitutionTitle>
                    <Role>
                      Role: <strong>{role}</strong>
                    </Role>
                  </Col>
                  <Col span={4} offset={8} style={{ marginTop: '1em', zIndex: '4' }}>
                    <Notification>Notifications</Notification>
                    <Number>4</Number>
                    <Active>4 Active Courses</Active>
                  </Col>
                </Row>
              </InstitutionContainer>
            </>
          ) : (
            <ProfileUpdate
              getLoader={getLoader}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              handleUpdate={handleUpdate}
              profileData={profileData}
              refetch={refetch}
            />
          )}
        </Fragment>
      ) : (
        <PageLoader isLoading={isLoading} />
      )}
    </MainLayout>
  );
};

export default Profile;

const ProfileHeader = styled(Row)`
  background: #000;
  min-height: 12em;
  padding: 1.5em 0 0 3.5em;
`;
const ProfileContainer = styled(Row)`
  width: 100%;
`;
const AvatarContainer = styled(Col)`
  position: relative;
  transition: all 0.5s ease-out;
  opacity: ${({ update }) => (update ? `0` : `1`)};
  pointer-events: ${({ update }) => (update ? `none` : 'auto')};
`;

const ProfileAvatar = styled(Avatar)`
  display: block !important;
  position: absolute !important;
  top: 0.1em;
`;

const UpdateContainer = styled(Col)`
  padding-top: 4.4em;
  margin-left: -12%;
`;

const UserName = styled(Typography.Paragraph)`
  color: #fff;
  font-weight: bold;
  line-height: 0.8;
  font-size: 3em;
  margin-bottom: 0.3em !important;
  margin-top: ${({ update }) => (update ? '.6em' : 0)};
  margin-left: ${({ update }) => (update ? '-3em' : 0)};
  transition: all 0.5s ease-out;
  transform: ${({ update }) =>
    update ? `translate(-5em, -1em) scale(0.6)` : 'translate(0em, 0em) scale(1)'};
  /* opacity: ${({ update }) => (update ? `0` : `1`)};
    pointer-events: ${({ update }) =>
    update ? `none` : 'auto'}; */
`;

const UpdateLink = styled(Typography.Paragraph)`
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.5s ease-out;
  opacity: ${({ update }) => (update ? `0` : `1`)};
  pointer-events: ${({ update }) => (update ? `none` : 'auto')};
`;

const UpdateText = styled(Typography.Text)`
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
  margin-left: 6px;
`;
const PenIcon = styled(FaPenAlt)``;

const HeaderDiv = styled(Row)`
  margin: 3.5em 0 1.5em 0;
`;

const Span = styled.span`
  margin-left: 0.5em;
  color: #7f7f7f;
`;

const About = styled(Typography.Paragraph)`
  font-size: 1.6em;
  color: #7f7f7f;
  font-weight: bold;
  margin: 0.6em 0 0 0 !important;
`;

const InstitutionContainer = styled.div`
  margin: auto;
  position: relative;
  padding: 2rem 2rem 0 2rem;
  min-height: 15rem;
  background: url(${InstitutionBG}) no-repeat center center/cover;
  border-radius: 6px;
  transition: all 0.5s ease-out;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: '';
    background: #0966f8;
    opacity: 0.6;
    border-radius: 6px;
  }
`;

const InstitutionText = styled(Typography.Paragraph)`
  font-weight: lighter;
  color: #fff;
`;

const InstitutionTitle = styled(Typography.Paragraph)`
  color: #fff;
  font-size: 2.3em;
`;
const Role = styled(Typography.Paragraph)`
  color: #fff;
  font-size: 1.4em;
  font-weight: lighter;
`;

const Notification = styled(Typography.Paragraph)`
  font-weight: lighter;
  color: #fff;
  font-size: 0.8em;
`;
const Number = styled(Typography.Paragraph)`
  color: #fff;
  font-size: 2.3em;
  font-weight: bold;
  margin-bottom: 20px !important;
`;
const Active = styled(Typography.Paragraph)`
  color: #fff;
  font-size: 0.7em;
`;
