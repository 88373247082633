import React from 'react';
import { Col, Button } from 'antd';
import styled from 'styled-components';
import { register } from 'userRoutes';
import { useHistory } from 'react-router';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const RegisterButton = ({ isMobile, setShowSignin }) => {
  const history = useHistory();
  return (
    <Col span={8} offset={!isMobile && 2}>
      <RegisterBtn onClick={() => history.push(register, setShowSignin)}>Register Now</RegisterBtn>
    </Col>
  );
};

export default RegisterButton;

const RegisterBtn = styled(Button)`
  width: 100%;
  height: 90%;
  border: none;
  background: #0966b8;
  color: #fff;
  border-radius: 2em;
  box-shadow: 1px 3px 8.5px 3px #333;
  font-size: 18px;
  margin-top: 0.4em;
  @media ${mobile} {
    height: 3rem;
    width: 8.5rem;
    margin-top: 1.2em;
  }
  &:hover {
    background: none;
    border: 3px solid #0966b8;
    color: #fff;
    box-shadow: 0px 0px 0px 0px #7f7f7f !important;
  }
`;
