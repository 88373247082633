import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import img from 'assets/images/facilitator.png';
import { ButtonCTA } from 'components/common/ButtonCTA';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const Jumbotron = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  return (
    <Container>
      <Inner>
        <TextDiv>
          <Heading>Be a part of the future of education in Africa</Heading>
          <SubText>Join SkillDorm e-learning marketplace</SubText>
          <ButtonCTA
            bgColor="#0966b8"
            color="#fff"
            fontSize="20px"
            fontWeight="600"
            width="250px"
            data-tf-popup="t1R5fOuZ"
            data-tf-hide-headers
            data-tf-iframe-props="title=My typeform"
            data-tf-medium="snippet"
            height={isMobile ? '59px' : '65px'}>
            Sign up as an instructor
          </ButtonCTA>
        </TextDiv>
        <ImgDiv>
          <Img src={img} />
        </ImgDiv>
      </Inner>
    </Container>
  );
};

export default Jumbotron;

const Container = styled.div`
  display: flex;
  margin: auto;
  padding: 9rem 0 0 0;
  background: #f8fafb;
  @media ${mobile} {
    display: block;
    margin: 0;
    padding: 4rem 0 0 0;
  }
`;
const Inner = styled.div`
  display: flex;
  width: 70rem;
  margin: auto;
  gap: 10%;
  @media ${mobile} {
    width: 100%;
    flex-direction: column;
    gap: 4rem;
  }
`;

const TextDiv = styled.div`
  width: 120%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  @media ${mobile} {
    align-items: center;
  }
`;
const ImgDiv = styled.div`
  width: 100%;
`;

const Img = styled.img`
  width: 100%;
  height: auto;
`;

const Heading = styled(Typography.Paragraph)`
  font-size: 60px;
  font-style: normal;
  font-weight: bold;
  line-height: 65px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  @media ${mobile} {
    text-align: center;
    font-size: 36px;
    line-height: 43.57px;
  }
`;
const SubText = styled(Typography.Paragraph)`
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

  color: #121212;
  margin: 0 !important;
  @media ${mobile} {
    text-align: center;
    font-size: 18px;
    line-height: 21.78px;
  }
`;
