import React, { useEffect, useState } from 'react';
import { Box, ResponsiveContext, Text } from 'grommet';
import PageHeader from 'components/common/PageHeader';
import Steps from 'components/extras/VerticalSteps';
import { animate, isMobile } from 'utils';
import BasicInfoInstitutionForm from 'components/forms/BasicInfoInstitutionForm';
import NotificationModal from 'components/modals/NotificationModal';
import { useMutation, useQuery } from 'react-query';
import { updateInstitution } from 'api/mutations/Institution';
import { manageinstitution } from 'adminRoutes';
import { useHistory } from 'react-router-dom';
import { getBanks, getCountries } from 'api/queries/Institution';
import MainLayout from 'components/MainLayout';

const steps = [
  { title: 'Basic Info' },
  {
    title: 'Finish'
  }
];

const institutionType = [
  { value: 1, name: 'Government ' },
  { value: 2, name: 'NonProfitOrganization' },
  { value: 3, name: 'Others' }
];

const UpdateInstitution = () => {
  const { data: countries } = useQuery(['countries'], getCountries);
  const { isLoading, data: banks } = useQuery('getBanks', getBanks);
  const size = React.useContext(ResponsiveContext);
  const [institutionUser, setInstitutionUser] = React.useState(null);
  const [current] = React.useState(1);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [courseThumbNailUrl, setCourseThumbNailUrl] = React.useState('');
  const [institutionBannerUrl, setInstitutionBannerUrl] = React.useState('');
  const [institutionLogoUrl, setInstitutionLogoUrl] = React.useState('');
  const [institutionLogoUrlFile, setInstitutionLogoUrlFile] = useState();
  const [courseThumbNailUrlFile, setCourseThumbNailUrlFile] = useState();
  const [institutionBannerUrlFile, setInstitutionBannerUrlFile] = useState();
  const [certificatelogoUrl, setCertificatelogoUrl] = React.useState('');
  const [certificatelogoUrlFile, setCertificatelogoUrlFile] = useState();
  const [createError, setCreateError] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const history = useHistory();
  const [createMessageAnimate] = React.useState([
    animate({ type: 'fadeIn', delay: 500, duration: 500 }),
    animate({ type: 'slideUp', delay: 500, duration: 500 })
  ]);

  const [updateMutate] = useMutation(updateInstitution, {
    onSuccess: data => {
      const { description, success } = data;
      if (success) {
        setSuccessMessage(description);
      } else {
        setCreateError(description);
        setIsSubmitting(false);
        console.log(description);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setCreateError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        setCreateError(error.message);
        console.log(error.message);
      }
    }
  });

  const onInstitutionFinish = async data => {
    if (
      institutionLogoUrlFile?.type.match(/image.*/) === null ||
      courseThumbNailUrlFile?.type.match(/image.*/) === null ||
      institutionBannerUrlFile?.type.match(/image.*/) === null ||
      certificatelogoUrlFile?.type.match(/image.*/) === null
    ) {
      setCreateError('Sorry only png, jpg, gif images are accepted');
      return;
    } else {
      setCreateError(undefined);
    }
    console.log(data);
    if (isSubmitting) return;
    let formData = new FormData();
    console.log(data);
    const institutionDetails = {
      id: history.location.state.id,
      Name: data.institutionName,
      Website: data.website,
      PhoneNumber: data.phone,
      Email: data.email,
      Address: data.address,
      Country: countries?.find(d => d.countryName === data.country).code1,
      AccountNumber: data.accountNumber,
      BankId: data.bank,
      overView: data.overView,
      Logo: institutionLogoUrlFile,
      Thumbnail: courseThumbNailUrlFile,
      Banner: institutionBannerUrlFile,
      FullName: data.fullName,
      CertificateLogo: certificatelogoUrlFile
    };
    console.log(institutionDetails);
    for (let key in institutionDetails) {
      formData.append(key, institutionDetails[key]);
    }

    // for (let key in institutionDetails) {
    //   if (typeof institutionDetails[key] === 'object') {
    //     for (let subKey in institutionDetails[key])
    //       formData.append(`${key}.${subKey}`, institutionDetails[key][subKey]);
    //   } else formData.append(key, institutionDetails[key]);
    // }

    try {
      setCreateError(null);
      setIsSubmitting(true);
      await updateMutate(formData);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (history.location.state) {
      console.log(history.location.state);
      const modelData = {
        institution: {
          institutionName: history.location.state?.name,
          institutionType: institutionType?.find(
            d => d.value === history.location.state?.institutionType
          )?.name,
          website: history.location.state?.website,
          phone: history.location.state?.phoneNumber,
          email: history.location.state?.email,
          address: history.location.state?.address,
          country: countries?.find(d => d.code1 === history.location.state?.country)?.countryName,
          bank: banks?.find(d => d.id === history.location.state?.bankId)?.name,
          overView: history.location.state?.overView,
          accountNumber: history.location.state?.accountNumber,
          profileUrl: history.location.state?.profileUrl,
          courseThumbNailUrl: history.location.state?.thumbnailUrl,
          institutionBannerUrl: history.location.state?.bannerUrl,
          institutionLogoUrl: history.location.state?.logoUrl,
          fullName: history.location.state?.fullName,
          certificateLogoUrl: history.location.state?.certificateLogoUrl
        }
      };
      setInstitutionUser(modelData.institution);
      console.log(modelData);
    }
  }, [banks, countries, history.location.state]);

  return (
    <MainLayout isAdmin sidebar padded={1} selectedKey="institution">
      <Box
        pad={{ left: 'medium', bottom: 'none', top: 'small' }}
        background="#fff"
        direction={isMobile(size) ? 'column' : 'row'}
        gap="large"
        border={isMobile(size) ? false : 'bottom'}
        wrap={true}>
        <PageHeader canGoBack pageTitle="Update Partner" />
      </Box>
      <Box
        pad="medium"
        background="#fff"
        direction={isMobile(size) ? 'column' : 'row'}
        gap="large"
        border={isMobile(size) ? false : 'between'}
        wrap={true}>
        <Box gap="medium" margin={{ bottom: 'medium' }}>
          <Steps current={current} steps={steps.map(step => step.title)} />
        </Box>
        <Box flex="grow">
          <PageHeader
            pageTitle={steps[current - 1].title}
            pageDescription={steps[current - 1].description}
          />
          {createError && (
            <Box margin={{ bottom: 'medium' }} animation={createMessageAnimate[0]}>
              <Box animation={createMessageAnimate[1]}>
                <Text color="status-critical">{createError}</Text>
              </Box>
            </Box>
          )}
          {
            {
              1: (
                <>
                  {institutionUser && (
                    <BasicInfoInstitutionForm
                      isSubmitting={isSubmitting}
                      onInstitutionSubmit={onInstitutionFinish}
                      institutionType={institutionType}
                      countries={countries}
                      banks={banks}
                      institutionFormState={institutionUser}
                      courseThumbNailUrl={institutionUser.courseThumbNailUrl || courseThumbNailUrl}
                      setCourseThumbNailUrl={setCourseThumbNailUrl}
                      institutionBannerUrl={
                        institutionUser.institutionBannerUrl || institutionBannerUrl
                      }
                      setInstitutionBannerUrl={setInstitutionBannerUrl}
                      institutionLogoUrl={institutionUser.institutionLogoUrl || institutionLogoUrl}
                      setInstitutionLogoUrl={setInstitutionLogoUrl}
                      setCourseThumbNailUrlFile={setCourseThumbNailUrlFile}
                      setInstitutionBannerUrlFile={setInstitutionBannerUrlFile}
                      setInstitutionLogoUrlFile={setInstitutionLogoUrlFile}
                      setCertificatelogoUrl={setCertificatelogoUrl}
                      certificatelogoUrl={institutionUser.certificateLogoUrl || certificatelogoUrl}
                      setCertificatelogoUrlFile={setCertificatelogoUrlFile}
                    />
                  )}
                </>
              )
            }[current]
          }
        </Box>
        {successMessage && (
          <NotificationModal
            title="Partner Update"
            message={<>Partner has been Updated successfully,</>}
            status="success"
            iconHeight="80px"
            iconWidth="80px"
            actionText="Complete"
            actionFunc={() => {
              history.replace(manageinstitution);
            }}
          />
        )}
      </Box>
    </MainLayout>
  );
};

export default UpdateInstitution;
