import React from 'react';
import {
  Box,
  Form,
  FormField,
  MaskedInput,
  ResponsiveContext,
  Select,
  TextInput,
  Text
} from 'grommet';
import { isMobile } from 'utils';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CourseDetailsForm = ({
  languageData,
  instructorData,
  headLine,
  setHeadLine,
  overView,
  setOverView,
  courseLevel,
  setCourseLevel,
  languageId,
  setLanguageId,
  objectives,
  setObjectives,
  effort,
  setEffort,
  instructorName,
  setInstructorName,
  errors,
  isReview
}) => {
  const size = React.useContext(ResponsiveContext);
  const [showErrors, setShowErrors] = React.useState({
    headLine: true,
    overView: true,
    courseLevel: true,
    languageId: true,
    objectives: true,
    effort: true,
    instructorName: true
  });
  const [courseLevels] = React.useState(['Beginner', 'Intermediate', 'Advanced']);
  const [instructorNameInput, setInstructorNameInput] = React.useState(null);
  const formFieldStyle = {
    flexDirection: isMobile(size) ? 'column' : 'row',
    alignItems: isMobile(size) ? 'stretch' : 'center',
    justifyContent: isMobile(size) ? 'flex-start' : 'space-between'
  };

  const instructorNameOptions = React.useMemo(() => {
    if (!instructorData?.data) return [];
    return instructorNameInput
      ? [
          ...new Set(
            [instructorNameInput].concat(instructorData.data.map(instructor => instructor.name))
          )
        ]
      : instructorData.data.map(instructor => instructor.name);
  }, [instructorData, instructorNameInput]);

  return (
    <Form style={{ maxWidth: 475 }}>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Course Headline"
          name="courseHeadline"
          htmlfor="course-headline"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <TextInput
            id="course-headline"
            name="courseHeadline"
            placeholder="Course Headline"
            style={{ width: isMobile(size) ? '100%' : 275 }}
            value={headLine}
            onChange={e => {
              const value = e.target.value;
              setHeadLine(value);
              if (value === '') setShowErrors({ ...showErrors, headLine: true });
              else setShowErrors({ ...showErrors, headLine: false });
            }}
            disabled={isReview}
          />
        </FormField>
        {errors?.headLine && showErrors.headLine && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.headLine}
          </Text>
        )}
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Course Overview"
          name="courseOverview"
          htmlfor="course-overview"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <Box style={{ width: isMobile(size) ? '100%' : 275 }}>
            <CKEditor
              editor={ClassicEditor}
              data={overView}
              onChange={(event, editor) => {
                const data = editor.getData();
                setOverView(data);
                if (data === '') setShowErrors({ ...showErrors, overView: true });
                else setShowErrors({ ...showErrors, overView: false });
              }}
              disabled={isReview}
            />
          </Box>
        </FormField>
        {errors?.overView && showErrors.overView && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.overView}
          </Text>
        )}
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Course Level"
          name="courseLevel"
          htmlfor="course-level"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <Select
            id="course-level"
            name="courseLevel"
            placeholder="Course Level"
            style={{ width: isMobile(size) ? '100%' : 227 }}
            options={courseLevels}
            value={courseLevels[courseLevel - 1]}
            onChange={({ option }) => {
              setCourseLevel(courseLevels.indexOf(option) + 1);
              setShowErrors({ ...showErrors, courseLevel: false });
            }}
            disabled={isReview}
          />
        </FormField>
        {errors?.courseLevel && showErrors.courseLevel && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.courseLevel}
          </Text>
        )}
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Language"
          name="language"
          htmlfor="language"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          {languageData && (
            <Select
              id="language"
              name="language"
              placeholder="Language"
              style={{ width: isMobile(size) ? '100%' : 227 }}
              options={languageData.data.map(d => d.name)}
              value={languageData.data.find(d => d.id === languageId)?.name}
              onChange={({ option }) => {
                setLanguageId(languageData.data.find(d => d.name === option)?.id);
                setShowErrors({ ...showErrors, languageId: false });
              }}
              disabled={isReview}
            />
          )}
        </FormField>
        {errors?.languageId && showErrors.languageId && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.languageId}
          </Text>
        )}
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Course Objectives"
          name="courseObjectives"
          htmlfor="course-objectives"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <Box style={{ width: isMobile(size) ? '100%' : 275 }}>
            <CKEditor
              editor={ClassicEditor}
              data={objectives}
              onChange={(event, editor) => {
                const data = editor.getData();
                setObjectives(data);
                if (data === '') setShowErrors({ ...showErrors, objectives: true });
                else setShowErrors({ ...showErrors, objectives: false });
              }}
              disabled={isReview}
            />
          </Box>
        </FormField>
        {errors?.objectives && showErrors.objectives && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.objectives}
          </Text>
        )}
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Course Effort"
          name="courseEffort"
          htmlfor="course-effort"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <MaskedInput
            id="course-effort"
            name="courseEffort"
            style={{ width: isMobile(size) ? '100%' : 275 }}
            value={effort}
            onChange={e => {
              const value = e.target.value;
              setEffort(value);
              if (value === '') setShowErrors({ ...showErrors, effort: true });
              else setShowErrors({ ...showErrors, effort: false });
            }}
            disabled={isReview}
            mask={[
              {
                length: 1,
                options: ['1', '2', '3'],
                regexp: /[1-9]/,
                placeholder: '2'
              },
              { fixed: ' to ' },
              {
                length: [1 - 2],
                options: ['4', '5', '6', '7', '8', '9'],
                regexp: /^[0-5][0-9]$|^[0-9]$/,
                placeholder: '4'
              },
              { fixed: ' hours per week' }
            ]}
          />
        </FormField>
        {errors?.effort && showErrors.effort && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.effort}
          </Text>
        )}
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Instructor Name"
          name="instructorName"
          htmlfor="instructor-name"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          {instructorData && (
            <Select
              onSearch={value => setInstructorNameInput(value)}
              dropHeight="small"
              placeholder="Instructor Name"
              style={{ width: isMobile(size) ? '100%' : 227 }}
              value={instructorName}
              options={instructorNameOptions}
              onChange={({ value }) => {
                setInstructorName(value);
                if (value === '') setShowErrors({ ...showErrors, instructorName: true });
                else setShowErrors({ ...showErrors, instructorName: false });
              }}
              disabled={isReview}
            />
          )}
        </FormField>
        {errors?.instructorName && showErrors.instructorName && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.instructorName}
          </Text>
        )}
      </Box>
    </Form>
  );
};

export default CourseDetailsForm;
