import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';
import { ImageWrapper } from 'components/common/ImageWrapper';
import { ButtonCTA } from 'components/common/ButtonCTA';
import Certified from 'assets/images/landing page Assets/Home landing page/certified.png';
import { useMediaQuery } from 'react-responsive';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;
const CertifiedSection = props => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  return (
    <CertifiedSectionWrapper>
      <Col md={10} offset={!isMobile && 1}>
        <DivWrapper>
          <Col>
            <SubTitle level={4}>Access to over 3000+</SubTitle>
            <Title>Certified Courses</Title>
            <Paragraph>
              Skilldorm provides access to certified courses with in-demand and relevant skillsets
              for the job market today. Also get verified certificates to boost your resume or
              porfolio.
            </Paragraph>
            {/* <ButtonCTA type="primary" size="large" color="#0966b8">
              Read More
            </ButtonCTA> */}
          </Col>
        </DivWrapper>
      </Col>
      <Col md={10} offset={!isMobile && 2}>
        <ImageWrapper width="100%" height="100%" src={Certified} preview={false} />
      </Col>
    </CertifiedSectionWrapper>
  );
};

CertifiedSection.propTypes = {};

export default CertifiedSection;

const CertifiedSectionWrapper = styled(Row)`
  height: 500px;
  padding: 50px;
  margin-top: 5rem;
  @media ${mobile} {
    margin-top: 19rem;
    padding: 0 1.3rem;
  }
`;

const Title = styled(Typography.Title)`
  margin-top: 0 !important;
  position: relative;
  margin-bottom: 1.8rem !important;
  &::before {
    content: '';
    top: 110%;
    left: 0%;
    height: 3px;
    width: 15%;
    color: #0966b8;
    background: #0966b8;
    position: absolute;
  }
  @media ${mobile} {
    font-size: 1.5em !important;
  }
`;

const SubTitle = styled(Typography.Title)`
  margin-left: 4px;
  @media ${mobile} {
    margin-bottom: 0.1em !important;
    font-size: 1em !important;
  }
`;

const Paragraph = styled(Typography.Paragraph)`
  margin-bottom: 3rem !important;
  @media ${mobile} {
    font-size: 0.9em !important;
  }
`;

const DivWrapper = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
`;
