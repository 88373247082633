import Axios from 'axios';

const service = 'notification';

export const getEmailTemplateList = async (key, page = 1, pageSize = 50) => {
  const { data } = await Axios.get(
    `/${service}/api/Email/Template/List?Page=${page}&PageSize=${pageSize}`
  );
  return data;
};
export const getTemplateListById = async (key, id) => {
  const { data } = await Axios.get(`/${service}/api/Email/Template/${id}`);
  return data;
};

export const toggleTemplate = async (key, id) => {
  const { data } = await Axios.get(`/${service}/api/Email/Email/Toggle/${id}`);
  return data;
};

export const categoryList = async () => {
  const { data } = await Axios.get(`/${service}/api/Email/Email/Category/List`);
  return data;
};

export const fetchTokens = async (key, categoryId) => {
  const { data } = await Axios.get(`/${service}/api/Email/Email/FetchTokens/${categoryId}`);
  return data;
};

//  InApp Notification

export const listCategory = async (key, isActive) => {
  const { data } = await Axios.get(
    `/${service}/api/InAppNotification/ListCategory?isActive=${isActive}`
  );
  return data;
};
export const listFeature = async (key, isActive) => {
  const { data } = await Axios.get(
    `/${service}/api/InAppNotification/ListFeatures?isActive=${isActive}`
  );
  return data;
};

export const getInAppNotification = async () => {
  const { data } = await Axios.get(`/${service}/api/InAppNotification/GetInAppNotification`);
  return data;
};

export const listInAppNotification = async (key, isActive) => {
  const { data } = await Axios.get(
    `/${service}/api/InAppNotification/ListInAppNotification${
      isActive ? `?isActive=${isActive}` : ''
    }`
  );
  return data;
};
