import React, { useContext } from 'react';
import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { dashboard } from 'adminRoutes';
import { home } from 'userRoutes';
import { MainContext } from 'index';
import AppData from 'AppData';

/**
 * Not Found Page Component - 404 page for url not found
 * E.g.:
 * ```html
 * <NotFound />
 * ```
 */
const NotFound = () => {
  const history = useHistory();
  const mainContext = useContext(MainContext);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
      <Result
        status="404"
        title="Not Found"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button
            type="primary"
            onClick={() =>
              history.push(mainContext.build === AppData.adminBuildType ? dashboard : home)
            }>
            Back To {mainContext.build === AppData.adminBuildType ? 'Dashboard' : 'Home'}
          </Button>
        }
      />
    </div>
  );
};

export default NotFound;
