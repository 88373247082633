import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import AppText from 'components/common/AppText';
import { getPendingCourse } from 'api/queries/Course';
import PageLoader from 'components/common/PageLoader';
import BeneficiarySelect from 'components/smeVille/BeneficiarySelect';
import Payment from '../../../components/smeVille/Payment';
import { BeneficiaryProvider } from 'context/BeneficiaryContext';
import NoPendingCourse from '../../../components/smeVille/NoPendingCourse';
import SmeVilleHeader from 'components/smeVille/SmeVilleHeader';
import { home, setpassword, thirdpartycheckout } from 'userRoutes';
import { useHistory } from 'react-router-dom';
import { getPasswordPasswordStatus } from 'api/queries/Authentication';
// import { showToast } from 'store/actions/toast';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const ThirdPartyCheckout = () => {
  const { auth } = useSelector(state => state);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const userName = queryParameters.get('username');
  //  const partnerName = location.pathname.split('/')[1]
  // console.log(partnerName)

  const { data: result } = useQuery(['passwordStatus', userName], getPasswordPasswordStatus, {
    enabled: userName
  });

  useEffect(() => {
    if (result?.data?.isFirstLogin === true) {
      history.push(`${setpassword}?username=${userName}`);
    }
    if (result?.data?.isFirstLogin === false) {
      if (!auth?.accessToken) {
        history.push(`${home}?redirect=${thirdpartycheckout}`);
      }
    }
  }, [auth?.accessToken, dispatch, history, result?.data?.isFirstLogin]);

  const { data, isLoading, refetch } = useQuery(['pendingCourse', auth.userId], getPendingCourse, {
    // `userId` would be `null` at first (falsy),
    // so the query will not execute until the userId exists
    enabled: auth.userId,
    refetchOnWindowFocus: false
  });

  return (
    <BeneficiaryProvider>
      <SmeVilleHeader />
      <Container>
        {data?.data && data?.data?.length !== 0 ? (
          <>
            <AppText
              value={`${data?.data?.length} Course${data?.data?.length > 1 ? 's' : ''}`}
              size={'24px'}
              weight={700}
            />
            <Inner>
              <LeftDiv>
                {data?.data?.map((course, index) => (
                  <BeneficiarySelect
                    refetch={refetch}
                    course={course}
                    index={index}
                    user={auth}
                    key={course.id}
                  />
                ))}
              </LeftDiv>
              <RightDiv>
                <Payment partnerId={data?.data} user={auth} />
              </RightDiv>
            </Inner>
          </>
        ) : data?.data?.length === 0 ? (
          <NoPendingCourse />
        ) : (
          <></>
        )}
        <PageLoader isLoading={isLoading} />
      </Container>
    </BeneficiaryProvider>
  );
};

export default ThirdPartyCheckout;

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 113px);
  padding: 5rem 3rem;
  background: #fff;
`;

const Inner = styled.div`
  display: flex;
  gap: 2rem;
`;

const LeftDiv = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const RightDiv = styled.div`
  width: 30%;
  height: 7rem;
  border-radius: 5px;
`;
