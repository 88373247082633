import React from 'react';
import { Box, Paragraph, ResponsiveContext, Text, Video } from 'grommet';
import FileUpload from 'components/common/FileUpload';
import { isMobile } from 'utils';

const InstitutionMediaUpload = ({
  title,
  description,
  mediaType = 'image',
  courseThumbNailUrl,
  setCourseThumbNailUrl,
  institutionBannerUrl,
  setInstitutionBannerUrl,
  setCoursePromotionalVideoUrl,
  institutionLogoUrl,
  setInstitutionLogoUrl,
  setCourseThumbNailUrlFile,
  setInstitutionBannerUrlFile,
  setInstitutionLogoUrlFile,
  setCertificatelogoUrl,
  certificatelogoUrl,
  setCertificatelogoUrlFile
}) => {
  const size = React.useContext(ResponsiveContext);
  const [media, setMedia] = React.useState(null);

  const getMedia = media => {
    if (mediaType === 'image') {
      if (media) {
        let reader = new FileReader();

        reader.onload = () => {
          setMedia(reader.result);
          if (title.includes('Thumbnail')) {
            setCourseThumbNailUrl(reader.result);
          } else if (title.includes('Banner')) {
            setInstitutionBannerUrl(reader.result);
          } else if (title.includes('Partner Logo')) {
            setInstitutionLogoUrl(reader.result);
          } else if (title.includes('Certificate Logo')) {
            setCertificatelogoUrl(reader.result);
          }
        };

        reader.readAsDataURL(media);
      }
    }
    if (title.includes('Thumbnail')) {
      setCourseThumbNailUrlFile(media);
    } else if (title.includes('Banner')) {
      setInstitutionBannerUrlFile(media);
    } else if (title.includes('Partner Logo')) {
      setInstitutionLogoUrlFile(media);
    } else if (title.includes('Certificate Logo')) {
      setCertificatelogoUrlFile(media);
    }
  };

  return (
    <Box>
      <Box direction={isMobile(size) ? 'column' : 'row'} gap="medium" justify="between" wrap={true}>
        <Box>
          <Paragraph style={{ fontWeight: 600 }}>{title}</Paragraph>
          <Text
            size="small"
            style={{ width: isMobile(size) ? '100%' : '350px' }}
            margin={{ bottom: 'small' }}>
            {description}
          </Text>
          <Box margin={{ bottom: 'small' }}>
            <FileUpload
              getMedia={getMedia}
              mediaType={mediaType}
              setCourseThumbNail={setCourseThumbNailUrl}
              setCourseBanner={setInstitutionBannerUrl}
              setCoursePromotionalVideo={setCoursePromotionalVideoUrl}
            />
          </Box>
        </Box>
        {
          {
            image: (
              <Box
                flex="grow"
                style={{
                  minHeight: 200,
                  backgroundImage: `url(${
                    media
                      ? media
                      : courseThumbNailUrl
                      ? courseThumbNailUrl
                      : institutionBannerUrl
                      ? institutionBannerUrl
                      : institutionLogoUrl
                      ? institutionLogoUrl
                      : certificatelogoUrl
                      ? certificatelogoUrl
                      : 'https://via.placeholder.com/300x300?text=No+Preview+Image'
                  })`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
                width={isMobile(size) ? 'fill' : 'small'}></Box>
            )
          }[mediaType]
        }
      </Box>
    </Box>
  );
};

export default InstitutionMediaUpload;
