import { Box, Heading, Paragraph } from 'grommet';
import React from 'react';

const PartnersDetailCard = ({ details, detailsExtra }) => {
  return (
    <Box fill margin={{ top: 'medium' }} pad="large">
      <Heading level="3" margin="none">
        SkillDorm Partnership Program
      </Heading>
      <Paragraph margin={{ top: 'none' }} fill style={{ fontSize: 'medium', fontWeight: '800' }}>
        Learners worldwide, representing every country
      </Paragraph>
      <Paragraph fill>{details}</Paragraph>
      <Paragraph margin={{ top: 'xxsmall' }} fill>
        {detailsExtra}
      </Paragraph>
    </Box>
  );
};

export default PartnersDetailCard;
