import React, { useEffect, useState } from 'react';
import MainLayout from 'components/MainLayout';
import { Card, Col, Row, Typography, Select } from 'antd';
import styled from 'styled-components';
import CustomButton from 'components/common/CustomButton';
import RevenuePerPartnerTable from 'components/tables/RevenuePerPartnerTable';
import { useQuery } from 'react-query';
import { revenuePerPartner } from 'api/queries/Report';
import PageLoader from 'components/common/PageLoader';
import { getPartners } from 'api/queries/Institution';
import AppData from 'AppData';

const RevenuePerPartner = () => {
  const { Text } = Typography;
  const { Option } = Select;
  const [value, setValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [toggleExport, setToggleExport] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const { isLoading, data: result } = useQuery(
    [
      'revenuePerPartner',
      value,
      toggleExport ? 1 : currentPage,
      toggleExport ? AppData.infinitePage : pageSize,
      toggleExport
    ],
    revenuePerPartner
  );

  const { data: partners } = useQuery(['searchPartners', '', 0, 1, 10000], getPartners);

  useEffect(() => {
    if (partners) {
      const rivetId = partners?.data.find(e => e?.name?.toLowerCase()?.includes('rivet')).id;
      setValue(rivetId);
    }
  }, [partners]);

  useEffect(() => {
    if (result && toggleExport && !isLoading) {
      base64Download(result?.file);
      setToggleExport(false);
    }
  }, [result, isLoading, toggleExport]);

  const base64Download = (base64Url, fileName = 'RevenuePerPartner.xlsx') => {
    const a = document.createElement('a');
    console.log('inside', base64Url);
    document.body.appendChild(a);
    a.href = `data:application/octet-stream;base64,${base64Url}`;
    a.download = fileName;
    a.click();
  };

  const handleSelect = option => {
    console.log(option);
    setValue(option);
  };

  return (
    <MainLayout isAdmin sidebar padded={1} selectedKey="revenuePerPartner">
      <Card>
        <HeaderRow gutter={[16, 16]}>
          <Col sm={{ span: 16 }} md={{ span: 10 }} xs={{ span: 24 }}>
            <Title level={2}>Platform Revenue</Title>
            <Text>Discover your overall course Revenue and Analytics</Text>
          </Col>
          <CenterCol sm={{ span: 16 }} md={{ span: 8 }} xs={{ span: 24 }}>
            <SelectInput placeholder="All Partners" size="large" onChange={handleSelect}>
              {partners &&
                partners.data.map(data => {
                  return (
                    <Option key={data.id} value={data.id}>
                      {data.name}
                    </Option>
                  );
                })}
            </SelectInput>
          </CenterCol>
          <ExportCol sm={{ span: 16 }} md={{ span: 6 }} xs={{ span: 24 }}>
            <CustomButton
              onClick={() => {
                setToggleExport(true);
              }}
              bgColor="#4d4d53"
              color="#fff"
              size="large">
              Export
            </CustomButton>
          </ExportCol>
        </HeaderRow>
        {result && (
          <RevenuePerPartnerTable
            result={result}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            toggleExport={toggleExport}
          />
        )}
      </Card>
      <PageLoader fullWidth={isLoading} fullHeight={isLoading} isLoading={isLoading} />
    </MainLayout>
  );
};

const Title = styled(Typography.Title)`
  margin-bottom: 0px !important;
`;

const CenterCol = styled(Col)`
  display: flex;
  margin: auto;
`;

const ExportCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const SelectInput = styled(Select)`
  width: 70%;
`;
const HeaderRow = styled(Row)`
  margin-bottom: 20px;
`;

export default RevenuePerPartner;
