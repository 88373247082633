import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, Layer, Text } from 'grommet';
import CourseModule from 'components/utils/CourseBuilder/CourseModule';
import AddCourseItem from 'components/utils/CourseBuilder/AddCourseItem';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { courseBuilder } from 'api/mutations/Course';
import { showToast } from 'store/actions/toast';
import AppData from 'AppData';
import { useHistory } from 'react-router-dom';
import { dashboard, previewcourse } from 'adminRoutes';
import usePermission from 'components/hooks/usePermission';
import { checkIfCourseHasAsset } from 'utils';
import { courseBuilderAction, courseStepNo } from 'store/actions/course';

const CourseBuilder = ({
  isReview = false,
  handlePreview,
  previewClicked,
  setPreviewClicked,
  setCurrent,
  uploads,
  setUploads,
  defaultCourseValue,
  setShowRejectionModal,
  courseApprovalHandler,
  setIsSubmitting
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { checkUserPermission } = usePermission();
  const { courseId, isPaid, approvalStatus, hasSurvey } = useSelector(state => state.course);
  const [showBackModal, setShowBackModal] = useState(false);
  const [courseDragData, setCourseDragData] = useState({
    columnId: uuidv4(),
    courseModules: []
  });
  const [dragContentSourceIndex, setDragContentSourceIndex] = useState();

  const courseBuilderData = useMemo(
    () => ({
      courseModules: courseDragData.courseModules.map(({ title, items, courseModuleId }) => ({
        title,
        courseModuleId: courseModuleId || 0,
        items: items.map(({ title, courseModuleItemAssets, courseModuleItemId }) => ({
          title,
          courseModuleItemId: courseModuleItemId || 0,
          courseModuleItemAssets: courseModuleItemAssets.map(
            ({ assetType, url, guid, quiz, courseModuleItemAssetId, courseAssetId }) => ({
              courseModuleItemAssetId: courseModuleItemAssetId || 0,
              courseAssetId: courseAssetId || 0,
              assetType,
              url,
              guid,
              quiz
            })
          )
        }))
      }))
    }),
    [courseDragData]
  );

  useEffect(() => {
    if (defaultCourseValue) {
      setCourseDragData(state => {
        const newState = { ...state };
        newState.courseModules = defaultCourseValue.courseModules.map(
          ({ items, title, courseModuleId }) => ({
            title,
            courseModuleId,
            id: uuidv4(),
            items: items.map(({ courseModuleItemAssets, title, courseModuleItemId }) => ({
              title,
              courseModuleItemId,
              id: uuidv4(),
              courseModuleItemAssets: courseModuleItemAssets.map(
                ({ assetType, guid, url, quiz, courseModuleItemAssetId, courseAssetId }) => ({
                  id: uuidv4(),
                  type: AppData.assetType[assetType],
                  courseModuleItemAssetId,
                  courseAssetId,
                  assetType,
                  guid,
                  url,
                  quiz
                })
              )
            }))
          })
        );
        return newState;
      });
    }
  }, [defaultCourseValue]);

  const moduleDragCallback = (source, destination, modules) => {
    const newModules = [...modules];
    const module = newModules[source.index];
    newModules.splice(source.index, 1);
    newModules.splice(destination.index, 0, module);
    setCourseDragData(state => {
      const newState = {
        ...state,
        courseModules: newModules
      };
      return newState;
    });
  };

  const sectionDragCallback = (source, destination, module) => {
    const newSections = [...module.items];
    const section = newSections[source.index];
    newSections.splice(source.index, 1);
    newSections.splice(destination.index, 0, section);
    setCourseDragData(state => {
      const newState = { ...state };
      newState.courseModules[newState.courseModules.findIndex(m => m.id === module.id)] = {
        ...module,
        items: newSections
      };
      return newState;
    });
  };

  const contentDragCallback = (source, destination, section) => {
    const newContents = [...section.courseModuleItemAssets];
    const content = newContents[source.index];
    setDragContentSourceIndex(source.index);
    newContents.splice(source.index, 1);
    newContents.splice(destination.index, 0, content);
    setCourseDragData(state => {
      const newState = { ...state };
      const moduleIndex = newState.courseModules.findIndex(m =>
        m.items.some(s => s.id === section.id)
      );
      newState.courseModules[moduleIndex].items[
        newState.courseModules[moduleIndex].items.findIndex(s => s.id === section.id)
      ] = {
        ...section,
        courseModuleItemAssets: newContents
      };
      return newState;
    });
  };

  const onDragEnd = ({ destination, source, type }) => {
    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    if (type.includes('module')) {
      moduleDragCallback(source, destination, courseDragData.courseModules);
    } else if (type.includes('section')) {
      sectionDragCallback(
        source,
        destination,
        courseDragData.courseModules[
          courseDragData.courseModules.findIndex(m => m.id === source.droppableId)
        ]
      );
    } else if (type.includes('content')) {
      const moduleIndex = courseDragData.courseModules.findIndex(m =>
        m.items.some(s => s.id === source.droppableId)
      );
      contentDragCallback(
        source,
        destination,
        courseDragData.courseModules[moduleIndex].items[
          courseDragData.courseModules[moduleIndex].items.findIndex(
            s => s.id === source.droppableId
          )
        ]
      );
    }
  };

  useEffect(() => {
    if (previewClicked) {
      setPreviewClicked(false);
      if (!canShowActionButton) {
        dispatch(
          showToast(
            'error',
            'Cannot preview course because one or more module/section have no content'
          )
        );
        return;
      }

      if (uploads.includes(true)) {
        dispatch(showToast('warning', 'One or more content is still uploading'));
        return;
      }

      handlePreview(courseBuilderData, AppData, previewcourse, courseBuilder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewClicked]);

  const checkIfMissingTitle = data => {
    return !data.courseModules.every(module => {
      if (!module.title) return false;
      return module.items.every(section => {
        if (!section.title) return false;
        return true;
      });
    });
  };

  const courseBuilderCallback = async saveDraft => {
    if (checkIfMissingTitle(courseDragData))
      return dispatch(showToast('warning', 'Module or section is missing a title'));

    if (uploads.includes(true))
      return dispatch(showToast('warning', 'One or more content is still uploading'));

    try {
      const payload = {
        ...courseBuilderData,
        stepNo: AppData.courseBuilder,
        courseId
      };

      if (isReview) {
        if (!(isPaid || hasSurvey)) {
          return courseApprovalHandler(AppData.courseApproval);
        }
        return dispatch(courseStepNo({ stepNo: hasSurvey ? 4 : 5 }));
      }

      setIsSubmitting(true);
      const data = await courseBuilder({
        payload,
        saveDraft,
        publish: isPaid && hasSurvey ? false : !saveDraft
      });
      if (data.success) {
        dispatch(courseBuilderAction({ ...payload }));
        if (!isReview) {
          dispatch(
            showToast(
              'success',
              saveDraft
                ? 'Course curriculum saved as draft'
                : isPaid || hasSurvey
                ? 'Course curriculum completed'
                : 'Course curriculum submitted for review'
            )
          );
          if (!saveDraft && !(isPaid || hasSurvey)) history.push(dashboard);
        } else if (isPaid || hasSurvey) {
          return courseApprovalHandler(AppData.courseApproval);
        }
        if (!saveDraft && (isPaid || hasSurvey))
          setCurrent(state => (hasSurvey ? state + 1 : state + 2));
      } else {
        dispatch(showToast('error', data.description));
      }
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
    }
  };

  const addCourseItemHandler = () => {
    setCourseDragData(state => {
      const courseModules = [...state.courseModules];
      courseModules.push({ id: uuidv4(), title: '', items: [] });
      const newState = {
        ...state,
        courseModules
      };
      return newState;
    });
  };

  const canShowActionButton = useMemo(() => checkIfCourseHasAsset(courseDragData), [
    courseDragData
  ]);

  return (
    <>
      <Box gap="medium">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={courseDragData.columnId} type="module">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <Box gap="medium">
                  {courseDragData.courseModules.length > 0 &&
                    courseDragData.courseModules.map((courseModule, index) => (
                      <CourseModule
                        key={courseModule.id}
                        moduleId={courseModule.id}
                        setCourseDragData={setCourseDragData}
                        dragContentSourceIndex={dragContentSourceIndex}
                        moduleIndex={index}
                        courseModule={courseModule}
                        setUploads={setUploads}
                        isReview={isReview}
                      />
                    ))}
                </Box>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {!isReview && <AddCourseItem itemType="module" onClick={addCourseItemHandler} />}
      </Box>
      <Box direction="row" gap="medium" justify="between" margin={{ top: 'medium' }}>
        <Button
          secondary
          label="Back"
          onClick={() => {
            if (isReview) return dispatch(courseStepNo({ stepNo: 2 }));
            setShowBackModal(true);
          }}
        />
        <Box direction="row" gap="medium" justify="end">
          {isReview &&
          (isPaid
            ? true
            : checkUserPermission(AppData.permissions.manageCourseQA) && approvalStatus === 4
            ? false
            : checkUserPermission(AppData.permissions.approvecourse) && approvalStatus === 1
            ? false
            : true) ? null : (
            <Button
              secondary
              label={isReview ? 'Reject' : 'Save Draft'}
              disabled={!canShowActionButton || uploads.includes(true)}
              onClick={() => {
                if (isReview) setShowRejectionModal(true);
                else {
                  courseBuilderCallback(true);
                }
              }}
            />
          )}
          {isReview &&
          (checkUserPermission(AppData.permissions.manageCourseQA) && approvalStatus === 4
            ? false
            : checkUserPermission(AppData.permissions.approvecourse) && approvalStatus === 1
            ? false
            : approvalStatus === 1 || approvalStatus === 4
            ? false
            : true) &&
          !(isPaid || hasSurvey) ? (
            approvalStatus === 2 ? (
              <Text>This course has been approved</Text>
            ) : approvalStatus === 3 ? (
              <Text>This course has been rejected</Text>
            ) : null
          ) : (
            <Button
              primary
              label={isPaid || hasSurvey ? 'Continue' : isReview ? 'Approve' : 'Submit for Review'}
              disabled={!canShowActionButton || uploads.includes(true)}
              onClick={() => {
                courseBuilderCallback(false);
              }}
            />
          )}
        </Box>
      </Box>
      <Box>
        {showBackModal && (
          <Layer
            onEsc={() => setShowBackModal(false)}
            onClickOutside={() => setShowBackModal(false)}>
            <Box pad="large">
              <Text size="large" textAlign="center" margin={{ bottom: 'medium' }}>
                You will loose your progress, are you sure you want to go back?
              </Text>
              <Box direction="row" justify="center" gap="medium">
                <Button label="Yes" primary onClick={() => setCurrent(state => state - 1)} />
                <Button label="No" onClick={() => setShowBackModal(false)} />
              </Box>
            </Box>
          </Layer>
        )}
      </Box>
    </>
  );
};

export default CourseBuilder;
