import Axios from 'axios';

const service = 'ContentLibraryBE';

export const downloadGoogleDriveFileMultiple = async ({ userName, fileIds }) => {
  try {
    const {
      data
    } = await Axios.get(
      `/${service}/api/FileManager/DownLoadGoogleDriveFileMultiple?userName=${userName}&fileIds=${fileIds}`,
      { baseURL: window['configs'].contentLibraryBaseURL }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllFilesByOwnerId = async (key, userName) => {
  try {
    const { data } = await Axios.get(
      `/${service}/api/FileManager/GetAllFilesByOwnerId?ownerId=${userName}`,
      {
        baseURL: window['configs'].contentLibraryBaseURL
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};
