import { createCourseCategory, updateCourseCategory } from 'api/mutations/Course';
import Modal from 'components/common/Modal';
import { Box, TextInput } from 'grommet';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showToast } from 'store/actions/toast';

const ManageCourseCategoryModal = ({
  onClose,
  setLoading,
  refetchCourseCategoryHandler,
  selectedCourseCategoryId,
  selectedCourseCategoryName,
  selectedCourseCategoryCode
}) => {
  const [courseCategoryName, setCourseCategoryName] = useState(selectedCourseCategoryName || '');
  const [courseCategoryCode, setCourseCategoryCode] = useState(selectedCourseCategoryCode || '');
  const dispatch = useDispatch();

  const createUpdateCourseCategoryHandler = async () => {
    try {
      setLoading(true);
      if (selectedCourseCategoryId) {
        const payload = {
          id: selectedCourseCategoryId,
          name: selectedCourseCategoryName === courseCategoryName ? null : courseCategoryName,
          code: selectedCourseCategoryCode === courseCategoryCode ? null : courseCategoryCode
        };
        const data = await updateCourseCategory(payload);
        if (data.success) {
          dispatch(showToast('success', data.description));
          refetchCourseCategoryHandler();
          onClose();
        } else {
          dispatch(showToast('error', data.description));
        }
        return;
      }

      const data = await createCourseCategory(courseCategoryName, courseCategoryCode);
      if (data.success) {
        dispatch(showToast('success', data.description));
        refetchCourseCategoryHandler();
        onClose();
      } else {
        dispatch(showToast('error', data.description));
      }
    } catch (error) {
      console.log(error);
      dispatch(
        showToast(
          'error',
          selectedCourseCategoryId
            ? 'Failed to update course category'
            : 'Failed to create course category'
        )
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      width="450px"
      onClose={onClose}
      title={selectedCourseCategoryId ? 'Edit Course Category' : 'Create Course Category'}
      footerCustomButtonDisabled={!courseCategoryName || !courseCategoryCode}
      footerCustomButtonLabel="Save"
      footerCustomButtonHandler={createUpdateCourseCategoryHandler}>
      <Box gap="medium">
        <TextInput
          placeholder="Enter Category Name"
          value={courseCategoryName}
          onChange={e => setCourseCategoryName(e.target.value)}
        />
        <TextInput
          placeholder="Enter Category Code"
          value={courseCategoryCode}
          onChange={e => setCourseCategoryCode(e.target.value)}
        />
      </Box>
    </Modal>
  );
};

export default ManageCourseCategoryModal;
