import React from 'react';
import styled from 'styled-components';
import { Row, Col, Typography, Button, Form, Input, Select, Switch, DatePicker } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from 'react-query';
import PageLoader from 'components/common/PageLoader';
import { useDispatch } from 'react-redux';
import { showToast } from 'store/actions/toast';
import { createDiscount } from 'api/mutations/Discount';
import { search } from 'api/mutations/Institution';
import { getCourses } from 'api/mutations/Course';
import { blockInvalidChar } from 'utils/blockInvalidChar';

const DiscountForm = ({
  discountType,
  handleSwitch,
  checkBoxAllCourses,
  handleCourseSwitch,
  cancel,
  refetch
}) => {
  const dispatch = useDispatch();
  const [showUsageLimit, setShowUsageLimit] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [selectedInstitutionId, setSelectedInstitutionId] = React.useState(0);
  const { Paragraph } = Typography;

  const { data: institutions } = useQuery(['institutions'], search);

  const { data: courses } = useQuery(['courses', selectedInstitutionId], getCourses);

  const [discountChargeTypeValue, setDiscountChargeTypeValue] = React.useState(0);
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const fileRef = React.useRef();

  function handleDiscountChargeChange(value) {
    setDiscountChargeTypeValue(value);
  }

  function handlePercentageChange(value) {
    if (discountChargeTypeValue === '2') {
      if (value > 100) {
        dispatch(showToast('warning', 'percentage can not be greater than 100.'));
        return;
      }
    }
  }

  function handleChange(value) {
    setSelectedInstitutionId(value);
  }

  const [createDiscountMutate] = useMutation(createDiscount, {
    onSuccess: data => {
      setIsSubmitting(false);
      if (data.success) {
        dispatch(showToast('success', 'Discount created successfully'));
        cancel();
        refetch();
      } else {
        dispatch(showToast('error', data.description));
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        dispatch(
          showToast(
            'error',
            <>
              We couldn't connect to the server.
              <br />
              Check your network or contact your admin
            </>
          )
        );
      } else {
        dispatch(showToast('error', error.message));
      }
    }
  });

  const handleFormSubmit = async ({
    name,
    description,
    discountChargeType,
    institutionType,
    startDate,
    endDate,
    allcoursesDiscount,
    discountType,
    amount,
    code,
    setUsage,
    usage,
    courseId
  }) => {
    try {
      const data = {
        name: name,
        description: description,
        discountChargeType: Number(discountChargeType),
        institutionType: institutionType,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        allCoursesDiscount: allcoursesDiscount,
        discountType: discountType ? 1 : 2,
        amount: amount,
        code: code,
        setUsage: setUsage,
        usage: setUsage ? Number(usage) : 0,
        courseId: courseId,
        file: uploadedFile?.length > 0 ? uploadedFile[0] : null
      };
      if (endDate < startDate) {
        dispatch(showToast('warning', 'Discount end date can not be less than start date.'));
        return;
      }
      if (discountChargeTypeValue == 2) {
        if (amount > 100) {
          dispatch(showToast('warning', 'percentage can not be greater than 100.'));
          return;
        }
      }
      setIsSubmitting(true);
      await createDiscountMutate(data);
    } catch (error) {
      console.log('err', error);
    }
  };

  return (
    <Container
      labelCol={{
        span: 4
      }}
      wrapperCol={{
        span: 14
      }}
      layout="horizontal">
      <Form
        initialValues={{ discountType: true, allcoursesDiscount: true, setUsage: true }}
        onFinish={handleFormSubmit}>
        <FormContainer>
          <Form.Item>
            <FormHeader>Create Discount</FormHeader>
          </Form.Item>

          <Form.Item
            label="Discount Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter discount name'
              }
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please enter discount description'
              }
            ]}>
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label="Discount Type"
            name="discountType"
            rules={[
              {
                required: true,
                message: 'Please enter discount type'
              }
            ]}>
            <Switch
              checkedChildren="Default"
              unCheckedChildren="Advanced"
              onChange={handleSwitch}
              defaultChecked
            />
          </Form.Item>

          <Form.Item
            label="Select Partner"
            name="institutionType"
            rules={[
              {
                required: true,
                message: 'Please select partner'
              }
            ]}>
            <Select placeholder="All Partners" onChange={handleChange}>
              {institutions
                ?.filter(i => i.isActive === true)
                ?.map(item => (
                  <Select.Option value={item.id}>{item.name}</Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Start Date"
            name="startDate"
            rules={[
              {
                required: true,
                message: 'Please enter discount start date'
              }
            ]}>
            <DatePicker />
          </Form.Item>

          <Form.Item
            label="End Date"
            name="endDate"
            rules={[
              {
                required: true,
                message: 'Please enter discount start date'
              }
            ]}>
            <DatePicker />
          </Form.Item>

          <Form.Item
            label="Select Courses"
            name="allcoursesDiscount"
            rules={[
              {
                required: true,
                message: 'Please select all courses discount'
              }
            ]}>
            <Switch
              checkedChildren="Apply discount to all courses for the selected partner"
              unCheckedChildren="Selected Courses Only"
              onChange={handleCourseSwitch}
              defaultChecked={true}
            />
          </Form.Item>
          {!checkBoxAllCourses ? (
            <>
              <Form.Item label="Courses" name="courseId">
                <Select mode="multiple" style={{ width: '100%' }} placeholder="Please select">
                  {courses?.data &&
                    courses?.data?.map(item => (
                      <Select.Option key={item.courseId}>{item.title}</Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </>
          ) : null}

          <Form.Item
            label="Discount Charge Type"
            name="discountChargeType"
            rules={[
              {
                required: true,
                message: 'Please select discount charge type'
              }
            ]}>
            <Select placeholder="Select" onChange={handleDiscountChargeChange}>
              <Select.Option value="1">Flat Amount</Select.Option>
              <Select.Option value="2">Percentage</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Discount Value"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please enter discount value'
              }
            ]}>
            <Input
              type="number"
              min="0"
              onKeyDown={blockInvalidChar}
              onChange={e => handlePercentageChange(e.target.value)}
              maxlength={discountChargeTypeValue == 1 ? 50 : 3}
              max={discountChargeTypeValue == 1 ? 99999999999999999999 : 100}
            />
          </Form.Item>

          <Form.Item
            label="Set Usage Limit"
            name="setUsage"
            rules={[
              {
                required: true,
                message: 'Please set usage limit'
              }
            ]}>
            <Switch
              checkedChildren="True"
              unCheckedChildren="False"
              defaultChecked={true}
              onChange={value => setShowUsageLimit(value)}
            />
          </Form.Item>

          {showUsageLimit && (
            <Form.Item
              label="Usage Limit"
              name="usage"
              rules={[
                {
                  required: true,
                  message: 'Please enter usage limit'
                }
              ]}>
              <Input type="number" min="0" />
            </Form.Item>
          )}

          {discountType ? (
            <>
              <Form.Item
                label="Discount Code"
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Please enter discount code'
                  }
                ]}>
                <Input />
              </Form.Item>
            </>
          ) : (
            <UploadDiv>
              <Col span={10}>
                <UploadHeader>Bulk Upload Unique Discount Code</UploadHeader>
                <UploadText>
                  Upload your unique ocument code and/or membership id for authentication and
                  validation here. It must meet our course count quality standard template to be
                  accepted. Important guidelines: .xlsx, .pdf, .docx, or .png no text on the image
                </UploadText>
              </Col>
              <Col span={10} offset={4}>
                <input type="file" ref={fileRef} onChange={e => setUploadedFile(e.target.files)} />
                <Paragraph style={{ marginBottom: 0, marginRight: 15 }}>
                  <strong>
                    {fileRef && fileRef.current?.files ? fileRef.current.files.length : 'No'}
                    {fileRef && fileRef.current?.files && fileRef.current?.files.length > 1
                      ? ' files added'
                      : ' file added'}
                  </strong>
                </Paragraph>
                <Typography.Paragraph style={{ marginTop: '.7em' }}>
                  <a
                    href="https://sdapi-dev.skilldorm.com/Discount/Templates/DiscountCode.xlsx"
                    download>
                    Download Template here&nbsp;&nbsp;
                  </a>
                  <DownloadOutlined />
                </Typography.Paragraph>
              </Col>
            </UploadDiv>
          )}
        </FormContainer>
        <ButtonDiv>
          <CancelBtn onClick={cancel}>Cancel</CancelBtn>
          <SaveBtn htmlType="submit">Save Discount</SaveBtn>
        </ButtonDiv>
      </Form>
      <PageLoader isLoading={isSubmitting} />
    </Container>
  );
};

export default DiscountForm;

const Container = styled(Form)`
  width: 65%;
  min-height: 30em;
  margin: auto;
`;

const FormContainer = styled.div`
  border: 1px solid #dfdfdf;
  width: 100%;
  min-height: 10em;
  margin: auto;
  margin-top: 2em;
  padding: 1.5em 2em;
`;
const UploadDiv = styled(Row)`
  width: 90%;
  margin: auto;
  margin-left: -0.1em;
  margin-top: 2em;
`;
const UploadHeader = styled(Typography.Paragraph)`
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0.2em !important;
`;
const UploadText = styled(Typography.Paragraph)`
  font-size: 0.9em;
  text-align: justify;
`;

const UploadBtn = styled(Button)`
  color: #fff;
  background: #173253;
  border: 1px solid #173253;
  border-radius: 2px;
`;

const ButtonDiv = styled.div`
  float: right;
  margin-top: 2em;
`;

const FormHeader = styled(Typography.Paragraph)`
  font-size: 1.8em;
  color: #afafaf;
  font-weight: 1000;
  margin-bottom: 10px !important;
`;
const CancelBtn = styled(Button)`
  border: none;
  background: #7f7f7f;
  color: #fff;
  width: 7em;
`;
const SaveBtn = styled(Button)`
  border: none;
  background: #2c7be5;
  color: #fff;
  margin-left: 1.2em;
`;
