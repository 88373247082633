import React from 'react';
import { Box, Form, FormField, TextInput, Text, ResponsiveContext } from 'grommet';
import { animate, isMobile } from 'utils';
import { message, Button } from 'antd';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { forgetPassword } from 'api/mutations/Authentication';
import { login } from 'userRoutes';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import buildJson from 'configs/json/build.json';
import AppData from 'AppData';

const ForgetPasswordForm = ({ setSuccessMessage, successMessage }) => {
  const { register, errors, handleSubmit } = useForm();
  const history = useHistory();
  const size = React.useContext(ResponsiveContext);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [resetError, setResetError] = React.useState(null);
  const [formMessage] = React.useState(
    buildJson.build === AppData.userBuildType ? (
      <>Put in your email to reset your password</>
    ) : (
      <>Put in your username to reset your password</>
    )
  );
  // const [successMessage, setSuccessMessage] = React.useState(null);
  const [formMessageAnimate] = React.useState([
    animate({ type: 'fadeIn', delay: 500, duration: 500 }),
    animate({ type: 'slideUp', delay: 500, duration: 500 })
  ]);
  const [resetMutate] = useMutation(forgetPassword, {
    onSuccess: data => {
      const { description, success } = data;
      if (success) {
        setSuccessMessage(description);
      } else {
        setResetError(description);
      }
      setIsSubmitting(false);
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        message.error("We couldn't connect to the server.");
        setResetError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        setResetError(error.message);
      }
    }
  });

  const onSubmit = async ({ userName }) => {
    if (isSubmitting) return;

    try {
      setResetError(null);
      setIsSubmitting(true);
      await resetMutate({
        userName,
        student: buildJson.build === AppData.userBuildType ? true : false
      });
    } catch (err) {
      console.log('error', err);
    }
  };

  return (
    <>
      {!resetError && (
        <Box margin={{ bottom: 'small' }} animation={formMessageAnimate[0]}>
          <Box animation={formMessageAnimate[1]}>
            <Text color={successMessage ? 'neutral-1' : null} textAlign="center">
              {successMessage || formMessage}
            </Text>
          </Box>
        </Box>
      )}
      {resetError && (
        <Box margin={{ bottom: 'small' }} animation={formMessageAnimate[0]}>
          <Box animation={formMessageAnimate[1]}>
            <Text color="status-critical" textAlign="center">
              {resetError}
            </Text>
          </Box>
        </Box>
      )}
      <Form
        style={isMobile(size) ? { width: 250 } : { width: 300 }}
        onSubmit={handleSubmit(onSubmit)}>
        <Box margin={{ bottom: 'small' }}>
          <FormField name="userName" htmlfor="username-id">
            <TextInput
              id="username-id"
              name="userName"
              placeholder={buildJson.build === AppData.userBuildType ? 'Email' : 'Username'}
              ref={register({ required: 'Username is required' })}
            />
          </FormField>
          {errors.userName && (
            <Text color="status-critical" size="small">
              {errors.userName.message}
            </Text>
          )}
        </Box>
        <Button size="large" block htmlType="submit" type="primary" loading={isSubmitting}>
          Reset Password
        </Button>
        {buildJson.build === AppData.userBuildType && (
          <CustomButton type="link" block onClick={() => history.push(login)} size="large">
            Back to Login
          </CustomButton>
        )}
      </Form>
    </>
  );
};

const CustomButton = styled(Button)`
  margin-top: 10px;
`;

export default ForgetPasswordForm;
