import React from 'react';
import { Col, Row, Carousel } from 'antd';
import styled from 'styled-components';
import IcsanLogo from 'assets/images/partners logo/icsan.png';
import CibnLogo from 'assets/images/partners logo/cibn.png';
import NimnLogo from 'assets/images/partners logo/nimn.png';
import CipmLogo from 'assets/images/partners logo/cipm.png';
import UnilagLogo from 'assets/images/partners logo/unilag.png';
import device from 'configs/responsive/mediaQueries';
import { useMediaQuery } from 'react-responsive';

const { mobile } = device;

const Partners = props => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  return (
    <Carousel autoplay>
      <PartnersContainer gutter={16}>
        <PartnersCol xs={{ span: 12 }} lg={{ span: 6 }}>
          <Img src={IcsanLogo} width={isMobile && '150px'} height={isMobile && '90px'} />
        </PartnersCol>
        <PartnersCol xs={{ span: 12 }} lg={{ span: 6 }}>
          <Img src={CibnLogo} width={isMobile && '150px'} height={isMobile && '90px'} />
        </PartnersCol>
        <PartnersCol xs={{ span: 12 }} lg={{ span: 6 }}>
          <Img src={NimnLogo} width={isMobile && '115px'} height={isMobile && '90px'} />
        </PartnersCol>
        <PartnersCol xs={{ span: 12 }} lg={{ span: 6 }}>
          <Img src={CipmLogo} width={isMobile && '120px'} height={isMobile && '100px'} />
        </PartnersCol>
      </PartnersContainer>
      <PartnersContainer gutter={16}>
        <PartnersCol xs={{ span: 12 }} lg={{ span: 6 }}>
          <Img src={IcsanLogo} width={isMobile && '150px'} height={isMobile && '90px'} />
        </PartnersCol>
        <PartnersCol xs={{ span: 12 }} lg={{ span: 6 }}>
          <Img src={CibnLogo} width={isMobile && '150px'} height={isMobile && '90px'} />
        </PartnersCol>
        <PartnersCol xs={{ span: 12 }} lg={{ span: 6 }}>
          <Img src={NimnLogo} width={isMobile && '120px'} height={isMobile && '90px'} />
        </PartnersCol>
        <PartnersCol xs={{ span: 12 }} lg={{ span: 6 }}>
          <Img src={CipmLogo} width={isMobile && '115px'} height={isMobile && '100px'} />
        </PartnersCol>
      </PartnersContainer>
    </Carousel>
  );
};

export default Partners;

const PartnersContainer = styled(Row)`
  width: 85% !important;
  height: 10rem;
  margin: auto !important;
  display: flex !important;
  @media ${mobile} {
    margin-top: 7rem !important;
    height: 15rem;
  }
`;
const PartnersCol = styled(Col)`
  padding: 0 0.5rem 0rem 0.5rem;
`;

const Img = styled.img``;

// cons= styled.div`
//   height: 100%;
//   width: 80%;
//   background: url(${props => props.logo}) no-repeat center center/cover;
// `;
