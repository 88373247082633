import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Typography, Select, message } from 'antd';
import styled from 'styled-components';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import Facebook from 'assets/svgs/facebookIcon.svg';
import Google from 'assets/svgs/google-symbol.svg';
import Linkedin from 'assets/svgs/linkedin.svg';
import { useMutation, useQuery } from 'react-query';
import { getCountries } from 'api/queries/Institution';
import { registerStudent } from 'api/mutations/Authentication';
import { signOut } from 'store/actions/auth';
import { home, terms } from 'userRoutes';
import { useDispatch } from 'react-redux';
import { emailRegex } from 'data/regex';

const RegisterForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState();
  const [termsAndConditionChecked, setTermsAndConditionChecked] = useState(false);
  const { data: countries } = useQuery(['countries'], getCountries);
  const { Paragraph } = Typography;
  const { Option } = Select;
  const ageOption = [
    { value: '10-14', name: '10-14' },
    { value: '15-19', name: '15-19' },
    { value: '20-24', name: '20-24' },
    { value: '25-29', name: '25-29' },
    { value: '30-34', name: '30-34' },
    { value: '35-39', name: '35-39' },
    { value: '40-44', name: '40-44' },
    { value: '45-49', name: '45-49' },
    { value: '50-54', name: '50-54' },
    { value: '55-59', name: '55-59' },
    { value: '60-64', name: '60-64' },
    { value: '65-69', name: '65-69' },
    { value: '70-74', name: '70-74' }
  ];

  const [registerStudentMutate] = useMutation(registerStudent, {
    onSuccess: data => {
      if (data.success) {
        message.success('Student created successfully');
        // console.log('student created successfully');
        history.push(home);
        dispatch(signOut());
      } else {
        setErrorMessage(data.description);
      }
    },
    onError: error => {
      if (error.message === 'Network Error') {
        setErrorMessage(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        setErrorMessage(error.message);
      }
    },
    onSettled: () => {
      setIsLoading(false);
    }
  });

  const onFinish = async ({
    firstName,
    password,
    confirmPassword,
    age,
    lastName,
    country,
    email
  }) => {
    if (password !== confirmPassword) {
      setErrorMessage('New password and Confirm password must be the same');
      return;
    }
    if (isLoading) return;
    try {
      setErrorMessage(null);
      setIsLoading(true);
      const data = {
        firstName,
        password,
        lastName,
        age,
        id: 0,
        institutionId: window['configs'].institutionId,
        roles: window['configs'].studentRole,
        email,
        country: countries?.find(item => country === item?.countryName)?.code1
      };
      await registerStudentMutate(data);
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <Form size="large" autoComplete="new-password" className="register-form" onFinish={onFinish}>
      <FormHeader>
        <FormHeaderText level={2}>Sign Up</FormHeaderText>
        <Paragraph strong>
          Have an Account already?
          <Text onClick={() => history.push(home, history.location.pathname)}>Sign in</Text>
        </Paragraph>
      </FormHeader>
      <FormError>{errorMessage}</FormError>
      <Form.Item
        className="margin-bottom-10"
        name="firstName"
        rules={[
          {
            required: true,
            message: 'Please input your Firstname!'
          }
        ]}>
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Firstname" />
      </Form.Item>
      <Form.Item className="margin-bottom-10" name="lastName">
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Lastname" />
      </Form.Item>
      <Form.Item
        className="margin-bottom-10"
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your Email!'
          },
          {
            pattern: emailRegex,
            message: 'Not a valid email address'
          }
        ]}>
        <Input
          type="email"
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Email"
          autoComplete="new-password"
        />
      </Form.Item>
      <Form.Item
        className="margin-bottom-10"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your Password!'
          },
          {
            pattern: new RegExp(
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/
            ),
            message:
              'Password must contain at least 8 characters, an uppercase alphabet,a lower case alphabet, a number and special character'
          }
        ]}>
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
          autoComplete="new-password"
        />
      </Form.Item>
      <Form.Item
        className="margin-bottom-10"
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: 'Please input your Password again!'
          }
        ]}>
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Confirm Password"
          autoComplete="new-password"
        />
      </Form.Item>
      <Form.Item
        className="margin-bottom-10"
        name="age"
        rules={[
          {
            required: true,
            message: 'Please select your age'
          }
        ]}>
        <Select showSearch placeholder="Age">
          {ageOption &&
            ageOption.map((age, i) => (
              <Option key={i} value={age.value}>
                {age.name}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        className="margin-bottom-10"
        name="country"
        rules={[
          {
            required: true,
            message: 'Please select a country'
          }
        ]}>
        <Select autoComplete="new-password" showSearch placeholder="Country">
          {countries &&
            countries.map(country => (
              <Option key={country.id} value={country.countryName}>
                {country.countryName}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item className="margin-bottom-10" name="remember" valuePropName="checked">
        <Checkbox
          checked={termsAndConditionChecked}
          onChange={e => setTermsAndConditionChecked(e.target.checked)}>
          <LinkWrapper target="_blank" rel="noopener noreferrer" to={terms}>
            Terms and Condition
          </LinkWrapper>
        </Checkbox>
      </Form.Item>
      <Form.Item className="margin-bottom-10">
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          className="register-form-button"
          disabled={!termsAndConditionChecked}>
          Get Started
        </Button>
      </Form.Item>
      <FormFooterStyle>
        <FooterLeftLine></FooterLeftLine>
        <FooterText strong>Or Sign Up With</FooterText>
        <FooterRightLine></FooterRightLine>
      </FormFooterStyle>
      <SocialContainer>
        <SocialStyle>
          <a href="https://sdapi-dev.skilldorm.com/Authentication/api/Account/GoogleLogin">
            <ImageWrapper src={Google} alt="" />
          </a>
          <a href="https://sdapi-dev.skilldorm.com/Authentication/api/Account/LinkedInLogin">
            <ImageWrapper src={Linkedin} alt="" />
          </a>
        </SocialStyle>
      </SocialContainer>
    </Form>
  );
};

export default RegisterForm;

const FormHeaderText = styled(Typography.Title)`
  margin-bottom: 3px !important;
`;

const FormError = styled(Typography.Paragraph)`
  color: red;
  margin-bottom: 10px;
  text-align: left;
`;

const Text = styled(Typography.Text)`
  margin-left: 5px;
  cursor: pointer;
  color: #0966b8;
`;

const LinkWrapper = styled(Link)`
  margin-left: 5px;
`;

const FormHeader = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const FormFooterStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px 0px 0px;
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px 0px 0px 0px;
`;

const SocialStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 60%;
  margin: 10px 0px 0px 0px;
`;

const FooterLeftLine = styled.div`
  width: 75px;
  height: 1px;
  background: #bfbfbf;
`;

const FooterText = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
  font-size: 14px;
`;

const FooterRightLine = styled.div`
  width: 75px;
  height: 1px;
  background: #bfbfbf;
`;

const ImageWrapper = styled.img`
  width: 26px;
  height: 26px;
  cursor: pointer;
`;
