import React, { useState } from 'react';
import { updateSignatureAndName, uploadSignatureAndName } from 'api/mutations/Institution';
import FileUpload from 'components/common/FileUpload';
import Modal from 'components/common/Modal';
import { Box, TextInput } from 'grommet';
import { useDispatch } from 'react-redux';
import { showToast } from 'store/actions/toast';

const CreateCertificateModal = ({
  onClose,
  refetchSignature,
  setLoading,
  refetchInstitutionHandler,
  selectedInstitutionId,
  selectedName,
  selectedJobTitle,
  selectedSignatureUrl,
  selectedId
}) => {
  const [certificateName, setCertificateName] = useState(selectedName || '');
  const [jobTitle, setJobTitle] = useState(selectedJobTitle || '');
  const [mediafile, setMediaFile] = useState(selectedSignatureUrl || undefined);
  const dispatch = useDispatch();

  // console.log( typeof mediafile === "string");

  const createUpdateCertificateHandler = async () => {
    try {
      setLoading(true);
      if (selectedId) {
        const payload = {
          id: selectedId,
          name: certificateName,
          jobTitle: jobTitle,
          signature: typeof mediafile === 'string' ? undefined : mediafile
        };
        const data = await updateSignatureAndName(payload);
        if (data.success) {
          dispatch(showToast('success', 'Certificate Signature Updated successfully'));
          refetchSignature();
          onClose();
        } else {
          dispatch(showToast('error', data.description));
        }
        return;
      }

      const payload = {
        institutionId: selectedInstitutionId,
        name: certificateName,
        jobTitle: jobTitle,
        signature: mediafile
      };
      const data = await uploadSignatureAndName(payload);
      if (data.success) {
        dispatch(showToast('success', data.description));
        refetchInstitutionHandler();
        onClose();
      } else {
        dispatch(showToast('error', data.description));
      }
    } catch (error) {
      console.log(error);
      dispatch(
        showToast(
          'error',
          selectedId
            ? 'Failed to update certificate Signature'
            : 'Failed to create certificate Signature'
        )
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      width="450px"
      onClose={onClose}
      title={selectedId ? 'Update Certificate Signature' : 'Create Certificate Signature'}
      footerCustomButtonDisabled={!certificateName || !jobTitle || !mediafile}
      footerCustomButtonLabel={selectedId ? 'Update' : 'Save'}
      footerCustomButtonHandler={createUpdateCertificateHandler}>
      <Box gap="medium">
        <TextInput
          placeholder="Enter Name"
          value={certificateName}
          onChange={e => setCertificateName(e.target.value)}
        />
        <TextInput
          placeholder="Enter Job Title"
          value={jobTitle}
          onChange={e => setJobTitle(e.target.value)}
        />
        <FileUpload color={'#0966b8'} getMedia={setMediaFile} />
      </Box>
    </Modal>
  );
};

export default CreateCertificateModal;
