import React from 'react';
import { Row, Col, Typography } from 'antd';
import styled from 'styled-components';

const BarChart = ({ chartData }) => {
  return (
    <Container>
      <Row gutter={16}>
        {chartData?.map(data => {
          const day = data.key.slice(0, 3);
          return (
            <Bar span={3}>
              <Div style={{ height: `${(parseInt(data.value) + 0.01).toString()}rem` }}></Div>
              <Days>{day}</Days>
            </Bar>
          );
        })}
      </Row>
    </Container>
  );
};

export default BarChart;

const Container = styled.div``;

const Bar = styled(Col)`
  position: relative;
  margin-top: 12%;
`;

const Div = styled.div`
  background: #2c7be5;
  height: 2rem;
  width: 1rem;
  margin: auto;
  margin-bottom: 0.3rem;
  position: absolute;
  bottom: 0;
`;
const Days = styled(Typography.Paragraph)`
  font-size: 0.8em;
  position: absolute;
`;
