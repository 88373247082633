import React from 'react';
import styled from 'styled-components';
import { Typography, Avatar, Image } from 'antd';
import { AiOutlineUser } from 'react-icons/ai';

import device from 'configs/responsive/mediaQueries';
import facilitatorImg from 'assets/images/MrsModupe.png';

const { mobile } = device;

const InstructorTestimonial = () => {
  return (
    <Container>
      <Inner>
        <Heading>Our Instructors shared experiences</Heading>
        <TestimonialDiv>
          <ImgDiv>
            <Avatar size={207} src={<img src={facilitatorImg} />} />
          </ImgDiv>
          <TextDiv>
            <Testimonial>
              It’s been an awesome experience teaching on SkillDorm. I’ve been able to gain a broad
              number of audience within two weeks.
            </Testimonial>
            <Name>Mrs Modupeore Osomo</Name>
            <Title>Facilitator, ICSAN</Title>
          </TextDiv>
        </TestimonialDiv>
      </Inner>
    </Container>
  );
};

export default InstructorTestimonial;

const Container = styled.div`
  display: flex;
  margin: auto;
  padding: 9rem 0;
  @media ${mobile} {
    padding: 5rem 1.5rem;
  }
`;
const Inner = styled.div`
  display: flex;
  width: 70rem;
  margin: auto;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled(Typography.Paragraph)`
  position: relative;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  margin-bottom: 6rem !important;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 76px;
    width: 125px;
    height: 6px;
    background: #0966b8;
  }
  @media ${mobile} {
    text-align: left;
    font-size: 36px;
    line-height: 43.57px;
    margin-bottom: 3.5rem !important;
    &::before {
      top: 96px;
    }
  }
`;

const TestimonialDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
  @media ${mobile} {
    flex-direction: column;
  }
`;
const ImgDiv = styled.div`
  @media ${mobile} {
    order: 2;
  }
`;
const TextDiv = styled.div`
  @media ${mobile} {
    order: 1;
  }
`;
const Testimonial = styled(Typography.Paragraph)`
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  letter-spacing: 0em;
  padding-right: 20rem;
  color: #6c6969;
  /* padding-right: 20rem; */
  @media ${mobile} {
    font-size: 18px;
    line-height: 27px;
  }
`;
const Name = styled(Typography.Text)`
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
`;
const Title = styled(Typography.Paragraph)`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
`;
