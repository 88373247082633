import { Box, Heading } from 'grommet';
import React from 'react';
import styled from 'styled-components';
import Flag from 'assets/svgs/flagpoints.svg';
import { Card } from 'antd';

const Requirements = ({ items, size = '14px' }) => {
  return (
    <CardContainer style={{ background: '#FBFBFB' }}>
      <Heading level="4" margin="none" style={{ fontSize: 24 }}>
        What You will Learn
      </Heading>
      {items}
      {/* <ListContainer size={size}>
        {items?.map(item => (
          <List img={Flag} key={item}>
            {item}
          </List>
        ))}
      </ListContainer> */}
    </CardContainer>
  );
};

export default Requirements;

// const ListContainer = styled.ul`
//   ${'' /* list-style: none;
//   padding: 0; */}
//   ${'' /* padding: 0px 20px; */}
//   font-size: ${({ size }) => size};
// `;
// const List = styled.li`
//   ${'' /* padding-left: 0.7em;
//   display: flex;
//   align-items: center;
//   &:before {
//     content: '';
//     -webkit-mask-image: ${({ img }) => `url(${img})`};
//     -webkit-mask-size: cover;
//     display: inline-block;
//     height: 10px;
//     width: 10px;
//     margin-right: 7px;
//     background-color: #42a6dd;
//   } */}
// `;

const CardContainer = styled(Card)`
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  background-color: #fff;
  color: #333333;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  border: 0.5px solid #e2e2e2;
  box-shadow: 0px 0px 0px rgb(0 0 0 / 20%);
`;
