import Axios from 'axios';

const service = 'Workflow';

export const create = async model => {
  const { data } = await Axios.post(`/${service}/api/Workflow/Create`, model);

  return data;
};

export const update = async model => {
  const { data } = await Axios.put(`/${service}/api/Workflow/Update`, model);

  return data;
};

export const courseApproval = async ({
  courseId,
  comment,
  status,
  courseCategoryId,
  institutiondId
}) => {
  try {
    const { data } = await Axios.post(`/${service}/api/CourseApproval/Course/Approval`, {
      courseId,
      comment,
      status,
      courseCategoryId,
      institutiondId
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const qaCourseApproval = async ({
  courseId,
  comment,
  approvalStatus,
  institutiondId,
  revenueId
}) => {
  try {
    const { data } = await Axios.post(`/${service}/api/CourseApproval/QA/QACourseApproval`, {
      courseId,
      comment,
      approvalStatus,
      institutiondId,
      revenueId
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const searchCourseApproval = async ({
  startDate,
  endDate,
  courseCategoryId,
  search,
  status = 1
}) => {
  try {
    const { data } = await Axios.post(`/${service}/api/CourseApproval/Pending/Search`, {
      startDate,
      endDate,
      courseCategoryId,
      search,
      status
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const deactivateWorkflow = async id => {
  const { data } = await Axios.get(`/${service}/api/Workflow/Deactivate?id=${id}`);
  return data;
};
