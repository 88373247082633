import React from 'react';
import { Layout, Menu } from 'antd';
import PropTypes from 'prop-types';
import { BankOutlined, SettingOutlined } from '@ant-design/icons';

import {
  AuditOutlined,
  UsergroupAddOutlined,
  MoneyCollectOutlined,
  UserAddOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import {
  approvecourse,
  audittrail,
  courseinsight,
  dashboard,
  manageinstitution,
  manageuser,
  managepartners,
  settings,
  discounts,
  revenue,
  managecourse,
  coursepayment,
  courseinsightsoverview,
  revenueperpartner,
  manageregistereduser,
  revenueperinstitution
} from 'adminRoutes';
import { useSelector } from 'react-redux';
import AppData from 'AppData';
import usePermission from 'components/hooks/usePermission';
import useRole from 'components/hooks/useRole';
import {
  AdminDashboardIcon,
  ManageCourseIcon,
  ReportIcon,
  PaymentIcon
} from 'components/customIcon/CustomIcon';

const AdminSidebar = props => {
  const { SubMenu } = Menu;
  const history = useHistory();
  const { permission, role: roles } = useSelector(state => state.auth);
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });
  const { checkUserPermission } = usePermission();
  const { checkUserRole } = useRole();

  const isAdminRole = [AppData.ROLE.SUPERADMIN, AppData.ROLE.RIVETADMIN];
  const isInstitutionRole = AppData.ROLE.INSTITUTIONADMIN;

  return (
    <Wrapper
      className="aside_menu"
      collapsible={!isMobileDevice}
      collapsed={isMobileDevice ? true : props.show}
      onCollapse={() => props.setShow(!props.show)}>
      <div style={{ marginTop: 32 }}></div>
      <Menu
        className="custom_adminmenu"
        theme="dark"
        defaultSelectedKeys={[props.selectedKey]}
        mode="inline">
        <Menu.Item
          key="dashboard"
          onClick={() => {
            history.push(dashboard);
          }}>
          <AdminDashboardIcon />
          <span>Dashboard</span>
        </Menu.Item>
        {checkUserPermission([
          AppData.permissions.approvecourse,
          AppData.permissions.manageCourseQA,
          AppData.permissions.viewCourseQA
        ]) && (
          <Menu.Item
            key="manageCourse"
            onClick={() => {
              history.push(managecourse);
            }}>
            <ManageCourseIcon />
            <span>Manage Course</span>
          </Menu.Item>
        )}
        {checkUserPermission([
          AppData.permissions.approvecourse,
          AppData.permissions.manageCourseQA,
          AppData.permissions.viewCourseQA
        ]) && (
          <Menu.Item
            key="approveCourse"
            onClick={() => {
              history.push(approvecourse);
            }}>
            <AuditOutlined />
            <span>Approve Course</span>
          </Menu.Item>
        )}
        {permission.some(
          perm =>
            perm === AppData.permissions.manageinstitution ||
            perm === AppData.permissions.viewinstitution
        ) ? (
          <Menu.Item
            key="institution"
            onClick={() => {
              history.push(manageinstitution);
            }}>
            <BankOutlined />
            <span>Manage Partner</span>
          </Menu.Item>
        ) : null}
        {permission.some(
          perm => perm === AppData.permissions.manageuser || perm === AppData.permissions.viewuser
        ) ? (
          <Menu.Item
            key="manageUser"
            onClick={() => {
              history.push(manageuser);
            }}>
            <UserAddOutlined />
            <span>Manage User</span>
          </Menu.Item>
        ) : null}
        {checkUserRole([
          AppData.ROLE.SUPERADMIN,
          AppData.ROLE.RIVETADMIN,
          AppData.ROLE.INSTITUTIONADMIN,
          AppData.ROLE.RIVETREPORT,
          AppData.ROLE.INSTITUTIONREPORT
        ]) && (
          <SubMenu key="reports" title="Reports" icon={<ReportIcon />}>
            {checkUserPermission([AppData.permissions.viewRivetReport]) && (
              <Menu.Item key="auditTrail" onClick={() => history.push(audittrail)}>
                Audit Trail
              </Menu.Item>
            )}
            {checkUserPermission([AppData.permissions.viewInstitutionReport]) && (
              <Menu.Item key="courseInsight" onClick={() => history.push(courseinsight)}>
                Course Insight
              </Menu.Item>
            )}
            {checkUserPermission([AppData.permissions.viewRivetReport]) && (
              <>
                <Menu.Item key="coursePayment" onClick={() => history.push(coursepayment)}>
                  Course Payment
                </Menu.Item>
                <Menu.Item
                  key="insightOverview"
                  onClick={() => history.push(courseinsightsoverview)}>
                  Course Insight
                </Menu.Item>
                <Menu.Item key="manageRegUser" onClick={() => history.push(manageregistereduser)}>
                  Manage Reg. User
                </Menu.Item>
                <Menu.Item
                  key="revenuePerInstitution"
                  onClick={() => history.push(revenueperinstitution)}>
                  Rev. per Partner
                </Menu.Item>
                <Menu.Item key="revenuePerPartner" onClick={() => history.push(revenueperpartner)}>
                  Platform Revenue
                </Menu.Item>
              </>
            )}
          </SubMenu>
        )}
        {/* {checkUserRole(isAdminRole) &&
        permission.some(
          perm => perm === AppData.permissions.manageuser || perm === AppData.permissions.viewuser
        ) ? (
          <Menu.Item
            key="managePartners"
            onClick={() => {
              history.push(managepartners);
            }}>
            <UsergroupAddOutlined />
            <span>Manage Platform</span>
          </Menu.Item>
        ) : null} */}
        {checkUserRole(isAdminRole) && (
          <Menu.Item
            key="discounts"
            onClick={() => {
              history.push(discounts);
            }}>
            <MoneyCollectOutlined />
            <span>Discount</span>
          </Menu.Item>
        )}
        {checkUserRole(isAdminRole) && (
          <Menu.Item
            key="revenue"
            onClick={() => {
              history.push(revenue);
            }}>
            <PaymentIcon />
            <span>Revenue</span>
          </Menu.Item>
        )}
        {isAdminRole.includes(roles) || isInstitutionRole.includes(roles) ? (
          <Menu.Item
            key="settings"
            onClick={() => {
              history.push(settings);
            }}>
            <SettingOutlined />
            <span>Settings</span>
          </Menu.Item>
        ) : null}
      </Menu>
    </Wrapper>
  );
};

AdminSidebar.propTypes = {
  selectedKey: PropTypes.string.isRequired
};

export default AdminSidebar;

const Wrapper = styled(Layout.Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed;
  background-color: #000;
  left: 0;
  margin-top: 64px;
`;
