import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from 'components/landingPageComponents/Header';
import Banner from 'components/landingPageComponents/Banner';
import AboutSection from 'components/landingPageComponents/AboutSection';
import FeatureSection from 'components/landingPageComponents/Features';
import Footer from 'components/landingPageComponents/Footer';
import SignInForm from 'components/landingPageComponents/SignInForm';
import SubscribeSection from 'components/landingPageComponents/SubscribeSection';
import CareerPathSection from 'components/landingPageComponents/CareerPath';
import PartnerSection from 'components/landingPageComponents/Partners';
import CertifiedSection from 'components/landingPageComponents/CertifiedSection';
import LearnerSection from 'components/landingPageComponents/LearnerSection';
import CollaborationSection from 'components/landingPageComponents/CollaborationSection';
import ExploreSection from 'components/landingPageComponents/ExploreSection';
import { useMediaQuery } from 'react-responsive';
import { Box } from 'grommet';
import ExporeButton from 'components/landingPageComponents/ExporeButton';
import MainLayout from 'components/MainLayout';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from 'store/actions/toast';
import { home } from 'userRoutes';
import { useParams, useHistory } from 'react-router-dom';

const Home = () => {
  const auth = useSelector(state => state.auth);
  const [showSignin, setShowSignin] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const [scroll, setScroll] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(history.location.search);

  const redirectresponse = searchParams.get('data');
  if (redirectresponse != null) {
    dispatch(showToast('success', JSON.stringify(redirectresponse)));
    history.push(home);
  }

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <MainLayout
      selectedKey={'home'}
      sidebar={false}
      noMargin={1}
      setShowSignin={setShowSignin}
      background={scroll === 0 ? 'rgba(0,0,0,.3)' : '#000'}>
      <div>
        {/* <Header auth={auth} setShowSignin={setShowSignin} /> */}
        <Banner />
        {/* <ExporeButton /> */}
        <SignInForm showSignin={showSignin} mobile={isMobile} />
        <AboutSection />

        {/* <FeatureSection /> */}
        <CareerPathSection />
        {/* <CertifiedSection />
        <LearnerSection />
        <CollaborationSection /> */}
        {/* <PartnerSection /> */}
        <ExploreSection />
        {/* <SubscribeSection /> */}
        {/* <Footer /> */}
      </div>
    </MainLayout>
  );
};

export default Home;
