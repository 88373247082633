import React from 'react';
import 'styles/utils.css';
import { Box, Text, ResponsiveContext, Card } from 'grommet';
import { isMobile } from 'utils';

const ManageAdminCard = ({ adminName, adminEmail, adminUsername, userName, isActive }) => {
  const size = React.useContext(ResponsiveContext);
  return (
    <div style={{ boxShadow: '0px 2px 4px rgb(0 0 0 / 20%)' }}>
      <Box
        style={{ boxShadow: '0px 2px 4px rgb(0 0 0 / 20%)' }}
        direction={isMobile(size) ? 'column' : 'row'}
        pad="small"
        gap={isMobile(size) ? 'medium' : 'none'}>
        <Box flex="shrink" margin={{ left: isMobile(size) ? 'small' : 'none' }}>
          <Box
            direction={isMobile(size) ? 'column' : 'row'}
            justify="between"
            gap="medium"
            margin={{ left: 'small', top: 'small', bottom: 'small', right: 'small' }}>
            <Box width={isMobile(size) ? '100%' : '400px'}>
              <Text>{`Name: ${adminName} ${adminUsername}`}</Text>
              <Text>{adminEmail && `Email: ${adminEmail}`}</Text>
              <Text>{userName && `Username: ${userName}`}</Text>
              <Text>{`Status: ${isActive ? 'Active' : 'Inactive'}`}</Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ManageAdminCard;
