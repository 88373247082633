import React from 'react';
import { Box, FormField, ResponsiveContext, Text, TextInput } from 'grommet';
// import { useForm } from 'react-hook-form';
import { isMobile } from 'utils';

const PasswordSettings = props => {
  const { register, errors } = props;
  const size = React.useContext(ResponsiveContext);
  const formFieldStyle = {
    flexDirection: isMobile(size) ? 'column' : 'row',
    alignItems: isMobile(size) ? 'stretch' : 'center',
    justifyContent: isMobile(size) ? 'flex-start' : 'space-between'
  };

  // const onSubmit = data => {
  //   console.log(data);
  // };

  return (
    <Box style={{ maxWidth: 475 }}>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Current Password"
          name="currentPassword"
          htmlfor="currentPassword"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <TextInput
            ref={register({ required: 'Current Password is required' })}
            id="currentPassword"
            name="currentPassword"
            placeholder="Current Password"
            style={{ width: isMobile(size) ? '100%' : 275 }}
            type="password"
          />
        </FormField>
        {errors.currentPassword && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.currentPassword.message}
          </Text>
        )}
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="New Password"
          name="newPassword"
          htmlfor="newPassword"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <TextInput
            ref={register({
              required: 'New Password is required',
              pattern: {
                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/,
                message:
                  'Password must contain at least 8 characters, an uppercase alphabet,a lower case alphabet, a number and special character'
              }
            })}
            id="newPassword"
            name="newPassword"
            placeholder="New Password"
            style={{ width: isMobile(size) ? '100%' : 275 }}
            type="password"
          />
        </FormField>
        {errors.newPassword && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.newPassword.message}
          </Text>
        )}
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Confirm Password"
          name="confirmPassword"
          htmlfor="course-objectives"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <TextInput
            ref={register({ required: 'Confirm Password is required' })}
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Confirm Password"
            style={{ width: isMobile(size) ? '100%' : 275 }}
            type="password"
          />
        </FormField>
        {errors.confirmPassword && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.confirmPassword.message}
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default PasswordSettings;
