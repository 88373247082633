import React from 'react';
import 'styles/utils.css';
import { Box, Text, Heading, Button, ResponsiveContext } from 'grommet';
import CourseImage from 'assets/images/coursesdis.png';
import { isMobile } from 'utils';
import PageLoader from 'components/common/PageLoader';
import CustomLogoWrapper from './CustomLogoWrapper';
import { Progress, Typography, Button as AntButton } from 'antd';
import { useDispatch } from 'react-redux';
import { courseDetails } from 'store/actions/course';
import { useHistory } from 'react-router-dom';
import { createcourse } from 'adminRoutes';
import styled from 'styled-components';

import { getCourseBuilder } from 'api/queries/Course';
import { MainContext } from 'index';
import AppData from 'AppData';

const ProgramLandingCard = ({
  isPrice = false,
  price,
  isCompleted,
  institutionName,
  institutionLogo,
  title,
  courseId,
  isReview,
  description,
  logoTitle,
  logoSubtitle,
  modules,
  careerRelated,
  navigation = true,
  duration,
  isAdmin,
  percentage,
  course,
  draft,
  level,
  courseThumbNailUrl,
  readonly = false,
  topMargin = true
}) => {
  const size = React.useContext(ResponsiveContext);
  const [isLoading] = React.useState(false);
  const [isEnrolled, setIsEnrolled] = React.useState(true);
  const mainContext = React.useContext(MainContext);
  const history = useHistory();
  const dispatch = useDispatch();

  const courseBuilderDetails = async courseId => {
    try {
      // setIsLoading(true);
      const { data } = await getCourseBuilder(courseId);
      if (data) {
        dispatch(courseDetails(data));
        if (mainContext.build === AppData.adminBuildType) history.push(createcourse);
      }
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
    }
  };

  return (
    <Box margin={{ top: topMargin ? 'medium' : 'none' }}>
      <Box direction={isMobile(size) ? 'column' : 'row'} elevation="small" gap="medium">
        <Box
          style={{
            position: 'relative',
            minWidth: isMobile(size) ? '100%' : '200px',
            minHeight: isMobile(size) ? '200px' : '100%'
          }}>
          <Box
            style={{
              height: '100%',
              backgroundImage: `url('${
                courseThumbNailUrl ? encodeURI(courseThumbNailUrl) : CourseImage
              }')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}></Box>
        </Box>
        <Box
          flex="shrink"
          width={isMobile(size) ? 'full' : 'calc(100% - 192px)'}
          margin={{ left: isMobile(size) ? 'small' : 'none' }}>
          <Box
            direction={isMobile(size) ? 'column' : 'row'}
            justify="between"
            gap="medium"
            margin={{ top: 'small', right: 'small' }}>
            <Box>
              <Heading margin={{ bottom: 'xsmall', top: 'none' }} level={4} color="#777">
                {title}
              </Heading>
              {!isAdmin && (
                <div style={{ margin: '5px 0px' }}>
                  Partner:{' '}
                  <Typography.Text size="small" strong style={{ fontSize: '18px', color: 'green' }}>
                    {isEnrolled ? institutionName : logoTitle}
                  </Typography.Text>
                </div>
              )}
              <Typography.Text
                size="small"
                style={{ fontSize: '15px', color: '#6c6c6c' }}
                className="lh-28">
                {description}
              </Typography.Text>
            </Box>
            <Box
              flex="grow"
              align={isMobile(size) ? 'start' : 'end'}
              margin={{ bottom: isMobile(size) ? 'small' : 'none' }}
              justify="start">
              <Box
                flex="shrink"
                direction={isMobile(size) ? 'row' : 'column'}
                gap={isMobile(size) ? 'xsmall' : 'none'}>
                {isCompleted ? (
                  <Progress type="circle" percent={100} width={50} />
                ) : isEnrolled && isReview ? (
                  <Box>
                    {!draft && (
                      <Text
                        size="xlarge"
                        weight="bold"
                        alignSelf={isMobile(size) ? 'start' : 'end'}>
                        {price ? 'Paid' : 'Free'}
                      </Text>
                    )}
                  </Box>
                ) : (
                  <Box>
                    {!isAdmin && (
                      <CustomLogoWrapper
                        title={logoTitle}
                        subtitle={logoSubtitle}
                        src={institutionLogo}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            direction="row"
            justify="between"
            align={isMobile(size) ? 'end' : 'stretch'}
            margin={{ top: 'xsmall', bottom: 'small' }}
            gap="medium">
            {isEnrolled && !draft ? (
              <>
                {' '}
                {!readonly && (
                  <Box
                    margin={{ bottom: isMobile(size) ? 'small' : 'none' }}
                    direction={isMobile(size) ? 'column' : 'row'}
                    align="center"
                    gap="xsmall">
                    <Text style={{ fontSize: 12 }} color="#7e7e7e">
                      {!careerRelated
                        ? `${modules} ${modules > 1 ? 'Modules' : 'Module'}`
                        : `${course} Courses Available`}
                    </Text>
                    {!careerRelated && !isMobile(size) && (
                      <Box width="5px" height="5px" background="#7e7e7e" round="full"></Box>
                    )}
                    {!careerRelated && (
                      <Text style={{ fontSize: 12 }} color="#7e7e7e">
                        {duration}
                      </Text>
                    )}
                    {!careerRelated && !isMobile(size) && (
                      <Box width="5px" height="5px" background="#7e7e7e" round="full"></Box>
                    )}
                    {!careerRelated && (
                      <Text style={{ fontSize: 12 }} color="#7e7e7e">
                        {level} Level
                      </Text>
                    )}
                  </Box>
                )}
              </>
            ) : (
              <>{!readonly && <Progress percent={percentage} status="active" />}</>
            )}
            {!readonly ? (
              <Box flex="grow" align="end" justify="end">
                <Box flex="shrink">
                  {!isCompleted && (
                    <Button
                      margin={{ right: 'small' }}
                      label={
                        isCompleted
                          ? 'Invite Others'
                          : isEnrolled && !isReview
                          ? 'Enroll Course'
                          : isPrice
                          ? 'Continue Course'
                          : isReview && draft
                          ? 'Continue'
                          : null
                      }
                      primary={isPrice || isReview}
                      onClick={() => {
                        if (draft) return courseBuilderDetails(courseId);
                        if (isCompleted) return;
                        setIsEnrolled(!isEnrolled);
                      }}
                    />
                  )}
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Box>
      <PageLoader isLoading={isLoading} />
    </Box>
  );
};

export default ProgramLandingCard;
