import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import RegisterLayout from 'components/common/RegisterLayout';
import RegisterForm from 'components/forms/RegisterForm';

const Register = () => {
  return (
    <div>
      <Row>
        <Col
          style={{ minHeight: '100vh' }}
          xs={{ span: 0 }}
          sm={{ span: 12 }}
          md={{ span: 16 }}
          xxl={{ span: 18 }}>
          <RegisterLayout />
        </Col>
        <CustomColumn xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xxl={{ span: 6 }}>
          <RegisterForm />
        </CustomColumn>
      </Row>
    </div>
  );
};

export default Register;

const CustomColumn = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #fff;
`;
