import React from 'react';
import styled from 'styled-components';
import { Row, Checkbox, Input, Radio, Typography } from 'antd';
import { AiFillCloseCircle } from 'react-icons/ai';

const AddOption = ({ quizType, optionData, setOptions, index, errorMessage, isReview }) => {
  const { Text } = Typography;

  const onTitleChange = e => {
    e.persist();
    setOptions(state => {
      const newState = [...state];
      newState[index] = { ...newState[index], title: e.target.value, errorMessage: null };
      return newState;
    });
  };

  const onDescriptionChange = e => {
    e.persist();
    setOptions(state => {
      const newState = [...state];
      newState[index] = { ...newState[index], description: e.target.value };
      return newState;
    });
  };

  const deleteOptionHandler = () =>
    setOptions(state =>
      state.filter((_, i) => index !== i).map((item, index) => ({ ...item, id: index }))
    );

  const onSingleAnswerChanged = () => {
    setOptions(state => {
      const newState = [...state];
      for (let i = 0; i < newState.length; i++) {
        if (i === index) newState[i].isAnswer = true;
        else newState[i].isAnswer = false;
      }
      return newState;
    });
  };

  const onMultipleAnswerChanged = e => {
    e.persist();
    setOptions(state => {
      const newState = [...state];
      newState[index].isAnswer = e.target.checked;
      console.log(newState);
      return newState;
    });
  };

  return (
    <FlexRow>
      {
        {
          0: (
            <Radio
              style={{ marginTop: 5 }}
              checked={optionData.isAnswer}
              onClick={onSingleAnswerChanged}
              disabled={isReview}
            />
          ),
          1: (
            <Checkbox
              style={{ marginTop: 5, marginRight: 10 }}
              checked={optionData.isAnswer}
              onClick={onMultipleAnswerChanged}
              disabled={isReview}
            />
          )
        }[quizType]
      }
      <div style={{ width: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
        <Input
          placeholder="Add Answer"
          value={optionData.title}
          onChange={onTitleChange}
          disabled={isReview}
        />
        {errorMessage && <Text style={{ color: 'red' }}>{errorMessage}</Text>}
        <Spacer space="10px" />
        <Input
          placeholder="Explain why this is or isn't the best answer (Optional)"
          value={optionData.description}
          onChange={onDescriptionChange}
          disabled={isReview}
        />
      </div>
      {!isReview && (
        <AiFillCloseCircle
          size="1.5em"
          color="#999"
          style={{ marginTop: 5, marginLeft: 8, height: 20, width: 20 }}
          onClick={deleteOptionHandler}
        />
      )}
    </FlexRow>
  );
};

const FlexRow = styled(Row)`
  align-items: flex-start;
  flex-flow: row nowrap;
  padding: 10px 20px;
`;

const Spacer = styled.div`
  width: 100%;
  height: ${props => props.space || '20px'};
`;

export default AddOption;
