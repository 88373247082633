import React from 'react';
import { Box } from 'grommet';
import Modal from 'components/common/Modal';
import CreateUserForm from 'components/forms/CreateUserForm';

const CreateUserModal = ({ refetchUserUpdate, onClose }) => {
  return (
    <Box>
      <Modal width="475px" onClose={onClose} title="Create User" subTitle="Basic Information">
        <Box pad={{ horizontal: 'medium' }} margin={{ bottom: 'medium' }}>
          <CreateUserForm refetchUserUpdate={refetchUserUpdate} onClose={onClose} />
        </Box>
      </Modal>
    </Box>
  );
};

export default CreateUserModal;
