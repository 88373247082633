import React from 'react';
import {
  Box,
  Button,
  Form,
  FormField,
  ResponsiveContext,
  Select,
  Text,
  TextArea,
  TextInput
} from 'grommet';
import { Switch } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { durationInWeeks, isMobile } from 'utils';
import ButtonLoader from 'components/common/ButtonLoader';
import DateInput from 'components/extras/DateInput';
import { courseCategoryList } from 'api/queries/Course';
import PageLoader from 'components/common/PageLoader';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from 'store/actions/toast';
import { courseCreateUpdate } from 'api/mutations/Course';
import { basicCourse, courseId, courseStepNo } from 'store/actions/course';
import AppData from 'AppData';
import moment from 'moment';
// import './../../styles/setup.css';

const CreateCourseForm = ({ isReview, setCurrent, defaultCourseValue }) => {
  const dispatch = useDispatch();
  const course = useSelector(state => state.course);
  const [courseTypes] = React.useState(['Self Paced', 'Instructor Paced']);
  const [pricingData] = React.useState(['Paid', 'Free']);
  const [hasSurvey, setHasSurvey] = React.useState(course?.hasSurvey);
  const { isLoading, isError, data: courseCategory, error } = useQuery(
    ['courseCategory', true],
    courseCategoryList
  );
  const { register, errors, setValue, handleSubmit, control } = useForm();
  const { institutionId } = useSelector(state => state.auth);
  const size = React.useContext(ResponsiveContext);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const formFieldStyle = {
    border: 'none!important',
    flexDirection: isMobile(size) ? 'column' : 'row',
    alignItems: isMobile(size) ? 'stretch' : 'center',
    justifyContent: isMobile(size) ? 'flex-start' : 'space-between'
  };

  React.useEffect(() => {
    console.log('hasSurvey', defaultCourseValue);
  }, [hasSurvey]);

  const [courseCreateUpdateMutate] = useMutation(courseCreateUpdate, {
    onSuccess: data => {
      setIsSubmitting(false);
      if (data.success) {
        dispatch(courseId(data.data));
        if (!isReview) dispatch(showToast('success', 'Course created successfully'));
        setCurrent(state => state + 1);
      } else {
        dispatch(showToast('error', data.description));
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        dispatch(
          showToast(
            'error',
            <>
              We couldn't connect to the server.
              <br />
              Check your network or contact your admin
            </>
          )
        );
      } else {
        dispatch(showToast('error', error.message));
      }
    }
  });

  React.useEffect(() => {
    register('courseCategoryId', { required: 'Course Category is required' });
    register('courseType', { required: 'Course Type is required' });
    register('startDate', { required: 'Start Date required' });
    // register('endDate', { required: 'End Date required' });
  }, [register]);

  const onSubmit = async ({
    title,
    description,
    courseCategoryId,
    courseType,
    startDate,
    // endDate,
    isPaid
  }) => {
    // const a = moment(startDate, 'YYYY/M/D');
    // const b = moment(endDate, 'YYYY/M/D');
    // const diffDays = b.diff(a, 'days');

    // if (diffDays < 1)
    //   return dispatch(showToast('error', 'End date should be greater than start date'));

    if (isReview) return dispatch(courseStepNo({ stepNo: 2 }));

    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      // const duration = durationInWeeks(endDate, startDate);
      const data = {
        institutionId: isReview ? course.institutionId : institutionId,
        title,
        description,
        courseCategoryId: courseCategory.data.find(d => d.name === courseCategoryId)?.id,
        courseType: courseType === 'Self Paced' ? 1 : 2,
        // duration,
        startDate,
        // endDate,
        stepNo: AppData.basicCourseStep,
        isPaid: isPaid === pricingData[0] ? true : false,
        hasSurvey
      };
      if (defaultCourseValue) data.courseId = defaultCourseValue.courseId;
      await courseCreateUpdateMutate(data);
      dispatch(basicCourse(data));
    } catch (err) {
      console.log('err', err);
    }
  };

  if (isLoading) {
    return <PageLoader isLoading />;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: 450 }}>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Title"
          name="title"
          htmlfor="title"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <Controller
            name="title"
            control={control}
            defaultValue={defaultCourseValue?.title || ''}
            rules={{ required: 'Title is required' }}
            render={props => (
              <TextInput
                placeholder="Title"
                style={{ width: isMobile(size) ? '100%' : 248, border: 'none' }}
                onChange={e => props.onChange(e.target.value)}
                value={props.value}
                disabled={isReview}
              />
            )}
          />
        </FormField>
        {errors.title && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.title.message}
          </Text>
        )}
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Description"
          name="description"
          htmlfor="description"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <Controller
            name="description"
            control={control}
            defaultValue={defaultCourseValue?.description || ''}
            rules={{ required: 'Description is required' }}
            render={props => (
              <TextArea
                placeholder="Description"
                resize={false}
                style={{ width: isMobile(size) ? '100%' : 248, border: 'none' }}
                onChange={e => props.onChange(e.target.value)}
                value={props.value}
                disabled={isReview}
              />
            )}
          />
        </FormField>
        {errors.description && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.description.message}
          </Text>
        )}
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Course Category"
          name="courseCategoryId"
          htmlfor="course-category"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          {courseCategory.data && (
            <Controller
              name="courseCategoryId"
              control={control}
              defaultValue={() => {
                if (defaultCourseValue) {
                  const courseCategoryName = courseCategory.data.find(
                    d => d.id === defaultCourseValue.courseCategoryId
                  )?.name;
                  setValue('courseCategoryId', courseCategoryName);
                  return courseCategoryName;
                }
                return null;
              }}
              rules={{ required: 'Course Category is required' }}
              render={props => (
                <Select
                  id="course-category"
                  name="courseCategoryId"
                  placeholder="Course Category"
                  style={{ width: isMobile(size) ? '100%' : 200, border: 'none' }}
                  options={courseCategory.data.map(d => d.name)}
                  value={props.value}
                  onChange={({ option }) => {
                    props.onChange(option);
                    setValue('courseCategoryId', option);
                  }}
                  disabled={isReview}
                />
              )}
            />
          )}
        </FormField>
        {errors.courseCategoryId && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.courseCategoryId.message}
          </Text>
        )}
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Course Type"
          name="courseType"
          htmlfor="course-type"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <Controller
            name="courseType"
            control={control}
            defaultValue={() => {
              if (defaultCourseValue) {
                const courseType = courseTypes[defaultCourseValue.courseType - 1];
                setValue('courseType', courseType);
                return courseType;
              }
              return null;
            }}
            rules={{ required: 'Course Type is required' }}
            render={props => (
              <Select
                id="course-type"
                name="courseType"
                placeholder="Course Type"
                style={{ width: isMobile(size) ? '100%' : 200, border: 'none' }}
                options={courseTypes}
                value={props.value}
                onChange={({ option }) => {
                  props.onChange(option);
                  setValue('courseType', option);
                }}
                disabled={isReview}
              />
            )}
          />
        </FormField>
        {errors.courseType && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.courseType.message}
          </Text>
        )}
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Start Date"
          name="startDate"
          htmlfor="start-date"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <Controller
            name="startDate"
            control={control}
            defaultValue={() => {
              if (defaultCourseValue) {
                const startDate = defaultCourseValue.startDate;
                setValue('startDate', startDate);
                return startDate;
              }
              return null;
            }}
            rules={{ required: 'Start Date is required' }}
            render={props => (
              <DateInput
                id="start-date"
                name="startDate"
                placeholder="Start Date"
                width={isMobile(size) ? '100%' : '248px'}
                style={{ border: 'none' }}
                value={props.value}
                onChange={value => {
                  props.onChange(value);
                  setValue('startDate', value);
                }}
                disabled={isReview}
              />
            )}
          />
        </FormField>
        {errors.startDate && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.startDate.message}
          </Text>
        )}
      </Box>
      {/* <Box margin={{ bottom: 'small' }}>
        <FormField
          label="End Date"
          name="endDate"
          htmlfor="end-date"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <Controller
            name="endDate"
            control={control}
            defaultValue={() => {
              if (defaultCourseValue) {
                const endDate = defaultCourseValue.endDate;
                setValue('endDate', endDate);
                return endDate;
              }
              return null;
            }}
            rules={{ required: 'Start Date is required' }}
            render={props => (
              <DateInput
                id="end-date"
                name="endDate"
                placeholder="End Date"
                width={isMobile(size) ? '100%' : '248px'}
                style={{ border: 'none' }}
                value={props.value}
                onChange={value => {
                  props.onChange(value);
                  setValue('endDate', value);
                }}
                disabled={isReview}
              />
            )}
          />
        </FormField>
        {errors.endDate && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.endDate.message}
          </Text>
        )}
      </Box> */}
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Pricing"
          name="isPaid"
          htmlfor="is-paid"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <Controller
            name="isPaid"
            control={control}
            defaultValue={() => {
              if (defaultCourseValue)
                return defaultCourseValue.isPaid ? pricingData[0] : pricingData[1];
              return pricingData[0];
            }}
            render={props => (
              <Select
                id="is-paid"
                name="isPaid"
                placeholder="Pricing"
                style={{ width: isMobile(size) ? '100%' : 200, border: 'none' }}
                options={pricingData}
                value={props.value}
                onChange={({ option }) => {
                  props.onChange(option);
                }}
                disabled={isReview}
              />
            )}
          />
        </FormField>
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField label="EOC survey" margin={{ bottom: 'none' }} style={formFieldStyle}>
          <Box border="none" direction="row" margin={{ left: '-15.6rem' }} gap=".5rem">
            <Text color={'#000'}>{hasSurvey ? 'activated' : 'Deactivated'}</Text>
            <Switch
              defaultChecked={hasSurvey}
              onChange={() => setHasSurvey(prev => !prev)}
              style={{ border: 'none!important' }}
              disabled={isReview}
            />
          </Box>
        </FormField>
      </Box>
      <Box direction={isMobile(size) ? 'column' : 'row'} justify="end">
        <Button
          type="submit"
          primary
          margin={{ top: 'xsmall' }}
          label={!isSubmitting && 'Continue'}
          icon={isSubmitting ? <ButtonLoader isSubmitting={isSubmitting} /> : null}
        />
      </Box>
    </Form>
  );
};

export default CreateCourseForm;
