import React from 'react';
import styled from 'styled-components';

import MainLayout from 'components/MainLayout';
import Jumbotron from 'components/facilitatorPageComponents/Jumbotron';
import WhyTeach from 'components/facilitatorPageComponents/WhyTeach';
import EasySteps from 'components/facilitatorPageComponents/EasySteps';
import InstructorTestimonial from 'components/facilitatorPageComponents/InstructorTestimonial';
import StartTeaching from 'components/facilitatorPageComponents/StartTeaching';
import FAQ from 'components/facilitatorPageComponents/FAQ';

const Facilitators = () => {
  return (
    <MainLayout selectedKey={'dashboard'} padded={0} noMargin={0} sidebar={false}>
      <Container>
        <Jumbotron />
        <WhyTeach />
        <EasySteps />
        <InstructorTestimonial />
        <StartTeaching />
        <FAQ />
      </Container>
    </MainLayout>
  );
};

export default Facilitators;

const Container = styled.div``;
