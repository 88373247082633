import React from 'react';
import { Box, Image, Anchor } from 'grommet';
import SkillDormLogo from 'assets/images/skilldormblacklogo.png';
import { login } from 'adminRoutes';
import ForgetPasswordForm from 'components/forms/ForgetPasswordForm';
import NotificationModal from 'components/modals/NotificationModal';
import { useHistory } from 'react-router-dom';

const ForgetPassword = () => {
  const history = useHistory();
  const [successMessage, setSuccessMessage] = React.useState(null);
  return (
    <Box pad="medium" align="center" justify="center" height="100vh">
      <Box height="xsmall" width="small">
        <img src={SkillDormLogo} style={{ margin: '2.2rem 0 0 -.1rem' }} alt="" />
      </Box>
      <Box
        align="center"
        animation={{
          type: 'slideUp',
          delay: 0,
          duration: 500,
          size: 'medium'
        }}>
        <ForgetPasswordForm setSuccessMessage={setSuccessMessage} successMessage={successMessage} />
        <Anchor
          className="anchor-brand-secondary"
          style={{ fontWeight: 400 }}
          size="medium"
          margin={{ top: 'medium' }}
          href={login}
          label="Back to login"
        />
      </Box>
      {successMessage && (
        <NotificationModal
          title="Password Reset"
          message={successMessage}
          status="success"
          iconHeight="80px"
          iconWidth="80px"
          actionText="Got it"
          actionFunc={() => {
            history.replace(login);
          }}
        />
      )}
    </Box>
  );
};

export default ForgetPassword;
