import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import Settings from 'pages/admin/Settings';
import AppText from 'components/common/AppText';
import { ButtonCTA } from 'components/common/ButtonCTA';
import AddFileIcon from 'assets/images/file-add.png';
import SurveyTabs from 'components/manageSurvey/SurveyTabs';
import { SurveyProvider } from 'context/SurveyContext';
import CreateSurvey from 'components/manageSurvey/createSurvey/CreateSurvey';

import PageLoader from 'components/common/PageLoader';
import AssignSurvey from 'components/manageSurvey/assignSurvey';

const ManageSurvey = () => {
  const [createSurvey, setCreateSurvey] = useState();
  const [assignSurvey, setAssignSurvey] = useState();
  // const { data, isLoading } = useQuery(['surveyList'], getCourseSurveyList);

  return (
    <Settings selectedKey="Manage Survey">
      <HeaderDiv>
        {!assignSurvey && (
          <TitleDiv>
            <AppText
              value={'End of course Survey'}
              weight={400}
              size="24px"
              color="#000"
              bottom={'16px'}
            />
            <AppText
              value={'Create a generic end of course survey'}
              weight={400}
              size="14px"
              color="#000"
            />
          </TitleDiv>
        )}

        {!(createSurvey || assignSurvey) && (
          <ButtonCTA
            color="#fff"
            bgColor="#0966B8"
            icon={<img src={AddFileIcon} style={{ marginRight: '1rem' }} />}
            borderRadius="5px"
            width="204px"
            fontSize="16px"
            onClick={() => setCreateSurvey(true)}
            height="48px">
            Add a survey
          </ButtonCTA>
        )}
      </HeaderDiv>
      <SurveyProvider>
        <>
          {createSurvey ? (
            <CreateSurvey setCreateSurvey={setCreateSurvey} />
          ) : assignSurvey ? (
            <AssignSurvey setAssignSurvey={setAssignSurvey} />
          ) : (
            <SurveyTabs setCreateSurvey={setCreateSurvey} setAssignSurvey={setAssignSurvey} />
          )}
        </>
      </SurveyProvider>
    </Settings>
  );
};

export default ManageSurvey;

const HeaderDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2rem;
`;
const TitleDiv = styled.div``;
