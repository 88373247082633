import React from 'react';
import { Box, Form, Button } from 'grommet';
import { emailRegex, newPhoneRegex } from 'data/regex';
import { Controller, useForm } from 'react-hook-form';
import InputController from 'components/common/InputController';

const ManageInstitutionUserForm = ({
  onUserSubmit,
  prev,
  institutionUserState,
  institutionFormState
}) => {
  const { errors, handleSubmit, control } = useForm();

  const onSubmit = data => {
    onUserSubmit(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: 475 }}>
      <InputController
        Controller={Controller}
        controllerName="firstName"
        errors={errors}
        errorMessage="First Name is required"
        control={control}
        defaultValue={institutionUserState[0]?.value || ''}
        required
        label="First Name"
        name="firstName"
        htmlfor="firstName"
        placeholder="First Name"
        width={'248px'}
      />
      <InputController
        Controller={Controller}
        controllerName="lastName"
        errors={errors}
        errorMessage="Last Name is required"
        control={control}
        defaultValue={institutionUserState[1]?.value || ''}
        required
        label="Last Name"
        name="lastName"
        htmlfor="lastName"
        placeholder="Last Name"
        width={'248px'}
      />
      <InputController
        Controller={Controller}
        controllerName="userName"
        errors={errors}
        errorMessage="UserName is required"
        control={control}
        defaultValue={institutionFormState[0]?.value || ''}
        required
        label="User Name"
        name="userName"
        htmlfor="userName"
        placeholder="User Name"
        width={'248px'}
        disabled
      />
      <InputController
        Controller={Controller}
        controllerName="email"
        errors={errors}
        control={control}
        defaultValue={institutionUserState[2]?.value || ''}
        otherRules={{
          pattern: {
            value: emailRegex,
            message: 'Not a valid email address'
          }
        }}
        label="Email"
        type={'email'}
        name="email"
        htmlfor="email"
        errorMessage="Email is required"
        placeholder="Email"
      />
      <InputController
        Controller={Controller}
        controllerName="phone"
        errors={errors}
        control={control}
        defaultValue={institutionUserState[3]?.value || ''}
        otherRules={{
          pattern: {
            value: newPhoneRegex,
            message: 'Not a valid phone number'
          }
        }}
        label="Phone"
        name="phone"
        htmlfor="phone"
        placeholder="Phone"
      />
      <Box direction="row" gap="medium" justify="end">
        <Button
          type="button"
          size="small"
          margin={{ top: 'xsmall' }}
          onClick={() => prev()}
          label="Prev"
        />
        <Button type="submit" primary size="small" margin={{ top: 'xsmall' }} label="Next" />
      </Box>
    </Form>
  );
};

export default ManageInstitutionUserForm;
