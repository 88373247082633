import React from 'react';
import { Box, RadioButton } from 'grommet';

const VerticalSteps = ({
  current = 1,
  setCurrent,
  steps,
  canGoBack,
  canGoForwardInitial = false
}) => {
  const [maxCurrent, setMaxCurrent] = React.useState(current);
  const [filteredSteps, setFilteredStep] = React.useState([]);

  React.useEffect(() => {
    setFilteredStep(steps.filter(step => step !== null && step !== undefined));
  }, [steps]);

  React.useEffect(() => {
    setMaxCurrent(state => (current > state ? current : state));
  }, [current, maxCurrent]);

  return (
    <Box>
      {filteredSteps.map((step, index) => {
        if (!step) return null;
        const canDisable =
          current === index + 1 ? false : canGoBack && index < maxCurrent ? false : true;
        return (
          <Box key={index}>
            <RadioButton
              label={step}
              name={step}
              onChange={() => {}}
              onClick={() => setCurrent(index + 1)}
              checked={current >= index + 1 ? true : false}
              disabled={canDisable && !canGoForwardInitial}
            />
            {filteredSteps.length - 1 !== index && (
              <Box
                width="1px"
                height="30px"
                background="#999"
                margin={{ left: '11px', top: '5px', bottom: '5px' }}></Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default VerticalSteps;
