import React, { useState } from 'react';
import MainLayout from 'components/MainLayout';
import { Card, Col, Row, Typography, Dropdown, Button, Menu } from 'antd';
import styled from 'styled-components';
import ManagePartnersTable from 'components/tables/ManagePartnersTable';
import PartnerForm from 'components/forms/PartnerForm';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { getBanks, getPartners } from 'api/queries/Institution';
import { createPartner } from 'api/mutations/Institution';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import PageLoader from 'components/common/PageLoader';

const ManagePartners = () => {
  const [createPartner, setCreatePartner] = useState(false);
  const [updateValues, setUpdateValues] = useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [createError, setCreateError] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const auth = useSelector(state => state.auth);

  const { data: banks } = useQuery(['banks'], getBanks);
  // console.log('banks', banks);

  const { data: partners, refetch, isLoading } = useQuery(['partners', '', 0, 1, 50], getPartners);
  // console.log('partners', partners);

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        3rd menu item
      </Menu.Item>
    </Menu>
  );

  const handleClick = () => {
    setUpdateValues(null);
    setCreatePartner(prev => !prev);
  };

  return (
    <MainLayout isAdmin sidebar padded={1} selectedKey="managePartners">
      {!createPartner ? (
        <Card>
          <HeaderRow gutter={16} show={!createPartner}>
            <Col span={8}>
              <Title level={2}>Manage Platform</Title>
              {/* <Text>Discover your overall course analytics</Text> */}
            </Col>
            <ExportCol span={8} offset={8}>
              <DiscountBtn onClick={handleClick}>Create Platform</DiscountBtn>
              {/* <Dropdown overlay={menu}>
                <MoreBtn>
                  More
                  <DownOutlined style={{ color: '#fff' }} />
                </MoreBtn>
              </Dropdown> */}
            </ExportCol>
          </HeaderRow>
          <ManagePartnersTable
            setUpdateValues={setUpdateValues}
            setCreatePartner={setCreatePartner}
            partner={partners}
            refetch={refetch}
          />
        </Card>
      ) : (
        <Card2>
          <HeaderRow>
            <Title level={3}>{updateValues ? 'Update Partner' : 'Create New Partner'}</Title>
          </HeaderRow>
          <PartnerForm
            updateValues={updateValues}
            handleClick={handleClick}
            bankList={banks}
            refetch={refetch}
            setIsSubmitting={setIsSubmitting}
          />
        </Card2>
      )}
      <PageLoader
        isLoading={isLoading || isSubmitting}
        tip={isSubmitting ? 'Loading...' : 'Fetching Data'}
      />
    </MainLayout>
  );
};

const Title = styled(Typography.Title)`
  margin-bottom: 0px !important;
`;

const ExportCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const DiscountBtn = styled(Button)`
  background: #173253;
  border: 1px solid #173253;
  color: #fff;
  border-radius: 4px;
  width: 12em;
`;
const MoreBtn = styled(Button)`
  background: #4d4f5c;
  border: 1px solid #4d4f5c;
  color: #fff;
  border-radius: 4px;
  margin-left: 0.8em;
  width: 8em;
`;

const HeaderRow = styled(Row)`
  margin-bottom: 20px;
  border-bottom: ${props => (props.show ? 'none' : '1px solid #dfdfdf')};
  padding-bottom: 0.5em;
`;
const Card2 = styled(Card)`
  width: 80%;
  margin: auto;
`;

export default ManagePartners;
