import React, { useState } from 'react';
import MainLayout from 'components/MainLayout';
import styled from 'styled-components';
import DiscountTable from 'components/tables/DiscountTable';
import DiscountForm from 'components/forms/DiscountForm';
import DiscountUpdate from 'components/forms/DiscountUpdate';
import {
  Row,
  Col,
  Typography,
  Button,
  Menu,
  Dropdown,
  Table,
  Tag,
  Space,
  Form,
  Input,
  Select,
  Switch,
  DatePicker,
  Checkbox,
  Upload
} from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import { getDiscounts, getDiscountId } from 'api/queries/Discount';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from 'components/common/PageLoader';
import { showDiscountUpdate } from 'store/actions/discount';

const Payments = () => {
  const [discountType, setDiscountType] = useState(true);
  const [checkBoxAllCourses, setCheckBoxAllCourses] = useState(true);
  const [discountCreate, setDiscountCreate] = useState(false);
  const [discountUpdated, setDiscountUpdated] = useState(false);
  //const [isLoading, setIsLoading] = useState(false);
  const auth = useSelector(state => state.auth);
  const { showDiscountUpdated, discountId } = useSelector(state => state.discount);
  const dispatch = useDispatch();

  const { data: discountResult, refetch, isLoading } = useQuery(
    ['discountResult', auth?.institutionId],
    getDiscounts
  );

  const { data: discount, refresh } = useQuery(['discountResult', discountId], getDiscountId);

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        3rd menu item
      </Menu.Item>
    </Menu>
  );

  const handleSwitch = () => {
    setDiscountType(prev => !prev);
  };

  const handleCourseSwitch = () => {
    setCheckBoxAllCourses(prev => !prev);
  };

  const handleDiscount = () => {
    setDiscountCreate(prev => !prev);
    setDiscountType(true);
    dispatch(showDiscountUpdate(false));
  };

  const handleDiscountUpdate = () => {
    dispatch(showDiscountUpdate(false));
  };

  return (
    <MainLayout isAdmin sidebar padded selectedKey="discounts">
      <Container>
        <HeaderDiv>
          <div>
            <Typography.Title level={2} style={{ marginBottom: 0 }}>
              Manage Discounts
            </Typography.Title>
            {!discountCreate && (
              <Typography.Text style={{ fontSize: '1rem' }}>
                Discount coupons for courses
              </Typography.Text>
            )}
          </div>
          <div>
            {!discountCreate ? (
              <DiscountBtn onClick={handleDiscount}>Create Discount</DiscountBtn>
            ) : (
              <DiscountBtn onClick={handleDiscount} style={{ float: 'right' }}>
                Back
              </DiscountBtn>
            )}
          </div>
        </HeaderDiv>
        <PageLoader isLoading={isLoading} tip="Fetching Data" />

        {!discountCreate && !showDiscountUpdated ? (
          <DiscountTable discount={discountResult} refetch={refetch} />
        ) : discountCreate && !showDiscountUpdated ? (
          <Div>
            <DiscountForm
              discountType={discountType}
              handleSwitch={handleSwitch}
              checkBoxAllCourses={checkBoxAllCourses}
              handleCourseSwitch={handleCourseSwitch}
              cancel={handleDiscount}
              refetch={refetch}
            />
          </Div>
        ) : null}

        {showDiscountUpdated ? (
          <DiscountUpdate
            discountType={discountType}
            handleSwitch={handleSwitch}
            checkBoxAllCourses={checkBoxAllCourses}
            handleCourseSwitch={handleCourseSwitch}
            cancel={handleDiscountUpdate}
            refetch={refetch}
            discount={discount}
          />
        ) : null}
      </Container>
    </MainLayout>
  );
};

export default Payments;

const Container = styled.div`
  background: #fff;
  box-shadow: 1px 1px 7px 2px #afafaf;
`;

const HeaderDiv = styled(Row)`
  border-bottom: 1px solid #dfdfdf;
  padding: 1rem;
  margin-bottom: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DiscountBtn = styled(Button)`
  background: #173253;
  border: 1px solid #173253;
  color: #fff;
  border-radius: 4px;
  width: 12em;
  &:focus {
    background: #173253;
    color: #fff;
  }
`;

const Div = styled.div`
  width: 100%;
  min-height: 7em;
  padding-bottom: 6rem;
`;
