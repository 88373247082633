import React from 'react';
import styled from 'styled-components';
import { Table, Typography } from 'antd';

const { Paragraph } = Typography;

const columns = [
  {
    title: 'PREVIOUS',
    dataIndex: 'previous',
    render: text => <p>{text}</p>
  },
  {
    title: 'UPDATE',
    dataIndex: 'update'
  }
];

const data = [
  {
    key: '1',
    previous: 'John Brown',
    update: '￥300,000.00'
  },
  {
    key: '2',
    previous: 'Jim Green',
    update: '￥1,256,000.00'
  },
  {
    key: '3',
    previous: 'Joe Black',
    update: '￥120,000.00'
  }
];

const CompareTable = () => {
  return (
    <Container>
      <Table columns={columns} dataSource={data} bordered pagination={false} />
    </Container>
  );
};

export default CompareTable;

const Container = styled.div``;
