import React, { useEffect } from 'react';
import MainLayout from 'components/MainLayout';
import styled, { keyframes } from 'styled-components';
import { Tooltip, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';

const Terms = () => {
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <MainLayout padded={0} background="#000" selectedKey={'terms'} sidebar={false} background>
      <MainContainer>
        <WrapperDiv>
          <HeaderTitle level={1}>Terms of Use</HeaderTitle>
        </WrapperDiv>
        <WrapperDiv>
          <p>
            These Terms apply to all your activities on Skilldorm's website, Skilldorm Mobile app,
            applications, our APIs, and other related services (“Services”).
          </p>
          <p>Skilldorm is an online e-learning platform developed by Rivet Software Limited.</p>
          <p>
            If you publish a course on the Skilldorm platform, you must also agree to the
            <InnerSpan> Instructor Terms.</InnerSpan> We also provide details regarding our
            processing of the personal data of our students and instructors in our{' '}
            <InnerSpan>Privacy Policy</InnerSpan> .
          </p>
          <p>
            If you live in Nigeria, by agreeing to these Terms, you agree to resolve disputes with
            Skilldorm through binding arbitration (with very limited exceptions, not in court), and
            you waive certain rights to participate in class actions, as detailed in the Dispute
            Resolution section.
          </p>
        </WrapperDiv>
        <WrapperContainer direction={isMobileDevice ? 'column' : 'row'}>
          <TableOfContentDiv position={isMobileDevice ? undefined : 'sticky'}>
            <TableLink href="#account">Account</TableLink>
            <TableLink href="#enrollment">
              <Tooltip title={'Content Enrollment and Annual Course Access'}>
                Content Enrollment and ...
              </Tooltip>
            </TableLink>
            <TableLink href="#paymentandcredit">
              <Tooltip title={'Payments, Credits, and Refunds'}>Payments, Credits, and ...</Tooltip>
            </TableLink>
            <TableLink href="#pricing">Pricing</TableLink>
            <TableLink href="#payments">Payments</TableLink>
            <TableLink href="#gift">Gift and Promotional Codes</TableLink>
            <TableLink href="#contentbehaviour">Content and Behavior Rules</TableLink>
          </TableOfContentDiv>
          <ContainerWrapperDiv>
            <WrapperSection id={'account'}>
              <div style={{ paddingTop: true ? '5rem' : '0rem' }}>
                <SubHeaderTitle level={3}>1. Accounts</SubHeaderTitle>
                <div>
                  <ContentParagraph>
                    <strong>Note: </strong>
                    You need an account for most activities on our platform. Keep your password
                    somewhere safe, because you’re responsible for all activity associated with your
                    account. If you suspect someone else is using your account, let us know by
                    contacting our <InnerSpan>Support Team.</InnerSpan>
                  </ContentParagraph>
                  <ContentParagraph>
                    You need an account for most activities on our platform, including to purchase
                    and access content or to submit content for publication. When setting up and
                    maintaining your account, you must provide and continue to provide accurate and
                    complete information, including a valid email address. You have complete
                    responsibility for your account and everything that happens on your account,
                    including for any harm or damage (to us or anyone else) caused by someone using
                    your account without your permission. This means you need to be careful with
                    your password. You may not transfer your account to someone else or use someone
                    else’s account. If you contact us to request access to an account, we will not
                    grant you such access unless you can provide us with the information that we
                    need to prove you are the owner of that account. In the event of the death of a
                    user, the account of that user will be closed.
                  </ContentParagraph>
                  <ContentParagraph>
                    You may not share your account login credentials with anyone else. You are
                    responsible for what happens with your account and Skilldorm will not intervene
                    in disputes between students or instructors who have shared account login
                    credentials. You must notify us immediately upon learning that someone else may
                    be using your account without your permission (or if you suspect any other
                    breach of security) by contacting our <InnerSpan>Support Team</InnerSpan>. We
                    may request some information from you to confirm that you are indeed the owner
                    of your account.
                  </ContentParagraph>
                  <ContentParagraph>
                    Students and instructors must be at least 18 years of age to create an account
                    on Skilldorm and use the Services. If you are younger than 18 but above the
                    required age for consent to use online services where you live, you may not set
                    up an account, but we encourage you to invite a parent or guardian to open an
                    account and help you access content that is appropriate for you. If you are
                    below this age of consent to use online services, you may not create a Skilldorm
                    account. If we discover that you have created an account that violates these
                    rules, we will terminate your account. Under our{' '}
                    <InnerSpan>Instructor Terms</InnerSpan>, you may be requested to verify your
                    identity before you are authorized to submit content for publication on
                    Skilldorm.
                  </ContentParagraph>
                  <ContentParagraph>
                    You can terminate your account at any time by following the steps here. Check
                    our <InnerSpan>Privacy Policy</InnerSpan> to see what happens when you terminate
                    your account
                  </ContentParagraph>
                </div>
              </div>
            </WrapperSection>
            <WrapperSection id={'enrollment'}>
              <SectionPadding>
                <SubHeaderTitle level={3}>
                  2. Content Enrollment and Annual Course Access
                </SubHeaderTitle>
                <div>
                  <ContentParagraph>
                    <strong>Note: </strong>
                    When you enroll in a course or other content, you get a license from us to view
                    it via the Skilldorm platforms and Services and no other use. Don’t try to
                    transfer or resell the content in any way. We generally grant you a one-year
                    access license for all courses published on our platforms, except when we must
                    disable the content because of legal or policy reasons or for enrollments via
                    Subscription Plans.
                  </ContentParagraph>
                  <ContentParagraph>
                    Under our <InnerSpan>Instructor Terms</InnerSpan>, when instructors publish
                    content on Skilldorm, they grant Skilldorm a license to offer a license to the
                    content to students. This means that we have the right to sublicense the content
                    to enrolled students. As a student, when you enroll in a course or other
                    content, whether it’s free or paid content, you are getting a license from
                    Skilldorm to view the content via the Skilldorm platform and Services, and
                    Skilldorm is the licensor of record. Content is licensed, and not sold, to you.
                    This license does not give you any right to resell the content in any manner
                    (including by sharing account information with a purchaser or illegally
                    downloading the content and sharing it on torrent sites)
                  </ContentParagraph>
                  <ContentParagraph>
                    In legal, more complete terms, Skilldorm grants you (as a student) a limited,
                    non-exclusive, non-transferable license to access and view the content for which
                    you have paid all required fees, solely for your personal, non-commercial,
                    educational purposes through the Services, in accordance with these Terms and
                    any conditions or restrictions associated with the particular content or feature
                    of our Services. All other uses are expressly prohibited. You may not reproduce,
                    redistribute, transmit, assign, sell, broadcast, rent, share, lend, modify,
                    adapt, edit, create derivative works of, sublicense, or otherwise transfer or
                    use any content unless we give you explicit permission to do so in a written
                    agreement signed by a Skilldorm authorized representative. This also applies to
                    content you can access via any of our APIs.
                  </ContentParagraph>
                  <ContentParagraph>
                    We generally give an annual renewable access license to our students when they
                    enroll in a course or other content. However, we reserve the right to revoke any
                    license to access and use any content at any point in time in the event where we
                    decide or are obligated to disable access to the content due to legal or policy
                    reasons, for example, if the course or other content you enrolled in is the
                    object of a copyright complaint, or if we determine it violates our{' '}
                    <InnerSpan>Trust & Safety Guidelines</InnerSpan>. This annual renewable access
                    license does not apply to add-on features and services associated with the
                    course or other content you enroll in. For example, instructors may decide at
                    any time to no longer provide teaching assistance or Q&A services in association
                    with the content. To be clear, the annual renewable access is to the course
                    content but not to the instructor.
                  </ContentParagraph>
                  <ContentParagraph>
                    Instructors may not grant licenses to their content to students directly, and
                    any such direct license shall be null and void and a violation of these Terms.
                  </ContentParagraph>
                </div>
              </SectionPadding>
            </WrapperSection>
            <WrapperSection id={'paymentandcredit'}>
              <SectionPadding>
                <SubHeaderTitle level={3}>3. Payments, Credits, and Refunds</SubHeaderTitle>
                <div>
                  <ContentParagraph>
                    <strong>Note: </strong>
                    When you make a payment, you agree to use a valid payment method.
                  </ContentParagraph>
                </div>
              </SectionPadding>
            </WrapperSection>
            <WrapperSection id={'pricing'}>
              <SectionPadding>
                <SubHeaderTitle level={3}>3.1 Pricing</SubHeaderTitle>
                <div>
                  <ContentParagraph>
                    The prices of content on Skilldorm are determined based on the terms of the{' '}
                    <InnerSpan>Instructor Terms</InnerSpan> and our{' '}
                    <InnerSpan>Promotions Policy</InnerSpan>.
                  </ContentParagraph>
                  <ContentParagraph>
                    We occasionally run promotions and sales for our content, during which certain
                    content is available at discounted prices for a set period of time. The price
                    applicable to the content will be the price at the time you complete your
                    purchase of the content (at checkout). Any price offered for particular content
                    may also be different when you are logged into your account from the price
                    available to users who aren’t registered or logged in because some of our
                    promotions are available only to new users.
                  </ContentParagraph>
                  <ContentParagraph>
                    For both logged-in and guest users visiting the Skilldorm platform, the listed
                    currency you will see on each published course is currently the Nigerian-based
                    currency (Naira) when you created your account. We do not enable users to see
                    pricing in other currencies, however, our payment processing partners (i.e
                    Paystack or any other connected processing partner(s)) provide payment options
                    to users in other countries thereby allowing you to make payment at the
                    equivalent of the displayed course price in your local currency.
                  </ContentParagraph>
                  <ContentParagraph>
                    <RedSpan>
                      If you are a student located in a country where use and sales tax, goods and
                      services tax, or value added tax is applicable to consumer sales, we are
                      responsible for collecting and remitting that tax to the proper tax
                      authorities. Depending on your location, the price you see may include such
                      taxes, or tax may be added at checkout.
                    </RedSpan>
                  </ContentParagraph>
                </div>
              </SectionPadding>
            </WrapperSection>
            <WrapperSection id={'payments'}>
              <SectionPadding>
                <SubHeaderTitle level={3}>3.2 Payments</SubHeaderTitle>
                <div>
                  <ContentParagraph>
                    You agree to pay the fees for content that you purchase, and you authorize us to
                    charge your debit or credit card or process other means of payment based on the
                    option available on our platforms for those fees. Skilldorm works with payment
                    service providers to offer you the most convenient payment methods in your
                    country and to keep your payment information secure. We may update your payment
                    methods using the information provided by our payment service providers. Check
                    out our
                    <InnerSpan>Privacy Policy</InnerSpan> for more details.
                  </ContentParagraph>
                  <ContentParagraph>
                    When you make a purchase, you agree not to use an invalid or unauthorized
                    payment method. If your payment method fails and you still get access to the
                    content you are enrolling in, you agree to pay us the corresponding fees within
                    thirty (30) days of notification from us. We reserve the right to disable access
                    to any content for which we have not received adequate payment.
                  </ContentParagraph>
                </div>
              </SectionPadding>
            </WrapperSection>
            <WrapperSection id="gift">
              <SectionPadding>
                <SubHeaderTitle level={3}>3.3 Gift and Promotional Codes</SubHeaderTitle>
                <div>
                  <ContentParagraph>
                    Skilldorm or our partners may offer gift and promotional codes to students.
                    Certain codes may be redeemed for gift or promotional credits applied to your
                    Skilldorm account, which then may be used to purchase eligible content on our
                    platform, subject to the terms included with your codes. Other codes may be
                    directly redeemable for specific content.
                  </ContentParagraph>
                  <ContentParagraph>
                    These codes and credits, as well as any promotional value linked to them, may
                    expire if not used within the period specified in your Skilldorm account or in
                    the promotions terms and conditions. Gift and promotional codes offered by
                    Skilldorm may not be refunded for cash, unless otherwise specified in the terms
                    included with your codes or as required by applicable law. Gift and promotional
                    codes offered by a partner are subject to that partner’s refund policies. If you
                    have multiple saved credit amounts, Skilldorm may determine which of your
                    credits to apply to your purchase. Check out our{' '}
                    <InnerSpan>Support Page</InnerSpan> and any terms included with your codes for
                    more details.
                  </ContentParagraph>
                </div>
              </SectionPadding>
            </WrapperSection>
            <WrapperSection id={'contentbehaviour'}>
              <SectionPadding>
                <SubHeaderTitle level={3}>4. Content and Behavior Rules</SubHeaderTitle>
                <div>
                  <ContentParagraph>
                    <strong>Note: </strong>
                    You can only use Skilldorm for lawful purposes. You’re responsible for all the
                    content that you post on our platform. You should keep the reviews, questions,
                    posts, courses, and other content you upload in line with our{' '}
                    <InnerSpan>Trust & Safety Guidelines</InnerSpan> and the law, and respect the
                    intellectual property rights of others. We can ban your account for repeated or
                    major offenses. If you think someone is infringing your copyright on our
                    platform, please do <InnerSpan>let us know</InnerSpan>.
                  </ContentParagraph>
                  <ContentParagraph>
                    You may not access or use the Services or create an account for unlawful
                    purposes. Your use of the Services and behavior on our platform must comply with
                    applicable local or national laws or regulations of your country. You are solely
                    responsible for the knowledge of and compliance with such laws and regulations
                    that are applicable to you.
                  </ContentParagraph>
                  <ContentParagraph>
                    If you are a student, the Services enable you to ask questions to the
                    instructors of courses or other content you are enrolled in and to post reviews
                    of content. For certain content, the instructor may invite you to submit content
                    as “homework” or tests. Don’t post or submit anything that is not yours.
                  </ContentParagraph>
                </div>
              </SectionPadding>
            </WrapperSection>
          </ContainerWrapperDiv>
        </WrapperContainer>
      </MainContainer>
    </MainLayout>
  );
};

const slideup = keyframes`
from {
    margin-top: 100%;
    height: 300%; 
  }

  to {
    margin-top: 0%;
    height: 100%;
  }
`;

const MainContainer = styled.div`
  padding: 4%;
  margin-right: auto;
  margin-left: auto;
  background-color: #ffff;
  animation: 0.5s ${slideup} ease-in-out;
`;

const WrapperSection = styled.section``;
const WrapperDiv = styled.div``;
const WrapperContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: space-between;
  width: 100%;
  gap: 20px;
`;
const TableOfContentDiv = styled.aside`
  width: 250px;
  position: ${props => props.position};
  height: 100%;
  top: 70px;
  left: 0px;
  padding: 10px;
  padding-top: 5rem;
`;
const ContainerWrapperDiv = styled.div`
  flex: 1;
`;

const SectionPadding = styled.div`
  padding-top: 5rem;
`;
const HeaderTitle = styled(Typography.Title)`
  text-align: center;
`;
const SubHeaderTitle = styled(Typography.Title)``;
const ContentParagraph = styled(Typography.Paragraph)``;
const TableLink = styled.a`
  font-weight: bold;
  display: block;
  padding: 5px;
  transition: all 0.3s ease-in-out;
  &:hover {
    padding-left: 0px;
  }
`;
const InnerSpan = styled.span`
  background-color: yellow;
`;
const RedSpan = styled.span`
  background-color: red;
  color: #fff;
`;

export default Terms;
