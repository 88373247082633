import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Typography, Button } from 'antd';
import { FaClock, FaLayerGroup, FaBook, FaChartLine } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';

import MainLayout from 'components/MainLayout';
import NotificationModal from 'components/modals/NotificationModal';
import BarChart from 'components/charts/barChart/BarChart';
import PageLoader from 'components/common/PageLoader';
import PieChart from 'components/charts/piechart/PieChart';
import HorizontalChart from 'components/charts/barChart/HorizontalChart';
import Collaboration from 'assets/images/landing page Assets/Home landing page/collaboration.png';
import { getCourseStatistics } from 'api/queries/Course';

const ExploreCourse2 = () => {
  const [courseDetail, setCourseDetail] = useState();
  const [activateData, setActivateData] = useState();
  const [activateMessage, setActivateMessage] = useState();
  const [courseStatistics, setCourseStatistics] = useState();
  const [activate, setActivate] = useState('Deactivate');
  const [isLoading, setisLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setCourseDetail(history.location.state);
    const getCourseStatistics = async courseId => {
      if (!courseId) return null;
      const { data } = await Axios.get(`/Course/api/Course/Statistics/${courseId}`);
      return data;
    };
    getCourseStatistics(history.location.state[0]?.courseId).then(data => {
      setCourseStatistics(data?.data);
      console.log(data);
    });
  }, [history.location.state, courseDetail]);

  const handleCourse = () => {
    if (courseDetail[0]?.courseId) {
      setisLoading(true);
      const getActivateData = async courseId => {
        const { data } = await Axios.get(`/Course/api/Course/Toggle/${courseId}`);
        return data;
      };

      getActivateData(courseDetail[0]?.courseId).then(data => {
        setActivateData(data);
        setisLoading(false);
        console.log(data);
      });
    }
  };

  useEffect(() => {
    if (activateData?.success === true && activateData?.code === '00') {
      activate === 'Deactivate' ? setActivate('Activate') : setActivate('Deactivate');
    } else if (activateData?.success === false) {
      setActivateMessage('Action could not be completed');
    }
  }, [activateData]);

  return (
    <MainLayout isAdmin sidebar padded selectedKey="manageCourse">
      {activateMessage && (
        <NotificationModal
          status="failed"
          message={activateMessage}
          iconHeight="80px"
          iconWidth="80px"
          actionText="close"
          actionFunc={() => window.location.reload()}
        />
      )}
      <PageLoader isLoading={isLoading} />
      {courseDetail && (
        <Container>
          <CourseRow>
            <CourseCol1 span={7}>
              <CourseBg bgImage={courseDetail[0]?.courseThumbNailUrl}></CourseBg>
            </CourseCol1>
            <CourseCol2 span={17}>
              <Row gutter={32}>
                <Col span={16}>
                  <CourseTitle>{courseDetail[0]?.title}</CourseTitle>
                  <CourseInstitution>
                    partner: <InstitutionName>{courseDetail[0]?.institution}</InstitutionName>
                  </CourseInstitution>
                  <CourseParagraph>{courseDetail[0]?.description}</CourseParagraph>
                  <Ul>
                    <Li>
                      <Icon>
                        <FaBook color="7e7e7e" />
                      </Icon>
                      {`${courseDetail[0]?.modules}${
                        courseDetail[0]?.modules === 1 ? 'module' : 'modules'
                      }`}
                    </Li>
                    <Li>
                      <Icon>
                        <FaClock color="7e7e7e" />
                      </Icon>
                      {`${courseDetail[0]?.duration}hrs`}
                    </Li>
                    <Li>
                      <Icon>
                        <FaLayerGroup color="7e7e7e" />
                      </Icon>
                      {courseDetail[0]?.courseLevel === 1 ? 'Beginner Level' : 'Advanced Level'}
                    </Li>
                  </Ul>
                </Col>
                <Col span={8}>
                  <CourseStatus>Published</CourseStatus>
                  <CourseDate>{courseDetail[0]?.publishDate}</CourseDate>
                  <CourseBtn block={true} onClick={handleCourse}>
                    {activate} course
                  </CourseBtn>
                </Col>
              </Row>
            </CourseCol2>
          </CourseRow>
          <StudentRow>
            <ChartCard span={8} style={{ background: '#22c435' }}>
              <Row>
                <Col span={10}>
                  <ViewsText style={{ color: '#fff' }}>Total Revenue</ViewsText>
                  <ViewsData
                    style={{ color: '#fff' }}>{`N${courseStatistics?.totalRevenue}`}</ViewsData>
                </Col>
                <Col span={10} offset={4}>
                  <LineChart style={{ color: '#fff' }} />
                </Col>
              </Row>
            </ChartCard>
            <ChartCard span={8}>
              <Row>
                <Col span={10}>
                  <ViewsText>Total Students</ViewsText>
                  <ViewsData>{courseStatistics?.totalStudent}</ViewsData>
                </Col>
                <Col span={10} offset={4}>
                  <LineChart style={{ color: '#3454d6' }} />
                </Col>
              </Row>
            </ChartCard>
            <ChartCard span={8}>
              <Row>
                <Col span={10}>
                  <ViewsText>Active Students</ViewsText>
                  <ViewsData>{courseStatistics?.activeStudent}</ViewsData>
                </Col>
                <Col span={10} offset={4}>
                  <LineChart style={{ color: '#3456a2' }} />
                </Col>
              </Row>
            </ChartCard>
          </StudentRow>
          <ViewsRow>
            <Col span={6}>
              <ViewsText>Monthly Views</ViewsText>
              <ViewsData>{courseStatistics?.monthlyView}</ViewsData>
            </Col>
            <Col span={6}>
              <ViewsText>Weekly Views</ViewsText>
              <ViewsData>{courseStatistics?.weeklyView}</ViewsData>
            </Col>
            <Col span={12}>
              <BarChart chartData={courseStatistics?.weeklyViewStat} />
            </Col>
          </ViewsRow>
          {/* <BottomRow>
            <GenderDiv>
              <ChartTitle>Gender Distribution</ChartTitle>
              <PieChart />
            </GenderDiv>
            <DemographicsDiv>
              <ChartTitle>Student Demographics</ChartTitle>
              <HorizontalChart />
            </DemographicsDiv>
          </BottomRow> */}
        </Container>
      )}
    </MainLayout>
  );
};

export default ExploreCourse2;

const Container = styled.div`
  margin: auto;
  margin-top: 1.2em;
  width: 98%;
  min-height: 20em;
  /* background: #fff; */
`;
const CourseRow = styled(Row)`
  width: 100%;
  border-radius: 7px;
  box-shadow: 1px 1px 3px 2px #cfcfcf;
`;

const CourseCol1 = styled(Col)`
  padding: 0.4em;
  background: #fff;
`;

const CourseBg = styled(Col)`
  background: url(${props => (props.bgImage ? props.bgImage : Collaboration)}) no-repeat
    center/cover;
  position: relative;
  height: 100%;
  &::after {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 2;
  }
`;

const CourseCol2 = styled(Col)`
  padding: 1.2rem 1.2rem;
  background: #fff;
`;

const CourseTitle = styled(Typography.Title)`
  color: #7f7f7f !important;
  margin: 0 0 1rem 0 !important;
  font-size: 1.5em !important;
`;

const CourseInstitution = styled(Typography.Paragraph)`
  font-size: 0.9em;
  color: #7f7f7f;
`;

const InstitutionName = styled(Typography.Text)`
  font-size: 1.2em;
  font-weight: bold;
  color: #437a34;
`;

const CourseParagraph = styled(Typography.Paragraph)`
  color: #7f7f7f;
  margin-bottom: 1.3em !important;
  font-size: 0.8em;
  line-height: 1.4em;
`;

const Ul = styled.ul`
  padding: 0 0 !important;
  margin-top: 2em;
`;

const Li = styled.li`
  display: inline-block;
  font-size: 0.76em;
  margin-right: 1rem;
  color: #7e7e7e;
`;

const Icon = styled(Typography.Text)`
  margin-right: 5px;
`;

const CourseStatus = styled(Typography.Paragraph)`
  text-align: right;
  margin-bottom: 0.2em !important;
  color: #437a34;
  font-size: 1.2em;
  font-weight: bold;
`;
const CourseDate = styled(Typography.Paragraph)`
  text-align: right;
  color: #7f7f7f;
  font-size: 0.92em;
`;

const CourseBtn = styled(Button)`
  margin: 20% 0 0 0;
  background: #0966b8;
  height: 3.5rem;
  font-size: 1em;
  color: #fff;
  border-radius: 7px;
`;

const StudentRow = styled.div`
  margin-top: 3em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
`;

const ChartCard = styled.div`
  background: #fff;
  width: 100%;
  padding: 1em;
  box-shadow: 1px 1px 3px 2px #cfcfcf;
  border-radius: 5px;
  min-height: 6.5em;
`;

const LineChart = styled(FaChartLine)`
  height: 4em;
  width: 4em;
`;

const ViewsRow = styled(Row)`
  margin-top: 2em;
  min-height: 4em;
  background: #fff;
  box-shadow: 1px 1px 3px 2px #cfcfcf;
  padding: 1.5em;
  border-radius: 5px;
`;

const ViewsText = styled(Typography.Paragraph)`
  color: #7f7f7f;
  font-size: 0.9em;
  margin-bottom: 0.7em !important;
`;
const ViewsData = styled(Typography.Paragraph)`
  color: #333;
  font-size: 1.7em;
  font-weight: bold;
  margin-bottom: 0.3em !important;
`;

const BottomRow = styled.div`
  margin-top: 3em;
  display: grid;
  grid-template-columns: 35% 65%;
  grid-gap: 1em;
`;
const GenderDiv = styled.div`
  background: #fff;
  min-height: 4em;
  box-shadow: 1px 1px 3px 2px #cfcfcf;
  border-radius: 5px;
  padding: 1.3em;
`;
const DemographicsDiv = styled.div`
  background: #fff;
  min-height: 4em;
  box-shadow: 1px 1px 3px 2px #cfcfcf;
  border-radius: 5px;
  padding: 1.3em;
`;
const ChartTitle = styled(Typography.Paragraph)`
  color: #7f7f7f;
`;
