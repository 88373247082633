import React from 'react';
import 'styles/utils.css';
import { Text, Card, CardHeader, CardBody } from 'grommet';
import { Popover } from 'antd';

const ManageAdminImages = ({ title, media }) => {
  return (
    <div style={{ marginTop: 20 }}>
      <Card>
        <CardHeader border="bottom">
          <Text size={'small'} margin="small">
            {title}
          </Text>
          <Popover
            content={
              <div style={{ width: '100%', height: '100px' }}>
                <img src={media} alt="" style={{ width: '100%', height: '100%' }} />
              </div>
            }
            title={title}>
            <Text
              margin="small"
              size={'small'}
              style={{ color: '#0966b8', textDecoration: 'underline' }}>
              {media?.substr(media?.length - 16)}
            </Text>
          </Popover>
        </CardHeader>
      </Card>
    </div>
  );
};

export default ManageAdminImages;
