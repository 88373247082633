import React from 'react';
import styled from 'styled-components';
import { Row, Col, Typography } from 'antd';

const HorizontalChart = () => {
  return (
    <Container>
      <BarRow gutter={16}>
        <Col span={3}>
          <Age>13-17</Age>
        </Col>
        <Col span={15}>
          <Bar></Bar>
        </Col>
        <Col span={6}>
          <Percent>7%</Percent>
        </Col>
      </BarRow>
      <BarRow gutter={16}>
        <Col span={3}>
          <Age>18-24</Age>
        </Col>
        <Col span={15}>
          <Bar></Bar>
        </Col>
        <Col span={6}>
          <Percent>7%</Percent>
        </Col>
      </BarRow>
      <BarRow gutter={16}>
        <Col span={3}>
          <Age>25-34</Age>
        </Col>
        <Col span={15}>
          <Bar></Bar>
        </Col>
        <Col span={6}>
          <Percent>7%</Percent>
        </Col>
      </BarRow>
      <BarRow gutter={16}>
        <Col span={3}>
          <Age>35-44</Age>
        </Col>
        <Col span={15}>
          <Bar></Bar>
        </Col>
        <Col span={6}>
          <Percent>7%</Percent>
        </Col>
      </BarRow>
      <BarRow gutter={16}>
        <Col span={3}>
          <Age>45-54</Age>
        </Col>
        <Col span={15}>
          <Bar></Bar>
        </Col>
        <Col span={6}>
          <Percent>7%</Percent>
        </Col>
      </BarRow>
      <BarRow gutter={16}>
        <Col span={3}>
          <Age>55-64</Age>
        </Col>
        <Col span={15}>
          <Bar></Bar>
        </Col>
        <Col span={6}>
          <Percent>7%</Percent>
        </Col>
      </BarRow>
    </Container>
  );
};

export default HorizontalChart;

const Container = styled.div`
  width: 100%;
  min-height: 4em;
`;

const BarRow = styled(Row)`
  margin-bottom: 1.2em;
`;

const Age = styled(Typography.Paragraph)`
  text-align: right;
  font-size: 0.85em;
  color: #7f7f7f;
`;

const Bar = styled.div`
  width: 100%;
  min-height: 1em;
  margin-top: 1%;
  background: #2c7be5;
`;

const Percent = styled(Typography.Paragraph)`
  font-size: 0.85em;
  color: #7f7f7f;
`;
