import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signOut } from 'store/actions/auth';
import { home, dashboard, profile } from 'userRoutes';
import { Col, Avatar, Typography, Row, Popover } from 'antd';
import { LogoutOutlined, UserOutlined, CaretDownOutlined } from '@ant-design/icons';

const UserAvatar = ({ auth, isMobile = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const first = auth?.firstName?.charAt(0).toUpperCase();
  const last = auth?.lastName?.charAt(0).toUpperCase();
  const initials = `${first}${last}`;

  const popoverContent = (
    <div>
      <DropdownStyles
        onClick={() => {
          history.push(dashboard);
        }}>
        <UserOutlined style={{ marginRight: 5 }} />
        My Dashboard
      </DropdownStyles>

      <DropdownStyles
        onClick={() => {
          history.push(profile);
        }}>
        <UserOutlined style={{ marginRight: 5 }} />
        Profile
      </DropdownStyles>
      <DropdownStyles
        onClick={() => {
          dispatch(signOut()); // handle sign out dispatch
          sessionStorage.clear(); // clear session storage
          history.push(home); // redirect to login
          window.location.reload(); // reload browser
        }}>
        <LogoutOutlined style={{ marginRight: 5 }} />
        Logout
      </DropdownStyles>
    </div>
  );

  return (
    <Container span={8} offset={isMobile ? 4 : 2}>
      <Div>
        {!isMobile && <Name isMobile={isMobile}>{auth?.firstName}</Name>}
        <NewAvatar size={isMobile ? 27 : 40}>{initials ? initials : 'OC'}</NewAvatar>
        <Popover placement="bottomLeft" content={popoverContent} trigger="click">
          <Caret isMobile={isMobile} />
        </Popover>
      </Div>
    </Container>
  );
};

export default UserAvatar;

const Container = styled(Col)`
  margin-top: 0.2em;
`;

const NewAvatar = styled(Avatar)`
  /* float: right !important; */
  color: #fff;
  background: #0966b8;
  font-size: 1em !important;
  font-weight: bold;
`;

const Div = styled(Row)`
  /* margin-left: 0.5em; */
  width: 100%;
`;

const Name = styled(Typography.Text)`
  color: #fff;
  font-weight: bold !important;
  font-size: ${props => (props.isMobile ? '15px' : '18px')};
  margin-top: 0.4em;
  margin-right: 0.4em;
`;
const Caret = styled(CaretDownOutlined)`
  color: #fff;
  font-size: ${props => (props.isMobile ? '15px' : '14px')};
  margin: 0.9em 0 0 0.3em;
`;

const DropdownStyles = styled.p`
  cursor: pointer;
  margin-bottom: 8px;
  text-align: left;
`;
