import React, { useState } from 'react';
import useCurrencyFormater from 'components/hooks/useCurrencyFormater';
// import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import 'styles/global.css';
import { Typography, Col, Row } from 'antd';
import { ButtonCTA } from 'components/common/ButtonCTA';
import { useMediaQuery } from 'react-responsive';
import CourseImage from 'assets/images/coursesdis.png';
// import CourseLanRating from 'components/common/CourseLanRating';
import CourseLandingDetails from '../CourseLanding/CourseLandingDetails';
// import { getCourseLevel, timeFormat } from 'utils';
import { useSelector } from 'react-redux';
import { courseEnrollment } from 'api/mutations/Course';
import { checkEnrollment, courseEnrolledDetails } from 'api/queries/Course';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ShareAltOutlined } from '@ant-design/icons';
import ShareModal from './ShareModal';
import { checkout, delivery, enrollment, login, register } from 'userRoutes';
import ReactPlayer from 'react-player';

const EnrollmentCard = ({
  // amount,
  // description,
  data,
  poster,
  newPrice,
  // oldPrice,
  // enrollee,
  // canEnroll = true,
  // certified = false,
  courseId,
  userId
}) => {
  // const { Text } = Typography;
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });
  const auth = useSelector(state => state.auth);
  const [, setSuccessMessage] = React.useState(null);
  const [, setCreateError] = React.useState(null);
  const [, setIsSubmitting] = React.useState(false);
  const history = useHistory();
  const currencyFormatter = useCurrencyFormater();
  const [modalOpen, setModalOpen] = useState(false);

  const { data: hasEnrolled } = useQuery(['hasEnrolled', userId, courseId], checkEnrollment);
  const { data: videoData } = useQuery(
    ['courseEnrolledVideos', userId, courseId],
    courseEnrolledDetails
  );

  const [createMutate] = useMutation(courseEnrollment, {
    onSuccess: dataResult => {
      const { description, data, success } = dataResult;
      if (success) {
        setSuccessMessage(description);
        history.push(delivery, {
          userId,
          courseId,
          currentModule: videoData?.data?.currentModule,
          currentSection: videoData?.data?.currentSection
        });
      } else {
        setCreateError(description);
        setIsSubmitting(false);
        console.log(description);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setCreateError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        // setCreateError(error.message);
        console.log(error);
      }
    }
  });

  const EnrollCourse = () => {
    if (auth.userId === null) {
      history.push(register, history.location.pathname);
      return;
    }

    if (newPrice > 0) {
      history.push(checkout);
      return;
    }

    const param = {
      userId: auth.userId,
      courseId: courseId
    };
    createMutate(param);
  };

  const ProceedToCourse = () => {
    //console.log('check');
    history.push(delivery, {
      userId,
      courseId,
      currentModule: videoData?.data?.currentModule,
      currentSection: videoData?.data?.currentSection
    });
  };
  // console.log(certified, hasEnrolled?.data?.enrolled, hasEnrolled?.data?.certified);

  // handle share
  const handleShare = () => setModalOpen(state => !state);

  return (
    <CardComponent position={isMobileDevice ? 'unset' : 'sticky'}>
      {isMobileDevice ? (
        <VideoContainer>
          {data && (
            <ReactPlayer
              url={data?.coursePromotionalVideoUrl}
              width="100%"
              height="100%"
              // className="react-player"
              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
              onContextMenu={e => e.preventDefault()}
              light={poster}
              controls
            />
          )}
        </VideoContainer>
      ) : (
        <ImageWrapper>
          <ThumbnailImage
            src={data?.courseThumbNailUrl ? encodeURI(data?.courseThumbNailUrl) : CourseImage}
            alt="thumbnail"
          />
        </ImageWrapper>
      )}
      <div style={{ padding: '5px 20px' }}>
        {isMobileDevice && (
          <>
            <HeaderTitle level={isMobileDevice ? 4 : 1}>{data?.title}</HeaderTitle>
            <div style={{ width: isMobileDevice ? '100%' : '50%' }}>
              <DescriptionWrapper>{data?.description}</DescriptionWrapper>
            </div>{' '}
          </>
        )}
      </div>
      <div style={{ padding: '5px 20px' }}>
        <TextLabel size="16px">Course Fee</TextLabel>
      </div>
      <div style={{ padding: '0px 20px 10px', marginLeft: '2rem' }}>
        <TextLabel size="30px" strong>
          {data?.coursePrice?.amount !== 0
            ? currencyFormatter(
                'NGN',
                'en-NG',
                data?.coursePrice?.amount ? data?.coursePrice?.amount : 0
              )
            : 'Free'}
        </TextLabel>
      </div>
      <Row style={{ padding: '0px 20px' }}>
        <Col span={19}>
          <CustomButton
            width="100%"
            type="primary"
            size={isMobileDevice ? 'medium' : 'large'}
            color="#fff"
            //disabled={subscribe ? false : canEnroll ? true : false}
            onClick={hasEnrolled?.data?.enrolled === true ? ProceedToCourse : EnrollCourse}>
            {hasEnrolled?.data?.enrolled === true ? 'Proceed to Course' : 'Enroll'}
          </CustomButton>
        </Col>
        <Col span={4} offset={1}>
          <ShareButton
            width="100%"
            // type="primary"
            size={isMobileDevice ? 'medium' : 'large'}
            color="#1890ff"
            //disabled={subscribe ? false : canEnroll ? true : false}
            onClick={handleShare}>
            <ShareAltOutlined />
          </ShareButton>
        </Col>
      </Row>
      <div style={{ padding: 20 }}>
        <SpaceBetween align="center" direction={isMobileDevice ? 'column' : 'row'}>
          <TextLabel size="16px">The Course Details includes;</TextLabel>
          {/* <TextLabel size="16px" type="secondary">
            165 Ratings
          </TextLabel> */}
        </SpaceBetween>
        {/* <SpaceBetween align="center" direction={isMobileDevice ? 'column' : 'row'}>
          <TextLabel size="16px" strong>
            Content Acurracy
          </TextLabel>
          <RateDiv>
            <CourseLanRating size="18px" reviews={4} />
          </RateDiv>
        </SpaceBetween> */}

        <AlignCenterDiv align="center" direction={isMobileDevice ? 'column' : 'row'}>
          <CourseLandingDetails
            details={[
              { key: 'Offered by', value: `Offered by ${data?.institution}` },
              {
                key: 'paced',
                value: data?.courseTypeName === 'Self' ? 'Self Paced' : 'Instructor Paced'
              },
              { key: 'Level', value: data?.courseLevelName },
              { key: 'Modules', value: data?.modules ? data?.modules : '0' },
              { key: 'Certification', value: 'Shareable Certificate' },
              { key: 'duration', value: data?.duration },
              {
                key: 'access',
                value: '1 Year Access'
              }
            ]}
          />
        </AlignCenterDiv>
        {/* <DetailText type="secondary">{description}</DetailText> */}

        <ShareModal modalOpen={modalOpen} handleShare={handleShare} />
      </div>
    </CardComponent>
  );
};

export default EnrollmentCard;

const VideoContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const ImageWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 0px;
  min-height: 0px;
  flex-direction: column;
  height: 199px;
  width: 384px;
`;
const ThumbnailImage = styled.img`
  margin: ${({ margin }) => margin};
  flex: 1 1 0%;
  overflow: hidden;
  object-fit: cover;
  max-width: 100%;
  vertical-align: middle;
  border-style: none;
`;

const CardComponent = styled.div`
  margin-top: 10px;
  position: ${({ position }) => position};
  top: 125px;
  left: 0;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  background-color: rgb(255, 255, 255);
  color: rgb(51, 51, 51);
  min-width: 0px;
  min-height: 0px;
  flex-direction: column;
  width: 384px;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px;
`;

const SpaceBetween = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  border-bottom: ${({ border }) => (border === 'bottom' ? 'solid 1px #cccccc' : 'none')};
  flex-direction: ${({ direction }) => direction};
  justify-content: space-between;
  align-items: ${({ align }) => (align ? align : 'flex-start')};
  ${'' /* padding-bottom: 16px; */}
  flex-wrap: wrap;
`;

const AlignCenterDiv = styled.div`
  display: flex;
  max-width: 100%;
  margin-top: 10px;
  flex-wrap: wrap;
  align-items: ${({ align }) => (align ? align : 'flex-start')};
  flex-direction: ${({ direction }) => direction};
  ${'' /* -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify; */}
  justify-content: center;
`;

const TextLabel = styled(Typography.Text)`
  font-size: ${({ size }) => size};
  margin: ${({ margin }) => margin};
`;

const CustomButton = styled(ButtonCTA)`
  border-radius: 5px;
  width: ${({ width }) => width};
  ${'' /* background: #fff; */}
`;
const ShareButton = styled(ButtonCTA)`
  border-radius: 5px;
  width: ${({ width }) => width};
  background: #fff;
  border-color: #1890ff;
  ${'' /* color: #fff;
   background: #40a9ff;
  border-color: #40a9ff; */}
`;

// const DetailText = styled(Typography.Paragraph)`
//   font-size: 12px;
//   margin: 18px 0px;
// `;

const DescriptionWrapper = styled(Typography.Paragraph)`
  line-height: 20px;
  font-size: 15px;
  ${'' /* color: #fff; */}
  text-align: left;
  width: 100%;
  ${'' /* height: 60px; */}
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const HeaderTitle = styled(Typography.Title)`
  ${'' /* color: #fff !important; */}
  word-spacing: 5px;
`;
