import React, { useEffect, useState } from 'react';
import MainLayout from 'components/MainLayout';
import { Card, Col, Input, Row, Typography } from 'antd';
import styled from 'styled-components';
import CustomButton from 'components/common/CustomButton';
import CourseInsightsTable from 'components/tables/CourseInsightsTable';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { courseInsights } from 'api/queries/Report';
import PageLoader from 'components/common/PageLoader';
import AppData from 'AppData';

const CourseInsight = () => {
  const { Text } = Typography;

  const [searchVal, setSearchVal] = useState('');
  const { institutionId } = useSelector(state => state.auth);
  const [toggleExport, setToggleExport] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate] = useState('');
  const [endDate] = useState('');
  const [pageSize, setPageSize] = useState(10);

  const { isLoading, data: insightResult } = useQuery(
    [
      'courseInsights',
      searchVal,
      institutionId,
      toggleExport ? 1 : currentPage,
      toggleExport ? AppData.infinitePage : pageSize,
      startDate,
      endDate,
      toggleExport
    ],
    courseInsights
  );

  useEffect(() => {
    if (insightResult && toggleExport && !isLoading) {
      base64Download(insightResult?.file);
      setToggleExport(false);
    }
  }, [insightResult, isLoading, toggleExport]);

  const onSearch = value => setSearchVal(value);

  const base64Download = (base64Url, fileName = 'CourseInsight.xlsx') => {
    const a = document.createElement('a');
    console.log('inside', base64Url);
    document.body.appendChild(a);
    a.href = `data:application/octet-stream;base64,${base64Url}`;
    a.download = fileName;
    a.click();
  };

  return (
    <MainLayout
      isAdmin
      elevation={1}
      sidebar
      padded={1}
      background="#fff"
      selectedKey="courseInsight">
      <Card>
        <HeaderRow gutter={[16, 16]}>
          <Col sm={{ span: 16 }} md={{ span: 8 }} xs={{ span: 24 }}>
            <Title level={2}>Course Insights</Title>
            <Text>Discover your overall course analytics</Text>
          </Col>
          <CenterCol sm={{ span: 16 }} md={{ span: 8 }} xs={{ span: 24 }}>
            <Search placeholder="Search Course" onSearch={onSearch} size="large" />
          </CenterCol>
          <ExportCol sm={{ span: 16 }} md={{ span: 8 }} xs={{ span: 24 }}>
            <CustomButton
              onClick={() => {
                setToggleExport(true);
              }}
              bgColor="#4d4d53"
              color="#fff"
              size="large">
              Export
            </CustomButton>
          </ExportCol>
        </HeaderRow>
        {insightResult && (
          <CourseInsightsTable
            insightResult={insightResult}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            toggleExport={toggleExport}
          />
        )}
      </Card>
      <PageLoader fullWidth={isLoading} fullHeight={isLoading} isLoading={isLoading} />
    </MainLayout>
  );
};

const Title = styled(Typography.Title)`
  margin-bottom: 0px !important;
`;

const CenterCol = styled(Col)`
  display: flex;
  margin: auto;
`;

const ExportCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const Search = styled(Input.Search)`
  width: 100%;
`;

const HeaderRow = styled(Row)`
  margin-bottom: 20px;
`;

export default CourseInsight;
