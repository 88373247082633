import * as videoTypes from 'store/actions/video';
import produce from 'immer';

const initialState = {
  moduleList: null,
  sectionList: null
};

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case videoTypes.CLEAR_VIDEO:
      return initialState;
    case videoTypes.SET_MODULE_LIST:
      return produce(state, draft => {
        draft.moduleList = action.data;
      });
    case videoTypes.SET_SECTION_LIST:
      return {
        ...state,
        sectionList: action.data
      };
    default:
      return state;
  }
};

export default videoReducer;
