import React from 'react';
import { Box, Form, FormField, TextInput, Button, Text, ResponsiveContext } from 'grommet';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { login, studentlogin } from 'api/mutations/Authentication';
import { animate, isMobile } from 'utils';
import { signIn, allPermissionsAction } from 'store/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import ButtonLoader from 'components/common/ButtonLoader';
import buildJson from 'configs/json/build.json';
import AppData from 'AppData';
import { getAllPermissions } from 'api/queries/Authentication';

const LoginForm = () => {
  const dispatch = useDispatch();
  const { allPermissions } = useSelector(state => state.auth);
  const { data: allPermissionsData, refetch } = useQuery('getAllPermissions', getAllPermissions);
  const { register, errors, handleSubmit } = useForm();
  const size = React.useContext(ResponsiveContext);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [loginError, setLoginError] = React.useState(null);
  const [formMessageAnimate] = React.useState([
    animate({ type: 'fadeIn', delay: 500, duration: 500 }),
    animate({ type: 'slideUp', delay: 500, duration: 500 })
  ]);

  const onSubmit = async ({ userName, password }) => {
    if (isSubmitting) return;

    try {
      setLoginError(null);
      setIsSubmitting(true);
      if (!allPermissions) refetch();
      const data =
        buildJson.build === AppData.userBuildType
          ? await studentlogin({ userName, password })
          : await login({ userName, password });
      if (data.success) dispatch(signIn(data.data));
      else {
        setLoginError(data.description);
        setIsSubmitting(false);
      }
    } catch (error) {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setLoginError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        console.log(error.message);
        setLoginError(error.message);
      }
    }
  };

  React.useEffect(() => {
    if (allPermissionsData)
      dispatch(allPermissionsAction({ allPermissions: allPermissionsData?.data }));
  }, [dispatch, allPermissionsData]);

  return (
    <>
      {!loginError && (
        <Box margin={{ bottom: 'small' }} animation={formMessageAnimate[0]}>
          <Box animation={formMessageAnimate[1]}>
            {buildJson.build === AppData.userBuildType ? (
              <Text>Welcome to Skilldorm Student</Text>
            ) : buildJson.build === AppData.adminBuildType ? (
              <Text>Welcome to Skilldorm Admin</Text>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      )}
      {loginError && (
        <Box margin={{ bottom: 'small' }} animation={formMessageAnimate[0]}>
          <Box animation={formMessageAnimate[1]}>
            <Text color="status-critical" textAlign="center">
              {loginError}
            </Text>
          </Box>
        </Box>
      )}
      <Form
        style={isMobile(size) ? { width: 250 } : { width: 300 }}
        onSubmit={handleSubmit(onSubmit)}>
        <Box margin={{ bottom: 'small' }}>
          <FormField name="userName" htmlfor="username-id" margin={{ bottom: 'none' }}>
            <TextInput
              ref={register({ required: 'Username is required' })}
              id="username-id"
              name="userName"
              placeholder="User Name"
            />
          </FormField>
          {errors.userName && (
            <Text color="status-critical" size="small">
              {errors.userName.message}
            </Text>
          )}
        </Box>
        <Box margin={{ bottom: 'medium' }}>
          <FormField name="password" htmlfor="password-id" margin={{ bottom: 'none' }}>
            <TextInput
              ref={register({ required: 'Password is required' })}
              id="password-id"
              name="password"
              placeholder="Password"
              type="password"
            />
          </FormField>
          {errors.password && (
            <Text color="status-critical" size="small">
              {errors.password.message}
            </Text>
          )}
        </Box>
        <Button
          type="submit"
          primary
          label={!isSubmitting && 'Log in'}
          fill="horizontal"
          icon={isSubmitting ? <ButtonLoader isSubmitting={isSubmitting} /> : null}
        />
      </Form>
    </>
  );
};

export default LoginForm;
