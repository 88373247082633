import React, { useState, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { Row, Col, Avatar, Typography, List, Button } from 'antd';

import General from './General';
import Payments from './Payments';
import Notification from './Notification';
import SocialIntegration from './SocialIntegration';
import PasswordUpdate from './PasswordUpdate';
import DeleteAccount from './DeleteAccount';
import NotificationModal from 'components/modals/NotificationModal';
import { MainContext } from 'index';
import AppData from 'AppData';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const Index = ({
  update,
  handleUpdate,
  currentIndex,
  setCurrentIndex,
  getLoader,
  profileData,
  refetch,
  isMobile
}) => {
  const mainContext = useContext(MainContext);
  // const data = ['General', 'Payments', 'Notifications', 'Social Integration', 'Password', 'Delete Account'];
  const data = ['General', 'Social Integration', 'Password'];
  const adminContentData = ['General', 'Password'];
  const [formType, setFormType] = useState('General');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const checkUser = mainContext.build === AppData.userBuildType;

  const updateContent = [
    {
      component: (
        <General
          setLoading={setLoading}
          setSuccessMessage={setSuccessMessage}
          checkUser={checkUser}
          profileData={profileData}
          refetch={refetch}
          isMobile={isMobile}
        />
      )
    },
    // {
    //   component: <Payments setLoading={setLoading} setSuccessMessage={setSuccessMessage} />
    // },
    // {
    //   component: <Notification setLoading={setLoading} setSuccessMessage={setSuccessMessage} />
    // },
    {
      component: (
        <SocialIntegration
          setLoading={setLoading}
          setSuccessMessage={setSuccessMessage}
          profileData={profileData}
          refetch={refetch}
        />
      )
    },
    {
      component: (
        <PasswordUpdate
          setLoading={setLoading}
          setSuccessMessage={setSuccessMessage}
          refetch={refetch}
          profileData={profileData}
        />
      )
    },
    {
      component: <DeleteAccount setLoading={setLoading} setSuccessMessage={setSuccessMessage} />
    }
  ];

  const adminUpdateContent = [
    {
      component: (
        <General
          setLoading={setLoading}
          setSuccessMessage={setSuccessMessage}
          checkUser={checkUser}
          profileData={profileData}
          refetch={refetch}
          isMobile={isMobile}
        />
      )
    },
    {
      component: (
        <PasswordUpdate
          setLoading={setLoading}
          setSuccessMessage={setSuccessMessage}
          profileData={profileData}
          refetch={refetch}
        />
      )
    },
    {
      component: <DeleteAccount setLoading={setLoading} setSuccessMessage={setSuccessMessage} />
    }
  ];

  React.useEffect(() => {
    console.log('success', successMessage?.success);
    console.log('success', successMessage?.description);
  }, [successMessage]);

  const handleClick = index => {
    setCurrentIndex(index);
    setFormType(checkUser ? data[index] : adminContentData[index]);
  };

  React.useEffect(() => {
    getLoader(loading);
  }, [loading]);

  return (
    <Container update={update} gutter={16}>
      {successMessage && (
        <NotificationModal
          status={successMessage.success === true ? 'success' : 'failed'}
          message={successMessage.description}
          iconHeight="80px"
          iconWidth="80px"
          actionText="close"
          actionFunc={() => {
            setSuccessMessage(null);
            // window.location.reload();
          }}
        />
      )}
      <CloseBtn onClick={handleUpdate}>&times;</CloseBtn>
      <ProfileDiv>
        <UpdateMenu
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 6 }}
          lg={{ span: 6 }}
          xl={{ span: 6 }}>
          <List
            size="small"
            bordered
            dataSource={checkUser ? data : adminContentData}
            renderItem={(item, index) => (
              <Item onClick={() => handleClick(index)} currentIndex={currentIndex} index={index}>
                {item}
              </Item>
            )}
          />
        </UpdateMenu>
        <UpdateContent
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 18 }}
          lg={{ span: 18 }}
          xl={{ span: 18 }}>
          <UpdateDetails>
            {checkUser
              ? updateContent[currentIndex].component
              : adminUpdateContent[currentIndex].component}
          </UpdateDetails>
          <UpdateButtons>
            {currentIndex !== 5 ? (
              <>
                <CancelBtn>Cancel</CancelBtn>
                <SaveBtn form={formType && formType} htmlType="submit" loading={loading}>
                  Save changes
                </SaveBtn>
              </>
            ) : (
              <DeleteBtn>Delete Account</DeleteBtn>
            )}
          </UpdateButtons>
        </UpdateContent>
      </ProfileDiv>
    </Container>
  );
};

export default Index;

const fadeInUp = keyframes`
    0% {
      transform: translateY(0em);
      opacity: 0;
    },
    100%{
      transform: translateY(-2.5em);
      opacity: 1;
    }
`;

const Container = styled.div`
  position: relative;
  background: #fff;
  margin: auto;
  width: 92%;
  margin: auto;
  animation: 0.5s ${fadeInUp} ease-in;
  transform: translateY(-2.5em);
  /* margin-top: -2.5em; */
`;

const CloseBtn = styled(Typography.Text)`
  color: #afafaf;
  position: absolute;
  right: 0.2em;
  top: 0.2em;
  font-size: 2.4em;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: #7f7f7f;
  }
`;

const ProfileDiv = styled(Row)`
  /* background: red; */
  padding: 1.5em;
`;
const UpdateMenu = styled(Col)`
  /* background: yellow; */
  height: 17em;
  padding-right: 1.5em;
`;
const Item = styled(List.Item)`
  text-align: center !important;
  padding: 0.5rem auto 0.5rem 2rem !important;
  font-size: 1.3em !important;
  font-weight: bold;
  background: ${({ currentIndex, index }) =>
    index === currentIndex ? `#0966b8!important` : `#fff`};
  color: ${({ currentIndex, index }) => (index === currentIndex ? `#fff` : `#afafaf`)};
  cursor: pointer;
  &:nth-last-of-type(even) {
    background: #f7f7f7;
  }
`;
const UpdateContent = styled(Col)`
  /* background: blue; */
  height: inherit;
  padding-left: 1.5em;
  border-left: 1px solid #dfdfdf;
  @media ${mobile} {
    border-left: none;
    padding-left: 0;
  }
`;
const UpdateDetails = styled.div`
  padding: 0 0.7em 0 0.7em;
  @media ${mobile} {
    padding: 0 0;
  }
`;

const UpdateButtons = styled.div`
  margin-top: 2em;
  @media ${mobile} {
    margin: 1.5rem 0;
  }
`;

const CancelBtn = styled(Button)`
  height: 2.5em;
  color: #fff;
  border: none;
  background: #afafaf;
  width: 12em;
  @media ${mobile} {
    display: block;
    width: 100%;
  }
`;
const SaveBtn = styled(Button)`
  height: 2.5em;
  color: #fff;
  border: none;
  background: #1d9800;
  margin-left: 1em;
  width: 12em;
  @media ${mobile} {
    display: block;
    width: 100%;
    margin: 1em 0 0 0;
  }
`;
const DeleteBtn = styled(Button)`
  height: 2.5em;
  color: #fff;
  border: none;
  background: #f00;
  margin-left: 1em;
  width: 12em;
  @media ${mobile} {
    display: block;
    width: 100%;
    margin-left: 0;
  }
`;
