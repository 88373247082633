import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Typography, Button, Form, Input, Select } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { showToast } from 'store/actions/toast';
import { createPartner, updatePartners } from 'api/mutations/Institution';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';

import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';

const RevenueForm = ({ handleClick, updateValues, setIsSubmitting, bankList, refetch }) => {
  const [rows, setRows] = useState([]);
  // const [isSubmitting, setIsSubmitting] = React.useState(false);
  const dispatch = useDispatch();
  const { register, errors, setValue, handleSubmit, control } = useForm();

  const addRow = () => {
    const Item = rows.length === 0 ? 0 : rows[rows.length - 1] + 1;
    setRows(prev => [...prev, Item]);
  };
  const RemoveRow = (index, row) => {
    setRows(rows.filter(row => rows.indexOf(row) !== index));
    // console.log(index);
    console.log(rows.indexOf(row));
  };
  // React.useEffect(() => {
  //   console.log(rows);
  // }, [rows]);

  console.log(updateValues);

  const [createPartnerMutate] = useMutation(updateValues ? updatePartners : createPartner, {
    onSuccess: data => {
      setIsSubmitting(false);
      console.log('data', data);
      if (data.success) {
        dispatch(showToast('success', 'Partner created successfully'));
        handleClick();
        refetch();
      } else {
        dispatch(showToast('error', data.description));
      }
    },
    onError: error => {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        dispatch(
          showToast(
            'error',
            <>
              We couldn't connect to the server.
              <br />
              Check your network or contact your admin
            </>
          )
        );
      } else {
        dispatch(showToast('error', error.message));
      }
    }
  });

  const onSubmit = async ({ name, bankId, account }) => {
    const payload = {
      id: updateValues.id,
      bankId,
      account
    };
    try {
      setIsSubmitting(true);
      if (updateValues) {
        await createPartnerMutate(payload);
      } else await createPartnerMutate({ name, bankId, account });
    } catch (error) {}
  };

  return (
    <Container>
      <Form
        onFinish={onSubmit}
        fields={[
          { name: ['name'], value: updateValues?.name },
          { name: ['account'], value: updateValues?.account },
          { name: ['bankId'], value: updateValues?.bankId }
        ]}
        labelCol={{
          span: 6
        }}
        wrapperCol={{
          span: 12
        }}
        layout="horizontal">
        <Form.Item
          label="Partner Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please enter name'
            }
          ]}>
          <Input placeholder="enter name" disabled={updateValues} />
        </Form.Item>
        <Form.Item
          label="Select Bank"
          name="bankId"
          rules={[
            {
              required: true,
              message: 'Please enter your Bank Name'
            }
          ]}>
          <Select showSearch placeholder="choose">
            {bankList.map(bank => (
              <Select.Option key={bank.id} value={bank.id}>
                {bank.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Account Number"
          name="account"
          rules={[
            {
              required: true,
              message: 'Please enter your account number'
            }
          ]}>
          <Input type="number" />
        </Form.Item>
        <ButtonDiv>
          <CloseBtn onClick={handleClick}>Close</CloseBtn>
          <SaveBtn htmlType="submit">{updateValues ? 'Update' : 'Save'}</SaveBtn>
        </ButtonDiv>
      </Form>
    </Container>
  );
};

export default RevenueForm;

const Container = styled.div`
  width: 85%;
  min-height: 5em;
  margin: auto;
  margin-top: 3.5em;
`;

const ButtonDiv = styled.div`
  margin: auto;
  margin-top: 3em;
  width: 50%;
`;

const CloseBtn = styled(Button)`
  border: none;
  background: #4d4f5c;
  color: #fff;
  width: 5.5em;
`;
const SaveBtn = styled(Button)`
  border: none;
  background: #173253;
  color: #fff;
  margin-left: 1.2em;
  width: 9em;
`;
