import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';

import AppText from 'components/common/AppText';
import StatisticsCard from './StatisticsCard';
import Loader from 'components/common/Loader';
import staticsImg from 'assets/images/Group 136.png';
import img1 from 'assets/images/bar_chart_24px.png';
import img2 from 'assets/images/supervisor_account_24px.png';
import img3 from 'assets/images/how_to_reg_24px.png';

const Statistics = () => {
  const [loading, setLoading] = useState();
  const [courseStatistics, setCourseStatistics] = useState();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    const getCourseStatistics = async courseId => {
      if (!courseId) return null;
      const { data } = await Axios.get(`/Course/api/Course/Statistics/${courseId}`);
      return data;
    };
    getCourseStatistics(history.location.state[0]?.courseId).then(data => {
      setLoading(false);
      setCourseStatistics(data?.data);
    });
  }, [history.location.state]);

  const statistics = [
    {
      title: 'Total Revenue',
      value: ` ₦${courseStatistics?.totalRevenue}`,
      iconBg: '#C0E7F54F',
      borderBg: '#00AFEF',
      iconImg: img1
    },
    {
      title: 'Total Student',
      value: courseStatistics?.totalStudent,
      iconBg: '#FFAC321A',
      borderBg: '#FF6E00',
      iconImg: img2
    },
    {
      title: 'Active Student',
      value: courseStatistics?.activeStudent,
      iconBg: '#AEE9BE1A',
      borderBg: '#9CD056',
      iconImg: img3
    }
  ];
  return (
    <Container>
      <Div>
        <TitleDiv>
          <img src={staticsImg} style={{ alignSelf: 'center' }} />
          <AppText value={'Stats'} size="24px" color="#000" weight="600" bottom={'0'} />
        </TitleDiv>
        {loading ? (
          <NoDataDiv>
            <Loader color={'#0966b8'} />
            <AppText value={'Fetching statistics...'} size="22px" top=".5rem" />
          </NoDataDiv>
        ) : (
          courseStatistics && (
            <StatisticsDiv>
              {statistics.map(({ title, value, iconBg, borderBg, iconImg }) => (
                <StatisticsCard
                  title={title}
                  value={value}
                  iconBg={iconBg}
                  borderBg={borderBg}
                  iconImg={iconImg}
                />
              ))}
            </StatisticsDiv>
          )
        )}
      </Div>
    </Container>
  );
};

export default Statistics;

const Container = styled.div``;

const Div = styled.div`
  width: 100%;
  background: #fff;
  min-height: 7rem;
  margin-top: 2rem;
  padding: 1rem;
`;

const TitleDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 7rem;
`;

const NoDataDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: '10rem';
`;

const StatisticsDiv = styled.div`
  display: flex;
  gap: 6rem;
`;
