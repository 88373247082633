import React from 'react';
import { Box } from 'grommet';
import ActiveLearningJumbotron from './ActiveLearningJumbotron';

const ActiveLearningSection = ({ heading, content, isMobile }) => {
  return (
    <Box
      direction={!isMobile && 'row'}
      margin={{ top: `${isMobile ? '4em' : '10em'}` }}
      gap={!isMobile && 'xlarge'}
      style={{ width: '100vw', color: '#fff', zIndex: 50, position: 'relative' }}>
      <ActiveLearningJumbotron heading={heading} content={content} isMobile={isMobile} />
    </Box>
  );
};

ActiveLearningSection.propTypes = {};

export default ActiveLearningSection;
