import React, { useState } from 'react';
import 'styles/utils.css';
import { Box, Button, ResponsiveContext, Text, TextInput, Grid } from 'grommet';
import { Search } from 'grommet-icons';
import PageHeader from 'components/common/PageHeader';
import { animate, isMobile } from 'utils';
import ManageCardWrapper from 'components/common/ManageCardWrapper';
import { useQuery } from 'react-query';
import PageLoader from 'components/common/PageLoader';
import CreateUserModal from 'components/modals/CreateUserModal';
import { getUserInfoByInstitutionId } from 'api/queries/Authentication';
import { useSelector } from 'react-redux';
import ManageUserModal from 'components/modals/ManageUserModal';
import UpdateUserModal from 'components/modals/UpdateUserModal';
import MainLayout from 'components/MainLayout';

const ManageUser = () => {
  const { institutionId } = useSelector(state => state.auth);
  const [modalData, setModalData] = useState(null);
  const [searchVal, setSearchVal] = useState('');
  const [updateModal, setUpdateModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [createMessageAnimate] = React.useState([
    animate({ type: 'fadeIn', delay: 500, duration: 500 }),
    animate({ type: 'slideUp', delay: 500, duration: 500 })
  ]);
  const { data: allUsers, error, refetch, isLoading } = useQuery(
    ['usersById', institutionId, searchVal],
    getUserInfoByInstitutionId
  );
  const size = React.useContext(ResponsiveContext);

  const refetchUserUpdate = () => {
    refetch();
  };

  return (
    <MainLayout isAdmin sidebar padded={1} selectedKey="manageUser">
      <Box pad="medium" background="#fff">
        <>
          <PageHeader pageTitle="Manage Users" />
        </>
        <Box margin={{ top: 'large' }} gap={isMobile(size) ? 'large' : 'small'}>
          {error && (
            <Box margin={{ bottom: 'medium' }} animation={createMessageAnimate[0]}>
              <Box animation={createMessageAnimate[1]}>
                <Text color="status-critical">{error}</Text>
              </Box>
            </Box>
          )}
          <PageHeader
            pageActionElement={
              <>
                <Box
                  direction={isMobile(size) ? 'column' : 'row'}
                  align="center"
                  gap={isMobile(size) ? 'medium' : '250px'}>
                  <Box
                    width="medium"
                    direction="row"
                    align="center"
                    pad={{ horizontal: 'small', vertical: 'xsmall' }}
                    round="xsmall"
                    elevation="small">
                    <Search color="brand" />
                    <TextInput
                      type="search"
                      plain
                      // value={searchVal}
                      onChange={e => setSearchVal(e.target.value)}
                      placeholder="Search Name..."
                    />
                  </Box>
                  <Box>
                    <Button
                      size={isMobile(size) ? 'medium' : 'large'}
                      label="Add Users"
                      primary
                      onClick={() => setUserModalOpen(true)}
                    />
                  </Box>
                </Box>
              </>
            }
          />
          <Box margin={{ top: 'medium' }}>
            <Grid columns={!isMobile(size) ? 'medium' : '100%'} gap="medium">
              {allUsers &&
                allUsers.map(data => (
                  <ManageCardWrapper
                    isActive={data.isActive}
                    key={data.id}
                    title={`${data.firstName} ${data.lastName}`}
                    address={data.email}
                    mobile={data.phoneNumber}
                    clickFunc={() => {
                      setOpen(true);
                      setModalData(data);
                    }}
                  />
                ))}
            </Grid>
          </Box>
        </Box>
        {open && (
          <ManageUserModal
            refetchUserUpdate={refetchUserUpdate}
            setUpdateModal={setUpdateModal}
            data={modalData}
            onClose={() => setOpen(false)}
          />
        )}
        {updateModal && (
          <UpdateUserModal
            refetchUserUpdate={refetchUserUpdate}
            onClose={() => setUpdateModal(false)}
            updatedata={modalData}
          />
        )}
        {userModalOpen && (
          <CreateUserModal
            refetchUserUpdate={refetchUserUpdate}
            onClose={() => setUserModalOpen(false)}
          />
        )}
        <PageLoader isLoading={isLoading} tip="Fetching Data" />
      </Box>
    </MainLayout>
  );
};

export default ManageUser;
