import React from 'react';
import styled from 'styled-components';
import { Typography, Form, Input, message } from 'antd';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import { changePassword } from 'api/mutations/Authentication';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const PasswordUpdate = ({ setLoading, setSuccessMessage, profileData }) => {
  const { userName: userEmail } = useSelector(state => state.auth);
  const [updateMutate] = useMutation(changePassword, {
    onMutate: variables => setLoading(true),
    onSuccess: data => {
      setLoading(false);
      setSuccessMessage(data);
      // setSuccessMessage(data.description);
    },
    onError: error => {
      console.log(error);
      setSuccessMessage(error);
    }
  });

  const onFinish = async ({ userName, oldPassword, password }) => {
    const data = {
      userName: userEmail,
      oldPassword,
      password
    };

    try {
      await updateMutate(data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <Container>
      <Heading>Password</Heading>
      <Paragraph>Synchronize your social accounts to improve your learning</Paragraph>
      <Form
        id="Password"
        onFinish={onFinish}
        labelCol={{
          span: 4
        }}
        wrapperCol={{
          span: 14
        }}
        layout="vertical">
        {profileData && (
          <Form.Item
            label={<Typography.Text style={{ marginLeft: '1em' }}>Email</Typography.Text>}
            name="email">
            <NewInput defaultValue={profileData.email} disabled></NewInput>
          </Form.Item>
        )}
        <Form.Item
          label="Current password"
          name="oldPassword"
          rules={[
            {
              required: true,
              message: 'Enter current password'
            }
          ]}>
          <NewInput type="password"></NewInput>
        </Form.Item>
        <Form.Item
          label="New Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your  New Password!'
            },
            {
              pattern: new RegExp(
                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/
              ),
              message:
                'Password must contain at least 8 characters, an uppercase alphabet,a lower case alphabet, a number and special character'
            }
          ]}>
          <NewInput type="password"></NewInput>
        </Form.Item>
      </Form>
    </Container>
  );
};

export default PasswordUpdate;

const Container = styled.div`
  width: 100%;
  height: 100%;
  /* background: green; */
`;

const Heading = styled(Typography.Title)`
  font-size: 1.6em !important;
  font-weight: bold !important;
  color: #afafaf !important;
  margin-bottom: 0.5em !important;
  @media ${mobile} {
    font-size: 1.2em !important;
  }
`;

const Paragraph = styled(Typography.Paragraph)`
  color: #afafaf;
  @media ${mobile} {
    font-size: 0.9em !important;
  }
`;
const NewInput = styled(Input)`
  margin-left: 1em;
  width: 17em !important;
`;
