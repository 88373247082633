import React from 'react';
import 'styles/utils.css';
import { useHistory } from 'react-router-dom';
import { Box } from 'grommet';
import PageHeader from 'components/common/PageHeader';
import { searchCourseApproval } from 'api/mutations/Workflow';
import { useQuery } from 'react-query';
import {
  courseCategoryList,
  getInstitutionApprovedCourses,
  getInstitutionRejectedCourses
} from 'api/queries/Course';
import PageLoader from 'components/common/PageLoader';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from 'store/actions/toast';
import CourseSetup from 'components/common/CourseSetup';
import usePermission from 'components/hooks/usePermission';
import AppData from 'AppData';
import { getCourseAwaitingQAList } from 'api/queries/Course';
import MainLayout from 'components/MainLayout';
import { Tabs } from 'antd';
import ApproveCourseSearch from 'components/approveCourse/ApproveCourseSearch';
import ApproveCourseList from 'components/approveCourse/ApproveCourseList';

const ApproveCourse = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const { checkUserPermission } = usePermission();
  const { institutionId } = useSelector(state => state.auth);
  const [courses, setCourse] = React.useState();
  const [showCourseSetup, setShowCourseSetup] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  const [dateRange, setDateRange] = React.useState();
  const [isFetching, setIsFetching] = React.useState(false);
  const [courseCategoryId, setCourseCategoryId] = React.useState(0);
  const [institutionApprovalStatus, setInstitutionApprovalStatus] = React.useState(1);
  const [qaApprovalStatus, setQaApprovalStatus] = React.useState(4);
  const { isLoading, data: courseCategory, error } = useQuery('courseCategory', courseCategoryList);

  const hasQAPermission = React.useCallback(
    () =>
      checkUserPermission([AppData.permissions.manageCourseQA, AppData.permissions.viewCourseQA]),
    [checkUserPermission]
  );

  const fetchCoursesForApproval = React.useCallback(
    async (status = 0) => {
      try {
        setIsFetching(true);
        if (hasQAPermission()) {
          const { data } = await getCourseAwaitingQAList(
            search,
            startDate,
            endDate,
            status === 0 ? qaApprovalStatus : status,
            courseCategoryId
          );
          setCourse(data.items);
        } else if (status === 0 || status === 1) {
          const { data } = await searchCourseApproval({
            startDate,
            endDate,
            courseCategoryId,
            search,
            status: status === 0 ? institutionApprovalStatus : status
          });
          setCourse(data);
        } else {
          const { data } =
            status === 2
              ? await getInstitutionApprovedCourses(
                  institutionId,
                  search,
                  startDate,
                  endDate,
                  courseCategoryId
                )
              : await getInstitutionRejectedCourses(
                  institutionId,
                  search,
                  startDate,
                  endDate,
                  courseCategoryId
                );
          setCourse(data.items);
        }
      } catch (error) {
        console.log('err', error);
      } finally {
        setIsFetching(false);
      }
    },
    [
      hasQAPermission,
      qaApprovalStatus,
      search,
      startDate,
      endDate,
      courseCategoryId,
      institutionApprovalStatus,
      institutionId
    ]
  );

  const onTabChange = key => {
    if (hasQAPermission()) {
      const status = Number(key) === 1 ? 4 : Number(key);
      setQaApprovalStatus(status);
      fetchCoursesForApproval(status);
    } else {
      setInstitutionApprovalStatus(Number(key));
      fetchCoursesForApproval(Number(key));
    }
  };

  React.useEffect(() => {
    if (!showCourseSetup) fetchCoursesForApproval(hasQAPermission() ? 4 : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCourseSetup]);

  React.useEffect(() => {
    if (!dateRange) return;

    const ranges = dateRange.split(',');
    ranges.forEach((item, index) => {
      if (index === 0) setStartDate(item.trim());
      if (index === 1) setEndDate(item.trim());
    });
  }, [dateRange]);

  React.useEffect(() => {
    if (error) {
      dispatch(showToast('error', error));
    }
  }, [dispatch, error]);

  React.useEffect(() => {
    if (!showCourseSetup) {
      setCourse(null);
      setCourseCategoryId(0);
    }
  }, [showCourseSetup]);

  React.useEffect(() => {
    const params = new URLSearchParams(history.location.search).get('courseReview');
    if (params && params === 'true') setShowCourseSetup(true);
    else setShowCourseSetup(false);
  }, [history.location.search]);

  return (
    <MainLayout isAdmin sidebar padded selectedKey="approveCourse">
      {showCourseSetup ? (
        <CourseSetup isReview canShowBackButton />
      ) : (
        <Box pad="medium" background="#fff">
          <PageHeader
            pageTitle={`${hasQAPermission() ? 'QA' : ''} Approve course`}
            pageDescription="Review and Approve Courses"
          />
          <Tabs defaultActiveKey="1" onChange={onTabChange}>
            {['Pending', 'Approved', 'Rejected'].map((status, index) => (
              <TabPane tab={`${status} Courses`} key={`${index + 1}`}>
                <ApproveCourseSearch
                  search={search}
                  setSearch={setSearch}
                  setDateRange={setDateRange}
                  courseCategory={courseCategory}
                  courseCategoryId={courseCategoryId}
                  setCourseCategoryId={setCourseCategoryId}
                  fetchCoursesForApproval={fetchCoursesForApproval}
                  approvalStatus={hasQAPermission() ? qaApprovalStatus : institutionApprovalStatus}
                />
                <ApproveCourseList courses={courses} setShowCourseSetup={setShowCourseSetup} />
              </TabPane>
            ))}
          </Tabs>
        </Box>
      )}
      <PageLoader isLoading={isLoading || isFetching} />
    </MainLayout>
  );
};

export default ApproveCourse;
