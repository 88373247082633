import React from 'react';
import { Box, Button, Tabs, Tab, ThemeContext, Accordion } from 'grommet';
import { FormPrevious } from 'grommet-icons';
import AppData from 'AppData';
import VideoTabTitle from 'components/utils/CourseDelivery/VideoTabTitle';
import ModulePlayList from 'components/utils/CourseDelivery/ModulePlayList';
import 'styles/global.css';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Progress, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';
import PreviewModulePlayList from './PreviewModulePlayList';

const PreviewDeliveryTab = ({
  setCurrentModule,
  setCurrentSection,
  currentModule,
  courseModules,
  videoTime,
  playing,
  videoPlay,
  activeIndex,
  setActiveIndex,
  setVideoSrc,
  setResource,
  currentSection
}) => {
  const history = useHistory();
  const { moduleList, sectionList } = useSelector(state => state.video);
  const course = useSelector(state => state.course);
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1224px)' });

  return (
    <Box fill>
      <ThemeContext.Extend value={AppData.videoPageTabTheme}>
        <TitleContainer>
          <ModuleTitle level={4}>{course?.title}</ModuleTitle>
          <ProgressbarWrap>
            {/* <Progress percent={(moduleList && moduleList.percentage) || 0} status="active" /> */}
          </ProgressbarWrap>
        </TitleContainer>
        <Tabs flex>
          <Tab title={<VideoTabTitle label="Content" />}>
            <TabCon height={isMobileDevice ? '300px' : '100%'}>
              <Box>
                <ThemeContext.Extend
                  value={{
                    accordion: {
                      heading: { level: '5', margin: 'small' },
                      icons: { color: '#000' }
                    }
                  }}>
                  <Box width="full" direction="row">
                    <Accordion
                      // activeIndex={0}
                      // onActive={value => {
                      //   setActiveIndex(value);
                      // }}
                      animate={false}
                      style={{
                        width: '100%'
                      }}>
                      {/* {moduleList?.videos?.map((module, index) => {
                        let disabledPlayList = moduleList?.currentModule < index; */}
                      {courseModules?.map((module, index) => {
                        return (
                          <PreviewModulePlayList
                            disabled={false}
                            key={index}
                            mIndex={index}
                            setCurrentModule={setCurrentModule}
                            setCurrentSection={setCurrentSection}
                            currentPlayingModule={currentModule === index}
                            moduleName={module.title}
                            item={module.items}
                            itemsCompleted={module.itemsCompleted}
                            videoTime={videoTime}
                            playing={playing}
                            videoPlay={videoPlay}
                            setVideoSrc={setVideoSrc}
                            setResource={setResource}
                            currentModule={currentModule}
                            currentSection={currentSection}
                          />
                        );
                      })}
                    </Accordion>
                  </Box>
                </ThemeContext.Extend>
              </Box>
            </TabCon>
          </Tab>
        </Tabs>
        <BackButtonWrap>
          <Button
            onClick={() => {
              history.goBack();
            }}
            style={{ color: '#0966b8', fontSize: 18 }}
            plain
            label="Go Back"
            icon={<FormPrevious color="#0966b8" />}
          />
        </BackButtonWrap>
      </ThemeContext.Extend>
    </Box>
  );
};

const TabCon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  height: ${({ height }) => height};
`;

const ModuleTitle = styled(Typography.Title)`
  margin: 12px 12px 12px 12px !important;
  font-size: 20px !important;
  line-height: 22px !important;
  max-width: 384px !important;
  font-weight: 600 !important;
  text-align: center;
  text-wrap: wrap;
`;

const TitleContainer = styled.div`
  padding: 6px 19px 0px;
`;
const ProgressbarWrap = styled.div`
  margin-top: 20px;
`;

const BackButtonWrap = styled.div`
  margin-top: 20px;
  padding: 0px 20px 10px 15px;
`;
export default PreviewDeliveryTab;
