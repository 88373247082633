import React from 'react';
import { Row } from 'antd';
import styled from 'styled-components';
import SkilldormLogo from 'assets/images/landing page Assets/Home landing page/skilldormLogo.png';
import SkilldormLogo2 from 'assets/images/landing page Assets/Home landing page/skilldormLogo2.png';

const Logo = ({ dashboard, isMobileDevice }) => {
  return <LogoDiv dashboard={dashboard} isMobileDevice={isMobileDevice}></LogoDiv>;
};
Logo.propTypes = {};

export default Logo;

const LogoDiv = styled(Row)`
  background: ${props =>
    `url(${props.dashboard ? SkilldormLogo2 : SkilldormLogo}) no-repeat center/cover`};
  width: ${props => (props.isMobileDevice ? '128%' : '68%')};
  height: ${props => (props.isMobileDevice ? '2rem' : '3rem')};
`;
