import React from 'react';
import styled from 'styled-components';
import { Table, Switch, Button } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import moment from 'moment';
import Axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from 'store/actions/toast';

const RevenueTable = props => {
  const { revenueList, showFormular, handleClick, revenueId, revenueName, refetch } = props;
  const dispatch = useDispatch();

  const service = 'Course';

  const activateDeactivateRevenue = async (id, isActive) => {
    const { data } = await Axios.delete(
      `/${service}/api/Revenue/ActivateDeactivateRevenue?id=${id}&isActive=${isActive}`
    );
    refetch();
    dispatch(showToast('warning', data.description));
    return data;
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'DATE CREATED',
      dataIndex: 'dateCreated',
      key: 'dateCreated'
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive'
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (index, record) => (
        <Button
          type="primary"
          onClick={() => {
            showFormular(true);
            handleClick();
            revenueId(record.id);
            revenueName(record.name);
          }}>
          View
        </Button>
      )
    }
  ];

  const tableData = revenueList?.data.map(rowData => ({
    ...rowData,
    key: rowData.id,
    statusType: rowData.isActive,
    dateCreated: moment(rowData.dateCreated).format('MM-DD-YYYY hh:mm'),
    isActive: (
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        //disabled
        checked={rowData.isActive ? true : false}
        onClick={() => {
          rowData.isActive
            ? activateDeactivateRevenue(rowData.id, false)
            : activateDeactivateRevenue(rowData.id, true);
        }}
      />
    )
    // action: <ViewBtn>view</ViewBtn>
  }));

  return <Table columns={columns} dataSource={tableData} />;
};

export default RevenueTable;

const ViewBtn = styled(Button)`
  border: none;
  background: #2c7be5;
  color: #fff;
`;
