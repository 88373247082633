import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Row, Col, Input, Typography, List, Card } from 'antd';
import { FaSearch } from 'react-icons/fa';
import { SearchOutlined } from '@ant-design/icons';
import { useMutation } from 'react-query';
import digitalLiteracy from 'assets/images/landing page Assets/Home landing page/digitalLiteracy.png';
import CourseCard2 from 'components/common/CourseCard2';
import SearchInput from 'components/common/SearchInput';
import { courseSearch } from 'api/mutations/Course';
import CourseCard from './CourseCard';

const SearchResult = ({ showSearch, showFunc, focus, setFocus }) => {
  const [inputVal, setInputval] = useState(null);
  const [searchResult, setSearchResult] = useState(false);
  const [value, setValue] = React.useState('');
  const [courseSearchResult, setCourseSearchResult] = useState(null);
  const body = document.getElementsByTagName('body')[0];

  useEffect(() => {
    body.style.overflow = `${showSearch ? `hidden` : `auto`}`;
    return () => {
      body.style.overflow = 'auto';
    };
  }, [showSearch]);

  useEffect(() => {
    inputVal?.length > 2 ? setSearchResult(true) : setSearchResult(false);
  }, [courseSearchResult]);

  const [createMutate] = useMutation(courseSearch, {
    onSuccess: dataResult => {
      const { description, data, success } = dataResult;
      if (success) {
        console.log('been', data.items);
        setCourseSearchResult(data?.items);
      }
    },
    onError: error => {
      console.log(error);
    }
  });

  const handleClose = e => {
    showFunc(false);
    setFocus(false);
    setInputval(null);
    console.log(inputVal);
  };

  const handleSearchResult = async e => {
    setInputval(e.target.value);
    const param = {
      page: 1,
      pageSize: 50,
      courseCategoryIds: [],
      parameter: inputVal,
      institutionIds: [],
      courseLevels: [],
      languageIds: []
    };
    createMutate(param);
  };

  return (
    <Container show={showSearch}>
      <SearchContainer>
        <SearchDiv>
          <SearchIcon />
          <SearchInput
            marginLeft={'15em'}
            handleChange={handleSearchResult}
            focus={focus}
            showBorder={false}
          />
          <CloseBtn onClick={handleClose}>&times;</CloseBtn>
        </SearchDiv>
        <ResultDiv searchResult={searchResult}>
          <Related>
            <ResultHeader>
              {courseSearchResult?.length === 0 ? (
                <p style={{ textAlign: 'center', margin: '3rem auto 0 auto' }}>
                  No results found for "{inputVal}"
                </p>
              ) : (
                'Related Courses'
              )}
            </ResultHeader>
            <Row gutter={16}>
              {courseSearchResult &&
                courseSearchResult.map(item => (
                  <Col span={6} style={{ marginTop: '1em' }}>
                    <CourseCard
                      key={item.courseId}
                      courseId={item.courseId}
                      isPrice
                      price={item.coursePrice?.amount || 0}
                      title={`${item.title.charAt(0).toUpperCase()}${item.title
                        .slice(1)
                        .toLowerCase()}`}
                      description={item.description}
                      modules={item.modules}
                      background={'#dfdfdf'}
                      institutionName={item.institution}
                      institutionLogo={item.institutionLogo}
                      duration={item.duration}
                      fromSearchNav
                      level={item.courseLevel}
                      courseThumbNailUrl={item.courseThumbNailUrl}
                    />
                  </Col>
                ))}
              ;
            </Row>
          </Related>
        </ResultDiv>
      </SearchContainer>
    </Container>
  );
};

export default SearchResult;

const Container = styled.div`
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  transition: all 0.5s ease-in;
  opacity: ${({ show }) => (show ? `1` : '0')};
  pointer-events: ${({ show }) => (show ? `auto` : 'none')};
  overflow-y: auto;
`;
const SearchContainer = styled(Row)`
  width: 100%;
`;

const SearchDiv = styled(Row)`
  width: 100%;
  background: #000;
  height: 20vh;
  padding: 2em 2em;
  position: relative;
  z-index: 1000 !important;
`;

const CloseBtn = styled(Typography.Text)`
  position: absolute;
  color: #fff;
  font-weight: bold;
  right: 0.7em;
  top: 0.9em;
  font-size: 2.2em;
  &:hover {
    cursor: pointer;
    color: #cfcfcf;
  }
`;

const Search = styled(Input)`
  background: none;
  border: 1px solid #fff;
  border-radius: 2em;
  height: 70%;
  width: 60%;
  margin: auto;
`;

const SearchIcon = styled(SearchOutlined)`
  color: #fff;
  position: absolute;
  left: 15rem;
  top: 3.5rem;
  font-size: 1.4em;
`;

const drop = keyframes`
  0% {
    transform: translateY(-80vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;
const ResultDiv = styled(Row)`
  background: #000;
  width: 100%;
  position: relative;
  min-height: 80vh;
  padding: 0em 2em;
  display: ${({ searchResult }) => (searchResult ? `flex` : `none`)};
  animation: 0.3s ${drop} ease-out;
`;

const Keywords = styled(Col)`
  /* background: red; */
  padding: 0 1em;
`;
const Related = styled.div`
  width: 100%;
  padding: 0 1em;
`;
const ResultHeader = styled(Typography.Paragraph)`
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
`;
const ListItem = styled(List.Item)`
  border-bottom: none !important;
  transition: all 0.4s ease;
  &:hover {
    transform: translate(10px) scale(1.2) !important;
    color: blue !important;
  }
`;
const ResultCard = styled(Card)`
  transition: all 0.5s ease;
  &:hover {
    transform: scaleY(1.1);
  }
`;
const A = styled.a`
  color: #fff !important;
  font-weight: bolder !important;
  font-size: 1.4em;
`;
