import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const Payments = () => {
  return (
    <div>
      <Container>
        <Heading>Payments</Heading>
        <Paragraph>Edit your payments settings</Paragraph>
      </Container>
    </div>
  );
};

export default Payments;

const Container = styled.div`
  width: 100%;
  height: 100%;
  /* background: green; */
`;

const Heading = styled(Typography.Title)`
  font-size: 1.6em !important;
  font-weight: bold !important;
  color: #afafaf !important;
  margin-bottom: 0.5em !important;
  @media ${mobile} {
    font-size: 1.2em !important;
  }
`;

const Paragraph = styled(Typography.Paragraph)`
  color: #afafaf;
  @media ${mobile} {
    font-size: 0.9em !important;
  }
`;
