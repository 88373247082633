import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import CustomButton from 'components/common/CustomButton';
import CBTWrapper from 'components/utils/CBT/CBTWrapper';
import { clearCBT } from 'store/actions/cbt';
import { useDispatch } from 'react-redux';

const CBTComplete = ({ setShowResult, resultInfo, handleEnded, userPassedQuiz }) => {
  const { Title, Paragraph } = Typography;
  const dispatch = useDispatch();

  return (
    <CBTWrapper bgcolor="#307ec3">
      <Container>
        <Title level={1} style={{ marginBottom: 24, color: '#fff' }}>
          Complete!
        </Title>
        <Paragraph style={{ fontSize: 24, marginBottom: 50, color: '#fff' }}>
          {resultInfo}
        </Paragraph>
        <ButtonGroup>
          {userPassedQuiz && (
            <CustomButton
              radius="40px"
              width="120px"
              bgColor="transparent"
              color="#fff"
              size="large"
              onClick={() => {
                handleEnded();
                dispatch(clearCBT());
              }}>
              Skip
            </CustomButton>
          )}
          <CustomButton
            radius="40px"
            width="120px"
            bgColor="#fff"
            color="#0966b8"
            size="large"
            onClick={() => setShowResult(true)}>
            View Result
          </CustomButton>
        </ButtonGroup>
      </Container>
    </CBTWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export default CBTComplete;
