import React from 'react';
import styled from 'styled-components';

const CBTWrapper = ({ bgcolor, children }) => {
  return <Wrapper bgcolor={bgcolor}>{children}</Wrapper>;
};

const Wrapper = styled.div`
  width: 100%;
  background-color: ${props => props.bgcolor || '#fff'};
  ${'' /* margin: 20px; */}
  height: 100%;
`;

export default CBTWrapper;
