import React from 'react';
import { Box, Button, Form, FormField, ResponsiveContext, Select, Text, TextInput } from 'grommet';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { isMobile } from 'utils';
import ButtonLoader from 'components/common/ButtonLoader';
import { currencyList } from 'api/queries/Configuration';
import PageLoader from 'components/common/PageLoader';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from 'store/actions/toast';
import { coursePricing } from 'api/mutations/Course';
import { coursePricingAction } from 'store/actions/course';
import { useHistory } from 'react-router-dom';
import { dashboard } from 'adminRoutes';
import AppData from 'AppData';
import usePermission from 'components/hooks/usePermission';
import { computePricing } from 'api/mutations/Course';

const CoursePricingForm = ({
  isReview,
  defaultCourseValue,
  setShowApprovalModal,
  setShowRejectionModal,
  courseApprovalHandler
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { checkUserPermission } = usePermission();
  const { isLoading, isError, data: currency, error } = useQuery('currencyList', currencyList);
  const { register, errors, setValue, handleSubmit, control } = useForm();
  const { courseId, approvalStatus } = useSelector(state => state.course);
  const size = React.useContext(ResponsiveContext);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [shouldComputePrice, setShouldComputePrice] = React.useState(!isReview ? true : false);
  const [basePrice, setBasePrice] = React.useState(0);
  const [vat, setVat] = React.useState(defaultCourseValue?.coursePrice?.vat || 0);
  const [gatewayCharge, setGatewayCharge] = React.useState(
    defaultCourseValue?.coursePrice?.gatewayCharge || 0
  );
  const [totalPrice, setTotalPrice] = React.useState(defaultCourseValue?.coursePrice?.amount || 0);
  const formFieldStyle = {
    flexDirection: isMobile(size) ? 'column' : 'row',
    alignItems: isMobile(size) ? 'stretch' : 'center',
    justifyContent: isMobile(size) ? 'flex-start' : 'space-between'
  };

  const handleCalculatePrice = async () => {
    try {
      setIsSubmitting(true);
      setShouldComputePrice(true);
      const { data } = await computePricing({ basePrice });
      setShouldComputePrice(false);
      setVat(data.vat);
      setGatewayCharge(data.gatewayCharge);
      setTotalPrice(data.amount);
    } catch (e) {
      dispatch(showToast('error', 'Failed to calculate price. Please try again later'));
    } finally {
      setIsSubmitting(false);
    }
  };

  React.useEffect(() => {
    register('currencyId', { required: 'Currency is required' });
  }, [register]);

  const onSubmit = async ({ currencyId }) => {
    if (isReview && !checkUserPermission([AppData.permissions.manageCourseQA])) {
      courseApprovalHandler(AppData.courseApproval);
      return;
    }

    if (isReview) {
      setShowApprovalModal(true);
      return;
    }

    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      const payload = {
        courseId,
        currencyId: currencyId.value,
        basePrice: Number(basePrice),
        vat: Number(vat),
        gatewayCharge: Number(gatewayCharge)
      };
      const data = await coursePricing(payload);
      dispatch(coursePricingAction(data));
      if (data.success) {
        dispatch(showToast('success', 'Course submitted for review'));
        history.push(dashboard);
        return;
      }

      dispatch(showToast('error', data.description));
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return <PageLoader isLoading />;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: 450 }}>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Currency"
          name="currencyId"
          htmlfor="currencyId"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          {currency.data && (
            <Controller
              name="currencyId"
              control={control}
              rules={{ required: 'Currency is required' }}
              defaultValue={() => {
                const coursePrice = defaultCourseValue?.coursePrice;
                if (coursePrice) {
                  const currencyData = currency.data.find(d => d.id === coursePrice?.currencyId);
                  if (!currencyData) return '';
                  const selectedCurrency = { label: currencyData.name, value: currencyData.id };
                  setValue('currencyId', selectedCurrency);
                  return selectedCurrency;
                }
                return '';
              }}
              render={props => (
                <Select
                  id="currencyId"
                  name="currencyId"
                  placeholder="Currency"
                  style={{ width: isMobile(size) ? '100%' : 200, border: 'none' }}
                  labelKey="label"
                  valueKey="value"
                  options={currency.data.map(d => ({ label: d.name, value: d.id }))}
                  value={props.value}
                  onChange={({ option }) => {
                    props.onChange(option);
                    setValue('currency', option);
                  }}
                  disabled={isReview}
                />
              )}
            />
          )}
        </FormField>
        {errors.currencyId && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.currencyId.message}
          </Text>
        )}
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Base Price"
          name="basePrice"
          htmlfor="basePrice"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <Controller
            name="basePrice"
            control={control}
            defaultValue={defaultCourseValue?.coursePrice?.basePrice || ''}
            rules={{ required: 'Base Price is required' }}
            render={props => (
              <TextInput
                type="number"
                min="1"
                onKeyDown={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                placeholder="Base Price"
                style={{ width: isMobile(size) ? '100%' : 248, border: 'none' }}
                onChange={e => {
                  props.onChange(e.target.value);
                  setBasePrice(e.target.value);
                  setVat(0);
                  setGatewayCharge(0);
                  setTotalPrice(0);
                  setShouldComputePrice(true);
                }}
                value={props.value}
                disabled={isReview}
              />
            )}
          />
        </FormField>
        {errors.basePrice && (
          <Text
            color="status-critical"
            size="small"
            alignSelf={isMobile(size) ? 'start' : 'end'}
            margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
            {errors.basePrice.message}
          </Text>
        )}
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="VAT"
          name="vat"
          htmlfor="vat"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <Controller
            name="vat"
            control={control}
            render={props => (
              <TextInput
                placeholder="VAT"
                style={{ width: isMobile(size) ? '100%' : 248, border: 'none' }}
                value={vat}
                disabled
              />
            )}
          />
        </FormField>
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Gateway Charge"
          name="gatewayCharge"
          htmlfor="gatewayCharge"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <Controller
            name="gatewayCharge"
            control={control}
            render={props => (
              <TextInput
                placeholder="Gateway Charge"
                style={{ width: isMobile(size) ? '100%' : 248, border: 'none' }}
                value={gatewayCharge}
                disabled
              />
            )}
          />
        </FormField>
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <FormField
          label="Total Price"
          htmlfor="totalPrice"
          margin={{ bottom: 'none' }}
          style={formFieldStyle}>
          <Controller
            name="totalPrice"
            control={control}
            render={props => (
              <TextInput
                style={{ width: isMobile(size) ? '100%' : 248, border: 'none' }}
                value={totalPrice}
                disabled
              />
            )}
          />
        </FormField>
      </Box>
      <Box direction={isMobile(size) ? 'column' : 'row'} gap="medium" justify="end">
        {isReview && (approvalStatus === 1 || approvalStatus === 4) && (
          <Button
            type="button"
            secondary
            margin={{ top: 'xsmall' }}
            label="Reject"
            onClick={() => isReview && setShowRejectionModal(true)}
          />
        )}
        {isReview && approvalStatus === 2 ? (
          <Text>This course has been approved</Text>
        ) : isReview && approvalStatus === 3 ? (
          <Text>This course has been rejected</Text>
        ) : (
          <>
            {shouldComputePrice ? (
              <Button
                type="button"
                primary
                disabled={basePrice <= 0}
                margin={{ top: 'xsmall' }}
                label={isSubmitting ? '' : 'Calculate Price'}
                icon={isSubmitting ? <ButtonLoader isSubmitting={isSubmitting} /> : null}
                onClick={handleCalculatePrice}
              />
            ) : (
              <Button
                type="submit"
                primary
                margin={{ top: 'xsmall' }}
                label={isSubmitting ? '' : isReview ? 'Approve' : 'Submit for Review'}
                icon={isSubmitting ? <ButtonLoader isSubmitting={isSubmitting} /> : null}
              />
            )}
          </>
        )}
      </Box>
    </Form>
  );
};

export default CoursePricingForm;
