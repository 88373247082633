import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'antd';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import AppText from 'components/common/AppText';
import ButtonLoader from 'components/common/ButtonLoader';
import { removePendingCourse } from 'api/mutations/Course';
import { showToast } from 'store/actions/toast';
import { BeneficiaryContext } from 'context/BeneficiaryContext';

const RemoveCoursePrompt = ({ course, isModalVisible, setIsModalVisible, refetch }) => {
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  const { beneficiaryData, setBeneficiaryData } = useContext(BeneficiaryContext);

  const [mutate] = useMutation(removePendingCourse, {
    onMutate: () => setLoading(true),
    onSuccess: () => {
      onRemoveBeneficiaryDataItem();
      setLoading(false);
      dispatch(showToast('success', `${course.title} has been deleted successfully`));
      setIsModalVisible();
      refetch();
    },
    onError: error => {
      setLoading(false);
      dispatch(showToast('error', error.message));
      throw error;
    }
  });

  const handleRemove = () => {
    mutate(course.id);
  };
  const onRemoveBeneficiaryDataItem = () => {
    const newBeneficiary = [...beneficiaryData];
    const resolvedBeneficiary = newBeneficiary.filter(
      item => item.pendingUserCourseId !== course.id
    );
    setBeneficiaryData(resolvedBeneficiary);
  };

  return (
    <Modal
      visible={isModalVisible}
      onCancel={() => setIsModalVisible()}
      closable={true}
      footer={
        <FooterDiv>
          <>
            <CancelBtn disabled={loading} onClick={() => setIsModalVisible()}>
              Cancel
            </CancelBtn>
            <ProceedBtn disabled={loading} onClick={handleRemove}>
              {loading ? <ButtonLoader isSubmitting={loading} color="#0966b8" /> : 'Proceed'}
            </ProceedBtn>{' '}
          </>
        </FooterDiv>
      }>
      <Container>
        <AppText
          value={
            <>
              You are about to remove this course <strong>({course.title})</strong>, this action is
              not reversible, do you want to proceed?
            </>
          }
          weight={400}
          top="2rem"
          bottom={'5px'}
          size="22px"
          align={'center'}
          lineHeight="37.41px"
          family={'Assistant'}
        />
      </Container>
    </Modal>
  );
};

export default RemoveCoursePrompt;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 4rem;
  gap: 1rem;
`;

const CancelBtn = styled(Button)`
  background: #c4c4c4;
  height: 3rem;
  width: 150px;
`;
const ProceedBtn = styled(Button)`
  background: #0966b8;
  height: 3rem;
  width: 150px;
  color: #fff;
  border: none;
`;
