import React from 'react';
import PropTypes from 'prop-types';
import AboutImageGrid from './AboutImageGrid.jsx';
import AboutInfo from './AboutInfo.jsx';
import { Row } from 'antd';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import device from 'configs/responsive/mediaQueries';

const { mobile } = device;

const AboutSection = props => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  return (
    <AboutSectionWrapper>
      <Row>
        <AboutInfo isMobile={isMobile} />
        <AboutImageGrid isMobile={isMobile} />
      </Row>
    </AboutSectionWrapper>
  );
};

AboutSection.propTypes = {};

export default AboutSection;

const AboutSectionWrapper = styled.div`
  display: grid;
  place-items: center;
  height: 500px;
  padding: 0px 50px;
  margin: 1.5rem 0 6rem 0;
  @media ${mobile} {
    padding: 0px 1.3rem;
    margin: 6rem 0 6rem 0;
  }
`;
