import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaRegCircle } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

import AppText from 'components/common/AppText';
import timesIcon from 'assets/svgs/times.svg';
import { randomInteger } from 'utils';
import { getId } from '../editSurvey/EditSurveyForm';

export const getOptionId = option =>
  option.surveyQuestionOptionId ? 'surveyQuestionOptionId' : 'id';

const AddOption = ({ setQuestions, questionId, isEdit, option }) => {
  const [options, setOptions] = useState(
    option
      ? option
      : isEdit
      ? [{ id: uuidv4() }, { id: uuidv4() }, { id: uuidv4() }]
      : [{ id: uuidv4() }, { id: uuidv4() }, { id: uuidv4() }]
  );

  // const optionId = isEdit ? 'surveyQuestionOptionId' : 'id';
  // const surveyQuestionId = isEdit ? 'surveyQuestionId' : 'id';

  useEffect(() => {
    setQuestions(prev => {
      const question = prev.find(el => el[getId(el)] == questionId);
      prev[prev.indexOf(question)] = {
        ...question,
        options: options ? options : null
      };
      return [...prev];
    });
    // console.log('optionData', options);
  }, [options]);

  const handleChange = e => {
    e.persist();
    setOptions(prev => {
      const existingOption = prev?.find(el => el[getOptionId(el)] == e.target.id);
      if (existingOption) {
        prev[prev.indexOf(existingOption)] = {
          ...existingOption,
          option: e.target.value
        };
        return [...prev];
      } else {
        return [...prev, { id: e.target.id, option: e.target.value }];
      }
    });
  };

  const handleClick = () => {
    setOptions(prev => {
      return [...prev, { id: uuidv4() }];
    });
  };

  const handleDelete = id => {
    setOptions(prev => prev.filter(option => option[getOptionId(option)] !== id));
  };

  return (
    <Container>
      {options.map((el, index) => (
        <OptionDiv>
          <FaRegCircle color="#5D5D5D" />
          <Input
            placeholder={`option ${index + 1}`}
            value={el.option}
            onChange={handleChange}
            id={el[getOptionId(el)]}
            defaultValue={el.option}
          />
          <Img src={timesIcon} onClick={() => handleDelete(el[getOptionId(el)])} />
        </OptionDiv>
      ))}
      <OptionDiv style={{ cursor: 'pointer' }} onClick={handleClick}>
        <FaRegCircle color="#B9B9B9" />
        <AppText value="Add option" color="#B9B9B9" bottom="0" size={'14px'} />
      </OptionDiv>
    </Container>
  );
};

export default AddOption;

const Container = styled.div`
  margin-top: 1rem;
  gap: 2rem;
  display: flex;
  width: 100%;
  flex-flow: row wrap;
`;
const OptionDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Input = styled.input`
  outline: none;
  width: 50px;
  padding: 0;
  min-height: 17px;
  border: none;
  font-size: 16px;
  word-wrap: wrap;
  transition: all 0.2s ease-out;
  &::placeholder {
    color: #5d5d5d;
    font-size: 14px;
  }
  &:focus {
    width: 180px;
    height: 20px;
  }
`;

const Img = styled.img`
  align-self: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    transform: scale(1.2);
  }
`;
