import React from 'react';
import { Box, DataTable, Heading } from 'grommet';

const ApprovalTable = ({ data }) => {
  const columns = [
    { property: 'name', header: 'User', size: 'small' },

    {
      property: 'action',
      header: 'Action',
      size: 'xsmall',
      align: 'end'
    }
  ];
  return (
    <Box>
      <DataTable columns={columns} data={data} primaryKey={false} />
    </Box>
  );
};

export default ApprovalTable;
