import Axios from 'axios';

const service = 'Workflow';

export const getWorkflows = async () => {
  const { data } = await Axios.get(`/${service}/api/Workflow/List`);
  return data;
};

export const getWorkflowByInstitutionId = async (key, id) => {
  const { data } = await Axios.get(
    `/${service}/api/Workflow/GetWorkflowByInstitutionId?institutionId=${id}`
  );
  return data;
};

export const getWorkflowById = async (key, id) => {
  const { data } = await Axios.get(`/${service}/api/Workflow/Search?id=${id}`);
  return data;
};

export const getCourseAwaitingQAList = async (
  courseTitle,
  startDate = '',
  endDate = '',
  page = 1,
  pageSize = 50,
  courseId = 0,
  institutionId = 0
) => {
  const { data } = await Axios.get(
    `/${service}/api/CourseApproval/QA/CourseAwaitingQAList?${
      courseTitle && `courseTitle=${courseTitle}&`
    }page=${page}&pageSize=${pageSize}&courseId=${courseId}&institutionId=${institutionId}${
      startDate ? `&StartDate${startDate}` : ''
    }${endDate ? `&EndDate=${endDate}` : ''}`
  );
  return data;
};
